import React, { useContext } from 'react'
import styled from 'styled-components'
import { STRUCTURE_KPIS_FACTORY } from '../../constants/structures/structuresKPIsFactory'
import { Row, Col, Button } from 'antd'
import { printDiv } from '../../common/functions/index.jsx'
import { PATHNAME } from '../../../constants/enums/pathname'
import { ReportContext } from '../../services/report/reportProvider'
import { path } from 'ramda'

export const TYPE_ASSESSMENT = {
    DAY: "Daily",
    MONTH: "Full"
}

export const AssessmentFactoryByDay = ({ searchDate, dataSource }) => {
    const { reportState } = useContext(ReportContext)
    let resultDay = path(['reportFactory'], reportState)
    let searchResult = path(['reportFactory', 'search'], reportState)

    return (
        <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {(searchResult) &&
                    <Button type="primary" onClick={() => printDiv('byDay', PATHNAME.REPORT_KPIS_FACTORY)} >{'Print'} </Button>
                }
            </div>
            <div id={'byDay'}>
                <div style={{ padding: 10 }}>
                    <div style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}> {'รายงานผลการประเมินประสิทธิภาพงานพนักงาน'}</div>
                    {(resultDay) && <div style={{ display: 'flex', fontSize: 14, fontWeight: 300, justifyContent: 'center', padding: 10 }}>
                        <span style={{ fontWeight: 600, padding: '0 8px' }}> {`วันที่ : `} </span>
                        <span> {`${searchResult.monthTH || ''}  ${searchResult.year || '-'} `} </span>
                        <span style={{ fontWeight: 600, padding: '0 8px' }}> {`แผนก : `} </span>
                        <span > {`${searchResult.subDepartmentName || '-'}`} </span>
                        <span style={{ fontWeight: 600, padding: '0 8px' }}> {`ประเภท : `} </span>
                        <span > {`${searchResult.assessmentType || '-'} `} </span>
                    </div>}
                </div>
                <TableContainer >
                    {
                        STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_DAY.header({
                            volume: path(['datasource', 0, 'weightVolume'], resultDay) || '',
                            quality: path(['datasource', 0, 'weightQuality'], resultDay) || '',
                            behavior: path(['datasource', 0, 'weightBehavior'], resultDay) || ''
                        }).map(eachR => (
                            eachR.map((eachC, indexC) => (
                                <TableHeader key={indexC} span={eachC.span}>
                                    <TableHeaderLabel> {eachC.title}</TableHeaderLabel>
                                    <Row style={{ display: 'flex' }}>
                                        {
                                            eachC.children && eachC.children.map((child, indexChild) => (
                                                <TableSubHeader key={indexChild} span={child.span}>
                                                    <TableHeaderLabel border={child.border}>
                                                        {child.title}
                                                    </TableHeaderLabel>
                                                </TableSubHeader>
                                            ))
                                        }
                                    </Row>
                                </TableHeader>
                            ))
                        ))
                    }
                    {
                        resultDay && resultDay.datasource.map((val, indexVal) => (
                            STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_DAY.content(val, indexVal).map(eachR => (
                                eachR.map((eachC, indexC) => (
                                    <TableContent key={indexC} span={eachC.span}>
                                        <Row style={{ display: 'flex' }}>
                                            {
                                                eachC.children && eachC.children.map((child, indexChild) => (
                                                    <TableSubContent key={indexChild} span={child.span}>
                                                        {child.answer &&
                                                            <TableContentLabel {...child.styleBox}>
                                                                {child.answer}
                                                            </TableContentLabel>}
                                                        <Row style={{ display: 'flex' }}>
                                                            {child.children && child.children.map((subChild, indexSubChild) => (
                                                                <TableSubContent border={false} key={indexSubChild} span={subChild.span}>
                                                                    <TableContentLabel {...subChild.styleBox}>
                                                                        {subChild.answer}
                                                                    </TableContentLabel>
                                                                </TableSubContent>
                                                            ))}
                                                        </Row>
                                                    </TableSubContent>
                                                ))
                                            }
                                        </Row>
                                    </TableContent>
                                ))
                            ))
                        ))
                    }
                </TableContainer>
                <TableGradeContainer>
                    {
                        STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_DAY.contentGrade().map((eachC, indexC) => (
                            <TableGrade key={indexC} span={eachC.span}>
                                <TableGradeLabel> {eachC.title}</TableGradeLabel>
                                <TableGradeAnswer> {eachC.answer}</TableGradeAnswer>
                            </TableGrade>
                        ))
                    }
                </TableGradeContainer>
                {
                    STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_DAY.contentSignature(resultDay).map((eachR, indexR) => (
                        <TableSignatureContainer key={indexR}>
                            {eachR.map((eachC, indexC) => (
                                <Col key={indexC} span={eachC.span} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: 250 }}>
                                        <div style={{ display: 'flex', textAlign: 'left', padding: '10px 0px' }}>
                                            <div>
                                                {`${eachC.title} :`}
                                            </div>
                                            <div style={{ paddingLeft: 5 }}>
                                                {searchResult && searchResult.subDepartmentName === 'all' ?
                                                    '................................'
                                                    :
                                                    `${eachC.signature}` || '................................'
                                                }

                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            {searchResult && searchResult.subDepartmentName === 'all' ?
                                                '......../........./........'
                                                :
                                                `${eachC.date}` || '......../........./........'
                                            }
                                            {/* <div > {`${eachC.date}` || '......../........./........'}</div> */}
                                            <div style={{ fontSize: "12px" }}>   {`${eachC.department}`}</div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </TableSignatureContainer>
                    ))
                }
            </div>
        </div >
    )
}

// export const AssessmentFactoryByMonth = () => {
//     return (
//         <div style={{ padding: 10 }}>
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                 <Button onClick={() => browserHistory.push(PATHNAME.REPORT_KPIS_FACTORY)} >{'Back'}</Button>
//                 <Button type="primary" onClick={() => printDiv('byDay')} >{'Print'}</Button>
//             </div>
//             <div id={'byDay'}>
//                 <div style={{ padding: '10px 0px' }}>
//                     <div style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}> {'ข้อมูล KPIs พนักงานรายเดือน รายตำแหน่ง หน่วยงาน Mantence'}</div>
//                     <div style={{ display: 'flex', fontSize: 14, fontWeight: 300, justifyContent: 'flex-start', paddingTop: 25 }}>
//                         <span style={{ fontWeight: 600, paddingRight: '8px' }}> {`คะแนน 5 : `} </span>
//                         <span> {`ดีมาก`} </span>
//                         <span style={{ fontWeight: 600, padding: '0 8px' }}> {`คะแนน 4 : `} </span>
//                         <span > {`ดี`} </span>
//                         <span style={{ fontWeight: 600, padding: '0 8px' }}> {`คะแนน 3 : `} </span>
//                         <span > {`มาตรฐาน`} </span>
//                         <span style={{ fontWeight: 600, padding: '0 8px' }}> {`คะแนน 2 : `} </span>
//                         <span > {`ปรับปรุง`} </span>
//                     </div>
//                 </div>
//                 <TableContainer >
//                     {
//                         STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_MONTH.header(resultMonth.weight).map(eachR => (
//                             eachR.map((eachC, indexC) => (
//                                 <TableHeader key={indexC} span={eachC.span}>
//                                     <TableHeaderLabel> {eachC.title}</TableHeaderLabel>
//                                     <Row style={{ display: 'flex' }}>
//                                         {
//                                             eachC.children && eachC.children.map((child, indexChild) => (
//                                                 <TableSubHeader key={indexChild} span={child.span}>
//                                                     <TableHeaderLabel border={child.border}>
//                                                         {child.title}
//                                                     </TableHeaderLabel>
//                                                 </TableSubHeader>
//                                             ))
//                                         }
//                                     </Row>
//                                 </TableHeader>
//                             ))
//                         ))
//                     }
//                     {
//                         resultMonth.datasource.map(val => (
//                             STRUCTURE_KPIS_FACTORY.PRINT_EVALUATION_BY_MONTH.content(val).map(eachR => (
//                                 eachR.map((eachC, indexC) => (
//                                     <TableContent key={indexC} span={eachC.span}>
//                                         <Row style={{ display: 'flex' }}>
//                                             {
//                                                 eachC.children && eachC.children.map((child, indexChild) => (
//                                                     <TableSubContent key={indexChild} span={child.span} >
//                                                         {child.answer &&
//                                                             <div {...child.styleBox}>
//                                                                 {(child.answerList) ?
//                                                                     val[child.fieldList].map((listVal, indexList) => (
//                                                                         <Row style={{ borderBottom: '1px solid', padding: 0 }}>
//                                                                             {child.answer(listVal).no && <Col span={2} style={{ borderRight: '1px solid', padding: 5, textAlign: 'center' }} >
//                                                                                 {child.answer(listVal).no}
//                                                                             </Col>}
//                                                                             <Col span={22} style={{ padding: 5, fontSize: 10, textAlign: 'left' }} {...child.styleBox} >{child.answer(listVal).value} </Col>
//                                                                         </Row>
//                                                                     ))
//                                                                     :
//                                                                     child.answer
//                                                                 }
//                                                             </div>}
//                                                         {/* <Row style={{ display: 'flex' }}>
//                                                             {child.children && child.children.map((subChild, indexSubChild) => (
//                                                                 <TableSubContent border={false} key={indexSubChild} span={subChild.span}>
//                                                                     <TableContentLabel {...subChild.styleBox}>
//                                                                         {subChild.answer === 'list' ?
//                                                                             subChild.answerList.map((list, indexLisrt) => (
//                                                                                 <div> {list.answer}</div>
//                                                                             ))
//                                                                             :
//                                                                             subChild.answer
//                                                                         }
//                                                                     </TableContentLabel>
//                                                                 </TableSubContent>
//                                                             ))}
//                                                         </Row> */}
//                                                     </TableSubContent>
//                                                 ))
//                                             }
//                                         </Row>
//                                     </TableContent>
//                                 ))
//                             ))
//                         ))
//                     }
//                 </TableContainer>
//             </div>
//         </div >
//     )
// }



export const TableContainer = styled(Row)`
border: 1px solid;
border-right: 0px;
text-align: ${props => props.align || 'center'} ;
`

export const TableHeader = styled(Col)`
font-weight: 600;
border-right: 1px solid;
text-align: ${props => props.align || 'center'} ;
`

export const TableSubHeader = styled(Col)`
font-weight: 600;
border-top: 1px solid;
text-align: ${props => props.align || 'center'} ;
`

export const TableHeaderLabel = styled(Col)`
padding: 5px;
border-right: ${props => props.border === 'right' ? ' 1px solid' : 0} ;
`
export const TableContentLabel = styled(Col)`
padding: 3px;
font-size:12px;
border-right: ${props => props.border === 'right' ? '1px solid' : 0} ;
`

export const TableContent = styled(Col)`
border-right: 1px solid;
text-align: ${props => props.align || 'center'} ;
`


export const TableSubContent = styled(Col)`
font-weight: 300;
border-top: ${props => props.border === false ? '0px' : '1px solid'} ;
text-align: ${props => props.align || 'center'} ;
`



export const TableGrade = styled(Col)`
font-weight: 600;
text-align: ${props => props.align || 'center'} ;
`
export const TableGradeLabel = styled(Col)`
border-right: 1px solid;
padding:5px;
`
export const TableGradeAnswer = styled(Col)`
font-weight: 300;
padding:5px;
border-top:  1px solid;
border-bottom:  1px solid;
border-right: 1px solid;
`

export const TableGradeContainer = styled(Row)`
border-top:  1px solid;
border-left:  1px solid;
margin-top : 30px
`
export const TableSignatureContainer = styled(Row)`
margin-top : 30px
`
