import React, { Fragment, useContext, useRef } from 'react'
import { ExportExcel } from '../common/buttons';
import { STRUCTURE_KPIS } from '../constants/structures/structuresKPIs';
import { Empty, Spin } from 'antd';
import { TableNested } from '../../constants/tables/TableNested';
import { ReportContext } from '../services/report/reportProvider';
import { renderMonth } from '../../constants/functions';
import * as R from 'ramda'

export const ReportKPIs = () => {
    const STRUCTURE = STRUCTURE_KPIS
    const refExport = useRef()
    const { reportState } = useContext(ReportContext)
    const { reportAreportKPIsLists, dataSearch } = reportState

    let columnsReport = [
        {
            title: 'ข้อมูลพนักงาน', children: [
                { title: 'รหัสพนักงาน', field: 'employeeId' },
                { title: 'ชื่อพนักงาน', field: 'employeeName' },
                { title: 'ชื่อเล่น', field: 'employeeNickname' },
                { title: 'ตัววัดผลงาน', field: 'indicatorLineName' },
                { title: 'Total Score', field: 'nomalizeScore' },
                { title: 'Total Year', field: 'totalYearByline' }
            ]
        }, {
            title: `ข้อมูลการประเมินประจำปี ${dataSearch[0].year} (คะแนนเต็ม 100 คะแนน)`,
            children: R.sort(((a, b) => (a - b)), dataSearch).map(each => {
                return (
                    {
                        title: `${renderMonth(parseInt(each.month)) && renderMonth(parseInt(each.month)).sortTH}`,
                        field: `month${each.month}`
                    }
                )
            })
        }
    ]
    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ExportExcel
                  ref={refExport}
                    columns={columnsReport}
                    dataSource={reportAreportKPIsLists}
                />
            </div>
            {
                (Array.isArray(reportAreportKPIsLists) && reportAreportKPIsLists.length > 0) ?
                    <div style={{ padding: 10 }}>
                        <Spin spinning={false} tip="กำลังโหลด...">
                            <TableNested
                                rowKey={'employeeId'}
                                columns={STRUCTURE.COLUMN_REPORT({
                                    searchDate: dataSearch,
                                })}
                                expandable={{
                                    rowExpandable: record => (record.evaluationLine) ? true : false
                                }}
                                pagination={{
                                    pageSize: 100
                                }}
                                columnsExpanded={STRUCTURE.COLUMN_REPORT_EXPANDED({
                                    searchDate: dataSearch,
                                })}
                                dataSource={reportAreportKPIsLists}
                                expandedField="evaluationLine"
                            />
                        </Spin>
                    </div>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </Fragment>
    )
};
