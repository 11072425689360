import React from "react";
import moment from "moment";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { ButtonTheme } from "..";

export const UseExportExcel = ({
  header,
  title,
  dataSet = [],
  filename = "name",
  colorFill = false,
  additionDataSet = [],
  templateFunction = false,
}) => {
  const _exporter = React.createRef();

  const exportExcel = () => {
    if (templateFunction) {
      templateFunction(_exporter);
      return;
    }
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  return (
    <span>
      <ButtonTheme
        title={title}
        useFor="EXPORT_EXCEL"
        onClick={exportExcel}
        colorFill={colorFill}
      />
      <ExcelExport
        data={
          additionDataSet.length > 0
            ? [...dataSet, ...additionDataSet]
            : dataSet
        }
        fileName={
          filename
            ? `${filename}-${moment().format("DD-MM-YYY")}.xlsx`
            : `downloaded-data-${moment().format("DD-MM-YYY")}.xlsx`
        }
        ref={_exporter}
      >
        {header &&
          header.map((val) => {
            const { subMenu } = val;
            if (!subMenu) {
              return <ExcelExportColumn field={val.field} title={val.title} />;
            } else if (subMenu.length > 1) {
              //Fix bug when there is only 1 subMenu, Column will not render with.map
              return (
                <ExcelExportColumnGroup
                  title={val.title}
                  headerCellOptions={{ textAlign: "center" }}
                >
                  {subMenu.map((subVal) => {
                    return (
                      <ExcelExportColumn
                        key={subVal.title}
                        field={subVal.field}
                        title={subVal.title}
                      />
                    );
                  })}
                </ExcelExportColumnGroup>
              );
            } else {
              return (
                <ExcelExportColumnGroup
                  title={val.title}
                  headerCellOptions={{ textAlign: "center" }}
                >
                  <ExcelExportColumn
                    key={subMenu[0].title}
                    field={subMenu[0].field}
                    title={subMenu[0].title}
                  />
                </ExcelExportColumnGroup>
              );
            }
          })}
      </ExcelExport>
    </span>
  );
};
