import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_REPORT_EMPLOYEE_TRANSFER = {
    actionType: undefined,
    statusTransfer: undefined,
    userId: undefined,
    currentStatus: undefined,
    startDate: undefined,
    toDate: undefined,
}

const DEFINE = {
    actionType: {
        label: "ประเภทการโอนย้าย",
        name: "actionType",
    },
    statusTransfer: {
        label: "สถานะการโอนย้าย",
        name: "statusTransfer",
    },
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    currentStatus: {
        label: "สถานภาพพนักงาน",
        name: "currentStatus",
    },
    startDate: {
        label: "วันที่มีผล",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
}

export const ComponentReportEmployeeTranfer = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { actionType, statusTransfer, userId, currentStatus, startDate, toDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 8, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: actionType.name,
                            label: actionType.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(actionType.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(actionType.label),
                            disabled: isDisable[actionType.name] || false,
                        },
                        fnChange: (value) => handleChange(actionType.name, value),
                        dropdown: options[actionType.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 8, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusTransfer.name,
                            label: statusTransfer.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusTransfer.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusTransfer.label),
                            disabled: isDisable[statusTransfer.name] || false,
                        },
                        fnChange: (value) => handleChange(statusTransfer.name, value),
                        dropdown: options[statusTransfer.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: currentStatus.name,
                            label: currentStatus.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(currentStatus.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(currentStatus.label),
                            disabled: isDisable[currentStatus.name] || false,
                        },
                        fnChange: (value) => handleChange(currentStatus.name, value),
                        dropdown: options[currentStatus.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}