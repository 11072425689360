import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from "antd";
import { configForm } from "./constants/ConstantComponent";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useRenderFormItem } from "./conponents/RenderComponents";
import { sumColumnWidth } from "./functions/UseFunction";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import { useConfirm } from "./conponents/modal/CustomConfirm";
import { browserHistory } from "react-router";
import { getPeriodDate, getDatePeriodNow } from "../functions";
import { useOptionSelect } from "./constants/OptionSelect";
import {
  GET_PERMISSION_USER_COMMISSION,
  POST,
  GET_COMMISSION_INCENTIVE_DASHBOARD_FSP,
  UPDATE_STATUS_SAVE_COMMISSION_FSP,
} from "../service";
import encodeString from "../extendFunction/encodeString";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import { useAlert } from "./conponents/modal/CustomAlert";
import { Button } from "antd";
import moment from "moment";
import { ColumnRecheckCommissionIncentiveDashboardFSP } from "./constants/RecheckCommissionIncentiveFSP/Columns";
import { ComponentRecheckCommissionIncentiveDashboardFSP } from "./constants/RecheckCommissionIncentiveFSP/Components";

const RecheckCommissionIncentiveDashboardFSP = () => {
  const [form] = Form.useForm();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [permissionUser, setPermissionUser] = useState([]);
  const [depOption, setDepOption] = useState([]);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));

  let optionSearch = {
    depNo: useOptionSelect("department"),
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    await getPermissionUser();
    form.setFieldsValue({ startDate: moment(getDatePeriodNow("start")) });
    form.setFieldsValue({ endDate: moment(getDatePeriodNow("end")) });
    await onSearchCommissionDashboard({
      startDate: moment(getDatePeriodNow("start")),
      endDate: moment(getDatePeriodNow("end")),
    });
  };

  useEffect(() => {
    if (permissionUser.length > 0 && depOption.length == 0) {
      let temp = optionSearch.depNo.filter((el) => {
        let index = permissionUser.findIndex(
          (val) => val.departmentNo == el.value
        );
        if (index != -1) {
          return el;
        }
      });
      setDepOption(temp);
    }
  }, [optionSearch.depNo]);

  const getPermissionUser = async () => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_PERMISSION_USER_COMMISSION, {
        userLogin: encodeString.decode(cookies.ID_User),
      });
      const { result } = response;
      if (result.length == 0) {
        browserHistory.push("/");
      } else {
        setPermissionUser(result);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser: ", err);
      browserHistory.push("/");
    } finally {
      setLoadingScreen(false);
    }
  };

  const onSearchCommissionDashboard = async (values) => {
    const obj = {
      // depNo: values.depNo || '',
      startDate: values.startDate ? values.startDate.format("YYYY-MM-DD") : "",
      endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : "",
      userLogin: encodeString.decode(cookies.ID_User),
    };
    getCommissionIncentiveDashboardFSP(obj);
  };

  const getCommissionIncentiveDashboardFSP = async (obj) => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_COMMISSION_INCENTIVE_DASHBOARD_FSP, obj);
      const { result, success } = response;
      if (success) {
        setDataSource(result);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser FSP: ", err);
      // browserHistory.push("/");
    } finally {
      setLoadingScreen(false);
    }
  };

  const editRow = (row) => {
    const { docNo, installmentEndDate, installmentStartDate } = row;
    browserHistory.push({
      pathname: "/RecheckCommissionIncentiveActionFSP",
      state: {
        docNo,
        depNo: row.departmentCode,
        installmentEndDate,
        installmentStartDate,
      },
    });
  };

  const viewRow = (row) => {
    const { docNo, installmentEndDate, installmentStartDate } = row;
    browserHistory.push({
      pathname: "/RecheckCommissionIncentiveActionFSP",
      state: {
        docNo,
        depNo: row.departmentCode,
        installmentEndDate,
        installmentStartDate,
        view: true,
      },
    });
  };

  const returnStatusRow = (row) => {
    let obj = {
      statusSave: `${parseInt(permissionUser[0].sequence) - 1}`,
      docNo: row.docNo,
      userLogin: encodeString.decode(cookies.ID_User),
    };
    useConfirm({
      content: `ต้องการย้อนสถานะรายการนี้ใช่หรือไม่`,
      FnOK: () => updateStatusSave(obj),
    });
  };

  const updateStatusSave = async (obj) => {
    try {
      setLoadingScreen(true);
      const response = await POST(UPDATE_STATUS_SAVE_COMMISSION_FSP, obj);
      const { message, success } = response;
      if (success) {
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          content: message,
          Fn: () => {
            onSearchCommissionDashboard({
              startDate: "",
              endDate: "",
            });
            form.resetFields();
          },
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser: ", err);
      browserHistory.push("/");
    } finally {
      setLoadingScreen(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      <Form form={form} onFinish={onSearchCommissionDashboard} {...configForm}>
        <Card
          title={`หน้าหลักตรวจสอบ Commission/Incentive โฟกัสช็อป (รอบวันที่ ${getPeriodDate(
            "display"
          )})`}
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentRecheckCommissionIncentiveDashboardFSP({
              options: { depNo: depOption },
              handleChange: () => {},
            }),
          })}
        </Card>
      </Form>
      <Card
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() =>
                browserHistory.push("/RecheckCommissionIncentiveActionFSP")
              }
            >
              สร้างเอกสาร
            </Button>
          </Space>
        }
      >
        <CustomTableAtnd
          HeadTable={ColumnRecheckCommissionIncentiveDashboardFSP({
            editRow,
            viewRow,
            returnStatusRow,
            permissionUser,
          })}
          DataTable={dataSource}
          moreProps={{
            scroll: {
              x:
                sumColumnWidth(
                  ColumnRecheckCommissionIncentiveDashboardFSP({
                    editRow,
                    viewRow,
                    returnStatusRow,
                  })
                ) || 2050,
              y: 500,
            },
            size: "small",
          }}
        />
      </Card>
    </Spin>
  );
};

export default RecheckCommissionIncentiveDashboardFSP;
