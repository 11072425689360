import React from "react";

import { Modal, Form, Input, DatePicker, Radio } from "antd";
import { API_URL } from "../components/connectAPI";
import { ComponentSendNewEmp } from "../screens/constants/Employee/Components";
import { useRenderFormItem } from "../screens/conponents/RenderComponents";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { PUT, PUT_STAMP_TASK_IN, PUT_STAMP_TASK_OUT } from "../service";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import { GET_UserLogin } from "../screens/functions/UseFunction";

const ComponentModal = ({
  modalVisible,
  modalTitle,
  onCloseModal,
  dataRecord,
  modalTask,
  setModalVisible,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    followUpDate: moment(),
    note: "",
    status: "FOLLOW",
  });

  useEffect(() => {
    form.setFieldsValue(dataRecord);
  }, [form, dataRecord]);

  const onChangeValue = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };

  const onSubmit = () => {
    const { followUpDate, note, status } = data;
    if (status === "COMPLETE") {
      const objBody = {
        userId: dataRecord.userId,
        task: modalTask,
        note: note,
        status: status,
      };
      return Modal.confirm({
        title: "ยืนยันการบันทึก",
        onOk: () => {
          activeTab === "NEW"
            ? onPutStampTask(objBody)
            : onPutStampTaskOut(objBody);
        },
      });
    }
    const objBody = {
      userId: dataRecord.userId,
      task: modalTask,
      note: note,
      status: status,
      followUpDate: followUpDate,
    };
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => {
        activeTab === "NEW"
          ? onPutStampTask(objBody)
          : onPutStampTaskOut(objBody);
      },
    });
  };

  const onPutStampTask = async (objBody) => {
    try {
      const res = await PUT(PUT_STAMP_TASK_IN(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => {
            onCloseModal();
            setData({
              followUpDate: moment().format("YYYY-MM-DD"),
              note: "",
              status: "FOLLOW",
            });
          },
        });
      }
    } catch (err) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
      });
    }
  };

  const onPutStampTaskOut = async (objBody) => {
    try {
      const res = await PUT(PUT_STAMP_TASK_OUT(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => {
            onCloseModal();
            setData({
              followUpDate: moment().format("YYYY-MM-DD"),
              note: "",
              status: "FOLLOW",
            });
          },
        });
      }
    } catch (err) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={dataRecord}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onCancel={() => {
          setData({
            followUpDate: moment(),
            note: "",
            status: "FOLLOW",
          });
          setModalVisible(false);
        }}
        onOk={onSubmit}
      >
        <Form.Item label="รหัสพนักงาน" name="userId">
          <Input disabled />
        </Form.Item>
        <Form.Item label="วันที่ Followup" name="followUpDate">
          <DatePicker
            style={{
              width: "100%",
            }}
            onChange={(date, dateString) => {
              onChangeValue("followUpDate", dateString);
            }}
            //value={followDate}
            // defaultValue={moment(followDate)}
            disabled={data.status === "COMPLETE" ? true : false}
          />
        </Form.Item>
        <Form.Item label="Note" name="noteValue">
          <Input
            onChange={(e) => {
              onChangeValue("note", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Please select an option!" }]}
        >
          <Radio.Group
            defaultValue={"FOLLOW"}
            onChange={(e) => {
              onChangeValue("status", e.target.value);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Radio value="FOLLOW"> ติดตามงาน </Radio>
            <Radio value="COMPLETE"> เสร็จสิ้น </Radio>
          </Radio.Group>
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default ComponentModal;
