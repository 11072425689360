import React from 'react'

import "bootstrap/dist/css/bootstrap.css";
import { Card, CardHeader, CardBody } from "reactstrap";

export const MainTheme = ({ header = "", component = "", view = false }) => {
	return (
		<Card style={{ margin: view ? 0 : '20px 0px' }}>
			{header ? <CardHeader> {header} </CardHeader> : null}
			<CardBody>
				{component ? component : null}
			</CardBody>
		</Card>
	)
}

