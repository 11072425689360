import { Spin, Button, Modal } from "antd";
import React, { useState } from "react";

const TestPage = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Spin tip="Loading..." spinning={false}>
      <Button type="primary" onClick={() => setVisible(true)}>
        Open Modal of 1000px width
      </Button>

      <Modal
        title="Modal 1000px width"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    </Spin>
  );
};

export default TestPage;
