import React from 'react'
import { Popover, Button } from 'antd'

const PopoverWrapper = ({
    icon,
    title,
    trigger,
    content,
    onClick,
    buttonText,
    ...rest
}) => {
        return (
        <Popover content={content} title={title} trigger={trigger} {...rest} >
            {(icon) ?
                icon
                :
                <Button onClick={onClick}>{buttonText}</Button>
            }
        </Popover>
    )
}

export default PopoverWrapper