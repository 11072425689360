import React, { useState } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { SSO, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import { optionHasSSO, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { ImagesHospitalList } from "../images/ImgAntd";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";

export const FormAddSSO = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeAddValue, setChangeAddValue] = useState({});
  const [loadingScreen, setloadingScreen] = useState(false);

  // [START] Handle Form Antd
  let copyArr = {
    ...changeAddValue,
    companyCode: changeAddValue.company,
    provinceId: changeAddValue.province,
  };

  let queryUser = useFixedQueryString(copyArr, ["companyCode"]);
  let queryHospital = useFixedQueryString(copyArr, ["provinceId"]);

  let optionProvince = useOptionSelect("province");
  let optionHospital = useOptionSelect("hospital", queryHospital);

  const onFinish = (values) => {
    let POST_DATA = values.hasSSO
      ? {
          userId: values.userId,
          hasSSO: values.hasSSO,
          hospitalIds: [],
          userlogin,
        }
      : {
          userId: values.userId,
          hasSSO: values.hasSSO,
          hospitalIds: [
            values.hospital_1,
            values.hospital_2,
            values.hospital_3,
          ],
          userlogin,
        };
    useConfirm({
      FnOK: () => POST_INSERT_SSO(POST_DATA),
    });
  };
  const onReset = () => {
    form.resetFields();
    setChangeAddValue({});
  };
  // [END] Handle Form Antd

  // [START] REST API
  const POST_INSERT_SSO = async (values) => {
    try {
      setloadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${SSO.POST_Insert_SSO}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: "การเพิ่มข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setloadingScreen(false),
        });
      }
    } catch {
      setloadingScreen(false);
      console.log("Error POST_INSERT_SSO");
    }
  };
  // [END] REST API

  // [START] Functions

  const onChangeValue = (val, name) => {
    let SET_undifined = {};
    let ArrayInput = [
      "company",
      "userId",
      "province",
      "hospital_1",
      "hospital_2",
      "hospital_3",
    ];
    switch (name) {
      case "company":
        SET_undifined = toUndefined(ArrayInput.slice(1));
        break;
      case "userId":
        SET_undifined = toUndefined(ArrayInput.slice(2));
        break;
      case "province":
        SET_undifined = toUndefined(ArrayInput.slice(3));
        break;
      default:
        SET_undifined = {};
        break;
    }
    form.setFieldsValue(SET_undifined);
    setChangeAddValue((prev) => ({
      ...prev,
      ...SET_undifined,
      [name]: val,
    }));
  };

  // [END] Functions

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Card
            title="เพิ่มข้อมูลโรงพยาบาลประกันสังคม"
            style={{ marginBottom: 30 }}
          >
            <div
              style={{
                textAlign: "center",
                margin: 30,
              }}
            >
              <ImagesHospitalList />
            </div>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                <SelectAntd
                  name={`company`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={useOptionSelect("company")}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <SelectAntd
                  name={`userId`}
                  label={`พนักงาน`}
                  smgReq={`กรุณาเลือก พนักงาน`}
                  placeholder={`เลือก พนักงาน`}
                  dropdown={useOptionSelect("empSSO", queryUser)}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <SelectAntd
                  name={`hasSSO`}
                  label={`มีบัตรรับรองสิทธิประกันสังคม`}
                  smgReq={`กรุณา เลือกคำตอบ`}
                  placeholder={`เลือก คำตอบ`}
                  dropdown={optionHasSSO}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
            </Row>
            {!form.getFieldValue("hasSSO") && (
              <Row gutter={[16, 16]} justify="center" align="bottom">
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <SelectAntd
                    name={`province`}
                    label={`จังหวัด`}
                    smgReq={`กรุณาเลือก จังหวัด`}
                    placeholder={`เลือก จังหวัด`}
                    dropdown={optionProvince}
                    FnOnChange={onChangeValue}
                    required={false}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <SelectAntd
                    name={`hospital_1`}
                    label={`โรงพยาบาลลำดับที่ 1`}
                    smgReq={`กรุณาเลือก โรงพยาบาลลำดับที่ 1`}
                    placeholder={`เลือก โรงพยาบาลลำดับที่ 1`}
                    dropdown={optionHospital}
                    FnOnChange={onChangeValue}
                    required={true}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <SelectAntd
                    name={`hospital_2`}
                    label={`โรงพยาบาลลำดับที่ 2`}
                    smgReq={`กรุณาเลือก โรงพยาบาลลำดับที่ 2`}
                    placeholder={`เลือก โรงพยาบาลลำดับที่ 2`}
                    dropdown={optionHospital}
                    FnOnChange={onChangeValue}
                    required={true}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <SelectAntd
                    name={`hospital_3`}
                    label={`โรงพยาบาลลำดับที่ 3`}
                    smgReq={`กรุณาเลือก โรงพยาบาลลำดับที่ 3`}
                    placeholder={`เลือก โรงพยาบาลลำดับที่ 3`}
                    dropdown={optionHospital}
                    FnOnChange={onChangeValue}
                    required={false}
                  />
                </Col>
              </Row>
            )}
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default FormAddSSO;
