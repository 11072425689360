import { renderMonth } from "../../../constants/functions";

export const optionYear = (_string = true, _num = 4) => {
    let nowYear = new Date().getFullYear();
    let resultLists = [];
    for (let i = 0; i < _num; i++) {
        if (_string) {
            resultLists.push(`${nowYear - i}`)
        } else {
            resultLists.push({ optionId: parseInt(nowYear - i), optionLabel: `${nowYear - i}` })
        }
    }
    return resultLists;
}

export function getQuarter(month) {
    try {
        let quarter = ''
        // console.log('month', month)

        if (month < 3) { //0 1 2
            quarter = 1
        }
        else if (month < 6 && month > 2) {//3 4 5
            quarter = 2
        }
        //else if (month < 9 && month > 5) {//6 7 8
        else if (month < 10 && month > 5) {//6 7 8
            quarter = 3
        }
        //  else if (month > 8) {//9 10 11
        else if (month > 9) {//9 10 11
            quarter = 4
        }
        //    console.log('quarter', quarter)
        return quarter;
    } catch (err) {
        console.log('err', err)
        throw err;
    }
}

export const optionQuarter = () => {
    return [
        { optionId: 1, optionLabel: 'ไตรมาสที่ 1 : ม.ค. - มี.ค.', optionValue: 1 },
        { optionId: 2, optionLabel: 'ไตรมาสที่ 2 : เม.ย. - มิ.ย.', optionValue: 2 },
        { optionId: 3, optionLabel: 'ไตรมาสที่ 3 : ก.ค. - ก.ย.', optionValue: 3 },
        { optionId: 4, optionLabel: 'ไตรมาสที่ 4 : ต.ค. - ธ.ค.', optionValue: 4 },
    ];
}

export const optionStatusEvac = () => {
    return [
        { optionId: 0, optionLabel: 'ทำแบบสอบถามแล้ว', optionValue: 0 },
        { optionId: 1, optionLabel: 'ยังไม่ได้ทำแบบสอบถาม', optionValue: 1 },
    ];
}

export const optionMonth = (_string = true, _num = 12) => {
    let resultLists = [];
    for (let i = 1; i <= _num; i++) {
        if (_string) {
            resultLists.push(renderMonth(i).en)
        } else {
            resultLists.push({ optionId: renderMonth(i).num, optionLabel: renderMonth(i).th })
        }
    }
    return resultLists;
}



export const optionAssessment = () => {
    return [{
        optionId: '1',
        optionValue: 'Full',
        optionLabel: 'พนักงานประจำ',
    }, {
        optionId: '2',
        optionValue: 'Daily',
        optionLabel: 'พนักงานรายวัน',
    }]
}