import React from 'react';
import { Table } from 'antd';

export const LayoutTable = ({
    rowKey = "",
    scroll,
    columns = [],
    dataSource = [],
    pagination,
    loading = false,
    bordered = true,
    ...rest
}) => {
    return (
        <Table
            rowKey={rowKey}
            scroll={scroll}
            loading={loading}
            bordered={bordered}
            columns={columns}
            dataSource={dataSource}
            pagination={pagination}
            {...rest} />
    )
};