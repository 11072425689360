import React, { useState, useContext } from "react";
import { Modal, Spin, Button } from "antd";
import "../CSSTraining.css";
import {
  LayoutBreadcrumb,
  LayoutWrapper,
} from "../../moduleKPIs/common/layouts";
import { BREADCRUMB_TRAINING } from "../commonsTraining/structuresTraining/breadcrumTraining";
import {
  APIImportTraining,
  APIInsertOthersFile,
} from "../servicesTraining/manageTraining/manageTrainingConnect";
import { ManageTrainingContext } from "../servicesTraining/manageTraining/manageTrainingProvider";
import { UploadFile } from "../../constants/functions/uploadFile";
import { TEXT_BUTTON } from "../../constants/buttons";
import { colors } from "../../themes";
import { downloadFile } from "../../constants/functions";
import { DownloadOutlined } from "@ant-design/icons";
import {
  ComponentImportTraining,
  ExcelToJSON,
  MapDataExcel,
} from "./constants/ImportTraining/Components";
import { useRenderFormItem } from "../../screens/conponents/RenderComponents";

const PageImportTraining = () => {
  const [arrExcel, setArrExcel] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userLogin } = useContext(ManageTrainingContext);

  const handleChange = async ({file,fileList}) => {
    try {
        const fileName = (file.name).split(".");
        const fileType = fileName[fileName.length - 1];
        if (fileList && fileList.length > 0 && fileType !== "xlsx") {
          Modal.warning({
            title: "กรุณาอัปโหลดไฟล์ .xlsx เท่านั้น",
          });
          setArrExcel([]);
          return;
        }
        const res = await ExcelToJSON(file);
        const dataUpload = MapDataExcel(res[0].data);
        setArrExcel(dataUpload);
    } catch (error) {
      console.error("error>", error);
    }
  };

  const TEXT_TRAINING = {
    HEADER: "หน้าจัดการเพิ่มข้อมูลการเข้าอบรม (Import Training)",
    CONTENT: () => [
      [
        useRenderFormItem({
          constantComponent: ComponentImportTraining({
            options: [],
            handleChange,
          }),
        }),
      ],
    ],
  };

  async function onConfirm() {
    if (arrExcel === "" || arrExcel === undefined) {
      Modal.success({ title: "กรุณาแนบไฟล์" });
    } else {
      Modal.confirm({
        title: "ยืนยันการเพิ่มข้อมูลหรือไม่",
        onOk: async () => {
          setLoading(true);
          // console.log("arrExcel>",arrExcel);
          await APIImportTraining(
            { data: arrExcel, userlogin: userLogin.userId },
            async (response) => {
              if (response) {
                let payloadFile = {
                  docNo: response.docNo,
                  userId: userLogin.userId,
                  enrollCourses: fileList,
                };
                if (payloadFile.enrollCourses.length > 0) {
                  await APIInsertOthersFile(payloadFile, async (resFile) => {
                    if (resFile) {
                      await Modal.success({
                        title: "อัพโหลดไฟล์สำเร็จ",
                        onOk: async () => {
                          await setLoading(true);
                          await window.location.reload();
                        },
                      });
                    }
                  });
                } else {
                  await Modal.success({
                    title: "อัพโหลดไฟล์สำเร็จ",
                    onOk: async () => {
                      await setLoading(true);
                      await window.location.reload();
                    },
                  });
                }
              }
            }
          );
          setLoading(false);
        },
      });
    }
  }

  return (
    <LayoutWrapper
      breadcrumb={
        <LayoutBreadcrumb
          breadcrumbLists={BREADCRUMB_TRAINING(window.location.pathname)}
        />
      }
      content={
        <div className="layout-card">
          <Spin spinning={loading} tip="กำลังอัพโหลดไฟล์...">
            <div className="layout-header">{TEXT_TRAINING.HEADER || ""}</div>
            <div className="layout-content">
              {
                <>
                  {TEXT_TRAINING.CONTENT({
                    arrExcel,
                  })}
                  <p style={{ color: "red", fontSize: "12px" }}>
                    * รองรับเฉพาะไฟล์ .xlxs เท่านั้น
                  </p>
                  <hr />
                </>
              }
            </div>

            <UploadFile
              message={true}
              maxlength={1}
              fieldname="enrollCourses"
              label="แนบเอกสารเพิ่มเติม"
              fileList={fileList}
              onClick={async (action, file, fieldname) => {
                switch (action) {
                  case "UPLOAD_ADD":
                    await setFileList(fileList.concat([file]));
                    break;
                  case "UPLOAD_REMOVE":
                    if (fileList.length > 0) {
                      await setFileList([]);
                    }
                    break;

                  default:
                    break;
                }
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <Button
                style={{
                  marginRight: 10,
                  color: colors.theme,
                  borderColor: colors.theme,
                }}
                type="dashed"
                icon={<DownloadOutlined style={{ marginBottom: 5 }} />}
                onClick={async () => {
                  try {
                    await downloadFile("/template/TemplateOJT.xlsx", "all");
                  } catch (error) {
                    console.log({ error });
                  }
                }}
              >
                {TEXT_BUTTON.DOWNLOAD_TEMPLATE}
              </Button>
              <Button
                type="primary"
                disabled={arrExcel.length > 0 ? false : true}
                onClick={() => onConfirm()}
                style={{
                  backgroundColor:
                    arrExcel.length > 0
                      ? colors.success
                      : colors.buttonDisabled,
                  border: 0,
                }}
              >
                {TEXT_BUTTON.CONFIRM}
              </Button>
            </div>
          </Spin>
        </div>
      }
    />
  );
};

export default PageImportTraining;
