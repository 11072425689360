import ImportPersonal from "./containersPersonal/importPersonal.jsx";
import InsertPersonal from "./containersPersonal/insertPersonal.jsx";
import UpdatePersonal from "./containersPersonal/updatePersonal.jsx";
import BlockPersonal from "./containersPersonal/blockPersonal.jsx";
import DashboardPersonal from "./containersPersonal/dashboardPersonal.jsx";
import DashboardImportPersonal from "./containersPersonal/dashboardImportPersonal.jsx";
import DashboardImportPersonalIdCard from "./containersPersonal/dashboardImportPersonalIdCard.jsx";
import RecheckPersonal from "./containersPersonal/recheckPersonal.jsx";
import DashboardImportQuitPersonal from "./containersPersonal/dashboardImportQuitPersonal.js";
import ViewPersonal from "./containersPersonal/viewPersonal.jsx";
import DashboardPersonalView from "./containersPersonal/dashboardPersonalView.jsx";

export const ModulePersonal = {
  InsertPersonal,
  ImportPersonal,
  UpdatePersonal,
  ViewPersonal,
  BlockPersonal,
  DashboardPersonal,
  DashboardImportPersonal,
  DashboardImportPersonalIdCard,
  RecheckPersonal,
  DashboardPersonalView,
  DashboardImportQuitPersonal,
};
