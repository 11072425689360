import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

const DEFINE = {
    users: {
        label: "กลุ่ม / รหัสพนักงาน",
        name: "users",
    },
    description: {
        label: "ชื่อสายการดูข้อมูล (ไม่เกิน 150 ตัวอักษร)",
        name: "description",
    },
    flowId: {
        label: "รหัสสายดูข้อมูล",
        name: "flowId",
    },
}

export const ComponentsSearchBranchView = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { users } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: users.name,
                            label: users.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(users.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(users.label),
                            disabled: isDisable[users.name] || false,
                            mode: "multiple",
                        },
                        fnChange: (value) => handleChange(value, users.name),
                        dropdown: options[users.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "search",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentInsertBranchView = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { description, users } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "top" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: description.name,
                            label: description.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(description.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(description.label),
                            disabled: isDisable[description.name] || false,
                            mode: "multiple",
                        },
                        fnChange: (event) => handleChange(event.target.value, description.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: users.name,
                            label: users.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(users.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(users.label),
                            disabled: isDisable[users.name] || false,
                            mode: "multiple",
                        },
                        fnChange: (value) => handleChange(value, users.name),
                        dropdown: options[users.name],
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentUpdateBranchView = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { flowId, description, users } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: flowId.name,
                            label: flowId.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(flowId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(flowId.label),
                            disabled: true,
                        },
                        fnChange: (event) => handleChange(event.target.value, flowId.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: description.name,
                            label: description.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(description.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(description.label),
                            disabled: isDisable[description.name] || false,
                            mode: "multiple",
                        },
                        fnChange: (event) => handleChange(event.target.value, description.name),
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: users.name,
                            label: users.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(users.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(users.label),
                            disabled: isDisable[users.name] || false,
                            mode: "multiple",
                        },
                        fnChange: (value) => handleChange(value, users.name),
                        dropdown: options[users.name],
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}
