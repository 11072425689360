import React, { Fragment } from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { loadingPage } from "../components/loadingPage";
import { Button, Form, FormGroup, Label, Container } from "reactstrap";
import {
  Row as AntdRow,
  Col as AntdCol,
  Card as AntdCard,
  Space,
  Modal,
} from "antd";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Select, Spin, Tabs } from "antd";
import { Link } from "react-router";
import {
  FcBusinessman,
  FcCellPhone,
  FcCollaboration,
  FcCancel,
} from "react-icons/fc";
import { GET, GET_USER_IN, GET_USER_OUT, GET_BEARER } from "../service";
import {
  filterSearchColumn,
  GET_UserLogin,
} from "../screens/functions/UseFunction";
import LayoutTable from "../components/structuresReport/layoutTable";
import {
  Sub_Department,
  USE_CONNECT_MODE_AUTO,
} from "../screens/services/ConnectAPI";
import { useButton } from "../screens/conponents/buttons/CustomButtonAntd";

const { TabPane } = Tabs;

class DashboardAssetEmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newEmpList: [],
      quitEmpList: [],
      resultDepList: [],
      depNo: "",
      done: true,
      userLogin: this.props.userLogin,
      premission: this.props.premission,
      EmpID: "",
      Name: "",
      ActiveTab: "NEW",
      loading: false,
    };
  }

  componentDidMount() {
    this.search();
    this.getOptionDepartment();
  }

  changeTab = async (activeKey) => {
    await this.setState({
      ActiveTab: activeKey,
    });

    if (activeKey === "QUIT") {
      return this.searchOut();
    }
    this.search();
  };

  setValue = (name, value) => {
    this.setState({ [name]: value });
  };

  search = async () => {
    try {
      this.setState({ loading: true });
      const res = await GET_BEARER(GET_USER_IN(this.state.depNo));
      const { result, success } = res;
      let mapResult = [];
      if (success) {
        mapResult = result.data.map((row, key) => ({
          key: key + 1,
          ...row,
        }));
        mapResult.map((e) => {
          e.BossInfo = (
            <React.Fragment>
              <FcBusinessman className="margin-icon" size={20} />
              {e.bossFullname}
              <br />
              <FcCellPhone className="margin-icon" size={20} />
              {e.bossTel}
            </React.Fragment>
          );
        });
        this.setState({ loading: false });
      }
      this.setState({ newEmpList: mapResult, loading: false });
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด" });
    } finally {
      this.setState({ loading: false });
    }
  }; // end func Search

  searchOut = async () => {
    try {
      this.setState({ loading: true });
      const res = await GET_BEARER(GET_USER_OUT(this.state.depNo));
      const { result, success } = res;
      let mapResult = [];
      if (success) {
        mapResult = result.data.map((row, key) => ({
          key: key + 1,
          ...row,
        }));
        mapResult.map((e) => {
          e.BossInfo = (
            <React.Fragment>
              <FcBusinessman className="margin-icon" size={20} />
              {e.bossFullname}
              <br />
              <FcCellPhone className="margin-icon" size={20} />
              {e.bossTel}
            </React.Fragment>
          );
        });
        this.setState({ loading: false });
      }
      this.setState({ quitEmpList: mapResult, loading: false });
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด" });
    } finally {
      this.setState({ loading: false });
    }
  }; // end func Search

  onChange = (e) => {
    this.setState({ depNo: e });
    console.log("e.target.value", e);
  };

  async getOptionDepartment() {
    const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Department}`;
    const res = await GET(url);
    const { result } = res;
    let resultDepList = result.map((val, index) => {
      return { value: val.depNo, label: `[${val.depNo}] : ${val.depName}` };
    });
    this.setState({ resultDepList: resultDepList });
  }

  render() {
    const newEmpList = this.state.newEmpList;
    const quitEmpList = this.state.quitEmpList;

    const columnIn = () => [
      {
        dataIndex: "key",
        title: "#",
        width: 50,
        fixed: "",
        align: "center",
        ...filterSearchColumn("key", "#"),
      },
      {
        title: "สถานะ",
        width: 150,
        fixed: "",
        align: "center",
        ...filterSearchColumn("transStatus", "สถานะ"),
        render: (text, record) => {
          const { ActiveTab } = this.state;
          return ActiveTab == "NEW" ? (
            <div>
              <Button color={"warning"}>
                <Link
                  target="_self"
                  to={`/Assets_NewEmpForm?userId=${record.userId}`}
                >
                  <b style={{ color: "#FFF", textAlign: "center" }}>
                    {"รอดำเนินการ"}
                  </b>
                </Link>
              </Button>
            </div>
          ) : (
            <div>
              <Button color={"warning"}>
                <Link
                  target="_self"
                  to={`/Assets_QuitEmpForm?userId=${record.userId}`}
                >
                  <b style={{ color: "#FFF", textAlign: "center" }}>
                    {"รอดำเนินการ"}
                  </b>
                </Link>
              </Button>
            </div>
          );
        },
      },
      {
        dataIndex: "userId",
        title: "รหัสพนักงาน",
        width: 100,
        fixed: "",
        align: "center",
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
        render: (text) => {
          return (
            <Fragment>
              <b>{text}</b>
            </Fragment>
          );
        },
      },
      {
        dataIndex: "fullname",
        title: "ชื่อ - สกุล",
        width: 150,
        fixed: "",
        align: "",
        ...filterSearchColumn("fullname", "ชื่อ - สกุล"),
      },
      {
        dataIndex: "levelWorker",
        title: "ระดับ",
        width: 50,
        fixed: "",
        align: "center",
        ...filterSearchColumn("levelWorker", "ระดับ"),
      },
      {
        dataIndex: "nickname",
        title: "ชื่อเล่น",
        width: 100,
        fixed: "",
        align: "",
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
      },
      {
        dataIndex: "depName",
        title: "แผนก",
        width: 150,
        fixed: "",
        align: "",
        ...filterSearchColumn("depName", "แผนก"),
      },
      {
        dataIndex: "BossInfo",
        title: "หัวหน้างาน",
        width: 200,
        fixed: "",
        align: "",
        ...filterSearchColumn("BossInfo", "หัวหน้างาน"),
      },
      {
        dataIndex: "createDate",
        title: "วันที่แจ้งเข้า",
        width: 150,
        fixed: "",
        align: "center",
        ...filterSearchColumn("createDate", "วันที่แจ้งเข้า"),
      },
      {
        dataIndex: "startDateJob",
        title: "วันที่เริ่มงาน",
        width: 100,
        fixed: "",
        align: "center",
        ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
      },
    ];

    const columnQuit = () => [
      {
        dataIndex: "key",
        title: "#",
        width: 50,
        fixed: "",
        align: "center",
        ...filterSearchColumn("key", "#"),
      },
      {
        title: "สถานะ",
        width: 150,
        fixed: "",
        align: "center",
        ...filterSearchColumn("transStatus", "สถานะ"),
        render: (text, record) => {
          const { ActiveTab } = this.state;
          return ActiveTab == "NEW" ? (
            <div>
              <Button color={"warning"}>
                <Link
                  target="_self"
                  to={`/Assets_NewEmpForm?userId=${record.userId}`}
                >
                  <b style={{ color: "#FFF", textAlign: "center" }}>
                    {"รอดำเนินการ"}
                  </b>
                </Link>
              </Button>
            </div>
          ) : (
            <div>
              <Button color={"warning"}>
                <Link
                  target="_self"
                  to={`/Assets_QuitEmpForm?userId=${record.userId}`}
                >
                  <b style={{ color: "#FFF", textAlign: "center" }}>
                    {"รอดำเนินการ"}
                  </b>
                </Link>
              </Button>
            </div>
          );
        },
      },
      {
        dataIndex: "userId",
        title: "รหัสพนักงาน",
        width: 100,
        fixed: "",
        align: "center",
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
        render: (text) => {
          return (
            <Fragment>
              <b>{text}</b>
            </Fragment>
          );
        },
      },
      {
        dataIndex: "fullname",
        title: "ชื่อ - สกุล",
        width: 150,
        fixed: "",
        align: "",
        ...filterSearchColumn("fullname", "ชื่อ - สกุล"),
      },
      {
        dataIndex: "levelWorker",
        title: "ระดับ",
        width: 50,
        fixed: "",
        align: "center",
        ...filterSearchColumn("levelWorker", "ระดับ"),
      },
      {
        dataIndex: "nickname",
        title: "ชื่อเล่น",
        width: 100,
        fixed: "",
        align: "",
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
      },
      {
        dataIndex: "depName",
        title: "แผนก",
        width: 150,
        fixed: "",
        align: "",
        ...filterSearchColumn("depName", "แผนก"),
      },
      {
        dataIndex: "BossInfo",
        title: "หัวหน้างาน",
        width: 200,
        fixed: "",
        align: "",
        ...filterSearchColumn("BossInfo", "หัวหน้างาน"),
      },
      {
        dataIndex: "createDate",
        title: "วันที่แจ้งออก",
        width: 150,
        fixed: "",
        align: "center",
        ...filterSearchColumn("createDate", "วันที่แจ้งออก"),
      },
      {
        dataIndex: "endDateJob",
        title: "วันที่ออกงาน",
        width: 100,
        fixed: "",
        align: "center",
        ...filterSearchColumn("endDateJob", "วันที่ออกงาน"),
      },
    ];

    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );

    return (
      <Container>
        {this.state.done ? (
          <div>
            <AntdCard
              title={"รายการพนักงานเข้า-ออก"}
              extra={
                <Space>
                  {useButton({
                    call: "clickSearch",
                    fn:
                      this.state.ActiveTab === "NEW"
                        ? this.search
                        : this.searchOut,
                  })}
                </Space>
              }
            >
              <Form>
                <AntdRow>
                  <AntdCol span={8}>
                    <FormGroup>
                      <Label>แผนก: </Label>
                      <Select
                        showSearch
                        allowClear
                        style={{
                          width: 350,
                          paddingLeft: "20px",
                        }}
                        onChange={this.onChange}
                        options={this.state.resultDepList}
                      ></Select>
                    </FormGroup>
                  </AntdCol>
                </AntdRow>
              </Form>
            </AntdCard>
            <br />
            <AntdCard>
              <Tabs activeKey={this.state.ActiveTab} onChange={this.changeTab}>
                <TabPane
                  tab={
                    <React.Fragment>
                      <FcCollaboration className="margin-icon" size={20} />
                      พนักงานเข้าใหม่
                    </React.Fragment>
                  }
                  key="NEW"
                >
                  <Spin spinning={this.state.loading}>
                    <LayoutTable
                      bordered
                      dataSource={newEmpList}
                      columns={columnIn()}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "40", "50"],
                      }}
                    />
                  </Spin>
                </TabPane>

                {/* <TabPane
                  tab={
                    <React.Fragment>
                      <FcCancel className="margin-icon" size={20} />
                      พนักงานลาออก
                    </React.Fragment>
                  }
                  key="QUIT"
                >
                  <Spin spinning={this.state.loading}>
                    <LayoutTable
                      bordered
                      dataSource={quitEmpList}
                      columns={columnQuit()}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "40", "50"],
                      }}
                    />
                  </Spin>
                </TabPane> */}
              </Tabs>
            </AntdCard>
          </div>
        ) : (
          <div>{loadingPage} </div>
        )}
      </Container>
    );
  }
}
export default DashboardAssetEmp;
