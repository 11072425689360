export * from "./ButtonIcon";
export * from "./ButtonTheme";
export * from "./buttonExport.jsx";
export * from "./buttonView.jsx";
export * from "./buttonUpdate.jsx";
export * from "./buttonInsert.jsx";

export const TEXT_BUTTON = {
  SEARCH: "ค้นหา",
  REMOVE: "ลบ",
  INSERT: "สร้าง",
  IMPORT: "Import",
  UPDATE: "แก้ไข",
  VIEW: "ดูรายละเอียด",
  SUBMIT: "บันทึก",
  DRAFT: "บันทึกร่าง",
  PRINT: "พิมพ์เอกสาร",
  BACK: "ย้อนกลับ",
  DOWNLOAD: "ดาวน์โหลด",
  CONFIRM: "ยืนยันข้อมูล (แก้ไขไม่ได้)",
  ADD_LISTS: "เพิ่มหัวข้อ",
  EXPORT_EXCEL: "Export Excel",
  INSERT_SIGNATURE: "เซ็นต์",
  VIEW_SIGNATURE: "ดู",
  RESET: "รีเซ็ต",
  CANCEL: "ยกเลิก",
  VERIFY_USER: "ตรวจสอบข้อมูล",
  EXPORT_INFO_EMP: "Export Prosoft",
  EXPORT_INFO_EMP_TIGER: "Export Tiger Soft",
  BLOCK_USER: "แจ้งพ้นสภาพ",
  DOWNLOAD_TEMPLATE: "Download Template",
  EXPORT: "EXPORT",
  IMPORT_OJT: "IMPORT",
};

export const FIELD_BUTTON = {
  /*** Start Module Training */

  SEARCH_DASHBOARD_TRAINING: "SEARCH_DASHBOARD_TRAINING",
  INSERT_TRAINING: "INSERT_TRAINING",
  UPDATE_TRAINING: "UPDATE_TRAINING",
  VIEW_TRAINING: "VIEW_TRAINING",
  DRAFT_TRAINING: "DRAFT_TRAINING",
  CONFIRM_TRAINING: "CONFIRM_TRAINING",
  EXPORT_OJT: "EXPORT_OJT",

  SUBMIT_TRAINING: "SUBMIT_TRAINING",
  SUBMIT_REVIEWER: "SUBMIT_REVIEWER",
  SUBMIT_EVALUTOR: "SUBMIT_EVALUTOR",

  PRINT_REPORT: "PRINT_REPORT",
  PRINT_INDIVIDUAL_REPORT: "PRINT_INDIVIDUAL_REPORT",

  UPDATE_SIGNATURE: "UPDATE_SIGNATURE",
  INSERT_SIGNATURE: "INSERT_SIGNATURE",
  RESET_SIGNATURE: "RESET_SIGNATURE",

  /*** Start Module Probation Contract */
  INSERT_CONTRACT: "INSERT_CONTRACT",
  INSERT_BENEFIT_CONTRACT: "INSERT_BENEFIT_CONTRACT",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",
  CONFIRM_CONTRACT: "CONFIRM_CONTRACT",
  CONFIRM_RECEIVE_CONTRACT: "CONFIRM_RECEIVE_CONTRACT",
  VIEW_CONTRACT: "VIEW_CONTRACT",
};

export const ID_REPORT = {
  TRAINING: "TRAINING",
  INDIVIDUAL: "INDIVIDUAL",
  TRAINERS: "TRAINERS",
};

export const TEXT_BUTTON_CUSTOM = ({ fieldBtn, status }) => {
  switch (fieldBtn) {
    case FIELD_BUTTON.SUBMIT_REVIEWER:
    case FIELD_BUTTON.SUBMIT_EVALUTOR:
    case FIELD_BUTTON.SUBMIT_TRAINING:
      if (status) {
        return TEXT_BUTTON.CONFIRM;
      } else {
        return TEXT_BUTTON.DRAFT;
      }
    default:
      console.error("FIELD NAME NOT FOUND");
      break;
  }
};
