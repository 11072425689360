import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";

export const ColumnReportResignRate = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickname",
        key: "nickname",
        align: "left",
        width: 100,
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionNo",
        key: "positionNo",
        align: "left",
        width: 300,
        ...filterSearchColumn("positionNo", "ตำแหน่งงาน"),
    },
    {
        title: "บริษัท",
        dataIndex: "Company",
        key: "Company",
        align: "left",
        width: 150,
        ...filterSearchColumn("Company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorkerDes",
        key: "statusWorkerDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "StartDateJob",
        key: "StartDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "วันที่สิ้นสุดการทำงาน",
        dataIndex: "EndDateJob",
        key: "EndDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("EndDateJob", "วันที่สิ้นสุดการทำงาน"),
    },
    {
        title: "เหตุผลการลาออก",
        dataIndex: "reasonLeaveDes",
        key: "reasonLeaveDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("reasonLeaveDes", "เหตุผลการลาออก"),
    },
    {
        title: "อายุงาน (ปี.เดือน)",
        dataIndex: "workYears",
        key: "workYears",
        align: "right",
        width: 150,
        ...useSorterTable("workYears", "number")
    },
    {
        title: "เกรดประเมินผลงานรอบล่าสุด",
        dataIndex: "probationGrade",
        key: "probationGrade",
        align: "right",
        width: 200,
        ...filterSearchColumn("probationGrade", "เกรดประเมินผลงานรอบล่าสุด"),
    },
    {
        title: "เกรดทดลองงาน",
        dataIndex: "grade",
        key: "grade",
        align: "right",
        width: 200,
        ...filterSearchColumn("grade", "เกรดทดลองงาน"),
    },
];
