export const useFormState = () => {
  const defaultValues = {
    company: "all",
    empID: "all",
    cerName: [],
  };

  return {
    defaultValues,
  };
};
