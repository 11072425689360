import React from "react";
import { useText } from "../../conponents/typography/Typography";
import {
  filterSearchColumn,
  useSorterTable,
} from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";
import { Button } from "antd";

export const ColumnSaleCodeAreaAssignment = ({ action }) => [
  {
    title: "ลำดับ",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
    ...filterSearchColumn("key", "ลำดับ"),
  },
  {
    title: "รหัสเซลล์/สาขา",
    dataIndex: "roleId",
    key: "roleId",
    align: "center",
    width: 200,
    ...filterSearchColumn("roleId", "รหัสเซลล์/สาขา"),
  },
  {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "center",
    width: 10,
    render: (text, record) => {
      return (
        <Button type="primary" onClick={() => action(record)}>
          แก้ไขพื้นที่ยอดขาย
        </Button>
      );
    },
  },
];

export const ColumnAreaCodeFSP = [
  {
    title: "พื้นที่ยอดขาย",
    dataIndex: "childCode",
    key: "childCode",
    align: "center",
    width: 200,
    ...filterSearchColumn("childCode", "พื้นที่ยอดขาย"),
  },
];
