import React, { createContext, useReducer, } from "react";
import { convertArray } from "../../../constants/functions";
import { FILED_OPTION, MASTER_OPTION, servicesOptions } from "../../../moduleKPIs/services/servicesOptions";
import { APISelectOptionFlow, APISelectOptionMentor, APISelectOptionSubDep, APISelectOptionTrainers, APISelectOptionTraining, APISelectOptionTrainingCourse, APISelectOptionUserTraining } from "./masterTrainingConnect";


export const MasterTrainingContext = createContext();

const initialState = {
    [MASTER_OPTION.OPTION_EVALUATION_METHOD]: [],
    [MASTER_OPTION.OPTION_TRAINING_RESULT]: [],
    [MASTER_OPTION.OPTION_LEARNING_RESULT]: [],
    [MASTER_OPTION.OPTION_TRAINING_STATUS]: [],
    [MASTER_OPTION.OPTION_TRAINERS]: [],
    [MASTER_OPTION.OPTION_SUPERVISORS]: [],
    [MASTER_OPTION.OPTION_TYPE_STAFF]: [],
    [MASTER_OPTION.OPTION_TRAINING_TYPE]: [],
    [MASTER_OPTION.OPTION_TRAINING_GROUP]: [],
    [MASTER_OPTION.OPTION_TRAINING_METHOD]: [],
    [MASTER_OPTION.OPTION_TRAINING_COURSE]: [],
    [MASTER_OPTION.OPTION_USER_OJT]: [],

    // [MASTER_OPTION.OPTION_DEP]: [],
    // [MASTER_OPTION.FILTER_DEP]: [],
    [MASTER_OPTION.OPTION_SUBDEP]: [],
    // [MASTER_OPTION.FILTER_SUBDEP]: [],
    [MASTER_OPTION.OPTION_MENTOR]: [],
    // [MASTER_OPTION.OPTION_SUPERVISORS]: [],

    [`SEARCH_${MASTER_OPTION.OPTION_TRAINING_GROUP}`]: ' ',
    [`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`]: ' ',
    [`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`]: ' ',
    [`SEARCH_${MASTER_OPTION.RANG_DATE}`]: ['', ''],
    [`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`]: ' ',
    [`SEARCH_${MASTER_OPTION.OPTION_TRAINING_STATUS}`]: ' ',
}

const MASTER_TRAINING_ACTION = {
    SET_MASTER_TRAINING_SUCCESS: "SET_MASTER_TRAINING_SUCCESS",
    SET_MASTER_TRAINING_FAILURE: "SET_MASTER_TRAINING_FAILURE"
}

const masterTrainingReducer = (state, action) => {
    switch (action.type) {
        case MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS:
            return { ...state, ...action.state }
        case MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_FAILURE:
        default:
            return state
    }
}

const ProviderMasterTraining = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [masterTrainingState, masterTrainingDispatch] = useReducer(masterTrainingReducer, initialState)

    async function getMaster({ fieldname, payload }) {
        // console.log({ fieldname, payload })
        // let _flowHRM = []
        // let _subDep = []
        // let _dep = []
        // await APISelectOptionFlow({ userId: userLogin },
        //     ({ resultDep, resultFlowHRM, resultsubDep }) => {
        //         _dep = convertArray('depNo', 'string', resultDep)
        //         _flowHRM = convertArray('idFlow', 'string', resultFlowHRM)
        //         _subDep = convertArray('subDepNo', 'string', resultsubDep)
        // })
        // console.log({ _flowHRM, _subDep, _dep })
        switch (fieldname) {
            case MASTER_OPTION.OPTION_EVALUATION_METHOD:
            case MASTER_OPTION.OPTION_TRAINING_METHOD:
            case MASTER_OPTION.OPTION_LEARNING_RESULT:
            case MASTER_OPTION.OPTION_TRAINING_RESULT:
            case MASTER_OPTION.OPTION_TRAINING_STATUS:
            case MASTER_OPTION.OPTION_TRAINING_GROUP:
            case MASTER_OPTION.OPTION_TRAINING_TYPE:
            case MASTER_OPTION.OPTION_TYPE_STAFF:
            case MASTER_OPTION.OPTION_TRAINING:
                switch (payload.type) {
                    case FILED_OPTION.ALL:
                        await APISelectOptionTraining(payload, async (response) => {
                            if (response) {
                                await masterTrainingDispatch({
                                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                    state: {
                                        [MASTER_OPTION.OPTION_EVALUATION_METHOD]: servicesOptions(MASTER_OPTION.OPTION_EVALUATION_METHOD, response[FILED_OPTION.OPTION_EVALUATION_METHOD]),
                                        [MASTER_OPTION.OPTION_TRAINING_METHOD]: servicesOptions(MASTER_OPTION.OPTION_TRAINING_METHOD, response[FILED_OPTION.OPTION_TRAINING_METHOD]),
                                        [MASTER_OPTION.OPTION_TRAINING_RESULT]: servicesOptions(MASTER_OPTION.OPTION_TRAINING_RESULT, response[FILED_OPTION.OPTION_TRAINING_RESULT]),
                                        [MASTER_OPTION.OPTION_LEARNING_RESULT]: servicesOptions(MASTER_OPTION.OPTION_LEARNING_RESULT, response[FILED_OPTION.OPTION_LEARNING_RESULT]),
                                        [MASTER_OPTION.OPTION_TYPE_STAFF]: servicesOptions(MASTER_OPTION.OPTION_TYPE_STAFF, response[FILED_OPTION.OPTION_TYPE_STAFF]),
                                        [MASTER_OPTION.OPTION_TRAINING_GROUP]: servicesOptions(MASTER_OPTION.OPTION_TRAINING_GROUP, response[FILED_OPTION.OPTION_TRAINING_GROUP]),
                                        [MASTER_OPTION.OPTION_TRAINING_TYPE]: servicesOptions(MASTER_OPTION.OPTION_TRAINING_TYPE, response[FILED_OPTION.OPTION_TRAINING_TYPE]),
                                    }
                                })
                            }
                        })
                        break;
                    case FILED_OPTION.OPTION_TRAINING_GROUP:
                    case FILED_OPTION.OPTION_TRAINING_STATUS:
                        await APISelectOptionTraining(payload, async (response) => {
                            if (response) {
                                await masterTrainingDispatch({
                                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(fieldname, response[payload.type], true),
                                    }
                                })
                            }
                        })
                        break;
                    default:
                        await APISelectOptionTraining(payload, async (response) => {
                            if (response) {
                                await masterTrainingDispatch({
                                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(fieldname, response[payload.type]),
                                    }
                                })
                            }
                        })
                        break;

                }
                break;
            case MASTER_OPTION.OPTION_SUBDEP:
                switch (userPermission) {
                    // case 'HR':
                    // case 'HR-HeadDep':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'Intern':
                    // case 'HR-Super':
                    case 'HRS-OFF':
                    case 'HRD-OFF':
                    case 'HRM-OFF':
                    case 'HRS-FAC':
                    case 'HRD-FAC':
                    case 'HRM-FAC':
                        // let options = await APISelectOptionSubDep({ subDepNo: '' })
                        // if (options) {
                        //     masterTrainingDispatch({
                        //         type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                        //         state: {
                        //             [fieldname]: servicesOptions(MASTER_OPTION[fieldname], options, true)
                        //         }
                        //     })
                        //     return true
                        // }

                        await APISelectOptionSubDep({ subDepNo: '' }, (response) => {
                            if (response) {
                                masterTrainingDispatch({
                                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response, true)
                                    }
                                })
                            }
                        })
                        break;
                    default:
                        await APISelectOptionFlow({ userId: userLogin }, async ({ resultsubDep }) => {
                            await masterTrainingDispatch({
                                type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                state: {
                                    [fieldname]: servicesOptions(MASTER_OPTION[fieldname], convertArray('subDepNo', 'object', resultsubDep), true)
                                }
                            })
                            return true
                        })
                }
                break;

            case MASTER_OPTION.OPTION_MENTOR:
                await APISelectOptionMentor(payload, async (response) => {
                    if (response) {
                        await masterTrainingDispatch({
                            type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                            state: {
                                [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response.result)
                            }
                        })
                    }
                })
                break;
            case MASTER_OPTION.SELECT_OPTION_TRAINING:
                await APISelectOptionTraining(null, async (response) => {
                    if (response) {
                        await masterTrainingDispatch({
                            type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                            state: {
                                [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response)
                            }
                        })

                    }
                })
                break;
            case MASTER_OPTION.OPTION_TRAINERS:
                await APISelectOptionTrainers(null, async (response) => {
                    if (response) {
                        await masterTrainingDispatch({
                            type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                            state: {
                                [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response)
                            }
                        })
                    }
                })
                break;
            case MASTER_OPTION.OPTION_USER_TRAINING:
                await APISelectOptionUserTraining(null, async (response) => {
                    if (response) {
                        await masterTrainingDispatch({
                            type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                            state: {
                                [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response, true)
                            }
                        })
                    }
                })
                break;
            case MASTER_OPTION.OPTION_TRAINING_COURSE:
                await APISelectOptionTrainingCourse({ trainingNo: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`] },
                    async (response) => {
                        if (response) {
                            await masterTrainingDispatch({
                                type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                                state: {
                                    [fieldname]: servicesOptions(MASTER_OPTION[fieldname], response, true)
                                }
                            })
                        }
                    })
                break;

            default:
                break;
        }
    }


    async function onChangeMaster({ fieldOption, checkedList, checkAll, plainOptions }) {
        //console.log({ fieldOption, checkedList, checkAll, plainOptions })

        switch (fieldOption) {
            case MASTER_OPTION.RANG_DATE:
            case MASTER_OPTION.OPTION_SUBDEP:
            case MASTER_OPTION.OPTION_USER_TRAINING:
            case MASTER_OPTION.OPTION_TRAINING_COURSE:
            case MASTER_OPTION.OPTION_TRAINING_STATUS:
            case MASTER_OPTION.OPTION_TRAINING_GROUP:
                /****  RETURN Sting or Number **/
                await masterTrainingDispatch({
                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                    state: { [`SEARCH_${fieldOption}`]: checkedList[0] }
                })
                break;
            default:
                /****  RETURN Array **/
                await masterTrainingDispatch({
                    type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
                    state: { [`SEARCH_${fieldOption}`]: (checkAll) ? plainOptions : checkedList }
                })
                break;
        }

    }
    async function clearMaster({ fieldOption }) {
        await masterTrainingDispatch({
            type: MASTER_TRAINING_ACTION.SET_MASTER_TRAINING_SUCCESS,
            state: { [`SEARCH_${fieldOption}`]: [] }
        })
    }

    return (
        <MasterTrainingContext.Provider
            value={{
                onChangeMaster,
                masterTrainingState,
                getMaster,
                clearMaster,
                masterTrainingDispatch
            }}>
            {children}
        </MasterTrainingContext.Provider>
    )
}


export default ProviderMasterTraining