import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { loadingPage } from "../components/loadingPage";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import { Tabs, Modal, Table } from "antd";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { FiXCircle, FiEdit, FiSend, FiChevronRight } from "react-icons/fi";
import paginationFactory from "react-bootstrap-table2-paginator";
import encodeString from "../extendFunction/encodeString";
import { getCurrent } from "../extendFunction/getCurrentYears";
import {
  RenderDepartmentPMS,
  RenderStatusEvaluatePMS,
  RenderTypeEvaluatePMS,
  RenderYearPMS,
} from "../modulePMS/componentsPMS/dropdown";
import { ColumnSelfAssessmentNew } from "../modulePMS/constantsPMS/columns";
import { tab_self } from "../modulePMS/constantsPMS";
import {
  DEL_SELF_ASSESSMENT,
  GET,
  POST,
  POST_SELECT_DATA_SELF_ASSESSMENT,
  SELECT_DATA_DASHBOARD,
} from "../service";

const { TabPane } = Tabs;
class DashboardSelfAssessment extends React.Component {
  constructor(props) {
    super(props);
    const { yearsSelect, typeSelect } = getCurrent();
    const { userLogin, premission } = props;
    this.state = {
      showDropdown_option: "",
      loadData: [],
      typeTXt: typeSelect,
      SegmentTxt: "all",
      yearsTxt: yearsSelect,
      years: yearsSelect,
      type: typeSelect,
      statusCode: "all",
      done: false, // true | false
      loadDataView: [],
      userLogin,
      premission,
    };
  }

  onLoadFinish = () => {
    this.setState({ done: true });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openEditSAF = (e) => {
    var docNo = encodeString.encode(e.target.id);
    window.open("/UpdateSelfAssessmentForm/" + docNo);
  };

  openPrintSAF = (e) => {
    const docNo = encodeString.encode(e.target.id);
    window.open("/PrintSelfAssessment/" + docNo);
  };

  openCreateSAF = (e) => {
    window.open("/SelfAssessmentForm");
  };

  search = async () => {
    try {
      if (!this.state.done) {
        return;
      }

      const { typeTXt: type, SegmentTxt: segment, yearsTxt: year, statusCode, userLogin } = this.state;

      this.setState({ loadData: [], loadDataView: [] });
      if (year !== "0" && type !== "0") {
        this.setState({ done: false });
      }

      // const object = {
      //   type,
      //   segment,
      //   year,
      //   statusCode,
      //   userLogin,
      // };

      // const res = await GET(SELECT_DATA_DASHBOARD(object));
      // const { data, dataView } = res;
      // this.setState({ loadData: data, loadDataView: dataView, done: true });

      const object = {
        type,
        SegmentTxt: segment,
        years: year,
        statusCode,
        userID: userLogin,
      };

      const res = await POST(POST_SELECT_DATA_SELF_ASSESSMENT, object);
      const { result } = res
      const { data, dataView } = result;
      this.setState({ loadData: data, loadDataView: dataView, done: true });

    } catch (error) {
      console.log(error);
    }
  };

  delRow = async (event) => {
    try {
      const r = window.confirm("ต้องการที่จะลบบรรทัดนี้ใช่หรือไม่!!");
      if (!r) {
        return;
      }
      const docNo = event.target.id;
      const res = await POST(DEL_SELF_ASSESSMENT, { docNo });
      const { status_api } = res.result;
      if (status_api === 1) {
        // มีนอกเหนือจาก 1 [0, 1, 500]
        Modal.success({ title: "Del Success!!" });
        window.location.reload();
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { userLogin } = this.state;
    return (
      <Container>
        <div>
          <Card>
            <CardHeader>Dashboard Self Assessment</CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md={2}>
                    <RenderYearPMS onChange={this.onChange} />
                  </Col>
                  <Col md={3}>
                    <RenderTypeEvaluatePMS onChange={this.onChange} />
                  </Col>
                  <Col md={3}>
                    <RenderDepartmentPMS
                      userLogin={userLogin}
                      onChange={this.onChange}
                      onLoadFinish={this.onLoadFinish}
                    />
                  </Col>
                  <Col md={2}>
                    <RenderStatusEvaluatePMS onChange={this.onChange} />
                  </Col>
                  <Col md={2} className="text-center cs_btn">
                    <FormGroup>
                      <Button color="info" onClick={this.search}>
                        SEARCH
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>กระบวนในการประเมินตนเอง</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      &nbsp; &nbsp;
                      <FiXCircle
                        className="margin-icon"
                        color="#FF5733"
                        size={20}
                      />
                      ยังไม่ทำแบบประเมิน
                      <FiChevronRight className="margin-icon" size={20} />
                      <FiEdit
                        className="margin-icon"
                        color="#95A5A6"
                        size={20}
                      />
                      รอยืนยันการประเมิน (แบบร่าง)
                      <FiChevronRight className="margin-icon" size={20} />
                      <FiSend
                        className="margin-icon"
                        color="#28B463"
                        size={20}
                      />
                      จบการประเมิน
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    **หมายเหตุ -
                    สามารถคลิกที่รหัสพนักงานเพื่อเรียกดูรูปพนักงานได้
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {this.state.done ? this._renderTable() : <div>{loadingPage} </div>}
        </div>
      </Container>
    );
  }

  _renderTable = () => {
    const { userLogin } = this.state;

    return (
      <Tabs defaultActiveKey="1">
        {tab_self.map((e, i) => {
          return (
            <TabPane tab={`${e.tab_label}`} key={`${i + 1}`}>
              <Table
                  key={`${e.key_field}${i + 1}`}
                  columns={ColumnSelfAssessmentNew({
                    userLogin,
                    openPrintSAF: this.openPrintSAF,
                    openEditSAF: this.openEditSAF,
                    openCreateSAF: this.openCreateSAF,
                    delRow: this.delRow,
                  })} 
                  
                  dataSource={this.state[e.tab_ref]}
              
              />
            </TabPane>
          );
        })}
      </Tabs>
    );
  };
}

export default DashboardSelfAssessment;
