import styled from "styled-components";
import { Card } from "antd";

export const DocumentDetail = styled(Card)`
  border: 1px solid #c3c3c3;
`;

export const FormPrintContainer = styled("div")`
  width: 100%;
  border-radius: 4px;
  margin: 8px;
`;


