import React from "react";
import { useForm } from "react-hook-form";
import { Spin, Card, Collapse } from "antd";
import { useCollapseState } from "./hook/useCollapseState";
import { useGetOptions } from "./hook/useGetOptions";
import {
  ButtonTheme,
  ContainerButton,
} from "../../../components/global/buttons";
import GeneralDocumentDetail  from "./views/GeneralDocumentDetail";
import { FormPrint } from "./views/FormPrint";
import { useGetDetail } from "./hook/useGetDetail";
import { useGetFormPrint } from "./hook/useGetFormPrint";
import { PrinterOutlined } from "@ant-design/icons";

const FormContractDetailScene = () => {
  const { Panel } = Collapse;
  const { control, setValue, getValues, handleSubmit, setError } = useForm();
  const {
    optionsCompanyTH,
    optionsPositionTH,
    optionsDepartmentTH,
    optionsSigner,
    optionsDocType,
    loadingOption
  } = useGetOptions();
  const { loadingDocument } = useGetDetail({setValue})
  const { activeKey, setActiveKey } = useCollapseState();
  const { formPrint, loadingFormPrint, onGetFormPrint, onPrint, rawValue } = useGetFormPrint({ setError, setActiveKey });
  const printMain = async (value) => {
    onPrint(value, 'MAIN')
  }
  const printCopy = async (value) => {
    onPrint(value, 'COPY')
  }
 
  return (
    <Card title={"แบบฟอร์มสัญญาจ้าง"}>
      <Collapse
        activeKey={activeKey}
        onChange={(e) => {
          setActiveKey(() => e);
        }}
      >
        <Panel header="แก้ไขรายละเอียดทั่วไป" key="docDetailGeneral">
          <Spin spinning={loadingDocument && loadingOption} tip="กำลังโหลด">
            <GeneralDocumentDetail
              control={control}
              setValue={setValue}
              options={{
                optionsCompanyTH,
                optionsPositionTH,
                optionsDepartmentTH,
                optionsSigner,
                optionsDocType
              }}
              getValues={getValues}
            />
          </Spin>
          <ContainerButton center style={{ marginTop: "20px" }}>
              <ButtonTheme
                bgColor="grey"
                colorFill={false}
                title={"พรีวิวเอกสาร"}
                onClick={handleSubmit(onGetFormPrint)}
              />
              <ButtonTheme
                style={{ marginLeft: "20px" }}
                icon={
                      <PrinterOutlined 
                        style={{
                          verticalAlign: 'middle',
                        }}
                />
                }
                title={"ต้นฉบับ"}
                bgColor="#007bff"
                onClick={handleSubmit(printMain)}
              />
              <ButtonTheme
                style={{ marginLeft: "20px" }}
                icon={
                      <PrinterOutlined 
                      style={{
                        verticalAlign: 'middle',
                      }}
                />}
                title={"คู่ฉบับ"}
                bgColor="#007bff"
                onClick={handleSubmit(printCopy)}
              />
          </ContainerButton>
        </Panel>      

        <Panel header="เอกสาร" key="formPrint">
          { formPrint  && Object.keys(formPrint).length > 0 && (
            <Spin spinning={loadingFormPrint} tip="กำลังโหลด">
              {
                <FormPrint formData={formPrint} rawValue={rawValue} printMain={'MAIN'} printCopy={'COPY'} />
              }
            </Spin>
          )}
        </Panel> 
      </Collapse>
    </Card>
  );
};

export default FormContractDetailScene;
