import React from "react"
import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";
import { USE_COLORS } from "../../Colors";

const setCellColor = {
    render: (text) => {
        let setColor = ""
        if (text < 50) {
            setColor = USE_COLORS.percentFail;
        } else if (text < 60) {
            setColor = USE_COLORS.percentWaring;
        }
        else if (text > 59) {
            setColor = USE_COLORS.percentPass;
        }
        return {
            props: {
                style: { background: setColor }
            },
            children: <div>{text} %</div>
        };
    }
}

const scoreRecruiter = [
    {
        title: "1 คะแนน",
        dataIndex: "score1",
        key: "score1",
        align: "right",
        width: 200,
        ...useSorterTable("score1", "number"),
    },
    {
        title: "2 คะแนน",
        dataIndex: "score2",
        key: "score2",
        align: "right",
        width: 200,
        ...useSorterTable("score2", "number"),
    },
    {
        title: "3 คะแนน",
        dataIndex: "score3",
        key: "score3",
        align: "right",
        width: 200,
        ...useSorterTable("score3", "number"),
    },
    {
        title: "4 คะแนน",
        dataIndex: "score4",
        key: "score4",
        align: "right",
        width: 200,
        ...useSorterTable("score4", "number"),
    },
    {
        title: "5 คะแนน",
        dataIndex: "score5",
        key: "score5",
        align: "right",
        width: 200,
        ...useSorterTable("score5", "number"),
    },
    {
        title: "รวม",
        dataIndex: "grandTotal",
        key: "grandTotal",
        align: "right",
        width: 150,
        ...useSorterTable("grandTotal", "number"),
    },
    {
        title: "1 คะแนน(%)",
        dataIndex: "percent1",
        key: "percent1",
        align: "right",
        width: 150,
        ...useSorterTable("percent1", "number"),
        ...setCellColor,
    },
    {
        title: "2 คะแนน(%)",
        dataIndex: "percent2",
        key: "percent2",
        align: "right",
        width: 150,
        ...useSorterTable("percent2", "number"),
        ...setCellColor,
    },
    {
        title: "3 คะแนน(%)",
        dataIndex: "percent3",
        key: "percent3",
        align: "right",
        width: 150,
        ...useSorterTable("percent3", "number"),
        ...setCellColor,
    },
    {
        title: "4 คะแนน(%)",
        dataIndex: "percent4",
        key: "percent4",
        align: "right",
        width: 150,
        ...useSorterTable("percent4", "number"),
        ...setCellColor,
    },
    {
        title: "5 คะแนน(%)",
        dataIndex: "percent5",
        key: "percent5",
        align: "right",
        width: 150,
        ...useSorterTable("percent5", "number"),
        ...setCellColor,
    },

]

export const ColumnReportRecruiterScoreStatus = [
    {
        title: "สถานะจ้างงาน",
        dataIndex: "currentStatus",
        key: "currentStatus",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("currentStatus", "สถานะจ้างงาน"),
    },
    ...scoreRecruiter,
];

export const ColumnReportRecruiterScoreDept = [
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        fixed: "left",
        width: 300,
        ...filterSearchColumn("depName", "แผนก"),
    },
    ...scoreRecruiter,
];

export const ColumnReportRecruiterScoreUser = [
    {
        title: "รหัสพนักงาน (ผู้สรรหา)",
        dataIndex: "recruiterId",
        key: "recruiterId",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("recruiterId", "รหัสพนักงาน (ผู้สรรหา)"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterNickname",
        key: "recruiterNickname",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("recruiterNickname", "ผู้สรรหา"),
    },
    ...scoreRecruiter,
];
