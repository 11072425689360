import React from "react";
import { Button, Card, Col, Form, Row } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import { RenderInput } from ".";
import { colors } from "../../themes";

const LayoutListCards = React.forwardRef((element, ref) => {
  return (
    <Form.List
      ref={ref}
      name={element.name}
      initialValue={element.initialvalue}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, i) => (
            <Card
              key={field.key}
              size={element.size || "small"}
              title={element.header && element.header(i)}
              style={{ marginBottom: 15 }}
              extra={
                !element.disabled &&
                field.fieldKey > 0 && (
                  <DeleteFilled
                    style={{ color: "red", marginBottom: 10 }}
                    onClick={() => remove(field.name)}
                  />
                )
              }
            >
              <Row gutter={[24, 10]}>
                {element.content(field).map((con, idCon) => (
                  <Col key={`content_${idCon}`} {...con}>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        JSON.stringify(prevValues[element.name]) !==
                        JSON.stringify(curValues[element.name])
                      }
                      {...con.itemprops}
                    >
                      <RenderInput {...con.inputprops} />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Card>
          ))}
          {!element.disabled && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                shape="round"
                style={{
                  width: "15%",
                  marginTop: "20px",
                  backgroundColor: colors.theme,
                  borderColor: colors.theme,
                  ...styles,
                }}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {"เพิ่มข้อมูล"}
              </Button>
            </div>
          )}
        </>
      )}
    </Form.List>
  );
});

export default LayoutListCards;

/* -------------------------------------------------------------------------- */
/*                                    STYLE                                   */
/* -------------------------------------------------------------------------- */
const styles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
