import React, { useState } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { InputAnt } from "../inputs/InputAnt";
import { OJT, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";

export const FormAddTraining = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeAddValue, setChangeAddValue] = useState({});
  const [loadingScreen, setloadingScreen] = useState(false);

  let optionTrainingsGroup = useOptionSelect("trainGroup");
  let optionTrainingsType = useOptionSelect("trainType");
  let optionTrainingMethod = useOptionSelect("trainMethod");

  // [START] Handle Form Antd
  const onFinish = (values) => {
    if (!changeAddValue) return null
    const objectValue = {
      ...values,
      userlogin,
    };
    useConfirm({
      FnOK: () => POST_INSERT_Training(objectValue),
    });
  };
  const onReset = () => {
    form.resetFields();
    setChangeAddValue({});
  };
  // [END] Handle Form Antd

  // [START] REST API
  const POST_INSERT_Training = async (objectValue) => {
    try {
      setloadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${OJT.POST_INSERT_Training}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        setloadingScreen(true);
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setloadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Training: ", err);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    setChangeAddValue((prev) => ({
      ...prev,
      [name]: val,
    }));
  };
  // [END] Functions

  return (
    <>
      <Card title="เพิ่มข้อมูลหลักสูตรหลัก" style={{ marginBottom: 30 }}>
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={12} md={12} lg={8} xl={12}>
                <SelectAntd
                  name={`trainingGroup`}
                  label={`กลุ่มหลักสูตรหลัก`}
                  smgReq={`กรุณาเลือกกลุ่มหลักสูตรหลัก`}
                  placeholder={`เลือกกลุ่มหลักสูตรหลัก`}
                  dropdown={optionTrainingsGroup}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <SelectAntd
                  name={`trainingType`}
                  label={`ประเภทหลักสูตรหลัก`}
                  smgReq={`กรุณาเลือกประเภท หลักสูตรหลัก`}
                  placeholder={`เลือกประเภท หลักสูตรหลัก`}
                  dropdown={optionTrainingsType}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <InputAnt
                  name={`trainingNo`}
                  label={`รหัสหลักสูตรหลัก`}
                  smgReq={`กรุณากรอกรหัส หลักสูตรหลัก`}
                  placeholder={`กรอกรหัส หลักสูตรหลัก`}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={12}>
                <InputAnt
                  name={`trainingCourses`}
                  label={`ชื่อหัวข้อหลักสูตรหลัก`}
                  smgReq={`กรุณากรอกชื่อหัวข้อ หลักสูตรหลัก`}
                  placeholder={`กรอกชื่อหัวข้อ หลักสูตรหลัก`}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={3}>
                <InputAnt
                  name={`levelJG`}
                  label={`ระดับ JG`}
                  smgReq={`กรุณากรอกระดับ JG`}
                  placeholder={`กรอกระดับ JG`}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={3}>
                <InputAnt
                  name={`timePeriod`}
                  label={`ระยะเวลา`}
                  smgReq={`กรุณากรอก ระยะเวลา`}
                  placeholder={`กรอกรหัส ระยะเวลา`}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <SelectAntd
                  name={`trainingMethod`}
                  label={`Training Method`}
                  smgReq={`กรุณากรอก Training Method`}
                  placeholder={`กรอกรหัส Training Method`}
                  FnOnChange={onChangeValue}
                  dropdown={optionTrainingMethod}
                  required={true}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default FormAddTraining;
