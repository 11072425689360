import React from 'react'
import DashboardFollowEvac from '../../componentsEvac/dashboardFollowEvac'
import DashboardReviewer from '../../componentsEvac/dashboardReviewer'
import DashboardWaitEvac from '../../componentsEvac/dashboardWaitEvac'
import SummaryAvgScoreByLine from '../../componentsEvac/summaryAvgScoreByLine'
import SummaryReplyEvacByForm from '../../componentsEvac/summaryReplyEvacByForm'
import SummaryReplyEvacByDep from '../../componentsEvac/summaryReplyEvacByDep'



export const TABS_DASHBOARD_REVIEWER = ({ permission }) => {
	switch (permission) {
		default:
			return [{
				tab: 'แบบสอบถาม', content: <DashboardReviewer />
			}
				// , {
				//     tab: 'ทีมงาน', content: <DashboardReviewer />
				// }
			]
	}
}

export const TABS_DASHBOARD_EVAC = ({ permission }) => {
	switch (permission) {
		default:
			const remark = '*หมายเหตุ: ในกรณีที่ไม่พบข้อมูลให้ประเมิน เนื่องจากรอบนั้นๆ ยังไม่มีการส่งแบบสอบถามเพื่อให้ทำการประเมิน'
			return [
				{ tab: 'รอประเมิน', content: <DashboardWaitEvac />, extra: <div style={{ padding: '10px', color: 'red' }}>{remark}</div> },
				{ tab: 'ติดตามประเมิน', content: <DashboardFollowEvac />, extra: <div style={{ padding: '10px', color: 'red' }}>{remark}</div> },
				{ tab: 'รายงานสรุปการทำประเมิน (รายแผนก)', content: <SummaryReplyEvacByDep />, extra: <div style={{ padding: '10px', color: 'red' }}>{remark}</div> }
			]
	}
}

export const TABS_DASHBOARD_EVAC_BY_ADMIN = ({ userLogin }) => {
	// console.log({ userLogin })
	// switch (userLogin.userId) {
	//     case 'DP55012': //พี่แหม่ม
	//     case 'DP55096': //พี่ดิว
	//     case 'DP57007': //พี่ไนซ์
	//     case 'ZT58025': //พี่นัท
	//     case 'ZT62485': //เฟิน
	return [
		{ tab: 'รายงานสรุปผลประเมินภาพรวม (รายแบบประเมิน)', content: <SummaryReplyEvacByForm /> },
		{ tab: 'รายงานสรุปการทำประเมิน (รายแผนก)', content: <SummaryReplyEvacByDep /> },
	]
	//         break;
	//     default:
	//         return [
	//             { tab: 'รายงานสรุปผลประเมินภาพรวม (รายแบบประเมิน)', content: <SummaryReplyEvacByForm /> },
	//             // { tab: 'รายงานสรุปการทำประเมิน (รายแผนก)', content: <SummaryReplyEvacByDep /> },
	//         ]
	// }
}

export const TABS_DASHBOARD_EVAC_BY_DEP = ({ permission }) => {
	//console.log({ permission })
	switch (permission) {
		default:
			return [
				{ tab: 'รายงานสรุปผลประเมินภาพรวม (รายแผนก)', content: <SummaryAvgScoreByLine /> },
			]
	}
}
