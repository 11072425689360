import { useState } from "react";

export const useGetOptions = () => {
  const [loadingOption] = useState({
    company: false,
    departmentNo: false,
    employeeNo: false,
  });

  const optionStatus = [{ label: "รอยืนยัน", value: "WAIT", key: "WAIT" },{ label: "ยืนยันแล้ว", value: "CONFIRM", key: "CONFIRM" }]
  const optionStatusGuarantee = [{ label: "จำเป็นต้องระบุ", value: "REQUIRED", key: "REQUIRED" },{ label: "ไม่จำเป็นต้อง", value: "NONE", key: "NONE" }]

  return {
    optionStatus,
    optionStatusGuarantee,
    loadingOption,
  };
};
