import React, { useContext, useEffect, useMemo, useState } from "react";
import { browserHistory } from "react-router";
import {
  Modal,
  Form,
  Collapse,
  Row,
  Col,
  Spin,
  Steps,
  Button,
  Typography,
} from "antd";
import { Line } from "@antv/g2plot";
import "../CSSPersonal.css";

import { APILineNoti, KEY_PERSONAL } from "../servicesPersonal";
import { PersonalContext } from "../servicesPersonal/personalProvider";
import { KEY_OPTION, TEXT_LOADING } from "../../constants/enums/enumCenter";
import { ButtonTheme, TEXT_BUTTON } from "../../constants/buttons";
import { CANVAS_DISC } from "../../constants/canvas";
import { MasterCenterContext } from "../../service/master/masterProvider";

import {
  MAP_CONTENT_EMPINFO,
  RenderInput,
  RENDER_BUTTON,
  RENDER_BUTTON_PERSONAL,
} from "../componentsPersonal";
import { PATHNAME } from "../../constants/enums/pathname";
import { SetDateAPI } from "../../common/setDate";
import { FIELD_EMP } from "../constantsPersonal/contents/employeeInfo";
import { FIELD_OTHER } from "../constantsPersonal/contents/otherInfo";
import {
  FIELD_PERSONAL,
  RENDER_ADDRESS_FORM,
  RENDER_ADDRESS_FORM_VIEW,
} from "../constantsPersonal/contents/personalInfo";
import { modifyToFormer, modifyToFullname } from "../functions/modifyField";
import {
  generateUsername,
  generateEmail,
  shouldUpdateFields,
  renderFieldError,
} from "../functions/validateField";
import { ERROR_MODAL, KEY_MODAL } from "../functions/modalKeys";
import { STATUS_VALIDATE } from "../constantsPersonal";
import { colors } from "../../themes";
import { calcNumYears } from "../functions/calculateField";
import { getValueAddress, SET_DEFAULT } from "../functions";
import {
  responseSuccess,
  responseSuccessExtra,
} from "../../service/functions/convertResponse";
import moment from "moment";
import { PATH_ROUTE } from "../../screens/constants/ConstantPath";
import { encode } from "../../extendFunction/encodeString";
import {
  GET,
  GET_GUARANTEE_COST_CENTER_LIST,
  GET_RUNNING_NO,
} from "../../service";
import { createGlobalStyle } from "styled-components";
import { set } from "lodash";
const { Paragraph, Link } = Typography;

const disabledFields = {
  [FIELD_PERSONAL.MILITARYSERVICE_WHEN]: false,
  [FIELD_PERSONAL.REASON_EXEMPT]: false,
  [FIELD_EMP.SUB_DEP_NO]: false,
  [FIELD_EMP.POSITION_NO]: false,
  [FIELD_EMP.FLOW_HRM]: false,
  [FIELD_EMP.COMPANY]: false,
  [FIELD_EMP.FULLNAME_EN]: false,
  [FIELD_EMP.CONTRACT_PERIOD]: true,
  [FIELD_EMP.DEP_NO]: true,
  [FIELD_EMP.END_CONTRACT_DATE_JOB]: true,
};

const showFields = {
  [FIELD_EMP.REASON_OUT_TEXT]: false,
};

const requiredFields = {
  [FIELD_EMP.END_DATE_JOB]: false,
};
const validateStatusFields = {
  [FIELD_PERSONAL.PRESENT_POSTCODE]: "",
  [FIELD_PERSONAL.FORMER_POSTCODE]: "",
};

let dataDISC = [];

const ManagePersonal = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { fetchAPIPersonal, loadingAPI, userLocation, userLogin } =
    useContext(PersonalContext);
  const { getMaster, masterCenterState, clearMaster } =
    useContext(MasterCenterContext);
  /* ------------------------------ STATE CENTER ------------------------------ */

  const [current, setCurrent] = useState(0); //TODO : กำหนด Default  ของ Step
  const [similar, setSimilar] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [collapseDefault, setCollapseDefault] = useState({
    //collapsible: "disabled",  //TODO : กำหนดให้ปิด Collapse ไว้
    //activekey: [1],
    collapsible: "",
    activekey: [...Array(12).keys()],
    key: "1",
  });
  const [isRecheck, setISRecheck] = useState(false);
  /* -------------------------------------------------------------------------- */

  const [disabledAddress, setDisabledAddress] = useState({
    FORMER: true,
    PRESENT: true,
  });
  const [disabledField, setDisabledField] = useState({ ...disabledFields });
  const [showField, setShowField] = useState({ ...showFields });
  const [validateStatusField, setStatusField] = useState({
    ...validateStatusFields,
  });
  const [requiredField, setRequiredField] = useState({ ...requiredFields });
  const [statusStep, setStatusStep] = useState("");

  const [visible, setVisible] = useState(false);
  const [costCenterList, setCostCenterList] = useState([]);
  const [oldEmail, setOldEmail] = useState("");
  const [userCode, setUserCode] = useState({
    company: "",
    startYear: moment().add(543, "year").format("YY"),
    depCode: "",
    subDepNo: "",
    runningNo: "",
  });
  const [duplicateField, setDuplicateField] = useState([]);

  const STEPS_PROGRESS = useMemo(() => props.process, [props.process]); //TODO : ส่งมาจากหน้า updatePersonal /  / dashboardPersonal ส่วนของ containers
  const INITIAL_VALUES = props.initial;
  let candidateNoObj = props.refData || "";
  if (candidateNoObj) {
    // candidateNoObj && candidateNoObj !== undefined
    candidateNoObj = candidateNoObj.candidateNo;
  }

  const OBJECT_STEPS_PROGRESS = {
    ...collapseDefault,
    ...INITIAL_VALUES,
    pathname: props.pathname,
    location: userLocation, //TODO : ได้ข้อมูลจากตอน Login
    options: { ...masterCenterState },
    renderAddressForm: props.isView
      ? RENDER_ADDRESS_FORM_VIEW({
          similar,
          options: { ...masterCenterState },
          disabled: disabledAddress,
          validateStatus: validateStatusField,
          disabledAll: true,
        })
      : RENDER_ADDRESS_FORM({
          similar,
          options: { ...masterCenterState },
          disabled: disabledAddress,
          validateStatus: validateStatusField,
          disabledAll: isRecheck,
        }),
    required: requiredField,
    disabled: disabledField,
    disabledAll: isRecheck || props.isView,
    show: showField,
    onClick: {
      onPressDISC: () => onShowDISC(),
    },
    duplicateField: duplicateField,
  };

  const getRunningNo = async () => {
    const number = await GET(GET_RUNNING_NO);
    setUserCode({ ...userCode, runningNo: number.result });
  };

  useEffect(() => {
    fetchAPI();
    getRunningNo();
  }, []);

  useEffect(() => {
    console.log(userCode);
    const isAllFieldsNotEmpty = Object.values(userCode).every(
      (value) => value !== ""
    );
    if (isAllFieldsNotEmpty) {
      const concatenatedString = Object.values(userCode).join("");
      form.setFieldsValue({
        [FIELD_EMP.EMPLOYEE_ID]: concatenatedString,
      });
    }
  }, [userCode]);

  /* -------------------------------- useEffect ------------------------------- */
  async function fetchAPI() {
    let response = [];
    try {
      setLoadingPage(true);
      response = await Promise.all([
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_LOCATION,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_COMPANY,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_TITLE_TH,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_TITLE_EN,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SHIRT_SIZE,
          typeOption: "null",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_TIME_SCAN,
          typeOption: "null",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_ONETIME_SCAN,
          typeOption: "empty",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_MAN_TYPE,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_PAYROLL_GROUP,
          typeOption: "null",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_REASON_LEAVE_GROUP,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SEX,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_STATUS_WORKERS,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_RELIGION,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_PERSONLA_STATUS,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_DRIVING_TYPE,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_MILITARYSERVICE,
          typeOption: "none",
        }),

        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_ASSETS_TYPE,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_HEALTH_PLAN,
          typeOption: "null",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_ACCIDENT_PLAN,
          typeOption: "null",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_RECRUITER,
          typeOption: "empty",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE,
          typeOption: "none",
        }),
      ]);

      const respond = await GET(`${GET_GUARANTEE_COST_CENTER_LIST}`);
      setCostCenterList(respond.result);

      /* --------------- เรียกค่า Default ของหน้า Update กับ Import --------------- */
      //TODO : โดยใช้ userId กับ candidateNo ในการค่าหาข้อมูลพนักงาน
      if (!!props.refData) {
        const initialDefault = await fetchAPIPersonal({
          key: KEY_PERSONAL.SELECT_EMP_PERSONAL,
          data: { ...props.refData },
        });
        //console.log(`get default form [${props.pathname}]:`, initialDefault)
        if (
          initialDefault.result.statusFindIdCard &&
          initialDefault.result.statusFindIdCard != 0 &&
          initialDefault.result.messageImportIdCard
        ) {
          Modal.warning({ title: initialDefault.result.messageImportIdCard });
        } else if (
          initialDefault.result.statusFindIdCard == 0 &&
          initialDefault.result.messageImportIdCard
        ) {
          Modal.error(
            ERROR_MODAL(
              KEY_MODAL.DUPLICATE_INSERT,
              initialDefault.result.messageImportIdCard
            )
          );
          // browserHistory.push(`${PATHNAME.DASHBOARD_IMPORT_PERSONAL_ID_CARD}`)
          return;
        }
        if (initialDefault.result.userId) {
          initialDISC(initialDefault.result.userId);
        }
        initialDefault.result[FIELD_EMP.AUTO_EMAIL] = initialDefault.result[
          FIELD_EMP.EMAIL
        ].includes("@dappmaker.co.th")
          ? true
          : false;
        handleCollapse();
        handleFields(initialDefault.result);
        return;
      }
      setLoadingPage(false);
      /* -------------------------------------------------------------------------- */
    } catch (error) {
      setLoadingPage(false);
      //! line Noti
      APILineNoti({
        header: "FRONTEND",
        title: `📍 fetchAPI : ${error.message} `,
        message: `${JSON.stringify({ ...props, resOption: response })}`,
      });
    }
  }

  async function initialDISC(userId) {
    const data = [];
    const [r] = await fetchAPIPersonal({
      key: KEY_PERSONAL.SELECT_TYPE_DISC,
      data: { userId },
    });
    if (!r) {
      return;
    }

    const s = ["D", "I", "S", "C"];
    for (let index = 0; index < 4; index++) {
      const score = r[`score${s[index]}`];
      data[index] = {
        Type:
          (index === 0 && "D") ||
          (index === 1 && "I") ||
          (index === 2 && "S") ||
          (index === 3 && "C"),
        Score: score,
      };
    }

    if (data.length) {
      dataDISC = data;
    }
  }

  function onShowDISC() {
    if (dataDISC.length) {
      setVisible(true);
      setTimeout(() => {
        const linePlot = new Line(document.getElementById("canvas"), {
          ...CANVAS_DISC,
          data: dataDISC,
        });
        linePlot.render();
      }, 100);
    }
  }

  const content = (_isOffice, refUser) => {
    if (_isOffice) {
      return (
        <Paragraph>
          <ul>
            <li>
              <Link
                href={`${PATHNAME.FORM_HIRE_DETAIL}?userId=${encode(refUser)}`}
                target="_blank"
              >
                {"สร้างแบบฟอร์มยืนยันการจ้าง (ใบปะหน้า)"}
              </Link>
            </li>
            <li>
              <Link
                href={`${PATHNAME.FORM_CONTRACT_DETAIL}?userId=${encode(
                  refUser
                )}`}
                target="_blank"
              >
                {"สร้างสัญญาจ้าง"}
              </Link>
            </li>
          </ul>
        </Paragraph>
      );
    }
  };

  /* -------------------------------------------------------------------------- */
  return (
    <Spin ref={ref} spinning={loadingPage || loadingAPI} tip={TEXT_LOADING}>
      <Steps
        status={statusStep}
        className="site-navigation-steps"
        type="navigation"
        size="small"
        current={current}
        onChange={
          !collapseDefault["collapsible"] &&
          !(loadingPage || loadingAPI) &&
          handleCurrent
        } //ถ้า collapsible เป็นค่าว่าง ถึงเรียกใช้ Func handleCurrent
      >
        {STEPS_PROGRESS().map(
          (progress, idxProgress) =>
            !progress.hidden && <Steps.Step key={idxProgress} {...progress} />
        )}
      </Steps>

      {STEPS_PROGRESS()[current] &&
        STEPS_PROGRESS({ ...OBJECT_STEPS_PROGRESS })[current]["content"].map(
          (collapse, idxCollapse) => (
            <Form
              key={`form_${idxCollapse}`}
              form={form}
              scrollToFirstError
              layout={collapse.layout || "vertical"}
              //initialValues={{ ...INITIAL_VALUES }}
              initialValues={{ ...SET_DEFAULT({}) }}
              onFinishFailed={onFinishFailed}
            >
              {/* ----------------------------- Render Collapse ---------------------------- */}
              <Collapse
                key={idxCollapse}
                activeKey={collapseDefault["activekey"]}
                defaultActiveKey={[1]}
                style={{ marginBottom: 20 }}
              >
                <Collapse.Panel
                  showArrow={false}
                  collapsible={collapseDefault["collapsible"]}
                  {...collapse}
                >
                  {collapse["content"]().map((row, idxR) => (
                    <Row key={idxR} gutter={collapse.gutter || [10, 0]}>
                      {row.map((each, idxE) => (
                        <Col key={idxE} {...each}>
                          <Form.Item
                            {...each.itemprops}
                            shouldUpdate={(prevValues, curValues) =>
                              shouldUpdateFields(
                                prevValues[each.itemprops.name],
                                curValues[each.itemprops.name]
                              )
                            }
                          >
                            {/* ---------------------------- Render Form Input --------------------------- */}
                            <RenderInput
                              {...each.inputprops}
                              onChange={(val, data) => {
                                handleChange({
                                  field: each.itemprops.name,
                                  value: val,
                                  data,
                                  onChangeCallBack:
                                    each.inputprops.onChangeCallBack,
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Collapse.Panel>
              </Collapse>
            </Form>
          )
        )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          //	!loadingPage && !isRecheck && RENDER_BUTTON(
          !loadingPage &&
            !props.isView &&
            RENDER_BUTTON_PERSONAL({
              current,
              onPrevious,
              onNext,
              onSubmit: () => onFinish(),
              label:
                props.pathname === PATHNAME.BLOCK_PERSONAL
                  ? TEXT_BUTTON.BLOCK_USER
                  : collapseDefault["collapsible"] && !+current
                  ? TEXT_BUTTON.VERIFY_USER
                  : TEXT_BUTTON.CONFIRM,
              color:
                props.pathname === PATHNAME.BLOCK_PERSONAL
                  ? colors.error
                  : collapseDefault["collapsible"]
                  ? colors.theme
                  : colors.success,
              disabled: props.pathname !== PATHNAME.BLOCK_PERSONAL && isRecheck,
            })
        }
        {current !== 5 &&
          props.pathname !== PATHNAME.BLOCK_PERSONAL &&
          RENDER_BUTTON_PERSONAL({
            current,
            onPrevious,
            onSubmit: () => onNext(),
            label: "ถัดไป",
            color: colors.theme,
          })}
      </div>

      <Modal
        title="DISC Summary"
        closable={false}
        destroyOnClose={true}
        visible={visible}
        onOk={() => setVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            OK
          </Button>,
        ]}
      >
        <div id="canvas"></div>
      </Modal>
    </Spin>
  );
  /* -------------------------------- Function -------------------------------- */

  //TODO: กำหนด Field Default
  async function handleFields(fieldResult) {
    try {
      setOldEmail(fieldResult.emailEmp);
      const _depNo = fieldResult[FIELD_EMP.DEP_NO];
      const company = fieldResult[FIELD_EMP.COMPANY];
      const location = fieldResult[FIELD_EMP.LOCATION];
      const is_update = props.pathname === PATHNAME.UPDATE_PERSONAL;
      const is_import = props.pathname === PATHNAME.IMPORT_PERSONAL;
      let checkDisableStartDateJob = true;
      if (is_update || is_import) checkDisableStartDateJob = false;

      const response = await Promise.all([
        props.pathname === PATHNAME.IMPORT_PERSONAL &&
          fetchAPIPersonal({
            key: KEY_PERSONAL.GET_USER_CODE,
            data: { [FIELD_EMP.COMPANY]: fieldResult[FIELD_EMP.COMPANY] },
          }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
          payload: {
            company: fieldResult[FIELD_EMP.COMPANY],
            location: fieldResult[FIELD_EMP.LOCATION],
          },
          typeOption: "required",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT,
          payload: { depNo: _depNo },
          typeOption: "required",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_POSITION,
          payload: { company: fieldResult[FIELD_EMP.COMPANY], depNo: _depNo },
          typeOption: "required",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_FLOWHRM,
          payload: {
            company: fieldResult[FIELD_EMP.COMPANY],
            location: fieldResult[FIELD_EMP.LOCATION],
          },
          typeOption: "required",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_ADDRESS,
          payload: {
            former: fieldResult[FIELD_PERSONAL.FORMER_POSTCODE],
            present: fieldResult[FIELD_PERSONAL.PRESENT_POSTCODE],
          },
        }),
      ]);

      setDisabledAddress({ ...disabledAddress, PRESENT: false, FORMER: false });
      let dataDisabled = {
        //TODO: ถ้า สถานะทางทหาร เป็น "จะถูกเรียก" ช่อง "รอเรียกเกณฑ์ทหาร ในปี พ.ศ." จะไม่ Disabled
        [FIELD_PERSONAL.MILITARYSERVICE_WHEN]:
          fieldResult[FIELD_PERSONAL.MILITARYSERVICE] === "MS2",
        //TODO: ถ้า สถานะทางทหาร เป็น "ได้รับการยกเว้น" ช่อง "ได้ยกเว้นเกณฑ์ทหารเนื่องจาก" จะไม่ Disabled
        [FIELD_PERSONAL.REASON_EXEMPT]:
          fieldResult[FIELD_PERSONAL.MILITARYSERVICE] === "MS3",
        [FIELD_EMP.COMPANY]: is_update,
        [FIELD_EMP.SHIFT_CODE]: fieldResult[FIELD_EMP.SHIFT_CODE] && is_update,
        [FIELD_EMP.SUB_DEP_NO]: !!_depNo,
        [FIELD_EMP.POSITION_NO]: !!_depNo,
        [FIELD_EMP.FLOW_HRM]: !company && !location,
        [FIELD_EMP.DEP_NO]: is_update,
        [FIELD_EMP.LOCATION]: is_update,
        [FIELD_EMP.SUB_DEP_NO]: !is_update,
        [FIELD_EMP.FLOW_HRM]: !is_update,
        [FIELD_EMP.STATUS_EMPLOYEE]: is_update,
        [FIELD_EMP.STATUS_WORKERS]: is_update,
        [FIELD_EMP.POSITION_NO]: !is_update,
        [FIELD_EMP.CONTRACT_PERIOD]: is_update,
        //[FIELD_EMP.START_DATE_JOB]: is_update,
        [FIELD_EMP.START_DATE_JOB]: checkDisableStartDateJob,
        // [FIELD_EMP.END_CONTRACT_DATE_JOB]: is_update,
      };

      if (fieldResult[FIELD_EMP.CONFIRM_FIRST_DATA]) {
        dataDisabled = {
          ...dataDisabled,
          [FIELD_EMP.TITLE_TH]: true,
          [FIELD_EMP.FIRSTNAME_TH]: true,
          [FIELD_EMP.LASTNAME_TH]: true,
          [FIELD_EMP.NICKNAME_TH]: true,
          [FIELD_EMP.PERSONAL_ID]: true,
          [FIELD_EMP.PERSONAL_ID_EXP]: true,
          [FIELD_EMP.TITLE_EN]: true,
          [FIELD_EMP.FIRSTNAME_EN]: true,
          [FIELD_EMP.LASTNAME_EN]: true,
          [FIELD_EMP.NICKNAME_EN]: true,
          [FIELD_EMP.PERSONAL_ID]: true,
          [FIELD_EMP.PERSONAL_ID_EXP]: true,
          [FIELD_EMP.PASSPORT_ID]: true,
          [FIELD_EMP.PASSPORT_ID_EXP]: true,
          [FIELD_EMP.EMAIL]: true,
          [FIELD_EMP.WORKPERMIT_ID]: true,
          [FIELD_EMP.WORKPERMIT_ID_EXP]: true,
          [FIELD_EMP.TEL]: true,
          [FIELD_PERSONAL.HOME_TEL]: true,
          [FIELD_PERSONAL.LINE_ID]: true,
          [FIELD_PERSONAL.EMAIL]: true,
        };
        setISRecheck(fieldResult[FIELD_EMP.CONFIRM_FIRST_DATA] && !is_update);
      }

      setSimilar(fieldResult[FIELD_PERSONAL.SIMILAR_PRESENT]);
      setDisabledField(dataDisabled);
      setRequiredField({
        [FIELD_EMP.END_DATE_JOB]:
          fieldResult[FIELD_EMP.STATUS_WORKERS] === "Block" ||
          fieldResult[FIELD_EMP.STATUS_WORKERS] === "notCome",
        [FIELD_EMP.END_CONTRACT_DATE_JOB]:
          fieldResult[FIELD_EMP.STATUS_WORKERS] === "Temp" ||
          fieldResult[FIELD_EMP.STATUS_WORKERS] === "DailyTemp",
      });

      const { OPTION_ADDRESS = {} } = masterCenterState;
      const { OPTION_ADDRESS_FORMER = {}, OPTION_ADDRESS_PRESENT = {} } =
        OPTION_ADDRESS;
      const {
        OPTION_DISTRICT: OPTION_DISTRICT_FORMER = [],
        OPTION_PROVINCE: OPTION_PROVINCE_FORMER = [],
        OPTION_SUB_DISTRICT: OPTION_SUB_DISTRICT_FORMER = [],
      } = OPTION_ADDRESS_FORMER;
      const {
        OPTION_DISTRICT: OPTION_DISTRICT_PRESENT = [],
        OPTION_PROVINCE: OPTION_PROVINCE_PRESENT = [],
        OPTION_SUB_DISTRICT: OPTION_SUB_DISTRICT_PRESENT = [],
      } = OPTION_ADDRESS_PRESENT;

      form.setFieldsValue({
        ...fieldResult,
        [FIELD_EMP.PRESENT_PROVINCE]: getValueAddress(
          fieldResult[FIELD_EMP.PRESENT_PROVINCE],
          OPTION_PROVINCE_PRESENT
        ),
        [FIELD_EMP.PRESENT_DISTRICT]: getValueAddress(
          fieldResult[FIELD_EMP.PRESENT_DISTRICT],
          OPTION_DISTRICT_PRESENT
        ),
        [FIELD_EMP.PRESENT_SUB_DISTRICT]: getValueAddress(
          fieldResult[FIELD_EMP.PRESENT_SUB_DISTRICT],
          OPTION_SUB_DISTRICT_PRESENT
        ),
        [FIELD_EMP.FORMER_PROVINCE]: getValueAddress(
          fieldResult[FIELD_EMP.FORMER_PROVINCE],
          OPTION_PROVINCE_FORMER
        ),
        [FIELD_EMP.FORMER_DISTRICT]: getValueAddress(
          fieldResult[FIELD_EMP.FORMER_DISTRICT],
          OPTION_DISTRICT_FORMER
        ),
        [FIELD_EMP.FORMER_SUB_DISTRICT]: getValueAddress(
          fieldResult[FIELD_EMP.FORMER_SUB_DISTRICT],
          OPTION_SUB_DISTRICT_FORMER
        ),
        [FIELD_EMP.STATUS_WORKERS]:
          props.pathname === PATHNAME.BLOCK_PERSONAL
            ? "Block"
            : fieldResult[FIELD_EMP.STATUS_WORKERS],
        // TODO : ถ้าเป็นแก้ไขให้ getUser ใหม่
        [FIELD_EMP.EMPLOYEE_ID]:
          props.pathname === PATHNAME.IMPORT_PERSONAL
            ? response[0]
            : fieldResult[FIELD_EMP.EMPLOYEE_ID],
        [FIELD_EMP.USERNAME]: fieldResult[FIELD_EMP.USERNAME],
        [FIELD_EMP.FULLNAME_TH]: modifyToFullname(
          fieldResult[FIELD_EMP.FIRSTNAME_TH],
          fieldResult[FIELD_EMP.LASTNAME_TH],
          fieldResult[FIELD_EMP.NICKNAME_TH],
          fieldResult[FIELD_EMP.TITLE_TH]
        ),
        [FIELD_EMP.FULLNAME_EN]: modifyToFullname(
          fieldResult[FIELD_EMP.FIRSTNAME_EN],
          fieldResult[FIELD_EMP.LASTNAME_EN],
          fieldResult[FIELD_EMP.NICKNAME_EN],
          fieldResult[FIELD_EMP.TITLE_EN]
        ),
        [FIELD_PERSONAL.AGE_REGIS]: calcNumYears(
          fieldResult[FIELD_PERSONAL.DATE_OF_BIRTHDAY],
          fieldResult[FIELD_EMP.START_DATE_JOB]
        ), //ปีเกิด - ปีที่เข้าทำงาน
        [FIELD_PERSONAL.AGE]: calcNumYears(
          fieldResult[FIELD_PERSONAL.DATE_OF_BIRTHDAY]
        ), // ปีเกิด - ปีปัจจุบัน
        [FIELD_EMP.WORK_EXPERIENCE]: calcNumYears(
          fieldResult[FIELD_EMP.START_DATE_JOB]
        ), // ปีปัจจุบัน - ปีที่เข้าทำงาน
        [FIELD_PERSONAL.SIMILAR_PRESENT]:
          fieldResult[FIELD_PERSONAL.SIMILAR_PRESENT],
        [FIELD_EMP.STATUS_EMPLOYEE]: "normal",
        [FIELD_EMP.PAYROLL_GROUP]: fieldResult[FIELD_EMP.PAYROLL_GROUP],
        [FIELD_EMP.CONTRACT_PERIOD]: fieldResult[FIELD_PERSONAL.CONTACT_PERIOD],
        [FIELD_EMP.END_DATE_JOB]:
          props.pathname === PATHNAME.BLOCK_PERSONAL
            ? new Date()
            : fieldResult[FIELD_EMP.END_DATE_JOB] || "",
        [FIELD_EMP.HEALTH_PLAN]:
          fieldResult[FIELD_EMP.HEALTH_PLAN] ||
          (!is_update ? "" : fieldResult[FIELD_EMP.HEALTH_PLAN]),
        [FIELD_EMP.ACCIDENT_PLAN]:
          fieldResult[FIELD_EMP.ACCIDENT_PLAN] ||
          (!is_update ? "p1" : fieldResult[FIELD_EMP.ACCIDENT_PLAN]),
        [FIELD_EMP.FLOW_HRM]: fieldResult[FIELD_EMP.FLOW_HRM],
        [FIELD_EMP.STATUS_EMPLOYEE]:
          props.pathname === PATHNAME.BLOCK_PERSONAL
            ? ""
            : fieldResult[FIELD_EMP.STATUS_EMPLOYEE],
        [FIELD_EMP.USERNAME]:
          fieldResult[FIELD_EMP.USERNAME] ||
          generateUsername(
            fieldResult[FIELD_EMP.FIRSTNAME_EN],
            fieldResult[FIELD_EMP.LASTNAME_EN]
          ),
        [FIELD_EMP.EMAIL]: fieldResult[FIELD_EMP.AUTO_EMAIL]
          ? generateEmail(
              fieldResult[FIELD_EMP.FIRSTNAME_EN],
              fieldResult[FIELD_EMP.LASTNAME_EN]
            )
          : fieldResult[FIELD_EMP.EMAIL],
        [FIELD_EMP.SALE_CODE]: fieldResult[FIELD_EMP.SALE_CODE],
      });
      setLoadingPage(false);
    } catch (error) {
      //! line Noti
      APILineNoti({
        header: "FRONTEND",
        title: `📍 handleFields : ${error.message} `,
        message: `${JSON.stringify({ ...fieldResult })}`,
      });
    }
  }

  //TODO: เช็คตอนเปลี่ยนค่าใน Form
  async function handleChange({ field, value, data, onChangeCallBack }) {
    const fieldValue = form.getFieldsValue(true);

    // console.log('field => ', field)
    // console.log('value => ', value)
    // console.log('data => ', data)
    try {
      //!This course no update in options
      // if (onChangeCallBack) {
      //   onChangeCallBack({ field, value, data, form });
      // }
      switch (field) {
        /* -------------------------------- FIELD_EMP ------------------------------- */
        case FIELD_EMP.TITLE_TH:
        case FIELD_EMP.TITLE_EN:
          //TODO : ถ้าเปลี่ยนคำนำหน้าชื่อ จะเปลี่ยนพร้อมกันทั้ง TH และ EN
          form.setFieldsValue({
            [FIELD_EMP.TITLE_TH]: value,
            [FIELD_EMP.TITLE_EN]: value,
          });
          break;
        case FIELD_EMP.COMPANY:
          //TODO : ถ้าเปลี่ยนบริษัท จะต้อง GET รหัสพนักงานล่าสุดของบริษัทนั้นมาจาก AP I
          // console.log("field>", field);
          // console.log("value>", value);
          const location = fieldValue[FIELD_EMP.LOCATION];
          await Promise.all([
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
              typeOption: "none",
              payload: { company: value, location },
            }),
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_POSITION,
              payload: { company: value },
              typeOption: "required",
            }),
            setDisabledField({
              ...disabledField,
              [FIELD_EMP.DEP_NO]: false,
              [FIELD_EMP.POSITION_NO]: true,
              [FIELD_EMP.FLOW_HRM]: !!value,
            }),
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_FLOWHRM,
              payload: {
                company: fieldValue[FIELD_EMP.COMPANY],
                location: fieldValue[FIELD_EMP.LOCATION],
              },
              typeOption: "required",
            }),
          ]);
          form.setFieldsValue({
            [FIELD_EMP.DEP_NO]: "",
          });
          setUserCode({ ...userCode, company: data.format_code });
          if (
            (value === "DP" || value === "DT" || value === "ZT") &&
            fieldValue[FIELD_EMP.STATUS_WORKERS] != "DailyTemp" &&
            fieldValue[FIELD_EMP.STATUS_WORKERS] != "Daily"
          ) {
            //เช็คเงื่อนไขว่าให้ Auto Generate Email หรือไม่
            form.setFieldsValue({
              [FIELD_EMP.AUTO_EMAIL]: true,
            });
          } else {
            form.setFieldsValue({
              [FIELD_EMP.AUTO_EMAIL]: false,
            });
          }
          handleAutoEmail(); //เช็คเงื่อนไขว่าให้ Auto Generate Email หรือไม่
          break;
        case FIELD_EMP.LOCATION:
          //TODO : ถ้าเปลี่ยนบริษัท จะต้อง GET รหัสพนักงานล่าสุดของบริษัทนั้นมาจาก API
          await Promise.all([
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
              typeOption: "none",
              payload: {
                company: fieldValue[FIELD_EMP.COMPANY],
                location: value,
              },
            }),
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_FLOWHRM,
              payload: {
                company: fieldValue[FIELD_EMP.COMPANY],
                location:
                  fieldValue[FIELD_EMP.LOCATION] == "TB"
                    ? "TM"
                    : fieldValue[FIELD_EMP.LOCATION],
              },
              typeOption: "required",
            }),
            setDisabledField({
              ...disabledField,
              [FIELD_EMP.FLOW_HRM]: !!value,
            }),
          ]);

          form.setFieldsValue({ [FIELD_EMP.DEP_NO]: "" });
          break;
        case FIELD_EMP.STATUS_WORKERS:
          //TODO : ถ้าเปลี่ยนสถานะเป็น Block || Temp  Required EndDatejob
          setRequiredField({
            ...requiredField,
            [FIELD_EMP.END_CONTRACT_DATE_JOB]:
              value == "Temp" || value == "DailyTemp",
            [FIELD_EMP.CONTRACT_PERIOD]:
              value == "Temp" || value == "DailyTemp",
          });
          form.setFieldsValue({
            [field]: value,
            [FIELD_EMP.END_CONTRACT_DATE_JOB]:
              fieldValue[FIELD_EMP.END_CONTRACT_DATE_JOB] || "",
          });
          // setDisabledField({ ...disabledField, [FIELD_EMP.CONTRACT_PERIOD]: false })
          setDisabledField({
            ...disabledField,
            [FIELD_EMP.CONTRACT_PERIOD]: !(
              value == "Temp" || value == "DailyTemp"
            ),
            [FIELD_EMP.END_CONTRACT_DATE_JOB]: !(
              value == "Temp" || value == "DailyTemp"
            ),
          });
          if (
            value != "DailyTemp" &&
            value != "Daily" &&
            ["DP", "ZT", "DT"].includes(fieldValue[FIELD_EMP.COMPANY])
          ) {
            //เช็คเงื่อนไขว่าให้ Auto Generate Email หรือไม่
            form.setFieldsValue({
              [FIELD_EMP.AUTO_EMAIL]: true,
            });
          } else {
            form.setFieldsValue({
              [FIELD_EMP.AUTO_EMAIL]: false,
            });
          }
          handleAutoEmail(); //เช็คเงื่อนไขว่าให้ Auto Generate Email หรือไม่

          if (value === "Block") {
            return (
              setRequiredField({
                ...requiredField,
                [FIELD_EMP.END_DATE_JOB]: true,
              }),
              form.setFieldsValue({
                [field]: value,
                [FIELD_EMP.END_DATE_JOB]:
                  fieldValue[FIELD_EMP.END_DATE_JOB] || "",
              }),
              setDisabledField({
                ...disabledField,
                [FIELD_EMP.CONTRACT_PERIOD]: false,
              })
            );
          }
          //TODO : ถ้าเปลี่ยนสถานะเป็น Block Set EndDatejob เป็นปัจจุบัน
          if (value === "notCome") {
            return (
              setRequiredField({
                ...requiredField,
                [FIELD_EMP.END_DATE_JOB]: true,
              }),
              form.setFieldsValue({
                [field]: value,
                [FIELD_EMP.END_DATE_JOB]:
                  fieldValue[FIELD_EMP.END_DATE_JOB] || moment(),
              })
            );
          }

          // setDisabledField({ ...disabledField, [FIELD_EMP.CONTRACT_PERIOD]: true, [FIELD_EMP.END_CONTRACT_DATE_JOB]: false })
          // setDisabledField({
          //   ...disabledField,
          //   [FIELD_EMP.CONTRACT_PERIOD]: true,
          //   [FIELD_EMP.END_CONTRACT_DATE_JOB]: true,
          // });
          // setRequiredField({
          //   ...requiredField,
          //   [FIELD_EMP.END_CONTRACT_DATE_JOB]: false,
          //   [FIELD_EMP.CONTRACT_PERIOD]: false,
          // }); // [FIELD_EMP.CONTRACT_PERIOD]: value ===
          // form.setFieldsValue({
          //   [field]: value,
          //   [FIELD_EMP.END_CONTRACT_DATE_JOB]: "",
          //   [FIELD_EMP.CONTRACT_PERIOD]: "",
          // });
          break;
        // break;
        case FIELD_EMP.CONTRACT_PERIOD:
          let obj = {
            [field]: value,
            [FIELD_EMP.CONTRACT_PERIOD_VALUE]: data.description_other,
          };
          if (fieldValue[FIELD_EMP.START_DATE_JOB]) {
            const period2 = data.description_other;
            const startDate2 = SetDateAPI(fieldValue[FIELD_EMP.START_DATE_JOB]);
            const endDate2 = moment(startDate2, "YYYY-MM-DD")
              .add(period2, "d")
              .format("YYYY-MM-DD");
            obj = {
              ...obj,
              [FIELD_EMP.END_CONTRACT_DATE_JOB]: SetDateAPI(endDate2),
            };
          }

          form.setFieldsValue(obj);
          break;

        case FIELD_EMP.FIRSTNAME_EN:
        case FIELD_EMP.LASTNAME_EN:
          //TODO : ถ้าเปลี่ยนชื่อหรือนามสกุล ภาษาอังกฤษ จะต้องเปลี่ยน username และ email ตาม format
          if (
            props.pathname != PATHNAME.UPDATE_PERSONAL &&
            props.pathname != PATHNAME.RECHECK_PERSONAL &&
            duplicateField.length === 0
          ) {
            form.setFieldsValue({
              [FIELD_EMP.USERNAME]: generateUsername(
                fieldValue[FIELD_EMP.FIRSTNAME_EN],
                fieldValue[FIELD_EMP.LASTNAME_EN]
              ),
            });
          }
        case FIELD_EMP.USERNAME:
          if (duplicateField.length > 0 && fieldValue[FIELD_EMP.AUTO_EMAIL]) {
            form.setFieldsValue({
              [FIELD_EMP.EMAIL]: `${
                fieldValue[FIELD_EMP.USERNAME]
              }@dappmaker.co.th`,
            });
          }
          break;
        case FIELD_EMP.AUTO_EMAIL:
          handleAutoEmail();
          break;
        case FIELD_EMP.DEP_NO:
          //TODO : ถ้าเปลี่ยนแผนก จะต้อง Reset Feild แผนกย่อย , ตำแหน่งใหม่ และ สายอนุมัติ
          setUserCode({ ...userCode, depCode: value.slice(-2) });
          form.setFieldsValue({
            [field]: value,
            [FIELD_EMP.SUB_DEP_NO]: "",
            [FIELD_EMP.POSITION_NO]: "",
          });
          //TODO : ถ้าเลือกแผนก จะไม่ต้อง Disabled
          setDisabledField({
            ...disabledField,
            [FIELD_EMP.SUB_DEP_NO]: !!value,
          });
          //TODO : ถ้าเปลี่ยนแผนก เรียก API แผนกย่อย , ตำแหน่งใหม่ และ สายอนุมัติ
          /* ------------------------- เรียก API แบบ Referance ------------------------ */
          await Promise.all([
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_POSITION,
              payload: {
                company: fieldValue[FIELD_EMP.COMPANY],
                depNo: value,
              },
              typeOption: "required",
            }),
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT,
              payload: { depNo: value },
              typeOption: "required",
            }),
          ]);
          /* -------------------------------------------------------------------------- */
          handleAutoSendFileGuarantee(value);
          break;
        case FIELD_EMP.SUB_DEP_NO:
          setUserCode({ ...userCode, subDepNo: value });
          break;
        case FIELD_EMP.SCORE_JG:
          //TODO : ถ้า SCORE_JG > 12 จะไม่สามารถกรอกได้
          if (value > 12) {
            return form.setFieldsValue({ [field]: "" });
          }
          form.setFieldsValue({ [field]: value });
          break;
        case FIELD_EMP.START_DATE_JOB:
          //TODO : ถ้าเปลี่ยนวันที่เริ่มงานจะต้องคำนวณ อายุ ณ วันที่มาสมัครงานและ อายุงาน
          const period = fieldValue[FIELD_EMP.CONTRACT_PERIOD_VALUE];
          const startDate = SetDateAPI(value);
          const startYear = moment(startDate).add(543, "year").format("YY");
          if (startYear !== "Invalid date") {
            setUserCode({
              ...userCode,
              startYear: startYear,
            });
          }
          const endDate =
            period &&
            moment(startDate, "YYYY-MM-DD")
              .add(period, "d")
              .format("YYYY-MM-DD");
          form.setFieldsValue({
            [field]: SetDateAPI(value), //TODO : SET Format วันที่
            [FIELD_PERSONAL.AGE_REGIS]: calcNumYears(
              fieldValue[FIELD_EMP.START_DATE_JOB],
              value
            ),
            [FIELD_EMP.WORK_EXPERIENCE]: calcNumYears(value),
            [FIELD_EMP.END_CONTRACT_DATE_JOB]: SetDateAPI(endDate),
          });
          break;
        case FIELD_EMP.END_CONTRACT_DATE_JOB:
        case FIELD_EMP.END_DATE_JOB:
          form.setFieldsValue({ [field]: SetDateAPI(value) });
          break;
        case FIELD_EMP.TEL:
          //TODO : ถ้าเบอร์โทรศัพท์เปลี่ยนจะต้องเก้บค่าใส่ Field Mobile Phone ของข้อมูล Personal ด้วย
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.MOBILE_PHONE]: value,
          });
          break;
        case FIELD_EMP.PAYROLL_GROUP:
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.PAYROLL_GROUP]: value,
          });
          break;
        case FIELD_EMP.STATUS_EMPLOYEE:
          if (disabledField[FIELD_EMP.REASON_OUT] !== (value === "notComes")) {
            setDisabledField({
              ...disabledField,
              [FIELD_EMP.REASON_OUT]: value === "notComes",
              [FIELD_EMP.REASON_OUT_TEXT]: value === "notComes",
              [FIELD_EMP.END_DATE_JOB]: value === "notComes",
            });
          }

          setShowField({ [FIELD_EMP.REASON_OUT_TEXT]: false });

          setRequiredField({
            ...requiredField,
            [FIELD_EMP.REASON_OUT]: value !== "notComes",
            [FIELD_EMP.REASON_OUT_TEXT]:
              fieldValue[FIELD_EMP.REASON_OUT] === "other",
            [FIELD_EMP.END_DATE_JOB]: value !== "hold",
          });
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.CURRENT_STATUS]: value,
            [FIELD_EMP.REASON_OUT]: "",
            [FIELD_EMP.REASON_OUT_TEXT]: "",
            [FIELD_EMP.END_DATE_JOB]:
              value !== "hold"
                ? value === "notComes"
                  ? new Date()
                  : fieldValue[FIELD_EMP.END_DATE_JOB]
                : "",
          });
          break;
        case FIELD_EMP.REASON_OUT:
          if (showField[FIELD_EMP.REASON_OUT_TEXT] !== (value === "other")) {
            setShowField({
              [FIELD_EMP.REASON_OUT_TEXT]: value === "other",
            });
          }
          setRequiredField({
            ...requiredField,
            [FIELD_EMP.REASON_OUT_TEXT]: value === "other",
          });
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.REASON_OUT]: value,
            [FIELD_PERSONAL.REASON_OUT_TEXT]: "",
          });
          break;
        case FIELD_EMP.REASON_OUT_TEXT:
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.REASON_OUT_TEXT]: value,
          });
          break;
        /* ----------------------------- FIELD_PERSONAL ----------------------------- */
        case FIELD_PERSONAL.DATE_OF_BIRTHDAY:
          //TODO : ถ้าเปลี่ยนวันเกิดจะต้องคำนวณ อายุ ณ วันที่มาสมัครงานและ อายุพนักงาน
          form.setFieldsValue({
            [field]: SetDateAPI(value), //TODO : SET Format วันที่
            [FIELD_PERSONAL.AGE_REGIS]: calcNumYears(
              value,
              fieldValue[FIELD_EMP.START_DATE_JOB]
            ),
            [FIELD_PERSONAL.AGE]: calcNumYears(value),
          });
          break;
        case FIELD_PERSONAL.MILITARYSERVICE:
          setDisabledField({
            //TODO: ถ้า สถานะทางทหาร เป็น "จะถูกเรียก" ช่อง "รอเรียกเกณฑ์ทหาร ในปี พ.ศ." จะไม่ Disabled
            [FIELD_PERSONAL.MILITARYSERVICE_WHEN]: value === "MS2",
            //TODO: ถ้า สถานะทางทหาร เป็น "ได้รับการยกเว้น" ช่อง "ได้ยกเว้นเกณฑ์ทหารเนื่องจาก" จะไม่ Disabled
            [FIELD_PERSONAL.REASON_EXEMPT]: value === "MS3",
          });
          //TODO: ถ้าเปลี่ยนแปลงสถานะทางทหาร ช่อง "รอเรียกเกณฑ์ทหาร" และ "ได้ยกเว้นเกณฑ์ทหารเนื่องจาก " จะ Reset
          form.setFieldsValue({
            [field]: value,
            [FIELD_PERSONAL.MILITARYSERVICE_WHEN]: "",
            [FIELD_PERSONAL.REASON_EXEMPT]: "",
          });
          break;
        /* ------------------------------ FIELD_UPLOAD IMAGE ------------------------ */
        case FIELD_PERSONAL.PROFILE_IMAGE:
        case FIELD_OTHER.FILE_PERSONAL_ID:
        case FIELD_OTHER.FILE_HOUSE_REGIS:
        case FIELD_OTHER.FILE_TRANSCRIPT:
        case FIELD_OTHER.FILE_RESUME:
        case FIELD_OTHER.FILE_LANG_TEST:
        case FIELD_OTHER.FILE_GUARANTEE:
        case FIELD_OTHER.FILE_BOOKBANK:
        case FIELD_OTHER.FILE_OTHER_1:
          //TODO: SET ค่าเป็น Array ถ้าเพิ่มใหม่ เป็น File แต่ถ้าเป้นไฟล์เก่าจะเป็น Object
          form.setFieldsValue({
            [field]: value.fileList,
            [FIELD_OTHER.FILES_CURRENTS]: {
              ...fieldValue[FIELD_OTHER.FILES_CURRENTS],
              [field]: value.defaultFileList,
            },
          });
          break;
        /* -------------------------------------------------------------------------- */
        /*                                   ADDRESS                                  */
        /* -------------------------------------------------------------------------- */
        case FIELD_PERSONAL.PRESENT_POSTCODE:
          //TODO: SET validating
          setStatusField({
            ...validateStatusField,
            [field]: STATUS_VALIDATE.VALIDATING,
          });
          if (`${value}`.length === 5) {
            let address = await getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_ADDRESS,
              payload: {
                present: value,
                former: similar
                  ? value
                  : fieldValue[FIELD_PERSONAL.FORMER_POSTCODE],
              },
            });

            //console.log(address.present, field)
            //TODO: clear validating
            setStatusField({
              ...validateStatusField,
              [field]: address.present
                ? STATUS_VALIDATE.SUCCESS
                : STATUS_VALIDATE.ERROR,
            });
            //TODO: แปลง Field Present ให้เป็น Former
            setDisabledAddress({ ...disabledAddress, PRESENT: false });
            //console.log(disabledAddress)
            form.setFieldsValue({
              [modifyToFormer(field)]: similar
                ? value
                : fieldValue[modifyToFormer(field)],
              [FIELD_PERSONAL.PRESENT_PROVINCE]: `${address.present.provinceId}`,
              [FIELD_PERSONAL.FORMER_PROVINCE]: similar
                ? `${address.present.provinceId}`
                : fieldValue[FIELD_PERSONAL.FORMER_PROVINCE],
              [FIELD_PERSONAL.PRESENT_DISTRICT]: `${address.present.districtId}`,
              [FIELD_PERSONAL.PRESENT_SUB_DISTRICT]: `${address.present.subDistrictId}`,
              [field]: value,
            });
          } else {
            const { OPTION_ADDRESS_PRESENT } =
              masterCenterState[KEY_OPTION.CENTER.OPTION_ADDRESS];
            const { OPTION_PROVINCE } = OPTION_ADDRESS_PRESENT;
            if (OPTION_PROVINCE && Array.isArray(OPTION_PROVINCE)) {
              // OPTION_PROVINCE.length >= 0
              await clearMaster({ key: KEY_OPTION.CENTER.OPTION_ADDRESS });
              setDisabledAddress({ ...disabledAddress, PRESENT: true });
              //TODO: Clear validating
              setStatusField({
                ...validateStatusField,
                [field]: fieldValue[FIELD_PERSONAL.FORMER_POSTCODE],
              });
              if (similar) {
                form.setFieldsValue({
                  [modifyToFormer(field)]: "",
                  [FIELD_PERSONAL.PRESENT_PROVINCE]: "",
                  [FIELD_PERSONAL.PRESENT_DISTRICT]: "",
                  [FIELD_PERSONAL.PRESENT_SUB_DISTRICT]: "",
                  [FIELD_PERSONAL.FORMER_PROVINCE]: "",
                  [FIELD_PERSONAL.FORMER_DISTRICT]: "",
                  [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: "",
                  [field]: value,
                });
              } else {
                form.setFieldsValue({
                  [modifyToFormer(field)]: fieldValue[modifyToFormer(field)],
                  [FIELD_PERSONAL.PRESENT_PROVINCE]: "",
                  [FIELD_PERSONAL.PRESENT_DISTRICT]: "",
                  [FIELD_PERSONAL.PRESENT_SUB_DISTRICT]: "",
                  [field]: value,
                });
              }
            }
          }
          break;
        case FIELD_PERSONAL.FORMER_POSTCODE:
          /* ----------------------------- GET API Address ----------------------------- */
          //TODO: SET validating
          setStatusField({
            ...validateStatusField,
            [field]: STATUS_VALIDATE.VALIDATING,
          });
          if (`${value}`.length === 5) {
            let address = await getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_ADDRESS,
              payload: {
                present: fieldValue[FIELD_PERSONAL.PRESENT_POSTCODE],
                former: value,
              },
            });

            //TODO: Clear validating
            setStatusField({
              ...validateStatusField,
              [field]: address
                ? STATUS_VALIDATE.SUCCESS
                : STATUS_VALIDATE.ERROR,
            });
            //TODO: แปลง Field Present ให้เป็น Former
            setDisabledAddress({ ...disabledAddress, FORMER: false });

            form.setFieldsValue({
              [FIELD_PERSONAL.FORMER_PROVINCE]: !similar
                ? `${address.former.provinceId}`
                : "",
              [FIELD_PERSONAL.FORMER_DISTRICT]: !similar
                ? `${address.former.districtId}`
                : "",
              [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: !similar
                ? `${address.former.subDistrictId}`
                : "",
              [FIELD_PERSONAL.SIMILAR_PRESENT]: "",
              [field]: value,
            });
          } else {
            await clearMaster({ key: KEY_OPTION.CENTER.OPTION_ADDRESS });
            //TODO: Clear validating
            setStatusField({ ...validateStatusField, [field]: "" });
            setDisabledAddress({ ...disabledAddress, FORMER: true });
            form.setFieldsValue({
              [FIELD_PERSONAL.FORMER_PROVINCE]: "",
              [FIELD_PERSONAL.FORMER_DISTRICT]: "",
              [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: "",
              [field]: value,
            });
          }
          break;
        case FIELD_PERSONAL.PRESENT_ADDRESS:
        case FIELD_PERSONAL.PRESENT_DISTRICT:
        case FIELD_PERSONAL.PRESENT_SUB_DISTRICT:
        case FIELD_PERSONAL.PRESENT_PROVINCE:
          //TODO: แปลง Field Present ให้เป็น Former
          form.setFieldsValue({
            [modifyToFormer(field)]: similar
              ? `${value}`
              : fieldValue[modifyToFormer(field)],
            [field]: `${value}`,
          });
          break;
        case FIELD_PERSONAL.FORMER_DISTRICT:
          form.setFieldsValue({
            [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: "",
            [FIELD_PERSONAL.SIMILAR_PRESENT]: "",
            [field]: value,
          });
          break;
        case FIELD_PERSONAL.FORMER_PROVINCE:
          form.setFieldsValue({
            [FIELD_PERSONAL.FORMER_DISTRICT]: "",
            [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: "",
            [FIELD_PERSONAL.SIMILAR_PRESENT]: "",
            [field]: value,
          });
          break;
        case FIELD_PERSONAL.SIMILAR_PRESENT:
          //TODO: Clear validating
          setStatusField({
            ...validateStatusField,
            [FIELD_PERSONAL.FORMER_POSTCODE]: "",
          });
          //TODO: SET SIMILAR_PRESENT
          setSimilar(value);
          //TODO: ถ้าที่อยู่เหมือนปัจจุบัน ให้แสดง value ที่พิมพ์ ถ้าไม่ให้แสดงค่า เดิม
          form.setFieldsValue({
            [FIELD_PERSONAL.FORMER_ADDRESS]: !value
              ? ""
              : fieldValue[FIELD_PERSONAL.PRESENT_ADDRESS],
            [FIELD_PERSONAL.FORMER_SUB_DISTRICT]: !value
              ? ""
              : fieldValue[FIELD_PERSONAL.PRESENT_SUB_DISTRICT],
            [FIELD_PERSONAL.FORMER_DISTRICT]: !value
              ? ""
              : fieldValue[FIELD_PERSONAL.PRESENT_DISTRICT],
            [FIELD_PERSONAL.FORMER_PROVINCE]: !value
              ? ""
              : fieldValue[FIELD_PERSONAL.PRESENT_PROVINCE],
            [FIELD_PERSONAL.FORMER_POSTCODE]: !value
              ? ""
              : fieldValue[FIELD_PERSONAL.PRESENT_POSTCODE],
            [field]: value,
          });
          break;
        /* -------------------------------------------------------------------------- */
        default:
          break;
      }
    } catch (error) {
      //! line Noti
      console.error(error);
      APILineNoti({
        header: "FRONTEND",
        title: `📍 handleChange : ${error.message} `,
        message: `${JSON.stringify({ ...fieldValue })}`,
      });
    }
  }

  function handleAutoEmail() {
    const fieldValue = form.getFieldsValue(true);
    if (fieldValue[FIELD_EMP.AUTO_EMAIL]) {
      if (duplicateField.length > 0) {
        form.setFieldsValue({
          [FIELD_EMP.EMAIL]: `${
            fieldValue[FIELD_EMP.USERNAME]
          }@dappmaker.co.th`,
        });
      } else {
        form.setFieldsValue({
          [FIELD_EMP.EMAIL]: generateEmail(
            fieldValue[FIELD_EMP.FIRSTNAME_EN],
            fieldValue[FIELD_EMP.LASTNAME_EN]
          ),
        });
      }
    } else {
      form.setFieldsValue({
        [FIELD_EMP.EMAIL]: oldEmail || "",
      });
    }
  }

  function handleAutoSendFileGuarantee(depNo) {
    if (costCenterList.includes(depNo)) {
      form.setFieldsValue({
        [FIELD_EMP.SEND_GUARANTEE]: true,
      });
    } else {
      form.setFieldsValue({
        [FIELD_EMP.SEND_GUARANTEE]: false,
      });
    }
  }

  //TODO: บันทึกข้อมูลพนักงาน
  //! STEP 1 : validateFields เช็ค Field ที่ยังไม่ได้กรอก
  //! STEP 2 : verifyEmployee ตรวจสอบข้อมูลพนักงาน ว่าเคยลาออกไปรึยัง
  //! STEP 3 : findEmp หาข้อมุลพนักงานจาก API
  async function onFinish() {
    console.log("onfinish...");
    // console.log("collapseDefault.activekey[0]", collapseDefault.activekey[0]);
    try {
      setLoadingPage(true);
      let response = false;
      //TODO : form.getFieldsValue(true)  คือ  Field ทั้งหมดที่ Default ไว้ใน intialValues
      let values = { ...form.getFieldsValue(true) };
      /* ------------- เช็ค Field ที่ยังไม่ได้กรอก ------------ */
      await form.validateFields();
      // console.log("form.validateFields()", form.validateFields());
      /* ------------------------------------------------ */
      // TODO: เช็คว่าถ้าเป็น Tab แรก === 1

      if (collapseDefault.activekey[0]) {
        /* -------------------------- ตรวจสอบข้อมูลพนักงาน -------------------------- */
        await verifyEmployee(values);
        /* -------------------------------------------------------------------------- */
      } else {
        values = { ...values };
        if (!!values[FIELD_EMP.EMPLOYEE_ID]) {
          if (
            (values[FIELD_OTHER.FILE_PERSONAL_ID].length === 0 ||
              values[FIELD_OTHER.FILE_HOUSE_REGIS].length === 0 ||
              values[FIELD_OTHER.FILE_BOOKBANK].length === 0) &&
            values[FIELD_EMP.EMPLOYEE_ID] === userLogin
          ) {
            Modal.error(ERROR_MODAL(KEY_MODAL.VALIDATE_CHANGE_TAB));
            setCurrent(5);
          } else {
            switch (props.pathname) {
              // TODO : เพิ่มพนักงานใหม่ จาก Job Online ==> API : '/HRM_API/empl/insertNewEmp'
              case PATHNAME.IMPORT_PERSONAL: // Import พนักงานใหม่จาก ระบบ Job Online
                console.log("submit PATHNAME.IMPORT_PERSONAL");
                if (props.refData && props.refData.candidateNo) {
                  console.log("values submit", {
                    ...values,
                    [props.refData.candidateNo.includes("IDN")
                      ? "refIdCardCandidateNo"
                      : "refCandidateNo"]:
                      (props.refData && props.refData.candidateNo) || "",
                  });
                }
                response = await fetchAPIPersonal({
                  key: KEY_PERSONAL.INSERT_PERSONAL,
                  data: {
                    ...values,
                    [props.refData.candidateNo.includes("IDN")
                      ? "refIdCardCandidateNo"
                      : "refCandidateNo"]:
                      (props.refData && props.refData.candidateNo) || "",
                  },
                });
                responseSuccessExtra(
                  response,
                  content(values.location == "OFF", values.userId),
                  () => handleReload()
                );
                if (props.refData.candidateNo.includes("IDN")) {
                  browserHistory.push(
                    `${PATHNAME.DASHBOARD_IMPORT_PERSONAL_ID_CARD}`
                  );
                }
                if (props.refData.candidateNo.includes("NF"))
                  return browserHistory.push(
                    `${PATHNAME.DASHBOARD_IMPORT_PERSONAL_FAC}`
                  );
                browserHistory.push(`${PATHNAME.DASHBOARD_IMPORT_PERSONAL}`);
                break;
              // TODO : เพิ่มพนักงานใหม่ ==> API : '/HRM_API/empl/insertNewEmp'
              case PATHNAME.INSERT_PERSONAL:
                console.log("submit PATHNAME.INSERT_PERSONAL");
                response = await fetchAPIPersonal({
                  key: KEY_PERSONAL.INSERT_PERSONAL,
                  data: values,
                });
                //console.log('response',response)
                const { result, message, status } = response;
                if (result > 0) {
                  responseSuccessExtra(
                    response,
                    content(values.location == "OFF", values.userId),
                    () => handleReload()
                  );
                  // responseSuccess(response, () => {handleShowGenerateForm(values.location == 'OFF'); handleReload();});
                } else {
                  if (status === "DUP_USERNAME") {
                    setDuplicateField(FIELD_EMP.USERNAME);
                  } else if (status === "DUP_EMAIL") {
                    setDuplicateField(FIELD_EMP.EMAIL);
                  }
                }

                break;
              // TODO : แก้ไขพนักงาน ==> API : '/HRM_API/empl/updateEmp'
              case PATHNAME.RECHECK_PERSONAL:
                console.log("submit PATHNAME.RECHECK_PERSONAL");
                response = await fetchAPIPersonal({
                  key: KEY_PERSONAL.UPDATE_PERSONAL,
                  data: { ...values },
                });
                responseSuccess(response, () =>
                  browserHistory.push(PATH_ROUTE.SSO_Edit)
                );
                break;
              case PATHNAME.UPDATE_PERSONAL:
                console.log("submit PATHNAME.UPDATE_PERSONAL");
                response = await fetchAPIPersonal({
                  key: KEY_PERSONAL.UPDATE_PERSONAL,
                  data: { ...values },
                });
                responseSuccess(response, () => handleReload());
                break;
              // TODO : แจ้งพ้นสภาพพนักงาน ==> API : '/HRM_API/empl/updateEmp'
              case PATHNAME.BLOCK_PERSONAL:
                console.log("submit PATHNAME.BLOCK_PERSONAL");
                Modal.confirm({
                  title: `คุณต้องการแจ้งพ้นสภาพการเป็นพนักงาน  ใช่หรือไม่ ?`,
                  content: MAP_CONTENT_EMPINFO([
                    {
                      key: FIELD_EMP.EMPLOYEE_ID,
                      label: `${values[FIELD_EMP.EMPLOYEE_ID]}`,
                    },
                    {
                      key: FIELD_EMP.FULLNAME_TH,
                      label: `${values[FIELD_EMP.FULLNAME_TH]}`,
                    },
                  ]),
                  onOk: async () => {
                    response = await fetchAPIPersonal({
                      key: KEY_PERSONAL.BLOCK_PERSONAL,
                      data: { ...values },
                    });
                    responseSuccess(response, () => handleReload());
                  },
                });
                break;
              default:
                break;
            }
          }
        } else {
          Modal.error(ERROR_MODAL(KEY_MODAL.VALIDATE_INSERT));
          setStatusStep("error");
          setCurrent(0);
          /**------------------------- */
        }
      }
    } catch (error) {
      Modal.error(ERROR_MODAL(KEY_MODAL.CATCH_INSERT, error.errorFields));
      //! line Noti
      APILineNoti({
        header: `FRONTEND ${userLogin} [${userLocation}]`,
        title: `📍 onFinish (${error.errorFields.length})`,
        message: `📑 errorFields : ${JSON.stringify(
          renderFieldError(error.errorFields)
        )}`,
      });
    } finally {
      setLoadingPage(false);
      console.log("Finally Finish", form.getFieldsValue(true));
    }
  }

  //TODO: ตรวจสอบข้อมูลพนักงาน ==> API : '/HRM_API/empl/blacklistCheck'
  //! STEP #2
  /**
   * กรณีเป็นค่าที่มีข้อมูลจริง    return { status : true  ,   result : {}  }
   * กรณีเป็นค่าที่ไม่มีข้อมูลจริง  return { status : false ,   result : {}  }
   */
  async function verifyEmployee(values) {
    try {
      if (!!values[FIELD_EMP.PERSONAL_ID]) {
        // console.log(`ค้นหาพนักงาน > เลขที่ ${ values[FIELD_EMP.PERSONAL_ID]} `)
        let response = await fetchAPIPersonal({
          key: KEY_PERSONAL.VERIFY_EMP,
          data: { ...values },
        });
        if (response) {
          const { message, success } = response;
          if (!success) {
            //TODO :กรณี success เป็น false จาก Backend
            return Modal.error({
              title: `${message || ""} `,
              onOk: () => {
                //! #1 : Reset Field ที่ค้นหาก่อน
                onReset();
                //! #2 : Set Field Default
                form.setFieldsValue({ ...SET_DEFAULT({}) });
                //! #3 : เปิด Collapse  ข้อมูลพนักงาน
                handleCollapse();
              },
            });
          } else {
            //TODO :กรณี success เป็น true
            Modal.confirm({
              title: message.title,
              content: MAP_CONTENT_EMPINFO(message.content), //TODO : Render Content ข้อมูลพนักงาน //# [{key:'',label:''}] => `${key} : ${label}
              onOk: () => findEmp(values), //TODO : เอา personalId ไป หาข้อมูลพนักงาน
            });
          }
        }
      } else {
        // console.log('ค้นหาพนักงาน > ไม่ระบุ > กำหนดข้อมูลตั้งต้น')
        //TODO :กรณีเป็นค่าว่าง values = undifriend
        form.setFieldsValue({ ...SET_DEFAULT({}) }); // กำหนด Field Defalut
        return handleCollapse();
      }
    } catch (error) {
      // console.log({ error })
      //! line Noti
      APILineNoti({
        header: "FRONTEND",
        title: `📍 verifyEmployee: ${error.message} `,
        message: `${JSON.stringify({ ...values })} `,
      });
    }
  }

  //TODO: ค้นหาข้อมูลพนักงาน ==> API : '/HRM_API/empl/findEmp'
  //! STEP #3
  async function findEmp(values) {
    try {
      if (!!values[FIELD_EMP.PERSONAL_ID] && !values[FIELD_EMP.EMPLOYEE_ID]) {
        let response = await fetchAPIPersonal({
          key: KEY_PERSONAL.SELECT_EMP_PERSONAL,
          data: { ...values },
        });
        if (!!response && response.success) {
          //TODO : SET ข้อมูลเข้า Form
          handleFields(response.result);
        }
        //TODO : Function เปิด Collapse
        handleCollapse();
      } else {
        return false;
      }
    } catch (error) {
      // console.log({ error })
      //! line Noti
      APILineNoti({
        header: "FRONTEND ",
        title: `📍findEmp: ${error.message} `,
        message: `${JSON.stringify({ ...values })} `,
      });
    }
  }

  function onFinishFailed(errorInfo) {
    // console.log('Failed:', errorInfo);
    Modal.error(ERROR_MODAL(KEY_MODAL.VALIDATE_INSERT));
  }

  function onReset() {
    form.resetFields(); //Reset Field ใน Form ทั้งหมด
  }

  async function handleReload(_close) {
    try {
      setLoadingPage(true);
      onReset();
      if (_close) {
        return window.close();
      }
      window.location.reload();
    } catch (error) {
      console.error({ error });
      setLoadingPage(false);
    } finally {
      setLoadingPage(false);
    }
  }

  /* ------------------------------ FUNCTION STEP ----------------------------- */

  function onPrevious() {
    setCurrent(current - 1); //ย้อนกลับ
  }

  function onNext() {
    setCurrent(current + 1); //ถัดไป
  }

  async function handleCurrent(cur) {
    try {
      //TODO ปิดไว้ตอนเปลี่ยน Step//
      // if (!props.isView) {
      //   await form.validateFields();
      // }

      setLoadingPage(true);
      /* ------------- เช็ค Field ที่ยังไม่ได้กรอก ------------ */
      // await form.validateFields()
      /* ------------------------------------------------ */
      let fieldValue = form.getFieldsValue(true);
      handleFields(fieldValue); //  กำหนด Field ทั้งหมดใหม่ก่อนเปลี่ยน Step
      setCurrent(cur); //  กำหนด Step ที่เปลี่ยน
      setStatusStep(""); //  รีเซ็ตสถานะ Step
    } catch (error) {
      //TODO ปิดไว้ตอนเปลี่ยน Step//
      // Modal.error(
      //   ERROR_MODAL(KEY_MODAL.VALIDATE_CHANGE_TAB, error.errorFields)
      // );
      setLoadingPage(false);
      //! line Noti
      APILineNoti({
        header: `FRONTEND ${userLogin} [${userLocation}]`,
        title: `📍 handleCurrent (${error.errorFields.length})`,
        message: `📑 errorFields : ${JSON.stringify(
          renderFieldError(error.errorFields)
        )}`,
      });
    } finally {
      setLoadingPage(false);
    }
  }
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- FUNCTION COLLAPSE --------------------------- */
  function handleCollapse() {
    return setCollapseDefault({
      collapsible: "",
      activekey: [...Array(12).keys()], //!เช็คให้ เปิด Collapse ทั้งหมด lenght ที่ 12
    });
  }
  /* -------------------------------------------------------------------------- */
});

export default ManagePersonal;
