import React, { createContext, useReducer, useContext } from "react";
import { MODAL_TYPE } from "../../constants/enums";
import { IndicatorContext } from "../manage/indicatorProvider";


export const ModalContext = createContext();

const initialState = {
    [MODAL_TYPE.EVALUATION_INDICATOR]: false,
    [MODAL_TYPE.EVALUATION_SCORE]: false,
    [MODAL_TYPE.APPROVAL_INDICATOR]: false,
    [MODAL_TYPE.APPROVAL_VIEW]: false,
    [MODAL_TYPE.INDICATOR_VIEW]: false,
    loading: false
}

const MODAL_ACTION = {
    SHOW_MODAL_SUCCESS: "SHOW_MODAL_SUCCESS",
    SHOW_MODAL_FAILURE: "SHOW_MODAL_FAILURE",

}

const modalReducer = (state, action) => {
    switch (action.type) {
        case MODAL_ACTION.SHOW_MODAL_SUCCESS:
            return state = {
                ...state,
                ...action.state
            }
        case MODAL_ACTION.SHOW_MODAL_FAILURE:
        default:
            return state
    }
}

const ProviderModal = ({ children }) => {
    const [modalState, modalDispatch] = useReducer(modalReducer, initialState)

    function showModal(modal) {
        modalDispatch({ type: MODAL_ACTION.SHOW_MODAL_SUCCESS, state: { [modal]: true } })
    }

    function closeModal(modal) {
        modalDispatch({ type: MODAL_ACTION.SHOW_MODAL_SUCCESS, state: { [modal]: false } })
    }


    return (
        <ModalContext.Provider
            value={{
                showModal,
                closeModal,
                modalState,
                modalDispatch
            }}>
            {children}
        </ModalContext.Provider>
    )
}


export default ProviderModal