import React from "react"
import { Space } from "antd";
import { useButton } from "../../conponents/buttons/CustomButtonAntd";
import { filterSearchColumn } from "../../functions/UseFunction";

export const ColumnChangeStatusWorker = ({ FnShowLog = () => null, FnRollback = () => null }) => [
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "Location",
        dataIndex: "location",
        key: "location",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("location", "Location"),
    },
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "NickName",
        key: "NickName",
        align: "left",
        width: 100,
        ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorkerDes",
        key: "statusWorkerDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "สายอนุมัติ",
        dataIndex: "hrmFlowDes",
        key: "hrmFlowDes",
        align: "left",
        width: 300,
        ...filterSearchColumn("hrmFlowDes", "สายอนุมัติ"),
    },
    {
        title: "สายดูข้อมูล",
        dataIndex: "viewFlowDes",
        key: "viewFlowDes",
        align: "left",
        width: 300,
        ...filterSearchColumn("viewFlowDes", "สายดูข้อมูล"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 200,
        ...filterSearchColumn("positionName", "ตำแหน่งงาน"),
    },
    {
        title: "ดูรายการโอนย้าย",
        key: "log",
        align: "left",
        fixed: "right",
        width: 100,
        render: (record) => {
            return (
                <Space size="middle" wrap>
                    {useButton({
                        call: "view",
                        moreProps: { label: "ดู" },
                        fn: () => FnShowLog(record),
                        isDisable: +record.statusTransfer !== 0 ? true : false,
                    })}
                </Space>
            );
        },
    },
    {
        title: "จัดการข้อมูล",
        key: "action",
        align: "left",
        fixed: "right",
        width: 100,
        render: (record) => {
            return (
                <Space size="middle" wrap>
                    {useButton({
                        call: "normal",
                        moreProps: { label: "ยกเลิก", type: "danger" },
                        fn: () => FnRollback(record),
                        isDisable: +record.statusTransfer !== 0 ? true : false,
                    })}
                </Space>
            );
        },
    },
];

export const ColumnTransferLogStatusWorker = [
    {
        title: "วันที่",
        dataIndex: "actionDate",
        key: "actionDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("actionDate", "วันที่"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorker",
        key: "statusWorker",
        align: "left",
        width: 200,
        ...filterSearchColumn("statusWorker", "สถานะพนักงาน"),
    },
    {
        title: "สถานะพนักงาน (ใหม่)",
        dataIndex: "newStatusWorker",
        key: "newStatusWorker",
        align: "left",
        width: 200,
        ...filterSearchColumn("newStatusWorker", "สถานะพนักงาน"),
    },
];