import { CONFIG } from "../components/connectAPI.js";
import { create } from "apisauce";
import { GET_UserLogin } from "../screens/functions/UseFunction.js";

export * from "./APIMaster.js";
export * from "./APIProbation.js";
export * from "./APIPermission";
export const authenLink = process.env.REACT_APP_AUTHEN_LINK;
export const qrLink = process.env.REACT_APP_QR_LINK;

// export const authenLink = "https://central.zetta-system.com/account/login";
// export const authenLinkDEV = "https://dev-central.zetta-system.com/account/login";
// export const qrLink = "https://central.zetta-system.com/Assets_ConfirmationLogin";
// export const qrLinkDEV = "https://dev-central.zetta-system.com/Assets_ConfirmationLogin";

const api = create({
	baseURL: `${CONFIG.API_URL}`,
	headers: {
		"Content-Type": "application/json",
		"Cache-Control": "no-cache",
	},
	timeout: 300000, // 3 minutes
});

const api_auth = create({
	baseURL: `${CONFIG.API_URL}`,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Cache-Control": "no-cache",
	},
	timeout: 300000, // 3 minutes
})

const apiupload = create({
	baseURL: `${CONFIG.API_URL}`,
	withCredentials: true,
	headers: {
		"Content-Type": "multipart/form-data",
		// 'Accept': 'multipart/form-data'
		// 'Accept': 'multipart/form-data'
	},
});

const withoutToken = (token, headers = {}) => {
	if (token) {
		return Object.assign({}, headers, { Authorization: `${token}` });
	}
	return Object.assign({}, headers);
};

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj, token = "") =>
	new Promise((resolve, reject) => {
		api
			.post(path, obj, {
				headers: withoutToken(token),
			})
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});
export const POST_BEARER = (path, obj, token = "") =>
	new Promise((resolve, reject) => {
		const { token } = GET_UserLogin();
		api
			.post(path, obj, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});

export const PUT = (path, obj) =>
	new Promise((resolve, reject) => {
		const { token } = GET_UserLogin();
		api
			.put(path, obj, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = "") =>
	new Promise((resolve, reject) => {
		api
			.get(
				path,
				{},
				{
					headers: withoutToken(token),
				}
			)
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});

export const GET_BEARER = (path, getToken = "") =>
	new Promise((resolve, reject) => {
		const userLogin = GET_UserLogin();
		api_auth
			.get(
				path,
				{},
				{
					headers: {
						Authorization: `Bearer ${getToken || userLogin.token}`,
					},
				}
			)
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});

export const UPLOAD = (path, formdata, token = "") =>
	new Promise((resolve, reject) => {
		apiupload
			.post(path, formdata, {
				headers: withoutToken(token),
				// 'Access-Control-Allow-Origin': '*'
				// headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then((response) => {
				if (response.ok) {
					resolve(response.data);
				} else {
					response.data
						? reject(response.data)
						: reject({ success: false, message: response.problem });
				}
			})
			.catch((err) => reject(err));
	});
//**------------------------------------------------------------- */
export const GET_USER_LIST = "/HRM_API/cent/get-user-list";
export const GET_CER_LIST = "/HRM_API/benefits/get-option-docname";
export const GET_OPTION_BENEFITS = (type, filter) =>
	`/HRM_API/benefits/get-option-all?type=${type}&filter${filter ? "=" + filter : ""
	}`;
export const GET_DASHBOARD_BENEFITS = "/HRM_API/benefits/get-dashboard";
export const GET_DETAIL_BENEFITS = "/HRM_API/benefits/get-doc-list";
export const POST_DOC_PRINT_BENEFITS = "/HRM_API/benefits/get-doc-print";
export const GET_SELECT_OPTION = (key) =>
	`${CONFIG.SELECT_OPTION_STATUS}?type=${key}`;
export const GET_SELECT_UTILITY = (key) =>
	`${CONFIG.SELECT_OPTION_UTILITY}?Option=${key}`;
export const GET_SELECT_OPTION_WORKSHIFT_ALL = () =>
	`${CONFIG.SELECT_OPTION_WORKSHIFT_ALL}`;
export const GET_SELECT_OPTION_WORKSHIFT = ({ location, company }) =>
	`/HRM_API/cent/get-workshift?companyCode=${company}&location=${location}`;
export const GET_SELECT_OPTION_DEPARTMENT_ALL = (company, location) =>
	`${CONFIG.SELECT_OPTION_DEPARTMENT_ALL}?company=${company}&location=${location}`;
export const GET_SELECT_OPTION_SUB_DEPARTMENT = (depNo) =>
	`${CONFIG.SELECT_OPTION_SUB_DEPARTMENT}?depNo=${depNo}`;
export const GET_SELECT_OPTION_POSITION = (company, depNo) =>
	`${CONFIG.SELECT_OPTION_POSITION}?company=${company}${depNo ? `&depNo=${depNo}` : ""}`;
export const GET_SELECT_HRM_FLOW = (company, location) =>
	`${CONFIG.FACTORY.SELECT_HRM_FLOW}?company=${company}&location=${location}`;
export const GET_SELECT_OPTION_ADDRESS_BY_POSTCODE = (postcode) =>
	`${CONFIG.SELECT_OPTION_ADDRESS_BY_POSTCODE}?postcode=${postcode}`;
export const GET_SELECT_MASTER_OPTION = () => `${CONFIG.MASTER.SELECT_OPTIONS}`;
export const GET_SELECT_OPTION_FLOW = (userId) =>
	`${CONFIG.SELECT_OPTION_FLOW}?userId=${userId}`;
export const GET_SELECT_OPTION_MENTOR = (depNo) =>
	`${CONFIG.SELECT_OPTION_MENTOR}?depNo=${depNo}`;
export const GET_SELECT_PERMISSION = (userID) =>
	`/HRM_API/cent/getOptionFilter?userId=${userID}`;
export const GET_SELECT_OPTION_ALL = () => `/HRM_API/cent/selectOptionAll`;
export const GET_SELECT_EMPLOYEE_HISTORY = (query = "") =>
	`/HRM_API/hist/empName${query}`;
export const GET_SELECT_OPTION_RECRUITER_GRADE = `/HRM_API/cent/selectOptionStatus?type=recruiterGrade`;
export const GET_SELECT_OPTION_RECRUITER_SCORE = `/HRM_API/cent/selectOptionStatus?type=recruiterScore`;
export const GET_SELECT_OPTION_SALE_CODE = `/HRM_API/empl/selectDataSaleCode`;

export const POST_SELECT_EMPLOYEE = `/HRM_API/empl/selectDataEmployeeAll`;
export const POST_SELECT_DATA_DASHBOARD_EMPLOYEE = `/HRM_API/empl/selectDataDashboardEmployee`;
export const POST_SELECT_DATA_DASHBOARD_VIEW = `/HRM_API/empl/selectDataDashboardEmployeeUnderling`;


//TODO//
export const POST_SELECT_DATA_DASHBOARD_EMPLOYEE_NEW = `/HRM_API/empl/selectDataDashboardEmployee-new`;
//TODO//

export const SELECT_DATA_DASHBOARD = ({
	type,
	segment,
	year,
	userLogin,
	statusCode,
}) =>
	`/HRM_API/select_dataDashboard?type=${type}&SegmentTxt=${segment}&years=${year}&userID=${userLogin}&statusCode=${statusCode}`;

export const POST_UPDATE_GRADE = `/HRM_API/pms/updateGrade`;

export const SELECT_DASHBOARD_PROBATION = `/HRM_API/prob/selectDashboardProbation`;
export const SELECT_DASHBOARD_PROBATION_VIEW = `/HRM_API/prob/selectDashboardProbationView`;
export const POST_INSERT_PROBATION = `/HRM_API/prob/insertProbation`;
export const POST_UPDATE_PROBATION = `/HRM_API/prob/updateProbation`;

export const GET_SELECT_DEPARTMENT_SUPER_ADMIN = () => `/HRM_API/select_option`;
export const SELECT_DASHBOARD_RECHECK_GRADE = ({
	SegmentTxt,
	yearsTxt,
	userLogin,
	statusCode,
	premission,
}) =>
	`/HRM_API/pms/selectDataDashboardRecheckGrade?segmentTxt=${SegmentTxt}&years=${yearsTxt}&userID=${userLogin}&statusCode=${statusCode}&permission=${premission}`;

export const SELECT_REPORT_SUMMARY_PEF = ({
	SegmentTxt,
	yearsTxt,
	CF_HR,
	userLogin,
	premission,
}) =>
	`/HRM_API/select_dataReportSummaryPEF?SegmentTxt=${SegmentTxt}&years=${yearsTxt}&userID=${userLogin}&statusCF=${CF_HR}&premission=${premission}`;
export const POST_REPORT_SUMMARY_PEF = `/HRM_API/pms/selectReportSummaryPEF`;

export const POST_INSERT_SCORE = `/HRM_API/insert_score`;

export const POST_INSERT_DAY_FORM_DAILY_WORKER = `/HRM_API/kpis/insertDayFormDailyWorker`;

export const SELECT_DAY_FORM_DAILY_WORKER = ({ yearsTxt, monthTxt }) =>
	`/HRM_API/kpis/selectReportCalRevenueGoodGrade?year=${yearsTxt}&month=${monthTxt}`;

export const POST_SELECT_DATA_MOVEMENT = `/HRM_API/report/selectDataMovement`;
export const POST_GET_DATA_JG_PG = `/HRM_API/empl/selectDataJGPG`;
export const POST_UPDATE_DATA_JG_PG = `/HRM_API/empl/updateDataJGPG`;

export const POST_SELECT_DASHBOARD_PROBATION = `/HRM_API/prob/selectDashboardProbation`;
export const POST_SELECT_DASHBOARD_PROBATION_NEW = `/HRM_API/prob/selectDashboardProbation-new`;
export const EDIT_DOC_STATUS = `/HRM_API/prob/edit-docNo-status`;
export const POST_SELECT_DATA_PROBATION = `/HRM_API/prob/selectDataProbation`;
export const POST_UPDATE_RECEIVED_DOC = (_doc) =>
	`/HRM_API/update_ReceivedProbation?DocNo=${_doc}`;
export const POST_UPDATE_RECEIVED = `/HRM_API/prob/updateReceivedProbation`;
export const POST_UPDATE_END_DATE_PROBATION = `/HRM_API/prob/updateEndDateProbation`;
export const POST_DEL_DOC_PROBATION = `/HRM_API/prob/delDocProbation`;
export const POST_SELECT_DASHBOARD_CF_BENEFIT_CONTRACT = `/HRM_API/prob/selectDataDashboardCFBenefitsContract`;
export const GET_SLECT_DASHBOARD_PROBATION_OUT = ({
	user_login,
	locationID = "all",
	SegmentTxt = "all",
	statusWorker = "all",
	statusMainCode = "all",
	statusProbation = 2,
	txtUserSeaching,
	StartDateJob,
	toDateJob,
}) =>
	`/HRM_API/select_dataDashboardBenenfitsOut?user_login=${user_login}&locationID=${locationID}&SegmentTxt=${SegmentTxt}&statusWorker=${statusWorker}&statusProbation=${statusProbation}&txtUserSeaching=${txtUserSeaching}&statusMainCode=${statusMainCode}&StartDateJob=${StartDateJob}&toDateJob=${toDateJob}`;

export const POST_SELECT_DASHBOARD_MOVEMENT = `/HRM_API/movement/selectDashboardMovement`;
export const POST_INSERT_DATA_MOVEMENT = `/HRM_API/movement/insertDataMovement`;
export const POST_SELECT_DATA_LOGS_NOT_TRANSFER = `/HRM_API/movement/selectDataLogsNotTransfer`;
export const POST_ROLL_BACK_MOVEMENT = `/HRM_API/movement/rollbackMovement`;

export const GET_SELECT_HRTEAM = `/HRM_API/empl/selectDataHrTeam`;

export const POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE = `/HRM_API/report/selectDataReportReturnEmployee`;
export const POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE_DETAIL = `/HRM_API/report/selectDataReportReturnEmployeeDetail`;

// export const POST_SELECT_DATA_EMPLOYEE_ALL = `/HRM_API/empl/selectDataEmployeeAll`
export const POST_SELECT_DATA_REQ_OT_DETAIL = `/HRM_API/rotf/selectDataReqOTDetail`;
export const POST_CANCEL_REQ_OT = `/HRM_API/rotf/cancelReqOT`;
export const POST_UPDATE_TIME_REQ_OT_BY_LINE = `/HRM_API/rotf/updateTimeReqOTbyLine`;
export const POST_SELECT_DATA_DASHBOARD_EDIT_OT = `/HRM_API/rotf/selectDataDashboardEditOT`;
export const POST_SELECT_DASHBOARD_BENEFITS_2Y = `/HRM_API/benefits/selectDashboardBenefits2Y`;
export const POST_UPDATE_PLAN_INSURANCE = `/HRM_API/benefits/updatePlanInsurance`;
export const POST_INSERT_SEND_SSO = `/HRM_API/benefits/insertStampBenefits`;
export const POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_IN = `/HRM_API/benefits/selectDashboardTransferBenefitsIn`;
export const POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_OUT = `/HRM_API/benefits/selectDashboardTransferBenefitsOut`;
export const POST_SELECT_DASHBOARD_BENEFITS_NEW_EMP = `/HRM_API/benefits/selectDashboardBenefitsNewEmp`;
export const POST_SELECT_DASHBOARD_BENEFITS_NEW_EMP_TEMP = `/HRM_API/benefits/selectDashboardBenefitsNewEmpTemp`;
export const POST_SELECT_DASHBOARD_BENEFITS_OUT = `/HRM_API/benefits/selectDashboardBenefitsOut`;
export const POST_UPDATE_CHECK_PVD = `/HRM_API/benefits/updateChqPVD`;
export const POST_SELECT_DATA_TURN_OVER_RATE = `/HRM_API/report/selectDataTurnoverRate`;
export const POST_SELECT_DATA_WARNING = `/HRM_API/report/selectDataWarning`;
export const POST_SELECT_DATA_BONUS = `/HRM_API/report/selectDataBonus`;
export const POST_SELECT_DASHBOARD_ACTION_NEW_EMP = `/HRM_API/sso/selectDashboardActionNewEmp`;
export const POST_SELECT_DASHBOARD_ACTION_OUT_EMP = `/HRM_API/sso/selectDashboardActionOutEmp`;
export const POST_SELECT_DATA_PERFORMANCE_RECRUITER = `/HRM_API/report/selectDataPerformanceRecruiter`;
export const POST_SELECT_DATA_SCORE_RECRUITER = `/HRM_API/report/selectDataScoreRecruiter`;
export const POST_SELECT_DATA_CHARGE_EMPLOYEE = `/HRM_API/report/selectDataChargeEmployee`;
export const POST_SELECT_DATA_RECHECK_PROBATION = `/HRM_API/report/selectDataRecheckProbation`;
export const POST_SELECT_DATA_RECHECK_EMPLOYEE = `/HRM_API/report/selectDataRecheckEmployee`;

export const GET_SELECT_REPORT_LEVEL_WORKER = (query) =>
	`/HRM_API/report/selectLevelWorker?${query}`;
export const GET_SELECT_REPORT_EMPLOYEE_AGE = (query) =>
	`/HRM_API/report/selectAge?${query}`;
export const GET_SELECT_REPORT_WORK_AGE = (query) =>
	`/HRM_API/report/selectAgeOfWork?${query}`;
export const GET_SELECT_REPORT_ALL_PROFILE = (query) =>
	`/HRM_API/report/selectAll?${query}`;
export const POST_SELECT_REPORT_EMPLOYEE_SUMMARY = (query) =>
	`/HRM_API/report/selectEmpAmount?${query}`;
export const GET_SELECT_REPORT_STATUS_WORKER = `/HRM_API/report/selectStatusWorker`;
export const POST_SELECT_DATA_CRIME = `/HRM_API/report/selectDataCrime`;
export const POST_SELECT_DATA_DASHBOARD_CRIME_ACTION = `/HRM_API/empl/selectDataDashboardCrimeAction`;
export const POST_SELECT_DATA_CRIME_HEADER = `/HRM_API/empl/selectDataCrimesHeader`;
export const POST_SELECT_DATA_CRIME_LINE = `/HRM_API/empl/selectDataCrimeLine`;
export const POST_SELECT_DATA_CRIME_MULTIPLE = `/HRM_API/empl/updateDataCrimeMultiple`;
export const INSERT_PERSONAL_ID_CARD = "/HRM_API/empl/insertNewEmpByIdCard";
export const GET_IMPORT_PERSONAL_ID_CARD =
	"/HRM_API/empl/getImportNewEmpByIdCard";

export const GET_SELECT_DASHBOARD_PLAN_KNOWLEAGE = ({
	typeTXt,
	SegmentTxt,
	yearsTxt,
}) =>
	`/HRM_API/pms/selectDataReportPlanKM?type=${typeTXt}&&SegmentTxt=${SegmentTxt}&&years=${yearsTxt}`;

/**Commission web */
export const GET_RECHECK_WORKING_EMPLOYEE =
	"/HRM_API/commission/getRecheckWorkingEmployee";
export const GET_NEW_SALE_EMPLOYEE = "/HRM_API/commission/getNewSaleEmployee";
export const INSERT_UPDATE_FSP_EMPLOYEE =
  "/HRM_API/commission/fsp/insertUpdateSaleFSP";
export const GET_SALE_FSP = "/HRM_API/commission/fsp/get-sale";
export const POST_SAVE_TARGET = "/HRM_API/commission/fsp/save-target";
export const GET_TARGET = "/HRM_API/commission/fsp/get-target";

export const INSERT_UPDATE_SALE_EMPLOYEE =
	"/HRM_API/commission/insertUpdateSaleEmployee";
export const GET_INCOME_GROUP = "/HRM_API/commission/getIncomGroup";
export const CHECK_IMPORT_INCENTIVE_EXCEL =
	"/HRM_API/commission/checkImportIncentiveExcel";
export const INSERT_UPDATE_INCENTIVE_EXCEL =
	"/HRM_API/commission/insertUpdateIncentiveExcel";
export const GET_MAP_SALE_AREA_LIST = "/HRM_API/commission/getMapSaleAreaList";
export const GET_MAP_SALE_MO = "/HRM_API/commission/fsp/getMapSaleMo";

export const INSERT_UPDATE_SALE_AREA =
  "/HRM_API/commission/insertUpdateSaleArea";
export const INSERT_UPDATE_SALE_AREA_FSP =
  "/HRM_API/commission/fsp/insertUpdateSaleArea";
export const GET_COMMISSION_INCENTIVE =
  "/HRM_API/commission/getCommissionIncentive";
export const GET_COMMISSION_INCENTIVE_FSP =
  "/HRM_API/commission/fsp/getCommissionIncentiveFSP";
export const INSERT_UPDATE_COMMISSION_INCENTIVE =
  "/HRM_API/commission/insertUpdateCommissionIncentive";
export const INSERT_UPDATE_COMMISSION_INCENTIVE_FSP =
  "/HRM_API/commission/fsp/insertUpdateCommissionIncentiveFSP";
export const GET_PERMISSION_USER_COMMISSION =
	"/HRM_API/commission/getPermissionUser";
export const GET_COMMISSION_INCENTIVE_DASHBOARD =
  "/HRM_API/commission/getCommissionIncentiveDashboard";
export const GET_COMMISSION_INCENTIVE_DASHBOARD_FSP =
  "/HRM_API/commission/fsp/getCommissionIncentiveDashboardFSP";
export const GET_COMMISSION_INCENTIVE_BY_DOCNO =
  "/HRM_API/commission/getCommissionIncentiveByDocNo";
export const GET_COMMISSION_INCENTIVE_BY_DOCNO_FSP =
  "/HRM_API/commission/fsp/getCommissionIncentiveFSPByDocNo";
export const UPDATE_STATUS_SAVE_COMMISSION =
  "/HRM_API/commission/updateStatusSave";
export const UPDATE_STATUS_SAVE_COMMISSION_FSP =
  "/HRM_API/commission/fsp/updateStatusSave";
export const GET_CUTOFF_DASHBOARD = "/HRM_API/commission/getCutOffDashboard";
export const GET_CUTOFF_DASHBOARD_FSP = "/HRM_API/commission/fsp/getCutOffDashboardFSP";
export const GET_SALE_CODE_OPTION = "/HRM_API/commission/getSaleCodeOption";
export const GET_SALE_CODE_OPTION_FSP =
  "/HRM_API/commission/getSaleCodeOption?group=FSP";
export const GET_FOCUS_SHOP_STORE_MASTER = "/HRM_API/commission/getFocusShopStoreMaster";
export const GET_FILTER_OUT_SALES_EMP_COMMISSION = "/HRM_API/commission/getFilterOutSalesEmpCommssion";
export const INSERT_FILTER_OUT_SALES_EMP_COMMISSION = "/HRM_API/commission/insertFilterOutSalesEmpCommission";
export const GET_CUTOFF_CONFIRM_REPORT =
	"/HRM_API/commission/getCutOffConfirmReport";
export const GET_CUTOFF_CONFIRM_REPORT_FSP =
	"/HRM_API/commission/fsp/getCutOffConfirmReportFSP";
export const GET_SALE_LIST_BY_APPROVER1 =
	"/HRM_API/commission/getSaleListByApprover1";
export const GET_SUMMARY_COM_INC_CUTOFF_REPORT =
	"/HRM_API/commission/getSummaryComIncCutOffReport";
export const GET_SUMMARY_COM_INC_CUTOFF_REPORT_FSP =
	"/HRM_API/commission/fsp/getSummaryComIncCutOffReportFSP";
export const GET_CUTOFF_APPROVE_SPECIAL_REPORT =
	"/HRM_API/commission/getCutOffApproveSpecialReport";
export const GET_CUTOFF_APPROVE_SPECIAL_REPORT_FSP =
	"/HRM_API/commission/fsp/getCutOffApproveSpecialReportFSP";
export const GET_MAP_FOCUS_SHOP_SALE_CODE =
	"/HRM_API/commission/getMapFocusShopSaleCode";
export const IMPORT_MAP_FOCUS_SHOP_SALE_CODE_EXCEL =
	"/HRM_API/commission/importMapFocusShopSaleCodeExcel";
export const IMPORT_MAP_FOCUS_SHOP_SALE_CODE_BY_ROWS =
	"/HRM_API/commission/importMapFocusShopSaleCodeByRows";
export const UPDATE_SALE_DATA_BY_VIEW =
	"/HRM_API/commission/updateSaleDataByBI";
export const UPDATE_SALE_DATA_BY_VIEW_FSP =
	"/HRM_API/commission/fsp/getUpdateSaleDataFromBI";
export const CHANGE_PASS = "/HRM_API/cent/changePassword";


/** PMS */
//ประเมินตนเอง//
export const GET_DETAIL_SAF = (_doc) =>
	`/HRM_API/pms/selectDataSelfAssDetail?docNo=${_doc}`;
export const POST_SELECT_DATA_SELF_ASSESSMENT = `/HRM_API/pms/selectDataSelfAss`;
export const POST_INSERT_SELF_ASSESSMENT = `/HRM_API/pms/insertSelfAssessment`;
export const POST_UPDATE_SELF_ASSESSMENT = `/HRM_API/pms/updateSelfAssessment`;
export const DEL_SELF_ASSESSMENT = `/HRM_API/pms/deleteDataSelfAss`;

//ประเมินผลงาน//
export const SELECT_DATA_PE_HISTORY = ({ years, staffCode }) =>
	`/HRM_API/pms/selectDataPEHistory?years=${years}&staffCode=${staffCode}`;
export const SELECT_DATA_PE = (docNo) =>
	`/HRM_API/pms/selectDataPerformanceEval?docNo=${docNo}`;
export const SELECT_DASHBOARD_PERFORMANCE = ({
	SegmentTxt,
	yearsTxt,
	userLogin,
	statusCode,
}) =>
	`/HRM_API/pms/selectDataDashboardPerformance?segmentTxt=${SegmentTxt}&years=${yearsTxt}&userID=${userLogin}&statusCode=${statusCode}`;
export const POST_INSERT_PERFORMANCE_EVAL = `/HRM_API/pms/insertPerformanceEval`;
export const POST_UPDATE_PERFORMANCE_EVAL = `/HRM_API/pms/updatePerformanceEval`;

//ประเมินหารือ//
export const GET_DETAIL_DIS = (_doc) =>
	`/HRM_API/pms/selectViewDataDiscussion?docNo=${_doc}`;
export const SELECT_DASHBOARD_DISCUSSION = ({
	typeTXt,
	SegmentTxt,
	yearsTxt,
	userLogin,
	statusCode,
}) =>
	`/HRM_API/pms/selectDataDashboardDiscussion?type=${typeTXt}&segmentTxt=${SegmentTxt}&years=${yearsTxt}&userID=${userLogin}&statusCode=${statusCode}`;
export const POST_INSERT_DISCUSSION = `/HRM_API/pms/insertDiscussion`;
export const POST_UPDATE_DISCUSSION = `/HRM_API/pms/updateDiscussion`;

//ยืนยันผลหารือ//
export const SELECT_DASHBOARD_CF_DISCUSSION = ({
	typeTXt,
	SegmentTxt,
	yearsTxt,
	userLogin,
	statusCode,
}) =>
	`/HRM_API/pms/selectDataDashboardCFDIS?type=${typeTXt}&segmentTxt=${SegmentTxt}&years=${yearsTxt}&userID=${userLogin}&statusCode=${statusCode}`;

export const SELECT_DATA_CF_DISCUSSION = ({ docNo }) =>
	`/HRM_API/pms/selectDataDiscussion?docNo=${docNo}`;
export const POST_UPDATE_CF_DIS = `/HRM_API/pms/updateCFDiscussion`;

// import Grade//
export const POST_INSERT_GRADE = `/HRM_API/pms/insertGrade`;

/* dashboardTraining */
export const GET_EXPORT_TEMPLATE_OJT = (query = "") =>
	`/HRM_API/training/export-template-ojt${query}`;
export const POST_IMPORT_OJT = `/HRM_API/training/import-ojt`;

/* IT TASK*/
export const SELECT_DASHBOARD_ASSET_EMP = ({
	userLogin,
	EmpID,
	Name,
	isWork,
}) =>
	`/HRM_API/ittask/select-dashboard-asset-emp?UserID=${userLogin}&EmpID=${EmpID}&Name=${Name}&isWork=${isWork}`;
export const GET_USER_IN = (depNo) =>
	`/HRM_API/empl/get-user-in?depNo=${depNo}`;
export const GET_USER_OUT = (depNo) =>
	`/HRM_API/empl/get-user-out?depNo=${depNo}`;

export const SELECT_USER_LIST = ({ LevelWorker }) =>
	`/HRM_API/ittask/select_UserList?LevelWorker=${LevelWorker}`;

export const SELECT_ASSET_POSSESSION = (userID) =>
	`/HRM_API/ittask/select-asset-in-possession/${userID}`;
export const SELECT_PRINT_CF_EMP_OUT = (userID) =>
	`/HRM_API/ittask/select-print-confirm-employee-quit?UserID=${userID}`;
export const SELECT_DATA_ASSET_TEMPLATE = ({ DivisionID, LevelID, UserID }) =>
	`/HRM_API/ittask/select-data-assets-template?DivisionID=${DivisionID}&LevelID=${LevelID}&UserID=${UserID}`;

export const POST_INSERT_TRANS_EMP_NEW = `/HRM_API/ittask/insert-trans-employee-new`;
export const POST_INSERT_TRANS_EMP_QUIT = `/HRM_API/ittask/insert-trans-employee-quit`;
export const POST_SELECT_DATA_ASSETS_FORM = `/HRM_API/ittask/select-data-assets-form`;
export const POST_SELECT_TRANS_EMP = `/HRM_API/ittask/select-trans-employee`;

export const POST_GET_WAIT_CONFIRM_OUT = `/HRM_API/empl/lark/get-wait-confirm-out`;
export const POST_CONFIRM_OUT = `/HRM_API/empl/lark/confirm-out`;
export const SELECT_APPROVE_FROM = `/HRM_API/cent/selectApprovedForm`;

export const GET_OPTION_UTILITY = (option) =>
	`/HRM_API/itTask/select_OptionUtility?Option=${option}`;
export const GET_TASK_USER_IN = ({ userId, bossId, depNo }) =>
	`/HRM_API/empl/get-task-user-in?userId=${userId}&bossId=${bossId}&depNo=${depNo}`;
export const GET_DETAIL_TASK_USER_IN = (userId) =>
	`/HRM_API/empl/get-detail-task-in?userId=${userId}`;
export const GET_DETAIL_TASK_USER_IN_MOBILE = (userId) =>
	`/HRM_API/empl/get-detail-task-in-mobile?userId=${userId}`;
export const GET_TASK_USER_OUT = ({ userId, bossId, depNo, showAll }) =>
	`/HRM_API/empl/get-task-user-out?userId=${userId}&bossId=${bossId}&depNo=${depNo}&showAll=${showAll}`;
export const GET_DETAIL_TASK_USER_OUT = (userId) =>
	`/HRM_API/empl/get-detail-task-out?userId=${userId}`;

export const PUT_STAMP_TASK_IN = () => `/HRM_API/empl/stamp-task-in`;
export const PUT_CONFIRM_TASK_IN = () => `/HRM_API/empl/confirm-user-in`;
export const PUT_CONFIRM_RECEIVED = () => `/HRM_API/empl/confirm-user-in-received`;
export const PUT_STAMP_TASK_OUT = () => `/HRM_API/empl/stamp-task-out`;
export const PUT_CONFIRM_TASK_OUT = () => `/HRM_API/empl/confirm-user-out`;

export const GET_DETAIL_OUT = (userId) =>
	`/HRM_API/empl/get-detail-out?userId=${userId}`;
export const GET_DETAIL_IN = (userId) =>
	`/HRM_API/empl/get-detail-in?userId=${userId}`;
export const POST_ADD_USER_OUT = () => `/HRM_API/empl/add-user-out`;
export const POST_ADD_USER_IN = () => `/HRM_API/empl/add-user-in`;
export const GET_SELECT_IMPORT_EMP_FAC = (candidateNo) => `/HRM_API/empl/selectImportEmpFac?candidateNo=${candidateNo || ''}`;
export const GET_SELECT_IMPORT_EMP = (candidateNo) => `/HRM_API/empl/selectImportEmp?candidateNo=${candidateNo || ''}`;

//V3.3
export const GET_DOWNLOAD_MONITOR_FILE = `/HRM_API/empl/download-monitor-file`;
export const GET_MONITOR_FILE_DASHBOARD = `/HRM_API/empl/monitor-file-dashboard`;
export const GET_MONITOR_FILE_DASHBOARD_VIEW = `/HRM_API/empl/monitor-file-dashboard-view`;
export const SAVE_MONITOR_FILE_STATUS = `/HRM_API/empl/save-monitor-file`;
export const GET_DASHBOARD_FORM_CONTRACT = "/HRM_API/empl/form-contract-dashboard";
export const GET_FORM_HIRE_DETAIL = "/HRM_API/empl/form-hire-detail";
export const GET_FORM_HIRE_DETAIL_PRINT = "/HRM_API/empl/form-hire-detail-print";
export const GET_FORM_CONTRACT_DETAIL = "/HRM_API/empl/form-contract-detail";
export const GET_FORM_CONTRACT_DETAIL_PRINT = "/HRM_API/empl/form-contract-detail-print";
export const POST_SAVE_LOG_PRINT = "/HRM_API/empl/save-log-print";
export const GET_OPTION_SIGNER = () => "/HRM_API/empl/option-signer";
export const GET_GUARANTEE_COST_CENTER_LIST = "/HRM_API/empl/guarantee-cost-center-list";
export const GET_RUNNING_NO = "/HRM_API/cent/selectRunningNo";

// Forgot Password
export const FORGOT_PASSWORD_REQUEST = "/HRM_API/cent/forwardMail";
export const FORGOT_PASSWORD = "/HRM_API/cent/forgotPassword";

