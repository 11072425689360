import React, { Component } from "react";
import "../index.css";
import { FiPrinter } from "react-icons/fi";
import { browserHistory } from "react-router";
import { Col, FormGroup, Label, Card, Row, CardBody, Button, Container } from "reactstrap";
import {
  GET,
  SELECT_PRINT_CF_EMP_OUT
} from "../service";
class Assets_QuitFormPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: "",
      resultViews: "",
      assetLists: [],
      note: "",
      userLogin: this.props.userLogin,
      styleBorder: { padding: "6px", textAlign: "center" },
      styleBorders: { padding: "6px", textAlign: "left" },
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let UserID = params.get("UserID"); // is the string "Jonathan"
    this.setState({ UserID: UserID }, () => {
      this.ViewPrintConfirmEmployeeQuit();
    });
  }

  //---------------------------Call API-----------------------

  ViewPrintConfirmEmployeeQuit = async () => {
    const userID = this.state.UserID;
    const res = await GET(SELECT_PRINT_CF_EMP_OUT(userID));
    const { result } = res

    let profiles = result.profile;
    let confirmInfos = result.confirmInfo;

    this.setState({
      //-----------------Profile-------------------
      FullName: `${profiles.ID_User} ${profiles.FullName}`,
      SupName: `${profiles.SupID} ${profiles.SupName}`,
      EndDateJob: profiles.EndDateJob,
      Location: profiles.Location,
      Level_Worker: profiles.Level_Worker,
      DepartmentName: profiles.DepartmentName,

      //------------------confirmInfo------------------
      ITFixedAsset: (
        <React.Fragment>
          {confirmInfos.ITFixedAssetUser_End}
          <br />
          {confirmInfos.ITFixedAssetCompletedDate_End}
          <br />
          {confirmInfos.ITFixedAssetNote_End}
        </React.Fragment>
      ),
      ITDomain: (
        <React.Fragment>
          {confirmInfos.ITDomainUser_End}
          <br />
          {confirmInfos.ITDomainCompletedDate_End}
          <br />
          {confirmInfos.ITDomainNote_End}
        </React.Fragment>
      ),
      ITEmail: (
        <React.Fragment>
          {confirmInfos.ITEmailUser_End}
          <br />
          {confirmInfos.ITEmailCompletedDate_End}
          <br />
          {confirmInfos.ITEmailNote_End}
        </React.Fragment>
      ),
      ITSoftware: (
        <React.Fragment>
          {confirmInfos.ITSoftwareUser_End}
          <br />
          {confirmInfos.ITSoftwareCompletedDate_End}
          <br />
          {confirmInfos.ITSoftwareNote_End}
        </React.Fragment>
      ),
      // PurchaseFixed: (
      //   <React.Fragment>
      //     {confirmInfos.PurchaseFixedUser_End}
      //     <br />
      //     {confirmInfos.PurchaseFixedCompletedDate_End}
      //     <br />
      //     {confirmInfos.PurchaseFixedNote_End}
      //   </React.Fragment>
      // ),
      Account: (
        <React.Fragment>
          {confirmInfos.AccountUser_End}
          <br />
          {confirmInfos.AccountCompletedDate_End}
          <br />
          {confirmInfos.AccountNote_End}
        </React.Fragment>
      ),

      Note_End: confirmInfos.Note_End,

      //------------------assetList------------------
      assetLists: result.assetList,
    });
  };

  //---------------------------Call API-----------------------

  //---------------------------onchange-----------------------
  onChangeNote = (e) => {
    this.setState({ note: e.target.value });
  };
  //------------------------- onChange-----------------------
  printSel = () => {
    window.print();
  };

  render() {
    const {
      FullName,
      SupName,
      EndDateJob,
      Location,
      Level_Worker,
      DepartmentName,
      assetLists,
      styleBorder,
      styleBorders,
      ITFixedAsset,
      ITDomain,
      ITEmail,
      ITSoftware,
      // PurchaseFixed,
      Account,
      Note_End,
    } = this.state;

    return (
      <div>
        <Container>
          <div id="printableArea" className="printQuitForm">
            <Card>
              <h3 style={{ textAlign: "center", marginBottom: "2em" }}>
                สรุปข้อมูลสินทรัพย์พนักงานลาออก
              </h3>
              <CardBody>
                <Row style={{ marginBottom: "12px" }}>
                  <Col sm={2}>รหัส/ชื่อพนักงาน:</Col>
                  <Col sm={4}>{FullName}</Col>
                  <Col sm={2}>รหัส/ชื่อหัวหน้า: </Col>
                  <Col sm={4}>{SupName}</Col>
                </Row>
                <Row style={{ marginBottom: "12px" }}>
                  <Col sm={2}>วันที่ลาออก: </Col>
                  <Col sm={4}>{EndDateJob}</Col>
                  <Col sm={2}>ฝ่าย: </Col>
                  <Col sm={4}>{Location}</Col>
                </Row>
                <Row style={{ marginBottom: "12px" }}>
                  <Col sm={2}>ระดับ: </Col>
                  <Col sm={4}>{Level_Worker}</Col>
                  <Col sm={2}>แผนก: </Col>
                  <Col sm={4}>{DepartmentName}</Col>
                </Row>
              </CardBody>
            </Card>
            <hr />
            <h5 style={{ marginBottom: "1em", marginTop: "2em" }}>งานที่ทำ</h5>
            <table border="1" width="100%">
              <thead style={{ backgroundColor: "#e7e7e7" }}>
                <th style={styleBorder}>IT Fixed Asset</th>
                <th style={styleBorder}>IT Domain</th>
                <th style={styleBorder}>IT Email</th>
                <th style={styleBorder}>IT Software</th>
                {/* <th style={styleBorder}>Purchase Fixed Asset</th> */}
                <th style={styleBorder}>Account</th>
              </thead>
              <tbody>
                <tr>
                  <td style={styleBorder}>{ITFixedAsset}</td>
                  <td style={styleBorder}>{ITDomain}</td>
                  <td style={styleBorder}>{ITEmail}</td>
                  <td style={styleBorder}>{ITSoftware}</td>
                  {/* <td style={styleBorder}>{PurchaseFixed}</td> */}
                  <td style={styleBorder}>{Account}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <h5 style={{ marginBottom: "1em", marginTop: "2em" }}>
              รายละเอียดสินทรัพย์ที่ต้องคืน
            </h5>
            <table border="1" width="100%">
              <thead style={{ backgroundColor: "#e7e7e7" }}>
                <th style={styleBorder}>Type</th>
                <th style={styleBorder}>Description</th>
                <th style={styleBorder}>Note</th>
              </thead>
              <tbody>
                {assetLists.map((val, index) => (
                  <tr>
                    <td style={styleBorders}>{val.AssetID}</td>
                    <td style={styleBorders}>{val.AssetName}</td>
                    <td style={styleBorders}>{val.AssetDesc}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <FormGroup style={{ marginBottom: "1em", marginTop: "2em" }}>
              <Label for="note">
                <b>เพิ่มเติม</b>
              </Label>
              <p>{Note_End}</p>
            </FormGroup>
          </div>
          <div className="no-printQuitForm">
            <Button
              color="defualt"
              onClick={() => browserHistory.push(`/Assets_NewEmpMain`)}
            >
              Back
            </Button>
            <Button color="info" onClick={this.printSel}>
              <FiPrinter /> Print
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default Assets_QuitFormPrint;
