import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from "react-icons/fi";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from "reactstrap";
import { API_URL } from "../components/connectAPI";
import { browserHistory } from 'react-router';
import { loadingPage } from '../components/loadingPage'
import * as NumericInput from "react-numeric-input";
import { getCurrent } from '../extendFunction/getCurrentYears';
import { Modal } from 'antd';
import {
	POST,
	POST_SELECT_EMPLOYEE,
	POST_INSERT_SELF_ASSESSMENT
} from "../service";
class SelfAssessmentForm extends React.Component {
	constructor(props) {
		super(props);
		const { yearsSelect, typeSelect } = getCurrent();
		this.state = {
			loadData: [],
			status_confirm: false,
			fields: {},
			errors: {},
			done: undefined,
			type: typeSelect,
			year: yearsSelect,
			cfSend: false,
			userLogin: this.props.userLogin,
			premission: this.props.premission
		};
		this.insertSAF = this.insertSAF.bind(this);
	}

	componentWillMount = async () => {
		const userLogin = this.state.userLogin;
		const res = await POST(POST_SELECT_EMPLOYEE, { userID: userLogin })

		const { success, result } = res;
		console.log(result)
		if (success) {
			this.setState({ loadData: result, done: true });

		} else {
			console.log("error selectData");
			this.setState({ loading: false });
		}

	}


	insertSAF(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		let entries = data.entries();
		let current, item, key, output, value;
		let cfSend = this.state.cfSend
		output = {};
		let errors = {};
		let formIsValid = true;
		while ((item = entries.next().value)) {
			// assign to variables to make the code more readable.
			key = item[0];
			value = item[1];
			// Check if key already exist
			if (Object.prototype.hasOwnProperty.call(output, key)) {
				current = output[key];
				if (!Array.isArray(current)) {
					// If it's not an array, convert it to an array.
					current = output[key] = [current];
				}
				current.push(value); // Add the new value to the array.
			} else {
				output[key] = value;
			}
		}

		if (!output["year"]) {
			formIsValid = false;
			errors["year"] = "Cannot be empty";
		}

		if (!output["targetSuccess"]) {
			formIsValid = false;
			errors["targetSuccess"] = "Cannot be empty";
		}

		if (!output["learnDeveloped"]) {
			formIsValid = false;
			errors["learnDeveloped"] = "Cannot be empty";
		}

		if (!output["targetNotSuccess"]) {
			formIsValid = false;
			errors["targetNotSuccess"] = "Cannot be empty";
		}

		// if (!output["causesNotSuccess"]) {
		// 	formIsValid = false;
		// 	errors["causesNotSuccess"] = "Cannot be empty";
		// }

		if (!output["barrier"]) {
			formIsValid = false;
			errors["barrier"] = "Cannot be empty";
		}


		// if (!output["solution"]) {
		// 	formIsValid = false;
		// 	errors["solution"] = "Cannot be empty";
		// }

		if (!output["km_1"]) {
			formIsValid = false;
			errors["km_1"] = "Cannot be empty";
		}

		if (!output["reason_1"]) {
			formIsValid = false;
			errors["reason_1"] = "Cannot be empty";
		}

		if (!output["feedback"]) {
			formIsValid = false;
			errors["feedback"] = "Cannot be empty";
		}

		if (!output["selfScore"]) {
			formIsValid = false;
			errors["selfScore"] = "Cannot be empty";
		}

		if (cfSend === false) {
			formIsValid = true
		}

		if (formIsValid === true) {
			console.log('output',output)
			Modal.confirm({
				title: 'คุณต้องการยืนยันการทำรายการใช่หรือไม่?',
				content: '',
				async onOk() {
					const response = await POST(POST_INSERT_SELF_ASSESSMENT, output);
					const { result } = response;
					if (result.status_api === 1) {
						Modal.success({
							title: "Insert Success!! \n" + "  DocNo = " + result.docNo,
							onOk: () => browserHistory.push("/DashboardSelfAssessment"),
						});
					} else {
						Modal.error({ title: "Insert Error!!" });
					}
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		} else {
			// alert("กรุณาระบุข้อมูลให้ครบถ้วน");
			Modal.warning({ title: "กรุณาระบุข้อมูลให้ครบถ้วน" });
			console.log(errors);
		}
	}


	onChangeCheckbox = (e) => {
		let isChecked = e.target.checked;
		//console.log(isChecked)
		this.setState({ cfSend: isChecked })
	}

	onChangeSelect = (e) => {
		let fields = this.state.fields;
		var _value = e.target.value

		let _id = e.target.id;
		fields[_id] = _value;
		this.setState({
			fields
		})
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		//console.log(fields)
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	render() {
		let data = this.state.loadData;
		let yearsSelect = getCurrent().yearsSelect
		let typeSelect = getCurrent().typeSelect
		let checkbox;
		let btnSave;
		checkbox = <div class="ui checkbox"> <Input type="checkbox" name="cfSend" id="cfSend"
			onChange={this.onChangeCheckbox} value={this.state.cfSend} defaultChecked={this.state.cfSend} />  <label>
				ยืนยันการบันทึกและส่งให้หัวหน้างาน <b>(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b></label></div>

		btnSave = <Button className="margin-right-20" >บันทึกแบบร่าง</Button>
		if (this.state.cfSend === true) {
			btnSave = <Button color="success" className="margin-right-20" >     ยืนยันข้อมูล (แก้ไขไม่ได้)</Button>
		}

		return (
			<Container>
				{this.state.done ? (
					<div>
						<Card>
							<CardHeader>
								<FiEdit3 className="margin-icon" />
								แบบฟอร์มประเมินตนเอง (SELF ASSESSMENT FORM)
							</CardHeader>
							{data.map((val, index) => {
								return (
									<CardBody>
										<Form onSubmit={this.insertSAF}>
											<Card>
												<CardHeader>ข้อมูลส่วนตัว</CardHeader>
												<CardBody>
													<Row>
														<Col md={6}>
															<FormGroup>
																<Label for="type"><span className="red"> * </span>ประเภทการประเมิน</Label>
																<Input type="select" name="type" id="type" onChange={this.onChangeSelect}>
																	{/* <option value="">  กรุณาเลือกประเภทการประเมิน   </option> */}
																	<option value="Half" selected={typeSelect === "Half"}>  ครั้งที่ 1 (ม.ค. - มิ.ย.) </option>
																	<option value="Full" selected={typeSelect === "Full"}>  ครั้งที่ 2 (ม.ค. - ธ.ค.) </option>
																</Input>
															</FormGroup>
														</Col>
														<Col md={6}>
															<FormGroup>
																<Label for="year"><span className="red"> * </span>การประเมินตนเองประจำปี</Label>
																<Input type="select" name="year" id="year" onChange={this.onChangeSelect}>
																	{/* <option value={_year} selected={yearsSelect === _year}> {_year}</option> */}
																	<option value={yearsSelect} selected={yearsSelect === yearsSelect}> {yearsSelect}</option>
																	{/* <option value={_year - 1} selected={yearsSelect === _year - 1}> {_year - 1}</option> */}
																</Input>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col md={6}>
															<FormGroup>
																<Label for="staffName">ชื่อผู้ทำแบบประเมิน</Label>
																<Input type="text" name="staffName" id="staffName" value={val.Name} readOnly />
																<Input type="hidden" name="staffCode" id="staffCode" value={val.ID_User} />
																<Input type="hidden" name="depNo" id="depNo" value={val.DepartmentNo} />
																<Input type="hidden" name="userLogin" id="userLogin" value={this.state.userLogin} />
															</FormGroup>
														</Col>
														<Col md={6}>
															<FormGroup>
																<Label for="Level_Worker">ตำแหน่ง</Label>
																<Input type="text" name="PositionNameEN" id="PositionNameEN" value={val.PositionNameEN} readOnly />
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col md={6}>
															<FormGroup>
																<Label for="bossName">ชื่อผู้บังคับบัญชา</Label>
																<Input type="text" name="bossName" id="bossName" value={val.BossName} readOnly />
																<Input type="hidden" name="bossCode" id="bossCode" value={val.Boss} />
																<Input type="hidden" name="idFlow" id="idFlow" value={val.refFlow_HRM} />
															</FormGroup>
														</Col>
														<Col md={6}>
															<FormGroup>
																<Label>ตำแหน่ง</Label>
																<Input
																	type="text" name="PositionNameEN_Boss" id="PositionNameEN_Boss" value={val.PositionNameEN_Boss} readOnly />
															</FormGroup>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<div className="margin-card2">
												<Card>
													<CardHeader>1. ผลการดำเนินการ ปีที่ผ่านมา (Performance)</CardHeader>
													<CardBody>
														{/* <Label>1.1 ผลสำเร็จตามเป้าหมาย -  คิดเห็นอย่างไรกับผลสำเร็จที่ทำได้ในช่วงเวลาที่ผ่านมา  </Label> */}
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label> <span className="red"> * </span>  1.1) ผลสำเร็จตามเป้าหมาย </Label>
																	<Input type="textarea" className="text-area" name="targetSuccess" id="targetSuccess" ref="targetSuccess" onChange={this.handleChange.bind(this, "targetSuccess")} value={this.state.fields["targetSuccess"]} />
																</FormGroup>
															</Col>
														</Row>

														{/* <Label>
															1.2 ผลงานที่ยังไม่บรรลุตามความคาดหวัง -
															ผลงานที่อยากปรับปรุงพัฒนาให้ดีขึ้นมีอะไรบ้าง
															คิดว่ามีสาเหตุจากอะไร และมีแนวทางแก้ไขอย่างไร
														</Label> */}
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label> <span className="red"> * </span>
																		1.2) ผลงานที่ยังไม่บรรลุตามเป้าหมาย
																	</Label>
																	<Input type="textarea" className="text-area" name="targetNotSuccess" id="targetNotSuccess" ref="targetNotSuccess" onChange={this.handleChange.bind(this, "targetNotSuccess")} value={this.state.fields["targetNotSuccess"]} />
																</FormGroup>
															</Col>
															{/* <Col md={6}>
																<FormGroup> <span className="red"> * </span>
																	<Label>
																		1.2.2) สาเหตุที่ยังไม่บรรลุตามเป้าหมาย
																	</Label>
																	<Input
																		type="textarea" className="text-area" name="causesNotSuccess" id="causesNotSuccess" ref="causesNotSuccess" onChange={this.handleChange.bind(this, "causesNotSuccess")} value={this.state.fields["causesNotSuccess"]} />
																</FormGroup>
															</Col> */}
														</Row>
														<Row>
															<Col md={12}>
																<FormGroup>
																	{/* <Label> <span className="red"> * </span>
																		1.2.3)
																		มีอุปสรรคหรือข้อจำกัดที่พบในการทำงานบ้างไหม  (เช่น กระบวนการ บุคลากร ระบบ เครื่องมือ/อุปกรณ์ สิ่งแวดล้อม ความรู้ทักษะในการทำงาน)
																	</Label> */}
																	<Label> <span className="red"> * </span>
																		1.3) อุปสรรคต่างๆที่พบ และ แนวทางพัฒนา แก้ไขปัญหา เพื่อบรรลุเป้าหมาย
																	</Label>
																	<Input
																		type="textarea" className="text-area" name="barrier" id="barrier" ref="barrier" onChange={this.handleChange.bind(this, "barrier")} value={this.state.fields["barrier"]} />
																</FormGroup>
															</Col>
															{/* <Col md={6}>
																<FormGroup>
																	<Label> <span className="red"> * </span>
																		1.2.4)  เราจะมีแนวทางพัฒนาหรือแก้ไขปัญหาร่วมกันอย่างไรได้บ้าง
																	</Label>
																	<Input type="textarea" className="text-area" name="solution" id="solution" ref="solution" onChange={this.handleChange.bind(this, "solution")} value={this.state.fields["solution"]} />
																</FormGroup>
															</Col> */}
														</Row>
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label> <span className="red"> * </span> 1.4) สิ่งที่ได้เรียนรู้และพัฒนาในปีที่ผ่านมา  </Label>
																	<Input
																		type="textarea" className="text-area" name="learnDeveloped" id="learnDeveloped" ref="learnDeveloped" onChange={this.handleChange.bind(this, "learnDeveloped")} value={this.state.fields["learnDeveloped"]} />
																</FormGroup>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</div>
											<div className="margin-card2">
												<Card>
													<CardHeader>2. ความรู้ ความเชี่ยวชาญในงาน</CardHeader>
													<CardBody>
														<Row>
															<Col md={6}>
																<FormGroup>
																	<Label>2.1) สิ่งที่อยากพัฒนา ปรับปรุง -  มีเรื่องอะไรบ้างที่อยากเรียนรู้พัฒนาเพิ่มเติม เพื่อช่วยให้ทำงานได้ง่ายขึ้น ดีขึ้น  </Label>
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup>
																	<Label>2.2) เหตุผลที่อยากเรียนรู้ พัฒนา (สำหรับแต่ละหัวข้อ)</Label>
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col md={6}>
																<FormGroup>
																	<Label><span className="red"> * </span>ลำดับที่ 1 </Label>
																	<Input type="textarea" className="text-area" name="km_1" id="km_1" ref="km_1" onChange={this.handleChange.bind(this, "km_1")} value={this.state.fields["km_1"]} />
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup>
																	<Label><span className="red"> * </span>เหตุผล </Label>
																	<Input
																		type="textarea" className="text-area" name="reason_1" id="reason_1" ref="reason_1" onChange={this.handleChange.bind(this, "reason_1")} value={this.state.fields["reason_1"]} />
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col md={6}>
																<FormGroup>
																	<Label>ลำดับที่ 2 </Label>
																	<Input type="textarea" className="text-area" name="km_2" id="km_2" ref="km_2" onChange={this.handleChange.bind(this, "km_2")} value={this.state.fields["km_2"]} />
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup>
																	<Label >เหตุผล </Label>
																	<Input
																		type="textarea" className="text-area" name="reason_2" id="reason_2" ref="reason_2" onChange={this.handleChange.bind(this, "reason_2")} value={this.state.fields["reason_2"]} />
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col md={6}>
																<FormGroup>
																	<Label> ลำดับที่ 3 </Label>
																	<Input type="textarea" className="text-area" name="km_3" id="km_3" ref="km_3" onChange={this.handleChange.bind(this, "km_3")} value={this.state.fields["km_3"]} />
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup>
																	<Label> เหตุผล </Label>
																	<Input
																		type="textarea" className="text-area" name="reason_3" id="reason_3" ref="reason_3" onChange={this.handleChange.bind(this, "reason_3")} value={this.state.fields["reason_3"]} />
																</FormGroup>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</div>
											<div className="margin-card2">
												<Card>
													<CardHeader>3.ข้อเสนอแนะ และขอคำปรึกษา</CardHeader>
													<CardBody>
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label><span className="red"> * </span>
																		3.1 สิ่งที่อยากเสนอแนะผู้บังคับบัญชา -
																		การทำงานร่วมกันเป็นอย่างไรบ้าง มีสิ่งที่อยากให้
																		ผู้บังคับบัญชาปรับหรือสนับสนุน (Feedback)
																	</Label>
																	<Input
																		type="textarea" className="text-area" name="feedback" id="feedback" ref="feedback" onChange={this.handleChange.bind(this, "feedback")} value={this.state.fields["feedback"]} />
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label>
																		3.2 สิ่งที่อยากเสนอแนะองค์กร - สิ่งที่อยากให้องค์กรปรับเพิ่มเติม
																	</Label>
																	<Input type="textarea" className="text-area" name="organComment" id="organComment" onChange={this.handleChange.bind(this, "organComment")} value={this.state.fields["organComment"]} />
																</FormGroup>
															</Col>
														</Row>

														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label>
																		3.3 ความเห็นอื่นๆ - ประเด็นอื่นๆ ที่อยากหารือกับผู้บังคับบัญชา / อยากขอบคุณใคร หรือ หน่วยงานใด สำหรับการทำงานในปีนี้
																	</Label>
																	<Input type="textarea" className="text-area" name="otherComment" id="otherComment" onChange={this.handleChange.bind(this, "otherComment")} value={this.state.fields["otherComment"]} />
																</FormGroup>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</div>
											<div className="margin-card2">
												<Card>
													<CardHeader>4.ความร่วมมือ และความคิดริเริ่ม</CardHeader>
													<CardBody>
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label>
																		{/* <span className="red"> * </span> */}
																		4.1 ปีที่ผ่านมา เราช่วยให้  <b>"ใคร"</b> ในองค์กรได้ทำงานให้ประสบความสำเร็จบ้าง /<b> "ใคร"</b> ช่วยผลักดันงานของเราให้สำเร็จบ้าง
																	</Label>
																	<Input
																		type="textarea" className="text-area" name="whoHelp" id="whoHelp" ref="whoHelp" onChange={this.handleChange.bind(this, "whoHelp")} value={this.state.fields["whoHelp"]} />
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label>
																		4.2 ปีที่ผ่านมาเอา <b>"ไอเดีย/ความคิดริเริ่ม"</b> ไหนขององค์กร หรือ ฝ่ายอื่นมาต่อยอดได้บ้าง
																	</Label>
																	<Input type="textarea" className="text-area" name="adaptIdea" id="adaptIdea" onChange={this.handleChange.bind(this, "adaptIdea")} value={this.state.fields["adaptIdea"]} />
																</FormGroup>
															</Col>
														</Row>

														<Row>
															<Col md={12}>
																<FormGroup>
																	<Label>
																		4.3 ถ้ามีโอกาสสร้างการเปลี่ยนแปลงในองค์กรได้ มีโครงการ มีไอเดียไหนที่น่าทำและอยากทำบ้าง (สามารถเสนอของหน่วยงานตัวเอง หรือหน่วยงานอื่น ๆ ได้)
																	</Label>
																	<Input type="textarea" className="text-area" name="changeOrg" id="changeOrg" onChange={this.handleChange.bind(this, "changeOrg")} value={this.state.fields["changeOrg"]} />
																</FormGroup>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</div>
											<div className="margin-card2">
												<Card>
													<CardHeader>
														<Row>
															<Col md={8}>
																5. คะแนนประเมินตนเอง (คะแนนเต็ม 10) <span className="red"> * </span>
															</Col>
															<Col md={4}> <NumericInput className="form-control" name="selfScore" id="selfScore" min={1} max={10} /></Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Row>

															<Col md={12}>
																<b>คำอธิบายเพิ่มเติม :: </b> &nbsp; &nbsp;
																<label> 1-2 = ต่ำกว่าความคาดหวังมาก &nbsp; &nbsp;
																	3-4 = ต่ำกว่าความคาดหวัง &nbsp; &nbsp;
																	5-6 = ตามความคาดหวัง &nbsp; &nbsp;
																	7-8 = สูงกว่าความคาดหวัง &nbsp; &nbsp;
																	9-10 = สูงกว่าความคาดหวังมาก </label>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</div>
											<Row>
												<Col md={12} className="text-center">
													<div class="ui checkbox">
														{checkbox}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md={12} className="text-center">
													{btnSave}
												</Col>

											</Row>
										</Form>
									</CardBody>
								);
							})}
						</Card>
					</div>
				) : (<div>{loadingPage} </div>)
				}
			</Container>
		);
	}
}
export default SelfAssessmentForm;
