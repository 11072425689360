import React from "react";
import ReactExport from "react-data-export";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const useExportExcel = ({
	header = [],
	dataset = [],
	filename = `Download`,
	...rest
}) => {
	return (
		<>
			<ExcelFile
				element={
					<Button style={{ color: "green", borderColor: "green" }}>
						{rest.title || 'Export Excel'}
					</Button>
				}
				filename={filename}
			>
				{header.length !== 0 ? (
					header.map((val, index) => {
						return (
							<ExcelSheet key={index} data={dataset} name={val.sheet}>
								{val.column.map((col, idx) => {
									return (
										<ExcelColumn
											key={idx}
											label={col.label}
											value={col.value}
										/>
									);
								})}
							</ExcelSheet>
						);
					})
				) : (
					<ExcelSheet data={[]} name="ไม่พบข้อมูล">
						<ExcelColumn label="ไม่พบข้อมูล" value="ไม่พบข้อมูล" />
					</ExcelSheet>
				)}
			</ExcelFile>
		</>
	);
};
