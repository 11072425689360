
export const TEXT_BTN = {
    SEARCH_DASHBOARD_OT: "ค้นหา",
    SEARCH_EXPORT_OT: "ค้นหา",
    INSERT_SIGNATURE: "เพิ่มลายเซ็นต์",

    LINK_REPORT: (text) => `${text || '-'}`,
    INSERT_REQUEST_OT: "+ เพิ่มคำขอโอที",
    UPDATE_REQUEST_OT: "แก้ไข",
    REMOVE_REQUEST_OT: "ลบ",
    REMOVE_EMP_REQUEST_OT: "ลบ",
    PRINT_REPORT_OT: "พิมพ์เอกสาร",
    RECALL_REPORT_OT: "ตีกลับเอกสาร",
    APPROVAL_REPORT_OT: "อนุมัติเอกสาร",
    APPROVAL_DASHBOARD_OT: "ดูรายละเอียด/อนุมัติ",
    VIEW_DASHBOARD_OT: "ดูรายละเอียด"
}

export const FIELD_BTN = {
    SEARCH_DASHBOARD_OT: "SEARCH_DASHBOARD_OT",
    SEARCH_EXPORT_OT: "SEARCH_EXPORT_OT",
    INSERT_SIGNATURE: "INSERT_SIGNATURE",
    DASHBOARD_OT: "DASHBOARD_OT",
    VIEW_DASHBOARD_OT: "VIEW_DASHBOARD_OT",
    VIEW_DASHBOARD_OT_APD: "VIEW_DASHBOARD_OT_APD",
    APPROVAL_DASHBOARD_OT: "APPROVAL_DASHBOARD_OT",
    APPROVAL_LIST_REPORT_OT: "APPROVAL_LIST_REPORT_OT",

    LINK_REPORT: "LINK_REPORT",
    INSERT_REQUEST_OT: "INSERT_REQUEST_OT",
    UPDATE_REQUEST_OT: "UPDATE_REQUEST_OT",
    REMOVE_REQUEST_OT: "REMOVE_REQUEST_OT",

    PRINT_REPORT_OT: "PRINT_REPORT_OT",
    APPROVAL_REPORT_OT: "APPROVAL_REPORT_OT",
    RECALL_REPORT_OT: "RECALL_REPORT_OT",
    REMOVE_EMP_REQUEST_OT: "REMOVE_EMP_REQUEST_OT"

}


export const BTN_NAME = {

}