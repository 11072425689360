export const formFilter = ({
  optionStatus = [], 
  optionStatusGuarantee = []
}) => {
  return [
    {
      ref: "fileStatus",
      name: "fileStatus",
      label: "สถานะแนบไฟล์",
      type: "DROPDOWN",
      span: 5,
      placeholder: "สถานะเอกสาร",
      properties: {
        options: optionStatus,
      },
    },
    {
      ref: "fileGuaranteeStatus",
      name: "fileGuaranteeStatus",
      label: "ระบุเอกสารค้ำประกัน",
      type: "DROPDOWN",
      span: 5,
      placeholder: "",
      properties: {
        options: optionStatusGuarantee,
      },
    },
  ];
};

