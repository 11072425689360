import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_REPORT_LEVEL_WORKER = {
    companyCode: undefined,
    location: undefined,
    depNo: undefined,
    subDepNo: undefined,
    startYear: undefined,
    endYear: undefined,
    statusWorker: undefined,
    startDateJob:undefined,
    endDateJob:undefined,
    isBlock:false
}

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
        name_2: "companyCode",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    startDate: {
        label: "วันที่เริ่มงาน",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
    startYear: {
        label: "ระหว่างปี",
        name: "startYear",
    },
    endYear: {
        label: "ถึงปี",
        name: "endYear",
    },
    from: {
        label: "เดือนลำดับที่ 1",
        name: "from",
    },
    to: {
        label: "เดือนลำดับที่ 2",
        name: "to",
    },
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    statusWorker: {
        label: "สถานะพนักงาน",
        name: "statusWorker",
    },
    filterBy: {
        label: "กรองข้อมูลโดย",
        name: "filterBy",
    },
    startDateJob:{
        label: "วันที่เริ่มงาน",
        name: "startDateJob",
    },
    endDateJob:{
        label: "วันที่พ้นสภาพ",
        name: "endDateJob",
    },
    endDateProbation:{
        label: "วันที่ผ่านทดลองงาน",
        name: "endDateProbation",
    },
    isBlock:{
        label: "",
        name: "isBlock",
        text:"เป็นพนักงานพ้นสภาพ",
    }
}

export const ComponentReportLevelWorker = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, startYear, endYear, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name_2,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name_2] || false,
                        },
                        fnChange: (value) => handleChange(company.name_2, value),
                        dropdown: options[company.name_2],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startYear.name,
                            label: startYear.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            picker: "year",
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startYear.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startYear.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: endYear.name,
                            label: endYear.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            picker: "year",
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[endYear.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(endYear.name, dateString),
                    },
                },
                // {
                //     breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                //     component: {
                //         type: COMPONENT_TYPE.SELECT,
                //         itemProps: {
                //             name: statusWorker.name,
                //             label: statusWorker.label,
                //             rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                //         },
                //         componentProps: {
                //             style: { width: "100%" },
                //             placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                //             disabled: isDisable[statusWorker.name] || false,
                //         },
                //         fnChange: (value) => handleChange(statusWorker.name, value),
                //         dropdown: options[statusWorker.name],
                //     },
                // },
            ], // column
        }, // Row
    ]
}

export const ComponentReportPersonal = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, endDateJob, startDateJob, statusWorker, subDepNo, userId, isBlock,endDateProbation } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name_2,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name_2] || false,
                        },
                        fnChange: (value) => handleChange(company.name_2, value),
                        dropdown: options[company.name_2],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 13, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                }, {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 13, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.RANG_PICKER,
                        itemProps: {
                            name: startDateJob.name,
                            label: startDateJob.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKERRANG }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKERRANG,
                            disabled: isDisable[startDateJob.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDateJob.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.RANG_PICKER,
                        itemProps: {
                            name: endDateJob.name,
                            label: endDateJob.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKERRANG }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKERRANG,
                            disabled: isDisable[endDateJob.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(endDateJob.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.RANG_PICKER,
                        itemProps: {
                            name: endDateProbation.name,
                            label: endDateProbation.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKERRANG }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKERRANG,
                            disabled: isDisable[endDateProbation.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(endDateProbation.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.CHECK_BOX,
                        itemProps: {
                            name: isBlock.name,
                            label: isBlock.label,
                            valuePropName: "checked",
                            rules: [{ required: false }],
                        },
                        componentProps: {
                            disabled: isDisable[isBlock.name] || false,
                        },
                        fnChange: (event) => handleChange(isBlock.name, event.target.checked),
                        text: isBlock.text,
                    },
                },

            ], // column
        }, // Row
    ]
}

export const ComponentReportEmployeeAge = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name_2,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name_2] || false,
                        },
                        fnChange: (value) => handleChange(company.name_2, value),
                        dropdown: options[company.name_2],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentReportWorkAge = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, statusWorker, filterBy } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name_2,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name_2] || false,
                        },
                        fnChange: (value) => handleChange(company.name_2, value),
                        dropdown: options[company.name_2],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: filterBy.name,
                            label: filterBy.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(filterBy.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(filterBy.label),
                            disabled: isDisable[filterBy.name] || false,
                        },
                        fnChange: (value) => handleChange(filterBy.name, value),
                        dropdown: options[filterBy.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentReportAll = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, userId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name_2,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name_2] || false,
                        },
                        fnChange: (value) => handleChange(company.name_2, value),
                        dropdown: options[company.name_2],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentReportEmployeeSummary = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, from, to, filterBy } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: from.name,
                            label: from.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            picker: "month",
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[from.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(from.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: to.name,
                            label: to.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            picker: "month",
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[to.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(to.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: filterBy.name,
                            label: filterBy.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(filterBy.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(filterBy.label),
                            disabled: isDisable[filterBy.name] || false,
                        },
                        fnChange: (value) => handleChange(filterBy.name, value),
                        dropdown: options[filterBy.name],
                    },
                },
            ], // column
        }, // Row
    ]
}