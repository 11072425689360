import { POST, POST_SAVE_LOG_PRINT } from "../../../../service";

export const onPrintPDF = async (htmlData, rawValue) => {
  if (rawValue) {
      await onSaveLogPrintAfterPreview(rawValue)
  }
  const winPrint = window.open("", "_blank");
  winPrint.document.write(htmlData);
  winPrint.document.close();
  winPrint.focus();
  setTimeout(() => {
    winPrint.print();
    winPrint.close();
  }, 100);
};

const onSaveLogPrintAfterPreview = async (rawValue) => {
    try {
      const data = {
        docID: rawValue.runningNo,
        requesterID: rawValue.requesterID,
        employeeID: rawValue.userId,
        formType: 'HIRE',
        companyCode: rawValue.companyCode
      }
      await POST(POST_SAVE_LOG_PRINT, data);
    } catch (error) {
      console.error(error);
      return false;
    }
}