import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";
import { Modal } from "antd";
import { path } from "ramda";
import { browserHistory } from "react-router";
import { PATHNAME } from "../../../constants/enums/pathname";

let API = servicesAPI.create()

export async function APISelectSubDepartmentOT(payload, nextAction) {
	try {
		const response = await API.selectSubDepartmentOT(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APISelectHRMFlow(payload, nextAction) {
	try {
		const response = await API.selectHRMFlow(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APISelectOptionDashboard(payload, nextAction) {
	try {
		const response = await API.selectOptionDashboard(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}


export async function APISelectFilterDashboardOTFac(payload, nextAction) {
	try {
		const response = await API.selectFilterDashboardOTFac(payload);
		const { data, problem, status } = response
		if (status === 200) {
			responseAPI(response, nextAction)
		} else if (status === 403) {
			Modal.error({
				title: `${path(['result', 'message'], data)}`,
				onOk: () => browserHistory.push(PATHNAME.INDEX)
			})
		} else {
			Modal.error({
				title: `${status} ( ${problem || ''} )`,
				content: path(['message'], data),
				onOk: () => window.location.reload()
			})
			return { error: path(['message'], data) }
		}
		// console.log(response)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APISelectOptionWorkShift(payload, nextAction) {
	try {
		const response = await API.selectWorkShift(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}



export async function APISelectFilterRequestOT(payload, nextAction) {
	try {
		const response = await API.selectFilterRequestOT(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}
