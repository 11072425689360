import React from "react";
import FormImportTemplete from "./conponents/forms/FormImportTemplete";

export const ManageJG_PG = () => {
  return (
    <>
      <FormImportTemplete />
    </>
  );
};

export default ManageJG_PG;
