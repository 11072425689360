import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

export const DEFAULT_REPORT_LEVEL_WORKER = {
  depNo: undefined,
  endDate: undefined,
  reasonLeave: undefined,
};

const DEFINE = {
  userId: {
    label: "ชื่อพนักงาน",
    name: "userId",
  },
  bossId: {
    label: "หัวหน้า",
    name: "bossId",
  },
  DateStart: {
    label: "วันที่เริ่มงาน",
    name: "DateStart",
  },
  DivisionID: {
    label: "สถานที่",
    name: "DivisionID",
  },
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  TransStatus: {
    label: "สถานะ",
    name: "TransStatus",
  },
  statusTask: {
    label: "เลือก column ที่ต้องการ",
    name: "statusTask",
  },
};

const SEND_DEFINE = {
  userId: {
    label: "รหัสพนักงาน",
    name: "userId",
  },
  followUpDate: {
    label: "วันที่ Followup",
    name: "followUpDate",
  },
  note: {
    label: "Note",
    name: "note",
  },
};
export const ComponentNewEmp = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { userId, bossId, DateStart, DivisionID, depNo, TransStatus } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: userId.name,
              label: userId.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(userId.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(userId.label),
              disabled: isDisable[userId.name] || false,
              mode: "multiple"              
            },
            fnChange: (value) => handleChange(userId.name, value),
            dropdown: options[userId.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: bossId.name,
              label: bossId.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(bossId.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(bossId.label),
              disabled: isDisable[bossId.name] || false,
            },
            fnChange: (value) => handleChange(bossId.name, value),
            dropdown: options[bossId.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(depNo.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        // {
        //   breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
        //   component: {
        //     type: COMPONENT_TYPE.DATE,
        //     itemProps: {
        //       name: DateStart.name,
        //       label: DateStart.label,
        //       rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
        //     },
        //     componentProps: {
        //       style: { width: "100%" },
        //       placeholder: ERROR_MESSAGE.DATEPICKER,
        //       disabled: isDisable[DateStart.name] || false,
        //     },
        //     fnChange: (date, dateString) =>
        //       handleChange(DateStart.name, dateString),
        //   },
        // },
        // {
        //   breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
        //   component: {
        //     type: COMPONENT_TYPE.SELECT,
        //     itemProps: {
        //       name: DivisionID.name,
        //       label: DivisionID.label,
        //       rules: [
        //         {
        //           required: false,
        //           message: ERROR_MESSAGE.SELECT(DivisionID.label),
        //         },
        //       ],
        //     },
        //     componentProps: {
        //       style: { width: "100%" },
        //       placeholder: ERROR_MESSAGE.SELECT(DivisionID.label),
        //       disabled: isDisable[DivisionID.name] || false,
        //     },
        //     fnChange: (value) => handleChange(DivisionID.name, value),
        //     dropdown: options[DivisionID.name],
        //   },
        // },
        // {
        //   breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
        //   component: {
        //     type: COMPONENT_TYPE.SELECT,
        //     itemProps: {
        //       name: TransStatus.name,
        //       label: TransStatus.label,
        //       rules: [
        //         {
        //           required: false,
        //           message: ERROR_MESSAGE.SELECT(TransStatus.label),
        //         },
        //       ],
        //     },
        //     componentProps: {
        //       style: { width: "100%" },
        //       placeholder: ERROR_MESSAGE.SELECT(TransStatus.label),
        //       disabled: isDisable[TransStatus.name] || false,
        //     },
        //     fnChange: (value) => handleChange(TransStatus.name, value),
        //     dropdown: options[TransStatus.name],
        //   },
        // },
      ], // column
    }, // Row
  ];
};

export const ComponentSelectNewEmp = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { statusTask } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: statusTask.name,
              label: statusTask.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(statusTask.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(statusTask.label),
              disabled: isDisable[statusTask.name] || false,
            },
            fnChange: (value) => handleChange(statusTask.name, value),
            dropdown: options[statusTask.name],
          },
        },
      ], // column
    }, // Row
  ];
};
