import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentCutOffCommissionIncentiveDashboard } from "./constants/CutOffCommissionIncentive/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnCutOffCommissionIncentiveDashboard } from "./constants/CutOffCommissionIncentive/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { getPeriodDate } from "../functions";
import { GET_CUTOFF_DASHBOARD, POST, UPDATE_STATUS_SAVE_COMMISSION, GET_CUTOFF_CONFIRM_REPORT, GET_SUMMARY_COM_INC_CUTOFF_REPORT, GET_CUTOFF_APPROVE_SPECIAL_REPORT } from "../service";
import { useAlert } from "./conponents/modal/CustomAlert";
import { useOptionSelect } from "./constants/OptionSelect";
import moment from "moment";
import { getDatePeriodNow } from '../functions/index';
import { decryptString, encryptString } from "../service/functions/encodeFunction";
import { optionInstallmentSaleStatus } from "../common/enum";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import encodeString from "../extendFunction/encodeString";
import { SetStringToObject } from "../constants/functions";
import Cookies from 'js-cookie';
import { useConfirm } from "./conponents/modal/CustomConfirm";
import { browserHistory } from "react-router";
import { CONNECT_API, USE_CONNECTION_MODE } from './../components/connectAPI';

const CutOffCommissionIncentiveDashboard = () => {
	const [form] = Form.useForm();
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	let optionSearch = {
		depNo: useOptionSelect("department"),
	}

	useEffect(() => {
		fetchApi()
	}, [])

	const fetchApi = async () => {
		form.setFieldsValue({ startDate: moment(getDatePeriodNow('start')) })
		form.setFieldsValue({ endDate: moment(getDatePeriodNow('end')) })
		await getCutOffDashboard({ startDate: moment(getDatePeriodNow('start')), endDate: moment(getDatePeriodNow('end')) })
	}


	const getCutOffDashboard = async (values) => {
		try {
			let obj = {
				...values,
				// depNo: values.depNo ? values.depNo : '',
				startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : '',
				endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : '',
			}
			setLoadingScreen(true);
			const response = await POST(GET_CUTOFF_DASHBOARD, obj);
			const { result, success } = response;
			if (success) {
				setDataSource(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
		} finally {
			setLoadingScreen(false);
		}
	}

	const updateStatusSave = async (obj) => {
		try {
			setLoadingScreen(true);
			const response = await POST(UPDATE_STATUS_SAVE_COMMISSION, obj);
			const { message, success } = response;
			if (success) {
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message, Fn: () => getCutOffDashboard({}) })
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const editRow = (record) => {
		const { docNo, installmentEndDate, installmentStartDate, } = record
		browserHistory.push({
			pathname: '/CutOffCommissionIncentive',
			state: {
				docNo,
				depNo: record.departmentCode,
				installmentEndDate,
				installmentStartDate
			}
		})
	}

	const viewRow = (record) => {
		const { docNo, installmentEndDate, installmentStartDate } = record
		browserHistory.push({
			pathname: '/CutOffCommissionIncentive',
			state: {
				docNo,
				depNo: record.departmentCode,
				installmentEndDate,
				installmentStartDate,
				view: true
			}
		})
	}

	const returnStatusRow = (row) => {
		let obj = {
			statusCutOff: '0',
			docNo: row.docNo,
			userLogin: encodeString.decode(cookies.ID_User)
		}
		useConfirm({
			content: `ต้องการย้อนสถานะรายการนี้ใช่หรือไม่`,
			FnOK: () => updateStatusSave(obj)
		})
	}

	const getCutOffConfirmReport = async () => {
		try {
			setLoadingScreen(true);
			let obj = {
				startDate: moment(getDatePeriodNow('start')).format("YYYY-MM-DD"),
				// startDate: '2022-01-21',
			}
			const response = await POST(GET_CUTOFF_CONFIRM_REPORT, obj);
			const { result, success } = response
			if (success) {
				// setDataSource(result)
				window.open(`${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`).focus();
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			// browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const getSummaryComIncCutOffReport = async () => {
		try {
			setLoadingScreen(true);
			let obj = {
				startDate: moment(getDatePeriodNow('start')).format("YYYY-MM-DD"),
				// startDate: '2022-01-21',
			}
			const response = await POST(GET_SUMMARY_COM_INC_CUTOFF_REPORT, obj);
			const { result, success } = response
			if (success) {
				// setDataSource(result)
				window.open(`${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`).focus();
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			// browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const getCutOffApproveSpecialReport = async () => {
		try {
			setLoadingScreen(true);
			let obj = {
				startDate: moment(getDatePeriodNow('start')).format("YYYY-MM-DD"),
				// startDate: '2022-01-21',
			}
			const response = await POST(GET_CUTOFF_APPROVE_SPECIAL_REPORT, obj);
			const { result, success } = response
			if (success) {
				// setDataSource(result)
				window.open(`${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`).focus();
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			// browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const onReset = () => {
		form.resetFields();
	};

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
			<Form form={form} onFinish={getCutOffDashboard} {...configForm}>
				<Card
					title={`หน้าหลักตัดรอบข้อมูล Commission/Incentive (รอบวันที่ ${getPeriodDate('display')})`}
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search" })}
							{useButton({
								call: "normal",
								fn: onReset,
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentCutOffCommissionIncentiveDashboard({
							options: optionSearch,
							handleChange: () => { },
						}),
					})}
				</Card>
			</Form>
			<Card
				extra={<Space>
					{useButton({ call: "search", moreProps: { label: "รายงานส่ง prosoft", onClick: getCutOffConfirmReport } })}
					{useButton({ call: "search", moreProps: { label: "รายงานสรุปรวม", onClick: getSummaryComIncCutOffReport } })}
					{useButton({ call: "search", moreProps: { label: "รายงานอนุมัติพิเศษ", onClick: getCutOffApproveSpecialReport } })}
				</Space>}
			>
				<CustomTableAtnd
					HeadTable={ColumnCutOffCommissionIncentiveDashboard({ returnStatusRow, editRow, viewRow })}
					DataTable={dataSource}
					moreProps={{
						// rowSelection: rowsSelection,
						scroll: {
							x: sumColumnWidth(ColumnCutOffCommissionIncentiveDashboard({ returnStatusRow, editRow, viewRow })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
			</Card>
		</Spin>
	);
};

export default CutOffCommissionIncentiveDashboard;
