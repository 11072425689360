import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "Location",
        name: "location",
    },
    flowId: {
        label: "สายอนุมัติ",
        name: "flowId",
    },
    approvers_lv_1: {
        label: "ลำดับที่ 1",
        name: "approvers_lv_1",
    },
    approvers_lv_2: {
        label: "ลำดับที่ 2",
        name: "approvers_lv_2",
    },
}

export const ComponentsSearchBranchApprove = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, location, flowId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(value, location.name),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 10 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: flowId.name,
                            label: flowId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(flowId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(flowId.label),
                            disabled: isDisable[flowId.name] || false,
                        },
                        fnChange: (value) => handleChange(value, flowId.name),
                        dropdown: options[flowId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 6, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "search",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 6, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentsInsertBranchApprove = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, location, approvers_lv_1, approvers_lv_2 } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(value, location.name),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: approvers_lv_1.name,
                            label: approvers_lv_1.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(approvers_lv_1.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(approvers_lv_1.label),
                            disabled: isDisable[approvers_lv_1.name] || false,
                        },
                        fnChange: (value) => handleChange(value, approvers_lv_1.name),
                        dropdown: options[approvers_lv_1.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: approvers_lv_2.name,
                            label: approvers_lv_2.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(approvers_lv_2.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(approvers_lv_2.label),
                            disabled: isDisable[approvers_lv_2.name] || false,
                        },
                        fnChange: (value) => handleChange(value, approvers_lv_2.name),
                        dropdown: options[approvers_lv_2.name],
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentsUpdateBranchApprove = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, location, flowId, approvers_lv_1, approvers_lv_2 } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(value, location.name),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: flowId.name,
                            label: flowId.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(flowId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(flowId.label),
                            disabled: isDisable[flowId.name] || false,
                        },
                        fnChange: (value) => handleChange(value, flowId.name),
                        dropdown: options[flowId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: approvers_lv_1.name,
                            label: approvers_lv_1.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(approvers_lv_1.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(approvers_lv_1.label),
                            disabled: isDisable[approvers_lv_1.name] || false,
                        },
                        fnChange: (value) => handleChange(value, approvers_lv_1.name),
                        dropdown: options[approvers_lv_1.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: approvers_lv_2.name,
                            label: approvers_lv_2.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(approvers_lv_2.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(approvers_lv_2.label),
                            disabled: isDisable[approvers_lv_2.name] || false,
                        },
                        fnChange: (value) => handleChange(value, approvers_lv_2.name),
                        dropdown: options[approvers_lv_2.name],
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}
