import { FIELD_STEP, MESSAGE_ERROR } from ".."
import { KEY_TYPE } from "../../../constants/enums/enumCenter"

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Label ที่นี่
    LANGUAGE: 'ภาษา (Language)',
    LISTENING: 'ฟัง (Listening)',
    SPEAKING: 'พูด (Speaking)',
    READING: 'อ่าน (Reading)',
    WRITING: 'เขียน (Writing)'
}

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    LANGUAGE: 'language',
    LISTENING: 'listening',
    SPEAKING: 'speaking',
    READING: 'reading',
    WRITING: 'writing'
}

/* -------------------------------------------------------------------------- */
/*                                PLACEHOLDER                                */
/* -------------------------------------------------------------------------- */

function PLACEHOLDER_WITH_INDEX(_index) {
    //!เปลี่ยนชื่อ Placeholder ที่นี่
    switch (+_index) {
        case 0: return 'ไทย';
        case 1: return 'อังกฤษ';
        case 2: return 'จีน';
        default: return 'ภาษา'
    }
}


/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
    language: '',
    listening: '',
    speaking: '',
    reading: '',
    writing: ''
}

export const FIELD_LANGUAGE = FIELD_PAGES;
export const INITIAL_LANGUAGE = INITIAL_PAGES;


/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_LANGUAGE = (props) => {
    const { disabledAll } = props;
    return [
        [{
            span: 24,
            inputprops: {
                type: KEY_TYPE.LIST_FORM,
                name: FIELD_STEP.COLLAPSE_S43,//'language',
                removelenght: 3,
                header: (idx) => `*โปรดระบุตัวเลขที่ตรงกับความสามารถของท่าน ดังนี้ 0 = ไม่ได้ (none) , 1 = พอใช้ (Fair), 2 = ดี (good), 3 = ดีมาก (Excellent)`,
                initialValues: { ...INITIAL_PAGES },
                disabled: disabledAll,
                content: (field) => [{
                    span: 7,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.LANGUAGE],
                        label: !+field.fieldKey && LABEL_PAGES.LANGUAGE,//ภาษา (Language)
                        fieldKey: [field.fieldKey, FIELD_PAGES.LANGUAGE],
                        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                    },
                    inputprops: {
                        type: KEY_TYPE.INPUT,
                        disabled: +field.fieldKey < 3 || disabledAll,
                        placeholder: PLACEHOLDER_WITH_INDEX(field.fieldKey)
                    }
                }, {
                    span: 4,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.LISTENING],
                        label: !+field.fieldKey && LABEL_PAGES.LISTENING,//ฟัง (Listening)
                        fieldKey: [field.fieldKey, FIELD_PAGES.LISTENING],
                        initialvalues: "0",
                        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                    },
                    inputprops: {
                        type: KEY_TYPE.NUMBER,
                        length: 1,
                        max: 3,
                        disabled: disabledAll,

                        placeholder: '0'
                    }
                }, {
                    span: 4,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.SPEAKING],
                        label: !+field.fieldKey && LABEL_PAGES.SPEAKING,//พูด (Speaking)
                        fieldKey: [field.fieldKey, FIELD_PAGES.SPEAKING],
                        initialvalues: "0",
                        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                    },
                    inputprops: {
                        type: KEY_TYPE.NUMBER,
                        length: 1,
                        max: 3,
                        disabled: disabledAll,

                        placeholder: '0'
                    }
                }, {
                    span: 4,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.READING],
                        label: !+field.fieldKey && LABEL_PAGES.READING,//อ่าน (Reading)
                        fieldKey: [field.fieldKey, FIELD_PAGES.READING],
                        initialvalues: "0",
                        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                    },
                    inputprops: {
                        type: KEY_TYPE.NUMBER,
                        length: 1,
                        max: 3,
                        disabled: disabledAll,
                        placeholder: '0'
                    }
                }, {
                    span: 4,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.WRITING],
                        label: !+field.fieldKey && LABEL_PAGES.WRITING,//เขียน (Writing)
                        fieldKey: [field.fieldKey, FIELD_PAGES.WRITING],
                        initialvalues: "0",
                        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                    },
                    inputprops: {
                        type: KEY_TYPE.NUMBER,
                        length: 1,
                        max: 3,
                        disabled: disabledAll,
                        placeholder: '0'
                    }
                }]
            }
        }]
    ]
}
