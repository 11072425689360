import React, { useState } from "react";
import { Form, Card, Spin, Table, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportEmployeeAge } from "../../constants/HeaderExcel";
import { optionSelectLocation, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString, useMakeQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportEmployeeAge } from "../../constants/Reports/Components/ComponentReports";
import { GET, GET_SELECT_REPORT_EMPLOYEE_AGE } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportEmployeeAge } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportEmployeeAge = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [dataTable, setDataTable] = useState([]);
  const [overAll, setOverAll] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    companyCode: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    statusWorker: optionStatusWorker,
  }

  // [START] Handle Form
  const onFinish = (values) => {
    const { companyCode, location, depNo, statusWorker } = values
    let objectBody = {
      companyCode: companyCode || "",
      location: location || "",
      depNo: depNo || "",
      statusWorker: statusWorker || "",
    }
    getReportEmployeeAge(useMakeQueryString(objectBody));
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setOverAll({});
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
  };
  // [END] Handle Form

  // [START] REST API
  const getReportEmployeeAge = async (query) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setOverAll({});
      const response = await GET(GET_SELECT_REPORT_EMPLOYEE_AGE(query));
      const { result } = response;
      const { summary, overall } = result;
      let mapResult = [];
      let mapOverAll = {};
      if (summary.length !== 0) {
        mapResult = summary.map((row, index) => ({
          key: `${index}`,
          ...row,
        }));
        mapOverAll = overall
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
      setOverAll(mapOverAll);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Employee Age: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    if (name === "companyCode") {
      if (locationLogin === "FAC") {
        setUndefined = toUndefined(["depNo"]);
      } else {
        setUndefined = toUndefined(["location", "depNo"]);
      }
    } else if (name === "location") {
      setUndefined = toUndefined(["depNo"]);
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  const renderSummaryRow = (obj) => {
    if (typeof obj !== "object") return null;
    let cell = [];
    for (let i = 17; i < 60; i++) {
      cell.push(
        <Table.Summary.Cell key={i} index={i} align="right">
          {obj[i + 1] || "-"}
        </Table.Summary.Cell>
      );
    }
    return (
      <Table.Summary >
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="center" colSpan={4}>
            รวมทั้งสิ้น
          </Table.Summary.Cell>
          {cell}
          <Table.Summary.Cell index={5} align="right">
            {obj["total"] || "-"}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };
  return (
    <>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
          <Card
            title="รายงานจำนวนพนักงานตามช่วงอายุ"
            style={{ marginBottom: 30 }}
            extra={
              <Space>
                {useButton({ call: "search" })}
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Space>
            }
          >
            {useRenderFormItem({
              constantComponent: ComponentReportEmployeeAge({
                options: optionSearch,
                handleChange: onChangeValue,
                isDisable: {
                  location: locationLogin === "FAC" || false,
                }
              }),
            })}

          </Card>
        </Form>
        {dataTable.length !== 0 && (
          <Card
            title="รายงานจำนวนพนักงานตามช่วงอายุ"
            style={{ marginBottom: 30 }}
            extra={
              useExportExcel({
                header: Header_ReportEmployeeAge(),
                dataset: dataTable,
                filename: "รายงานจำนวนพนักงานตามช่วงอายุ",
              })
            }
          >
            <CustomTableAtnd
              HeadTable={ColumnReportEmployeeAge()}
              DataTable={dataTable}
              moreProps={{
                scroll: {
                  x: 3000,
                  y: 500,
                },
                summary: () => renderSummaryRow(overAll),
              }}
            />
          </Card>
        )}
      </Spin>
    </>
  );
};

export default FormReportEmployeeAge;
