import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentRecheckCommissionIncentiveDashboard } from "./constants/RecheckCommissionIncentive/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnRecheckCommissionIncentiveDashboard } from "./constants/RecheckCommissionIncentive/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { useConfirm } from "./conponents/modal/CustomConfirm";
import { browserHistory } from "react-router";
import { getPeriodDate, getDatePeriodNow } from "../functions";
import { useOptionSelect } from "./constants/OptionSelect";
import { GET_PERMISSION_USER_COMMISSION, POST, GET_COMMISSION_INCENTIVE_DASHBOARD, UPDATE_STATUS_SAVE_COMMISSION } from "../service";
import encodeString from "../extendFunction/encodeString";
import { SetStringToObject } from "../constants/functions";
import Cookies from 'js-cookie';
import { useAlert } from "./conponents/modal/CustomAlert";
import { Button } from 'antd';
import moment from 'moment';

const RecheckCommissionIncentiveDashboard = () => {
	const [form] = Form.useForm();
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [permissionUser, setPermissionUser] = useState([]);
	const [depOption, setDepOption] = useState([]);
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	let optionSearch = {
		depNo: useOptionSelect("department"),
	}

	useEffect(() => {
		fetchApi()
	}, [])

	const fetchApi = async () => {
		await getPermissionUser()
		form.setFieldsValue({ startDate: moment(getDatePeriodNow('start')) })
		form.setFieldsValue({ endDate: moment(getDatePeriodNow('end')) })
		await getCommissionIncentiveDashboard({ startDate: moment(getDatePeriodNow('start')), endDate: moment(getDatePeriodNow('end')) })
	}

	useEffect(() => {
		if (permissionUser.length > 0 && depOption.length == 0) {
			let temp = optionSearch.depNo.filter(el => {
				let index = permissionUser.findIndex(val => val.departmentNo == el.value)
				if (index != -1) {
					return el
				}
			})
			setDepOption(temp)
		}
	}, [optionSearch.depNo])

	const getPermissionUser = async () => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_PERMISSION_USER_COMMISSION, { userLogin: encodeString.decode(cookies.ID_User) });
			const { result } = response;
			if (result.length == 0) {
				browserHistory.push('/')
			} else {
				setPermissionUser(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const getCommissionIncentiveDashboard = async (values) => {
		try {
			let obj = {
				// depNo: values.depNo || '',
				startDate: values.startDate ? values.startDate.format("YYYY-MM-DD") : '',
				endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : '',
				userLogin: encodeString.decode(cookies.ID_User)
			}
			setLoadingScreen(true);
			const response = await POST(GET_COMMISSION_INCENTIVE_DASHBOARD, obj);
			const { result, success } = response;
			if (success) {
				setDataSource(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}


	const editRow = (row) => {
		const { docNo, installmentEndDate, installmentStartDate } = row
		browserHistory.push({
			pathname: '/RecheckCommissionIncentiveAction',
			state: {
				docNo,
				depNo: row.departmentCode,
				installmentEndDate,
				installmentStartDate,
			}
		})
	}

	const viewRow = (row) => {
		const { docNo, installmentEndDate, installmentStartDate } = row
		browserHistory.push({
			pathname: '/RecheckCommissionIncentiveAction',
			state: {
				docNo,
				depNo: row.departmentCode,
				installmentEndDate,
				installmentStartDate,
				view: true
			}
		})
	}

	const returnStatusRow = (row) => {
		let obj = {
			statusSave: `${parseInt(permissionUser[0].sequence) - 1}`,
			docNo: row.docNo,
			userLogin: encodeString.decode(cookies.ID_User)
		}
		useConfirm({
			content: `ต้องการย้อนสถานะรายการนี้ใช่หรือไม่`,
			FnOK: () => updateStatusSave(obj)
		})

	}

	const updateStatusSave = async (obj) => {
		try {
			setLoadingScreen(true);
			const response = await POST(UPDATE_STATUS_SAVE_COMMISSION, obj);
			const { message, success } = response;
			if (success) {
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message, Fn: () => getCommissionIncentiveDashboard({}) })
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const onReset = () => {
		form.resetFields();
	};

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
			<Form form={form} onFinish={getCommissionIncentiveDashboard} {...configForm}>
				<Card
					title={`หน้าหลักตรวจสอบ Commission/Incentive (รอบวันที่ ${getPeriodDate('display')})`}
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search" })}
							{useButton({
								call: "normal",
								fn: onReset,
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentRecheckCommissionIncentiveDashboard({
							options: { depNo: depOption },
							handleChange: () => { },
						}),
					})}
				</Card>
			</Form>
			<Card
				extra={
					<Space>
						<Button type="primary" onClick={() => browserHistory.push('/RecheckCommissionIncentiveAction')}>
							สร้างเอกสาร
						</Button>
					</Space>
				}
			>
				<CustomTableAtnd
					HeadTable={ColumnRecheckCommissionIncentiveDashboard({ editRow, viewRow, returnStatusRow, permissionUser })}
					DataTable={dataSource}
					moreProps={{
						scroll: {
							x: sumColumnWidth(ColumnRecheckCommissionIncentiveDashboard({ editRow, viewRow, returnStatusRow })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
			</Card>
		</Spin>
	);
};

export default RecheckCommissionIncentiveDashboard;
