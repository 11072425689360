import React, { useContext, useState } from 'react'
import { Row, Col, Modal, Spin } from 'antd';
import { ButtonTheme } from '../../common/buttons';
import { MasterContext } from '../../services/master/masterProvider';
import { STRUCTURE_INDICATOR } from '../../constants/structures/structureIndicator';
import { REPORT_TYPE, ReportContext } from '../../services/report/reportProvider';
import { LayoutBreadcrumb, LayoutSearch, LayoutWrapper, LayoutTabs } from '../../common/layouts'
import { ModalWrapper } from '../../common/modal';
import { InsertIndicator } from '../../components/modal';
import { IndicatorContext } from '../../services/manage/indicatorProvider';
import { colors } from '../../../themes';


export const PageManageIndicator = () => {
    const STRUCTURE = STRUCTURE_INDICATOR;
    const { masterState, onSearch, onChange } = useContext(MasterContext)
    const { reportState, searchReport } = useContext(ReportContext)
    const [openModal, showModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { onSubmit, indicatorState, clearIndicator } = useContext(IndicatorContext)

    return (
        <LayoutWrapper
            breadcrumb={<LayoutBreadcrumb breadcrumbLists={STRUCTURE.BREADCRUMB()} />}
            search={
                <Row gutter={[24, 10]}>
                    <div style={{ position: 'absolute', top: 0, zIndex: 1, right: -15 }}>
                        <ButtonTheme
                            type='primary'
                            buttonText="ค้นหา"
                            onClick={async () => {
                                await setLoading(true)
                                await searchReport({ reportName: REPORT_TYPE.INDICATOR_HEADER, searchOption: masterState, factory: false })
                                await setLoading(false)
                            }} />
                    </div>

                    {STRUCTURE.SEARCH_LISTS().map((each, eachIndex) => {
                        return (
                            <Col span={8} key={eachIndex}>
                                <LayoutSearch
                                    type={each.type}
                                    selectAll={each.selectAll}
                                    searchInput={each.searchInput}
                                    fieldOption={each.fieldOption}
                                    header={each.header}
                                    checkedDetail={masterState[`SEARCH_${each.fieldOption}`]}
                                    onChange={onChange}
                                    onSearch={(value) => {
                                        onSearch({ fieldname: each.fieldname, searchText: value })
                                    }}
                                    plainOptions={masterState[each.fieldOption]} />

                            </Col>
                        )
                    })}

                </Row>
            }
            content={
                <div className="layout-content-card">
                    <Spin spinning={loading} tip="กำลังโหลด...">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ModalWrapper
                                okText={(indicatorState.approval) ? "ยืนยัน (แก้ไขไม่ได้)" : "บันทึกร่าง"}
                                visible={openModal}
                                loading={false}
                                buttonStyle={{ backgroundColor: colors.buttonCreate, color: colors.white, border: 0 }}
                                buttonText={"+ กำหนดตัวชี้วัด"}
                                titleText={"กำหนดตัวชี้วัด"}
                                content={<InsertIndicator content={{ indicator: reportState.indicatorLineLists }} />}
                                onSubmit={() => {
                                    let totalScore = 0
                                    indicatorState.indicator.forEach(({ proportion }) => { totalScore += proportion });
                                    // console.log(indicatorState)
                                    // indicatorState.indicator.filter(e => {
                                    //     if(e.title ===null || e.cr)
                                    // })
                                    const { durationActive, indicatorHeaderName, employeeList_LV1, employeeList_LV2, indicator } = indicatorState
                                    let validateLine = false
                                    indicator.filter((val) => validateLine = (val.title === "" || val.title === null || val.criterion === "" || val.criterion === null || val.proportion === "" || val.proportion === null))
                                    // console.log({ validateLine })

                                    if (durationActive !== null && indicatorHeaderName !== null && (employeeList_LV1.length > 0 || employeeList_LV2.length > 0)) {

                                        if (totalScore === 100) {
                                            if (validateLine) {
                                                Modal.error({ title: 'กรุณากรอกตัวชี้วัดให้ครบถ้วน' })
                                            } else {

                                                Modal.confirm({
                                                    title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่",
                                                    onOk: () => {
                                                        showModal(false)
                                                        onSubmit()
                                                    },
                                                    // onCancel: () => clearIndicator()
                                                })
                                            }
                                        } else {
                                            Modal.error({
                                                title: 'กรุณากรอกสัดส่วนให้ครบ 100%'
                                            })
                                        }
                                    } else {
                                        Modal.error({ title: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
                                    }


                                }}
                                showModal={() => {
                                    showModal(true)
                                }}
                                closeModal={() => {
                                    clearIndicator()
                                    showModal(false)
                                }}

                            />
                        </div>
                        <LayoutTabs tabsLists={STRUCTURE.TABS_LISTS()} />
                    </Spin>
                </div>
            }
        />
    )
};
