
//TODO : คำนวณจำนวนอายุ
//? ระบุส่วนที่ใช้งานฟังก์ชั่นนี้ ด้านล่าง ใน []
//! [ACTIVE PAGES] : [PersonalInfo]

export function calcNumYears(_date = new Date(), _start = null) {
    // console.log('[calcNumYears] : ', { _date, _start })
    let _strYear = (_start) && new Date(_start).getFullYear()
    let _nowYear = (_strYear) ? _strYear : new Date().getFullYear()

    let _year = new Date(_date).getFullYear();
    // console.log(isNaN(_year),_date, _strYear)
    if (isNaN(_year) || !_date || isNaN(_nowYear)) {
        console.warn('[calcNumYears] : NOT NUMBER')
        return 0
    }
    return +_nowYear - +_year
}

// calcNumYears(undefined) // => true , NaN
// calcNumYears(null) // => true , NaN
// calcNumYears('') // => true , NaN
// calcNumYears('12/10/2021') // => false , 0
// calcNumYears('1992-01-01') // => false , 29
// calcNumYears('1992-01-01','12-10-2015') // => false , 23


/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 */

export function fixedDecimal(_num = '', _precision = 2) {
    if (`${_num}`.includes('.')) {
        let precision = Math.pow(10, _precision)
        return Math.ceil(+_num * precision) / precision
    }
    return !isNaN(+_num) ? +_num : 0
}


// fixedDecimal('3756.445566')
// fixedDecimal(3.445566)
// fixedDecimal(0)
// fixedDecimal(null)
// fixedDecimal('null')
// fixedDecimal('undefined')
// fixedDecimal(undefined)