import React from 'react'
import { PATHNAME } from '../../../constants/enums/pathname'
import DashboardHistory from '../../componentsTraining/dashboardHistory'

import DashboardTraining from '../../componentsTraining/dashboardTraining'


export function renderTabsWithPathname(_pathname) {
    switch (_pathname) {
        case PATHNAME.DASHBOARD_TRAINING: return [{ tab: 'OJT', content: <DashboardTraining isView={false} /> }]
        case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL: return [{ tab: 'รายงานบันทึกประวัติผลการฝึกอบรมรายบุคคล', content: <DashboardHistory pathname={_pathname} /> }]
        case PATHNAME.DASHBOARD_HISTORY_TRAINERS: return [{ tab: 'รายงานบันทึกการฝึกอบรมของผู้ฝึกสอน', content: <DashboardHistory pathname={_pathname} /> }]
        default:
            break;
    }
}
export function renderTabsAdminWithPathname(_pathname) {
    switch (_pathname) {
        case PATHNAME.DASHBOARD_TRAINING: return [
            { tab: 'OJT', content: <DashboardTraining isView={false} /> },
            { tab: 'View OJT', content: <DashboardTraining isView={true} /> }
        ]
        case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL: return [{ tab: 'รายงานบันทึกประวัติผลการฝึกอบรมรายบุคคล', content: <DashboardHistory pathname={_pathname} /> }]
        case PATHNAME.DASHBOARD_HISTORY_TRAINERS: return [{ tab: 'รายงานบันทึกการฝึกอบรมของผู้ฝึกสอน', content: <DashboardHistory pathname={_pathname} /> }]
        default:
            break;
    }
}

export const TABS_TRAINING = ({ pathname, permission }) => {

    switch (permission) {
        // case 'HR':
        // case 'HR-HeadDep':
        // case 'HR-Admin':
        // case 'HR-AdminOff':
        // case 'HR-Ass':
        // case 'HR-Super':
        case 'HRS-OFF':
        case 'HRD-OFF':
        case 'HRM-OFF':
        case 'HRS-FAC':
        case 'HRD-FAC':
        case 'HRM-FAC':
            return renderTabsAdminWithPathname(pathname)
        default:
            return renderTabsWithPathname(pathname)
    }
}