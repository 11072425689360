import {
  certEmployTH,
  certEmployEN,
  certSalaryTH,
  certSalaryEN,
  certSalaryENVisa,
} from "../forms/documentDetail";

export const useGetFormList = ({
  formInputName,
  onSelected,
  changeInputState,
  setValue,
  index,
  options,
}) => {
  const getFormInputList = (name, reqTransfer, reqQuit, reqSalary, isSelected) => {
    const paramObj = { name, reqTransfer, reqQuit, reqSalary, isSelected, options };
    switch (name) {
      case "certEmployTH":
        return certEmployTH(paramObj);
      case "certEmployEN":
        return certEmployEN(paramObj);
      case "certSalaryTH":
        return certSalaryTH(paramObj);
      case "certSalaryEN":
        return certSalaryEN(paramObj);
      case "certSalaryENVisa":
        return certSalaryENVisa(paramObj);
      default:
        return [];
    }
  };

  const handleChangeCheckboxState = (ref, key) => {
    return (e) => {
      if (key == "isSelected") {
        onSelected(index, e.target.checked);
      }
      changeInputState(formInputName, key, e.target.checked);
      setValue(ref, e.target.checked);
    };
  };

  const handleChangeCheckbox = (ref) => {
    return (e) => setValue(ref, e.target.checked);
  };

  const handleChangeDatePicker = (ref) => {
    return (e) => setValue(ref, e);
  };

  const handleChangeText = (ref) => {
    return (e) => setValue(ref, e.target.value);
  };

  const handleChangeAutoComplete = (ref) => {
    return (e) => setValue(ref, e);
  };

  const handleChangeNumber = (ref) => {
    return (e) => {
      const value = e.target.value;
      if (value <= 0 && value != "") {
        return;
      }
      setValue(ref, value);
    };
  };

  const handleDropdown = (ref) => {
    return (e) => {
      setValue(ref, e); //TODO
    };
  };

  return {
    getFormInputList,
    handleChangeCheckbox,
    handleChangeDatePicker,
    handleChangeText,
    handleChangeAutoComplete,
    handleChangeNumber,
    handleChangeCheckboxState,
    handleDropdown,
  };
};
