import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_SEARCH_REPORT_CRIME = {
    company: undefined,
    location: undefined,
    startDate: undefined,
    endDate: undefined,
}

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    startDate: {
        label: "วันที่ยื่น",
        name: "startDate",
    },
    endDate: {
        label: "ถึงวันที่",
        name: "endDate",
    },
    docNo: {
        label: "ชุดเอกสาร (ระบบ)",
        name: "docNo",
    },
    externalDoc: {
        label: "ชุดเอกสารอ้างอิง",
        name: "externalDoc",
    },
    docCrime: {
        label: "แนบไฟล์ (รองรับไฟล์ .pdf เท่านั้น)",
        name: "docCrime",
    },
    sendDate: {
        label: "วันที่ส่งผลตรวจ",
        name: "sendDate",
    },
    receivedDate: {
        label: "วันที่รับผลตรวจ",
        name: "receivedDate",
    },
}

export const ComponentSearchReportCrime = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, startDate, endDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: endDate.name,
                            label: endDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[endDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(endDate.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentEditCrime = ({
    isDisable = {},
    handleChange = () => null,
}) => {
    const { docNo, externalDoc, docCrime, sendDate, receivedDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "top" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: docNo.name,
                            label: docNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(docNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(docNo.label),
                            disabled: isDisable[docNo.name] || false,
                        },
                        fnChange: (event) => handleChange(docNo.name, event.target.value),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: externalDoc.name,
                            label: externalDoc.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(externalDoc.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(externalDoc.label),
                            disabled: isDisable[externalDoc.name] || false,
                        },
                        fnChange: (event) => handleChange(externalDoc.name, event.target.value),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.UPLOAD,
                        itemProps: {
                            name: docCrime.name,
                            label: docCrime.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(docCrime.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            accept: "application/pdf",
                            disabled: isDisable[docCrime.name] || false,
                        },
                        fnChange: (value) => handleChange(docCrime.name, value),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: sendDate.name,
                            label: sendDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(sendDate) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[sendDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(sendDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: receivedDate.name,
                            label: receivedDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(receivedDate) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[receivedDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(receivedDate.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentShowDetailCrime = ({
    handleChange = () => null,
}) => {
    const { docNo, externalDoc, sendDate, receivedDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "top" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: docNo.name,
                            label: docNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(docNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(docNo.label),
                            disabled: true,
                        },
                        fnChange: (event) => handleChange(docNo.name, event.target.value),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: externalDoc.name,
                            label: externalDoc.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(externalDoc.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(externalDoc.label),
                            disabled: true,
                        },
                        fnChange: (event) => handleChange(externalDoc.name, event.target.value),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: sendDate.name,
                            label: sendDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(sendDate) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: true,
                        },
                        fnChange: (date, dateString) => handleChange(sendDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: receivedDate.name,
                            label: receivedDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(receivedDate) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: true,
                        },
                        fnChange: (date, dateString) => handleChange(receivedDate.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}