import React, { useState } from "react";
import { Form, Card, Row, Spin } from "antd";
import { Position, SubDepartment, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_PagePosition } from "../../constants/HeaderExcel";
import { GET_UserLogin, sumColumnWidth, toUndefined } from "../../functions/UseFunction";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString, useMakeQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsSearchPosition } from "../../constants/ManagePosition/Components";
import { useText } from "../typography/Typography";

export const FormSearchPosition = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUserCount, setModalUserCount] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);

  let queryDepart = useFixedQueryString(changeSearchValue, ["company"]);
  let queryPosition = useFixedQueryString(changeSearchValue, ["company","depNo"]);
  let querySubDepart = useFixedQueryString(changeSearchValue, ["depNo"]);

  let options = {
    company: useOptionSelect("company"),
    location: useOptionSelect("location"),
    positionCode: useOptionSelect("position", queryPosition),
    depNo: useOptionSelect("department", queryDepart),
    subDepNo: useOptionSelect("subDepartment", querySubDepart),
  }
  // [END] States

  // [START] Handle Form
  const onFinish = (values) => {
    searchPosition();
  };
  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeSearchValue({});
  };
  // [END] Handle Form

  // [START] REST API
  const searchPosition = async () => {
    try {
      let querySearch = useMakeQueryString({
        ...changeSearchValue,
        companyCode: changeSearchValue["company"],
        company :undefined
      });
      setLoadingScreen(true);
      setDataTable([]);
      
      const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_SEARCH_Position}?${querySearch}`;
      const response = await fetch(url);
      const data = await response.json();
      let MAP_RESULT = [];
      if (response.ok) {
        const RESULT = data.result;
        if (RESULT.length !== 0) {
          MAP_RESULT = RESULT.map((val, index) => ({
            key: index,
            ...val,
          }));
        } else {
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        }
      }
      setDataTable(MAP_RESULT);
    } catch (err) {
      console.log("Error Search Position: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const putDEACTIVE = async (record) => {
    if (!record) return null;
    try {
      setLoadingScreen(true);
      let statusActive = record.statusActive ? `inactive` : `active`;
      let statusChange = record.statusActive ? false : true;
      const url = `${USE_CONNECT_MODE_AUTO}${Position.PUT_DEACTIVE}${record.positionCode}/${statusActive}`;
      const response = await fetch(url, {
        method: `PUT`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การจัดการสถานะสำเร็จ`,
          Fn: () =>
            setDataTable(
              dataTable.map((val) => {
                return val.positionCode === record.positionCode
                  ? { ...val, statusActive: statusChange }
                  : val;
              })
            ),
        });
      } else {
        useAlert({
          type: "info",
          title: "การจัดการสถานะไม่สำเร็จ",
          content: `${data.message}`,
        });
      }
    } catch (err) {
      console.log("Error deActive Position: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const getEmployeeList = async (record) => {
    try {
      setShowModal(true);
      setDataRow(record);
      setLoadingContentModal(true);
      setModalUserCount([]);
      const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_Employee_LIST}${record["positionCode"]}`;
      const response = await fetch(url);
      const data = await response.json();
      let MAP_RESULT = [];
      if (response.ok) {
        const RESULT = data.result;
        MAP_RESULT = RESULT.map((val, index) => ({
          key: index,
          ID_User: val.ID_User,
          Name: val.Name,
          NickName: val.NickName,
        }));
      }
      setModalUserCount(MAP_RESULT);
    } catch (err) {
      console.log("Error Data Modal Position: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingContentModal(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndifined = {};
    let arrayInput = ['company', 'location', 'depNo', 'subDepNo', 'positionCode'];
    switch (name) {
      case "company":
        setUndifined = toUndefined(arrayInput.slice(1));
        break;
      case "location":
        setUndifined = toUndefined(arrayInput.slice(2));
        break;
      case "depNo":
        setUndifined = toUndefined(arrayInput.slice(3));
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...setUndifined,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { ColumnPosition, ColumnCountEmployees } = useColumnTable({
    Config: {
      openModal: getEmployeeList,
      fnDelete: (data) => useConfirm({ FnOK: () => putDEACTIVE(data) }),
    },
  });
  // [END] Control Column
  const contentModalUsersPosition = () => {
    return (
      <div style={{ width: "100%" }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingContentModal}>
          {modalUserCount.length !== 0 ? (
            <CustomTableAtnd
              HeadTable={ColumnCountEmployees}
              DataTable={modalUserCount}
              SetScroll={{ x: sumColumnWidth(ColumnCountEmployees) || "20%" }}
              TableLayout={`fixed`}
            />
          ) : (
            useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
          )}
        </Spin>
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setDataRow([])
  }
  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <RenderMainModal
        show={showModal}
        onCancel={closeModal}
        txtTitle={`สมาชิก : ${dataRow.positionNameTH}`}
        ModalContent={contentModalUsersPosition()}
      />
      <Card title="จัดการตำแหน่งงาน" style={{ marginBottom: 30 }}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsSearchPosition({
              options,
              isDisable: { 
                positionCode: changeSearchValue["company"] ? false : true,
                  depNo: changeSearchValue["company"] ? false : true,
                  subDepNo: changeSearchValue["depNo"] ? false : true
               },
              onClick: onReset,
              handleChange: onChangeValue,
            })
          })}
        </Form>
      </Card>
      {dataTable.length !== 0 && (
        <>
          <Row justify="end" align="middle" style={{ marginBottom: 30 }}>
            {useExportExcel({
              header: Header_PagePosition,
              dataset: dataTable,
              filename: "รายงานตำแหน่งงาน",
            })}
          </Row>
          <CustomTableAtnd
            HeadTable={ColumnPosition}
            SetScroll={{ x: sumColumnWidth(ColumnPosition) || 1080, y: 500 }}
            DataTable={dataTable}
          />
        </>
      )}
    </Spin>
  );
};

export default FormSearchPosition;
