import React, { useState } from "react";
import { Form, Card, Spin, Table, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportPersonal } from "../../constants/HeaderExcel";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportPersonal } from "../../constants/Reports/Components/ComponentReports";
import { POST, POST_SELECT_DATA_DASHBOARD_EMPLOYEE_NEW } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportPersonal } from "../../constants/Reports/Columns/ColumnReports";
import { useText } from '../../../screens/conponents/typography/Typography';
import moment from "moment";

export const FormReportPersonal = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  let optionSearch = {
    companyCode: useOptionSelect("company"),
    location: useOptionSelect("location"),
    depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["depNo"])),
    userId:useOptionSelect("empAll"),
    statusWorker: useOptionSelect("statusWorker"),
  }

  const onFinish = (values) => {
    const { companyCode, depNo, endDateJob, isBlock, location, startDateJob,statusWorker,subDepNo,userId,endDateProbation } = values
    let payload = {
      company: companyCode || "",
      location: location || "",
      dep: depNo || "",
      subDep:subDepNo || "",
      userID:userId || "",
      statusWorker: statusWorker || "",
      startDateJobFrom:startDateJob ?moment(startDateJob[0]).format("YYYY-MM-DD"):"",
      startDateJobTo:startDateJob ?moment(startDateJob[1]).format("YYYY-MM-DD"):"",
      isBlock:isBlock?"1":"0",
      endDateJobFrom:endDateJob ?moment(endDateJob[0]).format("YYYY-MM-DD"):"",
      endDateJobTo:endDateJob ?moment(endDateJob[1]).format("YYYY-MM-DD"):"",
      endProbationFrom:endDateProbation ?moment(endDateProbation[0]).format("YYYY-MM-DD"):"",
      endProbationTo:endDateProbation ?moment(endDateProbation[1]).format("YYYY-MM-DD"):"",
      
    }
    getReportPersonal(payload);
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
  };

  const getReportPersonal = async (payload) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const res = await POST(POST_SELECT_DATA_DASHBOARD_EMPLOYEE_NEW,payload);
       const { result,success } = res;

       if(success){
        if(result.length !== 0){
          setDataTable(result);
        }else{
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        }
       }
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
    } finally {
      setLoadingScreen(false);
    }
  };

  const onChangeValue = (name, value) => {
    let setUndefined = {};
    console.log({name, value})
    switch (name) {
      case "companyCode":
        if (locationLogin === "FAC") {
          setUndefined = toUndefined(["depNo"]);
        } else {
          setUndefined = toUndefined(["location", "depNo","subDepNo"]);
        }
        break;
      case "location":
        setUndefined = toUndefined(["depNo"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"]);
        break;
      case "startYear":
        setUndefined = toUndefined(["endYear"]);
        break;
    }
    //setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
        <Card
          title="รายงานรายชื่อพนักงานประจำ"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportPersonal({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                location: locationLogin === "FAC" || false,
              }
            }),
          })}
           {useText({ type: "danger", text: "* หากต้องการเลือกค้นหาผ่าน แผนก หรือ แผนกย่อย กรุณาระบุบริษัท หรือสถานที่ก่อนทำการค้นหา" })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานรายชื่อพนักงานประจำ"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportPersonal,
              dataset: dataTable,
              filename: "รายงานรายชื่อพนักงานประจำ",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReportPersonal}
            DataTable={dataTable}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportPersonal;
