import React from "react";
import { Row } from "antd";
import { TextSmall } from "../../../../components/global/text";
import { DocInputCheckbox } from "./DocInputCheckbox";
import { DocInputLabel } from "./DocInputLabel";
import { DocInputNumber } from "./DocInputNumber";
import { DocInputDatePicker } from "./DocInputDatePicker";
import { DocInputAutoComplete } from "./DocInputAutoComplete";
import { DocInputDropdown } from "./DocInputDropdown";
import { DocumentSelectCard } from "../style";
import { useGetFormList } from "../hook/useGetFormList";

export const DocumentSelector = ({
  formInputName,
  formDescription = "ทดสอบ",
  control,
  setValue,
  onSelected,
  index,
  changeInputState,
  inputState = { isTransfer: false, isQuit: false, showSalary: false, isSelected: false },
  options,
}) => {
  const { isTransfer, isQuit, showSalary, isSelected } = inputState;
  const {
    getFormInputList,
    handleChangeCheckbox,
    handleChangeDatePicker,
    handleChangeText,
    handleChangeAutoComplete,
    handleChangeNumber,
    handleChangeCheckboxState,
    handleDropdown,
  } = useGetFormList({
    formInputName,
    onSelected,
    changeInputState,
    setValue,
    index,
    options,
  });
  const formList = getFormInputList(
    formInputName,
    isTransfer,
    isQuit,
    showSalary,
    isSelected
  );

  const renderInput = (form) => {
    switch (form.type) {
      case "CHECK_BOX":
        return (
          <DocInputCheckbox
            form={form}
            control={control}
            handleChange={handleChangeCheckbox(form.ref)}
          />
        );
      case "DATE_PICKER":
        return (
          <DocInputDatePicker
            form={form}
            control={control}
            handleChange={handleChangeDatePicker(form.ref)}
          />
        );
      case "INPUT":
        return (
          <DocInputLabel
            form={form}
            control={control}
            handleChange={handleChangeText(form.ref)}
          />
        );
      case "INPUT_NUMBER":
        return (
          <DocInputNumber
            form={form}
            control={control}
            handleChange={handleChangeNumber(form.ref)}
          />
        );
      case "CHECK_BOX_STATE":
        return (
          <DocInputCheckbox
            form={form}
            control={control}
            handleChange={handleChangeCheckboxState(form.ref, form.key)}
          />
        );
      case "AUTO_COMPLETE":
        return (
          <DocInputAutoComplete
            form={form}
            control={control}
            handleChange={handleChangeAutoComplete(form.ref)}
          />
        );
      case "DROPDOWN":
        return (
          <DocInputDropdown
            form={form}
            control={control}
            handleChange={handleDropdown(form.ref)}
          />
        );
    }
  };

  return (
    <DocumentSelectCard
      isSelected={isSelected}
      title={<TextSmall bold={false} text={formDescription} />}
    >
      <Row gutter={[8, 8]}>
        {formList && formList.map((e) => renderInput(e))}
      </Row>
    </DocumentSelectCard>
  );
};
export default DocumentSelector;
