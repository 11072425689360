import React from "react";
import { Tabs } from "antd";
import FormSearchBranchApprove from "./conponents/forms/FormSearchBranchApprove";
import FormAddBranchApprove from "./conponents/forms/FormAddBranchApprove";

const ManageBranchApprove = () => {
  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="ดูข้อมูลสายอนุมัติ" key="1">
        <FormSearchBranchApprove />
      </TabPane>
      <TabPane tab="เพิ่มสายอนุมัติ" key="2">
        <FormAddBranchApprove />
      </TabPane>
    </Tabs>
  );
};

export default ManageBranchApprove;
