import React from "react";
import { Select, Form } from "antd";

const { Option } = Select;

function onChange(value, name) {
  console.log(`selected ${value} : ${name}`);
}

const optionDefault = [
  {
    value: "jack",
    txt: "I am Jack",
  },
  {
    value: "lucy",
    txt: "I am Lucy",
  },
  {
    value: "tom",
    txt: "I am Tom",
  },
];

export const SelectAntd = ({
  name = "test",
  label = label || null,
  required = true,
  smgReq = "Please Select someting !!",
  styWidth = "100%",
  placeholder = "ทดสอบ Select",
  FnOnChange = onChange,
  dropdown = optionDefault,
  isDisable = false,
  multipleMode = false,
  DefaultValue,
}) => {
  const mapDropDown = dropdown.map((val, index) => {
    return (
      <Option key={index} value={val.value}>
        {val.txt}
      </Option>
    );
  });

  const CheckMultipleMode = multipleMode ? `multiple` : ``;
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: smgReq,
        },
      ]}
      initialValue={DefaultValue}
    >
      <Select
        showSearch
        allowClear
        style={{ width: styWidth }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={(value) => FnOnChange(value, name)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={isDisable}
        mode={CheckMultipleMode}
      >
        {mapDropDown}
      </Select>
    </Form.Item>
  );
};

export const useSelect = ({
  name = "useInputArea",
  componentProps = {},
  fnChange = () => null,
  dropdown = [],
}) => (
  <Select
    showSearch
    allowClear
    optionFilterProp="children"
    onChange={fnChange}
    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    {...componentProps}
  >
    {dropdown.map((option, index) => {
      return (
        <Option key={index} value={option.value}>
          {option.txt}
        </Option>
      );
    })}
  </Select>
);
