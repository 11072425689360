import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from "react-icons/fi";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import * as NumericInput from "react-numeric-input";
import { API_URL } from "../components/connectAPI";
import { Tabs, Modal } from "antd";
import { browserHistory } from "react-router";
import encodeString from "../extendFunction/encodeString";
import { getCurrent } from "../extendFunction/getCurrentYears";
import {
  GET,
  POST,
  SELECT_DATA_PE_HISTORY,
  SELECT_DATA_PE,
  POST_UPDATE_PERFORMANCE_EVAL
} from "../service";
const { TabPane } = Tabs;

class PerformanceEvaluationFormOfficer extends React.Component {
  constructor(props) {
    super(props);
    const { yearsSelect } = getCurrent();
    this.state = {
      _year: yearsSelect,
      tooltipOpen: false,
      value: "",
      arrScore1: [],
      arrNum1: [],
      scoreP1: "",
      arrScore2: [],
      arrNum2: [],
      scoreP2: "",
      totalScore: "",
      loadData: [],
      dataHistory: [],
      _DocPEF: encodeString.decode(this.props.params.DocPEF_OFF),
      groupEdit: this.props.params.Type,
      status_confirm: false,
      userLogin: this.props.userLogin,
      premission: this.props.premission,
    };
  }

  handleInputChange(valueAsNumber, weight, _index, _id) {
    var valueS = 0;
    var Arr = this.state.loadData;
    if (valueAsNumber >= 0 && valueAsNumber != null) {
      if (valueAsNumber <= 0) {
        valueS = 1;
      } else if (valueAsNumber >= 10) {
        valueS = 9;
      } else {
        valueS = valueAsNumber;
      }
    } else {
      valueS = null;
    }

    Arr[0][_id] = valueS;
    var score = valueS * weight;
    var _arrScore2 = this.state.arrScore2;
    _arrScore2[_index] = score;

    var sumScore2 = _arrScore2.reduce((result, number) => result + number);
    var scoreP2 = Number((sumScore2 / 9).toFixed(2));
    Arr[0]["scorePart2"] = scoreP2;
    this.setState(
      {
        arrScore2: _arrScore2,
        loadData: Arr,
      },
      () => {
        this.sumTotalScore();
      }
    );
  }

  handleInputChangeP1(valueAsNumber, weight, _index, _id) {
    var valueS = 0;
    var Arr = this.state.loadData;

    if (valueAsNumber >= 0 && valueAsNumber != null) {
      if (valueAsNumber <= 0) {
        valueS = 1;
      } else if (valueAsNumber >= 10) {
        valueS = 10;
      } else {
        valueS = valueAsNumber;
      }
    } else {
      valueS = null;
    }

    Arr[0][_id] = valueS;
    var score = valueS * weight;
    var _arrScore1 = this.state.arrScore1;
    _arrScore1[_index] = score;

    var sumScore1 = _arrScore1.reduce((result, number) => result + number);
    var scoreP1 = Number((sumScore1 / 10).toFixed(2));

    Arr[0]["scorePart1"] = scoreP1;
    this.setState(
      {
        arrScore1: _arrScore1,
        loadData: Arr,
      },
      () => {
        this.sumTotalScore();
      }
    );
  }

  sumTotalScore = () => {
    var Arr = this.state.loadData;
    var sumAll =
      parseFloat(Arr[0]["scorePart1"]) + parseFloat(Arr[0]["scorePart2"]);
    Arr[0]["totalScore"] = sumAll;
    this.setState({
      loadData: Arr,
    });
  };

  componentDidMount() {
    this.selectData();
  }

  async selectData() {
    try {
      const docNo = this.state._DocPEF;
      const response = await GET(SELECT_DATA_PE(docNo));
      const { result } = response;
      let { data } = result;
      const status_con = data[0].status_confirm;
      const staffCode = data[0].staffCode;
      // console.log(staffCode)
      const years = docNo.substring(3, 7);
      let chk;
      if (status_con === 1) {
        chk = true;
      } else {
        chk = false;
      }
      //  console.log(data)
      const arrScore1 = (result.arrScore1).map(Number);
      const arrScore2 = result.arrScore2.map(Number)


      this.setState({
        loadData: data,
        status_confirm: chk,
        arrScore1,
        arrScore2,
      },
        () => {
          this.selectDataHistory(years, staffCode);
        }
      );
    } catch (err) {
      console.log("Error Search select Data: ", err);
      this.setState({ loading: false });
    }
  }

  async selectDataHistory(years, staffCode) {
    try {
      const queryObject = {
        years,
        staffCode,
      };
      const response = await GET(SELECT_DATA_PE_HISTORY(queryObject));
      const { result } = response;
      this.setState({
        dataHistory: result !== 0 ? result : [],
      });
    } catch (err) {
      console.log("Error Search select DataHistory: ", err);
      this.setState({ loading: false });
    }
  }

  updateData(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    // console.log('gggg')
    //console.log(this.state.draft)
    let errors = {};
    let formIsValid = true;
    var entries = data.entries();
    var current, item, key, output, value;
    output = {};
    while ((item = entries.next().value)) {
      // assign to variables to make the code more readable.
      key = item[0];
      value = item[1];
      // Check if key already exist
      if (Object.prototype.hasOwnProperty.call(output, key)) {
        current = output[key];
        if (!Array.isArray(current)) {
          // If it's not an array, convert it to an array.
          current = output[key] = [current];
        }
        current.push(value); // Add the new value to the array.
      } else {
        output[key] = value;
      }
    }
    // console.log(output)
    if (output["cfHr"] === "true" || output["cfDraft"] === "true") {
      if (!output["score1_1_1"]) {
        formIsValid = false;
        errors["score1_1_1"] = "Cannot be empty";
      }

      if (!output["score1_1_2"]) {
        formIsValid = false;
        errors["score1_1_2"] = "Cannot be empty";
      }

      if (!output["score1_2_1"]) {
        formIsValid = false;
        errors["score1_2_1"] = "Cannot be empty";
      }

      // if (!output["Score1_2_2"]) {
      //     formIsValid = false;
      //     errors["Score1_2_2"] = "Cannot be empty";
      // }

      if (!output["score2_1"]) {
        formIsValid = false;
        errors["score2_1"] = "Cannot be empty";
      }

      if (!output["score2_2"]) {
        formIsValid = false;
        errors["score2_2"] = "Cannot be empty";
      }

      if (!output["score2_3"]) {
        formIsValid = false;
        errors["score2_3"] = "Cannot be empty";
      }

      if (!output["score2_4"]) {
        formIsValid = false;
        errors["score2_4"] = "Cannot be empty";
      }

      if (!output["score2_6"]) {
        formIsValid = false;
        errors["score2_6"] = "Cannot be empty";
      }

      if (!output["score2_7"]) {
        formIsValid = false;
        errors["score2_7"] = "Cannot be empty";
      }

      if (!output["score2_8"]) {
        formIsValid = false;
        errors["score2_8"] = "Cannot be empty";
      }

      if (!output["score2_10"]) {
        formIsValid = false;
        errors["score2_10"] = "Cannot be empty";
      }
    }

    if (formIsValid === true) {
      Modal.confirm({
        title: "คุณต้องการยืนยันการทำรายการใช่หรือไม่?",
        content: "",
        onOk: async () => {

          const res = await POST(POST_UPDATE_PERFORMANCE_EVAL, output);
          const { result } = res
          const { status_api } = result;
          if (status_api === 1) {
            Modal.success({
              title: "Update Success!!",
              onOk: () => browserHistory.push("/DashboardPerformance"),
            });
          } else {
            Modal.error({ title: "Update Error!!" });
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      Modal.warning({ title: "กรุณาระบุข้อมูลให้ครบถ้วน" });
      console.log(errors);
    }
  }

  onChangeCheckboxHR = (e) => {
    let isChecked = e.target.checked;
    this.setState({ cfHr: isChecked });
  };

  onChangeCheckbox = (e) => {
    let isChecked = e.target.checked;
    this.setState({ cfDraft: isChecked });
  };

  changeValue = (e) => {
    var _id = e.target.id;
    var Arr = this.state.loadData;
    Arr[0][_id] = e.target.value;
    this.setState({ loadData: Arr });
    //console.log(this.state.loadData)
  };

  render() {
    let data = this.state.loadData;
    let dataHistory = this.state.dataHistory || [];
    //var dataHistory = []
    // if (this.state.premission === 'Head') {
    // if (
    //   this.state.premission === "Head-OFF" ||
    //   this.state.premission === "Head-FAC"
    // ) {
    //   dataHistory = [];
    // }
    let checkbox;
    let btnSave;

    btnSave = <Button className="margin-right-20">บันทึกแบบร่าง</Button>;
    if (this.state.cfHr === true || this.state.cfDraft === true) {
      btnSave = <Button color="success"> ยืนยันข้อมูล (แก้ไขไม่ได้)</Button>;
    }

    if (this.state.groupEdit === "Update") {
      checkbox = (
        <div class="ui checkbox">
          {" "}
          <Input
            type="checkbox"
            name="cfHr"
            id="cfHr"
            onChange={this.onChangeCheckboxHR}
            value={this.state.cfHr}
            defaultChecked={this.state.cfHr}
          />
          <label>
            ยืนยันผลการประเมินและส่งให้ทางฝ่ายบุคคล{" "}
            <b className="red">(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b>
          </label>
          <input type="hidden" id="cfDraft" name="cfDraft" value="1" />
        </div>
      );
    } else {
      checkbox = (
        <div class="ui checkbox">
          {" "}
          <Input
            type="checkbox"
            name="cfDraft"
            id="cfDraft"
            onChange={this.onChangeCheckbox}
            value={this.state.cfDraft}
            defaultChecked={this.state.cfDraft}
          />{" "}
          <label>
            ยืนยันการบันทึกและส่งให้หัวหน้างาน{" "}
            <b className="red">(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b>
          </label>
        </div>
      );
    }

    var notFound = "";
    if (dataHistory.length === 0) {
      notFound = (
        <p>
          {" "}
          <br /> ***ไม่พบข้อมูลผลการประเมินย้อนหลัง***{" "}
        </p>
      );
    }

    return (
      <div>
        <Card>
          <CardHeader>
            <FiEdit3 className="margin-icon" /> แบบฟอร์มบริหารผลงาน
            ระดับปฏิบัติการ (Officer)
          </CardHeader>
        </Card>
        <Tabs defaultActiveKey="1">
          <TabPane tab="แบบกรอกฟอร์มประเมิน" key="1">
            {data.map((val, index) => {
              return (
                <Form onSubmit={this.updateData}>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                          <Input
                            type="text"
                            name="StaffName_dis"
                            id="StaffName_dis"
                            value={val.staffName}
                            disabled
                          />
                          <Input
                            type="hidden"
                            name="staffName"
                            id="staffName"
                            value={val.staffName}
                          />
                          <Input
                            type="hidden"
                            name="staffCode"
                            id="staffCode"
                            value={val.staffCode}
                          />
                          <Input
                            type="hidden"
                            name="depNo"
                            id="depNo"
                            value={val.DepNo}
                          />
                          <Input
                            type="hidden"
                            name="idFlow"
                            id="idFlow"
                            value={val.ID_Flow}
                          />
                          <Input
                            type="hidden"
                            name="docNoSAF"
                            id="docNoSAF"
                            value={val.Ref_DocSAF}
                          />
                          <Input
                            type="hidden"
                            name="docNoPEF"
                            id="docNoPEF"
                            value={this.state._DocPEF}
                          />
                          <Input
                            type="hidden"
                            name="groupEdit"
                            id="groupEdit"
                            value={this.state.groupEdit}
                          />
                          <Input
                            type="hidden"
                            name="userLogin"
                            id="userLogin"
                            value={this.state.userLogin}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="company">ตำแหน่งงาน</Label>
                          <Input
                            type="text"
                            name="Level_WorkerDes"
                            id="Level_WorkerDes"
                            value={val.Level_WorkerDes}
                            disabled
                          />
                          <Input
                            type="hidden"
                            name="levelWorker"
                            id="levelWorker"
                            value={val.Level_Worker}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <h4>ส่วนที่ 1 การประเมินปัจจัยด้านผลงาน</h4>
                    <Row className="table-custom table-head3">
                      <Col md={2} className="col-style">
                        <b>1-2</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>3-4</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>5-6</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>7-8</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>9-10</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>ระบุคะแนน</b>
                      </Col>
                    </Row>
                    <Row className="table-custom3">
                      <b>
                        &nbsp; &nbsp;1.1 ผลงาน (น้ำหนักคะแนน 45 :: ปริมาณงาน 30
                        / ระยะเวลา 15) <span className="red"> * </span>
                      </b>
                    </Row>

                    <Row className="table-custom2">
                      <Col md={2} className="col-style2">
                        <b>ปริมาณงาน :</b> ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่
                        หรือตามที่ได้รับมอบหมายในปริมาณน้อยกว่าที่กำหนดไว้อย่างมาก
                      </Col>
                      <Col md={2} className="col-style2">
                        ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่
                        หรือตามที่ได้รับมอบหมายในปริมาณน้อยกว่าที่กำหนดไว้
                      </Col>
                      <Col md={2} className="col-style2">
                        ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่
                        หรือตามที่ได้รับมอบหมายได้ตามปริมาณที่กำหนดไว้
                      </Col>
                      <Col md={2} className="col-style2">
                        ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่
                        หรือตามที่ได้รับมอบหมายในปริมาณค่อนข้างสูงกว่าที่กำหนดไว้
                      </Col>
                      <Col md={2} className="col-style2">
                        ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่
                        หรือตามที่ได้รับมอบหมาย ในปริมาณสูงกว่าที่กำหนดไว้มาก
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_1_1"
                          id="score1_1_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              30,
                              0,
                              "score1_1_1"
                            )
                          }
                          value={val.score1_1_1}
                        />
                        <Input
                          type="hidden"
                          name="scoreW1_1_1"
                          id="scoreW1_1_1"
                          value={this.state.arrScore1[0]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={2} className="col-style2">
                        <b>ระยะเวลา :</b>{" "}
                        ใช้ระยะเวลาในการปฏิบัติงานล่าช้ากว่าที่กำหนดอย่างมาก
                      </Col>
                      <Col md={2} className="col-style2">
                        ใช้ระยะเวลาในการปฏิบัติงานล่าช้ากว่าที่กำหนดค่อนข้างมาก
                      </Col>
                      <Col md={2} className="col-style2">
                        ใช้ระยะเวลาในการปฏิบัติงานเป็นไปตามที่กำหนด
                      </Col>
                      <Col md={2} className="col-style2">
                        ใช้ระยะเวลาในการปฏิบัติงานรวดเร็วกว่าที่กำหนดค่อนข้างมาก
                      </Col>
                      <Col md={2} className="col-style2">
                        ใช้ระยะเวลาในการปฏิบัติงานรวดเร็วกว่าที่กำหนดมาก
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_1_2"
                          id="score1_1_2"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              15,
                              1,
                              "score1_1_2"
                            )
                          }
                          value={val.score1_1_2}
                        />
                        <Input
                          type="hidden"
                          name="scoreW1_1_2"
                          id="scoreW1_1_2"
                          value={this.state.arrScore1[1]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom3">
                      <b>
                        &nbsp; &nbsp;1.2 มาตรฐาน (น้ำหนักคะแนน 15){" "}
                        <span className="red"> * </span>{" "}
                      </b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={2} className="col-style2">
                        - ผลงานไม่มีคุณภาพ มีข้อผิดพลาด
                        และขาดความครบถ้วนอยู่เสมอ
                        มักเป็นงานที่ไม่มีความเรียบร้อย
                        <br />- ต้องแก้ไขงาน หรือทำใหม่อยู่เสมอ
                      </Col>
                      <Col md={2} className="col-style2">
                        - ผลงานไม่มีคุณภาพมากนัก
                        มักมีข้อผิดพลาดและ/หรือขาดความครบถ้วน เรียบร้อย
                        <br />- ต้องแก้ไขปรับปรุงผลงานบ่อยครั้ง
                      </Col>
                      <Col md={2} className="col-style2">
                        - ผลงานมีคุณภาพ มีความถูกต้อง ครบถ้วน
                        และเรียบร้อยพอสมควร หรืออยู่ในระดับที่ยอมรับได้
                        <br />- อาจมีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงอยู่บ้าง
                        แต่ไม่ก่อให้เกิดความเสียหาย
                      </Col>
                      <Col md={2} className="col-style2">
                        - ผลงานมีคุณภาพดี มีความถูกต้อง ครบถ้วน
                        และเรียบร้อยค่อนข้างสูง
                        <br />- มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงน้อยมา
                        และเกิดขึ้นน้อยครั้ง
                      </Col>
                      <Col md={2} className="col-style2">
                        - ผลงานมีคุณภาพดีมาก มีความถูกต้อง ครบถ้วน เรียบร้อย
                        และสมบูรณ์
                        <br />- ไม่มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุง
                        สามารถนำไปใช้เป็นผลงานตัวอย่างได้
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_2_1"
                          id="score1_2_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              15,
                              2,
                              "score1_2_1"
                            )
                          }
                          value={val.score1_2_1}
                        />
                        <Input
                          type="hidden"
                          name="scoreW1_2_1"
                          id="scoreW1_2_1"
                          value={this.state.arrScore1[2]}
                        />
                      </Col>
                    </Row>
                    {/* <Row className="table-custom2 margin-bottom20">
                                            <Col md={2} className="col-style2">ต้องแก้ไขงาน หรือทำใหม่อยู่เสมอ</Col>
                                            <Col md={2} className="col-style2">ต้องแก้ไขปรับปรุงผลงานบ่อยครั้ง</Col>
                                            <Col md={2} className="col-style2">อาจมีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงอยู่บ้าง แต่ไม่ก่อให้เกิดความเสียหาย</Col>
                                            <Col md={2} className="col-style2">มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงน้อยมา และเกิดขึ้นน้อยครั้ง</Col>
                                            <Col md={2} className="col-style2">ไม่มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุง สามารถนำไปใช้เป็นผลงานตัวอย่างได้</Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="Score1_2_2" id="Score1_2_2" onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 15, 3, 'Score1_2_2')} value={val.Score1_2_2} />
                                                <Input type="hidden" name="ScoreW1_2_2" id="ScoreW1_2_2" value={this.state.arrScore1[3]} />
                                            </Col>
                                        </Row> */}
                    <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Core Competency</b>
                    </Row>
                    <Row className="table-custom table-head3">
                      <Col md={3} className="col-style">
                        <b>1-3 (ต่ำกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>4-6 (ได้มาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>7-9 (สูงกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>ระบุคะแนน</b>
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.1 Teamwork : การทำงานเป็นทีม (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>{" "}
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        ความร่วมแรงร่วมใจในการผลักดันพันธกิจของหน่วยงาน/องค์กร
                        ให้ไปในทิศทางเดียวกัน มีทัศนคติที่เปิดกว้าง
                        รับฟังความคิดเห็นและเคารพในความแตกต่างของผู้อื่น
                        ตลอดจนมีน้ำใจช่วยเหลือผู้อื่น
                        โดยเห็นแก่ความสำเร็จขององค์กรในภาพรวม
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        - เข้าใจถึงบทบาทและหน้าที่ของตน
                        สำนึกในความเป็นส่วนหนึ่งของทีม<br></br>-
                        แสดงออกถึงความผูกพันกับทีม
                        แสดงบทบาทได้ทั้งผู้นำหรือผู้ตามขึ้นอยู่กับสถานการณ์และความจำเป็น
                        <br></br>-
                        รับฟังและให้กำลังใจผู้อื่นเมื่อมีการแสดงความคิดเห็น{" "}
                        <br></br>- มักเข้าไปมีส่วนร่วมกับภารกิจ
                        โครงการของทีมอย่างกระตือรือร้นและด้วยความเต็มใจ<br></br>
                        -
                        พยายามรักษาบรรยากาศในการทำงานของทีมให้เป็นไปด้วยดีอยู่เสมอ
                      </Col>
                      <Col md={3} className="col-style2">
                        - มีทัศนคติเชิงบวก ยืดหยุ่นต่อแนวทางและความคิดใหม่ๆ
                        <br></br>- รับฟังคำแนะนำ การวิพากษ์
                        วิจารณ์ที่สร้างสรรค์และปรับพฤติกรรมของตนได้โดยคำนึงถึงเป้าหมายของทีม
                        <br></br>- มีส่วนร่วมในการกำหนดภารกิจและเป้าหมายของทีม
                        <br></br>-
                        นำเสนอแนวทางเลือกในการแก้ไขปัญหาที่เกิดขึ้นของสมาชิกในทีม
                        <br></br>- เต็มใจที่จะแบ่งเบาภาระงานกับผู้อื่น
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        พยายามแสวงหาฉันทามติของทีมเมื่อเกิดความเห็นต่างของสมาชิก
                        <br></br>- กล้าที่จะวิพากษ์วิจารณ์ความคิดเห็น
                        ทัศนะของผู้อื่นอย่างสร้างสรรค์บนพื้นฐานของการให้เกียรติซึ่งกันและกัน
                        <br></br>-
                        กระตุ้นให้สมาชิกในทีมแลกเปลี่ยนความคิดเห็นซึ่งกันและกัน
                        <br></br>- แสดงออกถึงความมุ่งมั่นที่จะรักษาพันธะสัญญา
                        กติกาของทีม<br></br>-
                        แสวงหาหนทางบรรลุข้อตกลงทั้งอย่างเป็นทางการและไม่เป็นทางการเพื่อสนับสนุนผลประโยชน์ของทีม
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_1"
                          id="score2_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              0,
                              "score2_1"
                            )
                          }
                          value={val.score2_1}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_1"
                          id="scoreW2_1"
                          value={this.state.arrScore2[0]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.2 Customer Focus : ลูกค้าสำคัญ (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>{" "}
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        ความตระหนักถึงความต้องการคาดหวังตลอดจนปัญหาของลูกค้าภายใน/ภายนอก
                        พยายามแสวงหาแนวทาง วิธีการในการส่งมอบผลิตภัณฑ์
                        บริการที่มีคุณภาพเพื่อสนองตอบความต้องการและสร้างความพึงพอใจของลูกค้าอย่างต่อเนื่องกระตือรือร้น
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        - สามารถระบุความต้องการคาดหวังของลูกค้าได้<br></br>-
                        รับฟังปัญหาของลูกค้าด้วยความเต็มใจและตั้งใจ
                        แก้ปัญหาเฉพาะหน้าให้ลูกค้าได้ภายในขอบเขตความรับผิดชอบของตน
                        <br></br>- สะท้อนปัญหา รายงานต่อหัวหน้าเกี่ยวกับปัญหา
                        อุปสรรคในการบริการลูกค้าที่เกินขอบเขตของตน
                      </Col>
                      <Col md={3} className="col-style2">
                        - สร้าง/พัฒนา การสื่อสาร คู่มือ กระบวนการ วิธีการทำงาน
                        ระบบข้อมูลที่จำเป็นสำหรับลูกค้า<br></br>- กำหนดมาตรฐาน
                        หลักประกันว่าสินค้า/บริการจะสามารถตอบสนองความต้องการของลูกค้าได้
                        <br></br>-
                        ปรับปรุงกระบวนการให้มีประสิทธิภาพสามารถตอบสนองความต้องการของลูกค้าได้อย่างรวดเร็ว
                        ทันกาล<br></br>-
                        เสนอแนะวิธีการที่จะปรับปรุงคุณภาพผลิตภัณฑ์การบริการเพื่อสร้างความพึงพอใจของลูกค้า
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        ใช้แนวทางเชิงรุกเพื่อสร้างความพึงพอใจให้แก่ลูกค้าอย่างมีประสิทธิผล
                        <br></br>-
                        สร้าง/พัฒนาแบบหรือวิธีการสำรวจความพึงพอใจของลูกค้า
                        วิเคราะห์ผลและมีการปรับปรุงตามความจำเป็นเร่งด่วน
                        <br></br>- พัฒนาเทคนิคใหม่ๆ
                        ระดมความเห็นจากสมาชิกในทีมเพื่อระบุจุดอ่อนเพื่อการปรับปรุงผลิตภัณฑ์
                        บริการ
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_2"
                          id="score2_2"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              1,
                              "score2_2"
                            )
                          }
                          value={val.score2_2}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_2"
                          id="scoreW2_2"
                          value={this.state.arrScore2[1]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.3 Excellence / Go Beyond : ความเป็นเลิศ /
                          ความก้าวหน้า (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        การปฏิบัติงานอย่างมีวิสัยทัศน์
                        ประสิทธิภาพใช้ทรัพยากรที่มีอยู่อย่างชาญฉลาด คุ้มค่า
                        ส่งมอบผลงานที่มีคุณภาพอย่างเหนือความคาดหมาย
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        -
                        เข้าใจถึงผลลัพธ์ของงานใหน้าที่ของตนว่าจะส่งผลกระทบต่อส่วนรวมอย่างไร
                        <br></br>-
                        พยายามค้นหาทางแก้ปัญหาในงานที่เกิดความผิดพลาดในอดีต
                        ไม่เกิดความผิดพลาดซ้ำ<br></br>- ผลงานที่ส่งมอบ
                        แสดงถึงความถูกต้อง เรียบร้อย สมบูรณ์ ครบถ้วน<br></br>-
                        มีการตรวจสอบอย่างถี่ถ้วนเพือหาข้อผิดพลาดก่อนส่งมอบงานเสมอ
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        กำหนดขั้นตอนและวินัยในการทำงานของตนเองอย่างเป็นระบบและถือปฏิบัติอย่างสม่ำเสมอ
                        <br></br>- สำนึกถึงความจำเป็นเร่งด่วน
                        และจัดลำดับงานที่ต้องปฏิบัติก่อนหลังได้อย่างเหมาะสม
                        <br></br>-
                        เข้าใจและอธิบายต่อผู้อื่นได้ถึงปัจจัยแห่งความสำเร็จของงานแต่ละอย่างที่ตนรับผิดชอบอยู่
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        ได้รับความไว้วางใจสูงจากหัวหน้าว่าจะส่งมอบผลงานที่มีคุณภาพอย่างสม่ำเสมอ
                        ทันตามกำหนด<br></br>- มุ่งมั่น
                        ติดตามไม่ลดละเพื่อการบรรลุเป้าหมายทั้งทีพบความยุ่งยาก
                        อุปสรรคและปัญหา<br></br>-
                        มุ่งเน้นที่ผลลัพธ์ที่พึงประสงค์ด้วยการปฏิบัติตามกระบวนการ
                        วิธีการที่มีประสิทธิภาพ ถูกต้อง มีเหตุผล
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_3"
                          id="score2_3"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              2,
                              "score2_3"
                            )
                          }
                          value={val.score2_3}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_3"
                          id="scoreW2_3"
                          value={this.state.arrScore2[2]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.4 Learning and Development : การเรียนรู้และพัฒนา
                          (น้ำหนักคะแนน 5) <span className="red"> * </span>{" "}
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        กระตือรือร้นที่จะเรียนรู้สิ่งใหม่ๆเพื่อเพิ่มพูนควาสามารถและทักษะในการปฏิบัติงานของตนเองอยู่เสมอมีการถ่ายทอดแบ่งปันความรู้วิธีปฏิบัติงานใหม่ๆเพื่อให้สามารถตอบสนองต่อการเปลี่ยนแปลงของสภาพแวดล้อมแก่สมาชิกในทีม
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        - แสดงออกถึงความตั้งใจที่จะเรียนรู้งานที่ตนรับผิดชอบ
                        <br></br>-
                        ค้นคว้าหาข้อมูลที่เกี่ยวข้องจากแหล่งข้อมูลต่างๆได้
                        <br></br>- เข้ารับการฝึกอบรม เรียนรู้ผ่านหลักสูตร
                        โครงการต่างๆเพื่อเพิ่มพูนความรู้ในงาน
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        แสดงออกถึงการมีทัศนคติที่เปิดกว้างและรับฟังอย่างเต็มใจต่อความคิดเห็นที่แตกต่าง
                        <br></br>-
                        เรียนรู้จากความผิดพลาดของตนหรือผู้อื่นแล้วนำมาเป็นแนวทางการปรับปรุงแก้ไขให้ดีขึ้น
                        <br></br>-
                        ประยุกต์ขั้นตอนและวิธีการทำงานใหม่ๆได้ด้วยตนเอง
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        สนใจและติดตามกิจกรรมเรียนรู้เพื่อที่จะพัฒนาเสริมสร้างตนเอง
                        <br></br>-
                        จัดสรรเวลาในการเรียนรู้งานอื่นๆนอกเหนือจากงานที่ตนรับผิดชอบอยู่
                        <br></br>- ประยุกต์วิธีการ การใช้สารสนเทศ
                        เทคนิคใหม่ๆมาใช้ในการปรับปรุงการปฏิบัติงาน
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_4"
                          id="score2_4"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              3,
                              "score2_4"
                            )
                          }
                          value={val.score2_4}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_4"
                          id="scoreW2_4"
                          value={this.state.arrScore2[3]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>2.5 Integrity : ความมีคุณธรรม</b> <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        มีความเห็นใจและเข้าใจผู้อื่น
                        ประพฤติตนและเป็นแบบอย่างในด้านความซื่อสัตย์ ยุติธรรม
                        และมีจริยธรรม แสดงออกถึงความสม่ำเสมอทั้งคำพูดและการกระทำ
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={6} className="col-style">
                        <FormGroup>
                          <label> ตัวอย่างหรือตัวชี้บ่งพฤติกรรม </label>
                          <Input
                            type="textarea"
                            name="ans2_5_example"
                            id="ans2_5_example"
                            onChange={this.changeValue}
                            value={val.ans2_5_example}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} className="col-style">
                        <FormGroup>
                          <label>ข้อเสนอแนะเพื่อการพัฒนา </label>
                          <Input
                            type="textarea"
                            name="ans2_5_suggestion"
                            id="ans2_5_suggestion"
                            onChange={this.changeValue}
                            value={val.ans2_5_suggestion}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Functional Competency</b>
                    </Row>
                    <Row className="table-custom table-head3">
                      <Col md={3} className="col-style">
                        <b>1-3 (ต่ำกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>4-6 (ได้มาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>7-9 (สูงกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>ระบุคะแนน</b>
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.6 Job Knowledge : ความรู้ในงาน (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>{" "}
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b> เป็นผู้มีวิสัยทัศน์
                        มองการณ์ไกล เข้าใจภาพรวม
                        ประพฤติตนเป็นแบบอย่างที่ดีตามค่านิยมขององค์กรมอบหมายงานและสร้างแรงจูงใจเพื่อผลักดันให้ไปสู่เป้าหมาย
                        ตลอดจนประเมินผลลัพธ์อย่างเที่ยงตรง
                        สือสารอย่างมีประสิทธิภาพ
                        ได้รับการยอมรับจากสมาชิกในหน่วยงาน
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        - อธิบายให้ผู้อื่นเข้าใจถึงกระบวนการ เทคนิค ขั้นตอน
                        วิธีปฏิบัติงานของตนได้อย่างละเอียด<br></br>-
                        ปฏิบัติงานประจำโดยอิงกับกระบวนการ ขั้นตอน วิธีการ
                        และมาตรฐานที่กำหนดขึ้นในหน่วยงาน<br></br>-
                        เมื่อพบปัญหาในการปฏิบัติงานประจำยังต้องขอคำแนะนำด้านเทคนิค
                        วิธีการจากเพื่อนร่วมงานหรือหัวหน้า<br></br>-
                        ผลงานมีข้อผิดพลาดในระดับที่ยอมรับได้
                        อาจเป็นผลจากปัจจัยอื่นๆที่ไม่สัมพันธ์กับความรู้ในงานโดยตรง
                      </Col>
                      <Col md={3} className="col-style2">
                        - ผลงานทีส่งมอบมีความเที่ยงตรง ถูกต้อง สม่ำเสมอ
                        เสร็จตามกำหนด<br></br>-
                        ข้อผิดพลาดมีน้อยหรือถูกค้นพบแก้ไขก่อนด้วยตนเองก่อนส่งมอบผลงาน
                        <br></br>- ต้องการคำแนะนำจากหัวหน้างาน
                        ในกรณีทีต้องปฏิบัติงานใหม่ๆที่ไม่เคยทำมาก่อน<br></br>-
                        ประยุกต์ใช้ความรู้ในการแก้ปัญหาที่ไม่ยุ่งยากซับซ้อนมากนักอย่างมีประสิทธิผล
                      </Col>
                      <Col md={3} className="col-style2">
                        - ปฏิบัติงานในสถานการณ์ไม่ปกติ
                        ยุ่งยากซับซ้อนโดยไม่ต้องพึ่งคำแนะนำและยังส่งมอบผลงานที่ดีตามมาตรฐานที่กำหนดได้
                        <br></br>- เข้าใจและสามารถอธิบายที่มา เหตุผล ความจำเป็น
                        ความสัมพันธ์เชื่อมโยงของกระบวนการทั้งหมดในหน่วยงานได้
                        <br></br>- กระตือรือร้นที่จะถ่ายทอดแบ่งปันความรู้ เทคนิค
                        วิธีการที่มีประสิทธิภาพของตนกับผู้อื่นอยู่เสมอ
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_6"
                          id="score2_6"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              4,
                              "score2_6"
                            )
                          }
                          value={val.score2_6}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_6"
                          id="scoreW2_6"
                          value={this.state.arrScore2[4]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.7 Problem Solving : การแก้ไขปัญหา (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b> ความเข้าใจในบุคลิก
                        คุณลักษณะของสมาชิกในทีม
                        ส่งเสริมความสัมพันธ์ในทีม/หน่วยงานสามารถปรับพฤติกรรมของสมาชิกในทีมให้ไปในทิศทางที่พึงประสงค์ได้
                        มีทักษะในการ ถ่ายทอด สอนงาน ชี้แนะ โน้มน้าวจูงใจ
                        พัฒนาผู้ใต้บังคับบัญชา
                      </Col>
                    </Row>

                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        - ระบุได้ว่าอะไรคือประเด็นปัญหา ใครควรเป็นผู้ลงมือแก้ไข
                        หรือต้องให้ใครเป็นผู้ตัดสินใจก่อนดำเนินการใดๆ<br></br>-
                        เล็งเห็นถึงปัญหาก่อนที่ปัญหาจะเกิดขึ้น
                        คาดการณ์ได้ว่าปัญหาน่าจะเกิดที่ไหน ตลอดจนมีการติดตาม
                        เฝ้าระวัง ณ จุดนั้น<br></br>-
                        รายงานสภาพปัญหาต่อหัวหน้าในเวลาที่เหมาะสม
                        เมื่อพบปัญหาที่ไม่สามารถแก้ไขได้เองในเบื้องต้นได้
                      </Col>
                      <Col md={3} className="col-style2">
                        - ใช้การวิเคราะห์เชิงเหตุผล ตรรกะ บนฐานข้อมูล
                        ข้อเท็จจริงไม่ด่วนสรุป คาดเดาบนฐานของอคติ
                        ความเชื่อส่วนตัว<br></br>- สามารถสืบค้น แสวงหา รวบรวม
                        ข้อมูล
                        ข้อเท็จจริงที่ต้องใช้ประกอบการตัดสินใจได้อย่างครบถ้วน
                        ตรงประเด็น<br></br>-
                        ไม่เฉพาะแต่สามารถระบุถึงปัญหาเท่านั้น
                        หากยังสามารถวิเคราะห์สาเหตุปัญหาที่ไม่ซับซ้อนมากและเสนอแนวทางแก้ไขได้
                      </Col>
                      <Col md={3} className="col-style2">
                        - รู้จัก นำเครื่องมือ วิธีการ ข้อมูล สถิติ
                        รายงานมาใช้ในการระบุ วิเคราะห์
                        ชี้บ่งประเด็นปัญหาอย่างน่าเชื่อถือ<br></br>-
                        ชี้บ่งทางเลือกหรือวิธีการในการแก้ปัญหามากกว่าหนึ่งแนวทางและสามารถระบุจุดอ่อน
                        จุดแข็งของทางเลือกเหล่านั้นได้<br></br>-
                        ติดตามผลของมาตรการแก้ปัญหาเพื่อให้แน่ใจว่าการแก้ปัญหาบรรลุผลแล้ว
                        <br></br>- กำหนดมาตรการ
                        ขั้นตอนป้องกันล่วงหน้าเพื่อหลีกเลี่ยงการเกิดซ้ำของปัญหาเดิม
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_7"
                          id="score2_7"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              5,
                              "score2_7"
                            )
                          }
                          value={val.score2_7}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_7"
                          id="scoreW2_7"
                          value={this.state.arrScore2[5]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Managerial Competency</b>
                    </Row>
                    <Row className="table-custom table-head3">
                      <Col md={3} className="col-style">
                        <b>1-3 (ต่ำกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>4-6 (ได้มาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>7-9 (สูงกว่ามาตรฐาน)</b>
                      </Col>
                      <Col md={3} className="col-style">
                        <b>ระบุคะแนน</b>
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.8 Leadership : ภาวะผู้นำ (น้ำหนักคะแนน 5){" "}
                          <span className="red"> * </span>
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>เป็นผู้มีวิสัยทัศน์
                        มองการณ์ไกล เข้าใจภาพรวม
                        ประพฤติตนเป็นแบบอย่างที่ดีตามค่านิยมขององค์กร
                        มอบหมายงานและสร้างแรงจูงใจเพื่อผลักดันให้ไปสู่เป้าหมาย
                        ตลอดจนประเมินผลลัพธ์อย่างเที่ยงตรง
                        สือสารอย่างมีประสิทธิภาพ
                        ได้รับการยอมรับจากสมาชิกในหน่วยงาน
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        สื่อสารให้สมาชิกในทีมเข้าใจถึงเป้าหมายและวัตถุประสงค์การทำงานในหน้าที่บทบาทของตน
                        <br></br>- กระตุ้นให้ทีมงานนำเสนอความคิดเห็นใหม่ๆ
                        <br></br>- ยอมรับความคิดเห็นต่างๆของสมาชิกในทีม
                      </Col>
                      <Col md={3} className="col-style2">
                        -
                        กำหนดกิจกรรมเพื่อเสริมสร้างลูกทีมที่มีประสบการณ์น้อยเพื่อยกระดับความรู้
                        ทักษะในงาน<br></br>- แสดงบทบาทที่ปรึกษา ผู้สนับสนุน
                        ในการเสริมสร้างบุคลากร ทีม และหน่วยงาน<br></br>-
                        แสดงความรับผิดชอบต่อผลงานของทีมไม่ว่าจะสำเร็จหรือล้มเหลว
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_8"
                          id="score2_8"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              6,
                              "score2_8"
                            )
                          }
                          value={val.score2_8}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_8"
                          id="scoreW2_8"
                          value={this.state.arrScore2[6]}
                        />
                      </Col>
                    </Row>

                    <Row className="table-custom3">
                      <Col md={12}>
                        <b>
                          2.9 Planning and Organizing : การวางแผนและการจัดองค์กร
                          (น้ำหนักคะแนน 5) <span className="red"> * </span>
                        </b>{" "}
                        <br />
                        <b> คำอธิบายเพิ่มเติม : </b>{" "}
                        กำหนดแผนงานโดยตระหนักถึงปัจจัยที่เกี่ยวข้องต่างๆเช่น
                        สภาพแวดล้อม ทรัพยากรที่จำเป็น กำลังคน กระบวนการ ขั้นตอน
                        ปัญหา อุปสรรค ความเสี่ยง ลำดับความสำคัญเร่งด่วน
                        ตั้งเป้าหมายที่ท้าทายและเป็นไปได้
                        ดำเนินการตามแผนโดยกำกับ
                        ติดตามและตอบสนองตามความจำเป็นเพื่อให้บรรลุตามที่กำหนดไว้
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={3} className="col-style2">
                        -
                        ระบุถึงงาน/เป้าหมายที่ต้องปฏิบัติในขอบเขตหน้าที่ของตนได้ในแต่ละวัน
                        <br></br>-
                        เข้าใจถึงเป้าหมายในการทำงานของทีมที่ตนสังกัดอยู่
                        <br></br>- สามารถทำงานเสร็จตามเป้าหมายที่กำหนดไว้ได้
                      </Col>
                      <Col md={3} className="col-style2">
                        - ช่วยกำหนดแผนการ
                        ขั้นตอนการปฏิบัติการได้จากข้อมูลที่มีอยู่<br></br>-
                        เจรจาต่อรอง ปรึกษากับหัวหน้า เพื่อปรับกำหนดการ
                        ขอบเขตงาน/กิจกรรมให้เหมาะสม
                        รวมทั้งจัดลำดับความสำคัญให้สอดคล้องกับสภาวะการณ์ที่เกิดขึ้น
                        <br></br>- ประเมิน
                        ทบทวนผลงานของตนเองเป็นระยะและปรับปรุงวิธีการทำงานอยู่เสมอ
                      </Col>
                      <Col md={3} className="col-style2">
                        - จัดทำกระบวนการ
                        ขั้นตอนการปฏิบัติงานในหน่วยงานได้อย่างละเอียด สมบูรณ์
                        ครบถ้วน<br></br>-
                        ประสานงานกับผู้เกี่ยวข้องเมื่อพบปัญหา/ข้อขัดแย้งซึ่งอาจส่งผลกระทบต่องานอย่างทันท่วงที
                        <br></br>- สามารถปรับเปลี่ยนรายละเอียด
                        ขั้นตอนตามแผนให้เหมาะสมแก่สถานการณ์ทีไม่คาดฝันได้
                        <br></br>-
                        มีการรายงานสถานะความก้าวหน้าของการดำเนินงานตลอดจนแจ้งให้ผู้ที่เกี่ยวข้องทราบ
                      </Col>
                      <Col md={3} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_10"
                          id="score2_10"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              8,
                              "score2_10"
                            )
                          }
                          value={val.score2_10}
                        />
                        <Input
                          type="hidden"
                          name="scoreW2_10"
                          id="scoreW2_10"
                          value={this.state.arrScore2[8]}
                        />
                      </Col>
                    </Row>
                    <br />
                    <h4>
                      ส่วนที่ 3 การเปลี่ยนแปลง
                      Promote/โยกย้าย/ปรับตำแหน่ง/Execute D (ถ้าเสนอ โปรดระบุ
                      เพื่อ Discuss Management){" "}
                    </h4>
                    <Row className="table-custom2">
                      <Col md={6} className="col-style2">
                        <FormGroup>
                          <Label>รายละเอียด</Label>
                          <Input
                            type="textarea"
                            name="remarkDetail"
                            id="remarkDetail"
                            onChange={this.changeValue}
                            value={val.remarkDetail}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} className="col-style2">
                        <FormGroup>
                          <Label>ระยะเวลา</Label>
                          <Input
                            type="textarea"
                            name="remarkTime"
                            id="remarkTime"
                            onChange={this.changeValue}
                            value={val.remarkTime}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="table-custom table-footer">
                      <Col md={4} className="margin-top-20">
                        <b>ส่วนที่ 1 ด้านผลสำเร็จของงาน (60 คะแนน) </b>{" "}
                        <Input
                          type="text"
                          name="scorePart1"
                          id="scorePart1"
                          value={val.scorePart1}
                          readOnly
                        />
                      </Col>
                      <Col md={4} className="margin-top-20">
                        <b>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม (40 คะแนน) </b>
                        <Input
                          type="text"
                          name="scorePart2"
                          id="scorePart2"
                          value={val.scorePart2}
                          readOnly
                        />
                      </Col>
                      <Col md={4} className="margin-top-20 padding-right-32">
                        <b>รวมคะแนนทั้งหมด (100 คะแนน) </b>
                        <Input
                          type="text"
                          name="totalScore"
                          id="totalScore"
                          value={val.totalScore}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <div class="ui checkbox">{checkbox}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        {btnSave}
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              );
            })}
          </TabPane>
          <TabPane tab="รายงานผลประเมินย้อนหลัง 3 ปี" key="2">
            <h3>รายงานผลประเมินย้อนหลัง 3 ปี</h3>
            {notFound}
            {dataHistory.map((val, i) => {
              if (i === 0) {
                return (
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                          <Input
                            type="text"
                            name="StaffName_dis"
                            id="StaffName_dis"
                            value={dataHistory[0]["staffName"]}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="company">ตำแหน่งงาน</Label>
                          <Input
                            type="text"
                            name="Level_WorkerDes"
                            id="Level_WorkerDes"
                            value={dataHistory[0]["Level_WorkerDes"]}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <h4>ส่วนที่ 1 การประเมินปัจจัยด้านผลงาน</h4>
                    <Row className="table-custom table-head3">
                      <Col md={4} className="col-style">
                        <b>หัวข้อการประเมิน</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          {" "}
                          {dataHistory["0"] === undefined
                            ? ""
                            : "ปี" + dataHistory["0"]["Years"]}
                        </b>{" "}
                        <br />
                        {dataHistory["0"] === undefined
                          ? ""
                          : "(" + dataHistory["0"]["description"] + ")"}
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          {dataHistory["1"] === undefined
                            ? ""
                            : "ปี" + dataHistory["1"]["Years"]}
                        </b>
                        <br />
                        {dataHistory["1"] === undefined
                          ? ""
                          : "(" + dataHistory["1"]["description"] + ")"}
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          {" "}
                          {dataHistory["2"] === undefined
                            ? ""
                            : "ปี" + dataHistory["2"]["Years"]}
                        </b>
                        <br />
                        {dataHistory["2"] === undefined
                          ? ""
                          : "(" + dataHistory["2"]["description"] + ")"}
                      </Col>
                      <Col md={2} className="col-style">
                        {" "}
                        <b> ปีปัจจุบัน </b> <br />({this.state._year})
                      </Col>
                    </Row>
                    <Row className="table-custom table-head2">
                      &nbsp;&nbsp; <b>ผลงานตามเป้าหมาย</b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        ปริมาณงาน
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score1_1_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score1_1_1"]}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score1_1_1"]}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_1_1"
                          id="score1_1_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              30,
                              0,
                              "score1_1_1"
                            )
                          }
                          value={data[0]["score1_1_1"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        เวลา
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score1_1_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score1_1_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score1_1_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_1_2"
                          id="score1_1_2"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              15,
                              1,
                              "score1_1_2"
                            )
                          }
                          value={data[0]["score1_1_2"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom table-head2">
                      &nbsp;&nbsp; <b>มาตรฐานงาน</b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        คุณภาพ
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score1_2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score1_2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score1_2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score1_2_1"
                          id="score1_2_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChangeP1(
                              valueAsNumber,
                              15,
                              2,
                              "score1_2_1"
                            )
                          }
                          value={data[0]["score1_2_1"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        การแก้ไข
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score1_2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score1_2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score1_2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        {/* <NumericInput className="form-control" name="Score1_2_2" id="Score1_2_2"
                                                    onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 15, 3, 'Score1_2_2')} value={data[0]['Score1_2_2']} />  */}
                      </Col>
                    </Row>
                    <Row className="table-custom table-head3">
                      <Col md={4} className="col-style">
                        <b>รวมคะแนนส่วนที่ 1</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["0"] === undefined
                              ? ""
                              : dataHistory["0"]["scorePart1"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["1"] === undefined
                              ? ""
                              : dataHistory["1"]["scorePart1"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["2"] === undefined
                              ? ""
                              : dataHistory["2"]["scorePart1"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style2">
                        <Input
                          type="text"
                          name="scorePart1"
                          id="scorePart1"
                          value={data[0]["scorePart1"]}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <br />
                    <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Core Competency</b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Teamwork
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_1"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_1"
                          id="score2_1"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              0,
                              "score2_1"
                            )
                          }
                          value={data[0]["score2_1"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Customer Focus
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_2"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_2"
                          id="score2_2"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              1,
                              "score2_2"
                            )
                          }
                          value={data[0]["score2_2"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Excellence / Go Beyond
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_3"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_3"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_3"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_3"
                          id="score2_3"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              2,
                              "score2_3"
                            )
                          }
                          value={data[0]["score2_3"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Learning and Development
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_4"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_4"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_4"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_4"
                          id="score2_4"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              3,
                              "score2_4"
                            )
                          }
                          value={data[0]["score2_4"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Functional Competency</b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Job Knowledge
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_6"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_6"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_6"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_6"
                          id="score2_6"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              4,
                              "score2_6"
                            )
                          }
                          value={data[0]["score2_6"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Problem Solving
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_7"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_7"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_7"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_7"
                          id="score2_7"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              5,
                              "score2_7"
                            )
                          }
                          value={data[0]["score2_7"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom table-head">
                      &nbsp;&nbsp; <b>Managerial Competency</b>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Leadership
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_8"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_8"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_8"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_8"
                          id="score2_8"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              6,
                              "score2_8"
                            )
                          }
                          value={data[0]["score2_8"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        People Management
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_9"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_9"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_9"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2"></Col>
                    </Row>
                    <Row className="table-custom2">
                      <Col md={4} className="col-style2">
                        Planing and Organizing
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["0"] === undefined
                            ? ""
                            : dataHistory["0"]["score2_10"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["1"] === undefined
                            ? ""
                            : dataHistory["1"]["score2_10"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <center>
                          {dataHistory["2"] === undefined
                            ? ""
                            : dataHistory["2"]["score2_10"]}{" "}
                        </center>
                      </Col>
                      <Col md={2} className="col-style2">
                        <NumericInput
                          className="form-control"
                          name="score2_10"
                          id="score2_10"
                          onChange={(valueAsNumber) =>
                            this.handleInputChange(
                              valueAsNumber,
                              5,
                              8,
                              "score2_10"
                            )
                          }
                          value={data[0]["score2_10"]}
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom table-head3">
                      <Col md={4} className="col-style">
                        <b>รวมคะแนนส่วนที่ 2</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["0"] === undefined
                              ? ""
                              : dataHistory["0"]["scorePart2"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["1"] === undefined
                              ? ""
                              : dataHistory["1"]["scorePart2"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["2"] === undefined
                              ? ""
                              : dataHistory["2"]["scorePart2"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style2">
                        <Input
                          type="text"
                          name="scorePart2"
                          id="scorePart2"
                          value={data[0]["scorePart2"]}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="table-custom table-head">
                      <Col md={4} className="col-style">
                        <b>รวมคะแนนทั้งหมด</b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>{dataHistory["0"]["totalScore"]} </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["1"] === undefined
                              ? ""
                              : dataHistory["1"]["totalScore"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style">
                        <b>
                          <center>
                            {dataHistory["2"] === undefined
                              ? ""
                              : dataHistory["2"]["totalScore"]}{" "}
                          </center>
                        </b>
                      </Col>
                      <Col md={2} className="col-style2">
                        <Input
                          type="text"
                          name="totalScore"
                          id="totalScore"
                          value={data[0]["totalScore"]}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </CardBody>
                );
              }
            })}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default PerformanceEvaluationFormOfficer;
