import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card, Modal, Col, Row } from "antd";
import { configForm } from "./constants/ConstantComponent";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useRenderFormItem } from "./conponents/RenderComponents";
import { ComponentInsertNewSaleEmployee } from "./constants/InsertNewSaleEmployee/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnInsertNewSaleEmployee } from "./constants/InsertNewSaleEmployee/Columns";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import {
  POST,
  INSERT_UPDATE_SALE_EMPLOYEE,
  GET,
  GET_SALE_CODE_OPTION,
  GET_PERMISSION_USER_COMMISSION,
  GET_SALE_FSP,
  INSERT_UPDATE_FSP_EMPLOYEE,
  GET_SALE_CODE_OPTION_FSP,
} from "../service";
import { useOptionSelect } from "./constants/OptionSelect";
import { optionInstallmentSaleStatus } from "../common/enum";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import encodeString from "../extendFunction/encodeString";
import { getPeriodDate } from "../functions";
import { useAlert } from "./conponents/modal/CustomAlert";
import { GET_INCOME_GROUP } from "./../service/index";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import {
  decryptString,
  encryptString,
} from "../service/functions/encodeFunction";
import { browserHistory } from "react-router";
import { ColumnInsertNewSaleEmployeeFSP } from "./constants/InsertNewSaleEmployeeFSP/Columns";
import {
  ComponentImportNewSaleEmployeeFSP,
  ComponentInsertNewSaleEmployeeFSP,
  ExcelToJSON,
  MapDataExcel,
} from "./constants/InsertNewSaleEmployeeFSP/Components";
import { useExportExcel } from "./services/ExportExcel";
import { Header_InsertNewSaleEmployeeFSP } from "./constants/HeaderExcel";

const InsertNewSaleEmployeeFSP = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [optionIncomeGroup, setOptionIncomeGroup] = useState([]);
  const [optionSaleCode, setOptionSaleCode] = useState([]);
  const [rowsEdit, setRowsEdit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [permissionUser, setPermissionUser] = useState([]);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    async function fetchOptions() {
      await Promise.all([getSaleCodeOption(), getPermissionUser()]);
    }
    await fetchOptions();
  };

  useEffect(() => {}, [dataSource]);

  useEffect(() => {
    if (permissionUser.length > 0) {
      onFinish({});
    }
  }, [permissionUser]);

  let optionSearch = {
    // depNo: useOptionSelect("department"),
    depNo: permissionUser.map((el) => {
      return {
        txt: `[${el.departmentNo}]: ${el.departmentName}`,
        value: el.departmentNo,
      };
    }),
    optionInstallmentSaleStatus: optionInstallmentSaleStatus.map((el) => {
      return { txt: el.label, value: el.value };
    }),
    optionRemarkGuarnatee: [
      {
        txt: "พนักงานใหม่",
        value: "พนักงานใหม่",
      },
      {
        txt: "เปลี่ยนแปลงพื้นที่/ลูกค้า",
        value: "เปลี่ยนแปลงพื้นที่/ลูกค้า",
      },
      {
        txt: "เปลี่ยนแปลงหน้างาน",
        value: "เปลี่ยนแปลงหน้างาน",
      },
      {
        txt: "อื่นๆ",
        value: "อื่นๆ",
      },
    ],
  };

  const getPermissionUser = async () => {
    try {
      setLoading(true);
      const response = await POST(GET_PERMISSION_USER_COMMISSION, {
        userLogin: encodeString.decode(cookies.ID_User),
      });
      const { result } = response;
      if (result.length == 0) {
        browserHistory.push("/");
      } else {
        setPermissionUser(result);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser: ", err);
      browserHistory.push("/");
    } finally {
      setLoading(false);
    }
  };

  const getSaleFocusShop = async (objectBody) => {
    try {
      setLoading(true);
      const response = await POST(GET_SALE_FSP, objectBody);
      const { result, success } = response;
      if (success) {
        let mapResult = [];
        if (result.length !== 0) {
          mapResult = result.map((row, index) => ({
            ...row,
            key: `${row.ID_User}_${index}`,
            SaleCode: row.saleCodeCommline || row.saleCodeDplus,
            statusInstallmentName: renderLabelStatusInstallment(
              row.statusWorkerInstallment
            ),
            commissionGuarantee:
              parseFloat(decryptString(row.commissionGuarantee)) || "",
            incentiveGuarantee:
              parseFloat(decryptString(row.incentiveGuarantee)) || "",
          }));
        } else {
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        }
        setDataSource(mapResult);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
    } finally {
      setLoading(false);
    }
  };

  const renderLabelStatusInstallment = (statusWorkerInstallment) => {
    if (statusWorkerInstallment) {
      return optionInstallmentSaleStatus[
        optionInstallmentSaleStatus.findIndex(
          (el) => el.value.split(" | ")[0] == statusWorkerInstallment
        )
      ].label;
    }
  };

  const getSaleCodeOption = async () => {
    try {
      setLoading(true);
      const response = await GET(GET_SALE_CODE_OPTION_FSP);
      const { result, success } = response;
      if (success) {
        setOptionSaleCode(result);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (payload) => {
    let objectBody = {
      depNo:
        payload.depNo ||
        permissionUser.map((val) => val.departmentNo).join(","),
      periodDate: getPeriodDate("SQL"),
      startDateJob: payload.startDateJob
        ? payload.startDateJob.format("YYYY-MM-DD")
        : "",
      endDateJob: payload.endDateJob
        ? payload.endDateJob.format("YYYY-MM-DD")
        : "",
      statusWorkerInstallment:
        payload.statusInstallment && payload.statusInstallment.length > 0
          ? payload.statusInstallment
          : optionInstallmentSaleStatus.map((el) => el.value),
    };
    setRowsEdit([]);
    getSaleFocusShop(objectBody);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onChangeRow = (e, rowNo, fieldName) => {
    let temp = [...dataSource];
    switch (fieldName) {
      case "SaleCode":
      case "remarkGuarantee":
        // console.log('temp[rowNo - 1][fieldName]', e)
        temp[rowNo - 1][fieldName] = e;
        break;
      default:
        temp[rowNo - 1][`${e.target.name.split(/(\d+)/)[0]}`] = e.target.value;
    }
    setDataSource([]);
    setDataSource(temp);
    setIsDisable(false);
    // if (!rowsEdit.includes(rowNo - 1)) {
    //   let tempRowsEdit = [...rowsEdit];
    //   tempRowsEdit.push(rowNo - 1);
    //   setRowsEdit(tempRowsEdit);
    // }
  };

  const onSave = () => {
    const cookies = SetStringToObject(Cookies.get("cookie_session"));
    let onlyRowsEdit = dataSource.filter((el) =>
      rowsEdit.includes(+el.rowNo - 1)
    );
    let isValid = true;
    let checkRemarkGuarantee = true;
    let tempArr = [];
    dataSource.forEach((el) => {
      if (el.SaleCode) {
        tempArr.push({
          ...el,
          saleCodeCommline: el.saleCodeCommline
            ? el.saleCodeCommline.replace(/(\r\n|\r|\n)/g, "")
            : "",
          SaleCode: el.SaleCode.replace(/(\r\n|\r|\n)/g, ""),
          incentiveGuarantee:
            el.incentiveGuarantee && encryptString(`${el.incentiveGuarantee}`),
        });
      }
    });

    dataSource.map((el) => {
      if (el.incentiveGuarantee && (!el.SaleCode || !el.remarkGuarantee)) {
        checkRemarkGuarantee = false;
      }
    });

    // if (!isValid)
    //   return useAlert({
    //     type: "warning",
    //     content: "กรุณากรอกรหัสเซลล์",
    //   });
    if (!checkRemarkGuarantee)
      return useAlert({
        type: "warning",
        content: "กรุณากรอกเหตุผลการันตีและรหัสเซลล์",
      });
    let obj = {
      arrSale: tempArr,
      userId: encodeString.decode(cookies.ID_User),
      periodDate: getPeriodDate("SQL"),
    };

    // console.log("obj qqqqqqqqqqqqqqqqqqqqqqqq", obj);
    useAlert({
      type: "confirm",
      title: "ยืนยันการบันทึก",
      Fn: () => onCallApiInsertFSP(obj),
    });
  };

  const onCallApiInsertFSP = async (obj) => {
    setLoading(true);
    try {
      const response = await POST(INSERT_UPDATE_FSP_EMPLOYEE, obj);
      const { message, success } = response;
      if (success) {
        setRowsEdit([]);
        setDataSource([]);
        setIsDisable(true);
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          content: message,
          Fn: () => onFinish({}),
        });
      }
    } catch (err) {
      console.log("err", err);
      useAlert({ type: "catch", content: err.message });
    } finally {
      setLoading(false);
    }
  };

  const renderRowClass = (record) => {
    if (rowsEdit.length > 0) {
      if (rowsEdit.findIndex((el) => +el + 1 == record.rowNo) != -1) {
        return "row-edited";
      }
    }
    return "";
  };

  const handleChangeImportNewSaleEmployeeFSP = async ({ file, fileList }) => {
    try {
      setLoading(true);
      const fileName = file.name.split(".");
      const fileType = fileName[fileName.length - 1];
      if (fileList && fileList.length > 0 && fileType !== "xlsx") {
        Modal.warning({
          title: "กรุณาอัปโหลดไฟล์ .xlsx เท่านั้น",
        });
        return;
      }
      const res = await ExcelToJSON(file);
      const dataUpload = MapDataExcel(res[0].data);
      // console.log("dataUpload", dataUpload);
      if (dataUpload && dataUpload.length > 0) {
        useAlert({
          type: "success",
          title: "นำเข้าไฟล์สำเร็จ",
          Fn: () => "",
        });
        setIsDisable(false);
        const mapResultImport = dataUpload.map((item) => {
          form.setFieldsValue({ [`SaleCode${item.rowNo}`]: item.SaleCode });
          form.setFieldsValue({
            [`incentiveGuarantee${item.rowNo}`]: item.incentiveGuarantee,
          });
          form.setFieldsValue({
            [`remarkGuarantee${item.rowNo}`]: item.remarkGuarantee,
          });
          return {
            ...item,
          };
        });

        const excelData = mapResultImport.filter(e => e.SaleCode !== '')
        const excelUser = excelData.map(e => e.ID_User)
        const defaultData = dataSource.filter(data => excelUser.includes(data.ID_User));

        const updateDataSource = defaultData.map((e, i) => {
          const foundItem = excelData.find((item) => item.ID_User === e.ID_User);
          let SaleCode = e.SaleCode;
          let incentiveGuarantee = e.incentiveGuarantee;
          let remarkGuarantee = e.remarkGuarantee;
          if (foundItem) {
              SaleCode = foundItem.SaleCode || e.SaleCode;
              incentiveGuarantee = foundItem.incentiveGuarantee || e.incentiveGuarantee;
              remarkGuarantee = foundItem.remarkGuarantee || e.remarkGuarantee;
          }
          return {
            ...e,
            rowNo: i+1,
            SaleCode,
            incentiveGuarantee,
            remarkGuarantee,
          };
        });

        setDataSource([]);
        setDataSource(updateDataSource);
      } else {
        Modal.warning({
          title: "ข้อมูลที่นำเข้าไม่ถูกต้องตาม Template",
        });
        return;
      }
    } catch (error) {
      console.error("error>", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
      <Form form={form} onFinish={onFinish} {...configForm}>
        <Card
          title={`แก้ไขข้อมูลพนักงานโฟกัสช็อป (รอบวันที่ ${getPeriodDate(
            "display"
          )})`}
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentInsertNewSaleEmployeeFSP({
              options: { ...optionSearch, optionIncomeGroup },
              handleChange: () => {},
            }),
          })}
        </Card>
      </Form>
      <Card>
        <Row>
          <Col span={12} style={{ width: "100%" }}>
            {useRenderFormItem({
              constantComponent: ComponentImportNewSaleEmployeeFSP({
                options: [],
                handleChange: handleChangeImportNewSaleEmployeeFSP,
              }),
            })}
          </Col>
          <Col
            span={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {useExportExcel({
              header: Header_InsertNewSaleEmployeeFSP,
              dataset: dataSource,
              filename: "ข้อมูลพนักงานโฟกัสช็อป",
              title: `Download Template`,
            })}
          </Col>
        </Row>

        <CustomTableAtnd
          HeadTable={ColumnInsertNewSaleEmployeeFSP({
            onChangeRow,
            dataSource,
            optionSaleCode,
            optionSearch,
          })}
          DataTable={dataSource}
          moreProps={{
            pageSize: 100,
            rowClassName: renderRowClass,
            scroll: {
              x:
                sumColumnWidth(
                  ColumnInsertNewSaleEmployeeFSP({
                    onChangeRow,
                    dataSource,
                    optionSaleCode,
                    optionSearch,
                  })
                ) || 2050,
              y: 500,
            },
            size: "small",
          }}
        />
      </Card>
      <br />
      <div style={{ textAlignLast: "center", marginTop: "15px" }}>
        <Space>
          {useButton({
            call: "save",
            isDisable,
            moreProps: { onClick: () => onSave() },
          })}
        </Space>
      </div>
    </Spin>
  );
};

export default InsertNewSaleEmployeeFSP;
