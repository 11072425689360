import React, { useEffect, useContext } from "react"; import { Modal } from 'antd';
import { MasterCenterContext } from "../../service/master/masterProvider";
import { KEY_OPTION } from './../../constants/enums/enumCenter';
import { RenderForm } from './../../constants/inputs/RenderForm';
import { Card } from 'antd';


const RecruitmentAssessment = (props) => {
	const { defaultValue, onChangeMain } = props
	const { masterCenterState, getMaster } = useContext(MasterCenterContext);

	useEffect(() => {
		async function fetchApi() {
			await Promise.all(
				[getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_GRADE })],
				[getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_SCORE })],
			)
		}
		fetchApi()
	}, [])

	const onChangeMaster = (fieldName, value) => {
		onChangeMain(fieldName, value)
	}

	const RecruitmentAssessmentForm = [
		{
			label: `ประเมินคุณภาพการสรรหา (ศักยภาพพนักงานตามคุณภาพการสรรหา)`,
			width: '500px',
			style: { margin: 0 },
			name: 'recruiterGrade',
			value: defaultValue.recruiterGrade || '',
			showArrow: false,
			options: [{ label: '-----โปรดระบุ-----', value: '' }, ...masterCenterState[KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_GRADE]],
			onChange: (value) => onChangeMaster('recruiterGrade', value),
			comp: 'SelectSearch',
			styleLabel: {
				paddingRight: '10px'
			}
		},
		{
			label: `ความพึงพอใจต่อการสรรหาตำแหน่งนี้`,
			width: '500px',
			style: { margin: 0 },
			name: 'recruiterScore',
			value: defaultValue.recruiterScore || '',
			showArrow: false,
			options: [{ label: '-----โปรดระบุ-----', value: '' }, ...masterCenterState[KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_SCORE]],
			onChange: (value) => onChangeMaster('recruiterScore', value),
			comp: 'SelectSearch',
			styleLabel: {
				paddingRight: '10px'
			}
		}, {
			label: `ผู้สรรหา: ${defaultValue.recruiter || ''}`,
			comp: 'TextDisplay'
		}
	]
	return (
		<Card title="การประเมินเพื่อพัฒนาการสรรหา">
			{RecruitmentAssessmentForm.map(el => {
				return (
					<>
						<div style={{ width: '100%' }}>
							<RenderForm  {...el} />
						</div>
						<br />
					</>
				)
			})}
		</Card>
	)
}

export default RecruitmentAssessment