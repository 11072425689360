import React, { Fragment, useState, useContext, useRef } from 'react'
import * as R from 'ramda'
import { STRUCTURE_KPIS_FACTORY } from '../constants/structures/structuresKPIsFactory'
import { LayoutTable } from '../common/layouts'
import { ReportContext } from '../services/report/reportProvider'
import { path } from 'ramda'
import ContentUpdateScore from './contentUpdateScore'
import ContentInfoScore from './contentInfoScore'
import { searchMonth, splitString } from '../../constants/functions'
import { IndicatorContext } from '../services/manage/indicatorProvider'
import ContentInsertScore from './contentInsertScore'
import { MasterContext } from '../services/master/masterProvider'
import { renderWithLabel, renderWithOption } from '../../moduleKPIs/common/functions'
import { ExportExcel } from '../common/buttons'

const EvaluationFactory = ({ view }) => {
    const refExport = useRef()
    const STRUCTURE = STRUCTURE_KPIS_FACTORY
    const { userLogin } = useContext(IndicatorContext)

    const { reportState } = useContext(ReportContext)
    const { masterState } = useContext(MasterContext)
    const [pageSize, setPageSize] = useState(100)
    // const [dataSearch, setSearch] = useState(searchMonth())
    const dataSearch = searchMonth()

    const { SEARCH_OPTION_EMPLOYMENT_TYPE, SEARCH_OPTION_YEAR, SEARCH_OPTION_SUBDEP_FACTORY } = masterState
    let searchNow = JSON.parse(window.localStorage.getItem("dataSearch"));
    // console.log({ searchNow })
    return (
        <Fragment>
            {/* <Spin spinning={loading} tip="กำลังโหลด..."> */}
            <div style={{ padding: 10 }}>
                <div style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}> {'รายงานผลการประเมินประสิทธิภาพงานพนักงาน'}</div>
                <div style={{ display: 'flex', fontSize: 14, fontWeight: 300, justifyContent: 'center', padding: 10 }}>
                    <span style={{ fontWeight: 600, padding: '0 8px' }}> {`ประจำปี : `} </span>
                    {/* <span> {`${SEARCH_OPTION_YEAR[0] || ''} `} </span> */}
                    <span> {searchNow ? `${searchNow['year'] || ''} ` : SEARCH_OPTION_YEAR[0]} </span>
                    {/* <span> {`${renderMonth(SEARCH_OPTION_MONTH[0]) ? renderMonth(SEARCH_OPTION_MONTH[0]).th : '-'} ${SEARCH_OPTION_YEAR[0] || ''} `} </span> */}
                    <span style={{ fontWeight: 600, padding: '0 8px' }}> {`แผนก : `} </span>
                    <span > {`${renderWithOption(SEARCH_OPTION_SUBDEP_FACTORY[0], masterState.OPTION_SUBDEP_FACTORY) || '-'}`} </span>
                    <span style={{ fontWeight: 600, padding: '0 8px' }}> {`ประเภท : `} </span>
                    <span > {`${renderWithLabel({ label: SEARCH_OPTION_EMPLOYMENT_TYPE[0] || '' }) || '-'} `} </span>
                </div>
            </div>
            {
                (view) &&
                <ExportExcel
                    ref={refExport}
                    columns={[
                        {
                            title: 'ข้อมูลพนักงาน', children: [
                                { title: 'รหัสพนักงาน', field: 'ID_User' },
                                { title: 'ชื่อพนักงาน', field: 'Name' },
                                { title: 'ชื่อเล่น', field: 'NickName' },
                                { title: 'หัวหน้า', field: 'BossName' },
                                { title: 'แผนกย่อย', field: 'subDepName' }
                            ]
                        }, {
                            title: 'Total Score',
                            children: [
                                { title: 'เฉลี่ย', field: 'totalAgvScore' },
                                { title: 'เกรด', field: 'finalGrade' },
                            ]

                        }, {
                            title: `ข้อมูลการประเมินประจำปี ${searchNow ? `${searchNow['year'] || ''} ` : SEARCH_OPTION_YEAR[0]} (คะแนนเต็ม 100 คะแนน)`,
                            children: R.sort(((a, b) => (a - b)), dataSearch).map(each => {
                                return (
                                    {
                                        title: `${each.sortTH}`,
                                        field: `score${each.sortEN}`,
                                    }
                                )
                            })
                        }
                    ]}
                    dataSource={path(['reportKPIsLists'], reportState)}
                />
            }
            {/* {
                (Array.isArray(dataLists) && dataLists.length > 0) ? */}
            <LayoutTable
                bordered
                scroll={{ x: (dataSearch.length > 10) && 3000 }}
                rowKey={'ID_User'}
                columns={
                    STRUCTURE.COLUMN_EVALUATION({
                        view: view,
                        dataSource: (view) ? path(['reportKPIsLists'], reportState) : path(['dashboardKPIsLists'], reportState),
                        searchDate: dataSearch,
                        searchYear: searchNow ? `${searchNow['year']}` : (dataSearch && dataSearch[0].year),
                        action: (record, search) => {
                            let searchYear = searchNow ? `${searchNow['year']}` : (dataSearch && dataSearch[0].year)
                            let monthYear = (`${search.th}  ${searchNow && searchNow['year']}`);
                            let scoreStatus = record[`score${search.sortEN}`] ? splitString(record[`score${search.sortEN}`], '=)', 0) : ''
                            let scoreMonth = record[`score${search.sortEN}`] ? Number(splitString(record[`score${search.sortEN}`], '=)', 1)) : ''
                            // let monthActive = record[`score${search.sortEN}`] ? Number(splitString(record[`score${search.sortEN}`], '=)', 2)) : ''

                            // console.log({ scoreStatus, searchYear, search })
                            // console.log('StstusScore', { scoreStatus }, search.sortEN)
                            if (view) {
                                // console.log(record[`score${search.sortEN}`])
                                return (
                                    <ContentInfoScore
                                        factory={true}
                                        totalScore={`${record[`score${search.sortEN}`]}` || '-'}
                                        payload={{
                                            type: 'info',
                                            lineScore: { employeeId: record.employeeId, month: search.month, year: searchYear },
                                            employeeId: record.employeeId,
                                            activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${searchYear} ` : `0${search.month}/${searchYear}`)}`
                                        }}
                                        content={{ ...record, ...search, year: searchYear, monthYear, approval: (record.approvalNo2 === userLogin || record.sameApprover === "Y") }} />
                                )
                            } else {

                                switch (scoreStatus) {
                                    // case 1:
                                    // case 2:
                                    case '0':
                                    case 0:
                                        return (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                                <ContentUpdateScore
                                                    factory={true}
                                                    payload={{
                                                        type: 'update',
                                                        lineScore: { employeeId: record.employeeId, month: search.month, year: searchYear },
                                                        employeeId: record.employeeId,
                                                        activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${searchYear} ` : `0${search.month}/${searchYear}`)}`
                                                    }}
                                                    // searchContent={search}
                                                    content={{ ...record, ...search, year: searchYear, totalScore: record.totalScore, monthYear, approval: (record.approvalNo2 === userLogin || record.sameApprover === "Y") }} />
                                                <ContentInfoScore
                                                    factory={true}
                                                    totalScore={parseFloat(scoreMonth || 0).toFixed(2)}
                                                    payload={{
                                                        type: 'info',
                                                        lineScore: { employeeId: record.employeeId, month: search.month, year: searchYear },
                                                        employeeId: record.employeeId,
                                                        activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${searchYear} ` : `0${search.month}/${searchYear}`)}`
                                                    }}
                                                    content={{ ...record, ...search, year: searchYear, monthYear, approval: (record.approvalNo2 === userLogin || record.sameApprover === "Y") }} />
                                            </div>
                                        )
                                    case '1':
                                    case 1:
                                        // console.log({ record, scoreStatus, scoreMonth }, record[`score${search.sortEN}`])
                                        // console.log({ record, search })
                                        return (
                                            <ContentInfoScore
                                                factory={true}
                                                // totalScore={`${record[`score${search.sortEN}`]}` || '-'}
                                                totalScore={parseFloat(scoreMonth || 0).toFixed(2)}
                                                payload={{
                                                    type: 'info',
                                                    lineScore: { employeeId: record.employeeId, month: search.month, year: searchYear },
                                                    employeeId: record.employeeId,
                                                    activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${searchYear} ` : `0${search.month}/${searchYear}`)}`
                                                }}
                                                // search={search}
                                                content={{ ...record, ...search, year: searchYear, monthYear, approval: (record.approvalNo2 === userLogin || record.sameApprover === "Y") }} />
                                        )
                                    case 'N':
                                        // // console.log({ record, scoreStatus, scoreMonth }, record[`score${search.sortEN}`])
                                        // // console.log({ record, search })
                                        return null
                                    default:
                                        // return null
                                        return (<ContentInsertScore
                                            factory={true}
                                            payload={{
                                                type: 'insert',
                                                lineScore: { employeeId: record.employeeId, month: search.month, year: searchYear },
                                                // lineScore: { employeeId: record.employeeId, month: search.month, year: search.year },
                                                employeeId: record.employeeId,
                                                activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${searchYear} ` : `0${search.month}/${searchYear}`)}`
                                                // activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${search.year} ` : `0${search.month}/${search.year}`)}`
                                            }}
                                            // searchContent={search}
                                            content={{ ...record, ...search, year: searchYear, monthYear, approval: (record.approvalNo2 === userLogin || record.sameApprover === "Y") }} />)
                                }
                            }
                        }
                    })}

                dataSource={(view) ? path(['reportKPIsLists'], reportState) : path(['dashboardKPIsLists'], reportState)}
                pagination={{
                    position: 'bottom',
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['100', '500', '1000'],
                    onShowSizeChange: (current, size) => {
                        setPageSize(size)
                    },
                    showTotal: (total, range) => `ทั้งหมด ${total} รายการ`
                }}
            />
            {/* :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            } */}
            {/* </Spin> */}
        </Fragment >
    )
}

export default EvaluationFactory