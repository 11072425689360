import React from "react";
import { Tabs } from "antd";
import FormSearchProbation from "./conponents/forms/FormSearchProbation";
import FormAddProbation from "./conponents/forms/FormAddProbation";

const ManageMasterProbation = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ดูข้อมูล Probation" key="1">
          <FormSearchProbation />
        </TabPane>
        <TabPane tab="เพิ่มข้อมูล Probation" key="2">
          <FormAddProbation />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManageMasterProbation;
