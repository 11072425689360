import React from "react"
import { useText } from "../../conponents/typography/Typography";
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";
import { Tag, Space, Button } from "antd";
import { useButton } from './../../conponents/buttons/CustomButtonAntd';
import { convertBaht } from "../../../service/functions/convertOptions";
import { renderStatusColorInstallment } from "../RecheckWorkingSaleEmployee/ColumnRecheckWorkingSaleEmployee";

export const renderStatusWording = (code) => {
	switch (code) {
		case 'done':
			return 'พร้อม cutoff'
		case 'waitApproval1':
			return 'รอผู้อนุมัติอันดับ 1'
		case 'waitApproval2':
			return 'รอผู้อนุมัติอันดับ 2'
		case 'noDoc':
			return 'ยังไม่ได้สร้างชุดเอกสาร'
	}
}

export const renderStatusColor = (code) => {
	switch (code) {
		case 'done':
			return 'green'
		case 'waitApproval1':
		case 'waitApproval2':
			return 'gold'
		case 'noDoc':
			return 'red'
	}
}

export const ColumnCutOffCommissionIncentiveFSP = [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "left",
		fixed: "left",
		width: 100,
	},
	{
		title: "ตำแหน่ง",
		dataIndex: "positionName",
		key: "positionName",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("positionName", "ตำแหน่ง"),
	},
	{
		title: "รหัสเซลล์",
		dataIndex: "saleCode",
		key: "saleCode",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("saleCode", "รหัสเซลล์"),
	},
	{
		title: "รหัสพนักงาน",
		dataIndex: "employeeId",
		key: "employeeId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("employeeId", "รหัสพนักงาน"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "StartDateJob",
		key: "StartDateJob",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
	},
	{
		title: "แผนก",
		dataIndex: "departmentName",
		key: "departmentName",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("departmentName", "แผนก"),
	},
	{
		title: "สถานะพนักงานประจำงวด",
		dataIndex: "statusInstallmentName",
		key: "statusInstallmentName",
		align: "right",
		width: 150,
		...filterSearchColumn("statusInstallmentName", "สถานะพนักงานประจำงวด"),
		render: (text, record) => {
			return <Tag color={renderStatusColorInstallment(record.statusWorkerInstallment)}>{text}</Tag>
		}
	},
	{
		title: "Target ยอดขาย (บาท)",
		dataIndex: "saleTarget",
		key: "saleTarget",
		align: "right",
		width: 150,
		...filterSearchColumn("saleTarget", "Target ยอดขาย (บาท)"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Actual ยอดขาย (บาท)",
		dataIndex: "saleActual",
		key: "saleActual",
		align: "right",
		width: 150,
		...filterSearchColumn("saleActual", "Actual ยอดขาย (บาท)"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "%ยอดขายเดือน",
		dataIndex: "persentSalePerMonth",
		key: "persentSalePerMonth",
		align: "right",
		width: 150,
		...filterSearchColumn("persentSalePerMonth", "%ยอดขายเดือน"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Incentive Month",
		dataIndex: "incentiveMonth",
		key: "incentiveMonth",
		align: "right",
		width: 150,
		...filterSearchColumn("incentiveMonth", "Incentive Month"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Commission",
		dataIndex: "commissionMonth",
		key: "commissionMonth",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	
	
	{
		title: "การันตี Incentive",
		dataIndex: "incentiveGuarantee",
		key: "incentiveGuarantee",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Total Commision ก่อนปรับปรุง",
		dataIndex: "totalCommissionBeforeUpdate",
		key: "totalCommissionBeforeUpdate",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Total Incentive ก่อนปรับปรุง",
		dataIndex: "totalIncentiveBeforeUpdate",
		key: "totalIncentiveBeforeUpdate",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "เพิ่ม/ลด Commission",
		key: "commission",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text.commission && convertBaht((text.commission))}
				</div>
			)
		}
	},
	{
		title: "เพิ่ม/ลด Incentive",
		key: "incentive",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text.incentive && convertBaht((text.incentive))}
				</div>
			)
		}
	},
	{
		title: "หมายเหตุอนุมัติพิเศษ (ต้องระบุ)",
		key: "remarkApprovalSpecial",
		align: "left",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{record.remarkApprovalSpecial}
				</div>
			)
		}
	},
	{
		title: "Total Commision",
		dataIndex: "totalCommission",
		key: "totalCommission",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Total Incentive",
		dataIndex: "totalIncentive",
		key: "totalIncentive",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text)}
				</div>
			)
		}
	},
];

export const ColumnCutOffCommissionIncentiveDashboardFSP = ({ editRow, returnStatusRow, viewRow }) => [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "left",
		fixed: "left",
		width: 10,
		...filterSearchColumn("rowNo", "ลำดับ"),
	},
	{
		title: "เลขที่เอกสาร",
		dataIndex: "docNo",
		key: "docNo",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("docNo", "เลขที่เอกสาร"),
	},
	{
		title: "ช่วงวันที่งวดเงินเดือน",
		dataIndex: "installmentDatePeriod",
		key: "installmentDatePeriod",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("installmentDatePeriod", "ช่วงวันที่งวดเงินเดือน"),
		render: (text, record) => {
			return `${record.installmentStartDate} ถึง ${record.installmentEndDate}`
		}
	},
	{
		title: "วันที่สร้างเอกสาร",
		dataIndex: "createDate",
		key: "createDate",
		align: "left",
		fixed: "left",
		width: 100,
		render: (text) => moment(text).format('YYYY-MM-DD')
	},
	// {
	// 	title: "แผนก",
	// 	dataIndex: "departmentName",
	// 	key: "departmentName",
	// 	align: "left",
	// 	fixed: "left",
	// 	width: 100,
	// 	...filterSearchColumn("departmentName", "แผนก"),
	// },
	{
		title: "",
		dataIndex: "action",
		key: "action",
		align: "right",
		width: 50,
		render: (text, record) => {
			return (
				<div style={{ textAlignLast: 'center' }}>
					<Space>
						{

							record.statusSave == '1' ?
								record.statusCutOff == '1' ?
									<>
										{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
										{useButton({ call: "search", moreProps: { label: 'ย้อนสถานะ', onClick: () => returnStatusRow(record) } })}
									</>
									:
									<Button type="primary" onClick={() => editRow(record)} style={{ background: "#F28C28	", borderColor: "#F28C28" }} >แก้ไข
									</Button>
								: ''
						}
					</Space>
				</div>
			)
		}
	},
];
