import React from 'react'
import { LayoutWrapper, LayoutBreadcrumb } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_UPDATE_OT } from '../commonsOT/structuresOT/breadcrumOT'
import UpdateOT from '../componentsOT/updateOT'

const PageUpdateOT = () => {

    return (
        <LayoutWrapper
            breadcrumb={
                <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_UPDATE_OT()} />
            }
            search={null}
            content={<UpdateOT />}
        />
    )
}
export default PageUpdateOT
