import React from 'react'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const LayoutTabs = ({ type, defaultkey = '1', tabsLists, onChange, extraContent }) => {
	function callback(key) {
		if (typeof onChange === 'function') {
			onChange(key)
		}
	}

	return (
		<Tabs defaultActiveKey={defaultkey} type={type} onChange={callback}
			tabBarExtraContent={extraContent}
		>
			{
				Array.isArray(tabsLists) && tabsLists.map((element, elementIndex) => {
					return (
						<TabPane tab={element.tab} key={elementIndex + 1}>
							{element.extra || ''}
							{element.content}
						</TabPane>
					)
				})
			}
		</Tabs>
	)
};