import React from 'react';
import { useForm } from 'react-hook-form';
import { Spin, Card, Collapse } from 'antd';
import { useCollapseState } from './hook/useCollapseState';
import { useGetOptions } from './hook/useGetOptions';
import {
  ButtonTheme,
  ContainerButton,
} from '../../../components/global/buttons';
import GeneralDocumentDetail from './views/GeneralDocumentDetail';
import { FormPrint } from './views/FormPrint';
import { useGetDetail } from './hook/useGetDetail';
import { useGetFormPrint } from './hook/useGetFormPrint';

const FormHireDetailScene = () => {
  const { Panel } = Collapse;
  const { control, setValue, getValues, handleSubmit, setError } = useForm();
  const {
    optionsCompanyTH,
    optionsPositionEN,
    optionsPositionTH,
    optionsDepartmentTH,
    optionsSubDepartmentTH,
    optionsWorkShift,
    loadingOption,
  } = useGetOptions();
  const { loadingDocument } = useGetDetail({ setValue });
  const { activeKey, setActiveKey } = useCollapseState();
  const { formPrint, loadingFormPrint, onGetFormPrint, onPrint, rawValue } =
    useGetFormPrint({
      setError,
      setActiveKey,
    });

  return (
    <Card title={'แบบฟอร์มยืนยันการจ้าง (ใบปะหน้า)'}>
      <Collapse
        activeKey={activeKey}
        onChange={(e) => {
          setActiveKey(() => e);
        }}
      >
        <Panel header='แก้ไขรายละเอียดทั่วไป' key='docDetailGeneral'>
          <Spin spinning={loadingDocument && loadingOption} tip='กำลังโหลด'>
            <GeneralDocumentDetail
              getValues={getValues}
              control={control}
              setValue={setValue}
              options={{
                optionsCompanyTH,
                optionsPositionEN,
                optionsPositionTH,
                optionsDepartmentTH,
                optionsSubDepartmentTH,
                optionsWorkShift,
              }}
            />
          </Spin>
          <ContainerButton center style={{ marginTop: '20px' }}>
            <ButtonTheme
              bgColor='grey'
              colorFill={false}
              title={'พรีวิวเอกสาร'}
              onClick={handleSubmit(onGetFormPrint)}
            />
            <ButtonTheme
              style={{ marginLeft: '20px' }}
              title={'พิมพ์เอกสาร'}
              bgColor='#007bff'
              onClick={handleSubmit(onPrint)}
            />
          </ContainerButton>
        </Panel>

        <Panel header='เอกสาร' key='formPrint'>
          {formPrint && Object.keys(formPrint).length > 0 && (
            <Spin spinning={loadingFormPrint} tip='กำลังโหลด'>
              {<FormPrint formData={formPrint} rawValue={rawValue} />}
            </Spin>
          )}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default FormHireDetailScene;
