import React, { createContext, useReducer, useContext } from "react";
import { path, equals } from "ramda";
import { REPORT_OPTION, servicesOptions, MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions";
import { APISelectReviewerCurrent, APISelectSummaryReplyEvacByDep, APISummaryAvgScoreByLine, APISelectSummaryReplyEvacByForm, APISelectReviewerUpdate, APISelectDashboardReviewer, APISelectEmpEvacUpdate, APISelectDashboardEvac } from "./reportEvacConnect";
import { MasterEvacContext } from "../masterEvac/masterEvacProvider";
import { browserHistory } from "react-router";
import { APISelectDataEvac, APISelectDashboardEvacAction, APISelectDashboardEvacView } from "../reportEvac/reportEvacConnect";
import { APISelectFilterDepEvac, APISelectOptionDepEvac, APISelectQuestion } from "../masterEvac/masterEvacConnect";
import { Modal } from "antd";

export const ReportEvacContext = createContext();

const initialState = {
    reviewerData: {
        docNo: null,
        formId: null,
        formDescription: null,
        confirmStatus: null,
        year: null,
        quarter: null,
        formGroup: [],
        reviewerLists: []
    },
    questionData: {
        formId: null,
        year: null,
        quarter: null,
        depName: null,
        userId: null,
        fullname: null,
        nickname: null,
        depNo: null,
        subDepNo: null,
        frequencyWork: null,
        formDescription: null,
        pathImg: null,
        question: []
    },
    evacData: {
        action: [],
        view: [],
        summary: [],
        followEvac: []
    },
    employeeEvac: [],
    summaryReplyByForm: [],
    summaryReplyByDep: [],
    summaryAvgByLine: [],
    headSummary: null

}

const REPORT_EVAC_ACTION = {
    SET_REPORT_EVAC_SUCCESS: "SET_REPORT_EVAC_SUCCESS",
    SET_REPORT_EVAC_FAILURE: "SET_REPORT_EVAC_FAILURE"
}

const reportEvacReducer = (state, action) => {
    switch (action.type) {
        case REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS:
            return { ...state, ...action.state }
        case REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE:
        default:
            return state
    }
}

const ProviderReportEvac = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [reportEvacState, reportEvacDispatch] = useReducer(reportEvacReducer, initialState)
    const { masterEvacState } = useContext(MasterEvacContext)
    let depNoPermission = []

    async function getReportEvac({ fieldname, payload, pathname }) {
        switch (fieldname) {

            case REPORT_OPTION.SUMMARY_REPLY_EVAC_BY_ADMIN:

                await APISelectSummaryReplyEvacByForm({
                    "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                }, async (response) => {
                    //console.log('SUMMARY_REPLY_EVAC_BY_ADMIN ', response)
                    if (response) {
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "summaryReplyByForm": path(['summary'], response),
                            }
                        })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })


                break;
            case REPORT_OPTION.SUMMARY_REPLY_EVAC_BY_DEP:
                // console.log('SUMMARY_REPLY_EVAC_BY_DEP ')
                let newDepNo = []
                Array.isArray(masterEvacState[MASTER_OPTION.OPTION_DEP_EVAC]) && masterEvacState[MASTER_OPTION.OPTION_DEP_EVAC].forEach(({ optionId }) => newDepNo.push(optionId))

                //console.log({ newDepNo })
                await APISelectSummaryReplyEvacByDep({
                    "userId": userLogin,
                    "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    "depNo": newDepNo,
                }, async (response) => {
                    //console.log('SUMMARY_REPLY_EVAC_BY_DEP ', response)
                    if (response) {
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "summaryReplyByDep": response
                            }
                        })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })


                break;
            case REPORT_OPTION.SUMMARY_AVG_SCORE_BY_LINE:
                // console.log('SUMMARY_AVG_SCORE_BY_LINE', masterEvacState, payload)
                //    let newFormId = []
                //  Array.isArray(masterEvacState[MASTER_OPTION.OPTION_FORM_EVAC]) && masterEvacState[MASTER_OPTION.OPTION_FORM_EVAC].forEach(({ optionId }) => newFormId.push(optionId))
                // await getMaster({ fieldname: MASTER_OPTION.OPTION_FORM_EVAC })
                //console.log('SUMMARY_AVG_SCORE_BY_LINE', { payload })
                await reportEvacDispatch({
                    type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                    state: {
                        "summaryAvgByLine": [],
                        "headSummary": null,

                    }
                })
                if (payload && payload.formId) {
                    await APISummaryAvgScoreByLine(payload, async (response) => {
                        //console.log('APISummaryAvgScoreByLine ', response)
                        if (response) {
                            await reportEvacDispatch({
                                type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                                state: {
                                    "summaryAvgByLine": path(['summary'], response),
                                    "headSummary": path(['header', 0], response),
                                }
                            })
                        } else {
                            await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                        }
                    })
                } else {
                    Modal.error({
                        title: 'กรุณาเลือกแบบประเมิน'
                    })
                }
                break;
            case REPORT_OPTION.DASHBOARD_REVIEWER:
            case REPORT_OPTION.FILTER_DASHBOARD_REVIEWER:
                // console.log('DASHBOARD_REVIEWER ', masterEvacState, payload)
                await APISelectDashboardReviewer({
                    "userId": userLogin,
                    "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                }, async (response) => {
                    // console.log('DASHBOARD_REVIEWER ', response)
                    if (response) {
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "reviewerData": { ...response }
                            }
                        })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;
            case REPORT_OPTION.DASHBOARD_EVAC_VIEW:
                //console.log('[PAYLOAD] DASHBOARD_EVAC_VIEW ', masterEvacState)
                switch (userPermission) {
                    // case 'HR':
                    // case 'HR-HeadDep':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'Intern':
                    // case 'HR-Super':
                    case 'HRS-OFF':
                    case 'HRD-OFF':
                    case 'HRM-OFF':
                    case 'HRS-FAC':
                    case 'HRD-FAC':
                    case 'HRM-FAC':
                        await APISelectOptionDepEvac({ depNo: '' }, res => {
                            servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false).forEach(({ optionId }) => depNoPermission.push(optionId))
                        })
                        break;

                    default:
                        await APISelectFilterDepEvac({
                            userId: userLogin,
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                        }, res => {
                            servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false).forEach(({ optionId }) => depNoPermission.push(optionId))
                        })
                        break;
                }
                //console.log({ depNoPermission, masterEvacState })
                // depNoPermission.shift()
                let payloadEvacView = {
                    "userId": userLogin,
                    "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    "depNo": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`].length > 0 ? masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`] : depNoPermission,
                }
                // console.log({ depNoPermission })
                await APISelectDashboardEvacView(payloadEvacView, async (response) => {
                    // console.log('DASHBOARD_EVAC ', response)
                    if (response) {
                        const { view, summary } = response;
                        let followEvac = Array.isArray(view) && view.reduce((acc, cur) => {
                            const dataIndex = summary.findIndex(el => equals(el.depNo, cur.depNo))
                            const dataLine = view.filter(el => equals(el.depNo, cur.depNo))
                            // console.log({ dataIndex, dataLine, summary, view, cur })
                            if (dataIndex !== -1) {
                                acc[dataIndex] = {
                                    ...summary[dataIndex], dataLine: dataLine
                                }
                                return acc
                            } else {
                                acc[dataIndex] = {
                                    ...summary[dataIndex], dataLine: []
                                }
                                return acc
                            }
                        }, [])
                        // console.log({ followEvac })
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "evacData": {
                                    ...reportEvacState.evacData,
                                    year: 2020,
                                    // "action": action,
                                    "view": view,
                                    "summary": summary,
                                    followEvac
                                }
                            }
                        })
                        // await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;
            case REPORT_OPTION.DASHBOARD_EVAC:
                //console.log('[PAYLOAD] DASHBOARD_EVAC ', masterEvacState)
                // let depNoPermission = []
                // masterEvacState[MASTER_OPTION.OPTION_DEP_EVAC].forEach(({ optionId }) => depNoPermission.push(optionId))

                switch (userPermission) {
                    // case 'HR':
                    // case 'HR-HeadDep':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'Intern':
                    // case 'HR-Super':
                    case 'HRS-OFF':
                    case 'HRD-OFF':
                    case 'HRM-OFF':
                    case 'HRS-FAC':
                    case 'HRD-FAC':
                    case 'HRM-FAC':
                        await APISelectOptionDepEvac({ depNo: '' }, res => {
                            servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false).forEach(({ optionId }) => depNoPermission.push(optionId))
                        })
                        break;

                    default:
                        await APISelectFilterDepEvac({
                            userId: userLogin,
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                        }, res => {
                            servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false).forEach(({ optionId }) => depNoPermission.push(optionId))
                        })
                        break;
                }
                //console.log({ depNoPermission, masterEvacState })
                // depNoPermission.shift()

                await APISelectDashboardEvacAction({
                    "userId": userLogin,
                    "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    "depNo": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`].length > 0 ? masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`] : depNoPermission,
                }, async (response) => {
                    // console.log('DASHBOARD_EVAC ', response)
                    if (response) {
                        const { action } = response;
                        // let followEvac = Array.isArray(view) && view.reduce((acc, cur) => {
                        //     const dataIndex = summary.findIndex(el => equals(el.depNo, cur.depNo))
                        //     const dataLine = view.filter(el => equals(el.depNo, cur.depNo))
                        //     // console.log({ dataIndex, dataLine, acc, cur })
                        //     if (dataIndex !== -1) {
                        //         acc[dataIndex] = {
                        //             ...summary[dataIndex], dataLine: dataLine
                        //         }
                        //         return acc
                        //     } else {
                        //         acc[dataIndex] = {
                        //             ...summary[dataIndex], dataLine: []
                        //         }
                        //         return acc
                        //     }
                        // }, [])
                        // console.log({ followEvac })
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "evacData": {
                                    ...reportEvacState.evacData,
                                    year: 2020,
                                    "action": action,
                                    // "view": view,
                                    // "summary": summary,
                                    // followEvac
                                }
                            }
                        })
                        // await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                // await APISelectDashboardEvac({
                //     "userId": userLogin,
                //     "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                //     "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                //     "depNo": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`].length > 0 ? masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`] : depNoPermission,
                // }, async (response) => {
                //     // console.log('DASHBOARD_EVAC ', response)
                //     if (response) {
                //         const { action, view, summary } = response;
                //         let followEvac = Array.isArray(view) && view.reduce((acc, cur) => {
                //             const dataIndex = summary.findIndex(el => equals(el.depNo, cur.depNo))
                //             const dataLine = view.filter(el => equals(el.depNo, cur.depNo))
                //             // console.log({ dataIndex, dataLine, acc, cur })
                //             if (dataIndex !== -1) {
                //                 acc[dataIndex] = {
                //                     ...summary[dataIndex], dataLine: dataLine
                //                 }
                //                 return acc
                //             } else {
                //                 acc[dataIndex] = {
                //                     ...summary[dataIndex], dataLine: []
                //                 }
                //                 return acc
                //             }
                //         }, [])
                //         // console.log({ followEvac })
                //         await reportEvacDispatch({
                //             type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                //             state: {
                //                 "evacData": {
                //                     ...reportEvacState.evacData,
                //                     year: 2020,
                //                     "action": action,
                //                     "view": view,
                //                     "summary": summary,
                //                     followEvac
                //                 }
                //             }
                //         })
                //         // await browserHistory.push({ pathname })
                //     } else {
                //         await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                //     }
                // })
                break;
            case REPORT_OPTION.SELECT_DATA_EVAC:
                // console.log('[PAYLOAD] SELECT_DATA_EVAC ', payload)
                await APISelectDataEvac({
                    "formId": payload.formId,
                    "year": payload.year,
                    "quarter": payload.quarter
                }, async (response) => {
                    // console.log('SELECT_DATA_EVAC ', response)
                    if (response) {
                        const { arrData } = response;
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "reviewerData": {
                                    ...reportEvacState.reviewerData,
                                    "docNo": payload.docNo,
                                    "formId": payload.formId,
                                    "confirmStatus": payload.confirmStatus,
                                    "formDescription": payload.formDescription,
                                    "reviewerLists": arrData
                                }
                            }
                        })
                        await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;
            case REPORT_OPTION.SELETE_REVIEWER_CURRENT:
                // console.log('[PAYLOAD] SELETE_REVIEWER_CURRENT ', payload)
                await APISelectReviewerCurrent({
                    "docNo": payload.docNo
                }, async (response) => {
                    // console.log('SELETE_REVIEWER_CURRENT ', response)
                    if (response) {
                        const { arrData } = response;
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "reviewerData": {
                                    ...reportEvacState.reviewerData,
                                    "docNo": payload.docNo,
                                    "formId": payload.formId,
                                    "confirmStatus": payload.confirmStatus,
                                    "formDescription": payload.formDescription,
                                    "reviewerLists": arrData
                                }
                            }
                        })
                        await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;
            case REPORT_OPTION.SELETE_REVIEWER_UPDATE:
                // console.log('[PAYLOAD] SELETE_REVIEWER_UPDATE ', payload)
                await APISelectReviewerUpdate({
                    "formId": payload.formId,
                    "year": payload.year,
                    "quarter": payload.quarter
                }, async (response) => {
                    // console.log('SELETE_REVIEWER_UPDATE ', response)
                    if (response) {
                        const { arrData } = response;
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "reviewerData": {
                                    ...reportEvacState.reviewerData,
                                    "docNo": payload.docNo,
                                    "formId": payload.formId,
                                    "confirmStatus": payload.confirmStatus,
                                    "formDescription": payload.formDescription,
                                    "reviewerLists": arrData
                                }
                            }
                        })
                        await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;

            case REPORT_OPTION.SELECT_QUESTION:
                // console.log('SELECT_QUESTION', payload)
                await APISelectQuestion({ formId: payload.formId }, async (response) => {
                    // console.log('SELECT_QUESTION', response)
                    if (response) {
                        await reportEvacDispatch({
                            type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_SUCCESS,
                            state: {
                                "questionData": {
                                    "formId": payload.formId,
                                    "year": payload.year,
                                    "quarter": payload.quarter,
                                    "depName": payload.depName,
                                    "userId": payload.userId,
                                    "fullname": payload.fullname,
                                    "nickname": payload.nickname,
                                    "depNo": payload.depNo,
                                    "subDepNo": payload.subDepNo,
                                    "formDescription": payload.formDescription,
                                    "frequencyWork": payload.frequencyWork,
                                    "pathImg": response.pathImg,
                                    "question": response.question
                                }
                            }
                        })
                        await browserHistory.push({ pathname })
                    } else {
                        await reportEvacDispatch({ type: REPORT_EVAC_ACTION.SET_REPORT_EVAC_FAILURE })
                    }
                })
                break;

            default:
                await browserHistory.push({ pathname })
                break;
        }
    }

    return (
        <ReportEvacContext.Provider
            value={{
                getReportEvac,
                reportEvacState,
                reportEvacDispatch
            }}>
            {children}
        </ReportEvacContext.Provider>
    )
}


export default ProviderReportEvac