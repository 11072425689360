import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs, Table } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_SCORE_RECRUITER } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportRecruiterScore } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { ComponentReportRecruiterGrade, DEFAULT_REPORT_RECRUITER_GRADE } from "../../constants/Reports/Components/ComponentReportRecruiterGrade";
import { ColumnReportRecruiterScoreDept, ColumnReportRecruiterScoreStatus, ColumnReportRecruiterScoreUser } from "../../constants/Reports/Columns/ColumnReportRecruiterScore";

const FormReportRecruiterScore = () => {
    const { location: locationLogin } = GET_UserLogin();
    const [formSearch] = Form.useForm();
    const [ChangeValue, setChangeValue] = useState({ ...DEFAULT_REPORT_RECRUITER_GRADE, location: locationLogin === "FAC" ? "FAC" : undefined });
    const [dataTable, setDataTable] = useState({ dataByStatus: [], dataByDep: [], dataByrecruit: [] });
    const [summaryTable, setSummaryTable] = useState({ totalStatus: {}, totalDep: {}, totalRecruit: {} });
    const [activeTabs, setActiveTabs] = useState(1);
    const [loadingScreen, setLoadingScreen] = useState(false);

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        recruiterId: useOptionSelect("recruiterHr"),
    }

    const onFinish = (values) => {
        const { company, location, depNo, startDate, toDate, recruiterId } = values
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            recruiterId: recruiterId || "",
        }
        selectDataScoreRecruiter(objectBody);
    };

    const onReset = () => {
        formSearch.resetFields();
        setDataTable({ dataByStatus: [], dataByDep: [], dataByrecruit: [] });
        setSummaryTable({ totalStatus: {}, totalDep: {}, totalRecruit: {} });
        setChangeValue({ ...DEFAULT_REPORT_RECRUITER_GRADE, location: locationLogin === "FAC" ? "FAC" : undefined });
        setActiveTabs(1);
    };

    const selectDataScoreRecruiter = async (objectValue) => {
        try {
            setLoadingScreen(true);
            setDataTable({ dataByStatus: [], dataByDep: [], dataByrecruit: [] });
            setSummaryTable({ totalStatus: {}, totalDep: {}, totalRecruit: {} });
            const response = await POST(POST_SELECT_DATA_SCORE_RECRUITER, objectValue);
            const { result } = response;
            let mapResult = { dataByStatus: [], dataByDep: [], dataByrecruit: [] };
            let setSummary = { totalStatus: {}, totalDep: {}, totalRecruit: {} }
            if (result) {
                if (Array.isArray(result)) {
                    if (result.length === 0) {
                        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
                    }
                } else {
                    //console.log("result", result)
                    const { dataByStatus, dataByDep, dataByrecruit } = result
                    const { dataArr: dataStatus, overAll: totalStatus } = dataByStatus
                    const { dataArr: dataDep, overAll: totalDep } = dataByDep
                    const { dataArr: dataRecruit, overAll: totalRecruit } = dataByrecruit
                    mapResult = {
                        dataByStatus: dataStatus.map((row, index) => ({
                            key: `${index}`,
                            ...row,
                        })),
                        dataByDep: dataDep.map((row, index) => ({
                            key: `${index}`,
                            ...row,
                        })),
                        dataByrecruit: dataRecruit.map((row, index) => ({
                            key: `${index}`,
                            ...row,
                        })),
                    };
                    setSummary = { totalStatus, totalDep, totalRecruit };
                }
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
            setSummaryTable(setSummary);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search select Data Score Recruiter: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const onChangeValue = (name, value) => {
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                if (locationLogin === "FAC") {
                    setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                } else {
                    setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
                }
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        formSearch.setFieldsValue(setUndefined);
        setDataTable({ dataByStatus: [], dataByDep: [], dataByrecruit: [] });
        setSummaryTable({ totalStatus: {}, totalDep: {}, totalRecruit: {} });
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };

    const renderSummaryTable = (data, colSpan) => {
        if (!data) return null;
        const summary = data;
        let cell = [];
        let setClassname = ""
        for (const key in summary) {
            if (key.includes("percent")) {
                if (summary[key] < 50) {
                    setClassname = "bg-percentFail";
                } else if (summary[key] < 60) {
                    setClassname = "bg-percentWarning";
                }
                else if (summary[key] > 59) {
                    setClassname = "bg-percentPass";
                }
                cell.push(
                    <Table.Summary.Cell key={key} align="right" className={setClassname}>
                        {summary[key] || 0} %
                    </Table.Summary.Cell>
                );
            } else {
                cell.push(
                    <Table.Summary.Cell key={key} align="right">
                        {summary[key] || 0}
                    </Table.Summary.Cell>
                );
            }
        }
        return (
            <Table.Summary >
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center" colSpan={colSpan}>
                        รวมทั้งสิ้น
                    </Table.Summary.Cell>
                    {cell}
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    const checkDataSetExcel = (activeTab) => {
        switch (activeTab) {
            case 1:
                return dataTable.dataByStatus;
            case 2:
                return dataTable.dataByDep;
            case 3:
                return dataTable.dataByrecruit;
            default:
                return [];
        }
    };

    const checkFileNameExcel = (activeTab) => {
        switch (activeTab) {
            case 1:
                return "สถานะจ้างงาน";
            case 2:
                return "แผนก";
            case 3:
                return "ผู้สรรหา";
            default:
                return "";
        }
    };

    return (
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
            <Form form={formSearch} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }}>
                <Card
                    title="ค้นหารายงานคะแนนความพึงพอใจการสรรหา (นับจากวันที่ผ่านทดลองงาน)"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportRecruiterGrade({
                            options: optionSearch,
                            handleChange: onChangeValue,
                            isDisable: {
                                location: locationLogin === "FAC" || false,
                            }
                        }),
                    })}
                </Card>
                <Card
                    title="รายงานคะแนนความพึงพอใจการสรรหา (นับจากวันที่ผ่านทดลองงาน)"
                    style={{ marginBottom: 30 }}
                    extra={
                        useExportExcel({
                            header: Header_ReportRecruiterScore(activeTabs),
                            dataset: checkDataSetExcel(activeTabs),
                            filename: `รายงานคะแนนความพึงพอใจการสรรหา_${checkFileNameExcel(activeTabs)}`,
                        })
                    }
                >
                    <Tabs
                        defaultActiveKey="1"
                        onChange={key => {
                            setActiveTabs(+key);
                        }}
                    >
                        <Tabs.TabPane tab="สถานะจ้างงาน" key="1">
                            <CustomTableAtnd
                                HeadTable={ColumnReportRecruiterScoreStatus}
                                DataTable={dataTable.dataByStatus}
                                moreProps={{
                                    scroll: {
                                        x: sumColumnWidth(ColumnReportRecruiterScoreStatus) || 2850,
                                        y: 500,
                                    },
                                    summary: () => renderSummaryTable(summaryTable.totalStatus, 1),
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="แผนก" key="2">
                            <CustomTableAtnd
                                HeadTable={ColumnReportRecruiterScoreDept}
                                DataTable={dataTable.dataByDep}
                                moreProps={{
                                    scroll: {
                                        x: sumColumnWidth(ColumnReportRecruiterScoreDept) || 2850,
                                        y: 500,
                                    },
                                    summary: () => renderSummaryTable(summaryTable.totalDep, 1),
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ผู้สรรหา" key="3">
                            <CustomTableAtnd
                                HeadTable={ColumnReportRecruiterScoreUser}
                                DataTable={dataTable.dataByrecruit}
                                moreProps={{
                                    scroll: {
                                        x: sumColumnWidth(ColumnReportRecruiterScoreUser) || 2850,
                                        y: 500,
                                    },
                                    summary: () => renderSummaryTable(summaryTable.totalRecruit, 2),
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Form>
        </Spin>
    )
}

export default FormReportRecruiterScore
