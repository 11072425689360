import { FIELD_STEP, MESSAGE_ERROR } from ".."
import { KEY_OPTION, KEY_TYPE } from "../../../constants/enums/enumCenter"

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Label ที่นี่
    DRIVING_SKILL: 'ความสามารถในการขับขี่ (Driving Skill)',
    OWNER_VEHICLE: 'มีพาหนะเป็นของตนเอง (Own a vihicle ?)',
    PLATE_NO: 'เลขทะเบียนรถยนต์ (Car License plate No.)',
    LICENSE_NO: 'เลขที่ใบอนุญาตขับขี่ (Driving License No.)',
    DRIVING_TYPE: `ประเภทใบอนุญาต(Driver's license type)`
}

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    DRIVING_SKILL: 'drivingSkill',
    OWNER_VEHICLE: 'ownerVehicle',
    PLATE_NO: 'plateNo',
    LICENSE_NO: 'licenseNo',
    DRIVING_TYPE: 'drivingType'
}


/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
    plateNo: '',
    drivingType: '',
    licenseNo: ''
}

export const FIELD_DRIVING = FIELD_PAGES
export const INITIAL_DRIVING = INITIAL_PAGES
/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_DRIVING = (props) => {
    const { disabledAll, options } = props
    /**
    * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
    */
    return [
        [{
            span: 12,
            itemprops: {
                name: FIELD_PAGES.DRIVING_SKILL,
                label: LABEL_PAGES.DRIVING_SKILL, //ความสามารถในการขับขี่ (Driving Skill)
                rules: [{ required: false, message: MESSAGE_ERROR.RADIO }]
            },
            inputprops: {
                type: KEY_TYPE.RADIO,
                disabled: disabledAll,
                placeholder: '',
                options: [
                    { optionId: 1, optionLabel: 'ขับขี่ได้', optionValue: '1' },
                    { optionId: 2, optionLabel: 'ขับขี่ไม่ได้', optionValue: '0' },
                ]
            }
        }, {
            span: 12,
            itemprops: {
                name: FIELD_PAGES.OWNER_VEHICLE,
                label: LABEL_PAGES.OWNER_VEHICLE, //มีพาหนะเป็นของตนเอง (Own a vihicle ?)
                rules: [{ required: false, message: MESSAGE_ERROR.RADIO }]
            },
            inputprops: {
                type: KEY_TYPE.RADIO,
                disabled: disabledAll,
                placeholder: '',
                options: [
                    { optionId: 1, optionLabel: 'มี', optionValue: '1' },
                    { optionId: 2, optionLabel: 'ไม่มี', optionValue: '0' },
                ]
            }
        }, {
            span: 24,
            inputprops: {
                type: KEY_TYPE.LIST_FORM,
                name: FIELD_STEP.COLLAPSE_S41, //drivingInfo
                removelenght: 1,
                header: (idx) => null,
                initialValues: { ...INITIAL_PAGES },
                disabled: disabledAll,
                content: (field) => [
                    {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.DRIVING_TYPE],
                            label: !+field.fieldKey && LABEL_PAGES.DRIVING_TYPE, //ประเภทใบอนุญาต(Driver's license type)
                            fieldKey: [field.fieldKey, FIELD_PAGES.DRIVING_TYPE],
                            rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],

                        },
                        inputprops: {
                            type: KEY_TYPE.SELECT,
                            disabled: disabledAll,
                            placeholder: '',
                            options: options[KEY_OPTION.CENTER.OPTION_DRIVING_TYPE]
                        }
                    }, {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.PLATE_NO],
                            label: !+field.fieldKey && LABEL_PAGES.PLATE_NO, //เลขทะเบียนรถยนต์ (Car License plate No.)
                            fieldKey: [field.fieldKey, FIELD_PAGES.PLATE_NO],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            maxLength: 10,
                            disabled: disabledAll,
                            placeholder: '',
                        }
                    }, {
                        span: 11,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.LICENSE_NO],
                            label: !+field.fieldKey && LABEL_PAGES.LICENSE_NO, //เลขที่ใบอนุญาตขับขี่  (Driving License No.)
                            fieldKey: [field.fieldKey, FIELD_PAGES.LICENSE_NO],
                            rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                        },
                        inputprops: {
                            type: KEY_TYPE.NUMBER,
                            length: 8,
                            disabled: disabledAll,
                            placeholder: '',
                        }
                    }
                ]
            }
        }]
    ]
}
