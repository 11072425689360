import { useState, useEffect } from "react";

export const useButtonState = ({ docLength }) => {
  const [selectArr, setSelectedArr] = useState([false]);

  useEffect(() => {
    onFetchButtonState();
  }, [docLength]);

  const onFetchButtonState = () => {
    setSelectedArr(() =>
      [...Array(docLength > 0 ? docLength : 1).keys()].map(() => true)
    );
  };

  const onSelected = (index, isChecked) => {
    setSelectedArr((prev) => {
      let cur = prev;
      cur[index] = isChecked;
      return [...cur];
    });
  };

  return {
    selectArr,
    onSelected,
  };
};
