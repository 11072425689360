import React from "react";
import "react-table/react-table.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import SummaryPEFTable from "../components/probation/SummaryPEFTable";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import { loadingPage } from "../components/loadingPage";
import {
  GET,
  POST,
  POST_REPORT_SUMMARY_PEF,
  SELECT_REPORT_SUMMARY_PEF,
} from "../service/";
import { getCurrent } from "../extendFunction/getCurrentYears";
import {
  RenderCF_HR,
  RenderDepartmentPMS,
  RenderYearPMS_PEF,
} from "../modulePMS/componentsPMS/dropdown";
import { Modal } from "antd";

const obj = SetStringToObject(Cookies.get("cookie_session"));
class SummaryReportPEF extends React.Component {
  constructor(props) {
    super(props);
    // const year = getCurrent().yearsNow;
    const year = getCurrent().yearsSelect;
    console.log("year", year);
    const { userLogin, premission } = props;
    this.state = {
      allData: [],
      dataToDownload: [],
      yearsTxt: year,
      done: false,
      CF_HR: "",
      SegmentTxt: "",
      userLogin,
      permission: premission,
    };
  }

  onLoadFinish = () => {
    this.setState({ done: true });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    if (name === "SegmentTxt" && value === "all") {
      this.setState({ [name]: "" });
    }
    this.setState({ [name]: value });
  };

  search = async () => {
    try {
      const { SegmentTxt, yearsTxt, CF_HR, userLogin, permission } = this.state;
      this.setState({ allData: [], done: false });
      const objectBody = {
        userID: userLogin,
        permission,
        years: yearsTxt,
        depNo: SegmentTxt,
        statusCF: CF_HR,
      };
      const response = await POST(POST_REPORT_SUMMARY_PEF, objectBody);
      const { result } = response;
      let allData = [];
      if (result.data !== 0) {
        allData = result.data.map((row, index) => ({ key: index, ...row }));
      }
      this.setState({ allData });
    } catch (err) {
      console.log("Error SELECT REPORT SUMMARY PEF: ", err);
      Modal.error({ title: "เกิดข้อผิดพลาด", content: `${err.message}` });
    } finally {
      this.setState({ done: true });
    }
  };

  render() {
    const { userLogin, allData, done } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            Report Summary Performance Evaluation :: ใบสรุปผลการประเมิน
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col md={3}>
                  <RenderYearPMS_PEF onChange={this.onChange} />
                </Col>
                <Col md={3}>
                  <RenderDepartmentPMS
                    userLogin={userLogin}
                    onChange={this.onChange}
                    onLoadFinish={this.onLoadFinish}
                  />
                </Col>
                <Col md={3}>
                  <RenderCF_HR onChange={this.onChange} />
                </Col>
                <Col md={3} className="text-center cs_btn">
                  <FormGroup>
                    <Button color="info" onClick={this.search}>
                      SEARCH
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <hr />
        {done ? (
          <SummaryPEFTable dataSource={allData} />
        ) : (
          <div>{loadingPage} </div>
        )}
      </div>
    );
  }
}

export default SummaryReportPEF;
