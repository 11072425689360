
import { Button, Input } from 'antd';
import React from 'react';
import { SR_COLORS, Svg } from './index.jsx';


/***
 * 
 * TODO: Function for checking the required label and return output to the format
 * @param {boolean} required - The parameter for this method is boolean.
 * @param {string} label - The parameter for this method is string.
 * ! The required is false 
 * * requiredLabel({required: false, label: 'Lable : ' }) --> return Lable 
 * ? The required is true 
 * * requiredLabel({required: ture,  label: 'Lable : ' }) --> return * Lable :
 * 
 */

export function requiredLabel({ required, label, color }) {
    let str = ''
    if (required) {
        str = <span style={{ color: color || SR_COLORS.LABEL_INPUT }} >
            <span style={{ color: SR_COLORS.ERROR, paddingRight: 5 }}> * </span>
            {label}
        </span >;
    } else {
        str = <span style={{ color: color || SR_COLORS.LABEL_INPUT }}>{label}</span>;
    }
    return str
}


/***
 * 
 * TODO: Function for checking array before loop 
 * @param _array - The parameter for this method is array.
 * ! Not an array --> return [] 
 * ? The parameter is array and  than more 1 --> return @param  
 * 
 */
export const checkLength = (_array) => {
    if (_array && Array.isArray(_array) && _array.length > 0) {
        return true
    }
    return false
}


export const columnSearchProps = (dataIndex, searchInput, renderText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
        <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
            <Input
                ref={node => {
                    searchInput.current = node;
                }}
                placeholder={`ค้นหา...`}
                value={selectedKeys && selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => {
                    fnSearch();
                    setSelectedKeys(selectedKeys[0])
                }}
                style={{ width: 150, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
            />
            <Button
                type="primary"
                onClick={() => {
                    fnSearch();
                    setSelectedKeys(selectedKeys[0])
                }}
                icon={<Svg.search color={SR_COLORS.BLACK} />}
                size="small"
                style={{ fontSize: 8 }} />
            <Button
                icon={<Svg.cancel color={SR_COLORS.BLACK} />}
                onClick={() => {
                    clearFilters();
                    setSelectedKeys('');
                }}
                size="small"
                style={{ fontSize: 8, margin: '0px 5px' }} />
        </div>
    ),
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    filterIcon: filtered => <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}> <Svg.search color={SR_COLORS.BLACK} /></div>,
    render: (text, record, index) => (typeof renderText === 'function') ? renderText(text, record, index) : text || '-'
})

export const columnSort = (dataIndex) => ({
    sorter: (a, b) => a[dataIndex] - b[dataIndex],
    render: (text) => {
        switch (typeof text) {
            case 'number': return text.toFixed(2) || 0
            case 'string': return text || '-'
            default: return '-'
        }
    }
})

export const columnSortDate = (dataIndex) => ({
    sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
    render: (text) => {
        switch (typeof text) {
            case 'number': return text.toFixed(2) || 0
            case 'string': return text || '-'
            default: return '-'
        }
    }
})



/* -------------------------------------------------------------------------- */
/*                                 RENDER FORM                                */
/* -------------------------------------------------------------------------- */



// export const fnSwitchRender = (element) => {
//     switch (element.type) {
//         case KEY_TYPE.SELECT:
//             return (
//                 <Select
//                     showSearch
//                     value={element.value}
//                     onChange={(e, option) => {
//                         element.onChange(option.props.optionvalue)
//                     }}
//                     optionFilterProp="children"
//                     filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
//                     {
//                         Array.isArray(element.options) && element.options.map((option, optionIndex) => {
//                             return (
//                                 <Select.Option
//                                     key={Number(optionIndex)}
//                                     value={`${option.optionValue}`}
//                                     optionvalue={option.optionValue ? `${option.optionValue}` : null}
//                                 >
//                                     {`${option.optionLabel}`}
//                                 </Select.Option>
//                             )
//                         })
//                     }
//                 </Select>
//             )

//         case KEY_TYPE.CHECKBOX:
//             return (
//                 <Checkbox
//                     checked={element.value}
//                     onChange={(e) => element.onChange(e.target.value)}
//                     {...element}
//                 >
//                     {element['label']}
//                 </Checkbox>
//             )

//         default:
//             return (
//                 <Input
//                     value={element.value}
//                     onChange={(e) => element.onChange(e.target.value)}
//                     {...element}
//                 />
//             )
//     }
// }

/* -------------------------------------------------------------------------- */
