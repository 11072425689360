import React, { Component, Fragment } from "react";
import { path } from "ramda";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row } from "reactstrap";
import { APIProbation } from "../../service";
import { SetDate, SetDateStr } from "../../common/setDate";
import { size } from "../../themes";
import { ButtonTheme, FIELD_BUTTON } from "../../constants/buttons";
import encodeString from '../../extendFunction/encodeString';
import moment from 'moment'
import { KEY_OPTION } from "../../constants/enums/enumCenter";
import { APIContractByDocNo } from "../servicesContract/contractConnect";
import { convertString } from "../../service/functions/convertOptions";
import { Spin } from "antd";
import logoDP  from "../../assets/companyLogo/DP.png";
import logoDT from "../../assets/companyLogo/DT.jpg";
import logoDC from "../../assets/companyLogo/DC.png";
import logoAH from "../../assets/companyLogo/AH.png";
import logoFI from "../../assets/companyLogo/FI.png";
import logoZT from "../../assets/companyLogo/ZT.png";


const renderData = value =>  [
	[
		{ title: "ชื่อ-นามสกุล : ", value: path(["StaffName"], value) },
		{ title: "รหัสพนักงาน : ", value: path(["StaffCode"], value) }
	],
	[
		{ title: "ตำแหน่ง : ", value: path(["PositionNameEN"], value) },
		{ title: "แผนก : ", value: path(["Department_Name"], value) }
	],
	[
		{ title: "วันที่เริ่มงาน : ", value: SetDateStr(path(["StartDateJob"], value)) },
		{ title: "วันที่ครบทดลองงาน : ", value: SetDateStr(path(["expProDate"], value)) }
	],
	//Start CR
	[
		{ title: "", value: '' },
		{ title: "วันที่ผ่านทดลองงาน : ", value: path(["probationPassDate"], value) ? SetDateStr(path(["probationPassDate"], value)) : '-' }
	],
	[
		{ title: "ต่อสัญญาครั้งที่ : ", value: path(["contractRound"], value) },
		{ title: "ระยะเวลาในการต่อสัญญา : ", value: path(["contractPeriodDes"], value) }
	],
	[
		{ title: "วันที่เริ่มรอบสัญญา : ", value: SetDateStr(path(["startProbationDate"], value)) },
		{ title: "วันที่จบรอบสัญญา : ", value: SetDateStr(path(["endContractDate"], value)) }
	],
	//End CR
	[
		{ title: "พี่เลี้ยงที่ได้รับมอบหมาย : ", value: path(["mentorName"], value) },
		{ title: "หัวหน้างาน/ผู้ประเมิน : ", value: path(["BossName"], value) }
	]

];


const renderFactor = () => [
	{
		label: "1. ความรอบรู้ในงาน การตัดสินใจ การก้ไขปัญหา (ระบุรายละเอียด)",
		mainRound: 1,
	},
	{
		label: "2. ความรับผิดชอบ ความขยัน",
		mainRound: 2,
	},
	{
		label: "3. การเรียนรู้ ความสนใจใฝ่รู้",
		mainRound: 3,
	},
	{
		label: "4. ความมีวินัย ตรงต่อเวลา ปฎิบัติตามกฎระเบียบบริษัท",
		mainRound: 4,
	},
	{
		label: "5. ทัศนคติ ต่องาน ผู้บังคับบัญชา บริษัท วัฒนธรรมองค์กร",
		mainRound: 5
	},
	{
		label: "6. บุคลิก การพูด การวางตัว",
		mainRound: 6
	},
	{
		label: "7. มนุษยสัมพันธ์ การประสานงาน การให้ความร่วมมือ",
		mainRound: 7
	},
	{
		label: "8. คามคิดริเริ่ม เสนอแนะ",
		mainRound: 8

	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		value: path(["remark"], initialData),
		mainRound: 0
	},
	{
		label: "สรุปผลการประเมิน",
		name: 'probationStatus',
		value: path(["probationStatus_Des"], initialData),
		mainRound: 0

	},
	{
		label: "คุณภาพการสรรหาพนักงาน",//"สรุปผลการประเมินเกรด",
		name: 'probationGrade',
		value: path(["probationGradeDes"], initialData),

		mainRound: 0
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",//"สรุปผลการประเมินเกรด",
		name: 'contractExtensionDes',
		value: path(["contractExtensionDes"], initialData),

		mainRound: 0
	},

];

const renderFactorFacL1 = () => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		mainRound: 1,
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม ",
		mainRound: 0
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อร่วมงานได้",
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		mainRound: 8,
	},
	{
		label: "คะแนนเฉลี่ย",
		mainRound: 12,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		value: path(["remark"], initialData),
		mainRound: 0
	},
	{
		label: "สรุปผลการประเมิน",
		name: 'probationStatus',
		value: path(["probationStatus_Des"], initialData),
		mainRound: 0

	},
	{
		label: "สรุปผลการประเมินเกรด",
		name: 'probationGrade',
		value: path(["probationGradeDes"], initialData),
		mainRound: 0
	}, {
		label: "ลักษณะการต่อสัญญาจ้าง",//"สรุปผลการประเมินเกรด",
		name: 'contractExtensionDes',
		value: path(["contractExtensionDes"], initialData),

		mainRound: 0
	},
	{
		label: "ผลการฝึกอบรม OJT (ต่ำกว่า 3 คะแนนไม่ผ่าน)", // จากระบบ Training
		name: 'resultTrainingDes',
		value: path(["resultTrainingDes"], initialData) || '-',

		mainRound: 0
	},
	{
		label: "คะแนนผลการประเมินการอบรม OJT", // จากระบบ Training
		name: 'avgTrainingScore',
		value: path(["avgTrainingScore"], initialData) || '-',

		mainRound: 0
	}
];

const renderFactorFacL2 = () => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		mainRound: 1,
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม ",
		mainRound: 0,
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อร่วมงานได้",
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น แบบอย่างให้เพื่อนร่วมงาน ",
		mainRound: 10,
	},
	{
		label: "2.10 มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		mainRound: 12,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		value: path(["remark"], initialData),
		mainRound: 0
	},
	{
		label: "สรุปผลการประเมิน",
		name: 'probationStatus',
		value: path(["probationStatus_Des"], initialData),
		mainRound: 0

	},
	{
		label: "สรุปผลการประเมินเกรด",
		name: 'probationGrade',
		value: path(["probationGradeDes"], initialData),
		mainRound: 0
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",//"สรุปผลการประเมินเกรด",
		name: 'contractExtensionDes',
		value: path(["contractExtensionDes"], initialData),

		mainRound: 0
	}, {
		label: "ผลการฝึกอบรม OJT (ต่ำกว่า 3 คะแนนไม่ผ่าน)", // จากระบบ Training
		name: 'resultTrainingDes',
		value: path(["resultTrainingDes"], initialData) || '-',

		mainRound: 0
	},
	{
		label: "คะแนนผลการประเมินการอบรม OJT", // จากระบบ Training
		name: 'avgTrainingScore',
		value: path(["avgTrainingScore"], initialData) || '-',

		mainRound: 0
	}
];

const renderFactorFacL3 = () => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		mainRound: 1,

	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม ",
		mainRound: 0,
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		mainRound: 2,
	},
	{
		label: "2.2 เป็นแบบอย่าง ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		mainRound: 3,
	},
	{
		label: "2.3 เข้าปฏิบัติงานตรงเวลา รักษาเวลา ตรงต่อเวลานัดหมาย",
		mainRound: 4,
	},
	{
		label: "2.4 เปิดรับการเรียนรู้ ยอมรับการเปลี่ยนแปลง และรับฟังความคิดเห็นของผู้อื่น",
		mainRound: 5,
	},
	{
		label: "2.5 แก้ไขปัญหาในเชิงการป้องกัน เพื่อไม่เกิดซ้ำ",
		mainRound: 6,
	},
	{
		label: "2.6 สื่อสาร ประสานงาน และ เจรจาต่อรองเก่ง",
		mainRound: 7,
	},
	{
		label: "2.7 มีใจบริการ มีมนุษยสัมพันธ์ดี ให้ความร่วมมือกิจกรรมต่างๆ",
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		mainRound: 10,
	},
	{
		label: "2.10 บริการจัดการผู้ใต้บังคับบัญชาอย่างเป็นกลาง และมีเหตุและผล",
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		mainRound: 12,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		value: path(["remark"], initialData),
		mainRound: 0
	},
	{
		label: "สรุปผลการประเมิน",
		name: 'probationStatus',
		value: path(["probationStatus_Des"], initialData),
		mainRound: 0

	},
	{
		label: "สรุปผลการประเมินเกรด",
		name: 'probationGrade',
		value: path(["probationGradeDes"], initialData),
		mainRound: 0
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",//"สรุปผลการประเมินเกรด",
		name: 'contractExtensionDes',
		value: path(["contractExtensionDes"], initialData),

		mainRound: 0
	},
	{
		label: "ผลการฝึกอบรม OJT (ต่ำกว่า 3 คะแนนไม่ผ่าน)", // จากระบบ Training
		name: 'resultTrainingDes',
		value: path(["resultTrainingDes"], initialData) || '-',

		mainRound: 0
	},
	{
		label: "คะแนนผลการประเมินการอบรม OJT", // จากระบบ Training
		name: 'avgTrainingScore',
		value: path(["avgTrainingScore"], initialData) || '-',

		mainRound: 0
	}
];

let initialData = null;

class ViewProbationContract extends Component {
	state = { printForm: true, loading: true }

	componentDidMount() {
		//const { router: { location: { state } } } = this.props;
		// const { location: { state } } = this.props;
		let state = JSON.parse(localStorage.getItem(KEY_OPTION.DATA.PROBATION_CONTRACT,))
		if (state) {
			this.setState({ isPrint: false, _docNo: state.DocNo, _workplace: state.workplace, _company: state.company })
			APIContractByDocNo({ docNo: state.DocNo }, async (response) => {
				//console.log(response)
				if (response) {
					initialData = convertString(response.result)
					// console.log('initialData>',initialData);
					this.setState({ loading: false })
					this.setState({ _company: initialData.Company })
					this.forceUpdate();
				}
			})
		}
	}

	render() {
		//console.log('ViewProbationContract', initialData)
		//  console.log(this.state._docNo)
		let textHeader = ''
		if (this.state._workplace === 'FAC') {
			textHeader = "No. FR-HRD-08 Rev.03"
		}
		// console.log(this.state._workplace, textHeader)
		// console.log('this.state._company>', this.state._company);
		//**Image Logo */
		let pathIMG = ''
		if (this.state._company) {
			switch (this.state._company) {
				case 'DP':
					pathIMG = logoDP;
					break;
				case 'DT':
					pathIMG = logoDT;
					break;
				case 'DC':
					pathIMG = logoDC;
					break;
				case 'AH':
					pathIMG = logoAH;
					break;
				case 'FI':
					pathIMG = logoFI;
					break;
				case 'ZT':
					pathIMG = logoZT;
					break;
			}
		}
		return (
			<Spin spinning={this.state.loading} tip="กำลังโหลด">
				{/* <Row >
                    <Col>{textHeader}</Col>
                </Row> */}
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', fontSize: size.large, fontWeight: 300, marginTop: 2 }}>
					<img src={pathIMG} alt="company-logo" width={80} />
					<div style={style.container}>
						<Row style={{ width: '100%' }} >
							<Col md={2}></Col>
							<Col md={8}>
								<div style={{ fontSize: size.header, fontWeight: 600, padding: '10px 0px 10px', textAlign: 'center' }} > {'แบบประเมินผลพนักงานทดลองงานสัญญาจ้าง '}  </div>
							</Col>
							<Col md={2}><p style={{ fontSize: '12px', textAlign: 'right' }} >{textHeader} </p></Col>
						</Row>
						<div style={{ width: '100%' }}>
							{renderData(initialData).map((row, i) => (
								<Row key={i}>
									{row.map((each, i) => (
										<Col key={i} md={6} style={{ display: "flex", padding: "5px 15px" }} >
											<div style={{ fontWeight: 600 }}> {each.title}                                           </div>
											<div style={{ paddingLeft: 10 }}> {each.value} </div>
										</Col>
									))}
								</Row>
							))}
						</div>
						<div style={{ color: 'red', width: '100%', margin: '15px 0px' }}>
							{'* ระดับการประเมิน ให้ระบุคะแนนในแต่ละปัจจัย แบ่งเป็น 4 ระดับ โดยให้ 4 = ดีมาก , 3 = ดี , 2 = พอใช้ , 1 = ปรับปรุง '}
						</div>
					</div>
					{this.renderFactor()}
				</div>
			</Spin>
		);
	}

	renderFactor() {
		const { printForm } = this.state;
		var template = [];
		let textFac = '';
		let maxHeaderRender
		if (initialData) {
			maxHeaderRender = (initialData.RoundNow > 4 ? 6 : 4)
			//  console.log('ViewProbationContractjhjhjh', initialData.workplace)
			template = renderFactor()
			if (initialData.workplace == 'FAC') {
				let jg = encodeString.decode(initialData.JG)
				textFac = <div style={{ width: '100%', maxWidth: '1024px', paddingTop: ' 10px' }}>
					<Row>
						<Col md={12} style={{ fontWeight: 600 }}>{'หมายเหตุ : '} </Col>

					</Row>
					<Row >
						<Col md={12}>
							<div style={{ padding: 10 }}> {' - พนักงานที่ผ่านทดลองงานต้องได้คะแนนเฉลี่ยทั้งหมดไม่ต่ำกว่า 2.6 คะแนน และคะแนนส่วนที่ 2 มีคะแนนทุกข้อไม่ต่ำกว่า 3 คะแนน ( 3.7-4.0 = เกินความาดหวังมาก, 3.4-3.6 = เกินความคาดหวัง, 2.6-3.3 = ได้ตามความคาดหวัง , 2.0-2.5  = เกือบได้ตามความคาดหวัง, 0.00-1.99 = ต่ำกว่าความคาดหวัง)'}
								<br />
								{' - สรุปผลการทดลองงานส่งให้ hr ภายใน 3 วัน หลังจากประเมินครั้งสุดท้าย'}
							</div>
						</Col>
						{/* <Col md={12}>
                            <div style={{ padding: 10 }}>  </div>
                        </Col> */}
					</Row>
				</div>
				if (jg <= 2) {
					template = renderFactorFacL1()
				} else if (jg > 2 && jg < 6) {
					template = renderFactorFacL2()
				} else {
					template = renderFactorFacL3()
				}
			}
		}
		let headerNumber = [1, 2, 3, 4, 5, 6]
		// var a = renderFactorFacL1()
		return (
			<Fragment>
				<Row style={{ width: '100%', maxWidth: '1024px', fontWeight: 600 }} >
					<Col md={6} style={{
						border: '1px solid rgb(51, 51, 51)',
						padding: 10,
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						minWidth: 300,
						backgroundColor: '#DDD'
					}}>{'ปัจจัยการประเมิน'}</Col>
					<Col md={6} >
						<Row>
							<Col md={12} style={{ ...style.center, ...style.border, padding: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: '#DDD' }} > {'วันที่ประเมิน'}</Col>
						</Row>
						<Row>
							{
								headerNumber.map(num =>
									num <= maxHeaderRender && <Col md={maxHeaderRender > 4 ? 2 : 3} style={{ ...style.center, ...style.border, padding: 5, width: 100, backgroundColor: '#DDD' }}>{`ครั้งที่ ${num}`}</Col>
								)
							}
						</Row>
						<Row style={{ fontWeight: 300 }}>
							{
								headerNumber.map(num =>
									num <= maxHeaderRender && <Col md={maxHeaderRender > 4 ? 2 : 3} style={{ ...style.center, ...style.border, padding: 5, width: 100 }}>{SetDate(path([`RoundDate_${num}`], initialData))}</Col>
								)
							}
						</Row>
					</Col>

				</Row>
				{
					template.map((each, i) => {
						let ListRound = path([`${"factor_"}${each.mainRound}`], initialData) || []
						let avgScore1 = path([`${"average_1"}`], initialData) || ''
						let avgScore2 = path([`${"average_2"}`], initialData) || ''
						let avgScore3 = path([`${"average_3"}`], initialData) || ''
						let avgScore4 = path([`${"average_4"}`], initialData) || ''
						let avgScore5 = path([`${"average_5"}`], initialData) || ''
						let avgScore6 = path([`${"average_6"}`], initialData) || ''
						//console.log('initialData_NUT', ListRound)
						let arrAvgScore = [avgScore1, avgScore2, avgScore3, avgScore4, avgScore5, avgScore6]
						//console.log('initialData_NUT', arrAvgScore)
						switch (each.mainRound) {
							case 0:

								return <Fragment key={i}>
									<Row style={{ width: '100%', maxWidth: '1024px' }}>
										<Col md={6} style={{
											fontWeight: (each.label === "ความคิดเห็นเพิ่มเติม") ? 300 : 600,
											border: '1px solid rgb(51, 51, 51)',
											padding: 5,
											alignItems: 'center',
											justifyContent: 'flex-start',
											display: 'flex',
											minWidth: 300,
											backgroundColor: (each.label !== "ความคิดเห็นเพิ่มเติม") ? '#DDD' : null
										}}>
											{each.label}
										</Col>
										<Col md={6} style={{
											border: '1px solid rgb(51, 51, 51)',
											padding: 5,
											alignItems: 'center',
											justifyContent: 'center',
											display: 'flex',
											minWidth: 300,
											backgroundColor: (each.label !== "ความคิดเห็นเพิ่มเติม") ? '#DDD' : null
										}}>
											{each.value}
										</Col>
									</Row>
								</Fragment>
							case 1:
								return <Fragment key={i}>
									<Row style={{ width: '100%', maxWidth: '1024px' }}>
										<Col md={12} style={{
											// fontWeight: 600,
											border: '1px solid rgb(51, 51, 51)',
											padding: 5,
											alignItems: 'center',
											justifyContent: 'flex-start',
											display: 'flex',
											minWidth: 300
										}}>
											{each.label || ''}
										</Col>
									</Row>
									{ListRound.map((roundData, indexRound) => {
										return (

											<Row key={indexRound} style={{ width: '100%', maxWidth: '1024px' }} >
												<Col md={6} style={{
													// fontWeight: 600,
													border: '1px solid rgb(51, 51, 51)',
													padding: 5,
													alignItems: 'center',
													justifyContent: 'flex-start',
													display: 'flex',
													minWidth: 300,
													paddingLeft: 20
												}}>
													{each.mainRound}{'.'}{indexRound + 1} {'. '}{roundData.name}
												</Col>
												<Col md={6} style={{ ...style.center, textAlign: 'center', display: 'flex', padding: 0 }}>
													{
														roundData.score.map((val, index) => {
															let maxRender = initialData.RoundNow > 4 ? 6 : 4
															return (
																index + 1 <= maxRender && <Col md={maxRender > 4 ? 2 : 3} key={index} style={{
																	alignItems: 'center', justifyContent: 'center',
																	display: 'flex', border: '1px solid #333', width: 100
																}}>
																	{val || ''}
																</Col>
															)
														})
													}
												</Col>
											</Row>
										)
									})}
								</Fragment>

							case 12:
								return ListRound.map((roundData, indexRound) => {
									return (
										<Fragment key={indexRound}>
											<Row style={{ width: '100%', maxWidth: '1024px' }}>
												<Col md={6} style={{
													fontWeight: 600,
													border: '1px solid rgb(51, 51, 51)',
													padding: 5,
													alignItems: 'center',
													justifyContent: 'flex-start',
													display: 'flex',
													minWidth: 300,
													// backgroundColor: (each.label !== "ความคิดเห็นเพิ่มเติม") ? '#DDD' : null
												}}>
													{each.label}
												</Col>

												<Col md={6} style={{ ...style.center, textAlign: 'center', display: 'flex', padding: 0, fontWeight: 600 }}>
													{
														arrAvgScore.map((val, index) => {
															let maxRender = initialData.RoundNow > 4 ? 6 : 4
															return (
																index + 1 <= maxRender && <Col md={maxRender > 4 ? 2 : 3} key={index} style={{
																	alignItems: 'center', justifyContent: 'center',
																	display: 'flex', border: '1px solid #333', width: 100
																}}>
																	{val !== '' ? parseFloat(val).toFixed(2) : ''}
																</Col>
															)
														})

													}
												</Col>
											</Row>

										</Fragment>
									)

								})

							default:
								return ListRound.map((roundData, indexRound) => {
									return (
										<Fragment key={indexRound}>
											<Row style={{ width: '100%', maxWidth: '1024px' }}>
												<Col md={6} style={{
													// fontWeight: 600,
													border: '1px solid rgb(51, 51, 51)',
													padding: 5,
													alignItems: 'center',
													justifyContent: 'flex-start',
													display: 'flex',
													minWidth: 300
												}}>
													{each.label}
												</Col>

												<Col md={6} style={{ ...style.center, textAlign: 'center', display: 'flex', padding: 0, fontWeight: 300 }}>
													{
														roundData.score.map((val, index) => {
															let maxRender = initialData.RoundNow > 4 ? 6 : 4
															return (
																index + 1 <= maxRender && <Col md={maxRender > 4 ? 2 : 3} key={index} style={{
																	alignItems: 'center', justifyContent: 'center',
																	display: 'flex', border: '1px solid #333', width: 100
																}}>
																	{val || ''}
																</Col>
															)
														})
													}
												</Col>
											</Row>

										</Fragment>
									)

								})
						}
					})
				}
				{textFac}
				<div style={{ height: 250, width: ' 100%', paddingTop: 40 }}>

					<Row>
						<Col md={6} style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							lineHeight: '10px',
						}}>
							<div style={{ padding: 10 }}>
								{(initialData && initialData.HR_Received === 1) ? `ผู้ประเมิน  ${initialData.BossName}` : 'ผู้ประเมิน..................................................................'}
								{/* {'ผู้ประเมิน..................................................................'} */}
							</div>
							{(initialData && initialData.HR_Received === 1) ? null : <div style={{ padding: 10 }}> {'(.........................................................................)'}</div>}
							<div style={{ padding: 10 }}>
								{(initialData && initialData.HR_Received === 1) ? `${moment(initialData.probationEndDate).format('DD/MM/YYYY')}` : '...................../...................../.....................'}
								{/* {'...................../...................../...................../'} */}
							</div>
						</Col>
						<Col md={6} style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							lineHeight: '10px',
						}}>
							<div style={{ padding: 10 }}>
								{(initialData && initialData.HR_Received === 1) ? `ผู้จัดการแผนก  ${initialData.approvalFullname}` : 'ผู้จัดการแผนก..................................................................'}

								{/* {'ผู้จัดการแผนก..................................................................'} */}
							</div>
							{(initialData && initialData.HR_Received === 1) ? null : <div style={{ padding: 10 }}> {'(.........................................................................)'}</div>}
							<div style={{ padding: 10 }}>
								{(initialData && initialData.HR_Received === 1) ? `${moment(initialData.probationEndDate).format('DD/MM/YYYY')}` : '...................../...................../.....................'}
								{/* {'...................../...................../.....................'} */}
							</div>
						</Col>
					</Row>
					<Row style={{ paddingTop: 20 }}>
						<Col md={6} style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							lineHeight: '10px',
						}}>
							<div style={{ padding: 10 }}> {'พนักงาน..................................................................'}</div>
							<div style={{ padding: 10 }}> {'(.......................................................................)'}</div>
							<div style={{ padding: 10 }}> {'...................../...................../.....................'}</div>
						</Col>
						<Col md={6} style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							lineHeight: '10px',
						}}>
							{/* <div style={{ padding: 10 }} > {'ฝ่ายบุคคล..................................................................'}</div>
                            <div style={{ padding: 10 }}> {'(.......................................................................)'}</div>
                            <div style={{ padding: 10 }}> {'...................../...................../.....................'}</div>
                        */}
						</Col>
					</Row>
				</div>
				{
					(printForm) &&
					<div style={{ padding: 20 }}>
						{
							<ButtonTheme
								label={"พิมพ์เอกสาร"}
								style={{ textAlign: 'end' }}
								onClick={() => {
									this.setState({ printForm: false }, () => {
										window.print()
										this.setState({ printForm: true })
									})
								}} />
						}
					</div>
				}
			</Fragment >
		)
	}

}

export default ViewProbationContract;
const style = {
	center: {
		width: '100%', textAlign: 'center', margin: 0
	},
	border: {
		border: '1px solid #333'
	},
	container: {
		width: ' 100%',
		maxWidth: 1024,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	}
}


