import { MESSAGE_ERROR } from ".."
import { KEY_OPTION, KEY_TYPE } from "../../../constants/enums/enumCenter"
import { FIELD_EMP, LABEL_EMP } from "./employeeInfo"

export const CONTENT_BLOCK_EMP = (props) => {
    const { options, disabled, required } = props
    /**
    * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
    */
    return [
        [{
            span: 9,
            itemprops: {
                name: FIELD_EMP.STATUS_EMPLOYEE,
                label: LABEL_EMP.STATUS_EMPLOYEE, //สถานะพนักงาน
                rules: [{ required: true, message: MESSAGE_ERROR.SELECT }]
            },
            inputprops: {
                type: KEY_TYPE.SELECT,
                disabled: false,
                placeholder: '',
                options: options[KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE].filter((e) => e.optionId !== 'normal')
            }
        },
        {
            span: props.show.reasonLeaveDes ? 4 : 9,
            itemprops: {
                name: FIELD_EMP.REASON_OUT,
                label: LABEL_EMP.REASON_OUT, //สถานะพนักงาน
                rules: [{ required: required[FIELD_EMP.REASON_OUT], message: MESSAGE_ERROR.SELECT }]
            },
            inputprops: {
                type: KEY_TYPE.SELECT,
                disabled: disabled[FIELD_EMP.REASON_OUT],
                placeholder: '',
                options: options[KEY_OPTION.CENTER.OPTION_REASON_LEAVE_GROUP]
            }
        },
        {
            span: props.show.reasonLeaveDes ? 5 : 0,
            itemprops: {
                name: FIELD_EMP.REASON_OUT_TEXT,
                label: LABEL_EMP.REASON_OUT_TEXT, //สถานะพนักงาน
                rules: [{ required: required[FIELD_EMP.REASON_OUT_TEXT], message: MESSAGE_ERROR.INPUT }]
            },
            inputprops: {
                type: KEY_TYPE.TEXT,
                disabled: disabled[FIELD_EMP.REASON_OUT_TEXT],
                placeholder: '',
            }
        },
        {
            span: 6,
            itemprops: {
                name: FIELD_EMP.END_DATE_JOB,
                // label: LABEL_EMP.END_DATE_JOB, //วันที่สิ้นสุดสัญญา
                label: "วันที่พ้นสภาพการทำงาน", // วันที่พ้นสภาพการทำงาน
                rules: [{
                    required: required[FIELD_EMP.END_DATE_JOB],
                    message: MESSAGE_ERROR.DATE_PICKER
                }]
            },
            inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                placeholder: '',
                //disabled: disabled[FIELD_EMP.END_DATE_JOB], // ปิดช่องพ้นสภาพการทำงาน
                disabled: false,
            }
        }]
    ]
}
