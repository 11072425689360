import React from "react";
import { filterSearchColumn } from "../../functions/UseFunction";
import { Space, Input, Tag, Button } from "antd";
import { useButton } from "./../../conponents/buttons/CustomButtonAntd";
import { convertBaht } from "../../../service/functions/convertOptions";
import { renderStatusColorInstallment } from "../RecheckWorkingSaleEmployee/ColumnRecheckWorkingSaleEmployee";

const renderButton = (
  permissionUser,
  editRow,
  returnStatusRow,
  record,
  viewRow
) => {
  if (permissionUser.length > 0) {
    let index = permissionUser.findIndex(
      (val) => record.departmentCode.split(",")[0] == val.departmentNo
    );
    if (index != -1) {
      if (permissionUser[index].sequence == "1") {
        if (record.statusSave == "0") {
          return (
            <Button
              type="primary"
              onClick={() => editRow(record)}
              style={{ background: "#F28C28	", borderColor: "#F28C28" }}
            >
              แก้ไข
            </Button>
          );
        } else if (record.statusCutOff == "1") {
          return (
            <>
              {useButton({
                call: "normal",
                moreProps: {
                  label: "ดูรายละเอียด",
                  onClick: () => viewRow(record),
                },
              })}
            </>
          );
        }
        return (
          <>
            {useButton({
              call: "normal",
              moreProps: {
                label: "ดูรายละเอียด",
                onClick: () => viewRow(record),
              },
            })}
            {useButton({
              call: "search",
              moreProps: {
                label: "ย้อนสถานะ",
                onClick: () => returnStatusRow(record),
              },
            })}
          </>
        );
      } else {
        if (record.statusSave != "0") {
          if (record.statusSave == "1") {
            return (
              <Button
                type="primary"
                onClick={() => editRow(record)}
                style={{ background: "#F28C28	", borderColor: "#F28C28" }}
              >
                แก้ไข
              </Button>
            );
          } else if (record.statusCutOff == "1") {
            return (
              <>
                {useButton({
                  call: "normal",
                  moreProps: {
                    label: "ดูรายละเอียด",
                    onClick: () => viewRow(record),
                  },
                })}
              </>
            );
          }
          return (
            <>
              {useButton({
                call: "normal",
                moreProps: {
                  label: "ดูรายละเอียด",
                  onClick: () => viewRow(record),
                },
              })}
              {useButton({
                call: "search",
                moreProps: {
                  label: "ย้อนสถานะ",
                  onClick: () => returnStatusRow(record),
                },
              })}
            </>
          );
        }
      }
    }
  }
};

export const getNewValueSum = (bf, value, total) => {
  let totalBF = bf ? parseFloat(bf) : 0;
  let totalValue = value ? parseFloat(value) : 0;
  let grandTotal = total ? parseFloat(total) : 0;
  return totalBF + totalValue != grandTotal  ? (
    <Tag color="green">
      ค่าใหม่: {convertBaht((totalBF + totalValue).toFixed(2))}
    </Tag>
  ) : (
    ""
  );
};

export const getNewValueSumText = (bf, value, total) => {
  let totalBF = bf ? parseFloat(bf) : 0;
  let totalValue = value ? parseFloat(value) : 0;
  let grandTotal = total ? parseFloat(total) : 0;
  // console.log('getNewValueSumText',totalBF + totalValue);
  return totalBF + totalValue != grandTotal 
    ? (totalBF + totalValue).toFixed(2)
    : "";
};

export const getTotalNet = (record) => {
  let totalCom = record.totalCommission
    ? parseFloat(record.totalCommission)
    : 0;
  let totalInc = record.totalIncentive ? parseFloat(record.totalIncentive) : 0;

  return convertBaht((totalCom + totalInc).toFixed(2));
};

export const getNewTotalNet = (record) => {
  const { incentiveNewTotal, commissionNewTotal, oldTotal } = record;
  let totalCom = commissionNewTotal ? parseFloat(commissionNewTotal) : 0;
  let totalInc = incentiveNewTotal ? parseFloat(incentiveNewTotal) : 0;
  let totalOld = oldTotal ? parseFloat(oldTotal) : 0;
  // console.log('getNewTotalNet', totalCom + totalInc);
  return totalCom + totalInc !== totalOld  ? (
    <Tag color="green">
      ค่าใหม่: {convertBaht((totalCom + totalInc).toFixed(2))}
    </Tag>
  ) : (
    ""
  );
};

export const ColumnRecheckCommissionIncentiveDashboardFSP = ({
  editRow,
  viewRow,
  returnStatusRow,
  permissionUser,
}) => [
  {
    title: "ลำดับ",
    dataIndex: "rowNo",
    key: "rowNo",
    align: "left",
    fixed: "left",
    width: 10,
    ...filterSearchColumn("rowNo", "ลำดับ"),
  },
  {
    title: "เลขที่เอกสาร",
    dataIndex: "docNo",
    key: "docNo",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("docNo", "เลขที่เอกสาร"),
  },
  {
    title: "ช่วงวันที่งวดเงินเดือน",
    dataIndex: "installmentDatePeriod",
    key: "installmentDatePeriod",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("installmentDatePeriod", "ช่วงวันที่งวดเงินเดือน"),
    render: (text, record) => {
      return `${record.installmentStartDate} ถึง ${record.installmentEndDate}`;
    },
  },
  {
    title: "วันที่สร้างเอกสาร",
    dataIndex: "createDate",
    key: "createDate",
    align: "left",
    fixed: "left",
    width: 100,
  },
  // {
  // 	title: "แผนก",
  // 	dataIndex: "departmentName",
  // 	key: "departmentName",
  // 	align: "left",
  // 	fixed: "left",
  // 	width: 100,
  // 	...filterSearchColumn("departmentName", "แผนก"),
  // },
  {
		title: "สถานะเอกสาร",
		dataIndex: "stausSaveDesc",
		key: "stausSaveDesc ",
		align: "center",
		width: 50,
		render: (text) => text === 'แบบร่าง' ? <Tag color="blue">{text}</Tag> : <Tag color="green">{text}</Tag>
	},
  {
    title: "",
    dataIndex: "action",
    key: "action",
    align: "right",
    width: 50,
    render: (text, record) => {
      return (
        <div style={{ textAlignLast: "center" }}>
          <Space>
            {renderButton(
              permissionUser,
              editRow,
              returnStatusRow,
              record,
              viewRow
            )}
          </Space>
        </div>
      );
    },
  },
];

export const ColumnRecheckCommissionIncentiveActionFSP = ({
  isView,
  dataSource,
  onChangeRow,
  dataSourceOrigin,
}) => [
  {
    title: "ลำดับ",
    dataIndex: "rowNo",
    key: "rowNo",
    align: "left",
    fixed: "left",
    width: 100,
  },
  {
    title: "รหัสเซลล์",
    dataIndex: "saleCode",
    key: "saleCode",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("saleCode", "รหัสเซลล์"),
    render: (text, record) => {
      return <div>{text || <Tag color="red">ไม่มีรหัสเซลล์</Tag>}</div>;
    },
  },
  {
    title: "รหัสพนักงาน",
    dataIndex: "employeeId",
    key: "employeeId",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("employeeId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "fullname",
    key: "fullname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "left",
    width: 150,
    ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "วันที่พ้นสภาพ",
    dataIndex: "EndDateJob",
    key: "EndDateJob",
    align: "left",
    width: 150,
    ...filterSearchColumn("EndDateJob", "วันที่พ้นสภาพ"),
  },

  {
    title: "แผนก",
    dataIndex: "departmentName",
    key: "departmentName",
    align: "left",
    width: 100,
    ...filterSearchColumn("departmentName", "แผนก"),
  },
  {
    title: "สถานะพนักงานประจำงวด",
    dataIndex: "statusInstallmentName",
    key: "statusInstallmentName",
    align: "right",
    width: 150,
    filters: [
      {
        text: "ปัจจุบัน",
        value: "ปัจจุบัน",
      },
      {
        text: "เข้าใหม่",
        value: "เข้าใหม่",
      },
      {
        text: "ลาออก",
        value: "ลาออก",
      },
    ],
    onFilter: (value: string, record) =>
      record.statusInstallmentName.includes(value),
    render: (text, record) => {
      return (
        <Tag
          color={renderStatusColorInstallment(record.statusWorkerInstallment)}
        >
          {text}
        </Tag>
      );
    },
  },
  {
    title: "Target รวมยอดขาย (บาท)",
    dataIndex: "saleTarget",
    key: "saleTarget",
    align: "right",
    width: 150,
    ...filterSearchColumn("saleTarget", "Target ยอดขาย (บาท)"),
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "Target Acc (บาท)",
    dataIndex: "accTarget",
    key: "accTarget",
    align: "right",
    width: 150,
    ...filterSearchColumn("accTarget", "Target Acc (บาท)"),
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "Target Temp (บาท)",
    dataIndex: "tempTarget",
    key: "tempTarget",
    align: "right",
    width: 150,
    ...filterSearchColumn("tempTarget", "Target Temp (บาท)"),
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "Actual ยอดขาย (บาท)",
    dataIndex: "saleActual",
    key: "saleActual",
    align: "right",
    width: 150,
    ...filterSearchColumn("saleActual", "Actual ยอดขาย (บาท)"),
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "%ยอดขายเดือน",
    dataIndex: "persentSalePerMonth",
    key: "persentSalePerMonth",
    align: "right",
    width: 150,
    ...filterSearchColumn("persentSalePerMonth", "%ยอดขายเดือน"),
  },
  {
    title: "Incentive Month",
    dataIndex: "incentiveMonth",
    key: "incentiveMonth",
    align: "right",
    width: 150,
    ...filterSearchColumn("incentiveMonth", "Incentive Month"),
  },
  {
    title: "Commission Month",
    dataIndex: "commissionMonth",
    key: "commissionMonth",
    align: "right",
    width: 150,
  },
  {
    title: "% ACC",
    dataIndex: "percentAccDecrypt",
    key: "percentAccDecrypt",
    align: "right",
    width: 150,
  },
  {
    title: "% Temp",
    dataIndex: "percentTempDecrypt",
    key: "percentTempDecrypt",
    align: "right",
    width: 150,
  },

  {
    title: "การันตี Incentive",
    dataIndex: "incentiveGuarantee",
    key: "incentiveGuarantee",
    align: "right",
    width: 150,
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "เหตุผลการันตี",
    dataIndex: "remarkGuarantee",
    key: "remarkGuarantee",
    align: "center",
    width: 200,
    render: (text, record) => {
      return <div>{text && text != "undefined" ? text : ""}</div>;
    },
  },
  {
    title: "Total Commision ก่อนปรับปรุง",
    dataIndex: "totalCommissionBeforeUpdate",
    key: "totalCommissionBeforeUpdate",
    align: "right",
    width: 150,
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "Total Incentive ก่อนปรับปรุง",
    dataIndex: "totalIncentiveBeforeUpdate",
    key: "totalIncentiveBeforeUpdate",
    align: "right",
    width: 150,
    render: (text, record) => {
      return <div>{text && convertBaht(text.toFixed(2))}</div>;
    },
  },
  {
    title: "เพิ่ม/ลด Commission",
    key: "commission",
    align: "left",
    width: 150,
    render: (text, record) => {
      return (
        <Input
          disabled={isView}
          type="number"
          name={`commission${record.rowNo}`}
          value={
            dataSource[record.rowNo - 1]
              ? dataSource[record.rowNo - 1].commission
              : ""
          }
          onChange={(e) => onChangeRow(e, record.rowNo)}
        />
      );
    },
  },
  {
    title: "เพิ่ม/ลด Incentive",
    key: "incentive",
    align: "left",
    width: 150,
    render: (text, record) => {
      return (
        dataSource.length > 0 && (
          <Input
            disabled={isView}
            type="number"
            name={`incentive${record.rowNo}`}
            value={
              dataSource[record.rowNo - 1]
                ? dataSource[record.rowNo - 1].incentive
                : ""
            }
            onChange={(e) => onChangeRow(e, record.rowNo)}
          />
        )
      );
    },
  },
  {
    title: "หมายเหตุอนุมัติพิเศษ (ต้องระบุ)",
    key: "remarkApprovalSpecial",
    align: "left",
    width: 300,
    filters: [
      {
        text: "เฉพาะที่มีหมายเหตุ",
        value: "haveRemark",
      },
    ],
    onFilter: (value: string, record) => {
      if (value == "haveRemark") {
        return record.remarkApprovalSpecial && true;
      }
      return record.remarkApprovalSpecial;
    },
    render: (text, record) => {
      const { TextArea } = Input;
      return (
        dataSource.length > 0 && (
          <TextArea
            rows={1}
            disabled={isView}
            name={`remarkApprovalSpecial${record.rowNo}`}
            value={
              dataSource[record.rowNo - 1]
                ? dataSource[record.rowNo - 1].remarkApprovalSpecial
                : ""
            }
            onChange={(e) => onChangeRow(e, record.rowNo)}
          />
        )
      );
    },
  },
  {
    title: "Total Commision",
    dataIndex: "totalCommission",
    key: "totalCommission",
    align: "right",
    width: 150,
    render: (text, record) => {
      return (
        <div>
          {dataSource.length > 0 &&
            dataSource[record.rowNo - 1] &&
            getNewValueSum(
              dataSource[record.rowNo - 1].totalCommissionBeforeUpdate,
              dataSource[record.rowNo - 1].commission,
              dataSource[record.rowNo - 1].totalCommission
            )}
          {text && convertBaht(text.toFixed(2))}
        </div>
      );
    },
  },
  {
    title: "Total Incentive",
    dataIndex: "totalIncentive",
    key: "totalIncentive",
    align: "right",
    width: 150,
    render: (text, record) => {
      return (
        <div>
          {dataSource.length > 0 &&
            dataSource[record.rowNo - 1] &&
            getNewValueSum(
              dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate,
              dataSource[record.rowNo - 1].incentive,
              dataSource[record.rowNo - 1].totalIncentive
            )}
          {text && convertBaht(text.toFixed(2))}
        </div>
      );
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    width: 150,
    render: (text, record) => {
      return (
        <div>
          {dataSource.length > 0 &&
            dataSource[record.rowNo - 1] &&
            (
              getNewValueSumText(
                dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate,
                dataSource[record.rowNo - 1].incentive,
                dataSource[record.rowNo - 1].totalIncentive
              ) ||
              getNewValueSumText(
                dataSource[record.rowNo - 1].totalCommissionBeforeUpdate,
                dataSource[record.rowNo - 1].commission,
                dataSource[record.rowNo - 1].totalCommission
              )
            ) ? (
              getNewTotalNet({
                incentiveNewTotal:
                  getNewValueSumText(
                    dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate,
                    dataSource[record.rowNo - 1].incentive,
                    dataSource[record.rowNo - 1].totalIncentive
                  ) || record.totalIncentive,
                commissionNewTotal:
                  getNewValueSumText(
                    dataSource[record.rowNo - 1].totalCommissionBeforeUpdate,
                    dataSource[record.rowNo - 1].commission,
                    dataSource[record.rowNo - 1].totalCommission
                  ) || record.totalCommission,
                oldTotal: getTotalNet(dataSource[record.rowNo - 1]),
              })
            ) : ''}

          {dataSource.length > 0 &&
            dataSource[record.rowNo - 1] &&
            getTotalNet(dataSource[record.rowNo - 1])}
          {/* {text && convertBaht(text.toFixed(2))} */}
        </div>
      );
    },
  },
];
