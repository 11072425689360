import { Button } from 'antd'
import React from 'react'
import { TEXT_BUTTON } from '.'
import { MdCreate, MdEdit, MdSearch } from "react-icons/md";

import './buttons.css'
import { colors } from '../../themes';

export const ButtonInsert = ({ style, disabled, onClick }) => {
    return (
        <div style={style}>
            <Button
                type="primary"
                disabled={disabled}
                icon={<MdCreate style={{ marginRight: 10 }} />}
                style={{
                    minWidth: 100,
                    border: 0,
                    color: colors.white,
                    backgroundColor: disabled ? colors.buttonDisabled : colors.buttonCreate
                }}
                onClick={onClick}
            > {TEXT_BUTTON.INSERT} </Button>
        </div>
    )
}