import React from 'react'
import { Col, Row } from 'antd';
import { PATHNAME } from '../constants/enums/pathname';
import { FiXCircle, FiEdit, FiSend, FiChevronRight, FiFileText } from 'react-icons/fi';

const processDetail = [
    {
        icon: <FiXCircle className='margin-icon' color="#FF5733" size={20} />,
        label: 'รอสร้างแบบประเมิน',
        right: <FiChevronRight className='margin-icon' size={20} />
    }, {
        icon: <FiEdit className='margin-icon' color="#95A5A6" size={20} />,
        label: 'อยู่ระหว่างการประเมิน (ระหว่างทดลองงาน)',
        right: <FiChevronRight className='margin-icon' size={20} />
    }, {
        icon: <FiFileText className='margin-icon' color="#C39BD3" size={20} />,
        label: 'ส่งเอกสารการประเมินให้ HR',
        right: <FiChevronRight className='margin-icon' size={20} />
    }, {
        icon: <FiSend className='margin-icon' color="#28B463" size={20} />,
        label: 'จบการประเมิน ( HR รับเอกสาร )',
        right: null
    }
]

const WorkingProcess = ({ pathname }) => {
    switch (pathname) {
        case PATHNAME.DASHBOARD_PROBATION:
        case PATHNAME.DASHBOARD_PROBATION_CONTRACT:
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT:
            return (
                <div >
                    <Row>
                        <Col style={{ fontSize: 14, fontWeight: 600, paddingBottom: 10 }}>
                            {'กระบวนในการประเมินพนักงานทดลองงาน'}
                        </Col>
                    </Row>
                    <Row>
                        {processDetail.map((process, i) => {
                            return (
                                <Col key={i} style={{ display: 'flex', alignItems: 'center', paddingBottom: 5 }}>
                                    { process.icon}
                                    { process.label}
                                    { process.right}
                                </Col>
                            )
                        })}
                    </Row>
                    <Row>
                        <Col style={{ paddingLeft: 10, color: '#949494', fontSize: 12 }}>
                            {/* {'* หมายเหตุ -  สามารถคลิกที่รหัสพนักงานเพื่อเรียกดูรูปพนักงานได้'} */}
                        </Col>
                    </Row>
                </div>
            )
        default:
            break;
    }
}

export default WorkingProcess