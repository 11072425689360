import React, { useState } from 'react'
import { browserHistory } from "react-router";

import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { TABLE_EXPORT_OT } from '../commonsOT/structuresOT/tableOT'
import { TEXT_BTN, FIELD_BTN } from '../commonsOT/structuresOT/buttonOT'
import { PATHNAME } from '../../constants/enums/pathname';
import { ExportExcel } from '../../moduleKPIs/common/buttons';
import { useContext } from 'react';
import { ReportOTContext } from '../servicesOT/reportOT/reportOTProvider';
import { Modal } from 'antd';



const ExportOT = () => {
    const [openModal, showModal] = useState(false)
    const { reportOTState } = useContext(ReportOTContext);


    const actionWithinTable = ({ fieldBtn, pathname, source }) => {
        switch (fieldBtn) {
            case FIELD_BTN.REMOVE_REQUEST_OT:
                Modal.confirm({
                    title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?'
                })
                //console.log('REMOVE_REQUEST_OT', source)
                break;
            case FIELD_BTN.INSERT_SIGNATURE:
                //console.log('INSERT_SIGNATURE')
                showModal(false)
                break;
            case FIELD_BTN.UPDATE_REQUEST_OT:
            case FIELD_BTN.VIEW_DASHBOARD_OT_APD:
            case FIELD_BTN.VIEW_DASHBOARD_OT:
            case FIELD_BTN.LINK_REPORT:
                //console.log('VIEW_DASHBOARD_OT', source)
                //console.log('UPDATE_REQUEST_OT', source)
                //console.log('LINK_REPORT', source)
                window.localStorage.setItem('refDocNo', JSON.stringify(source.docNo))
                // JSON.parse(window.localStorage.getItem("descriptionOT"))
                browserHistory.push({ pathname: pathname })
                break;

            default: break;
        }
    }

    const onChange = ({ fieldname, value, index }) => {
        //console.log({ fieldname, value, index })
    }

    return (
        <div style={{ padding: 10 }}>
            <LayoutTable
                scroll={{ x: 600 }}
                bordered
                pagination={{
                    pageSize:100
                }}
                rowKey="userId"
                dataSource={reportOTState.exportOTFac}
                columns={TABLE_EXPORT_OT({
                    onChange: onChange,
                    actionButton: actionWithinTable,
                    optionModal: {
                        openModal,
                        showModal: () => showModal(true),
                        closeModal: () => showModal(false),
                    }
                })} />
        </div>
    )
}

export default ExportOT
