import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, FormGroup, Label, Card, CardHeader, CardBody, Container } from 'reactstrap';

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
// const api = connectAPI.API_URL


let fileReader;
class CovertFileTax extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: "",
            selectedFile: null
        };
    }

    componentDidMount() {

    }

    onChangeHandler = event => {

        let file = event.target.files[0]
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsText(file);

    }

    handleFileRead = (e) => {
        const content = fileReader.result;
        let arrContent = content.split("\n")
        let newContent = ''

        for (let i = 0; i < arrContent.length; i++) {
            let dataSplit = arrContent[i].split("|");
            //New Pattern 
            //00|เลขผู้เสียภาษีบริษัท|0000000000|0000|เลขบัตรประชาชน|
            //0000000000|คำนำหน้าชื่อ|ชื่อ|นามสกุล|บ้านเลขที่ ซอย|
            //เขต จังหวัด |เลขไปษณีย์|เดือน|ปี|1|
            //วันที่จ่ายเงินเดือน|0|รายได้รวมที่เสียภาษี|ยอดเสียภาษี|1
            if (arrContent[i]) {
                let newPattern = `${dataSplit[0] || ''}|${dataSplit[3] || ''}|${dataSplit[6] || ''}|${dataSplit[4] || ''}|${dataSplit[5] || ''}|` +
                    `${dataSplit[6] || ''}|${dataSplit[7] || ''}|${dataSplit[8] || ''}|${dataSplit[9] || ''}|${dataSplit[10] || ''}|` +
                    `${dataSplit[11] || ''}|${dataSplit[12] || ''}|${dataSplit[13] || ''}|${dataSplit[14] || ''}|${dataSplit[15] || ''}|` +
                    `${dataSplit[16] || ''}|${dataSplit[17] || ''}|${dataSplit[18] || ''}|${dataSplit[19] || ''}|${dataSplit[20] || ''}`
                if (i === arrContent.length - 1) {
                    newContent = newContent + newPattern
                } else {
                    newContent = newContent + newPattern + '\n'
                }
            }


        }
        this.setState({
            text: newContent
        });
    };

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        this.setState({ copySuccess: 'Copied!' });
    };


    render() {

        return (

            <Container>
                <Card>
                    <CardHeader> {'หน้าแปลงไฟล์ภาษี Tiger Soft ==> HMC'}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={12} >
                                <input type="file" name="file" onChange={this.onChangeHandler} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <hr />
                <div>
                    {
                        /* Logical shortcut for only displaying the 
                           button if the copy command exists */
                        document.queryCommandSupported('copy') &&
                        <div className="btn-right">
                            {this.state.copySuccess}
                            <button className="btn btn-export" onClick={this.copyToClipboard}>Copy</button>

                        </div>

                    }
                    <form>
                        <textarea class="form-control" rows="20"
                            ref={(textarea) => this.textArea = textarea}
                            value={this.state.text}
                        />
                    </form>
                </div>

            </Container>

        );

    }
}
export default CovertFileTax;
