import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useNotification } from "../notification/NotificationWithIcon";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { subStringDate, sumColumnWidth, toUndefined, useReplace } from "../../functions/UseFunction";
import { POST, POST_SELECT_DATA_CRIME_HEADER } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentSearchReportCrime } from "../../constants/ManageCrime/Components/ComponentSearchReportCrime";
import { ColumnSearchReportCrime } from "../../constants/ManageCrime/Columns/ColumnSearchReportCrime";

export const FormSearchReportCrime = () => {
  const [form] = Form.useForm();
  const [changeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
  };
  // [START] Handle Form Antd
  const onFinish = (values) => {
    if (!changeValue) return null;
    const { company, location, startDate, endDate } = values
    let objectBody = {
      company: company || "",
      location: location || "",
      startDate: startDate ? startDate.format("YYYY") : "",
      endDate: endDate ? endDate.format("YYYY") : "",
    }
    selectDataCrimeHeader(objectBody);
  };
  const onReset = () => {
    form.resetFields();
    setChangeValue({});
    setDataTable([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const selectDataCrimeHeader = async (objectValue) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const response = await POST(POST_SELECT_DATA_CRIME_HEADER, objectValue);
      const { result } = response;
      let mapData = [];
      if (result.length !== 0) {
        mapData = result.map((row, index) => ({
          key: `${index}`,
          ...row,
          filePath: useReplace(row.filePath, "/public", USE_CONNECT_MODE_AUTO),
          sendDate: subStringDate(row.sendDate),
          receivedDate: subStringDate(row.receivedDate),
          createDate: subStringDate(row.createDate),
          prepareDate: subStringDate(row.prepareDate),
        }));
      } else {
        useNotification({ type: "info", desc: "ไม่พบข้อมูล" })
      }
      setDataTable(mapData);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Select Data Crime Header: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    if (name === "startDate") setUndefined = toUndefined(["endDate"])
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} >
        <Card
          title="ค้นหาชุดเอกสารเพื่อส่งเอกสารเพิ่มเติม"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentSearchReportCrime({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
        {dataTable.length !== 0 && (
          <CustomTableAtnd
            HeadTable={ColumnSearchReportCrime}
            DataTable={dataTable}
            moreProps={{
              scroll: {
                x: sumColumnWidth(ColumnSearchReportCrime) || 2000,
                y: 500,
              },
            }}
          />
        )}
      </Form>
    </Spin>
  );
};

export default FormSearchReportCrime;
