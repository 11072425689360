import React from "react";
import { renderSearchColumn } from "./constants/renderSearchColumn";
import { dataCell, dataCellNumber } from "./constants/cellStyle";
import {
  compareSortText,
  compareSortNumber,
  compareSortDate,
} from "../../../constants/functions/compare";
import { Progress } from "antd";
const { createRef } = React;
const searchInput = createRef();

export const tableColumn = ({ type, title, key, width, option = {} }) => {
  switch (type) {
    case "TEXT":
      return textColumn(
        compareSortText,
        title,
        key,
        width,
        option.fixed || "",
        "left"
      );
    case "NUMBER":
      return textColumn(
        compareSortNumber,
        title,
        key,
        width,
        option.fixed || "",
        "right"
      );
    // case "MONEY": //TODO
    //   return moneyColumn(
    //     compareSortNumber,
    //     title,
    //     key,
    //     width,
    //     option.fixed || "",
    //     option.dataCellStyle || dataCellNumber
    //   );
    case "DATE":
      return textColumn(
        compareSortDate,
        title,
        key,
        width,
        option.fixed || "",
        "center"
      );
    // case "TAG":
    //   return tagColumn(
    //     compareSortText,
    //     title,
    //     key,
    //     width,
    //     option.fixed || "",
    //     option.dataCellStyle || dataCellDate
    //   );
    case "PROGRESS":
      return progressColumn(
        compareSortNumber,
        title,
        key,
        width,
        option.fixed || "",
        option.dataCellStyle || dataCellNumber,
        option.option
      );
    // case "COLOR":
    //   return colorColumn(
    //     compareSortText,
    //     title,
    //     key,
    //     width,
    //     option.fixed || "",
    //     option.dataCellStyle || dataCell
    //   );
    case "CUSTOM":
      return customRenderColumn(title, key, width || 130, option);
    case "CUSTOM_FLAT":
      return customRenderColumnFlat(title, key, width || 130, option);

    default:
      console.error(`${type} is not a tableColumn Type`);
      return customRenderColumn(title, key, width || 130, option);
  }
};

const textColumn = (
  sorterFunction,
  title,
  key,
  width,
  fixed,
  align = "left"
) => {
  return {
    title,
    titleFlat: title,
    width: width,
    dataIndex: key,
    key: key,
    align,
    fixed: fixed,
    displayName: key,
    ...renderSearchColumn(key, searchInput),
    sorter: (a, b) => sorterFunction(a, b, key),
    isAction: false,
    skipSum: true,
  };
};

// const moneyColumn = (//TODO
//   sorterFunction,
//   title,
//   key,
//   width,
//   fixed,
//   dataCellStyle
// ) => {
//   return {
//     title,
//     titleFlat: title,
//     width: width,
//     dataIndex: key,
//     key: key,
//     align: "left",
//     fixed: fixed,
//     displayName: key,
//     ...renderSearchColumn(key, searchInput),
//     render: (text) => (
//       <div style={dataCellStyle}>
//         {numberWithCommas(text ? parseFloat(text).toFixed(2) : "0.00")}
//       </div>
//     ),
//     sorter: (a, b) => sorterFunction(a, b, key),
//     isAction: false,
//   };
// };

// const tagColumn = (
//   sorterFunction,
//   title,
//   key,
//   width,
//   fixed,
//   dataCellStyle = dataCell
// ) => {
//   return {
//     title,
//     titleFlat: title,
//     width: width,
//     dataIndex: key[0],
//     key: key[0],
//     align: "center",
//     fixed: fixed,
//     displayName: key[0],
//     ...renderSearchColumn(key[0], searchInput),
//     sorter: (a, b) => sorterFunction(a, b, key[0]),
//     isAction: false,
//     render: (text, record) => (
//       <div style={dataCellStyle}>
//         <Tag
//           color={color[record[key[1]]]}
//           icon={Icon[record[key[1]]]}
//           style={{
//             marginRight: 3,
//           }}
//         >
//           {text}
//         </Tag>
//       </div>
//     ),
//   };
// };

const progressColumn = (
  sorterFunction,
  title,
  key,
  width,
  fixed,
  dataCellStyle = dataCell,
  option = {}
) => {
  return {
    title,
    titleFlat: title,
    width: width,
    dataIndex: key,
    key: key,
    align: "center",
    fixed: fixed,
    displayName: key,
    ...renderSearchColumn(key, searchInput),
    sorter: (a, b) => sorterFunction(a, b, key),
    isAction: false,
    render: (text, record) => (
      <div style={dataCellStyle}>
        <Progress
          strokeColor={{
            "0%": option.strokeColorBegin || "#108ee9",
            "100%": option.strokeColorEnd || "#87d068",
          }}
          status={
            record[option.canceledConditionRecord] ==
            option.canceledConditionText
              ? "exception"
              : ""
          }
          percent={text}
        />
      </div>
    ),
  };
};

// const colorColumn = (sorterFunction, title, key, width, fixed) => {
//   return {
//     title,
//     titleFlat: title,
//     width,
//     dataIndex: key,
//     key,
//     align: "center",
//     fixed,
//     displayName: key,
//     ...renderSearchColumn(key, searchInput),
//     sorter: (a, b) => sorterFunction(a, b, key),
//     render(text) {
//       return {
//         props: {
//           style: { color: color[text] },
//         },
//         children: <div>{text}</div>,
//       };
//     },
//     isAction: false,
//   };
// };

const customRenderColumn = (
  title,
  key,
  width,
  option = { render: (text) => <div>{text}</div>, fixed: "" }
) => {
  return {
    title,
    titleFlat: title,
    width: width,
    dataIndex: key,
    key: key,
    align: "center",
    fixed: option.fixed,
    displayName: key,
    render: (text, record, index) => option.render(text, record, index, key),
    ...renderSearchColumn(key, searchInput, option.disableSearch),
    sorter: option.sorterFunction
      ? (a, b) => option.sorterFunction(a, b, key)
      : null,
    isAction: false,
  };
};

const customRenderColumnFlat = (
  title,
  key,
  width,
  option = { render: (text) => <div>{text}</div>, fixed: "" }
) => {
  return {
    title,
    titleFlat: title,
    width: width,
    dataIndex: key,
    key: key,
    align: "center",
    fixed: option.fixed,
    displayName: key,
    render: (text, record, index) => option.render(text, record, index, key),
    sorter: option.sorterFunction
      ? (a, b) => option.sorterFunction(a, b, key)
      : null,
    isAction: false,
  };
};

export default tableColumn;
