import { equals, isNil } from "ramda";
import { CONFIG } from "../../components/connectAPI";

export function SetStringToObject(_object) {
	let result = null
	if (typeof _object === "string") {
		result = JSON.parse(_object)
	}
	//console.log('SetStringToObject :', result)
	return result;
}


export function allYear() {
	let lastYear = []
	let thisYear = []
	let num = new Date().getMonth() + 1
	for (let i = 1; i <= 12; i++) {
		// lastYear.push({
		//     year: `${new Date().getFullYear() - 1}`,
		//     month: `${renderMonth(i).num}`
		// })
		lastYear.push(`${new Date().getFullYear() - 1}/${renderMonth(i).num}`)
	}
	for (let i = 1; i <= num; i++) {
		// lastYear.push({
		//     year: `${new Date().getFullYear()}`,
		//     month: `${renderMonth(i).num}`
		// })
		thisYear.push(`${new Date().getFullYear() - 0}/${renderMonth(i).num}`)
	}

	return lastYear.concat(thisYear)
}

export function compareYearMonth(_yearList) {

	let yearList = _yearList.sort((min, max) => min - max);
	let monthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
	let indexMonthNow = 12 - (new Date().getMonth() + 1);
	let indexYearMonth = (equals(`${yearList[0]}`, `${compareYear(0, 'start')}`)) ? (yearList.length * monthList.length) - indexMonthNow : yearList.length * monthList.length;
	let yearIndexList = [];

	for (let i = 1; i <= indexYearMonth; i++) {

		if (i <= 12) {
			yearIndexList.push(`${yearList[0]}/${monthList[i - 1]}`)
		} else if (i <= 24) {
			yearIndexList.push(`${yearList[1]}/${monthList[i - 13]}`)
		} else if (i <= 36) {
			yearIndexList.push(`${yearList[2]}/${monthList[i - 25]}`)
		} else if (i <= 48) {
			yearIndexList.push(`${yearList[3]}/${monthList[i - 37]}`)
		}
	}

	return yearIndexList
}

export function compareYear(_num = 1, _type) {
	let startYear = new Date().getFullYear() - _num;
	let endYear = new Date().getFullYear();
	switch (_type) {
		case 'start':
			return `${startYear}`
		case 'end':
			return `${endYear}`
		default:
			return `${startYear} vs ${endYear}`
	}
}

export function renderMonth(_month) {
	// console.log('renderMonth', _month)
	switch (_month) {
		case 1: return { sortTH: 'ม.ค.', sortEN: 'Jan', th: "มกราคม", en: "January", num: 1 };
		case 2: return { sortTH: 'ก.พ.', sortEN: 'Fab', th: "กุมภาพันธ์", en: "February", num: 2 };
		case 3: return { sortTH: 'มี.ค.', sortEN: 'Mar', th: "มีนาคม", en: "March", num: 3 };
		case 4: return { sortTH: 'เม.ย.', sortEN: 'Apl', th: "เมษายน", en: "April", num: 4 };
		case 5: return { sortTH: 'พ.ค.', sortEN: 'May', th: "พฤษภาคม", en: "May", num: 5 };
		case 6: return { sortTH: 'มิ.ย.', sortEN: 'Jun', th: "มิถุนายน", en: "June", num: 6 };
		case 7: return { sortTH: 'ก.ค.', sortEN: 'Jul', th: "กรกฎาคม", en: "July", num: 7 };
		case 8: return { sortTH: 'ส.ค.', sortEN: 'Aug', th: "สิงหาคม", en: "August", num: 8 };
		case 9: return { sortTH: 'ก.ย', sortEN: 'Sep', th: "กันยายน", en: "September", num: 9 };
		case 10: return { sortTH: 'ต.ค.', sortEN: 'Oct', th: "ตุลาคม", en: "October", num: 10 };
		case 11: return { sortTH: 'พ.ย.', sortEN: 'Nov', th: "พฤศจิกายน", en: "November", num: 11 };
		case 12: return { sortTH: 'ธ.ค.', sortEN: 'Dec', th: "ธันวาคม", en: "December", num: 12 };
		default: return null;
	}
}

export function searchMonth(year) {
	let _year = (year) ? year : new Date().getFullYear()
	return [
		{ year: _year, sortTH: 'ม.ค.', sortEN: 'Jan', th: "มกราคม", en: "January", month: 1 },
		{ year: _year, sortTH: 'ก.พ.', sortEN: 'Feb', th: "กุมภาพันธ์", en: "February", month: 2 },
		{ year: _year, sortTH: 'มี.ค.', sortEN: 'Mar', th: "มีนาคม", en: "March", month: 3 },
		{ year: _year, sortTH: 'เม.ย.', sortEN: 'Apl', th: "เมษายน", en: "April", month: 4 },
		{ year: _year, sortTH: 'พ.ค.', sortEN: 'May', th: "พฤษภาคม", en: "May", month: 5 },
		{ year: _year, sortTH: 'มิ.ย.', sortEN: 'Jun', th: "มิถุนายน", en: "June", month: 6 },
		{ year: _year, sortTH: 'ก.ค.', sortEN: 'Jul', th: "กรกฎาคม", en: "July", month: 7 },
		{ year: _year, sortTH: 'ส.ค.', sortEN: 'Aug', th: "สิงหาคม", en: "August", month: 8 },
		{ year: _year, sortTH: 'ก.ย', sortEN: 'Sep', th: "กันยายน", en: "September", month: 9 },
		{ year: _year, sortTH: 'ต.ค.', sortEN: 'Oct', th: "ตุลาคม", en: "October", month: 10 },
		{ year: _year, sortTH: 'พ.ย.', sortEN: 'Nov', th: "พฤศจิกายน", en: "November", month: 11 },
		{ year: _year, sortTH: 'ธ.ค.', sortEN: 'Dec', th: "ธันวาคม", en: "December", month: 12 }
	]
}

export function splitString(_string, _split, _index) {
	// console.log('splitString', { _string, _split, _index })
	if (typeof _string === 'string') {
		const result = _string.split(`${_split}`)
		// console.log(result[_index])
		return result[_index]
	} else {
		return ''
	}
}

export function calculateAvg(_arr, _field) {
	let result = 0;
	let _sumData = 0;
	let _newArr = [];
	let _length = 0

	/**
	 * ค่าเฉลี่ย = ผลรวมข้อมูลทั้งหมด/จำนวนข้อมูลทั้งหมด
	 */
	if (Array.isArray(_arr) && _arr.length > 0) {
		for (let i = 0; i < _arr.length; i++) {
			if (Math.floor(_arr[i][_field]) !== 0) {
				_length = _newArr.push(_arr[i])
				_sumData += Math.floor(_arr[i][_field])
			}
		}
		// console.log({ _sumData, _length })
		result = _sumData / _length;
	}
	return parseFloat(result.toFixed(2))
}


export function checkArrayEmpty(_arr, _field) {
	let result = _arr.some((each) => (each[_field] === ""))
	return result
}
export function checkDisbled(_arr, _field) {
	let result = _arr.some((each) => (each[_field] === "" || each[_field] === undefined))
	return result
}


export function convertArray(_field, _type, _arr) {
	let result = []
	switch (_type) {
		case 'string':
			for (let i = 0; i < _arr.length; i++) {
				if (isNil(_arr[i][_field])) {
					result.push('')
				} else {
					result.push(_arr[i][_field])
				}
			}
			break;
		case 'object':
			for (let i = 0; i < _arr.length; i++) {
				if (!isNil(_arr[i][_field]) && _arr[i][_field] !== 'undefined') {
					result.push(_arr[i])
				}
			}
			// console.log({ result })
			break;
		default:
			break;
	}
	return result
}


export function downloadFile(file, type) {
	let pathname = CONFIG.API_IMAGE + file.filePath
	if (type === 'all') {
		pathname = CONFIG.API_IMAGE + file
	}

	var link = document.createElement('a');
	link.setAttribute('href', pathname);
	link.setAttribute('download', pathname);
	link.setAttribute('target', '_blank');
	link.click();
}

export function validateHour(_num = '') {
	// console.log({ _num })
	let _hour = Math.floor(_num.toString().split('.')[0])
	let _decimal = _num.toString().split('.')[1]
	// console.log({ _hour, _decimal })
	if (!isNaN(_decimal) && _decimal !== "" && _decimal !== 'undefined') {
		if (_decimal < 5) {
			return parseInt(_hour)
		} else {
			return parseFloat(`${_hour}.5`)
		}
	} else {
		return _num
	}
}

export function validateScore(_num = '') {
	//console.log({ _num })
	let _score = Math.floor(_num.toString().split('.')[0])
	// let _decimal = _num.toString().split('.')[1]
	if (Math.floor(_num) === 0) {
		return ''
	} else {
		// if (!isNaN(_decimal) && _decimal !== "" && _decimal !== 'undefined') {
		//     if (decimal) {
		//         return parseFloat(`${_score}.${_decimal}`)
		//     } else {
		return parseInt(_score)
		// }
		// } else {
		//     return _num
		// }
	}
}


export async function wait(ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}


export function toCamelCase(str) {
	return `${str}`.split(' ').map((word) => word.charAt(0).toLowerCase() + word.slice(1)).join('');
}



export function validateTextEN(_value) {
	let result = ''
	let isNum = new RegExp(/([0-9])/g).test(_value);
	let isEN = new RegExp(/^[A-z-]/g).test(_value);
	let isTH = new RegExp(/([ก-ฮ])/g).test(_value);
	if (!!isEN && (!isNum && !isTH)) {
		result = _value
	}
	return result
}

