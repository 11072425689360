import React from "react";
import { tableColumn } from "../../../../components/global/tables/tableColumn";
import { PATHNAME } from "../../../../constants/enums/pathname";
import { ButtonTheme } from "../../../../components/global/buttons";
import { Space } from "antd";
import { encode } from "../../../../extendFunction/encodeString";

export const employFormColumn = () => {
  return [
    tableColumn({
      type: "DATE",
      title: "รหัสพนักงาน",
      key: "userId",
      width: 150,
    }),
    tableColumn({
      type: "TEXT",
      title: "ชื่อ-สกุล",
      key: "empFullName",
      width: 300,
    }),
    tableColumn({
      type: "TEXT",
      title: "ชื่อเล่น",
      key: "empNickName",
      width: 150,
    }),
    tableColumn({
      type: "DATE",
      title: "วันที่เริ่มงาน",
      key: "startDateJob",
      width: 200,
    }),
    tableColumn({
      type: "TEXT",
      title: "ประเภทการจ้าง",
      key: "empType",
      width: 200,
    }),
    tableColumn({
      type: "TEXT",
      title: "แผนก",
      key: "empDepartmentName",
      width: 300,
    }),
    tableColumn({
      type: "CUSTOM_FLAT",
      title: "ฟอร์ม",
      key: "userId",
      width: 300,
      isAction: true,
      option: {
        fixed: "right",
        render: (text) => {
          return (
            <Space>
              <ButtonTheme
                useFor="VIEW"
                title="ใบปะหน้า"
                onClick={() => {
                  window.open(
                    `${PATHNAME.FORM_HIRE_DETAIL}?userId=${encode(text)}`,
                    "_blank"
                  );
                }}
              />
  
              <ButtonTheme
                useFor="VIEW"
                title="หนังสือสัญญา"
                onClick={() => {
                  window.open(
                    `${PATHNAME.FORM_CONTRACT_DETAIL}?userId=${encode(text)}`,
                    "_blank"
                  );
                }}
              />
            </Space>
          );
        },
      },
    }),
  ];
};
