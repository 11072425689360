import { FIELD_STEP, MESSAGE_ERROR } from "../..";
import { KEY_TYPE } from "../../../../constants/enums/enumCenter";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  COURSE_NAME: "ชื่อหลักสูตร (Course name)",
  ORGANIZED_BY: "จัดโดย (Organized by)",
  DURATION: "ระยะเวลา (Duration)",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  COURSE_NAME: "courseName",
  ORGANIZED_BY: "organizedBy",
  DURATION: "duration",
};

/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
  courseName: "",
  organizedBy: "",
  duration: "",
};

export const FIELD_TRAINING = FIELD_PAGES;
export const INITIAL_TRAINING = INITIAL_PAGES;
/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_TRAINING_VIEW = (props) => {
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CARD_FORM,
          name: FIELD_STEP.COLLAPSE_S32,
          removelenght: 0,
          header: (idx) => `หลักสูตรที่ ${idx + 1}`,
          initialValues: { ...INITIAL_PAGES },
          disabled: disabledAll,
          content: (field) => [
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.COURSE_NAME],
                label: LABEL_PAGES.COURSE_NAME, //ชื่อหลักสูตร (Course name)
                fieldKey: [field.fieldKey, FIELD_PAGES.COURSE_NAME],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.ORGANIZED_BY],
                label: LABEL_PAGES.ORGANIZED_BY, // จัดโดย (Organized by)
                fieldKey: [field.fieldKey, FIELD_PAGES.ORGANIZED_BY],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.DURATION],
                label: LABEL_PAGES.DURATION, //ระยะเวลา (Duration)
                fieldKey: [field.fieldKey, FIELD_PAGES.DURATION],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};
