import React from 'react'
import { MASTER_OPTION } from "../../services/servicesOptions"
import { ReportIndicator } from '../../components/reportIndicator'
import { AwaitingApproval } from '../../components/awaitingApproval'
import { ButtonTheme } from '../../common/buttons'
import { TYPE } from '../../common/inputs'

const breadcrumbLists = () => [
    { label: "HRM - PMS" },
    { label: "KPIs รายบุคคล" },
    { label: "เพิ่ม/แก้ไข/อนุมัติ (KPIs)" }
]

const tabsLists = () => [
    { tab: 'รายการรออนุมัติ', content: <AwaitingApproval /> },
    { tab: 'รายการตัวชี้วัด', content: <ReportIndicator /> },
]

const searchLists = () => [
    {
        header: "เดือนที่มีผลบังคับใช้",
        type: TYPE.SELECT,
        fieldname: MASTER_OPTION.MONTH,
        fieldOption: `OPTION_${MASTER_OPTION.MONTH}`,
        searchInput: true
    }, {
        header: "สายการอนุมัติ",
        type: TYPE.SELECT_MULTIPLE,
        fieldname: MASTER_OPTION.BOSS_APPROVAL,
        fieldOption: `OPTION_${MASTER_OPTION.BOSS_APPROVAL}`,
        searchInput: true
    }, {
        header: "สถานะ",
        type: TYPE.SELECT,
        fieldname: MASTER_OPTION.INDICATOR_STATUS,
        fieldOption: `OPTION_${MASTER_OPTION.INDICATOR_STATUS}`,
        searchInput: true
    }
]

// const columnApproveIndicator = ({ dataSource, searchEmpty, searchDate, action, tooltip }) => {

//     return [
//         {
//             title: 'ลำดับ',
//             dataIndex: 'rowNum',
//             render: (text) => text || '-',
//         },
//         {
//             title: 'รหัสตัวชี้วัด',
//             dataIndex: 'indicatorHeaderId',
//             render: (text) => text || '-',
//         },
//         {
//             title: 'จำนวนหัวข้อตัวชี้วัด',
//             dataIndex: 'indicatorCount',
//             render: (text) => text || '-',
//         }, {
//             title: 'สร้างโดย',
//             dataIndex: 'createName',
//             render: (text) => text || '-',
//         }, {
//             title: 'เดือนทีมีผลบังคับใช้',
//             dataIndex: 'durationActive',
//             render: (text) => text || '-',
//         }, {
//             title: 'สถานะ',
//             dataIndex: 'statusName',
//             render: (text) => text || '-',
//         }, {

//             title: '',
//             dataIndex: '',
//             render: (text, record) => <ButtonTheme buttonText="ดูรายละเอียด" />,
//         }
//     ]
// }
const columnIndicatorAll = ({ action }) => {

    return [
        {
            title: 'ชื่อตัวชี้วัด',
            dataIndex: 'indicatorHeaderName',
            render: (text) => text || '-',
        },
        {
            title: 'จำนวนหัวข้อตัวชี้วัด',
            dataIndex: 'indicatorCount',
            render: (text) => text || '-',
        }, {
            title: 'สร้างโดย',
            dataIndex: 'createBy',
            render: (text) => text || '-',
        }, {
            title: 'เดือนทีมีผลบังคับใช้',
            dataIndex: 'durationActive',
            render: (text) => text || '-',
        }, {
            title: 'สถานะ',
            dataIndex: 'statusName',
            render: (text) => text || '-',
        }, {

            title: '',
            dataIndex: '',
            width: 100,
            render: (text, record) => action(record),
        }
    ]
}
const columnIndicatorExpanded = () => [
    { title: 'ตัววัดผลงาน', dataIndex: 'title', key: 'title' },
    { title: 'เกณฑ์', dataIndex: 'criterion', key: 'criterion' },
    { title: 'สัดส่วน', dataIndex: 'proportion', key: 'proportion' },
]

export const STRUCTURE_INDICATOR = {
    BREADCRUMB: breadcrumbLists,
    SEARCH_LISTS: searchLists,
    TABS_LISTS: tabsLists,
    // COLUMN_APPROVER_INDICATOR: columnApproveIndicator,
    COLUMN_INDICATOR_ALL: columnIndicatorAll,
    COLUMN_INDICATOR_EXPANDED: columnIndicatorExpanded
}