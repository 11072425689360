import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal, Form, Spin, Space, Card, Row, Col, Checkbox } from "antd";

import ComponentModal from "./ComponentModal";
import { ModalSelectTask } from "./ModalSelectTask";

import { FcCollaboration, FcCancel } from "react-icons/fc";

import {
  GET_BEARER,
  GET_TASK_USER_IN,
  GET_TASK_USER_OUT,
} from "../service";
import moment from "moment";
import { useRenderFormItem } from "../screens/conponents/RenderComponents";
import {
  ComponentNewEmp,
  ComponentSelectNewEmp,
} from "../screens/constants/Employee/Components";
import {
  optionStatusQuitTask,
  optionStatusTask,
  useOptionSelect,
} from "../screens/constants/OptionSelect";
import { configForm } from "../screens/constants/ConstantComponent";
import { useButton } from "../screens/conponents/buttons/CustomButtonAntd";
import CustomTableAtnd from "../screens/conponents/tables/CustomTableAtnd";
import {
  ColumnNewEmp,
  ColumnQuitEmp,
} from "../screens/constants/Employee/Column";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import ModalConfirmTask from "./ModalConfirmTask";
import { GET_UserLogin } from "../screens/functions/UseFunction";
import { decryptBase64 } from "../service/functions/encodeFunction";
import ModalConfirmReceived from "./ModalConfirmReceived";
const { TabPane } = Tabs;

const Assets_NewEmpMain = () => {
  const [form] = Form.useForm();
  const [selectFrom] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("NEW");
  const [dataTable, setDataTable] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleSelect, setModalVisibleSelect] = useState(false);
  const [modalVisibleConfirmTask, setModalVisibleConfirmTask] = useState(false);
  const [modalVisibleConfirmReceived, setModalVisibleConfirmReceived] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalTask, setModalTask] = useState("");
  const [dataRecord, setDataRecord] = useState("");
  const [empOption, setEmpOption] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const { token } = GET_UserLogin();

  useEffect(() => {
    onLoadActiveTab();
  }, []);

  const onLoadActiveTab = () => {
    const params = new URLSearchParams(window.location.search);
    const activeTabParam = params.get("activeTab");
    if (!activeTabParam) return onGetTaskUserIn();
    setActiveTab(activeTabParam);
    onGetTaskUserOut();
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const onSelectRow = {
    selectedRows,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.prepareStatus === "CONFIRM" ? true : false,
    }),
  };

  let optionSearch = {
    userId: empOption,
    bossId: useOptionSelect("managerName"),
    depNo: useOptionSelect("department"),
    statusTask: activeTab === "QUIT" ? optionStatusQuitTask : optionStatusTask,
  };

  const onReset = () => {
    form.resetFields();
  };

  const changeTab = (activeKey) => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setDataTable([]);
    setShowAll(false);
    setActiveTab(activeKey);
    if (activeKey === "QUIT") return onGetTaskUserOut();
    onGetTaskUserIn();
  };

  const onSearch = (values) => {
    const { userId, bossId, depNo } = values;
    const payload = {
      depNo: depNo || "",
      bossId: bossId || "",
      userId: userId || "",
      showAll: showAll,
    };
    if (activeTab === "QUIT") return onGetTaskUserOut(payload);
    onGetTaskUserIn(payload);
  };

  const onGetTaskUserIn = async (payload) => {
    const params = new URLSearchParams(window.location.search);
    const isFromLark = (window.location.href).includes("?userId=");
    let userId = "";
    if (isFromLark) {
      const val = params.get("userId")
      userId = decodeURI(decryptBase64(val));
    }
    const payloadFetch = {
      depNo: "",
      bossId: "",
      userId: userId,
    };
    const payloadInit = {
      depNo: "",
      bossId: "",
      userId: "",
    };
    try {
      setLoading(true);
      const res = await Promise.all([
        GET_BEARER(GET_TASK_USER_IN(payloadInit)), //*Get Option All
        GET_BEARER(GET_TASK_USER_IN(payload || payloadFetch)) //*Get Search Data
      ]);
      const { result, success } = res[1];
      let mapResult = [];
      if (success) {
        mapResult = result.data.map((row, key) => ({
          key: key + 1,
          ...row,
        }));
      }
      setDataTable(mapResult);
      //-------------------------------------------------------
      let mapOption = [];
      const { result: resultAll} = res[0];
      mapOption = resultAll.data.map((row, key) => ({
        value: row.userId,
        txt: `[${row.userId}] : ${row.fullname}`,
      }));
      setEmpOption(mapOption);
      const searchParams = userId ? userId.split(',') :  []
      form.setFieldsValue({
        userId: payload && payload.userId ? payload.userId : searchParams
      })
      setLoading(false);
      setDataRecord();
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Get: ", err.message);
    } finally {
      setLoading(false);
    }
  };

  const onGetTaskUserOut = async (payload) => {
    const payloadFetch = {
      depNo: "",
      bossId: "",
      userId: "",
      showAll: showAll,
    };
    try {
      setLoading(true);
      const res = await GET_BEARER(
        GET_TASK_USER_OUT(payload || payloadFetch),
        token
      );
      const { result, success } = res;
      let mapResult = [];
      let mapOption = [];
      if (success) {
        mapResult = result.data.map((row, key) => ({
          key: key + 1,
          ...row,
        }));
      }
      mapOption = result.data.map((row, key) => ({
        value: row.userId,
        txt: `[${row.userId}] : ${row.fullname}`,
      }));
      setEmpOption(mapOption);
      setDataTable(mapResult);
      setLoading(false);
      setDataRecord();
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Get: ", err.message);
    } finally {
      setLoading(false);
    }
  };

  const onOpenModal = (record, title, task) => {
    setModalVisible(true);
    setModalTask(task);
    setModalTitle(title);

    switch (task) {
      case "IT_ASSET":
        return setDataRecord({
          ...record,
          followUpDate: record.itFixedAssetFollowUpDate
            ? moment(record.itFixedAssetFollowUpDate)
            : moment(),
          noteValue: record.itFixedAssetNote,
          status: "FOLLOW",
        });
      case "DOMAIN":
        return setDataRecord({
          ...record,
          followUpDate: record.domainFollowUpDate
            ? moment(record.domainFollowUpDate)
            : moment(),
          noteValue: record.domainNote,
          status: "FOLLOW",
        });
      case "EMAIL":
        return setDataRecord({
          ...record,
          followUpDate: record.emailFollowUpDate
            ? moment(record.emailFollowUpDate)
            : moment(),
          noteValue: record.emailNote,
          status: "FOLLOW",
        });
      case "SOFTWARE":
        return setDataRecord({
          ...record,
          followUpDate: record.softwareFollowUpDate
            ? moment(record.softwareFollowUpDate)
            : moment(),
          noteValue: record.softwareNote,
          status: "FOLLOW",
        });
      case "PURCHASE_ASSET":
        return setDataRecord({
          ...record,
          followUpDate: record.purchaseFixedAssetFollowUpDate
            ? moment(record.purchaseFixedAssetFollowUpDate)
            : moment(),
          noteValue: record.purchaseFixedAssetNote,
          status: "FOLLOW",
        });
      case "HR":
        return setDataRecord({
          ...record,
          followUpDate: record.hrFollowUpDate
            ? moment(record.hrFollowUpDate)
            : moment(),
          noteValue: record.hrNote,
          status: "FOLLOW",
        });
      default:
      return null;
    }
  };

  const onOpenModalSelect = (task) => {
    if (!task.statusTask)
      return Modal.warning({
        title: "กรุณาเลือก Task",
      });
    setModalVisibleSelect(true);
    setModalTask(task.statusTask);
    switch (task.statusTask) {
      case "IT_ASSET":
        return setModalTitle("IT Fixed Asset");
      case "DOMAIN":
        return setModalTitle("IT Domain");
      case "EMAIL":
        return setModalTitle("IT Email");
      case "SOFTWARE":
        return setModalTitle("IT Software");
      case "PURCHASE_ASSET":
        return setModalTitle("PurchaseFixed");
      case "CONFIRM":
        return setModalTitle("Confirm");
      case "HR":
        return setModalTitle("Hr");
      default:
        return null;
    }
  };

  const onOpenModalConfirmTask = (record) => {
    setModalVisibleConfirmTask(true);
    setDataRecord({ ...record, status: "CONFIRM" });
  };

  const onOpenModalConfirmReceived = (record) => {
    setModalVisibleConfirmReceived(true);
    setDataRecord({ ...record, dateReceived: moment(), status: "RECEIVED" });
  };

  const onCheck = () => {
    setShowAll(!showAll);
  };

  const onCloseModal = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setModalVisible(false);
    if (activeTab === "QUIT") return onGetTaskUserOut();
    onGetTaskUserIn();
  };

  const onCloseModalSelect = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setModalVisibleSelect(false);
    if (activeTab === "QUIT") return onGetTaskUserOut();
    onGetTaskUserIn();
  };

  const onCloseModalConfirmTask = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setModalVisibleConfirmTask(false);
    if (activeTab === "QUIT") return onGetTaskUserOut();
    onGetTaskUserIn();
  };

  const onCloseModalConfirmReceived = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setModalVisibleConfirmReceived(false);
    if (activeTab === "QUIT") return onGetTaskUserOut();
    onGetTaskUserIn();
  };

  return (
    <Fragment>
      <Form form={form} onFinish={onSearch} {...configForm}>
        <Card
          title="ค้นหาพนักงาน"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                moreProps: { label: "ล้างข้อมูล" },
                fn: onReset,
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentNewEmp({
              options: optionSearch
            }),
          })}
          {activeTab === "QUIT" && (
            <Checkbox checked={showAll} onChange={onCheck}>
              {"แสดงข้อมูลทั้งหมด"}
            </Checkbox>
          )}
        </Card>
      </Form>
      <ComponentModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modalTitle={modalTitle}
        modalTask={modalTask}
        onCloseModal={onCloseModal}
        dataRecord={dataRecord}
        activeTab={activeTab}
      />
      <ModalConfirmTask
        modalVisible={modalVisibleConfirmTask}
        setModalVisible={setModalVisibleConfirmTask}
        onCloseModal={onCloseModalConfirmTask}
        dataRecord={dataRecord}
        activeTab={activeTab}
      />
      <ModalConfirmReceived
        modalVisible={modalVisibleConfirmReceived}
        setModalVisible={setModalVisibleConfirmReceived}
        onCloseModal={onCloseModalConfirmReceived}
        dataRecord={dataRecord}
        activeTab={activeTab}
      />
      <ModalSelectTask
        modalVisible={modalVisibleSelect}
        setModalVisible={setModalVisibleSelect}
        selectedRows={selectedRows}
        selectedRowKeys={selectedRowKeys}
        onCloseModal={onCloseModalSelect}
        modalTask={modalTask}
        modalTitle={modalTitle}
        activeTab={activeTab}
      />
      <Form form={selectFrom} onFinish={onOpenModalSelect}>
        <Card>
          <Row>
            <Col span={8} offset={16}>
              {useRenderFormItem({
                constantComponent: ComponentSelectNewEmp({
                  options: optionSearch,
                }),
              })}
            </Col>
          </Row>

          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "end" }}
          >
            {useButton({
              call: "save",
              isDisable: selectedRows.length > 0 ? false : true,
            })}
          </Space>
        </Card>
      </Form>

      <Tabs activeKey={activeTab} onChange={changeTab}>
        <TabPane
          tab={
            <Fragment>
              <FcCollaboration className="margin-icon" size={20} />
              พนักงานเข้าใหม่
            </Fragment>
          }
          key="NEW"
        >
          <Spin spinning={loading}>
            <div style={{ color: "red", paddingBottom: "20px" }}>
              <b>**หมายเหตุ:</b> - รายการจะไม่แสดงหลังจากที่กดยืนยันไปแล้ว 3 วัน
            </div>
            <CustomTableAtnd
              HeadTable={ColumnNewEmp({
                actions: { onOpenModal, onOpenModalConfirmTask, onOpenModalConfirmReceived },
              })}
              DataTable={dataTable}
              moreProps={{
                scroll: {
                  x: 2200,
                  y: 700,
                },
                rowSelection: onSelectRow,
              }}
            />
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <Fragment>
              <FcCancel className="margin-icon" size={20} />
              พนักงานลาออก
            </Fragment>
          }
          key="QUIT"
        >
          <Spin spinning={loading}>
            <div style={{ color: "red", paddingBottom: "20px" }}>
              <b>**หมายเหตุ:</b> - รายการจะไม่แสดงหลังจากที่กดยืนยันไปแล้ว 3 วัน
            </div>
            <CustomTableAtnd
              HeadTable={ColumnQuitEmp({
                actions: { onOpenModal, onOpenModalConfirmTask },
              })}
              DataTable={dataTable}
              moreProps={{
                scroll: {
                  x: 2000,
                  y: 700,
                },
                rowSelection: onSelectRow,
              }}
            />
          </Spin>
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

export default Assets_NewEmpMain;
