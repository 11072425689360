import React, { useState } from "react";
import { Form, Card, Spin } from "antd";
import { ApprovalFlow, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsInsertBranchApprove } from "../../constants/ManageBranchApprove/Components";

const FormAddBranchApprove = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [ChangeAddValue, setChangeAddValue] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);

  let optionEmployee = useOptionSelect("empAll");

  let options = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    approvers_lv_1: optionEmployee,
    approvers_lv_2: optionEmployee,
  }

  let isDisable = {
    location: ChangeAddValue["company"] ? false : true,
    approvers_lv_1: ChangeAddValue["location"] ? false : true,
    approvers_lv_2: ChangeAddValue["location"] ? false : true,
  }
  // [END] States

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const objectBody = {
      approvers: [values.approvers_lv_1, values.approvers_lv_2],
      company: values.company,
      location: values.location,
      active: true,
      userlogin,
    };
    useConfirm({ FnOK: () => insertApproveFlow(objectBody) });
  };

  const onReset = () => {
    form.resetFields();
    setChangeAddValue([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const insertApproveFlow = async (value) => {
    try {
      const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.POST_INSERT_Approve}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      if (response.ok) {
        const genID = data.result;
        setLoadingScreen(true);
        useAlert({
          type: "success",
          title: "เพิ่มสายอนุมัติสำเร็จ",
          content: `รหัสสายอนุมัติคือ : ${genID.flowId}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาดจากการเพิ่มข้อมูล",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      setLoadingScreen(false);
      console.log("Error Insert ApproveFlow: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndifined = {};
    let setName = {};
    let arrayInput = ['company', 'location', 'approvers_lv_1', 'approvers_lv_2'];
    switch (name) {
      case "company":
        setName = { companyCode: val }
        setUndifined = toUndefined(arrayInput.slice(1));
        break;
      case "location":
        setUndifined = toUndefined(arrayInput.slice(2));
        break;
    }
    form.setFieldsValue(setUndifined);
    setChangeAddValue((prev) => ({
      ...prev,
      ...setUndifined,
      ...setName,
      [name]: val,
    }));
  };
  // [END] Functions
  return (
    <Card title="เพิ่มข้อมูลสายอนุมัติ" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsInsertBranchApprove({
              options,
              isDisable,
              onClick: onReset,
              handleChange: onChangeValue,
            })
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FormAddBranchApprove;
