import React, { useState } from "react";
import { Form, Card, Spin } from "antd";
import { Position, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import {
  optionSelectLocation,
  useOptionSelect,
} from "../../constants/OptionSelect";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsInsertPosition } from "../../constants/ManagePosition/Components";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";

export const FormAddPosition = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [formValue, setFormValue] = useState({});

  let querySubDepart = useFixedQueryString(formValue, ["depNo"]);
  let queryDepart = useFixedQueryString(formValue, ["company"]);

  let options = {
    company: useOptionSelect("company"),
    location: useOptionSelect("location"),
    depNo: useOptionSelect("department", queryDepart),
    subDepNo: useOptionSelect("subDepartment", querySubDepart),
  };

  // [START] Handle Form Antd
  const onFinish = (values) => {
    useConfirm({ FnOK: () => insertPosition({ ...values, userlogin }) });
  };
  const onReset = () => {
    form.resetFields();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const insertPosition = async (value) => {
    try {
      setLoadingScreen(true);
      const payload = {
        ...value,
        companyCode: value.company,
        company: undefined,
      };
      const url = `${USE_CONNECT_MODE_AUTO}${Position.POST_CREATE_Position}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การเพิ่มตำแหน่งงานสำเร็จ`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "info",
          title: "เพิ่มข้อมูลไม่สำเร็จ",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Position: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API

  const changeFormValue = (value, name) => {
    let setUndifined = {};
    let arrayInput = [
      "company",
      "location",
      "depNo",
      "subDepNo",
      "position",
      "positionNameEN",
      "positionNameTH",
    ];
    switch (name) {
      case "company":
        setUndifined = toUndefined(arrayInput.slice(1));
        break;
      case "location":
        setUndifined = toUndefined(arrayInput.slice(2));
        break;
      case "depNo":
        setUndifined = toUndefined(arrayInput.slice(3));
        break;
    }
    form.setFieldsValue(setUndifined);
    setFormValue((prev) => ({
      ...prev,
      ...setUndifined,
      [name]: value,
    }));
  };

  return (
    <Card title="เพิ่มข้อมูลตำแหน่งงาน" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsInsertPosition({
              options,
              isDisable: { 
                  positionCode: formValue["company"] ? false : true,
                  depNo: formValue["company"] ? false : true,
                  subDepNo: formValue["depNo"] ? false : true
               },
              onClick: onReset,
              handleChange: changeFormValue,
            }),
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FormAddPosition;
