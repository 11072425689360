import React from "react";
import moment from "moment";
import { convertExcelHeader } from "../../../functions/convert";
import TableForm from "../tables/TableForm";
import { RenderForm } from "../forms";
import { ButtonTheme } from "../buttons";
import { UseExportExcel } from "../buttons/exportExcelButton";
import { Spin, Card } from "antd";

const ReportPage = ({
  title = "",
  filterLoading = false,
  tableLoading = false,
  loading = false,
  filterForm = [],
  tableColumn = [],
  dataSource = null,
  onSearchClick,
  rowSelection = null,
  middleComponent,
  additionDataSet,
  tableButtonComponent,
  footerComponent,
  templateFunction,
  summary,
  control,
  setValue,
  getValues,
}) => {
  return (
    <Card title={title}>
      {filterForm.length > 0 && (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "8px 8px 8px 8px",
            border: "1px solid #e1e1e1",
          }}
        >
          <Spin spinning={filterLoading} tip="กำลังโหลด">
            <RenderForm
              title={title}
              control={control}
              forms={filterForm}
              setValue={setValue}
              getValues={getValues}
              searchSpan={2}
              renderButton={
                onSearchClick ? (
                  <ButtonTheme
                    useFor="SEARCH"
                    title="ค้นหา"
                    onClick={onSearchClick}
                  />
                ) : null
              }
            />
          </Spin>
        </div>
      )}
      {middleComponent}
      <div
        style={{
          marginTop: "10px",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px 8px 8px 8px",
          border: "1px solid #e1e1e1",
        }}
      >
        {dataSource == null && (
          <div style={{ textAlign: "center" }}>กรุณากดค้นหา</div>
        )}
        {dataSource != null && (
          <div>
            <div style={{ textAlign: "right" }}>
              {tableButtonComponent}
              <UseExportExcel
                header={convertExcelHeader(tableColumn)}
                dataSet={dataSource}
                filename={`${title}-${moment()}`}
                additionDataSet={additionDataSet}
                templateFunction={templateFunction}
              />
            </div>
            <Spin spinning={tableLoading} tip="กำลังโหลด">
              <TableForm
                columns={tableColumn}
                dataSource={dataSource}
                rowSelection={rowSelection}
                summary={summary}
                loading={loading}
              />
            </Spin>
            {footerComponent}
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReportPage;
