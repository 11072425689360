import React, { useContext, useEffect, useState } from "react";
import { ReportOTContext } from "../servicesOT/reportOT/reportOTProvider";
import { ManageOTContext } from "../servicesOT/manageOT/manageOTProvider";
import { path, update, equals } from "ramda";
import {
  REPORT_OPTION,
  MASTER_OPTION,
} from "../../moduleKPIs/services/servicesOptions";
import LayoutInput from "../commonsOT/layoutsOT/layoutInput";
import { MANAGE_REQUEST_OT } from "../commonsOT/structuresOT/manageOT";
import { Row, Col, Checkbox, Modal, Spin } from "antd";
import { ButtonTheme } from "../../moduleKPIs/common/buttons";
import { colors } from "../../themes";
import { LayoutTable } from "../../moduleKPIs/common/layouts";
import { TABLE_REGISTER_OT } from "../commonsOT/structuresOT/tableOT";
import { MasterOTContext } from "../servicesOT/masterOT/masterOTProvider";
import { browserHistory } from "react-router";
import { PATHNAME } from "../../constants/enums/pathname";
import { calDateDiff } from "../../moduleKPIs/common/functions";
import { APIUpdateRequestOT } from "../servicesOT/manageOT/manageOTConnect";
import { APILineNoti } from "../../modulePersonal/servicesPersonal/personalConnect";
const UpdateOT = () => {
  const { masterOTState, getMaster } = useContext(MasterOTContext);
  const { onClickButton, userLogin } = useContext(ManageOTContext);
  const { getReports } = useContext(ReportOTContext);
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const userLists = JSON.parse(window.localStorage.getItem("userLists"));
  const [initialData, setInitialData] = useState({ employeeLists: [] });
  const [signatureLists, setSignatureLists] = useState([]);
  const [statusCF, setStatusCF] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await getMaster({
        fieldname: MASTER_OPTION.FILTER_WORK_SHIFT,
        payload: null,
      });
      await getMaster({
        fieldname: MASTER_OPTION.FILTER_DASHBOARD_OT,
        payload: {
          userId: userLogin.userId,
          userPermission: userLogin.userPermission,
          subDepNo: userData.subDepNo,
        },
      });
    }
    fetchData();
  }, []);

  const onChange = async ({ fieldname, value, index = null }) => {
    switch (fieldname) {
      case "subDepNo":
        let _subDepNo = value[0];
        await getMaster({
          fieldname: REPORT_OPTION.USER_LOGIN_DATA,
          payload: {
            userId: "",
            subDep: _subDepNo,
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
          },
        });
        await setInitialData({ ...initialData, [fieldname]: _subDepNo });
        break;
      // case 'workShift':
      case "refFlow":
        await setInitialData({ ...initialData, [fieldname]: value[0] });
        break;
      case "remark":
      case "workload":
      case "selectedRowKeys":
        await setInitialData({ ...initialData, [fieldname]: value });
        break;
      case "activeDate":
        await setInitialData({
          ...initialData,
          [fieldname]: value.dateStrings,
        });
        break;
      case "startTime":
        // console.log(initialData, userData)
        await setInitialData({
          ...initialData,
          [fieldname]: value,
          totalHours: calDateDiff({
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime: userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
            [fieldname]: value,
          }),

          // totalHours: timeDiff(`${initialData.endDate || moment(userData.endDateTime)} ${initialData.endTime || '00:00'}`, `${initialData.startDate || moment(userData.startDateTime)} ${value}`)
        });
        await getMaster({
          fieldname: REPORT_OPTION.USER_LOGIN_DATA,
          payload: {
            userId: "",
            subDep: initialData.subDepNo || userData.subDepNo,
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              value || userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
          },
        });
        break;
      case "endTime":
        await setInitialData({
          ...initialData,
          [fieldname]: value,
          totalHours: calDateDiff({
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime: userData.endDateTime.split("T")[1].substr(0, 5),
            [fieldname]: value,
          }),

          // totalHours: timeDiff(`${initialData.endDate || moment(userData.endDateTime)} ${value}`, `${initialData.startDate || moment(userData.startDateTime)} ${initialData.startTime || '00:00'}`)
        });
        await getMaster({
          fieldname: REPORT_OPTION.USER_LOGIN_DATA,
          payload: {
            userId: "",
            subDep: initialData.subDepNo || userData.subDepNo,
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime: value || userData.endDateTime.split("T")[1].substr(0, 5),
          },
        });
        break;
      case "startDate":
        await setInitialData({
          ...initialData,
          [fieldname]: value.dateStrings,
          totalHours: calDateDiff({
            startDate: userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
            [fieldname]: value.dateStrings,
          }),
          // totalHours: timeDiff(
          //     `${initialData.endDate || moment(userData.endDateTime)} ${initialData.endTime || userData.endDateTime.split('T')[1].substr(0, 5) || '00:00'}`,
          //     `${value.dateStrings} ${initialData.startTime || userData.startDateTime.split('T')[1].substr(0, 5) || '00:00'}`)
        });
        await getMaster({
          fieldname: REPORT_OPTION.USER_LOGIN_DATA,
          payload: {
            userId: "",
            subDep: initialData.subDepNo || userData.subDepNo,
            startDate:
              value.dateStrings || userData.startDateTime.split("T")[0],
            endDate: initialData.endDate || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
          },
        });
        break;
      case "endDate":
        await setInitialData({
          ...initialData,
          [fieldname]: value.dateStrings,
          totalHours: calDateDiff({
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
            [fieldname]: value.dateStrings,
          }),
          // totalHours: timeDiff(
          //     `${value.dateStrings} ${initialData.endTime || userData.endDateTime.split('T')[1].substr(0, 5) || '00:00'}`,
          //     `${initialData.startDate || moment(userData.startDateTime)} ${initialData.startTime || userData.startDateTime.split('T')[1].substr(0, 5) || '00:00'}`)
        });
        await getMaster({
          fieldname: REPORT_OPTION.USER_LOGIN_DATA,
          payload: {
            userId: "",
            subDep: initialData.subDepNo || userData.subDepNo,
            startDate:
              initialData.startDate || userData.startDateTime.split("T")[0],
            endDate: value.dateStrings || userData.endDateTime.split("T")[0],
            startTime:
              initialData.startTime ||
              userData.startDateTime.split("T")[1].substr(0, 5),
            endTime:
              initialData.endTime ||
              userData.endDateTime.split("T")[1].substr(0, 5),
          },
        });
        break;
      case "signatureLists":
        let dataIndex = userLists.findIndex((el) => el.userId === value.userId);
        await setSignatureLists(
          update(dataIndex, { ...userLists[dataIndex], ...value }, userLists)
        );
        // await setInitialData({ ...initialData, employeeLists: initialData.employeeLists.concat([{ ...value }]) })
        break;
      default:
        break;
    }
  };
  async function onSubmit() {
    // console.log(userLists, initialData)
    try {
      if (initialData) {
        let sumEmployeeList = [
          ...initialData.employeeLists,
          ...userLists,
          ...signatureLists,
        ];
        // console.log({ sumEmployeeList })
        let newEmployeeList = sumEmployeeList.reduce((acc, cur, i) => {
          const dataIndex = acc.findIndex((el) =>
            equals(el.userId, cur.userId)
          );
          if (dataIndex !== -1) {
            acc[dataIndex] = { ...acc[dataIndex] };
            return acc;
          } else {
            return [
              ...acc,
              {
                docNo: cur.docNo,
                linenum: cur.linenum,
                userId: cur.userId,
                name: cur.name,
                nickname: cur.nickname,
                positionNo: cur.positionNo,
                positionName: cur.positionName,
                depNo: cur.depNo,
                subDepNo: cur.subDepNo,
                newSignature: cur.newSignature,
                signature: cur.signature, ///signatureLists[i].signature
                createBy: userLogin.userId,
                createPermission: userLogin.userPermission,
              },
            ];
          }
        }, []);
        // console.log({ newEmployeeList })

        let payload = {
          dataHeader: {
            docNo: userData.docNo,
            subDepNo: path(["subDepNo"], initialData)
              ? path(["subDepNo"], initialData)
              : path(["subDepNo"], userData),
            refFlow: path(["refFlow"], initialData)
              ? path(["refFlow"], initialData)
              : path(["refFlow"], userData),
            startDateTime: `${
              path(["startDate"], initialData) ||
              userData.startDateTime.split("T")[0]
            } ${
              path(["startTime"], initialData) ||
              userData.startDateTime.split("T")[1].substr(0, 5)
            }`,
            endDateTime: `${
              path(["endDate"], initialData) ||
              userData.endDateTime.split("T")[0]
            } ${
              path(["endTime"], initialData) ||
              userData.endDateTime.split("T")[1].substr(0, 5)
            }`,
            startDate: `${
              path(["startDate"], initialData) ||
              userData.startDateTime.split("T")[0]
            } `,
            endDate: `${
              path(["endDate"], initialData) ||
              userData.endDateTime.split("T")[0]
            } `,
            // "startDateTime":(path(['startDate'], initialData) && path(['startTime'], initialData)) ? `${path(['startDate'], initialData)} ${path(['startTime'], initialData)}` : path(['startDateTime'], userData),
            // "endDateTime": (path(['endDate'], initialData) && path(['endTime'], initialData)) ? `${path(['endDate'], initialData)} ${path(['endTime'], initialData)}` : path(['endDateTime'], userData),
            // "startDate": path(['startDate'], initialData) ? path(['startDate'], initialData) : `${userData.startDateTime.split('T')[0]}`,
            // "endDate": path(['endDate'], initialData) ? path(['endDate'], initialData) : `${userData.endDateTime.split('T')[0]}`,
            startTime: path(["startTime"], initialData)
              ? path(["startTime"], initialData)
              : `${userData.startDateTime.split("T")[1].substr(0, 5)}`,
            endTime: path(["endTime"], initialData)
              ? path(["endTime"], initialData)
              : `${userData.endDateTime.split("T")[1].substr(0, 5)}`,
            activeDate:
              path(["activeDate"], initialData) ||
              userData.startDateTime.split("T")[0],
            totalHours: path(["totalHours"], initialData)
              ? path(["totalHours"], initialData)
              : path(["totalHours"], userData),
            workShift: "", //path(['workShift'], initialData) ? path(['workShift'], initialData) : path(['workShift'], userData),
            workload: path(["workload"], initialData)
              ? path(["workload"], initialData)
              : path(["workload"], userData),
            remark: path(["remark"], initialData)
              ? path(["remark"], initialData)
              : path(["remark"], userData),
            // "sendAP3": path(['totalHours'], initialData) ? path(['totalHours'], initialData) > 48 ? "1" : "0" : `${userData.sendAP3}`,  //ถ้าtotalTime มากกว่า 2 ชั่วโมง  ให้ sendAP3 === 1 : 0
            sendAP3: "0", //! ไม่มีอนุมัติลำดับ 3 แล้ว
            statusCF: statusCF ? "1" : "0", //ถ้าเป็นแบบร่าง ส่ง 0 ถ้า confirm ส่ง 1
            createBy: userLogin.userId,
            createPermission: userLogin.userPermission,
          },
          dataLine: newEmployeeList,
        };
        const { dataHeader, dataLine } = payload;

        if (
          !!dataHeader.docNo &&
          !!dataHeader.refFlow &&
          !!dataHeader.startDateTime &&
          !!dataHeader.endDateTime &&
          !!dataHeader.startTime &&
          !!dataHeader.endTime &&
          !!dataHeader.activeDate &&
          !!dataHeader.remark &&
          !!dataHeader.sendAP3 &&
          !!dataHeader.subDepNo &&
          !!dataHeader.totalHours &&
          !!dataHeader.workload &&
          dataLine.length > 0
        ) {
          // console.log('UPDATE', { payload })
          //! line Noti
          APILineNoti({
            header: `FRONTEND `,
            title: ` APIUpdateRequestOT 📝`,
            message: `payload : ${JSON.stringify({ ...payload })}`,
          });

          let response = await APIUpdateRequestOT(payload);
          if (response.success) {
            Modal.success({
              title: "บันทึกสำเร็จ",
              onOk: () => {
                // window.close()
                browserHistory.push(PATHNAME.DASHBOARD_OT_FACTORY);
                let payloadSearchOT = JSON.parse(
                  window.localStorage.getItem("searchDashboardOT")
                );
                getReports({
                  fieldname: REPORT_OPTION.DASHBOARD_OT_FACTORY,
                  payload: { ...payloadSearchOT, userLogin },
                });
              },
            });
          }
        } else {
          Modal.error({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน 0",
          });
        }
      }
      // }
    } catch (error) {
      console.log({ error });
    }
  }

  let sumEmployeeList = [...initialData.employeeLists, ...userLists];
  let newEmployeeList = sumEmployeeList.reduce((acc, cur) => {
    const dataIndex = acc.findIndex((el) => equals(el.userId, cur.userId));
    if (dataIndex !== -1) {
      acc[dataIndex] = { ...acc[dataIndex] };
      return acc;
    } else {
      return [
        ...acc,
        {
          docNo: cur.docNo,
          linenum: cur.linenum,
          userId: cur.userId,
          name: cur.name,
          nickname: cur.nickname,
          positionNo: cur.positionNo,
          positionName: cur.positionName,
          depNo: cur.depNo,
          subDepNo: cur.subDepNo,
          newSignature: cur.newSignature,
          signature: cur.signature, //! Remove Signature
          createBy: userLogin.userId,
          createPermission: userLogin.userPermission,
        },
      ];
    }
  }, []);
  //   let sigLists =
  //     Array.isArray(newEmployeeList) &&
  //     newEmployeeList.filter((val) => val.signature === "");//! Remove Signature
  //let disabledConfirm = sigLists.length > 0 ? true : false;//! Remove Signature
  let disabledConfirm = false;
  return (
    <Spin spinning={false} tip="กำลังโหลดข้อมูล...">
      <div style={{ padding: 20, backgroundColor: "white", marginBottom: 20 }}>
        <Row gutter={[10, 24]}>
          {MANAGE_REQUEST_OT({
            update: true,
            permisstion: userLogin.userPermissioln,
            options: {
              hrmFlow: masterOTState
                ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]
                : [],
              subDepNo: masterOTState
                ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]
                : [],
              // workShift: masterOTState ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_WORK_SHIFT}`] : []
            },
            defaultValue: {
              subDepNo: path(["subDepNo"], userData),
              refFlow: path(["refFlow"], userData),
              workShift: "", // path(['workShift'], userData),
              startDate: path(["startDateTime"], userData).split("T")[0],
              endDate: path(["endDateTime"], userData).split("T")[0],
              startTime: path(["startDateTime"], userData),
              endTime: path(["endDateTime"], userData),
              activeDate: path(["activeDate"], userData)
                ? path(["activeDate"], userData).split("T")[0]
                : path(["startDateTime"], userData).split("T")[0],
              totalHours: path(["totalHours"], userData),
              remark: path(["remark"], userData),
              workload: path(["workload"], userData),
            },
          }).map((row) =>
            row.map((each, eachIndex) => {
              return (
                <Col key={eachIndex} span={each.span}>
                  <div style={{ paddingBottom: 5 }}>
                    <span style={{ color: colors.error }}> {"*"} </span>{" "}
                    {each.header}
                  </div>
                  <LayoutInput
                    type={each.type}
                    value={path([each.inputOption.fieldname], initialData)}
                    onChange={onChange}
                    {...each}
                  />
                </Col>
              );
            })
          )}
        </Row>
      </div>
      {/* <ButtonTheme
                buttonStyle={{ backgroundColor: colors.error, color: colors.white }}
                buttonText={TEXT_BTN.REMOVE_EMP_REQUEST_OT}
                onClick={() => onClickButton({
                    fieldBtn: FIELD_BTN.REMOVE_EMP_REQUEST_OT, payload: initialData
                })}
            /> */}
      <div style={{ position: "relative" }}>
        <LayoutTable
          bordered
          rowKey="userId"
          scroll={{ x: 600 }}
          dataSource={userLists || []}
          columns={TABLE_REGISTER_OT({
            update: true,
            userLogin: userLogin,
            onChange: onChange,
            selectedRowKeys: initialData.selectedRowKeys,
            actionButton: (element) => {
              //TODO : รอเช็ค API Update Image
              /* ---------------------------- Update Signature ---------------------------- */

              // if (element.fieldBtn === FIELD_BUTTON.INSERT_SIGNATURE) {
              //     Modal.confirm({
              //         icon: null,
              //         title: <div style={{ position: 'absolute', left: 15, bottom: 10 }}>
              //             <ButtonTheme
              //                 buttonText={TEXT_BUTTON.RESET}
              //                 onClick={() => refSignature.clear()} />
              //         </div>,
              //         okText: TEXT_BUTTON.CONFIRM,
              //         cancelText: TEXT_BUTTON.CANCEL,
              //         onOk: () => onChange({
              //             fieldname: 'signatureLists',
              //             value: {
              //                 ...element.payload.record,
              //                 signature: refSignature.getTrimmedCanvas().toDataURL('image/png'),
              //                 newSignature: 'Y'
              //             },
              //             index: element.payload.index
              //         }),

              //         content: <div style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
              //             <CompSignature ref={(_sigPad) => refSignature = _sigPad} />
              //         </div>
              //     })

              // } else {
              /* -------------------------------------------------------------------------- */

              onClickButton(element);
              // }
            },
            // optionModal: {
            //     openModal,
            //     showModal: () => showModal(true),
            //     closeModal: () => showModal(false),
            // }
          })}
          pagination={{
            defaultPageSize: 500,
          }}
          // rowSelection={{
          //     type: 'checkbox',
          //     ...rowSelection,
          // }}
        />
        <Checkbox
          disabled={disabledConfirm}
          style={{ paddingTop: 10 }}
          checked={statusCF}
          onChange={() => setStatusCF(!statusCF)}
        >
          {
            "ยืนยันการบันทึกและส่งให้หัวหน้างาน (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)"
          }
        </Checkbox>
        <div className="layout-content-btn-confirm">
          <ButtonTheme
            type={statusCF ? "primary" : ""}
            style={{ paddingRight: 10 }}
            buttonText={
              statusCF ? "ยืนยันข้อมูล (แก้ไขไม่ได้)" : "บันทึกแบบร่าง"
            }
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </Spin>
  );
};

export default UpdateOT;
