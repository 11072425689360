import React, { useState } from "react";
import { Form, Card, Row, Col, Divider, Spin } from "antd";
import { KPIs, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { SelectAntd } from "../select/SelectAntd";
import { OpenNotificationWithIcon } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { useButton } from "../buttons/CustomButtonAntd";
import {
  useFixedQueryString,
  useMakeQueryString,
} from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useOptionSelect } from "../../constants/OptionSelect";

export const FormSearchKPIs = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({});
  const [dataTable, setDataTable] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);

  let querySearch = useMakeQueryString(changeSearchValue);
  let queryPosition = useFixedQueryString(changeSearchValue, ["companyCode"]);

  let optionCompany = useOptionSelect("company");
  let optionPosition = useOptionSelect("kpiPosition", queryPosition);
  // [END] States

  // [START] Handle Form

  const onFinish = (values) => {
    GET_Search_KPIs();
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeSearchValue({});
  };
  // [END] Handle Form

  // [START] REST API
  const GET_Search_KPIs = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const url = `${USE_CONNECT_MODE_AUTO}${KPIs.GET_SELECT_KPIs}?${querySearch}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const RESULT = data.result;
        let MAP_RESULT = [];
        if (RESULT.length !== 0) {
          for (let Round in RESULT) {
            MAP_RESULT.push({ ...RESULT[Round], key: Round });
          }
          setDataTable(MAP_RESULT);
        } else {
          setDataTable([]);
          OpenNotificationWithIcon(
            "error",
            "ไม่พบข้อมูลจากการค้นหา",
            `กรุณาลองใหม่อีกครั้ง`
          );
        }
      }
    } catch (err) {
      console.log("Error Search KPIs", err);
      setDataTable([]);
    } finally {
      setLoadingScreen(false);
    }
  };

  const PUT_DEACTIVE = async (record) => {
    if (!record) return null;
    try {
      let statusActive = record.statusActiveHeader ? `inactive` : `active`;
      let statusChange = record.statusActiveHeader ? false : true;
      const url = `${USE_CONNECT_MODE_AUTO}${KPIs.PUT_KPIs_Activate}${record.positionCode}/${statusActive}`;
      const response = await fetch(url, {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การจัดการสถานะสำเร็จ`,
          Fn: () =>
            setDataTable(
              dataTable.map((val) => {
                return val.positionCode === record.positionCode
                  ? {
                    ...val,
                    statusActiveHeader: statusChange,
                  }
                  : val;
              })
            ),
        });
      } else {
        useAlert({
          type: "error",
          title: "การจัดการสถานะไม่สำเร็จ",
          content: `${data.message}`,
        });
      }
    } catch (err) {
      console.log("PUT deActive KPIs: ", err);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let SET_undifined = {};
    switch (name) {
      case "companyCode":
        SET_undifined = toUndefined(["positionCode"]);
        break;
      default:
        SET_undifined = {};
        break;
    }
    setDataTable([]);
    form.setFieldsValue(SET_undifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...SET_undifined,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { ColumnKPIs, ColumnKPIs_ExpandableTable } = useColumnTable({
    Config: {
      LinkEdit: `ManageKPIs/update-KPIs/`,
      FnDelete: (record) => PUT_DEACTIVE(record),
    },
  });
  // [END] Control Column

  // [START] Expand Table
  const ShowExpandableTable = (record) => {
    const MAP_indicators = record.indicators.map((val) => ({
      ...val,
      key: val.indicatorLine,
    }));
    return (
      <>
        <div style={{ marginTop: 30 }}>
          <Divider orientation="left">ตัวชี้วัด</Divider>
          {record.indicators.length !== 0 && (
            <CustomTableAtnd
              HeadTable={ColumnKPIs_ExpandableTable}
              DataTable={MAP_indicators}
              SetScroll={{ x: "80%" }}
            />
          )}
        </div>
      </>
    );
  };

  const expandable = {
    expandedRowRender: (record) => ShowExpandableTable(record),
  };
  // [END] Expand Table

  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        <Card
          title="จัดการหัวข้อ KPI ตามตำแหน่งงาน (โรงงาน)"
          style={{ marginBottom: 30 }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <SelectAntd
                  name={`companyCode`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={optionCompany}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <SelectAntd
                  name={`positionCode`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={optionPosition}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                <Form.Item>{useButton({ call: "search" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {dataTable.length !== 0 && (
          <CustomTableAtnd
            HeadTable={ColumnKPIs}
            DataTable={dataTable}
            ExpandTable={expandable}
            SetScroll={{ x: "80%" }}
          />
        )}
      </Spin>
    </>
  );
};

export default FormSearchKPIs;
