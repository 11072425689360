import { KEY_OPTION } from "../../constants/enums/enumCenter";
import { isArray } from "../common/functions";

/***
 * @param {*} _option - MASTER_OPTION.......
 */

export const REPORT_OPTION = {
    USER_LOGIN_DATA: "USER_LOGIN_DATA",
    DASHBOARD_OT_FACTORY: "DASHBOARD_OT_FACTORY",
    REPORT_OT_FACTORY: "REPORT_OT_FACTORY",
    EXPORT_CSV__OT_FACTORY: "EXPORT_CSV__OT_FACTORY",
    UPDATE_STATUS_CONFIRM: "UPDATE_STATUS_CONFIRM",
    UPDATE_STATUS_RECALL: "UPDATE_STATUS_RECALL",

    //# REGION OF EVAC

    DASHBOARD_REVIEWER: "DASHBOARD_REVIEWER",
    DASHBOARD_EVAC: "DASHBOARD_EVAC",
    DASHBOARD_EVAC_ACTION: "DASHBOARD_EVAC_ACTION",
    DASHBOARD_EVAC_VIEW: "DASHBOARD_EVAC_VIEW",
    SELECT_DATA_EVAC: "SELECT_DATA_EVAC",
    SELETE_REVIEWER_CURRENT: "SELETE_REVIEWER_CURRENT",
    SELETE_REVIEWER_UPDATE: "SELETE_REVIEWER_UPDATE",
    OPTION_FREQUENCY_WORK_WITH: "FILTER_FREQUENCY_WORK_WITH",
    OPTION_DEP_EVAC: "FILTER_DEP_EVAC",
    SELECT_QUESTION: "SELECT_QUESTION",
    FILTER_DEP_EVAC: "FILTER_DEP_EVAC",
    FILTER_DASHBOARD_REVIEWER: "FILTER_DASHBOARD_REVIEWER",
    SUMMARY_REPLY_EVAC_BY_ADMIN: "SUMMARY_REPLY_EVAC_BY_ADMIN",
    SUMMARY_REPLY_EVAC_BY_DEP: "SUMMARY_REPLY_EVAC_BY_DEP",
    SUMMARY_AVG_SCORE_BY_LINE: "SUMMARY_AVG_SCORE_BY_LINE",
    FILTER_DASHBOARD_SUMMARY: "FILTER_DASHBOARD_SUMMARY",
    //# END REGION OF EVAC

    //# REGION OF TRAINING
    DASHBOARD_TRAINING_VIEW: "DASHBOARD_TRAINING_VIEW",
    DASHBOARD_TRAINING_ACTION: "DASHBOARD_TRAINING_ACTION",
    DASHBOARD_TRAINING: "DASHBOARD_TRAINING",
    DASHBOARD_HISTORY_INDIVIDUAL: "DASHBOARD_HISTORY_INDIVIDUAL",
    DASHBOARD_HISTORY_TRAINERS: "DASHBOARD_HISTORY_TRAINERS",
    VIEW_TRAINING: "VIEW_TRAINING",
    VIEW_INDIVIDUAL: "VIEW_INDIVIDUAL",
    SELECT_TRAINING_COURSE: "SELECT_TRAINING_COURSE",
    SELECT_TRAINING_USER: "SELECT_TRAINING_USER",
    SELECT_TRAINING_USER_BY_DOC_NO: "SELECT_TRAINING_USER_BY_DOC_NO",
    //# END REGION OF TRAINING


}

export const FILED_OPTION = {
    ALL: 'all',
    OPTION_LEARNING_RESULT: 'learningResult',
    OPTION_TRAINING_RESULT: 'trainingResult',
    OPTION_EVALUATION_METHOD: 'evaluationMethod',
    OPTION_TRAINING_STATUS: 'trainingStatus',
    OPTION_TYPE_STAFF: 'typeStaff',
    OPTION_TRAINING_GROUP: 'trainingGroup',
    OPTION_TRAINING_TYPE: 'trainingType',
    OPTION_TRAINING_METHOD: 'trainingMethod'
}

export const MASTER_OPTION = {
    EVALUATION_EMPLOYEE: "EVALUATION_EMPLOYEE",
    INDICATOR_HEADER: "INDICATOR_HEADER",
    INDICATOR_STATUS: "INDICATOR_STATUS",
    DEPARTMENT: "DEPARTMENT",
    BOSS_APPROVAL: "BOSS_APPROVAL",
    BOSS_INSPECT: "BOSS_INSPECT",
    YEAR: "YEAR",
    MONTH: "MONTH",
    EMPLOYMENT_TYPE: "EMPLOYMENT_TYPE",
    INDICATOR_FACTORY: "INDICATOR_FACTORY",
    DEPARTMENT_FACTORY: "DEPARTMENT_FACTORY",
    FLOWHRM_FACTORY: "FLOWHRM_FACTORY",
    VIEW_FACTORY: "VIEW_FACTORY",
    SUBDEP_FACTORY: "SUBDEP_FACTORY",
    FILTER_FACTORY: "FILTER_FACTORY",
    FILTER_REPORT_FACTORY: "FILTER_REPORT_FACTORY",

    FILTER_DASHBOARD_OT: "FILTER_DASHBOARD_OT",
    FILTER_STATUS_OT: "FILTER_STATUS_OT",
    SUB_DEPARTMENT_FACTORY: "SUB_DEPARTMENT_FACTORY",
    FILTER_SUB_DEP_OT: "FILTER_SUB_DEP_OT",
    FILTER_HRM_FLOW_OT: "FILTER_HRM_FLOW_OT",
    HRM_FLOW_FACTORY: "HRM_FLOW_FACTORY",
    FILTER_WORK_SHIFT: "FILTER_WORK_SHIFT",
    USER_LOGIN_DATA: "USER_LOGIN_DATA",
    FILTER_DEFAULT_INSERT: "FILTER_DEFAULT_INSERT",

    //# REGION OF EVAC
    OPTION_FREQUENCY_WORK_WITH: "OPTION_FREQUENCY_WORK_WITH",
    OPTION_DEP_EVAC: "OPTION_DEP_EVAC",
    OPTION_FILTER_DEP_EVAC: "OPTION_FILTER_DEP_EVAC",
    OPTION_STATUS_EVAC: "OPTION_STATUS_EVAC",
    OPTION_QUARTER: "OPTION_QUARTER",
    OPTION_YEAR: "OPTION_YEAR",
    OPTION_FORM_EVAC: "OPTION_FORM_EVAC",

    FILTER_STATUS_EVAC: "FILTER_STATUS_EVAC",
    FILTER_DEP_EVAC: "FILTER_DEP_EVAC",
    FILTER_DEP_EVAC_BY_ADMIN: "FILTER_DEP_EVAC_BY_ADMIN",
    FILTER_DEP_EVAC_BY_DEP: "FILTER_DEP_EVAC_BY_DEP",
    //# REGION OF EVAC

    OPTION_DEP: "OPTION_DEP",
    FILTER_DEP: "FILTER_DEP",
    OPTION_SUBDEP: "OPTION_SUBDEP",
    FILTER_SUBDEP: "FILTER_SUBDEP",
    OPTION_SUPERVISORS: "OPTION_SUPERVISORS",
    OPTION_EMPLOYEES: "OPTION_EMPLOYEES",
    OPTION_MENTOR: "OPTION_MENTOR",
    OPTION_USER_CONTRACT: "OPTION_USER_CONTRACT",

    //# REGION OF TRAINING

    RANG_DATE: "RANG_DATE",

    OPTION_USER_OJT: "OPTION_USER_OJT",
    OPTION_USER_TRAINING: "OPTION_USER_TRAINING",
    OPTION_TRAINING: "OPTION_TRAINING",
    OPTION_TRAINING_GROUP: "OPTION_TRAINING_GROUP",
    OPTION_TRAINING_TYPE: "OPTION_TRAINING_TYPE",
    OPTION_TRAINERS: "OPTION_TRAINERS",
    OPTION_TRAINING_COURSE: "OPTION_TRAINING_COURSE",
    OPTION_TYPE_STAFF: "OPTION_TYPE_STAFF",
    OPTION_TRAINING_STATUS: "OPTION_TRAINING_STATUS",
    OPTION_EVALUATION_METHOD: "OPTION_EVALUATION_METHOD",
    OPTION_TRAINING_RESULT: "OPTION_TRAINING_RESULT",
    OPTION_LEARNING_RESULT: "OPTION_LEARNING_RESULT",
    OPTION_TRAINING_METHOD: "OPTION_TRAINING_METHOD"
    //# REGION OF TRAINING



}

export function servicesOptions(_option, _datasource, _filter, _lable) {

    let result = [];
    if (isArray(_datasource) && _datasource.length > 0) {
        if (_filter) {
            result = [{ optionId: ' ', optionValue: ' ', optionLabel: `---- ${_lable || 'ทั้งหมด'} ----` }]
        } else {
            result = [{ optionId: ' ', optionValue: ' ', optionLabel: ' ' }]
        }

        switch (_option) {
            case KEY_OPTION.MOCKUP:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.value,
                        optionValue: each.value,
                        optionLabel: `${each.label || ''} `
                    })))
                break;
            case MASTER_OPTION.BOSS_APPROVAL:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.rowNum,
                        optionValue: each.flowId,
                        optionLabel: `[${each.flowId || ''}] : ${each.flowName || ''} - ${each.departmentName || ''} `
                    })))
                break;
            case MASTER_OPTION.BOSS_INSPECT:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.rowNum,
                        optionValue: `${each.flowId}/${each.departmentNo}`,
                        optionLabel: `[${each.departmentNo || ''}] : ${each.departmentName || ''} `
                    })))
                break;
            case MASTER_OPTION.DEPARTMENT:
                result = result.concat(
                    _datasource.map((each, index) => ({
                        optionId: index,
                        optionValue: each.DepNo,
                        optionLabel: `[${each.DepNo || ''}] : ${each.Department_Name || ''}`
                    })))
                break;
            case MASTER_OPTION.FILTER_STATUS_OT:
                result = result.concat(
                    _datasource.map((each, index) => ({
                        optionId: index,
                        optionValue: each.code,
                        optionLabel: `${each.description || ''}`
                    })))
                break;

            case MASTER_OPTION.INDICATOR_HEADER:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.indicatorHeaderId,
                        optionValue: each.indicatorHeaderId,
                        optionLabel: `${each.indicatorHeaderName}`
                    })))
                break;
            case MASTER_OPTION.EVALUATION_EMPLOYEE:
                // console.log({ _datasource })
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.employeeId,
                        optionValue: each.employeeId,
                        optionLabel: `[${each.employeeId || ''}] : ${each.employeeName || ''} - ${each.departmentName || ''} `,
                        optionDisable: (each.employeeIdHave) ? true : false
                    })))
                break;
            // case MASTER_OPTION.OPTION_QUARTER:
            case MASTER_OPTION.MONTH:
            case MASTER_OPTION.OPTION_YEAR:
            case MASTER_OPTION.YEAR:
            case MASTER_OPTION.INDICATOR_STATUS:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: `${each.optionId}`,
                        optionValue: each.optionId,
                        optionLabel: `${each.optionLabel}`
                    })))
                break;
            case MASTER_OPTION.OPTION_SUPERVISORS:
            case MASTER_OPTION.OPTION_DEP:
            case MASTER_OPTION.OPTION_FILTER_DEP:
            case MASTER_OPTION.OPTION_DEP_EVAC:
            case MASTER_OPTION.OPTION_FILTER_DEP_EVAC:
            case MASTER_OPTION.FILTER_DEP_EVAC:
            case MASTER_OPTION.DEPARTMENT_FACTORY:
                // console.log(_datasource)
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.depNo,
                        optionValue: each.depNo,
                        optionLabel: `${each.depName}`
                    })))
                break;
            case MASTER_OPTION.FILTER_HRM_FLOW_OT:
            case MASTER_OPTION.HRM_FLOW_FACTORY:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.ID_Flow,
                        optionValue: each.ID_Flow,
                        optionLabel: `${each.Flow_Name}`,
                        optionDepNo: `${each.Dept_No}`,
                        optionSubDepNo: `${each.subDepNo}`
                    })))
                break;
            case MASTER_OPTION.FLOWHRM_FACTORY:
            case MASTER_OPTION.VIEW_FACTORY:
                // result = result.concat(
                //     _datasource.map((each) => ({
                //         optionId: each.idFlow,
                //         optionValue: each.idFlow,
                //         optionLabel: `${each.flowName}`
                //     })))
                    result = result.concat(
                        _datasource.map((each) => ({
                            optionId: each.flowId,
                            optionValue: each.flowId,
                            optionLabel: `${each.flowName}`
                        })))

                break;
            case MASTER_OPTION.FILTER_SUBDEP:
            case MASTER_OPTION.SUB_DEPARTMENT_FACTORY:
            case MASTER_OPTION.FILTER_SUB_DEP_OT:
            case MASTER_OPTION.SUBDEP_FACTORY:
                // console.log(_datasource)
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.subDepNo,
                        optionValue: each.subDepNo,
                        optionLabel: `${each.subDepName}`
                    })))
                break;
            case MASTER_OPTION.FILTER_WORK_SHIFT:
            case MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.code,
                        optionValue: each.code,
                        optionLabel: `${each.description}`
                    })))
                break;
            case MASTER_OPTION.OPTION_SUBDEP:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.subDepNo,
                        optionValue: each.subDepNo,
                        optionLabel: `[${each.subDepNo || ''}] : ${each.subDepName || ''}`
                    })))
                break;
            case MASTER_OPTION.OPTION_FORM_EVAC:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.formId,
                        optionValue: each.formId,
                        optionLabel: `[${each.formId || ''}] : ${each.formDescription || ''}`
                    })))
                break;
            case MASTER_OPTION.OPTION_LEARNING_RESULT:
            case MASTER_OPTION.OPTION_TRAINING_RESULT:
            case MASTER_OPTION.OPTION_EVALUATION_METHOD:
            case MASTER_OPTION.OPTION_TRAINING_METHOD:
            case MASTER_OPTION.OPTION_TRAINING_STATUS:
            case MASTER_OPTION.OPTION_TRAINING_TYPE:
            case MASTER_OPTION.OPTION_TYPE_STAFF:
            case KEY_OPTION.OPTION_STATUS:
                // console.log(_datasource)
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.ID_Row,
                        optionValue: each.code,
                        optionLabel: `${each.description || ''}`
                    })))
                break;
            case MASTER_OPTION.OPTION_TRAINING_GROUP:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.ID_Row,
                        optionValue: each.code,
                        optionLabel: `[${each.code}] : ${each.description || ''}`
                    })))
                break;
            case MASTER_OPTION.OPTION_USER_CONTRACT:
            case MASTER_OPTION.OPTION_USER_TRAINING:
            case MASTER_OPTION.OPTION_MENTOR:
                result = result.concat(
                    _datasource.map((each, index) => ({
                        optionId: index,
                        optionValue: each.ID_User,
                        optionLabel: `[${each.ID_User || ''}] : ${each.Name || ''} ${each.NickName ? `- ${each.NickName}` : ''}`
                    })))
                break;
            case MASTER_OPTION.OPTION_TRAINERS:
                result = result.concat(
                    _datasource.map((each, index) => ({
                        optionId: index,
                        optionValue: each.ID_User,
                        optionLabel: `${each.NickName ? `${each.NickName || ''} :` : ''} ${each.Name || ''} - ${each.ID_User}`
                    })))
                break;
            case MASTER_OPTION.OPTION_TRAINING_COURSE:
                result = result.concat(
                    _datasource.map((each, index) => ({
                        optionId: index,
                        optionValue: each.trainingNo,
                        optionLabel: `[${each.trainingNo || ''}] : ${each.trainingCourses || ''}`
                    })))
                break;
            default:
                result = result.concat(
                    _datasource.map((each) => ({
                        optionId: each.optionId,
                        optionValue: each.optionId,
                        optionLabel: `[${each.optionId || ''}] : ${each.optionLabel || ''}`
                    })))
                break;
        }
    } else {
        result = []
    }
    return result
}
