import moment from "moment";
import { FIELD_STEP, MESSAGE_ERROR } from "../..";
import { KEY_TYPE } from "../../../../constants/enums/enumCenter";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  EDUCATION_LEVEL: "ระดับการศึกษา",
  INSTITUTION: "สถานศึกษา",
  MAJOR: "คณะ/สาขา",
  DEGREE: "วุฒิการศึกษา",
  GRADUATION_YEAR: "ปีที่จบการศึกษา",
  GRADE: "เกรดเฉลี่ย",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  EDUCATION_LEVEL: "educationLevel",
  INSTITUTION: "institution",
  MAJOR: "major",
  DEGREE: "degree",
  GRADUATION_YEAR: "graduationYear",
  GRADE: "grade",
};

/* -------------------------------------------------------------------------- */
/*                                PLACEHOLDER                                */
/* -------------------------------------------------------------------------- */

function PLACEHOLDER_WITH_INDEX(_index) {
  //!เปลี่ยนชื่อ Placeholder ที่นี่
  switch (+_index) {
    case 0:
      return "ประถมศึกษา";
    case 1:
      return "มัธยมศึกษา";
    case 2:
      return "วิทยาลัย/พาณิชย์/อาชีวะ";
    case 3:
      return "มหาวิทยาลัย";
    default:
      return "ระดับการศึกษา";
  }
}

/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
  educationLevel: "",
  institution: "",
  major: "",
  degree: "",
  graduationYear: "",
  grade: "",
};

export const FIELD_EDUCATION = FIELD_PAGES;
export const INITIAL_EDUCATION = INITIAL_PAGES;

/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_EDUCATION_VIEW = (props) => {
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   * ? (!+field.fieldKey) => ถ้า key เท่ากับ 1 ถึงจะแสดง
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.LIST_FORM,
          name: FIELD_STEP.COLLAPSE_S31, //education
          removelenght: 4,
          header: (idx) => null,
          initialValues: { ...INITIAL_PAGES },
          disabled: disabledAll,
          content: (field) => [
            {
              span: 4,
              itemprops: {
                name: [field.name, FIELD_PAGES.EDUCATION_LEVEL],
                label: !+field.fieldKey && LABEL_PAGES.EDUCATION_LEVEL, //ระดับการศึกษา
                fieldKey: [field.fieldKey, FIELD_PAGES.EDUCATION_LEVEL],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: +field.fieldKey < 4 || disabledAll,
                placeholder: PLACEHOLDER_WITH_INDEX(field.fieldKey),
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.INSTITUTION],
                label: !+field.fieldKey && LABEL_PAGES.INSTITUTION, //สถานศึกษา
                fieldKey: [field.fieldKey, FIELD_PAGES.INSTITUTION],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.MAJOR],
                label: !+field.fieldKey && LABEL_PAGES.MAJOR, //คณะ/สาขา
                fieldKey: [field.fieldKey, FIELD_PAGES.MAJOR],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 3,
              itemprops: {
                name: [field.name, FIELD_PAGES.DEGREE],
                label: !+field.fieldKey && LABEL_PAGES.DEGREE, //วุฒิการศึกษา
                fieldKey: [field.fieldKey, FIELD_PAGES.DEGREE],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 2,
              itemprops: {
                name: [field.name, FIELD_PAGES.GRADUATION_YEAR],
                label: !+field.fieldKey && LABEL_PAGES.GRADUATION_YEAR, //ปีที่จบการศึกษา
                fieldKey: [field.fieldKey, FIELD_PAGES.GRADUATION_YEAR],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                picker: "year",
                disabledDate: (current) => current && current > moment(),
                placeholder: "",
                defaultdate: "",
                disabled: disabledAll,
              },
            },
            {
              span: 2,
              itemprops: {
                name: [field.name, FIELD_PAGES.GRADE],
                label: !+field.fieldKey && LABEL_PAGES.GRADE, //เกรดเฉลี่ย
                fieldKey: [field.fieldKey, FIELD_PAGES.GRADE],
                rules: [{ required: false, message: MESSAGE_ERROR.DECIMAL }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                max: 4, //ค่าสูงสุด
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};
