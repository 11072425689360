import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";
const DEFINE = {
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    startDate: {
        label: "วันที่ขอโอที",
        label_2: "วันที่มีผล",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
    statusComp: {
        label: "สถานะการขอโอที",
        name: "statusComp",
    },
}

export const ComponentReportOT = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { userId, depNo, subDepNo, startDate, toDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentManageOT = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { userId, subDepNo, startDate, toDate, statusComp } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
                // {
                //     breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                //     component: {
                //         type: COMPONENT_TYPE.SELECT,
                //         itemProps: {
                //             name: depNo.name,
                //             label: depNo.label,
                //             rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                //         },
                //         componentProps: {
                //             style: { width: "100%" },
                //             placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                //             disabled: isDisable[depNo.name] || false,
                //         },
                //         fnChange: (value) => handleChange(depNo.name, value),
                //         dropdown: options[depNo.name],
                //     },
                // },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 16, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusComp.name,
                            label: statusComp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusComp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusComp.label),
                            disabled: isDisable[statusComp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusComp.name, value),
                        dropdown: options[statusComp.name],
                    },
                },
            ], // column
        }, // Row
    ]
}