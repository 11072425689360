import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import { FormGroup, Label, Input } from "reactstrap";

const TextInput = ({
  type = "text",
  name = "",
  label = "",
  onChange,
  styleLabel,
  style,
  value,
  ...rest
}) => {
  return (
    <FormGroup style={style}>
      {label ? <Label style={styleLabel}>{label}</Label> : null}
      <Input
        type={type}
        name={name}
        label={label}
        value={value || ""}
        onChange={(e) => onChange(name, e.target.value)}
        {...rest}
      />
    </FormGroup>
  );
};

export default TextInput;
