import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

const DEFINE = {
	depNo: {
		label: "แผนก",
		name: "depNo",
	},
	incomeGroup: {
		label: "กลุ่มรายได้",
		name: "incomeGroup",
	},
	startDateJob: {
		label: "วันที่เริ่มงาน",
		name: "startDateJob",
	},
	endDateJob: {
		label: "ถึงวันที่",
		name: "endDateJob",
	},
	statusInstallment: {
		label: "สถานะพนักงานตามงวด",
		name: "statusInstallment",
	},
}

export const ComponentInsertNewSaleEmployee = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
}) => {
	const { depNo, incomeGroup, startDateJob, endDateJob, statusInstallment } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: depNo.name,
							label: depNo.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(depNo.label),
							disabled: isDisable[depNo.name] || false,
						},
						fnChange: (value) => handleChange(depNo.name, value),
						dropdown: options.depNo,
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: incomeGroup.name,
							label: incomeGroup.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(incomeGroup.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(incomeGroup.label),
							disabled: isDisable[incomeGroup.name] || false,
						},
						fnChange: (value) => handleChange(incomeGroup.name, value),
						dropdown: options.optionIncomeGroup,
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDateJob.name,
							label: startDateJob.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[startDateJob.name] || false,
						},
						fnChange: (date, dateString) => handleChange(startDateJob.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDateJob.name,
							label: endDateJob.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[endDateJob.name] || false,
						},
						fnChange: (date, dateString) => handleChange(endDateJob.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: statusInstallment.name,
							label: statusInstallment.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusInstallment.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							mode: "multiple",
							placeholder: ERROR_MESSAGE.SELECT(statusInstallment.label),
							disabled: isDisable[statusInstallment.name] || false,
						},
						fnChange: (value) => handleChange(statusInstallment.name, value),
						dropdown: options.optionInstallmentSaleStatus,
					},
				},
			], // column
		}, // Row
	]

}
