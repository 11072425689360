import moment from 'moment'
import { SetDate } from '../../../common/setDate'
import { TYPE } from '../../../moduleKPIs/common/inputs'
import { MASTER_OPTION } from '../../../moduleKPIs/services/servicesOptions'

export const MANAGE_TRAINING = ({ update, view, permission, options, defaultValue }) => {
    switch (permission) {
        default:
            let data = [
                [{
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'ชื่อผู้รับการอบรม',
                    required: false,
                    message: 'กรุณากรอก ชื่อผู้รับการอบรม',
                    inputOption: {
                        fieldname: 'fullname',
                        // plainOptions: options[MASTER_OPTION.OPTION_USER_TRAINING],
                        defaultValue: defaultValue.fullname,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'รหัสผู้รับการอบรม',
                    required: false,
                    message: 'กรุณากรอก รหัสผู้รับการอบรม',
                    inputOption: {
                        fieldname: 'userId',
                        // plainOptions: options[MASTER_OPTION.OPTION_USER_TRAINING],
                        defaultValue: defaultValue.userId,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'วันที่เริ่มงาน',
                    required: false,
                    message: 'กรุณากรอก วันที่เริ่มงาน',
                    inputOption: {
                        fieldname: 'startDate',
                        // plainOptions: options[MASTER_OPTION.OPTION_USER_TRAINING],
                        defaultValue: defaultValue.startDate,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'หัวหน้าลำดับที่ 1',
                    required: false,
                    message: 'กรุณากรอก หัวหน้าลำดับที่ 1',
                    inputOption: {
                        fieldname: 'approverName',
                        // plainOptions: options[MASTER_OPTION.OPTION_SUPERVISORS],
                        defaultValue: defaultValue.approverName,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'ตำแหน่ง',
                    required: false,
                    message: 'กรุณากรอก ตำแหน่ง',
                    inputOption: {
                        fieldname: 'positionName',
                        defaultValue: defaultValue.positionName,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'แผนกย่อย',
                    required: false,
                    message: 'กรุณากรอก แผนกย่อย',
                    inputOption: {
                        fieldname: 'subDepName',
                        // plainOptions: options[MASTER_OPTION.OPTION_SUBDEP],
                        defaultValue: defaultValue.subDepName,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'เงื่อนไขการ OJT',
                    required: false,
                    value: defaultValue.typeStaff === 'new' ? 'พนักงานใหม่' : 'ย้ายตำแหน่ง',
                    message: 'กรุณากรอก การปรับตำแหน่ง',
                    inputOption: {
                        fieldname: 'typeStaff',
                        plainOptions: options[MASTER_OPTION.OPTION_TYPE_STAFF],
                        defaultValue: defaultValue.typeStaff === 'new' ? 'พนักงานใหม่' : 'ย้ายตำแหน่ง',
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: (view) ? TYPE.TEXT : TYPE.SELECT,
                    header: 'พี่เลี้ยงที่ได้รับมอบหมาย',
                    required: (view) ? false : true,
                    value: defaultValue.mentorName,
                    message: 'กรุณากรอก ผู้เทรนนิ่ง',
                    inputOption: {
                        fieldname: 'mentorCode',
                        plainOptions: options[MASTER_OPTION.OPTION_MENTOR],
                        defaultValue: (update) ? defaultValue.mentorCode : '',
                        disabled: false,
                        placeholder: ''
                    }
                }]
            ]

            if (view) {
                return [...data, [{
                    type: TYPE.DIVIDER,
                }], [{
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'ระยะเวลาเริ่มต้น - สิ้นสุด',
                    value: `${defaultValue.durationTraining}`, // (view) ?  `${defaultValue.durationTraining}` : "-"
                    required: false,
                    message: '',
                    inputOption: {
                        fieldname: 'durationTraining',
                        plainOptions: [],
                        defaultValue: '',// (view) ? `${defaultValue.startDate} - ${defaultValue.endDate}` : '-',
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'จำนวนการฝึกอบรมทั้งหมด (วัน)',
                    value: defaultValue.totalTraining,
                    required: false,
                    message: '',
                    inputOption: {
                        fieldname: 'totalTraining',
                        plainOptions: [],
                        defaultValue: '',//(view) ? defaultValue.totalTraining : 0,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'คะแนนเฉลี่ยรวม OJT ',
                    required: false,
                    message: '',
                    value: defaultValue.avgTrainingScore,
                    inputOption: {
                        fieldname: 'avgTrainingScore',
                        plainOptions: [],
                        defaultValue: defaultValue.avgTrainingScore,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'ผลการประเมิน',
                    value: defaultValue.resultTraining === 'pass' ? 'ผ่านการประเมิน' : 'ไม่ผ่านการประเมิน',
                    required: false,
                    message: '',
                    inputOption: {
                        fieldname: 'resultTraining',
                        plainOptions: [],
                        defaultValue: defaultValue.resultTraining,
                        disabled: false,
                        placeholder: ''
                    }
                }], [{
                    type: TYPE.DIVIDER,
                }]]
            } else {
                return [...data, [{
                    type: TYPE.DIVIDER,
                }], [{
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'คะแนนเฉลี่ยรวม OJT ',
                    required: false,
                    message: '',
                    value: defaultValue.avgTrainingScore,
                    inputOption: {
                        fieldname: 'avgTrainingScore',
                        plainOptions: [],
                        defaultValue: defaultValue.avgTrainingScore,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.TEXT,
                    header: 'ผลการประเมิน',
                    value: defaultValue.resultTraining === 'pass' ? 'ผ่านการประเมิน' : 'ไม่ผ่านการประเมิน',
                    required: false,
                    message: '',
                    inputOption: {
                        fieldname: 'resultTraining',
                        plainOptions: [],
                        defaultValue: defaultValue.resultTraining,
                        disabled: false,
                        placeholder: ''
                    }
                }, {
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'Time Attendance',
                    required: false,
                    value: defaultValue.timeAttendance,
                    message: '',
                    inputOption: {
                        fieldname: 'timeAttendance',
                        plainOptions: options[MASTER_OPTION.OPTION_TRAINING_RESULT],
                        defaultValue: (update) ? defaultValue.timeAttendance : '',
                        disabled: false,
                        placeholder: ''
                    }
                }], [{
                    type: TYPE.DIVIDER,
                }]]
            }
    }
}
