import React from "react";
import {
  filterSearchColumn,
  useSorterTable,
} from "../../../functions/UseFunction";

export const ColumnReportBonus = [
  {
    title: "รหัสพนักงาน",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "fullname",
    key: "fullname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "nickname",
    key: "nickname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("nickname", "ชื่อพนักงาน"),
  },
  {
    title: "สถานะพนักงาน",
    dataIndex: "statusWorkerDes",
    key: "statusWorkerDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
  },
  {
    title: "บริษัท",
    dataIndex: "Company",
    key: "Company",
    align: "left",
    width: 150,
    ...filterSearchColumn("Company", "บริษัท"),
  },
  {
    title: "สถานที่",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 150,
    ...filterSearchColumn("location", "สถานที่"),
  },
  // {
  //     title: "รหัสแผนก",
  //     dataIndex: "depNo",
  //     key: "depNo",
  //     align: "left",
  //     width: 100,
  //     ...filterSearchColumn("depNo", "รหัสแผนก"),
  // },
  {
    title: "แผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 200,
    ...filterSearchColumn("depName", "แผนก"),
  },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 300,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },
  {
    title: "ตำแหน่งงาน",
    dataIndex: "positionNo",
    key: "positionNo",
    align: "left",
    width: 300,
    ...filterSearchColumn("positionNo", "ตำแหน่งงาน"),
  },
  {
    title: "กลุ่มเงินเดือน",
    dataIndex: "payrollGroupDes",
    key: "payrollGroupDes",
    align: "left",
    width: 300,
    ...filterSearchColumn("payrollGroupDes", "กลุ่มเงินเดือน"),
  },
  {
    title: "ผู้ประเมิน 1",
    dataIndex: "approverName1",
    key: "approverName1",
    align: "left",
    width: 200,
    ...filterSearchColumn("approverName1", "ผู้ประเมิน 1"),
  },
  {
    title: "ผู้ประเมิน 2",
    dataIndex: "approverName2",
    key: "approverName2",
    align: "left",
    width: 200,
    ...filterSearchColumn("approverName2", "ผู้ประเมิน 2"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "startDateJob",
    key: "startDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "วันผ่านทดลองงาน",
    dataIndex: "probationDateEnd",
    key: "probationDateEnd",
    align: "right",
    width: 150,
    ...filterSearchColumn("probationDateEnd", "วันผ่านทดลองงาน"),
  },
  {
    title: "อายุงาน (ปี.เดือน)",
    dataIndex: "workYears",
    key: "workYears",
    align: "right",
    width: 150,
    //...filterSearchColumn("workYears", "อายุงาน"),
    ...useSorterTable("workYears", "number"),
  },
  {
    title: "PG",
    dataIndex: "PG",
    key: "PG",
    align: "right",
    width: 100,
    ...useSorterTable("PG", "number"),
  },
  {
    title: "JG",
    dataIndex: "JG",
    key: "JG",
    align: "right",
    width: 100,
    ...useSorterTable("JG", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>Sick เกิน 10D</span> <br />
          <span>(วันละ -1%)</span>
        </div>
      );
    },
    dataIndex: "percentBonusSick",
    key: "percentBonusSick",
    align: "right",
    width: 150,
    ...useSorterTable("percentBonusSick", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>ขาดงาน</span> <br />
          <span>(วันละ 2%)</span>
        </div>
      );
    },
    dataIndex: "percentBonusAbs",
    key: "percentBonusAbs",
    align: "right",
    width: 150,
    ...useSorterTable("percentBonusAbs", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>ลาอื่น ๆ</span> <br />
          <span>(เกิน 10D -10%)</span>
        </div>
      );
    },
    dataIndex: "percentBonusOtherLeave",
    key: "percentBonusOtherLeave",
    align: "right",
    width: 150,
    ...useSorterTable("percentBonusOtherLeave", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>ลาไม่รับค่าจ้าง</span> <br />
          <span>(วันละ -1%)</span>
        </div>
      );
    },
    dataIndex: "percentBonusNotPay",
    key: "percentBonusNotPay",
    align: "right",
    width: 150,
    ...useSorterTable("percentBonusNotPay", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>เตือนร้ายแรง</span> <br />
          <span>(ใบละ -25%)</span>
        </div>
      );
    },
    dataIndex: "effectBonus",
    key: "effectBonus",
    align: "right",
    width: 150,
    ...useSorterTable("effectBonus", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>เตือนวาจา</span> <br />
          <span>(ใบละ -10%)</span>
        </div>
      );
    },
    dataIndex: "effectBonus",
    key: "effectBonus",
    align: "right",
    width: 150,
    ...useSorterTable("effectBonus", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>ใบเตือนสาย</span> <br />
          <span>(ออฟฟิศ)</span>
        </div>
      );
    },
    dataIndex: "effectBonus",
    key: "effectBonus",
    align: "right",
    width: 150,
    ...useSorterTable("effectBonus", "number"),
  },
  {
    title: () => {
      return (
        <div>
          <span>%เพิ่ม/ลด</span> <br />
          <span>(สายขาดลา ใบเตือน)</span>
        </div>
      );
    },
    dataIndex: "effectBonus",
    key: "effectBonus",
    align: "right",
    width: 150,
    ...useSorterTable("effectBonus", "number"),
  },
];
