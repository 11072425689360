

export const filterDashboardReportNewEmployee = ({
	handlechange,
	options,
	txtOption,
	initialFilter,
	onClick
}) => [
		[
			{
				size: 2,
				name: "location",
				label: "Location",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionLocation],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "DepNo",
				label: "แผนก/กลุ่ม",
				comp: "SelectInput",
				options: options.optionSegment,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},


			{
				size: 3,
				name: "statusMainCode",
				label: "สถานะการประเมิน",
				comp: "SelectInput",
				options: options.optionSumProbation,
				valueField: { value: "value", label: "label" },
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "StartDateJob",
				label: "วันที่เริ่มทำงาน",
				comp: "DateInput",
				placeholder: "เลือกวันที่เริ่มทำงาน",
				selected: initialFilter.StartDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "ToDateJob",
				label: "ถึงวันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.ToDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},

		],
		[
			{
				size: 2,
				name: "userSeaching",
				label: "รหัส,ชื่อ,ชื่อเล่นพนักงาน",
				comp: "TextInput",
				value: txtOption.txtUserSeaching,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "statusProbation",
				label: "ผลการทดลองงาน",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionProbationStatus],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "statusWorker",
				label: "กลุ่ม/สถานะพนง.",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionStatusWorkerCode],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "search",
				label: "SEARCH",
				comp: "ButtonTheme",
				func: "search",
				className: "text-center cs_btn",
				onClick
			}
		]
	]

export const filterDashboardReportTrackProbation = ({
	handlechange,
	options,
	txtOption,
	initialFilter,
	onClick
}) => [
		[
			{
				size: 3,
				name: "location",
				label: "Location",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionLocation],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "depNo",
				label: "แผนก/กลุ่ม",
				comp: "SelectInput",
				options: options.optionSegment,
				valueField: txtOption.txtOption,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "StartDateJob",
				label: "วันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.StartDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "toDateJob",
				label: "ถึงวันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.toDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "search",
				label: "SEARCH",
				comp: "ButtonTheme",
				func: "search",
				className: "text-center cs_btn",
				onClick
			}
		],
		[
			{
				size: 3,
				name: "userSeaching",
				label: "รหัส,ชื่อ,ชื่อเล่นพนักงาน",
				comp: "TextInput",
				value: txtOption.txtUserSeaching,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "statusProbation",
				label: "ผลการทดลองงาน",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionProbationStatus],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 4,
				name: "statusWorker",
				label: "กลุ่ม/สถานะพนง.",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionStatusWorkerCode],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
		]
	];

export const filterDashboardReportBenefit = ({
	handlechange,
	options,
	txtOption,
	initialFilter,
	onClick
}) => [
		[
			{
				size: 3,
				name: "location",
				label: "Location",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionLocation],
				// valueField: txtOptionLocation,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "depNo",
				label: "แผนก/กลุ่ม",
				comp: "SelectInput",
				options: options.optionSegment,
				// valueField: txtOption,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "StartDateJob",
				label: "วันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.StartDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "toDateJob",
				label: "ถึงวันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.toDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "search",
				label: "SEARCH",
				comp: "ButtonTheme",
				func: "search",
				className: "text-center cs_btn",
				onClick
			}
		],
		[
			{
				size: 3,
				name: "userSeaching",
				label: "รหัส,ชื่อ,ชื่อเล่นพนักงาน",
				comp: "TextInput",
				value: txtOption.txtUserSeaching,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "statusProbation",
				label: "ผลการทดลองงาน",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionProbationStatus],
				// valueField: txtOptionStatusProbation,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 4,
				name: "statusWorker",
				label: "กลุ่ม/สถานะพนง.",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionStatusWorkerCode],
				// valueField: txtOptionStatusWorkerCode,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
		]
	];



export const filterDashboardReportBenefitOut = ({
	handlechange,
	options,
	txtOption,
	initialFilter,
	onClick
}) => [
		[
			{
				size: 3,
				name: "location",
				label: "Location",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionLocation],
				// valueField: txtOptionLocation,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "depNo",
				label: "แผนก/กลุ่ม",
				comp: "SelectInput",
				options: options.optionSegment,
				valueField: txtOption.txtOption,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "StartDateJob",
				label: "วันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.StartDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "toDateJob",
				label: "ถึงวันที่",
				comp: "DateInput",
				placeholder: "เลือกวันที่",
				selected: initialFilter.toDateJob,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 2,
				name: "search",
				label: "SEARCH",
				comp: "ButtonTheme",
				func: "search",
				className: "text-center cs_btn",
				onClick: () => onClick("search")
			}
		],
		[
			{
				size: 3,
				name: "userSeaching",
				label: "รหัส,ชื่อ,ชื่อเล่นพนักงาน",
				comp: "TextInput",
				value: txtOption.txtUserSeaching,
				onChange: (fieldname, value) => handlechange(fieldname, value)
			},
			{
				size: 3,
				name: "statusProbation",
				label: "ผลการทดลองงาน",
				comp: "SelectInput",
				options: [{ label: '-----ทั้งหมด-----', value: '' }, ...options.optionProbationStatus],
				onChange: (fieldname, value) => handlechange(fieldname, value)
			}
		]
	];