import React from "react";

export const useMakeQueryString = (object) => {
  const obj = JSON.parse(JSON.stringify(object));
  let arr = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && obj[key] !== "") {
      const element = obj[key];
      arr.push(`${key}=${element}`);
    }
  }
  return arr.join("&");
};

export const useFixedQueryString = (object, array) => {
  const obj = JSON.parse(JSON.stringify(object, array));
  let arr = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && obj[key] !== "") {
      const element = obj[key];
      arr.push(`${key}=${element}`);
    }
  }
  return arr.join("&");
};
