import React, { useState, useEffect, useContext, useRef } from "react";
import { browserHistory } from "react-router";
import { LayoutTable } from "../../moduleKPIs/common/layouts";
import { TABLE_REGISTER_OT } from "../commonsOT/structuresOT/tableOT";
import { PATHNAME } from "../../constants/enums/pathname";
import { FIELD_BUTTON, TEXT_BUTTON } from "../../constants/buttons/index";
import { ButtonTheme } from "../../moduleKPIs/common/buttons";
import { Row, Col, Checkbox, Modal, Spin } from "antd";
import LayoutInput from "../commonsOT/layoutsOT/layoutInput";
import { MasterOTContext } from "../servicesOT/masterOT/masterOTProvider";
import {
  MASTER_OPTION,
  REPORT_OPTION,
} from "../../moduleKPIs/services/servicesOptions";
import { ManageOTContext } from "../servicesOT/manageOT/manageOTProvider";
import { path, equals, update, isEmpty, isNil, remove } from "ramda";
import { APIInsertRequestOT } from "../servicesOT/manageOT/manageOTConnect";
import { calDateDiff } from "../../moduleKPIs/common/functions";
import { colors } from "../../themes";
import { MANAGE_REQUEST_OT } from "../commonsOT/structuresOT/manageOT";
import moment from "moment";
import { CompSignature } from "../../moduleKPIs/components/signature/compSignature";
import { checkArrayEmpty } from "../../constants/functions";
import {
  APILineNotify,
  KEY_LOGS,
} from "../../functions/notifications/lineNotify";
import { useText } from "../../screens/conponents/typography/Typography";

//! ถ้า รีเฟชร ข้อมูลจะใไห้สร้างใหม่

let initial = {
  employeeLists: [],
  selectedRowKeys: [],
  subDepNo: null,
  refFlow: null,
  startDate: moment(new Date()).format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  activeDate: moment(new Date()).format("YYYY-MM-DD"),
  startTime: null,
  endTime: null,
  totalHours: null,
  workShift: null,
  workload: null,
  remark: null,
};
const InsertOT = () => {
  let refSignature = useRef(null);
  const { masterOTState, getMaster, changeSignature } =
    useContext(MasterOTContext);
  const {
    masterUser: { userLists },
  } = masterOTState;
  const { userLogin } = useContext(ManageOTContext);
  const [statusCF, setStatusCF] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginData =
    window.localStorage.getItem("loginData") &&
    JSON.parse(window.localStorage.getItem("loginData"));
  const [initialData, setInitialData] = useState({
    ...initial,
    subDepNo: path(["subDepNo"], loginData) || "",
  });

  useEffect(() => {
    console.log("file: insertOT.jsx:67 ~ userLists", userLists);
  }, [userLists]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        await Promise.all([
          getMaster({
            fieldname: MASTER_OPTION.FILTER_INSERT_OT,
            payload: {
              userId: userLogin.userId,
              userPermission: userLogin.userPermission,
              subDepNo: loginData && loginData.subDepNo,
            },
          }),
          // ต้องเลือก แผนกย่อยแล้วค่อย ดึงข้อมูล
          // getMaster({ //! คอมเม้นออก เพราะตัวนี้ทำให้ข้อมูลแสงก่อนผู้ใช้เลือกข้อมูลทุกตัว
          //   fieldname: MASTER_OPTION.USER_LOGIN_DATA,
          //   payload: { userId: userLogin.userId },
          // }),
        ]);
      } catch (error) {
        setLoading(false);
        //! line Noti
        APILineNotify({
          key: KEY_LOGS.ERROR_CATCH,
          message: `${error.message}`,
          props: {
            title: `⛔️ OT FAC `,
            userLogin: `${userLogin.userId}`,
            payload: "",
          },
        });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  async function onSubmit() {
    try {
      setLoading(true);
      if (initialData) {
        let sumEmployeeList = [...initialData.employeeLists, ...userLists];
        let newEmployeeList = sumEmployeeList.reduce((acc, cur) => {
          const dataIndex = acc.findIndex((el) =>
            equals(el.userId, cur.userId)
          );
          if (dataIndex !== -1) {
            acc[dataIndex] = { ...acc[dataIndex] };
            return acc;
          } else {
            return [
              ...acc,
              {
                userId: cur.userId,
                name: cur.name,
                nickname: cur.nickname,
                positionNo: cur.positionNo,
                positionName: cur.positionName,
                depNo: cur.depNo,
                subDepNo: initialData.subDepNo,
                signature: cur.signature,
                createBy: userLogin.userId,
                createPermission: userLogin.userPermission,
              },
            ];
          }
        }, []);
        let newDataLine = initialData.selectedRowKeys.map(
          (val) => newEmployeeList.filter((each) => val === each.userId)[0]
        );

        let payload = {
          dataHeader: {
            subDepNo: initialData.subDepNo || "",
            refFlow: initialData.refFlow || "",
            startDateTime: `${initialData.startDate || ""} ${
              initialData.startTime || ""
            }`,
            endDateTime: `${initialData.endDate || ""} ${
              initialData.endTime || ""
            }`,
            activeDate: initialData.activeDate || "",
            totalHours: initialData.totalHours || "",
            workShift: "", //initialData.workShift || "",
            workload: initialData.workload || "",
            remark: initialData.remark || "",
            sendAP3: initialData.totalHours > 2 ? "1" : "0", //ถ้าtotalTime มากกว่า 2 ชั่วโมง  ให้ sendAP3 === 1 : 0
            //    "sendAP3": "0",  //! ไม่มีอนุมัติลำดับ 3 แล้ว
            statusCF: statusCF ? "1" : "0", //ถ้าเป็นแบบร่าง ส่ง 0 ถ้า confirm ส่ง 1
            createBy: userLogin.userId || "",
            createPermission: userLogin.userPermission,
          },
          dataLine: newDataLine, // initialData.employeeLists
        };
        const {
          subDepNo,
          refFlow,
          startDate,
          endDate,
          startTime,
          endTime,
          workload,
          remark,
          activeDate,
        } = initialData;
        // console.log({ payload })
        if (
          moment(startDate, "YYYY-MM-DD").isAfter(moment(endDate, "YYYY-MM-DD"))
        ) {
          Modal.warning({
            title: "วันที่เริ่มต้องน้อยกว่าวันที่จบ",
          });
          return;
        }
        if (
          !isNil(subDepNo) &&
          !isEmpty(subDepNo) &&
          !isNil(refFlow) &&
          !isEmpty(refFlow) &&
          !isNil(startDate) &&
          !isEmpty(startDate) &&
          !isNil(endDate) &&
          !isEmpty(endDate) &&
          !isNil(activeDate) &&
          !isEmpty(activeDate) &&
          !isNil(startTime) &&
          !isEmpty(startTime) &&
          !isNil(endTime) &&
          !isEmpty(endTime) &&
          // (!isNil(workShift) && !isEmpty(workShift)) &&
          !isNil(workload) &&
          !isEmpty(workload) &&
          !isNil(remark) &&
          !isEmpty(remark) &&
          payload.dataLine.length > 0
        ) {
          await APIInsertRequestOT(payload, (res) => {
            //console.log('INSERT', res)
            if (res.success) {
              Modal.success({
                title: "บันทึกสำเร็จ",
                onOk: async () => {
                  await browserHistory.push(PATHNAME.DASHBOARD_OT_FACTORY);
                  await window.localStorage.clear();
                },
              });
            }
          });
        } else {
          Modal.error({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      //! line Noti
      APILineNotify({
        key: KEY_LOGS.ERROR_CATCH,
        message: `${error.message}`,
        props: {
          title: `⛔️ OT FAC `,
          userLogin: `${userLogin.userId}`,
          payload: "",
        },
      });
    } finally {
      setLoading(false);
    }
  }
  async function getUserData(value) {
    try {
      setLoading(true);
      await getMaster({
        fieldname: REPORT_OPTION.USER_LOGIN_DATA,
        payload: {
          userId: "",
          subDep:
            path(["subDepNo"], value) || path(["subDepNo"], initialData) || "",
          startDate:
            path(["startDate"], value) ||
            path(["startDate"], initialData) ||
            "",
          endDate:
            path(["endDate"], value) || path(["endDate"], initialData) || "",
          startTime:
            path(["startTime"], value) ||
            path(["startTime"], initialData) ||
            "",
          endTime:
            path(["endTime"], value) || path(["endTime"], initialData) || "",
        },
      });
    } catch (error) {
      setLoading(false);
      //! line Noti
      APILineNotify({
        key: KEY_LOGS.ERROR_CATCH,
        message: `${error.message}`,
        props: {
          title: `⛔️ OT FAC `,
          userLogin: `${userLogin.userId}`,
          payload: "",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function onChange({ fieldname, value, index = null }) {
    try {
      let dataIndex = userLists.findIndex((el) => el.userId === value.userId);
      switch (fieldname) {
        case "refFlow":
          setInitialData({ ...initialData, [fieldname]: value[0] });
          break;
        case "remark":
        case "workload":
        case "selectedRowKeys":
          setInitialData({ ...initialData, [fieldname]: value });
          break;
        case "activeDate":
          setInitialData({ ...initialData, [fieldname]: value.dateStrings });
          break;
        case "signatureLists":
          changeSignature(
            update(dataIndex, { ...userLists[dataIndex], ...value }, userLists)
          );
          setInitialData({
            ...initialData,
            employeeLists: initialData.employeeLists.concat([{ ...value }]),
          });
          break;
        case "signatureReset":
          changeSignature(
            update(dataIndex, { ...userLists[dataIndex], ...value }, userLists)
          );
          setInitialData({
            ...initialData,
            employeeLists: remove(index, 1, initialData.employeeLists),
          });
          break;
        case "subDepNo":
          let _subDepNo = value[0];
          setInitialData({ ...initialData, [fieldname]: _subDepNo });
          await getUserData({ subDepNo: _subDepNo });
          break;
        case "startDate":
          setInitialData({
            ...initialData,
            [fieldname]: value.dateStrings,
            activeDate: value.dateStrings,
            totalHours: calDateDiff({
              ...initialData,
              [fieldname]: value.dateStrings,
            }),
          });
          await getUserData({
            startDate: value.dateStrings,
            endDate: initialData.endDate,
          });

          break;
        case "endDate":
          setInitialData({
            ...initialData,
            [fieldname]: value.dateStrings,
            totalHours: calDateDiff({
              ...initialData,
              [fieldname]: value.dateStrings,
            }),
          });
          await getUserData({
            startDate: initialData.startDate,
            endDate: value.dateStrings,
          });
          break;
        case "startTime":
          setInitialData({
            ...initialData,
            [fieldname]: value,
            totalHours: calDateDiff({ ...initialData, [fieldname]: value }),
          });
          await getUserData({
            startTime: value,
            endTime: initialData.endTime,
          });
          break;
        case "endTime":
          // console.log({ fieldname, value })
          setInitialData({
            ...initialData,
            [fieldname]: value,
            totalHours: calDateDiff({ ...initialData, [fieldname]: value }),
          });
          await getUserData({
            startTime: initialData.startTime,
            endTime: value,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      setLoading(false);
      //! line Noti
      APILineNotify({
        key: KEY_LOGS.ERROR_CATCH,
        message: `${error.message}`,
        props: {
          title: `⛔️ OT FAC `,
          userLogin: `${userLogin.userId}`,
          payload: "",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setLoading(true);
      onChange({ fieldname: "selectedRowKeys", value: selectedRowKeys });
      setLoading(false);
    },
  };
  let sumEmployeeList = [...initialData.employeeLists, ...userLists];
  let newEmployeeList = sumEmployeeList.reduce((acc, cur) => {
    const dataIndex = acc.findIndex((el) => equals(el.userId, cur.userId));
    if (dataIndex !== -1) {
      acc[dataIndex] = { ...acc[dataIndex] };
      return acc;
    } else {
      return [
        ...acc,
        {
          userId: cur.userId,
          name: cur.name,
          nickname: cur.nickname,
          positionNo: cur.positionNo,
          positionName: cur.positionName,
          depNo: cur.depNo,
          subDepNo: initialData.subDepNo,
          signature: cur.signature, //! Remove Signature
          createBy: userLogin.userId,
        },
      ];
    }
  }, []);
  let newDataLine = initialData.selectedRowKeys.map(
    (val) => newEmployeeList.filter((each) => val === each.userId)[0]
  );
  // console.log(newDataLine)

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล...">
      <div
        style={{ padding: 20, backgroundColor: colors.white, marginBottom: 20 }}
      >
        <Row gutter={[10, 24]}>
          {MANAGE_REQUEST_OT({
            update: false,
            permisstion: userLogin.userPermissioln,
            options: {
              // hrmFlow: (path(['subDepNo'], userData)) ? servicesOptions(MASTER_OPTION.HRM_FLOW_FACTORY, reportOTState.hrmFlow) : masterOTState[`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`],
              hrmFlow: masterOTState
                ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]
                : [],
              subDepNo: masterOTState
                ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]
                : [],
              // workShift: (masterOTState) ? masterOTState[`OPTION_${MASTER_OPTION.FILTER_WORK_SHIFT}`] : [] //!Option WorkShift
            },
            defaultValue: {
              subDepNo: path(["subDepNo"], loginData) || "",
              refFlow: path(["refFlow_HRM"], loginData) || "",
            },
            initialData,
          }).map((row) =>
            row.map((each, eachIndex) => {
              return (
                <Col key={eachIndex} span={each.span}>
                  <div style={{ paddingBottom: 5 }}>
                    <span style={{ color: colors.error }}> {"*"} </span>{" "}
                    {each.header}
                  </div>
                  <LayoutInput
                    type={each.type}
                    value={path([each.inputOption.fieldname], initialData)}
                    onChange={onChange}
                    {...each}
                  />
                </Col>
              );
            })
          )}
        </Row>
        <br />
        {useText({
          type: "danger",
          text: "* หมายเหตุ: รายชื่อพนักงานจะแสดงก็ต่อเมื่อระบุข้อมูลข้างต้นครบทุกช่องแล้ว",
        })}
      </div>

      <div style={{ position: "relative" }}>
        <LayoutTable
          bordered
          rowKey="userId"
          scroll={{ x: 800 }}
          dataSource={userLists || []}
          columns={TABLE_REGISTER_OT({
            update: false,
            userLogin: userLogin,
            onChange: onChange,
            selectedRowKeys: initialData.selectedRowKeys,
            // employeeLists: initialData.employeeLists
            actionButton: async ({ fieldBtn, payload }) => {
              try {
                //console.log({ fieldBtn })
                switch (fieldBtn) {
                  case FIELD_BUTTON.INSERT_SIGNATURE:
                    setLoading(true);
                    Modal.confirm({
                      icon: null,
                      title: (
                        <div
                          style={{ position: "absolute", left: 15, bottom: 10 }}
                        >
                          <ButtonTheme
                            buttonText={TEXT_BUTTON.RESET}
                            onClick={() => refSignature.clear()}
                          />
                        </div>
                      ),
                      okText: TEXT_BUTTON.CONFIRM,
                      cancelText: TEXT_BUTTON.CANCEL,
                      onOk: () =>
                        onChange({
                          fieldname: "signatureLists",
                          value: update
                            ? {
                                ...payload.record,
                                signature: refSignature
                                  .getTrimmedCanvas()
                                  .toDataURL("image/png"),
                                newSignature: "Y",
                              }
                            : {
                                ...payload.record,
                                signature: refSignature
                                  .getTrimmedCanvas()
                                  .toDataURL("image/png"),
                              },
                          index: payload.index,
                        }),

                      content: (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItem: "center",
                          }}
                        >
                          <CompSignature
                            ref={(_sigPad) => (refSignature = _sigPad)}
                          />
                        </div>
                      ),
                    });
                    break;
                  case FIELD_BUTTON.RESET_SIGNATURE:
                    onChange({
                      fieldname: "signatureReset",
                      value: {
                        ...payload.record,
                        signature: "",
                      },
                      index: payload.index,
                    });
                    break;
                  default:
                    break;
                }
              } catch (error) {
                //console.log({ error })
                setLoading(false);
              } finally {
                setLoading(false);
              }
            },
          })}
          pagination={{
            showTotal: (total) => `ทั้งหมด ${total} รายการ`,
            defaultPageSize: 500,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
        <Checkbox
          // disabled={disabledConfirm}
          //disabled={(newDataLine.length > 0 && !checkArrayEmpty(newDataLine, 'signature')) ? false : true}//! Remove Signature
          disabled={newDataLine.length > 0 ? false : true}
          style={{ paddingTop: 10 }}
          checked={statusCF}
          onChange={() => setStatusCF(!statusCF)}
        >
          {
            "ยืนยันการบันทึกและส่งให้หัวหน้างาน (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)"
          }
        </Checkbox>
        <div className="layout-content-btn-confirm">
          <ButtonTheme
            type={statusCF ? "primary" : ""}
            style={{ paddingRight: 10 }}
            buttonText={
              statusCF ? "ยืนยันข้อมูล (แก้ไขไม่ได้)" : "บันทึกแบบร่าง"
            }
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </Spin>
  );
};

export default InsertOT;
