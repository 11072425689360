import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { optionCurrentStatusPeriod, optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_RECHECK_EMPLOYEE } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportCheckEmployees } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { ColumnReportCheckEmployee } from "../../constants/Reports/Columns/ColumnReportEmployee";
import { ComponentReportCheckEmployee, DEFAULT_REPORT_CHECK_EMPLOYEE } from "../../constants/Reports/Components/ComponentReportEmployee";

const FormReportCheckEmployees = () => {
    const { location: locationLogin } = GET_UserLogin();
    const [formCheckEmployee] = Form.useForm();
    const [ChangeValue, setChangeValue] = useState({ ...DEFAULT_REPORT_CHECK_EMPLOYEE, location: locationLogin === "FAC" ? "FAC" : undefined });
    const [dataTable, setDataTable] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(false);

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        hrmFlow: useOptionSelect("approval", useFixedQueryString(ChangeValue, ["company", "location"])),
        currentStatusPeriod: optionCurrentStatusPeriod,
    }

    const onFinish = (values) => {
        // console.log(values)
        const { company, location, depNo, subDepNo, hrmFlow, startDate, toDate, startDateSalary, toDateSalary, currentStatusPeriod } = values
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            hrmFlow: hrmFlow || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            startDateSalary: startDateSalary ? startDateSalary.format("YYYY-MM-DD") : "",
            toDateSalary: toDateSalary ? toDateSalary.format("YYYY-MM-DD") : "",
            currentStatusPeriod: currentStatusPeriod || "",
        }
        selectDataRecheckEmployee(objectBody);
    };

    const onReset = () => {
        formCheckEmployee.resetFields();
        setDataTable([]);
        setChangeValue({ ...DEFAULT_REPORT_CHECK_EMPLOYEE, location: locationLogin === "FAC" ? "FAC" : undefined });
    };

    const selectDataRecheckEmployee = async (objectValue) => {
        try {
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_RECHECK_EMPLOYEE, objectValue);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                    fullname: `${row.firstName} ${row.lastName}`,
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Recheck Employee: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const onChangeValue = (name, value) => {
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                if (locationLogin === "FAC") {
                    setUndefined = toUndefined(["depNo", "subDepNo", "hrmFlow"]);
                } else {
                    setUndefined = toUndefined(["location", "depNo", "subDepNo", "hrmFlow"]);
                }
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo", "hrmFlow"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        formCheckEmployee.setFieldsValue(setUndefined);
        setDataTable([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };

    return (
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
            <Form form={formCheckEmployee} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
                <Card
                    title="ค้นหารายงานตรวจสอบจำนวนพนักงาน"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportCheckEmployee({
                            options: optionSearch,
                            handleChange: onChangeValue,
                            isDisable: {
                                location: locationLogin === "FAC" || false,
                            },
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานตรวจสอบจำนวนพนักงาน"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportCheckEmployees,
                                dataset: dataTable,
                                filename: "รายงานตรวจสอบจำนวนพนักงาน",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnReportCheckEmployee}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnReportCheckEmployee) || 2850,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    )
}

export default FormReportCheckEmployees
