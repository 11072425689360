import React, { useState } from 'react'
import { ModalWrapper } from "../../moduleKPIs/common/modal"
import { FIELD_BTN, TEXT_BTN } from "../commonsOT/structuresOT/buttonOT"
import { Button } from 'antd'

const renderButtonWithField = ({ fieldBtn, actionButton, active, buttonText, titleText, content, openModal, showModal, closeModal, }) => {
    // console.log({ fieldBtn })
    switch (fieldBtn) {
        // case FIELD_BTN.INSERT_SIGNATURE:
        //     return (
        //         <ModalWrapper
        //             buttonText={buttonText}
        //             titleText={titleText}
        //             visible={openModal}
        //             content={content}
        //             onSubmit={actionButton}
        //             showModal={showModal}
        //             closeModal={closeModal}
        //         />
        //     )
        // case FIELD_BTN.LINK_REPORT:
        //     return (
        //         <Button type="link" onClick={actionButton(FIELD_BTN.APPROVAL_DASHBOARD_OT)}>
        //             {buttonText}
        //         </Button>
        //     )
        // case FIELD_BTN.UPDATE_REQUEST_OT:
        //     return (
        //         <Button type="primary" onClick={actionButton(FIELD_BTN.APPROVAL_DASHBOARD_OT)}>
        //             {buttonText}
        //         </Button>
        //     )
        // case FIELD_BTN.REMOVE_REQUEST_OT:
        //     return (
        //         <Button type="primary" danger onClick={actionButton(FIELD_BTN.APPROVAL_DASHBOARD_OT)}>
        //             {buttonText}
        //         </Button>
        //     )
        case FIELD_BTN.VIEW_DASHBOARD_OT_APD:
            return (
                <Button onClick={() => actionButton(FIELD_BTN.VIEW_DASHBOARD_OT_APD)} style={{ width: '100%' }}>
                    {TEXT_BTN.VIEW_DASHBOARD_OT}
                </Button>
            )
        case FIELD_BTN.VIEW_DASHBOARD_OT:
            return (
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <Button onClick={() => actionButton(FIELD_BTN.VIEW_DASHBOARD_OT)} style={{ width: '100%' }}>
                        {TEXT_BTN.VIEW_DASHBOARD_OT}
                    </Button>
                    <Button
                        type="primary"
                        style={{ marginLeft: 15 }}
                        danger onClick={() => actionButton(FIELD_BTN.REMOVE_REQUEST_OT)}>
                        {TEXT_BTN.REMOVE_REQUEST_OT}
                    </Button>
                </div>
            )
        case FIELD_BTN.APPROVAL_DASHBOARD_OT:
            return (
                <Button onClick={() => actionButton(FIELD_BTN.APPROVAL_DASHBOARD_OT)} style={{ width: '100%' }}>
                    {TEXT_BTN.APPROVAL_DASHBOARD_OT}
                </Button>
            )
        case FIELD_BTN.DASHBOARD_OT:
            return (
                (active) &&
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <Button type="primary" onClick={() => actionButton(FIELD_BTN.UPDATE_REQUEST_OT)} >
                        {TEXT_BTN.UPDATE_REQUEST_OT}
                    </Button>
                    <Button type="primary" danger onClick={() => actionButton(FIELD_BTN.REMOVE_REQUEST_OT)}>
                        {TEXT_BTN.REMOVE_REQUEST_OT}
                    </Button>
                </div>
            )
        default:
            return null
    }
}

export default renderButtonWithField