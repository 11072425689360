import React, { useEffect, useState } from "react";
import { Spin, Form, Space, Card } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentFilterOutSalesEmpCommission } from "./constants/FilterOutSalesEmpCommission/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnFilterOutSalesEmpCommission } from "./constants/FilterOutSalesEmpCommission/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { browserHistory } from "react-router";
import { useOptionSelect } from "./constants/OptionSelect";
import { useAlert } from "./conponents/modal/CustomAlert";
import { GET, GET_FILTER_OUT_SALES_EMP_COMMISSION, POST, INSERT_FILTER_OUT_SALES_EMP_COMMISSION } from "../service";
import { SetStringToObject } from "../constants/functions";
import Cookies from 'js-cookie';
import encodeString from "../extendFunction/encodeString";
import { useExportExcel } from './services/ExportExcel';
import { Header_FilterOutSalesEmpCommissionReport } from "./constants/HeaderExcel";
import { MdDelete } from 'react-icons/md';
import * as Button from "../constants/buttons";
import { colors } from "../themes";
import { Modal } from 'antd';

const FilterOutSalesEmpCommission = (props) => {
	const [form] = Form.useForm();
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [rowsEdit, setRowsEdit] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [empAllOption, setEmpAllOption] = useState([]);
	const [ChangeValue, setChangeValue] = useState({ empNo: undefined });
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	let optionSearch = {
		empAll: useOptionSelect("empAll"),
	}

	useEffect(() => {
		getFilterOutSalesEmpCommission()
	}, [])

	useEffect(() => {
		if (empAllOption.length == 0) {
			setEmpAllOption(optionSearch.empAll)
		}
	}, [optionSearch.empAll])

	const getFilterOutSalesEmpCommission = async () => {
		try {
			setLoadingScreen(true);
			const response = await GET(GET_FILTER_OUT_SALES_EMP_COMMISSION);
			const { result } = response;
			result.map((v, i) => v.rowNo = i + 1)
			setDataSource(result)
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const onFinish = (values) => {
		if (values && values.empNo.length > 0) {
			for (const dt of dataSource) {
				let index = values.empNo.findIndex(f => f === dt.empNo)
				if (index !== -1) {
					values.empNo.splice(index);
				}
			}
			let selected = (empAllOption.filter(el => {
				return (values.empNo).includes(el.value) && el
			})).map((v, i) => {
				return { empNo: v.value, empName: v.txt, key: v.value }
			})
			setRowsEdit([...values.empNo, ...rowsEdit])
			let running = [...selected, ...dataSource].map((el, i) => { return { ...el, rowNo: i + 1 } })
			setDataSource(running)
			form.resetFields();
			setChangeValue({ empNo: undefined });
		}
	};

	const onRemove = (row) => {
		Modal.confirm({
			title: 'ยืนยันการทำรายการ',
			content: 'ต้องการลบรายการใช่หรือไม่',
			okText: `ยืนยัน`,
			cancelText: `ยกเลิก`,
			onOk() {
				let remainEmp = (dataSource.filter(el => row.empNo !== el.empNo)).map((v, i) => {
					v.rowNo = i + 1
					return v
				})
				setDataSource(remainEmp)
			},
		})
	};

	const onRemoveMultiple = () => {
		Modal.confirm({
			title: 'ยืนยันการทำรายการ',
			content: 'ต้องการลบรายการใช่หรือไม่',
			okText: `ยืนยัน`,
			cancelText: `ยกเลิก`,
			onOk() {
				let remainEmp = (dataSource.filter(el => !selectedRowKeys.includes(el.empNo))).map((v, i) => {
					v.rowNo = i + 1
					return v
				})
				setDataSource(remainEmp)
				setSelectedRowKeys([])
			},
		})
	}

	const handleSave = async () => {
		try {
			setLoadingScreen(true);
			const response = await POST(INSERT_FILTER_OUT_SALES_EMP_COMMISSION, { userLogin: encodeString.decode(cookies.ID_User), array: dataSource });
			const { message, success } = response;
			if (success) {
				useAlert({ type: "success", content: message })
				setRowsEdit([])
				getFilterOutSalesEmpCommission()
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง" })
		} finally {
			setLoadingScreen(false);
		}
	}

	const onSave = () => {
		Modal.confirm({
			title: 'ยืนยันการทำรายการ',
			content: 'ต้องการบันทึกใช่หรือไม่',
			okText: `ยืนยัน`,
			cancelText: `ยกเลิก`,
			onOk() {
				handleSave()
			},
		})
	}

	const onSelectChange = (selectedRowDataKeys, selectedRows) => {
		setSelectedRowKeys(selectedRowDataKeys)
	};

	const rowsSelection = {
		selectedRowKeys: selectedRowKeys,
		onChange: onSelectChange,
	};

	const renderRowClass = (record) => {
		if (rowsEdit.length > 0) {
			if (rowsEdit.findIndex(el => el == record.empNo) != -1) {
				return 'row-edited'
			}
		}
		return ''
	}


	const onChangeValue = (name, value) => {
		form.setFieldsValue({ [name]: value });
		setChangeValue((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
			<Form form={form} onFinish={onFinish} {...configForm}>
				<Card
					title={`จัดการพนักงานที่ไม่คำนวณ Commission ในระบบ`}
					style={{ marginBottom: 30 }}
				>
					{useRenderFormItem({
						constantComponent: ComponentFilterOutSalesEmpCommission({
							options: { empNo: empAllOption },
							handleChange: onChangeValue,
						}),
					})}
					<div style={{ color: 'green' }}>* หลังจากเพิ่มหรือลบข้อมูลแล้ว กรุณากดปุ่ม "ยืนยันและบันทึกข้อมูล"</div>
					<div style={{ textAlign: 'right' }}>{useButton({ call: "save", moreProps: { onClick: () => onFinish(false), label: "เพิ่มข้อมูล" }, isDisable: false, moreStyle: { width: 'none' } })}</div>
				</Card>
			</Form>
			<Card
				extra={
					<div style={{ display: 'flex' }}>
						<Button.ButtonIcon icon={<MdDelete />} label={"ลบหลายรายการ"} color={colors.buttonEdit}
							onClick={() => onRemoveMultiple()}
							disabled={selectedRowKeys.length === 0}
						/>
						&nbsp;
						{useExportExcel({
							header: Header_FilterOutSalesEmpCommissionReport,
							dataset: dataSource,
							filename: "พนักงานที่ไม่ต้องคำนวณในระบบ Commission",
						})}
					</div>
				}
			>
				<CustomTableAtnd
					HeadTable={ColumnFilterOutSalesEmpCommission({ onRemove })}
					DataTable={dataSource}
					moreProps={{
						rowClassName: renderRowClass,
						pageSize: 50,
						rowSelection: rowsSelection,
						scroll: {
							x: sumColumnWidth(ColumnFilterOutSalesEmpCommission({ onRemove })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
				<div style={{ textAlignLast: 'center', marginTop: '15px' }}>
					<Space>
						{useButton({
							call: "save", isDisable: dataSource.length === 0, moreProps: {
								label: "ยืนยันและบันทึกข้อมูล",
								onClick: () => onSave()
							}
						})}
					</Space>
				</div>
			</Card>
		</Spin>
	);
};

export default FilterOutSalesEmpCommission;
