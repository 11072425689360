import React, { useState } from 'react'
import PopoverWrapper from '../common/popover/popoverWrapper'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BaseText } from '../common/text';
import { Row, Col, Modal } from 'antd';
import { colors } from '../../themes';
import { APIReportIndicatorLineScore } from '../services/report/reportConnect';
import { APIMasterIndicatorFactory } from '../services/master/masterConnect';
import { renderWithLabel } from '../common/functions';

const ContentInfoScore = ({
    content,
    factory,
    totalScore,
    monthYear,
    payload
}) => {
    const [contentList, setContentList] = useState([])
    const [statusActive, setStatusActive] = useState(false)
    return (
        <div
            onClick={async () => {
                if (factory) {
                    await APIMasterIndicatorFactory({ userId: content.ID_User, year: content.year, month: content.month }, res => {
                        const { result } = res
                        if (result.length > 0) {
                            setContentList(result)
                            setStatusActive(true)
                        } else {
                            Modal.error({ title: 'ไม่สามารถทำรายการได้', content: 'กรุณาตรวจสอบรายการตัวชี้วัด' })
                        }
                    })
                } else {
                    await APIReportIndicatorLineScore(payload, res => {
                        if (res.length > 0) {
                            setContentList(res)
                            setStatusActive(true)
                        } else {
                            Modal.error({ title: 'ไม่สามารถทำรายการได้', content: 'กรุณาตรวจสอบรายการตัวชี้วัด' })
                        }
                    })

                }
            }
                // await APIReportIndicatorLineScore(payloadLineScore, res => {
                //     let obj = {
                //         strMonth: parseInt(res.strMonth),
                //         endMonth: parseInt(res.endMonth),
                //         strYear: parseInt(res.strYear),
                //         endYear: parseInt(res.endYear)
                //     }
                //     console.log(obj, content.search)
                //     if (res.statusActive === 1) {
                //         setStatusActive(true)
                //         setContentList(res.indicator)
                //     }
                // })
            }
            style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 10px' }}>
            {(totalScore) && <BaseText size={16} label={totalScore} />}
            <PopoverWrapper
                icon={<InfoOutlinedIcon style={{ paddingLeft: 5 }} />}

                trigger="click"
                title={`ผลการประเมิน เดือน ${content.monthYear}`}
                content={
                    (statusActive) ?
                        (factory) ? contentFactory(contentList) : contentOffice(contentList)
                        :
                        <div style={{ fontSize: 14, textAlign: 'center' }}> ไม่พบข้อมูล </div>
                }
            />
        </div>
    )
}

export default ContentInfoScore


const contentOffice = (contentList) => {
    return (
        <div style={{ width: 600 }}>
            <Row gutter={[24, 10]}>
                <Col span={14}>
                    <BaseText size={14} color={colors.black} label={'ตัวชี้วัดผลงาน'} />
                </Col>
                <Col span={3}>
                    <BaseText size={14} color={colors.black} label={'เกณฑ์'} />
                </Col>
                <Col span={3}>
                    <BaseText size={14} color={colors.black} label={'สัดส่วน'} />
                </Col>
                <Col span={3}>
                    <BaseText size={14} color={colors.black} label={'คะแนน'} />
                </Col>
            </Row>
            <div>

                {contentList.length > 0 && contentList.map((el, elIndex) => {
                    return (
                        <Row key={elIndex} gutter={[24, 10]}>
                            <Col span={14}>
                                <BaseText size={14} label={`${elIndex + 1}. ${el.title}`} />
                            </Col>
                            <Col span={3}>
                                <BaseText size={14} label={`${el.criterion}`} />
                            </Col>
                            <Col span={3}>
                                <BaseText size={14} label={`${el.proportion}`} />
                            </Col>
                            <Col span={3}>
                                <BaseText size={14} color={(el.rawScore < el.criterion ? colors.error : colors.success)} label={`${el.rawScore || ''}`} />
                            </Col>
                        </Row>
                    )
                })}
            </div>
        </div>
    )
}

const contentFactory = (contentList) => {
    // console.log({ contentList })
    return (
        <div style={{ width: 600 }}>
            <Row gutter={[24, 10]}>
                <Col span={12}>
                    <BaseText size={14} color={colors.black} label={'งานที่ชี้วัด'} />
                </Col>
                <Col span={4}>
                    <BaseText size={14} color={colors.black} label={'ตัวชี้วัดผลงาน'} />
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <BaseText size={14} color={colors.black} label={'เกณฑ์'} />
                </Col>
                {/* <Col span={3}>
                    <BaseText color={colors.black} label={'สัดส่วน'} />
                </Col> */}
                <Col span={4} style={{ textAlign: 'center' }}>
                    <BaseText size={14} color={colors.black} label={'คะแนน'} />
                </Col>
            </Row>
            <div>

                {contentList.length > 0 && contentList.map((el, elIndex) => {
                    return (
                        <Row key={elIndex} gutter={[24, 10]}>
                            <Col span={12}>
                                <BaseText size={14} label={`${elIndex + 1}. ${el.indicatorDes}`} />
                            </Col>
                            <Col span={4}>
                                <BaseText size={14} color={colors.black} label={renderWithLabel({ label: el.indicatorGroup })} />
                            </Col>
                            <Col span={4} style={{ textAlign: 'center' }}>
                                <BaseText size={14} label={`${el.weight}`} />
                            </Col>
                            <Col span={4} style={{ textAlign: 'center' }}>
                                <BaseText size={14} label={`${el.rawScore || ''}`} />
                            </Col>
                            {/* <Col span={3}>
                                <BaseText color={(el.rawScore < el.criterion ? colors.error : colors.success)} label={`${el.rawScore}`} />
                            </Col> */}
                        </Row>
                    )
                })}
            </div>
        </div>
    )
}
