import { useEffect, useState } from "react";
import {
  GET,
  GET_SELECT_OPTION_POSITION,
  GET_SELECT_OPTION_DEPARTMENT_ALL,
  GET_OPTION_BENEFITS,
} from "../../../../service";

export const useGetOptions = () => {
  const [optionsCompanyTH, setOptionsCompanyTH] = useState([]);
  const [optionsCompanyEN, setOptionsCompanyEN] = useState([]);
  const [optionsPositionEN, setOptionsPositionEN] = useState([]);
  const [optionsPositionTH, setOptionsPositionTH] = useState([]);
  const [optionsDepartmentEN, setOptionsDepartmentEN] = useState([]);
  const [optionsDepartmentTH, setOptionsDepartmentTH] = useState([]);
  const [optionsSigner, setOptionsSigner] = useState([]);
  const [loadingOption, setLoadingOption] = useState({
    company: false,
    position: false,
    department: false,
    signer: false,
  });

  useEffect(() => {
    onFetchOptionsCompany();
    onFetchPosition();
    onFetchDepartment();
    onFetchSigner();
  }, []);

  useEffect(() => {}, []);

  const onFetchOptionsCompany = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, company: true }));
      const respond = await GET(GET_OPTION_BENEFITS("company"));
      setOptionsCompanyTH(() =>
        respond.result.data.map((e) => ({
          label: e.companyNameTH,
          value: e.companyNameTH,
          others: { e },
        }))
      );
      setOptionsCompanyEN(() =>
        respond.result.data.map((e) => ({
          label: e.companyNameEN,
          value: e.companyNameEN,
          others: { e },
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, company: false }));
    }
  };

  const onFetchSigner = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, signer: true }));
      const respond = await GET(GET_OPTION_BENEFITS("signer"));
      setOptionsSigner(() =>
        respond.result.data.map((e) => ({
          label: `${e.fullNameTH} - ${e.fullNameEN}`,
          value: e.userID,
          others: { e },
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, signer: false }));
    }
  };

  const onFetchPosition = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, position: true }));
      const respond = await GET(GET_OPTION_BENEFITS("position"));
      const positionNameEN = respond.result.data.map((e) => e.positionNameEN);
      const positionNameTH = respond.result.data.map((e) => e.positionNameTH);
      setOptionsPositionEN(() =>
        positionNameEN
          .filter((element, index) => {
            return positionNameEN.indexOf(element) === index;
          })
          .map((e) => ({
            label: e,
            value: e,
          }))
      );
      setOptionsPositionTH(() =>
        positionNameTH
          .filter((element, index) => {
            return positionNameTH.indexOf(element) === index;
          })
          .map((e) => ({
            label: e,
            value: e,
          }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, position: false }));
    }
  };

  const onFetchDepartment = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, position: true }));
      const respond = await GET(GET_SELECT_OPTION_DEPARTMENT_ALL("", ""));
      setOptionsDepartmentEN(() =>
        respond.result.map((e) => ({ label: e.depNameEN, value: e.depNameEN }))
      );
      setOptionsDepartmentTH(() =>
        respond.result.map((e) => ({ label: e.depNameTH, value: e.depNameTH }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, position: false }));
    }
  };

  return {
    optionsCompanyTH,
    optionsCompanyEN,
    optionsPositionEN,
    optionsPositionTH,
    optionsDepartmentEN,
    optionsDepartmentTH,
    optionsSigner,
    loadingOption:
      loadingOption.company &&
      loadingOption.position &&
      loadingOption.signer &&
      loadingOption.department,
  };
};
