import { useState, useEffect } from "react";
import { GET, GET_MONITOR_FILE_DASHBOARD } from "../../../../src/service";

export const useGetDataSource = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loadingDataSource, setLoadingDataSource] = useState(false);

  useEffect(
    () => onFetchDataSource({ company: "all", departmentNo: "all", employeeNo: "all", fileStatus: "WAIT", fileGuaranteeStatus: "NONE" }),
    []
  );

  const onFetchDataSource = async (value) => {
    try {
      const company = value.company == "all" ? "" : value.company;
      const departmentNo = value.departmentNo == "all" ? "" : value.departmentNo;
      const employeeNo = value.employeeNo == "all" ? "" : value.employeeNo;
      const fileStatus = value.fileStatus;
      const fileGuaranteeStatus = value.fileGuaranteeStatus == "REQUIRED" ? 1 : "";

      setLoadingDataSource(true);
      const respond = await GET(
        `${GET_MONITOR_FILE_DASHBOARD}?company=${company || ''}&departmentNo=${departmentNo || ''}&employeeNo=${employeeNo || ''}&fileStatus=${fileStatus || ''}&fileGuaranteeStatus=${fileGuaranteeStatus || ''}`
      );
      setDataSource(() => [
        ...respond.result.map((element) => ({
          ...element,
          key: element.employeeNo
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataSource(false);
    }
  };

  return {
    dataSource,
    onFetchDataSource,
    loadingDataSource,
  };
};
