import React, { useContext, useEffect, useState, useRef } from "react";
import { Spin, Button, Modal } from "antd";

import { MasterCenterContext } from "../../service/master/masterProvider";
import { PersonalContext } from "../servicesPersonal/personalProvider";
import { PATHNAME } from "../../constants/enums/pathname";

import LayoutSearch from "../../components/structuresReport/layoutSearch";
import LayoutTable from "../../components/structuresReport/layoutTable";
import { LayoutTabs } from "../../components/structuresReport/layoutTabs";
import { TEXT_BUTTON } from "../../constants/buttons";
import { APILineNoti, KEY_PERSONAL } from "../servicesPersonal";
import {
  ButtonExport,
  ContainerButton,
} from "../../components/structuresReport/buttons";
import {
  LayoutBreadcrumb,
  LayoutWrapper,
} from "../../moduleKPIs/common/layouts";
import {
  KEY_ACTIVE_TAB,
  KEY_OPTION,
  TEXT_LOADING,
} from "../../constants/enums/enumCenter";
import {
  FORM_DASHBOARD_IMPORT_PERSONLA,
  ICON_DASHBOARD_IMPORT_PERSONLA,
  TEXT_DASHBOARD_IMPORT_PERSONLA,
} from "../constantsPersonal/dashboards/dashboardImportPersonal";
import { GET, GET_SELECT_IMPORT_EMP_FAC } from "../../service";

/**
 * TODO : ถ้าต้องการเปลี่ยนข้อมูล ตาม Report
 * ! ใน API_PAGE อย่าลืมเพิ่ม fetchAPIPersonal ตาม KEY_API
 * @param API_PAGE - เปลี่ยน KEY_API
 * @param TEXT - เปลี่ยน TEXT_PAGE
 * @param ICON - เปลี่ยน TEXT_PAGE
 * @param FORM - เปลี่ยน FORM_PAGE
 * @param DEFAULT_SEARCH - เปลี่ยน DEFAULT_SEARCH
 *
 */

const DashboardImportPersonalFac = (props) => {
  const refExport = useRef(); // TODO: The reference of export button Excel

  /* --------------------------------- CONTEXT -------------------------------- */
  const {
    personalState,
    fetchAPIPersonal,
    loadingAPI,
    userLogin,
    userLocation,
  } = useContext(PersonalContext);
  const { getMaster, loadingMaster, masterCenterState } =
    useContext(MasterCenterContext);
  /* -------------------------------------------------------------------------- */

  /* --------------------------------- CONTENT -------------------------------- */
  let API_PAGE = `${KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL}`; //! อย่าลืมเปลี่ยน KEY_API
  let TEXT_PAGE = TEXT_DASHBOARD_IMPORT_PERSONLA; //! อย่าลืมเปลี่ยน TEXT_PAGE
  // let ICON_PAGE = ICON_DASHBOARD_IMPORT_PERSONLA;                     //! อย่าลืมเปลี่ยน ICON_PAGE
  let FORM_PAGE = FORM_DASHBOARD_IMPORT_PERSONLA; //! อย่าลืมเปลี่ยน FORM_PAGE
  let DEFAULT_TAB = KEY_ACTIVE_TAB.IMPORT_PERSONAL; //! อย่าลืมเปลี่ยน DEFAULT_TAB
  let DATASOURCE =
    personalState[KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL]["dataSource"];
  let ROWKEY = personalState[KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL]["rowKey"];

  let location = props.locationM;

  //TODO : SET Default Search
  let DEFAULT_SEARCH = {
    company: null,
    location: location,
    depNo: null,
    subDepNo: null,
    statusWorker: null,
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- USE_EFFECT ------------------------------- */

  async function fetchAPI() {
    let response = [];
    try {
      response = await Promise.all([
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_LOCATION,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_COMPANY,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_STATUS_WORKERS,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT_ALL,
          typeOption: "none",
        }),
        handleSearch({
          ...DEFAULT_SEARCH,
          activeTab: KEY_ACTIVE_TAB.IMPORT_PERSONAL,
        }),
      ]);
    } catch (error) {
      // console.log({ error })
      //! line Noti
      APILineNoti({
        header: `FRONTEND ${userLogin} [${userLocation}]`,
        title: `📍 fetchAPI : ${error.message} `,
        message: `${JSON.stringify({ resOption: response })}`,
      });
    }
  }

  useEffect(() => {
    fetchAPI();
  }, []);
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- STATE --------------------------------- */
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                  Function                                 */
  /* -------------------------------------------------------------------------- */
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  
  async function handleSearch(values) {
    setLoading(true);
    try {
      const res = await GET(GET_SELECT_IMPORT_EMP_FAC(values.candidateNo));
      const { result } = res;
      setDataSource(result);
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด" });
    } finally {
      setLoading(false);
    }
  }

  function handleTabs(_activeKey) {
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */
    switch (+_activeKey) {
      case 1:
        /* ----------------------------- อัพเดต Key Tabs ---------------------------- */
        setActiveTab(KEY_ACTIVE_TAB.IMPORT_PERSONAL);
        /* ------------------------ เปลี่ยน Tabs แล้วให้ค้นหา ----------------------- */
        fetchAPIPersonal({
          key: KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL,
          data: { ...DEFAULT_SEARCH, activeTab },
        });
        break;
      default:
        break;
    }
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  const RENDER_TABLE = () => {
    return (
      <LayoutTable
        title={null} // TODO : <TitleTable contentList={[{ title: '', label: '' }]} />
        loading={loading}
        rowKey={ROWKEY[activeTab]}
        dataSource={dataSource}
        columns={FORM_PAGE.TABLE({
          location,
          activeTab,
          actionButton: RENDER_BUTTON_TABLE,
        })}
      />
    );
  };
  const RENDER_BUTTON_TABLE = (text, record) => {
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          onClick={() =>
            window.open(`${PATHNAME.IMPORT_PERSONAL}/${record.candidateNo}`)
          }
        >
          {TEXT_BUTTON.IMPORT}
        </Button>
      </div>
    );
  };

  const RENDER_BUTTON_EXPORT = () => {
    return (
      <ContainerButton right>
        {/* ------------------------------ EXPORT EXCEL ------------------------------ */}
        <ButtonExport
          ref={refExport}
          columns={FORM_PAGE.EXPORT_EXCEL({ location, activeTab })}
          fieldname={TEXT_PAGE.FILE_EXPORT[activeTab]}
          dataSource={dataSource}
        />
        {/* -------------------------------------------------------------------------- */}
      </ContainerButton>
    );
  };

  return (
    <Spin spinning={loadingMaster} tip={TEXT_LOADING}>
      <LayoutWrapper
        breadcrumb={
          <LayoutBreadcrumb
            breadcrumbLists={FORM_PAGE.BREADCRUMB({
              pathname: PATHNAME.DASHBOARD_IMPORT_PERSONAL,
            })}
          />
        }
        search={
          <LayoutSearch
            onSearch={handleSearch}
            disabledSearch={false}
            disabledClear={false}
            onHandleChange={() => null}
            searchLists={FORM_PAGE.SEARCH({
              search: { ...DEFAULT_SEARCH },
              location,
              activeTab,
              options: { ...masterCenterState },
            })}
          />
        }
        content={
          <div className="layout-content-card" style={{ padding: 10 }}>
            <LayoutTabs
              type="card"
              defaultKey="1"
              onChange={handleTabs}
              tabsLists={FORM_PAGE.TABS({
                location,
                activeTab,
                renderTable: RENDER_TABLE(),
              })}
              extraContent={RENDER_BUTTON_EXPORT()} //component for render button export
            />
          </div>
        }
      />
    </Spin>
  );
};

export default DashboardImportPersonalFac;
