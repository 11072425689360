import { MESSAGE_ERROR } from "../..";
import {
  KEY_LOCATION,
  KEY_OPTION,
  KEY_TYPE,
} from "../../../../constants/enums/enumCenter";
import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  PROFILE_IMAGE: "รูปถ่าย/รูปถ่ายผู้สมัคร",
  TITLE_TH: "คำนำหน้า  (ภาษาไทย)",
  FIRSTNAME_TH: "ชื่อ (ภาษาไทย)",
  LASTNAME_TH: "นามสกุล (ภาษาไทย)",
  NICKNAME_TH: "ชื่อเล่น  (ภาษาไทย)",
  TITLE_EN: "คำนำหน้า (ภาษาอังกฤษ)",
  FIRSTNAME_EN: "ชื่อ (ภาษาอังกฤษ)",
  LASTNAME_EN: "นามสกุล (ภาษาอังกฤษ)",
  NICKNAME_EN: "ชื่อเล่น (ภาษาอังกฤษ)",
  WEIGHT: "น้ำหนัก กก.(Weight )",
  HEIGHT: "ส่วนสูง ซม.(Height)",
  AGE_REGIS: "อายุตอนสมัคร (Age)",
  AGE: "อายุ (Age)",
  DATE_OF_BIRTHDAY: "วัน/เดือน/ปีเกิด (Date of Birth)",
  SEX: "เพศ (Sex)",
  NATIONALITY: "เชื้อชาติ (Nationality)",
  RACE: "สัญชาติ (Race)",
  RELIGION: "ศาสนา (Religion)",
  PERSONAL_ID: "บัตรประชาชนเลขที่ (ID Card No.)",
  PERSONAL_ID_EXP: "หมดอายุ (Date of Expiry)",
  PASSPORT_ID: "หนังสือเดินทาง (Passport No.)",
  PASSPORT_ID_EXP: "หมดอายุ (Date of Expiry)",
  WORKPERMIT_ID: "ใบอนุญาติทำงาน (Work Permit)",
  WORKPERMIT_ID_EXP: "หมดอายุ (Date of Expiry)",
  PERSONAL_STATUS: "สถานภาพ (Marital Status)",
  MILITARYSERVICE: "สถานะทางทหาร (Military Service)",
  MILITARYSERVICE_WHEN: "รอเรียกเกณฑ์ทหาร ในปี พ.ศ.",
  REASON_EXEMPT: "ได้ยกเว้นเกณฑ์ทหารเนื่องจาก (Reason for exemption)",

  SPOUSE_NAME: "ชื่อ-นามสกุล คู่สมรส (Spouse Name)",
  SPOUSE_NUM_CHILDREN: "จำนวนบุตร (Number of children)",
  SPOUSE_TEL: `เบอร์โทรศัพท์คู่สมรส (Spouse's phone number)`,
  SPOUSE_OCCUPATION: `อาชีพคู่สมรส (Spouse's occupation)`,
  SPOUSE_POSITION: `ตำแหน่งงานคู่สมรส (Spouse's Position)`,
  SPOUSE_OFFICE: `ที่ทำงานคู่สมรส (Spouse Office)`,

  SIMILAR_PRESENT: "เหมือนที่อยู่ปัจจุบัน",
  PRESENT_ADDRESS: "ที่อยู่ปัจจุบัน (Present Address)",
  PRESENT_PROVINCE: "จังหวัด (Province)",
  PRESENT_DISTRICT: "อำเภอ/เขต (District)",
  PRESENT_SUB_DISTRICT: "ตำบล / แขวง (Sub-district)",
  PRESENT_POSTCODE: "รหัสไปรษณีย์ (Postcode)",
  FORMER_ADDRESS: "ที่อยู่ตามทะเบียนบ้าน (Former Address)",
  FORMER_PROVINCE: "จังหวัด (Province)",
  FORMER_DISTRICT: "อำเภอ/เขต (District)",
  FORMER_SUB_DISTRICT: "ตำบล / แขวง (Sub-district)",
  FORMER_POSTCODE: "รหัสไปรษณีย์ (Postcode)",

  HOME_TEL: "โทรศัพท์ที่บ้าน (Home Tel)",
  MOBILE_PHONE: "โทรศัพท์มือถือ (Phone Number)",
  LINE_ID: "LINE ID",
  EMAIL: "อีเมลส่วนตัว (Email)",

  EMERGENCY_FULLNAME:
    "ชื่อผู้ติดต่อฉุกเฉิน (Contact person incase of emergency)",
  EMERGENCY_REALATION: "ความสัมพันธ์ (Relationship)",
  EMERGENCY_PHONE: "เบอร์ติดต่อฉุกเฉิน (Phone Number)",
  EMERGENCY_TEL_OTHER: "เบอร์ติดต่อที่บ้าน / ที่ทำงาน (Home / Office tel)",
  EMERGENCY_ADDRESS: "ที่อยู่ผู้ติดต่อฉุกเฉิน (Home / Office Address)",
  DISC: "ผล DISC",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  PROFILE_IMAGE: "profileImage",
  TITLE_TH: "titleTH",
  FIRSTNAME_TH: "firstNameTH",
  LASTNAME_TH: "lastNameTH",
  NICKNAME_TH: "nickNameTH",
  TITLE_EN: "titleEN",
  FIRSTNAME_EN: "firstNameEN",
  LASTNAME_EN: "lastNameEN",
  NICKNAME_EN: "nickNameEN",
  WEIGHT: "weight",
  HEIGHT: "height",
  AGE_REGIS: "ageRegis",
  AGE: "ageRegis",
  DATE_OF_BIRTHDAY: "dateOfBirth",
  SEX: "sex",
  NATIONALITY: "nationality",
  RACE: "race",
  RELIGION: "religion",
  PERSONAL_ID: "personalId",
  PERSONAL_ID_EXP: "personalExpiry",
  PASSPORT_ID: "passportId",
  PASSPORT_ID_EXP: "passportExpiry",
  WORKPERMIT_ID: "workPermitId",
  WORKPERMIT_ID_EXP: "workPermitExpiry",
  PERSONAL_STATUS: "personalStatus",
  MILITARYSERVICE: "militaryService",
  MILITARYSERVICE_WHEN: "militaryWhen",
  REASON_EXEMPT: "reasonExempt",

  SPOUSE_NAME: "spouseName",
  SPOUSE_NUM_CHILDREN: "spouseNumChildren",
  SPOUSE_TEL: "spouseTel",
  SPOUSE_OCCUPATION: "spouseOccupation",
  SPOUSE_POSITION: "spousePosition",
  SPOUSE_OFFICE: "spouseOffice",

  SIMILAR_PRESENT: "similarPresent",
  PRESENT_ADDRESS: "presentAddress",
  PRESENT_PROVINCE: "presentProvince",
  PRESENT_DISTRICT: "presentDistrict",
  PRESENT_SUB_DISTRICT: "presentSubDistrict",
  PRESENT_POSTCODE: "presentPostcode",
  FORMER_ADDRESS: "formerAddress",
  FORMER_PROVINCE: "formerProvince",
  FORMER_DISTRICT: "formerDistrict",
  FORMER_SUB_DISTRICT: "formerSubDistrict",
  FORMER_POSTCODE: "formerPostcode",

  HOME_TEL: "homeTel",
  MOBILE_PHONE: "mobilePhone",
  LINE_ID: "lineId",
  EMAIL: "email",

  EMERGENCY_FULLNAME: "emergencyPerson",
  EMERGENCY_REALATION: "emergencyRelation",
  EMERGENCY_PHONE: "emergencyTel",
  EMERGENCY_TEL_OTHER: "emergencyTelOther",
  EMERGENCY_ADDRESS: "emergencyAddress",

  /* --------------------------------- DISPLAY -------------------------------- */

  FULLNAME_TH: "fullNameTH",
  FULLNAME_EN: "fullNameEN",
  PAYROLL_GROUP: "payrollGroup",
  CURRENT_STATUS: "currentStatus",
  REASON_OUT: "reasonLeaveCode",
  REASON_OUT_TEXT: "reasonLeaveDes",
  DISC: "disc",
  CONTACT_PERIOD: "contractPeriod",
  /* -------------------------------------------------------------------------- */
};

export const FIELD_PERSONAL = FIELD_PAGES;
export const LABEL_PERSONAL = LABEL_PAGES;

export const CONTENT_PERSONAL_VIEW = (props) => {
  const {
    location,
    options,
    renderAddressForm,
    disabled = {},
    disabledAll,
  } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.PROFILE_IMAGE,
          label: LABEL_PAGES.PROFILE_IMAGE, //รูปถ่าย/รูปถ่ายผู้สมัคร
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_IMAGE }],
        },
        inputprops: {
          name: FIELD_PAGES.PROFILE_IMAGE,
          type: KEY_TYPE.CONTENT,
          listType: "picture-card",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: "image/jpeg,image/png",
          max: 1,
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.WEIGHT,
          label: LABEL_PAGES.WEIGHT, //น้ำหนัก (Weight)
          rules: [{ required: false, message: MESSAGE_ERROR.DECIMAL }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          max: 300,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.HEIGHT,
          label: LABEL_PAGES.HEIGHT, //ส่วนสูง (Height)
          rules: [{ required: false, message: MESSAGE_ERROR.DECIMAL }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          max: 300,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.SEX,
          label: LABEL_PAGES.SEX, //เพศ (Sex)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SEX],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.DATE_OF_BIRTHDAY,
          label: LABEL_PAGES.DATE_OF_BIRTHDAY, //วัน/เดือน/ปีเกิด (Date of Birth)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          placeholder: "",
          disabledDate: (current) => current && current > moment(),
          defaultPickerValue: moment("1990-01-01"),
          disabled: disabledAll,
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.AGE,
          label: LABEL_PAGES.AGE, //อายุ (Age)
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 2,
          disabled: true,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.NATIONALITY,
          label: LABEL_PAGES.NATIONALITY, //เชื้อชาติ (Nationality)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.RACE,
          label: LABEL_PAGES.RACE, //สัญชาติ (Race)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.RELIGION,
          label: LABEL_PAGES.RELIGION, //ศาสนา (Religion)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_RELIGION],
        },
      },
      {
        span: 9,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_STATUS,
          label: LABEL_PAGES.PERSONAL_STATUS, //สถานภาพ (Marital Status)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_PERSONLA_STATUS],
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.MILITARYSERVICE,
          label: LABEL_PAGES.MILITARYSERVICE, //สถานะทางทหาร (Military Service)
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_MILITARYSERVICE],
        },
      },
      {
        span: 5,
        itemprops: {
          name: FIELD_PAGES.MILITARYSERVICE_WHEN,
          label: LABEL_PAGES.MILITARYSERVICE_WHEN, //รอเรียกเกณฑ์ทหาร ในปี พ.ศ.
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          picker: "year",
          disabled: !disabled[FIELD_PAGES.MILITARYSERVICE_WHEN] || disabledAll,
          placeholder: "",
          disabledDate: (current) =>
            current && current < moment().endOf("year"),
        },
      },
      {
        span: 14,
        itemprops: {
          name: FIELD_PAGES.REASON_EXEMPT,
          label: LABEL_PAGES.REASON_EXEMPT, //ได้ยกเว้นเกณฑ์ทหารเนื่องจาก (Reason for exemption)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: !disabled[FIELD_PAGES.REASON_EXEMPT] || disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CONTENT,
          title: "ข้อมูลที่อยู่",
        },
      },
      /* -------------------------------------------------------------------------- */
      ...renderAddressForm, //TODO : Render Form Address
      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CONTENT,
          title: "ข้อมูลคู่สมรส",
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_NAME,
          label: LABEL_PAGES.SPOUSE_NAME, //ชื่อ-นามสกุล คู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_OCCUPATION,
          label: LABEL_PAGES.SPOUSE_OCCUPATION, //อาชีพคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_NUM_CHILDREN,
          label: LABEL_PAGES.SPOUSE_NUM_CHILDREN, //จำนวนบุตร
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER, len: 1 }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 1,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_TEL,
          label: LABEL_PAGES.SPOUSE_TEL, //เบอร์โทรศัพท์คู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.TEL, len: 10 }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_POSITION,
          label: LABEL_PAGES.SPOUSE_POSITION, //ตำแหน่งงานคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.SPOUSE_OFFICE,
          label: LABEL_PAGES.SPOUSE_OFFICE, //ที่ทำงานคู่สมรส
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },

      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CONTENT,
          title: "ผู้ติดต่อฉุกเฉิน ",
        },
      },
      /* -------------------------------------------------------------------------- */

      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_FULLNAME,
          label: LABEL_PAGES.EMERGENCY_FULLNAME, //ชื่อผู้ติดต่อฉุกเฉิน
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.INPUT,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_PHONE,
          label: LABEL_PAGES.EMERGENCY_PHONE, // 'เบอร์ติดต่อฉุกเฉิน
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.TEL,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_REALATION,
          label: LABEL_PAGES.EMERGENCY_REALATION, //ความสัมพันธ์ (Relationship)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_TEL_OTHER,
          label: LABEL_PAGES.EMERGENCY_TEL_OTHER, //เบอร์ติดต่อที่บ้าน / ที่ทำงาน
          rules: [{ required: false, message: MESSAGE_ERROR.TEL }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 24,
        itemprops: {
          name: FIELD_PAGES.EMERGENCY_ADDRESS,
          label: LABEL_PAGES.EMERGENCY_ADDRESS, //ที่อยู่ผู้ติดต่อฉุกเฉิน
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};
