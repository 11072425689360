import axios from "axios";
import { Modal } from "antd";
import servicesAPI from "../../moduleKPIs/services/servicesAPI";
import { CONFIG } from "../../components/connectAPI";
const API = servicesAPI.create();

export async function APIExportProsoft(payload) {
  try {
    return await axios({
      url: `${CONFIG.API_URL}${CONFIG.PERSONAL.EXPORT_PROSOFT}`,
      method: "GET",
      responseType: "blob", // Important
      params: payload,
    }).then((response) => {
      return response;
    });
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIExportTigerSoft(payload) {
  try {
    return await axios({
      url: `${CONFIG.API_URL}${CONFIG.PERSONAL.EXPORT_TIGER_SOFT}`,
      method: "GET",
      responseType: "blob", // Important
      params: payload,
    }).then((response) => {
      return response;
    });
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIVerifyUser(payload) {
  try {
    const response = await API.getVerifyUser(payload);
    if (response.status === 200) {
      return response.data;
    }

    // console.log('Failure : ', { response }, response.status)
    return response.data;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIGetUserCode(payload) {
  try {
    const response = await API.getUserCode(payload);
    if (response.status === 200) {
      return response.data && response.data.userCode;
    }
    // console.log('Failure : ', { response }, response.status)
    return null;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIInsertPersonal(payload) {
  try {
    const response = await API.insertPersonal(payload);
    if (response.status === 200) {
      return response.data;
    }
    // console.log('Failure : ', { response }, response.status)
    if (response.status === 400) {
      Modal.error({
        title: `${response.data.message}`,
        content: `กรุณาลองใหม่ภายหลัง`,
        onOk: () => null,
      });
      return response.data;
    }
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIImportPersonal(payload) {
  try {
    const response = await API.importPersonal(payload);
    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      Modal.error({
        title: `${response.data.message}`,
        content: `กรุณาลองใหม่ภายหลัง`,
        onOk: () => null,
      });
      return response.data;
    }
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIUpdatePersonal(payload) {
  try {
    const response = await API.updatePersonal(payload);
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 400) {
      Modal.error({
        title: `${response.data.message || "ไม่สามารถทำรายการได้"}`,
        content: `กรุณาลองใหม่ภายหลัง`,
        onOk: () => null,
      });
      return response.data;
    }
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIDashboardPersonal(payload) {
  try {
    const response = await API.dashboardPersonal(payload);
    if (response.status === 200) {
      return response.data;
    }
    //console.log('Failure : ', { response }, response.status)
    return null;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIDashboardImportPersonal(payload) {
  try {
    const response = await API.dashboardImportPersonal(payload);
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
    // console.log('Failure : ', { response }, response.status)
    return false;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APISelectPersonal(payload) {
  try {
    const response = await API.selectPersonal(payload);
    if (response.status === 200) {
      return response.data;
    }
    // console.log('Failure : ', { response }, response.status)
    return null;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIInsertProbationHistory(payload) {
  try {
    const response = await API.insertProbationHistory(payload);
    if (response.status === 200) {
      return response.data;
    }
    // console.log('Failure : ', { response }, response.status)
    return null;
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APILineNoti(payload) {
  try {
    const response = await API.getLineNoti(payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}

export async function APIGetDISC(payload) {
  try {
    const response = await API.getDISC(payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log("API Catch : ", { error });
  }
}
