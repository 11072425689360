import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs, Modal, Select } from "antd";
import {
  optionSelectLocation,
  useOptionSelect,
} from "../../constants/OptionSelect";
import {
  GET_UserLogin,
  toUndefined,
  sumColumnWidth,
  subStringDate,
} from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import {
  GET,
  GET_SLECT_DASHBOARD_PROBATION_OUT,
  POST,
  POST_SELECT_DASHBOARD_PROBATION_NEW,
  EDIT_DOC_STATUS
} from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { ColumnSearchProbationEmpContractNew } from "../../constants/ProbationEmployee/Columns";
import { ComponentSearchProbationEmpContractNew } from "../../constants/ProbationEmployee/Components";
import WorkingProcess from "../../../components/workingProcess";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportProbationEmpContractNew } from "../../constants/HeaderExcel";
import { optionDocStatus } from "../../../constants/options";

const FormSearchProbationEmpContractNew = () => {
  const [form] = Form.useForm();
  const { userlogin, location } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({
    location: location === "FAC" ? "FAC" : undefined,
  });
  const [dataTableProbation, setDataTableProbation] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [isModalDocStatusOpen, setIsModalDocStatusOpen] = useState(false);
  const [docStatus, setDocStatus] = useState('');
  const [docNo, setDocNo] = useState('');
  // [END] States

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect(
      "department",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
    statusMainCode: useOptionSelect("statusMainCode"),
    statusWorker: useOptionSelect("statusWorker"),
    searchUser: useOptionSelect(
      "empAll",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
  };
  // [START] Handle Form Antd
  const onFinish = (values) => {
    const {
      company,
      depNo,
      statusMainCode,
      statusWorker,
      startDate,
      toDate,
      searchUser,
      contractDate
    } = values;

    const objectBody = {
      userlogin,
      locationLogin: location,
      depNo: depNo || "",
      subDepNo: "",
      statusMainCode: statusMainCode || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      startContractDate: contractDate ? contractDate[0].format("YYYY-MM-DD") : "",
      endContractDate: contractDate ? contractDate[1].format("YYYY-MM-DD") : "",
      toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
      report: "report",
      statusWorker: statusWorker || "",
      currentStatus: "",
      statusProbation: "",
      type: "contract",
      location: values.location || "",
      company: company || "",
      searchUser: searchUser || "",
      pageType: "",
      contractExtension: "",
    };

    console.log({ objectBody, values })
    // if (activeTab === 1) {
    searchProbationEmpContract(objectBody);
    // } else {
    //   searchProbationEmpContractOut(objectBody);
    // }
  };

  const onReset = () => {
    form.resetFields();
    setChangeValue({});
    setDataTableProbation([]);
    // setDataTableProbationOut([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const searchProbationEmpContract = async (objectRow) => {
    try {
      const {
        company,
        depNo,
        statusMainCode,
        statusWorker,
        startDate,
        toDate,
        searchUser,
        startContractDate,
        endContractDate
      } = objectRow;
      const objectBody = {
        userlogin,
        locationLogin: location,
        depNo: depNo || "",
        subDepNo: "",
        statusMainCode: statusMainCode || "",
        startDate: startDate || "",
        startContractDate,
        endContractDate,
        toDate: toDate || "",
        report: "report",
        statusWorker: statusWorker || "",
        currentStatus: "",
        statusProbation: "",
        type: "contract",
        location: objectRow.location || "",
        company: company || "",
        searchUser: searchUser || "",
        pageType: "",
        contractExtension: "",
      };
      setLoadingScreen(true);
      const response = await POST(POST_SELECT_DASHBOARD_PROBATION_NEW, objectBody);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: index,
          ...row,
          StartDateJob: subStringDate(row.StartDateJob),
          expProDate: subStringDate(row.expProDate),
          probationEndDate: subStringDate(row.probationEndDate),
          HR_ReceivedDate: subStringDate(row.HR_ReceivedDate),
          date_HEA: subStringDate(row.date_HEA),
          date_PVD: subStringDate(row.date_PVD),
          date_SOS: subStringDate(row.date_SOS),
          endContractDate: subStringDate(row.endContractDate),
        }));
      } else {
        useNotification({ type: "error", title: "ไม่พบข้อมูล" });
      }
      setDataTableProbation(mapResult);
    } catch (err) {
      console.log("Error searchProbationEmpContract: ", err);
      useAlert({
        type: "error",
        title: "เกิดข้อผิดพลาด",
        content: `${err.message}`,
      });
    } finally {
      setLoadingScreen(false);
    }
  };

  // const searchProbationEmpContractOut = async (objectRow) => {
  //   try {
  //     const {
  //       depNo,
  //       statusWorker,
  //       statusMainCode,
  //       searchUser,
  //       startDate,
  //       toDate,
  //     } = objectRow;
  //     const objectParam = {
  //       user_login: userlogin,
  //       locationID: "all",
  //       SegmentTxt: depNo || "all",
  //       statusWorker: statusWorker || "all",
  //       statusMainCode: statusMainCode || "all",
  //       txtUserSeaching: searchUser || "",
  //       StartDateJob: startDate || "",
  //       toDateJob: toDate || "",
  //     };
  //     setLoadingScreen(true);
  //     const response = await GET(
  //       GET_SLECT_DASHBOARD_PROBATION_OUT(objectParam)
  //     );
  //     const { data } = response;
  //     let mapResult = [];
  //     if (data !== 0) {
  //       mapResult = data.map((row, index) => ({
  //         key: index,
  //         ...row,
  //         date_HEA: subStringDate(row.date_HEA),
  //         date_PVD: subStringDate(row.date_PVD),
  //         date_SOS: subStringDate(row.date_SOS),
  //       }));
  //     } else {
  //       useNotification({ type: "error", title: "ไม่พบข้อมูล" });
  //     }
  //     setDataTableProbationOut(mapResult);
  //   } catch (err) {
  //     console.log("Error searchProbationEmpContractOut: ", err);
  //     useAlert({
  //       type: "error",
  //       title: "เกิดข้อผิดพลาด",
  //       content: `${err.message}`,
  //     });
  //   } finally {
  //     setLoadingScreen(false);
  //   }
  // };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndifined = {};
    let setName = {};
    switch (name) {
      case "company":
        setName = { companyCode: value };
        if (location === "FAC") {
          setUndifined = toUndefined(["depNo", "searchUser"]);
        } else {
          setUndifined = toUndefined(["location", "depNo", "searchUser"]);
        }
        break;
      case "location":
        setUndifined = toUndefined(["depNo", "searchUser"]);
        break;
      case "depNo":
        setUndifined = toUndefined(["searchUser"]);
        break;
    }
    form.setFieldsValue(setUndifined);
   // setDataTableProbation([]);
    // setDataTableProbationOut([]);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndifined,
      ...setName,
      [name]: value,
    }));
  };

  const onUpdateDocStatus = async () => {
    console.log({ docStatus, docNo })
    try {
      const payload = [{
        docNo: docNo || '11111',
        docStatus:docStatus||'',
        createdBy: userlogin
      }]
      const res = await POST(EDIT_DOC_STATUS, { listDocNo: payload })
      const { success, message } = res

      if (success) {
        Modal.success({ title: message, onOk: () => onFinish({}) })
        setIsModalDocStatusOpen(false)
      }

    } catch (err) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: `${err.message}`,
      })
    }
  }

  const onEditstatusDoc = (record) => {
    setDocNo(record.docNo)
    setDocStatus(record.docStatus)
    setIsModalDocStatusOpen(true)
  }

  const handleChange = (value) => {
    setDocStatus(value)
  }

  const onCloseModal = () => {
    setIsModalDocStatusOpen(false)
    setDocStatus('')
    setDocNo('')
  }



  // [END] Functions
  return (
    <>
      <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
        <Form
          form={form}
          onFinish={onFinish}
          {...configForm}
          initialValues={{ location: location === "FAC" ? "FAC" : undefined }}
        >
          <Card
            title="รายงานพนักงานช่วงทดลองงาน (สัญญาจ้าง) อันใหม่"
            style={{ marginBottom: 30 }}
            extra={
              <Space>
                {useButton({ call: "search" })}
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Space>
            }
          >
            {useRenderFormItem({
              constantComponent: ComponentSearchProbationEmpContractNew({
                options: optionSearch,
                handleChange: onChangeValue,
                isDisable: { location: location === "FAC" ? true : false },
              }),
            })}
          </Card>
          {/* <Card style={{ marginBottom: 30 }}>
            <WorkingProcess pathname={"/dashboardProbationEmpContract"} />
          </Card> */}
          <Card>
            <Tabs
              defaultActiveKey="1"
              // onChange={(activeKey) => {
              //   setActiveTab(+activeKey);
              //   if (+activeKey === 1) {
              //     setDataTableProbationOut([]);
              //     searchProbationEmpContract(ChangeValue);
              //   } else {
              //     setDataTableProbation([]);
              //     searchProbationEmpContractOut(ChangeValue);
              //   }
              // }}
              tabBarExtraContent={useExportExcel({
                header: Header_ReportProbationEmpContractNew(activeTab),
                dataset: dataTableProbation,
                filename: "รายงานพนักงานช่วงทดลองงาน (สัญญาจ้าง)",
              })}
            >
              <Tabs.TabPane key={1} tab={"อยู่ระหว่างดำเนินการ - ผ่านทดลองงาน"}>
                <CustomTableAtnd
                  HeadTable={ColumnSearchProbationEmpContractNew({ onEditstatusDoc })}
                  DataTable={dataTableProbation}
                  moreProps={{
                    scroll: {
                      x:
                        sumColumnWidth(ColumnSearchProbationEmpContractNew({ onEditstatusDoc })) ||
                        2050,
                      y: 500,
                    },
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Form>
        <Modal width={'320px'} title="แก้ไขสถานะเอกสาร" visible={isModalDocStatusOpen} onOk={onUpdateDocStatus}
          onCancel={onCloseModal} cancelText={'ยกเลิก'} okText={'ยืนยัน'}>
          <Select style={{ width: '100%' }} options={optionDocStatus} onChange={handleChange} defaultValue={docStatus || null} placeholder={"เลือกสถานะเอกสาร"}/>
        </Modal>
      </Spin>
    </>
  );
};

export default FormSearchProbationEmpContractNew;
