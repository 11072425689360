import React, { createContext, useReducer, useEffect } from "react";
import { APIMasterEvaluationEmployee, APIMasterIndicatorHeader, APIMasterIndicatorStatus, APIMasterBossApproval, APIMasterBossInspect, APIMasterFilterFactory, APIMasterFilterSubDepFactory } from "./masterConnect";
import { MASTER_OPTION, servicesOptions } from "../servicesOptions";
import { optionMonth, optionYear, optionAssessment } from "../../common/options";


export const MasterContext = createContext();
const statusWorker = JSON.parse(window.localStorage.getItem("statusWorker"))
const initialState = {
    search_optionYear: [new Date().getFullYear()],
    search_optionMonth: [],
    search_optionBossApproval: [],
    search_optionBossInspect: [],
    search_optionDepartment: [],
    search_optionEmployee_LV1: [],
    search_optionEmployee_LV2: [],
    search_optionIndicatorStatus: [],
    search_optionEmployment: statusWorker ? statusWorker : ["Full"],
    search_optionDepartmentFac: [],
    optionEvaluationEmployee_LV1: [],
    optionEvaluationEmployee_LV2: [],
    optionIndicatorStatus: [],
    optionIndicatorHeader: [],
    optionDepartment: [],
    optionBossApproval: [],
    optionBossInspect: [],
    [`SEARCH_OPTION_${MASTER_OPTION.MONTH}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.YEAR}`]: [new Date().getFullYear()],
    [`SEARCH_OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`]: statusWorker ? statusWorker : ["Full"],
    [`SEARCH_OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.BOSS_INSPECT}`]: [],
    [`SEARCH_OPTION_${MASTER_OPTION.INDICATOR_STATUS}`]: [],

    [`OPTION_${MASTER_OPTION.MONTH}`]: servicesOptions(MASTER_OPTION.MONTH, optionMonth(false), false),
    [`OPTION_${MASTER_OPTION.YEAR}`]: servicesOptions(MASTER_OPTION.YEAR, optionYear(false), false),
    [`OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`]: optionAssessment(),
    [`OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: [],
    [`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: [],
    [`OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: [],
    [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: [],
    [`OPTION_${MASTER_OPTION.BOSS_APPROVAL}`]: [],
    [`OPTION_${MASTER_OPTION.BOSS_INSPECT}`]: [],
    [`OPTION_${MASTER_OPTION.INDICATOR_STATUS}`]: [],
    optionEmployment: optionAssessment(),
    optionMonth: servicesOptions(MASTER_OPTION.MONTH, optionMonth(false), false),
    optionYear: servicesOptions(MASTER_OPTION.YEAR, optionYear(false), false)
}

const MASTER_ACTION = {
    SET_MASTER_SUCCESS: "SET_MASTER_SUCCESS",
    SET_MASTER_FAILURE: "SET_MASTER_FAILURE"
}

const masterReducer = (state, action) => {
    switch (action.type) {
        case MASTER_ACTION.SET_MASTER_SUCCESS:
            return { ...state, ...action.state }
        case MASTER_ACTION.SET_MASTER_FAILURE:
        default:
            return state
    }
}

const ProviderMaster = ({ children }) => {
    // console.log('ProviderMaster' , children)
    const { userLogin } = children.props;
    const [masterState, masterDispatch] = useReducer(masterReducer, initialState)
    // console.log({ masterState })
    // useEffect(() => {
    //     APIMasterIndicatorStatus({ searchText: '' }, res => {
    //         if (res) {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { optionIndicatorStatus: servicesOptions(MASTER_OPTION.INDICATOR_STATUS, res) } })
    //         } else {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
    //         }
    //     })
    //     APIMasterBossApproval({ userLogin: userLogin, searchText: '' }, res => {
    //         if (res) {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { optionBossApproval: servicesOptions(MASTER_OPTION.BOSS_APPROVAL, res) } })
    //         } else {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
    //         }
    //     })
    //     APIMasterBossInspect({ userLogin: userLogin, searchText: '' }, res => {
    //         if (res) {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { optionBossInspect: servicesOptions(MASTER_OPTION.BOSS_INSPECT, res) } })
    //         } else {
    //             masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
    //         }
    //     })
    // }, [userLogin])


    async function getMaster({ fieldname, payload }) {
        //console.log(fieldname, payload)
        switch (fieldname) {
            case MASTER_OPTION.EVALUATION_EMPLOYEE:
                // let mockData = {
                //     endMonth: "06",
                //     // indicatorHeaderId: undefined
                //     searchText: "",
                //     strMonth: "05",
                //     userLogin: payload.userId, // "ZT54020" "DP55012",
                //     year: "2020",
                // }

                //console.log('MASTER_OPTION EVALUATION_EMPLOYEE', payload)
                await APIMasterEvaluationEmployee(payload, res => {
                    // await APIMasterEvaluationEmployee(mockData, res => {
                    if (res) {
                        masterDispatch({
                            type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                optionEvaluationEmployee_LV1: servicesOptions(MASTER_OPTION.EVALUATION_EMPLOYEE, res.LV1),
                                optionEvaluationEmployee_LV2: servicesOptions(MASTER_OPTION.EVALUATION_EMPLOYEE, res.LV2)
                            }
                        })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.INDICATOR_HEADER:
                await APIMasterIndicatorHeader(payload, res => {
                    // console.log({ res })
                    if (res) {
                        masterDispatch({
                            type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                optionIndicatorHeader: servicesOptions(MASTER_OPTION.INDICATOR_HEADER, res)
                            }
                        })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            // case MASTER_OPTION.DEPARTMENT_FACTORY:
            // case MASTER_OPTION.FLOWHRM_FACTORY:
            // case MASTER_OPTION.VIEW_FACTORY:
            // case MASTER_OPTION.FILTER_FACTORY:
            // await APIMasterFilterFactory({ userId: payload.userId }, res => {
            //     if (res) {
            //         masterDispatch({
            //             type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
            //                 [`OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: servicesOptions(MASTER_OPTION.DEPARTMENT_FACTORY, res.result.resultDep,true),
            //                 [`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: servicesOptions(MASTER_OPTION.FLOWHRM_FACTORY, res.result.resultFlowHRM,true),
            //                 [`OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: servicesOptions(MASTER_OPTION.VIEW_FACTORY, res.result.resultView,true),
            //                 [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: servicesOptions(MASTER_OPTION.SUBDEP_FACTORY, res.result.resultsubDep,true),

            //             }
            //         })
            //     } else {
            //         masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
            //     }
            // })
            // break;

            case MASTER_OPTION.FILTER_REPORT_FACTORY:
            case MASTER_OPTION.FILTER_FACTORY:
                // console.log('FILTER_FACTORY', { payload })
                switch (payload.userPermission) {
                    // case 'HR':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'HR-Super':
                    // case 'HR-HeadDep':
                case 'HRS-OFF':
                case 'HRD-OFF':
                case 'HRM-OFF':
                case 'HRS-FAC':
                case 'HRD-FAC':
                case 'HRM-FAC':
                        // case 'HeadDep':
                        await APIMasterFilterSubDepFactory({ subDepNo: '' }, res => {
                            if (res) {
                                masterDispatch({
                                    type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                        [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: servicesOptions(MASTER_OPTION.SUBDEP_FACTORY, res.result, true),
                                    }
                                })
                            } else {
                                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                            }
                        })
                        await APIMasterFilterFactory({ userId: payload.userId }, res => {
                            if (res) {
                                masterDispatch({
                                    type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                        [`OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: servicesOptions(MASTER_OPTION.DEPARTMENT_FACTORY, res.result.resultDep, true),
                                        [`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: servicesOptions(MASTER_OPTION.FLOWHRM_FACTORY, res.result.resultFlowHRM, true),
                                        [`OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: servicesOptions(MASTER_OPTION.VIEW_FACTORY, res.result.resultView, true),
                                        // [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: []// servicesOptions(MASTER_OPTION.SUBDEP_FACTORY, res.result.resultsubDep),

                                    }
                                })
                            } else {
                                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                            }
                        })
                        break;
                    default:
                        await APIMasterFilterFactory({ userId: payload.userId }, res => {
                            if (res) {
                                masterDispatch({
                                    type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                        [`OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: servicesOptions(MASTER_OPTION.DEPARTMENT_FACTORY, res.result.resultDep, true),
                                        [`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: servicesOptions(MASTER_OPTION.FLOWHRM_FACTORY, res.result.resultFlowHRM, true),
                                        [`OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: servicesOptions(MASTER_OPTION.VIEW_FACTORY, res.result.resultView, true),
                                        [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: servicesOptions(MASTER_OPTION.SUBDEP_FACTORY, res.result.resultsubDep, true),

                                    }
                                })
                            } else {
                                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                            }
                        })
                        break;
                }

                break;

            default:
                break;
        }
    }


    async function onSearchMaster({ fieldname, searchText }) {
        // console.log({ fieldname, searchText })
        let result = [];
        switch (fieldname) {

            case MASTER_OPTION.FILTER_FACTORY:
                await APIMasterFilterFactory({
                    userLogin: userLogin,
                    //  searchText 
                }, res => {
                    if (res) {
                        masterDispatch({
                            type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                                [`OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`]: servicesOptions(MASTER_OPTION.DEPARTMENT_FACTORY, res.result.resultDep),
                                [`OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`]: servicesOptions(MASTER_OPTION.FLOWHRM_FACTORY, res.result.resultFlowHRM),
                                [`OPTION_${MASTER_OPTION.VIEW_FACTORY}`]: servicesOptions(MASTER_OPTION.VIEW_FACTORY, res.result.resultView),
                                [`OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`]: servicesOptions(MASTER_OPTION.SUBDEP_FACTORY, res.result.resultsubDep),

                            }
                        })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.EVALUATION_EMPLOYEE:
                await APIMasterEvaluationEmployee({ userLogin: userLogin, searchText }, res => {
                    if (res) {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: servicesOptions(MASTER_OPTION.EVALUATION_EMPLOYEE, res) } })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.INDICATOR_HEADER:
                await APIMasterIndicatorHeader({ userLogin: userLogin, searchText }, res => {
                    if (res) {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: servicesOptions(MASTER_OPTION.INDICATOR_HEADER, res) } })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.INDICATOR_STATUS:
                await APIMasterIndicatorStatus({ searchText }, res => {
                    if (res) {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: servicesOptions(MASTER_OPTION.INDICATOR_STATUS, res) } })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.BOSS_APPROVAL:
                await APIMasterBossApproval({ userLogin: userLogin, searchText }, res => {
                    if (res) {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: servicesOptions(MASTER_OPTION.BOSS_APPROVAL, res) } })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.BOSS_INSPECT:
                await APIMasterBossInspect({ userLogin: userLogin, searchText }, res => {
                    if (res) {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: servicesOptions(MASTER_OPTION.BOSS_INSPECT, res) } })
                    } else {
                        masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                    }
                })
                break;
            case MASTER_OPTION.MONTH:
                result = servicesOptions(MASTER_OPTION.MONTH, optionMonth(false), false).filter(filter => new RegExp(searchText, "i").test(filter.optionLabel))
                if (result) {
                    masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { optionMonth: result } })
                } else {
                    masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                }
                break;
            case MASTER_OPTION.YEAR:
                result = servicesOptions(MASTER_OPTION.YEAR, optionYear(false), false).filter(filter => new RegExp(searchText, "i").test(filter.optionLabel))
                if (result) {
                    masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${fieldname}`]: result } })
                } else {
                    masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                }
                break;
            case MASTER_OPTION.OPTION_FORM_EVAC:
                //console.log('OPTION_FORM_EVAC')
                // result = servicesOptions(MASTER_OPTION.YEAR, optionYear(false), false).filter(filter => new RegExp(searchText, "i").test(filter.optionLabel))
                // if (result) {
                //     masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { optionYear: result } })
                // } else {
                //     masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
                // }
                break;
            default:
                break;
        }

    }



    async function onChangeMaster({ fieldOption, checkedList, checkAll, plainOptions }) {
        //console.log({ fieldOption })
        switch (fieldOption) {
            case `OPTION_${MASTER_OPTION.YEAR}`:
            case `OPTION_${MASTER_OPTION.MONTH}`:
            case `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`:
            case `OPTION_${MASTER_OPTION.DEPARTMENT_FACTORY}`:
            case `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`:
            case `OPTION_${MASTER_OPTION.VIEW_FACTORY}`:
            case `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`:
            case `OPTION_${MASTER_OPTION.INDICATOR_STATUS}`:
                // console.log({ fieldOption, checkedList, checkAll, plainOptions })
                await masterDispatch({
                    type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                        [`SEARCH_${fieldOption}`]: (checkAll) ? plainOptions : checkedList
                    }
                })

                break;
            case `OPTION_${MASTER_OPTION.BOSS_APPROVAL}`:
            case `OPTION_${MASTER_OPTION.BOSS_INSPECT}`:
                // console.log({ fieldOption, checkedList, checkAll, plainOptions })
                await masterDispatch({
                    type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                        [`SEARCH_${fieldOption}`]: checkedList[0]
                    }
                })

                break;
            default:
                // await masterDispatch({
                //     type: MASTER_ACTION.SET_MASTER_SUCCESS, state: {
                //         [`search_${fieldOption}`]: (checkAll) ? plainOptions : checkedList
                //     }
                // })
                break;
        }

    }

    async function defaultMaster() {
        await APIMasterIndicatorStatus(null, res => {
            if (res) {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${MASTER_OPTION.INDICATOR_STATUS}`]: servicesOptions(MASTER_OPTION.INDICATOR_STATUS, res) } })
            } else {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
            }
        })
        await APIMasterBossApproval({ userLogin: userLogin }, res => {
            if (res) {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${MASTER_OPTION.BOSS_APPROVAL}`]: servicesOptions(MASTER_OPTION.BOSS_APPROVAL, res) } })
            } else {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
            }
        })
        await APIMasterBossInspect({ userLogin: userLogin }, res => {
            if (res) {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_SUCCESS, state: { [`OPTION_${MASTER_OPTION.BOSS_INSPECT}`]: servicesOptions(MASTER_OPTION.BOSS_INSPECT, res) } })
            } else {
                masterDispatch({ type: MASTER_ACTION.SET_MASTER_FAILURE })
            }
        })
    }

    return (
        <MasterContext.Provider
            value={{
                onSearch: onSearchMaster,
                onChange: onChangeMaster,
                masterState,
                getMaster,
                defaultMaster,
                masterDispatch
            }}>
            {children}
        </MasterContext.Provider>
    )
}


export default ProviderMaster