import React from 'react'
import ProviderMaster from '../master/masterProvider'
import ProviderComposer from './providerComposer'
import ProviderIndicator from '../manage/indicatorProvider'
import ProviderReport from '../report/reportProvider'
import ProviderEvaluation from '../manage/evaluationProvider'
import ProviderModal from '../modal/modalProvider'
import ProviderMasterOT from '../../../moduleOT/servicesOT/masterOT/masterOTProvider'
import ProviderManageOT from '../../../moduleOT/servicesOT/manageOT/manageOTProvider'
import ProviderReportOT from '../../../moduleOT/servicesOT/reportOT/reportOTProvider'
import ProviderManageEvac from '../../../moduleEvac/servicesEvac/manageEvac/manageEvacProvider'
import ProviderMasterEvac from '../../../moduleEvac/servicesEvac/masterEvac/masterEvacProvider'
import ProviderReportEvac from '../../../moduleEvac/servicesEvac/reportEvac/reportEvacProvider'
import ProviderManageTraining from '../../../moduleTraining/servicesTraining/manageTraining/manageTrainingProvider'
import ProviderMasterTraining from '../../../moduleTraining/servicesTraining/masterTraining/masterTrainingProvider'
import ProviderMasterCenter from '../../../service/master/masterProvider'
import ProviderContract from '../../../moduleContract/servicesContract/contractProvider'
import ProviderPersonal from '../../../modulePersonal/servicesPersonal/personalProvider'

const ProviderState = ({ children }) => {
    // contexts : ด้านซ้ายสุดคือ provider ใหญ่สุด
    return (
        <ProviderComposer contexts={[
            <ProviderModal />,
            <ProviderMasterCenter />,
            <ProviderMaster />,
            <ProviderMasterOT />,
            <ProviderMasterEvac />,
            <ProviderMasterTraining />,
            <ProviderPersonal />,
            <ProviderReport />,
            <ProviderReportOT />,
            <ProviderReportEvac />,
            <ProviderContract />,
            <ProviderManageEvac />,
            <ProviderManageTraining />,
            <ProviderManageOT />,
            <ProviderIndicator />,
            <ProviderEvaluation />,
        ]} >
            {children}
        </ProviderComposer>
    )
}

export default ProviderState