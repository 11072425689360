import React, { Component } from "react";
import { path } from "ramda";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Container } from "reactstrap";
import { SelectInput, DateInput } from "../constants/inputs";
import { optionSumProbation } from "../common/enum/index";
import { ButtonTheme } from "../constants/buttons/ButtonTheme";
import ProbationTable from "../components/probation/ProbationTable";
import * as Layouts from "../constants/layouts";
import { INITIAL_FILTER_PROBATION } from "../components/probation/initialProbation";
import {
  APIPermission,
  APIDashboardProbation,
  APIProbation,
  APIConfirmProbation,
  GET,
  GET_SELECT_PERMISSION,
} from "../service";
import { SetDateAPI } from "../common/setDate";
import { PATHNAME } from "../constants/enums/pathname";
import WorkingProcess from "../components/workingProcess";
import { Modal, Spin } from "antd";
import { TEXT_LOADING } from "../constants/enums/enumCenter";
import { filterDashboardProbation } from "../components/probation/ProbationContantsRender";
import { RenderForm } from "./../constants/inputs/RenderForm";
import {
  POST,
  SELECT_DASHBOARD_PROBATION,
  SELECT_DASHBOARD_PROBATION_VIEW,
  GET_SELECT_EMPLOYEE_HISTORY,
} from "./../service/index";
import { Row, Col, Tabs } from "antd";
import ProbationViewTable from "../components/probation/ProbationViewTable";

const { TabPane } = Tabs;

class DashboardProbation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userLogin: this.props.userLogin,
      locationM: this.props.locationM,
      userRole: this.props.premission,
      initialFilter: INITIAL_FILTER_PROBATION,
      dataSource: [],
      optionSegment: [],
      optionEmployee: [],
      SegmentTxt: "",
      txtOption: { value: "value", label: "label" },
    };
  }

  componentDidMount() {
    // console.log('componentDidMount ::', this.props)

    this.getProbationRole();
    this.getSelectEmployeeHistory();
  }

  //----- Start :: API -----//
  getProbationRole = async () => {
    try {
      this.setState({ loading: true });
      const res = await GET(GET_SELECT_PERMISSION(this.state.userLogin));
      //let optionSegment = res.result.depArr.map(el => {
      let optionSegment = res.result["depArrByFlowHrm"].map((el) => {
        return { value: el.depNo, label: el.depName };
      });
      optionSegment = [
        { value: "", label: "----- ทั้งหมด -----" },
        ...optionSegment,
      ];
      this.setState({ optionSegment });
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getSelectEmployeeHistory = async () => {
    try {
      this.setState({ loading: true });
      const res = await GET(GET_SELECT_EMPLOYEE_HISTORY());
      //console.log('res getSelectEmployeeHistory', res)
      const { success, result } = res;
      if (success) {
        let employee = result.map((el) => {
          return { value: el.userId, label: `${el.userId} : ${el.fullName}` };
        });
        let optionEmployee = [
          { value: "", label: "----- ทั้งหมด -----" },
          ...employee,
        ];
        this.setState({ optionEmployee });
      }
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };
  //----- End :: API -----//

  //----- Start :: Function -----//
  handleFilter = (fieldname, value) => {
    //console.log(fieldname, value)
    let initialFilter = { ...this.state.initialFilter };
    let SegmentTxt = "";
    switch (fieldname) {
      case "SegmentTxt":
        initialFilter = { ...initialFilter, [fieldname]: value.value };
        SegmentTxt = value.value;
        this.setState({ SegmentTxt });
        break;
      case "statusMainCode":
      case "searchUser":
        initialFilter = { ...initialFilter, [fieldname]: value.value };
        break;
      default:
        initialFilter = { ...initialFilter, [fieldname]: value };
        break;
    }
    //('initialFilter wwwwwwwwww', initialFilter)
    this.setState({ initialFilter });
  };

  handleLocation = async ({ pathname, state, status }) => {
    try {
      //console.log('[handleLocation] ::', pathname, state, status, this.props)
      this.setState({ loading: true });
      const { router } = this.props;
      let pathURL = `${"DocNo="}${state.DocNo}`;
      //  this.setState({ loading: true })

      switch (status) {
        case "confirm":
          let data = {
            DocNo: state.DocNo,
            user_login: this.state.userLogin,
          };
          return await APIConfirmProbation(pathURL, data).then((res) => {
            if (!!res) {
              Modal.success({
                title: `${"ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร "}${data.DocNo || ""
                  }`,
                onOk: () => window.location.reload(),
              });
            }
            // alert(`${'ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร '}${data.DocNo || ""}`)
            // window.location.reload(true);
          });

        case "update":
          let initialData = null;
          let payload = { docNo: state.DocNo };
          return await APIProbation(payload).then((res) => {
            initialData = res.result;
            router.push({ pathname, state: initialData });
          });
        default:
          router.push({ pathname, state });
          break;
      }
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getDashboardProbation = async (_payload) => {
    try {
      this.setState({ loading: true });
      let res = await POST(SELECT_DASHBOARD_PROBATION, _payload);
      let resView = await POST(SELECT_DASHBOARD_PROBATION_VIEW, _payload);
      //console.log('res', res)
      const { success, result } = res;
      //const resultView = resView.result;
      if (success) {
        this.setState({ dataSource: result, dataView: resView.result || [] });
      }
    } catch (err) {
      Modal.error({ title: `${err.message}` });
      this.setState({ dataSource: [], dataView: [] });
      console.log({ err });
    } finally {
      this.setState({ loading: false });
    }
  };

  functionButton = async (name) => {
    const { SegmentTxt, initialFilter, userLogin, locationM } = this.state;
    this.setState({ loading: true });
    //console.log(this.state)
    switch (name) {
      case "search":
        let _payload = {
          userlogin: userLogin,
          // userID: this.state.userLogin,
          locationLogin: locationM,
          depNo: SegmentTxt == "all" ? "" : SegmentTxt,
          statusMainCode: initialFilter.statusMainCode || "",
          startDate: SetDateAPI(initialFilter.StartDateJob),
          toDate: SetDateAPI(initialFilter.ToDateJob),
          searchUser: initialFilter.searchUser,
        };
        //console.log({ _payload })
        await this.getDashboardProbation(_payload);
        break;
      default:
        return null;
    }
  };

  //----- End :: Function -----//

  render() {
    const {
      userRole,
      userLogin,
      loading,
      initialFilter,
      optionSegment,
      txtOption,
      optionEmployee,
    } = this.state;
    return (
      <Spin spinning={loading} tip={TEXT_LOADING}>
        <Container>
          <Layouts.MainTheme
            header={"Dashboard Probation"}
            component={
              <>
                {filterDashboardProbation({
                  handlechange: this.handleFilter,
                  initialFilter,
                  optionSegment,
                  optionSumProbation,
                  optionEmployee,
                  txtOption,
                  functionButton: this.functionButton,
                }).map((row, i) => (
                  <Row key={i} gutter={[16, 16]}>
                    {row.map((each, ie) => (
                      <Col
                        key={ie}
                        md={each.size}
                        className={each.className}
                        style={each.styleCol}
                      >
                        {RenderForm(each)}
                      </Col>
                    ))}
                  </Row>
                ))}
                <WorkingProcess pathname={PATHNAME.DASHBOARD_PROBATION} />
              </>
            }
          ></Layouts.MainTheme>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Action" key="1">
              <ProbationTable
                userRole={userRole}
                userLogin={userLogin}
                dataSource={this.state.dataSource}
                handleLocation={this.handleLocation} //สำหรับเปลี่ยนหน้า
              />
            </TabPane>
            <TabPane tab="View" key="2">
              <ProbationViewTable
                userRole={userRole}
                userLogin={userLogin}
                dataSource={this.state.dataView}
                handleLocation={this.handleLocation} //สำหรับเปลี่ยนหน้า
              />
            </TabPane>
          </Tabs>
        </Container>
      </Spin>
    );
  }
}

export default DashboardProbation;
