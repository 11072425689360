import React, { useRef, useState } from "react";
import { Form, Card, Space, Divider, Row, Col, Spin, Typography } from "antd";
import { useColumnTable } from "../../constants/UseColumnTable";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import CsvParse from "@vtex/react-csv-parse";
import { useButton } from "../buttons/CustomButtonAntd";
import { ModalUploadTemplete } from "../modal/CustomModalAntd";
import { GET_UserLogin, sumColumnWidth } from "../../functions/UseFunction";
import { CSVLink } from "react-csv";
import { useOptionSelect } from "../../constants/OptionSelect";
import { SelectAntd } from "../select/SelectAntd";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { POST, POST_GET_DATA_JG_PG, POST_UPDATE_DATA_JG_PG } from "../../../service";
import { useConfirm } from "../modal/CustomConfirm";

export const FormImportTempleteFAC = () => {
    const [form] = Form.useForm();
    const { userlogin } = GET_UserLogin();
    const { ColumnJG_PG } = useColumnTable({});

    const [ChangeSearchValue, setChangeSearchValue] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [arrExcel, setArrExcel] = useState([]);
    const [dataTable, setDataTable] = useState([]);

    const [loadingScreen, setLoadingScreen] = useState(false);
    const inputUpload = useRef(null);

    const CopyChangeSearch = {
        ...ChangeSearchValue,
        companyCode: ChangeSearchValue["company"],
    };

    const onFinish = (values) => {
        const { company, depNo } = values
        const objectBody = {
            company: company || "",
            depNo: depNo || "",
            payrollGroup: "dailyFac",
        }
        getDataJG_PG(objectBody)
    };

    const onReset = () => {
        form.resetFields();
        setDataTable([]);
        setChangeSearchValue({});
    };

    const SET_DATA = (data) => {
        setLoadingScreen(true)
        setArrExcel(data);
        setLoadingScreen(false)
    };

    const uploadTemplete = async () => {
        if (arrExcel.length !== 0) {
            try {
                setLoadingScreen(true);
                const dataUpdate = arrExcel.map((val) => ({
                    userID: val.userID,
                    JG: val.JG,
                    PG: val.PG,
                    userlogin: userlogin,
                }));
                const response = await POST(POST_UPDATE_DATA_JG_PG, dataUpdate)
                const { success } = response
                if (success) {
                    useAlert({
                        type: "success",
                        title: "การ Upload Templete สำเร็จ",
                        Fn: () => window.location.reload(),
                    });
                }
            } catch (err) {
                console.log("Error Upload Templete: ", err);
                useAlert({
                    type: "error",
                    title: "เกิดผิดพลาดจากการ Upload กรุณาลองใหม่อีกครั้ง",
                    content: `${err.message}`,
                    Fn: () => {
                        setArrExcel([]);
                        setLoadingScreen(false)
                    },
                });
            }
        } else {
            useAlert({
                type: "warning",
                title: `กรุณาแนบไฟล์กด "Upload"`,
                Fn: () => setArrExcel([]),
            });
        }
    };

    const getDataJG_PG = async (objectBody) => {
        try {
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_GET_DATA_JG_PG, objectBody);
            const { result } = response
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map(row => ({
                    key: row.userId,
                    ...row,
                }))
            }
            setDataTable(mapResult)
        } catch (err) {
            console.log("Error Get Data JG PG FAC: ", err);
            useAlert({ type: "error", title: "เกิดข้อผิดพลาด", content: `${err.message}` })
            setDataTable([]);
        } finally {
            setLoadingScreen(false);
        }
    };

    const extraCard = () => {
        const headerCSV = [
            { label: "รหัสพนักงาน", key: "userId" },
            { label: "JG", key: "JG" },
            { label: "PG", key: "PG" },
        ];
        return (

            <Space split={<Divider type="vertical" />}>
                <CSVLink
                    headers={headerCSV}
                    data={dataTable}
                    filename={"TemplateJG_PG.csv"}
                >
                    Export Template CSV
                </CSVLink>
                {useButton({
                    call: "normal",
                    fn: () => setShowModal(true),
                    moreProps: { label: "Import Template JG PG (CSV)", type: "link" },
                })}
            </Space>

        );
    };

    const ContentModal = (setData, loading) => {
        const keys = ["userID", "JG", "PG"];
        return (

            <Spin tip="Loading..." spinning={loading}>
                <Space direction="vertical">
                    <Typography.Text type="danger">
                        ** รองรับเฉพาะไฟล์ .csv เท่านั้น **
                    </Typography.Text>
                    <CsvParse
                        keys={keys}
                        onDataUploaded={setData}
                        render={(onChange) => (
                            <input type="file" onChange={onChange} accept=".csv" ref={inputUpload} />
                        )}
                    />
                </Space>
            </Spin>

        );
    };

    const onChangeValue = (val, name) => {
        let setUndefined = {};
        if (name === "company") {
            setUndefined = { depNo: undefined };
        }
        form.setFieldsValue(setUndefined);
        setChangeSearchValue((prev) => ({
            ...prev,
            ...setUndefined,
            [name]: val,
        }));
    };

    return (

        <Spin tip="Loading..." spinning={loadingScreen}>
            <ModalUploadTemplete
                show={showModal}
                close={() => {
                    inputUpload.current.value = ""
                    setArrExcel([]);
                    setShowModal(false)
                }}
                fnOK={() => useConfirm({ FnOK: () => uploadTemplete() })}
                txtTitle={`Import Templete JG PG (CSV)`}
                ModalContent={ContentModal(SET_DATA, loadingScreen)}
            />
            <Card title="อัพเดทข้อมูลแก้ไข JG PG (โรงงาน)" extra={extraCard()}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    {...configForm}
                >
                    <Row gutter={[16, 16]} justify="start" align="bottom">
                        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
                            <SelectAntd
                                name={`company`}
                                label={`บริษัท`}
                                smgReq={`กรุณาเลือก บริษัท`}
                                placeholder={`เลือก บริษัท`}
                                dropdown={useOptionSelect("company")}
                                FnOnChange={onChangeValue}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={10} xl={8}>
                            <SelectAntd
                                name={`depNo`}
                                label={`แผนก`}
                                smgReq={`กรุณาเลือก แผนก`}
                                placeholder={`เลือก แผนก`}
                                dropdown={useOptionSelect(
                                    "department",
                                    useFixedQueryString(CopyChangeSearch, ["companyCode"])
                                )}
                                FnOnChange={onChangeValue}
                                required={false}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={3} xl={2}>
                            <Form.Item>{useButton({ call: "search" })}</Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={3} xl={2}>
                            <Form.Item>
                                {useButton({
                                    call: "normal",
                                    fn: onReset,
                                    moreProps: { label: "ล้างข้อมูล" },
                                })}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {dataTable.length !== 0 && (
                    <CustomTableAtnd
                        HeadTable={ColumnJG_PG}
                        DataTable={dataTable}
                        SetScroll={{ x: sumColumnWidth(ColumnJG_PG), y: 500 }}
                    />
                )}
            </Card>
        </Spin>

    );
};

export default FormImportTempleteFAC;
