import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Space, Spin, Typography } from "antd";
import ReportPage from "../../components/global/pages/ReportPage";
import { useButton } from "../../screens/conponents/buttons/CustomButtonAntd";
import { useAlert } from "../../screens/conponents/modal/CustomAlert";
import { formFilter } from "./forms/reportFilter";
import { FormColumn } from "./constants/tableColumn";
import { useFormState } from "./hook/useFormState";
import { useGetOptions } from "./hook/useGetOptions";
import { useGetDataSource } from "./hook/useGetDataSource";
import { useModal } from "./hook/useModal";
import { POST, SAVE_MONITOR_FILE_STATUS } from "../../service";
import { SetStringToObject } from "../../constants/functions";
import Cookies from "js-cookie";
import encodeString from "../../extendFunction/encodeString";
import { useGetFileDownload } from "./hook/useGetFileDownload";
import { CONNECT_API, USE_CONNECTION_MODE } from "../../components/connectAPI";
const { Paragraph, Link } = Typography;

const DashboardMonitorFile = () => {
  const { defaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });
  const [loadingScreen, setLoadingScreen] = useState(false);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));
  const { dataDownload, onFetchDataDownload } =
  useGetFileDownload();
  useEffect(
    () => {
      callDownload();
     },
    [dataDownload],    
  );

  const callDownload = () => {
    if (dataDownload) {
      window.open(
        `${CONNECT_API[USE_CONNECTION_MODE].URL}${dataDownload}`,
        "_blank"
      );        
    }
  }  
  const getLinkDownload = async ({employeeNo}) => {
    await onFetchDataDownload({employeeNo})
    callDownload();
  }
  const {
    optionsEmp,
    optionsCompany,
    optionsDepartment,
    optionStatus,
    optionStatusGuarantee,
    loadingOption,
  } = useGetOptions();
  const { dataSource, onFetchDataSource, loadingDataSource } =
    useGetDataSource();
  const { isModalOpen, file, showModal, handleOk, handleCancel } = useModal();
  const [selectRow, setSelectRow] = useState({
    selectedRowKeys: [],
    loading: false,
  });

  const { selectedRowKeys, loading } = selectRow;
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectRow({
        ...selectRow,
        selectedRowKeys: selectedRowKeys,
      });
    },
  };

  //CALL API
  const insertConfirmData = (data, callType) => {
    const employeeNoList = data;
    useAlert({
      type: "confirm",
      title: "ยืนยันการบันทึกข้อมูล",
      Fn: () => onSaveConfirm(employeeNoList, callType),
    });
  };

  const onSaveConfirm = async (employeeNoList, callType) => {
    try {
      setLoadingScreen(true);
      const response = await POST(SAVE_MONITOR_FILE_STATUS, {
        employeeNoList,
        callType,
        userConfirm: encodeString.decode(cookies.ID_User),
      });
      const { success, message } = response;
      if (success) {
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          content: message,
          Fn: () => {
            onFetchDataSource({
              company: "all",
              departmentNo: "all",
              employeeNo: "all",
              fileStatus: "WAIT",
            });
            setSelectRow({ selectedRowKeys: [] });
          },
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.error("Error: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      <ReportPage
        title={"ติดตามไฟล์เอกสาร"}
        filterForm={formFilter({
          optionsCompany,
          optionsDepartment,
          optionsEmp,
          optionStatus,
          optionStatusGuarantee,
        })}
        filterLoading={
          loadingOption.company ||
          loadingOption.departmentNo ||
          loadingOption.employeeNo ||
          loadingOption.fileStatus
        }
        tableLoading={loadingDataSource}
        tableColumn={FormColumn({ showModal, getLinkDownload })}
        dataSource={dataSource}
        onSearchClick={handleSubmit(onFetchDataSource)}
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: () => {
            setSelectRow(record);
          },
        })}
        loading={loading}
        control={control}
        setValue={setValue}
        getValues={getValues}
      />
      <div style={{ textAlignLast: "center", marginTop: "15px" }}>
        <Space>
          {useButton({
            call: "save",
            isDisable: !(
              dataSource.length > 0 && selectRow.selectedRowKeys.length > 0
            ),
            moreProps: {
              label: "ยืนยันข้อมูล",
              onClick: () => {
                insertConfirmData(selectRow.selectedRowKeys, "CONFIRM");
              },
            },
          })}
          {useButton({
            call: "normal",
            isDisable: !(
              dataSource.length > 0 && selectRow.selectedRowKeys.length > 0
            ),
            moreProps: {
              label: "ย้อนสถานะ",
              onClick: () => {
                insertConfirmData(selectRow.selectedRowKeys, "REVERT");
              },
            },
          })}
        </Space>
      </div>

      <Space>
        <Modal
          title="ไฟล์แนบ"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={500}
        >
          <Paragraph>
            {file.map((e) => (
              <ul>
                <li>
                  <Link
                    href={`${CONNECT_API[USE_CONNECTION_MODE].URL}${e.filePath}`}
                    target="_blank"
                  >
                    {e.fileName}
                  </Link>
                </li>
              </ul>
            ))}
          </Paragraph>
        </Modal>
      </Space>
    </Spin>
  );
};

export default DashboardMonitorFile;
