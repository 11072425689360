import { PATHNAME } from "../../../constants/enums/pathname"

let DEFAULT_BREADCRUMB = [
    { label: "HRM - PMS" },
    { label: "พนักงานใหม่ - พ้นสภาพ" },
]


export function renderLabelWithPathname(pathname) {
    switch (pathname) {
        // case PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE: return "รายงานติดตามเอกสารค้ำประกัน"
        case PATHNAME.DASHBOARD_PROBATION_CONTRACT: return "แบบประเมินทดลองงาน (สัญญาจ้าง)"
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT: return "รายงานพนักงานช่วงทดลองงาน (สัญญาจ้าง)"
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW: return "รายงานพนักงานช่วงทดลองงาน (สัญญาจ้าง) อันใหม่"
        case PATHNAME.DASHBOARD_TRACK_CONTRACT: return "ค้างรับเอกสารทดลองงาน (สัญญาจ้าง)"
        case PATHNAME.DASHBOARD_BENEFIT_CONTRACT: return "ค้างรับสวัสดิการพนักงานใหม่ (สัญญาจ้าง)"
        case PATHNAME.INSERT_PROBATION_CONTRACT: return "สร้างแบบประเมินทดลองงาน (สัญญาจ้าง)"
        case PATHNAME.UPDATE_PROBATION_CONTRACT: return "แก้ไขแบบประเมินทดลองงาน (สัญญาจ้าง)"
        case PATHNAME.VIEW_PROBATION_CONTRACT: return "ดูแบบประเมินทดลองงาน (สัญญาจ้าง)"
        default:
            break;
    }
}

export const BREADCRUMB_CONTRACT = (_pathname) => {
    return [...DEFAULT_BREADCRUMB, { label: renderLabelWithPathname(_pathname) }]
}
