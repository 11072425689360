export const MODAL_TYPE = {
    EVALUATION_INDICATOR: "EVALUATION_INDICATOR",
    EVALUATION_SCORE: "EVALUATION_SCORE",
    APPROVAL_INDICATOR:"APPROVAL_INDICATOR",
    APPROVAL_VIEW:"APPROVAL_VIEW",
    INDICATOR_VIEW:"INDICATOR_VIEW"
}

export const COMP_TYPE = {
    SEARCH_FORM: "SEARCH_FORM"
}