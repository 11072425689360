import React, { useState, useEffect } from "react";
import { Card, Col, Form, Modal, Row, Space, Spin } from "antd";
import Cookies from "js-cookie";
import moment from "moment";

import { useRenderFormItem } from "./conponents/RenderComponents";
import { configForm } from "./constants/ConstantComponent";
import {
  ComponentImportSaleTarget,
  ComponentInsertSaleTarget,
  ExcelToJSON,
  MapDataExcel,
} from "./constants/InsertSaleTargetFSP/Components";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useAlert } from "./conponents/modal/CustomAlert";
import { GET_TARGET, POST, POST_SAVE_TARGET } from "../service";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import {
  ColumnInsertSaleTargetFSP,
  ColumnViewInsertSaleTargetFSP,
} from "./constants/InsertSaleTargetFSP/Columns";
import { optionMonths, optionYears } from "./constants/OptionSelect";
import { getCurrent } from "../extendFunction/getCurrentYears";
import { getMonthNow } from "../functions";
import encodeString from "../extendFunction/encodeString";
import { SetStringToObject } from "../constants/functions";
import { getPeriodDate } from "../functions";
import { useExportExcel } from "./services/ExportExcel";
import { Header_InsertSaleTargetFSP } from "./constants/HeaderExcel";

const InsertSaleTargetFSP = () => {
  const [form] = Form.useForm();
  const cookies = SetStringToObject(Cookies.get("cookie_session"));
  const [tableForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [remark, setRemark] = useState("");
  const [newData, setNewData] = useState([]);
  const [searchValue, setSearchValue] = useState({
    year: "",
    month: "",
  });
  const yearNow = getCurrent().yearsNow;
  const monthNow =
    moment(new Date()).format("DD") > 28
      ? getMonthNow(getCurrent().monthNow)
      : getMonthNow(getCurrent().monthNow - 1);

  useEffect(() => {
    onSearch("");
    form.setFieldsValue({
      ["year"]: yearNow.toString(),
      ["month"]: monthNow[0],
    });
  }, []);

  const options = {
    year: optionYears,
    month: optionMonths,
  };

  const onSearch = (values) => {
    const { year, month } = values;
    const objBody = {
      year: year || yearNow.toString(),
      month: month || monthNow[0],
    };
    onGetTarget(objBody);
    setSearchValue(objBody);
  };

  const onGetTarget = async (objBody) => {
    setLoading(true);
    try {
      const res = await POST(GET_TARGET, objBody);
      const { success, result } = res;
      if (success) {
        const mapResult = result.map((item, key) => ({
          key: key + 1,
          ...item,
        }));
        setDataSource(mapResult);
        setNewData(mapResult);
        setRemark(mapResult[0].remark);
        tableForm.resetFields();
      }
    } catch (error) {
      useAlert({
        type: "catch",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    const dataLine = newData.map((item) => ({
      saleID: item.roleId,
      saleQTY: !isNaN(+item.saleQTY)
        ? +item.saleQTY
        : parseFloat(item.saleQTY.replace(/,/g, "")),
      tempTarget: !isNaN(+item.tempTarget)
        ? +item.tempTarget
        : parseFloat(item.tempTarget.replace(/,/g, "")),
      accTarget: !isNaN(+item.accTarget)
        ? +item.accTarget
        : parseFloat(item.accTarget.replace(/,/g, "")),
    }));

    const objBody = {
      year: searchValue.year,
      month: searchValue.month,
      loginUser: encodeString.decode(cookies.ID_User),
      line: dataLine,
    };

    useAlert({
      type: "confirm",
      title: "ยืนยันการบันทึก",
      Fn: () => onSaveTarget(objBody),
    });
  };

  const onSaveTarget = async (objBody) => {
    setLoading(true);
    try {
      const res = await POST(POST_SAVE_TARGET, objBody);
      const { success } = res;
      if (success) {
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          Fn: () => onSearch(""),
        });
      }
    } catch (error) {
      useAlert({
        type: "catch",
      });
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const onChangeValue = (values) => {
    const { key, value, fieldName } = values;
    setNewData((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            [fieldName]: value,
          };
        }
        return { ...row };
      });
    });
  };

  const handleChangeImportSaleTarget = async ({ file, fileList }) => {
    try {
      setLoading(true);
      const fileName = file.name.split(".");
      const fileType = fileName[fileName.length - 1];
      if (fileList && fileList.length > 0 && fileType !== "xlsx") {
        Modal.warning({
          title: "กรุณาอัปโหลดไฟล์ .xlsx เท่านั้น",
        });
        return;
      }
      const res = await ExcelToJSON(file);
      const dataUpload = MapDataExcel(res[0].data);

      if (dataUpload && dataUpload.length > 0) {
        useAlert({
          type: "success",
          title: "นำเข้าไฟล์สำเร็จ",
          Fn: () => "",
        });
        const mapResultImport = dataUpload.map((item, key) => {
          tableForm.setFieldsValue({
            [`tempTarget_${key + 1}`]: item.tempTarget,
          });
          tableForm.setFieldsValue({
            [`accTarget_${key + 1}`]: item.accTarget,
          });
          tableForm.setFieldsValue({ [`saleQTY_${key + 1}`]: item.saleQTY });
          return {
            key: key + 1,
            ...item,
            yearMonth: `${yearNow.toString()}/${monthNow[0]}`,
          };
        });
        // console.log("mapResultImport>", mapResultImport);
        setDataSource([]);
        setDataSource(mapResultImport);
        setNewData(mapResultImport);
      } else {
        Modal.warning({
          title: "ข้อมูลที่นำเข้าไม่ถูกต้องตาม Template",
        });
        return;
      }
    } catch (error) {
      console.error("error>", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={onSearch} {...configForm}>
        <Card
          title={`Insert sale target (รอบวันที่ ${getPeriodDate("display")})`}
          style={{ marginBottom: 30 }}
        >
          {useRenderFormItem({
            constantComponent: ComponentInsertSaleTarget({
              options,
            }),
          })}
          <Space
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {useButton({ call: "search" })}
            {useButton({
              call: "normal",
              fn: onReset,
              moreProps: { label: "ล้างข้อมูล" },
            })}
          </Space>
        </Card>
      </Form>
      <Form form={tableForm} onFinish={onSubmit}>
        <Card>
          {searchValue.year === yearNow.toString() &&
          searchValue.month === monthNow[0] ? (
            <Row>
              <Col span={12}>
                {useRenderFormItem({
                  constantComponent: ComponentImportSaleTarget({
                    options: [],
                    handleChange: handleChangeImportSaleTarget,
                  }),
                })}
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {useExportExcel({
                  header: Header_InsertSaleTargetFSP,
                  dataset: dataSource,
                  filename: `ข้อมูลเป้าสาขาโฟกัสช็อป-${searchValue.year}-${searchValue.month}`,
                  title: `Download Template`,
                })}
              </Col>
            </Row>
          ) : (
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {useExportExcel({
                header: Header_InsertSaleTargetFSP,
                dataset: dataSource,
                filename: `ข้อมูลเป้าสาขาโฟกัสช็อป-${searchValue.year}-${searchValue.month}`,
                title: `Download Template`,
              })}
            </Col>
          )}

          <CustomTableAtnd
            title={
              remark &&
              (() => (
                <div style={{ fontSize: "15px" }}>
                  หมายเหตุ :<span style={{ color: "red" }}> {remark}</span>
                </div>
              ))
            }
            HeadTable={
              searchValue.year === yearNow.toString() &&
              searchValue.month === monthNow[0]
                ? ColumnInsertSaleTargetFSP({
                    actions: { onChangeValue },
                  })
                : ColumnViewInsertSaleTargetFSP()
            }
            DataTable={dataSource}
          />
        </Card>
        <br />
        {searchValue.year === yearNow.toString() &&
          searchValue.month === monthNow[0] && (
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {useButton({ call: "save" })}
            </Space>
          )}
      </Form>
    </Spin>
  );
};

export default InsertSaleTargetFSP;
