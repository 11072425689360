import React, { useState, useRef, useEffect } from "react";
import { Spin, Form, Space, Card, Tabs } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentImportMapFocusShopSaleCode } from "./constants/ImportMapFocusShopSaleCode/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnFocusShopStoreMaster, ColumnImportMapFocusShopSaleCode } from "./constants/ImportMapFocusShopSaleCode/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { useAlert } from "./conponents/modal/CustomAlert";
import { POST, GET_MAP_FOCUS_SHOP_SALE_CODE, GET, GET_SALE_CODE_OPTION, IMPORT_MAP_FOCUS_SHOP_SALE_CODE_BY_ROWS, GET_FOCUS_SHOP_STORE_MASTER } from '../service/index';
import servicesAPI from "../moduleKPIs/services/servicesAPI";
import { getDatePeriodNow, getPeriodDate } from "../functions";
import { useExportExcel } from './services/ExportExcel';
import { Header_ImportMapFocusShopSaleCode } from './constants/HeaderExcel';
import moment from "moment";

const API = servicesAPI.create()

const ImportMapFocusShopSaleCode = (props) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false)
	const [fileList, setFileList] = useState([])
	const [dataSource, setDataSource] = useState([])
	const [focusShopStore, setFocusShopStore] = useState([])
	const [optionSaleCode, setOptionSaleCode] = useState([])
	const [rowsEdit, setRowsEdit] = useState([])
	const [isDisable, setIsDisable] = useState(true)

	useEffect(() => {
		fetchApi()
	}, [])

	const fetchApi = async () => {
		async function fetchOptions() {
			await Promise.all([
				getMapFocusShopSaleCode(),
				getSaleCodeOption(),
				getFocusShopStoreMaster(),
			])
		}
		await fetchOptions()
	}

	const onChangeFile = (name, value) => {
		if (value.fileList[0]) {
			setFileList(value.fileList)
			// validateFile(value.fileList[0])
		}
	}

	const getFocusShopStoreMaster = async () => {
		try {
			setLoading(true)
			const response = await GET(GET_FOCUS_SHOP_STORE_MASTER);
			const { result, success } = response
			if (success) {
				setFocusShopStore(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const getSaleCodeOption = async () => {
		try {
			setLoading(true)
			const response = await GET(GET_SALE_CODE_OPTION);
			const { result, success } = response
			if (success) {
				setOptionSaleCode(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const getMapFocusShopSaleCode = async () => {
		try {
			setLoading(true)
			let obj = {
				startDate: moment(getDatePeriodNow('start')).format("YYYY-MM-DD"),
				// startDate: '2022-01-21',
			}
			let res = await POST(GET_MAP_FOCUS_SHOP_SALE_CODE, obj)
			const { result, success } = res

			if (success) {
				setDataSource([...result])
				// window.open(`${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`).focus();
			}
		} catch (err) {
			//alert
			console.log('err excel', err)
			// useAlert({ type: 'catch', title: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onFinish = async (isNotUpload) => {
		try {
			setLoading(true)
			let res = ''
			let startDate = moment(getDatePeriodNow('start')).format("YYYY-MM-DD")
			let userLogin = props.userLogin
			if (isNotUpload) {
				res = await POST(IMPORT_MAP_FOCUS_SHOP_SALE_CODE_BY_ROWS, { startDate, userLogin, arrFocusShop: dataSource })
			} else {
				let formdata = new FormData()
				formdata.append('file', fileList[0].originFileObj)
				formdata.append('startDate', startDate)
				// formdata.append('startDate', '2022-01-21')
				formdata.append('userLogin', userLogin)
				res = await API.importMapFocusShopSaleCodeExcel(formdata);
			}
			const { message, success } = res.data || res
			if (success) {
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message })
				setFileList([])
				setIsDisable(true)
				setDataSource([])
				await getMapFocusShopSaleCode()
			}
		} catch (err) {
			//alert
			useAlert({ type: 'catch', title: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onChangeRow = (e, rowNo, fieldName) => {
		let temp = [...dataSource]
		temp[rowNo - 1][fieldName] = e
		setDataSource([])
		setDataSource(temp)
		setIsDisable(false)
		if (!rowsEdit.includes(rowNo - 1)) {
			let tempRowsEdit = [...rowsEdit]
			tempRowsEdit.push(rowNo - 1)
			setRowsEdit(tempRowsEdit)
		}
	}

	const { TabPane } = Tabs;
	const Tab_List = [
		{
			key: 1,
			name: "จัดการข้อมูล", // GET select all info
			component: (
				<>
					<Form form={form} onFinish={() => { }} {...configForm}>
						<Card
							title={`หน้าจับคู่พื้นที่ Focus Shop กับรหัสเซลล์ (รอบวันที่ ${getPeriodDate('display')})`}
							style={{ marginBottom: 30 }}
						>
							{useRenderFormItem({
								constantComponent: ComponentImportMapFocusShopSaleCode({
									options: [],
									handleChange: onChangeFile,
								}),
							})}
							<div style={{ textAlign: 'right' }}>{useButton({ call: "save", moreProps: { onClick: () => onFinish(false), label: "บันทึกอัปโหลดไฟล์" }, isDisable: fileList.length == 0, moreStyle: { width: 'none' } })}</div>
						</Card>
					</Form>
					<Card
						extra={
							useExportExcel({
								header: Header_ImportMapFocusShopSaleCode,
								dataset: dataSource,
								filename: "เทมเพลตนำเข้าข้อมูลพื้นที่ focus shop",
								title: 'เทมเพลตนำเข้าข้อมูล'
							})
						}
					>
						{
							dataSource.length > 0 &&
							<CustomTableAtnd
								HeadTable={ColumnImportMapFocusShopSaleCode({ dataSource, onChangeRow, optionSaleCode })}
								DataTable={dataSource}
								moreProps={{
									scroll: {
										x: sumColumnWidth(ColumnImportMapFocusShopSaleCode({ dataSource, onChangeRow, optionSaleCode })) || 2050,
										y: 500,
									},
									size: "small"
								}}
							/>
						}
						<div style={{ width: '100%', textAlignLast: 'center', marginTop: '10px' }}>
							<Space>{useButton({ call: "save", moreProps: { onClick: () => onFinish(true) }, isDisable })}</Space>
						</div>

					</Card>
				</>
			),
		},
		{
			key: 2,
			name: "รายชื่อร้าน focus shop", // get employee summary
			component: focusShopStore.length > 0 &&
				<>
					<div style={{ color: "red" }}>*กรณีต้องการเพิ่มร้าน focus shop กรุณาติดต่อทีม IT</div>
					<CustomTableAtnd
						HeadTable={ColumnFocusShopStoreMaster()}
						DataTable={focusShopStore}
						moreProps={{
							scroll: {
								x: sumColumnWidth(ColumnFocusShopStoreMaster()) || 2050,
								y: 500,
							},
							size: "small"
						}}
					/>
				</>
			,
		},
	];

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
			<Tabs tabPosition="top" defaultActiveKey="1">
				{Tab_List.map((tab) => (
					<TabPane tab={tab.name} key={tab.key}>
						{tab.component}
					</TabPane>
				))}
			</Tabs>
		</Spin>
	);
};

export default ImportMapFocusShopSaleCode;
