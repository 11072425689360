import { useText } from "../../../conponents/typography/Typography";
import { filterSearchColumn } from "../../../functions/UseFunction";

export const ColumnReportDataMovement = [
  {
    title: "รหัสพนักงาน",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    title: "คำนำหน้า",
    dataIndex: "title",
    key: "title",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("title", "คำนำหน้าชื่อ"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "Name",
    key: "Name",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("Name", "ชื่อพนักงาน"),
  },
  {
    title: "สถานะการโอนย้าย",
    dataIndex: "statusTransferDes",
    key: "statusTransferDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("statusTransferDes", "สถานะการโอนย้าย"),
  },
  {
    title: "ประเภทการโอนย้าย",
    dataIndex: "actionDes",
    key: "actionDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("actionDes", "ประเภทการโอนย้าย"),
  },
  {
    title: "วันที่มีผล",
    dataIndex: "actionDate",
    key: "actionDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("actionDate", "วันที่มีผล"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "startDate",
    key: "startDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("startDate", "วันที่เริ่มงาน"),
  },
  {
    title: "วันที่สิ้นสุดการทำงาน",
    dataIndex: "endDateJob",
    key: "endDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("endDateJob", "วันที่สิ้นสุดการทำงาน"),
  },
  {
    title: "สถานภาพพนักงาน",
    dataIndex: "currentStatus",
    key: "currentStatus",
    align: "left",
    width: 150,
    ...filterSearchColumn("currentStatus", "สถานภาพพนักงาน"),
  },
  {
    title: "บริษัท",
    dataIndex: "company",
    key: "company",
    align: "left",
    width: 100,
    ...filterSearchColumn("company", "บริษัท"),
  },
  {
    title: "บริษัท (ใหม่)",
    key: "newCompany",
    align: "left",
    width: 100,
    render: (row) => {
      if (row.company !== row.newCompany) {
        return useText({ type: "warning", text: row.newCompany })
      } else {
        return row.newCompany
      }
    },
    ...filterSearchColumn("newCompany", "บริษัท"),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 100,
    ...filterSearchColumn("location", "Location"),
  },
  {
    title: "Location (ใหม่)",
    key: "newLocation",
    align: "left",
    width: 150,
    render: (row) => {
      if (row.location !== row.newLocation) {
        return useText({ type: "warning", text: row.newLocation })
      } else {
        return row.newLocation
      }
    },
    ...filterSearchColumn("newLocation", "Location"),
  },
  {
    title: "รหัสแผนก",
    dataIndex: "depNo",
    key: "depNo",
    align: "left",
    width: 150,
    ...filterSearchColumn("depNo", "รหัสแผนก"),
  },
  {
    title: "ชื่อแผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 300,
    ...filterSearchColumn("depName", "ชื่อแผนก"),
  },
  {
    title: "รหัสแผนก (ใหม่)",
    key: "newDepNo",
    align: "left",
    width: 150,
    render: (row) => {
      if (row.depNo !== row.newDepNo) {
        return useText({ type: "warning", text: row.newDepNo })
      } else {
        return row.newDepNo
      }
    },
    ...filterSearchColumn("newDepNo", "รหัสแผนก"),
  },
  {
    title: "ชื่อแผนก (ใหม่)",
    key: "newDepName",
    align: "left",
    width: 300,
    render: (row) => {
      if (row.depName !== row.newDepName) {
        return useText({ type: "warning", text: row.newDepName })
      } else {
        return row.newDepName
      }
    },
    ...filterSearchColumn("newDepName", "ชื่อแผนก"),
  },
  {
    title: "รหัสแผนกย่อย",
    dataIndex: "subDepNo",
    key: "subDepNo",
    align: "left",
    width: 200,
    ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
  },
  {
    title: "ชื่อแผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 400,
    ...filterSearchColumn("subDepName", "ชื่อแผนกย่อย"),
  },
  {
    title: "รหัสแผนกย่อย (ใหม่)",
    key: "newSubDepNo",
    align: "left",
    width: 200,
    render: (row) => {
      if (row.subDepNo !== row.newSubDepNo) {
        return useText({ type: "warning", text: row.newSubDepNo })
      } else {
        return row.newSubDepNo
      }
    },
    ...filterSearchColumn("newSubDepNo", "รหัสแผนกย่อย"),
  },
  {
    title: "ชื่อแผนกย่อย (ใหม่)",
    key: "newSubDepName",
    align: "left",
    width: 400,
    render: (row) => {
      if (row.subDepName !== row.newSubDepName) {
        return useText({ type: "warning", text: row.newSubDepName })
      } else {
        return row.newSubDepName
      }
    },
    ...filterSearchColumn("newSubDepName", "ชื่อแผนกย่อย"),
  },

  {
    title: "รหัสสายอนุมัติ",
    dataIndex: "hrmFlow",
    key: "hrmFlow",
    align: "left",
    width: 200,
    ...filterSearchColumn("hrmFlow", "รหัสสายอนุมัติ"),
  },
  {
    title: "ชื่อสายอนุมัติ",
    dataIndex: "hrmFlowName",
    key: "hrmFlowName",
    align: "left",
    width: 400,
    ...filterSearchColumn("hrmFlowName", "ชื่อสายอนุมัติ"),
  },
  {
    title: "รหัสสายอนุมัติ (ใหม่)",
    key: "newHrmFlow",
    align: "left",
    width: 200,
    render: (row) => {
      if (row.hrmFlow !== row.newHrmFlow) {
        return useText({ type: "warning", text: row.newHrmFlow })
      } else {
        return row.newHrmFlow
      }
    },
    ...filterSearchColumn("newHrmFlow", "รหัสสายอนุมัติ"),
  },
  {
    title: "ชื่อสายอนุมัติ (ใหม่)",
    key: "newHrmFlowName",
    align: "left",
    width: 400,
    render: (row) => {
      if (row.hrmFlowName !== row.newHrmFlowName) {
        return useText({ type: "warning", text: row.newHrmFlowName })
      } else {
        return row.newHrmFlowName
      }
    },
    ...filterSearchColumn("newHrmFlowName", "ชื่อสายอนุมัติ"),
  },
  {
    title: "รหัสสายการดูข้อมูล",
    dataIndex: "viewFlow",
    key: "viewFlow",
    align: "left",
    width: 200,
    ...filterSearchColumn("viewFlow", "รหัสสายการดูข้อมูล"),
  },
  {
    title: "ชื่อสายการดูข้อมูล",
    dataIndex: "viewFlowDes",
    key: "viewFlowDes",
    align: "left",
    width: 400,
    ...filterSearchColumn("viewFlowDes", "ชื่อสายการดูข้อมูล"),
  },
  {
    title: "รหัสสายการดูข้อมูล (ใหม่)",
    key: "newViewFlow",
    align: "left",
    width: 200,
    render: (row) => {
      if (row.viewFlow !== row.newViewFlow) {
        return useText({ type: "warning", text: row.newViewFlow })
      } else {
        return row.newViewFlow
      }
    },
    ...filterSearchColumn("newViewFlow", "รหัสสายการดูข้อมูล"),
  },
  {
    title: "ชื่อสายการดูข้อมูล (ใหม่)",
    key: "newViewFlowDes",
    align: "left",
    width: 400,
    render: (row) => {
      if (row.viewFlowDes !== row.newViewFlowDes) {
        return useText({ type: "warning", text: row.newViewFlowDes })
      } else {
        return row.newViewFlowDes
      }
    },
    ...filterSearchColumn("newViewFlowDes", "ชื่อสายการดูข้อมูล"),
  },
  {
    title: "รหัสตำแหน่งงาน",
    dataIndex: "positionNo",
    key: "positionNo",
    align: "left",
    width: 200,
    ...filterSearchColumn("positionNo", "รหัสตำแหน่งงาน"),
  },
  {
    title: "ชื่อตำแหน่งงาน",
    dataIndex: "positionName",
    key: "positionName",
    align: "left",
    width: 300,
    ...filterSearchColumn("positionName", "ชื่อตำแหน่งงาน"),
  },
  {
    title: "รหัสตำแหน่งงาน (ใหม่)",
    key: "newPositionNo",
    align: "left",
    width: 200,
    render: (row) => {
      if (row.positionNo !== row.newPositionNo) {
        return useText({ type: "warning", text: row.newPositionNo })
      } else {
        return row.newPositionNo
      }
    },
    ...filterSearchColumn("newPositionNo", "รหัสตำแหน่งงาน"),
  },
  {
    title: "ชื่อตำแหน่งงาน (ใหม่)",
    key: "newPositionName",
    align: "left",
    width: 300,
    render: (row) => {
      if (row.positionName !== row.newPositionName) {
        return useText({ type: "warning", text: row.newPositionName })
      } else {
        return row.newPositionName
      }
    },
    ...filterSearchColumn("newPositionName", "ชื่อตำแหน่งงาน"),
  },
  {
    title: "สถานะพนักงาน",
    dataIndex: "statusWorkerDes",
    key: "statusWorkerDes",
    align: "left",
    width: 300,
    ...filterSearchColumn("statusWorkerDes", "สถานภาพพนักงาน"),
  },
  {
    title: "สถานะพนักงาน (ใหม่)",
    key: "newStatusWorkerDes",
    align: "left",
    width: 300,
    render: (row) => {
      if (row.statusWorker !== row.newStatusWorker) {
        return useText({ type: "warning", text: row.newStatusWorkerDes })
      } else {
        return row.newStatusWorkerDes
      }
    },
    ...filterSearchColumn("newStatusWorkerDes", "สถานภาพพนักงาน"),
  },
];
