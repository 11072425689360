import moment from 'moment';

export const formFilter = ({ optionsEmp = [], optionsCompany = [] }) => {
  return [
    {
      ref: "company",
      name: "company",
      label: "บริษัท (Company)",
      type: "DROPDOWN",
      span: 5,
      placeholder: "บริษัท",
      properties: {
        options: optionsCompany,
      },
    },
    {
      ref: "empID",
      name: "empID",
      label: "พนักงาน",
      type: "DROPDOWN",
      span: 5,
      placeholder: "พนักงาน",
      properties: {
        options: optionsEmp,
      },
    },
    {
      ref: "startDateJob",
      name: "startDateJob",
      label: "ช่วงวันที่เริ่มงาน",
      type: "RANGE_PICKER",
      defaultValue: [moment().startOf('month'), moment().endOf('month')],
      span: 6,
      placeholder: ["เริ่ม", "จบ"],
    },
  ];
};
