import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { API_URL } from "../components/connectAPI";
import { AiOutlineUser } from "react-icons/ai";
import { browserHistory } from "react-router";
import axios from "axios";
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import {
  GET,
  GET_BEARER,
  GET_DETAIL_TASK_USER_IN,
  POST,
  POST_SELECT_DATA_ASSETS_FORM,
} from "../service";
import { Modal } from "antd";

const columns = [
  {
    dataField: "id",
    headerAlign: "center",
    align: "center",
    text: "ลำดับ",
  },

  {
    dataField: "type",
    headerAlign: "center",
    text: "ประเภทสินทรัพย์",
  },
  {
    dataField: "description",
    headerAlign: "center",
    text: "รายการสินทรัพย์",
  },
  {
    dataField: "note",
    headerAlign: "center",
    text: "หมายเหตุ",
  },
];

class Assets_NewEmpView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: "",
      resultView: [],
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let UserID = params.get("UserID"); // is the string "Jonathan"
    this.setState({ UserID: UserID }, () => {
      this.getDataUser();
    });
  }

  async getDataUser() {
    const userId = this.state.UserID;
    try {
      const res = await GET_BEARER(GET_DETAIL_TASK_USER_IN(userId));
      const { result } = res;
      let mapResult = [];
      this.setState({
        FullName: result.data.fullname,
        SupName: result.data.bossName,
        DepartmentName: result.data.depName,
        StartDateJob: result.data.startDateJob,
        Location: result.data.location,
        Level_Worker: result.data.levelWorker,
        LevelID: result.data.levelId,
        DivisionID: result.data.divisionId,
      });
      mapResult = result.data.line.map((item, key) => ({
        id: key + 1,
        type: item.type,
        description: item.description,
        note: item.note,
      }));
      this.setState({
        resultView: mapResult,
      });
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด", content: error.message });
    }
  }

  render() {
    const { resultView } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            <AiOutlineUser /> ข้อมูลพนักงาน
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup row>
                <Label sm={1}>รหัส/ชื่อพนักงาน: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="UserName"
                    id="UserName"
                    value={this.state.FullName}
                    readOnly
                  />
                </Col>
                <Label sm={1}>รหัส/ชื่อหัวหน้า: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="SupName"
                    id="SupName"
                    value={this.state.SupName}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={1}>วันที่เริ่มทำงาน: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="StartDateJob"
                    id="StartDateJob"
                    value={this.state.StartDateJob}
                    readOnly
                  />
                </Col>
                <Label sm={1}>ฝ่าย: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="Location"
                    id="Location"
                    value={this.state.Location}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={1}>ระดับ: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="Level_Worker"
                    id="Level_Worker"
                    value={this.state.Level_Worker}
                    readOnly
                  />
                </Col>
                <Label sm={1}>แผนก: </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="DepartmentName"
                    id="DepartmentName"
                    value={this.state.DepartmentName}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <BootstrapTable keyField="id" data={resultView} columns={columns} />
        <center>
          <Button
            color="secondary"
            className="text-center"
            onClick={() =>
              browserHistory.push(`/Assets_NewEmpMain?activeTab=NEW`)
            }
          >
            Back
          </Button>
        </center>
      </div>
    );
  }
}

export default Assets_NewEmpView;
