import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const useConfirm = ({
  FnOK,
  FnCancel,
  content = content || `กรุณาตรวจสอบข้อมูลก่อนกด "ยืนยัน"`,
  moreProps = moreProps || {},
}) => {
  Modal.confirm({
    title: "แจ้งเตือน",
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: "ยืนยัน",
    cancelText: "ยกเลิก",
    onOk: FnOK,
    onCancel: FnCancel,
    ...moreProps,
  });
};
