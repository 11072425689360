import React, { useState, useContext, useEffect } from 'react'
import { browserHistory } from "react-router";

import { Spin, Col, Row, Upload, Checkbox, Divider, Modal } from 'antd'
import { MANAGE_TRAINING } from '../commonsTraining/structuresTraining/manageTraining'
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider'
import { colors } from '../../themes'
import LayoutInput from '../../moduleOT/commonsOT/layoutsOT/layoutInput'
import { isNil, path, remove, update, view } from 'ramda'
import { LayoutBreadcrumb, LayoutTable } from '../../moduleKPIs/common/layouts'
import { TABLE_INSERT_TRAINING } from '../commonsTraining/structuresTraining/tableTraining'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { BREADCRUMB_MANAGE_TRAINING } from '../commonsTraining/structuresTraining/breadcrumTraining'
import { MasterTrainingContext } from '../servicesTraining/masterTraining/masterTrainingProvider'
import { FILED_OPTION, MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { PATHNAME } from '../../constants/enums/pathname';
import { requiredInput, TYPE } from '../../moduleKPIs/common/inputs';
import { ButtonConfirm } from '../../constants/functions/buttonConfirm';
import { UploadFile } from '../../constants/functions/uploadFile';
import { validateEmptyLists } from '../../constants/functions/emptyLists';
import { APIInsertTraining, APIUpdateTraining, APIRemoveTraining, APIInsertTrainingFile } from '../servicesTraining/manageTraining/manageTrainingConnect';
import { calculateAvg, checkArrayEmpty, convertArray, downloadFile } from '../../constants/functions';
import { TEXT_BUTTON } from '../../constants/buttons';
import { SetDate } from '../../common/setDate';

const ManageTraining = ({ isUpdate, isView }) => {
    const { userLogin, manageTrainingState: { trainingCourses, trainingUser } } = useContext(ManageTrainingContext)
    const { masterTrainingState, getMaster } = useContext(MasterTrainingContext)
    const [loading, setLoading] = useState(false)
    // const [pagesize, setPageSize] = useState(100)
    let dataUser = JSON.parse(window.localStorage.getItem("trainingUser"));
    let dataCourses = JSON.parse(window.localStorage.getItem("trainingCourses"));
    const [initialData, setInitialData] = useState(trainingUser || { ...dataUser, startDate: dataUser && SetDate(dataUser.startDate) })
    const [initialList, setInitialList] = useState(trainingCourses || dataCourses)
    const [files, setFiles] = useState({
        trainingFiles: []
    })
    //console.log({ initialData })
    let validateFieldList = [
        'evaluationMethod', 'score', 'timePeriod', 'trainerNo', 'trainingCourses', 'trainingDate', 'trainingMethod'
    ]
   // console.log({ trainingCourses, dataCourses })
    async function fetchAPI() {
        await setLoading(true)
        await Promise.all(
            [getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINERS })],
            [getMaster({ fieldname: MASTER_OPTION.OPTION_MENTOR, payload: { depNo: path(['depNo'], dataUser) } })],
            [getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINING, payload: { type: FILED_OPTION.ALL } })],
        )
        await setLoading(false)
        // await setLoading(true)
        // await getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINERS })
        // await getMaster({ fieldname: MASTER_OPTION.OPTION_MENTOR, payload: { depNo: path(['depNo'], dataUser) } })
        // await getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINING, payload: { type: FILED_OPTION.ALL } })
        // await setLoading(false)
    }
    useEffect(() => {
        if (!dataUser) {
            browserHistory.push(PATHNAME.DASHBOARD_TRAINING)
        }
        fetchAPI();
        // if (isNil(trainingUser) || isNil(trainingCourses)) {
        //     browserHistory.push(PATHNAME.DASHBOARD_TRAINING)
        // }
    }, [])

    async function onDownload() {
        await Modal.confirm({
            title: 'คุณต้องการดาวน์โหลดไฟล์แนบ (ผลการอบรม) ใช่หรือไม่',
            onOk: async () => {
                await downloadFile(initialData.filePath, 'all')
            }
        })
    }

    async function onUploadFile(action, file, fieldname) {
        switch (action) {
            case "UPLOAD_ADD":
                let duplicateFile = files[fieldname].filter(
                    image => image.name === file.name
                );
                if (duplicateFile.length > 0) {
                    Modal.error({
                        title: "ดำเนินการไม่สำเร็จ",
                        content: "มีชื่อไฟล์ซ้ำ กรุณาเลือกไฟล์ใหม่"
                    });
                } else {
                    if (files[fieldname].length > 4) {
                        Modal.error({
                            title: "ดำเนินการไม่สำเร็จ",
                            content: "อัพโหลดได้สูงสุด 5 รูปเท่านั้น!!"
                        });
                    } else {
                        setFiles({ ...files, [fieldname]: files[fieldname].concat([file]) });
                    }
                }
                break;
            case "UPLOAD_REMOVE":
                if (files[fieldname].length > 0) {
                    setFiles({ ...files, [fieldname]: [] });
                }
                break;
            default:
                break;
        }
    }

    async function onAddList() {
        await setInitialList(initialList.concat({
            docNo: "",
            createBy: userLogin.userId,
            createDate: new Date().toISOString(),
            evaluationMethod: "",
            idRow: initialList.length + 1,
            linenum: initialList.length + 1,
            score: '',
            timeAttendance: "",
            timePeriod: "",
            trainerName: "",
            trainerNo: "",
            trainingCourses: "",
            trainingDate: "",
            trainingGroup: "",
            trainingMethod: "",
            trainingNo: "",
            trainingResult: "",
            trainingType: "",
            updateBy: "",
            updateDate: "",
        }))
    }

    async function onSubmit(_statusCF) {
        let initialUpdate = []
        for (let i in initialList) {
            if (initialList[i].docNo === "") {
                initialUpdate.push({ ...initialList[i] })
            }
        }

        let payload = {
            dataHeader: {
                ...initialData,
                timeAttendance: initialData.timeAttendance,
                startDate: initialData.startDate,
                statusCF: (_statusCF) ? 1 : 0,
                userLogin: userLogin.userId
            },
            dataLine: initialList,
            dataInsert: initialUpdate
        }
        await setLoading(true)
        // console.log({ payload })
        if (!initialData.mentorCode) {
            await Modal.error({ title: 'กรุณากรอกพี่เลี้ยงที่ได้รับมอบหมาย', onOk: () => setLoading(false) })
        } else {
            if (checkArrayEmpty(payload.dataInsert, 'trainingCourses')) {
                let newLine = convertArray('trainingCourses', 'object', payload.dataLine)
                let newInsert = []
                for (let i = 0; i < newLine.length; i++) {
                    if (newLine[i]['docNo'] === "") {
                        newInsert.push(newLine[i])
                    }
                }
                payload.dataLine = newLine
                payload.dataInsert = newInsert
            }
            //console.log(payload)
            await saveData(payload)
            await setLoading(false)
        }
    }
    async function saveData(payload) {
        if (isUpdate) {
            await APIUpdateTraining(payload, async (response) => {
                // console.log(response, initialData)
                await setLoading(false)
                if (response) {
                    await setLoading(true)

                    if (isNil(initialData.filePath) && files.trainingFiles.length > 0) {
                        await APIInsertTrainingFile({
                            docNo: response.docNo,
                            userId: initialData.userId,
                            trainingFile: files.trainingFiles,
                        })
                        await setLoading(false)
                    }
                    await Modal.success({
                        title: 'บันทึกสำเร็จ',
                        content: `เลขที่เอกสาร ${response.docNo}`,
                        onOk: () => browserHistory.push(PATHNAME.DASHBOARD_TRAINING)
                    })
                }
            })

        } else {

            await APIInsertTraining(payload, async (response) => {
                if (response) {
                    await setLoading(true)
                    if (files.trainingFiles.length > 0) {
                        await APIInsertTrainingFile({
                            docNo: response.docNo,
                            userId: initialData.userId,
                            trainingFile: files.trainingFiles,
                        })
                        await setLoading(false)
                    }
                    await Modal.success({
                        title: 'บันทึกสำเร็จ',
                        content: `เลขที่เอกสาร ${response.docNo}`,
                        onOk: () => browserHistory.push(PATHNAME.DASHBOARD_TRAINING)
                    })
                } else {
                    await Modal.error({
                        title: 'บันทึกไม่สำเร็จ',
                        content: `เลขที่เอกสาร ${response.docNo}`,
                        onOk: () => setLoading(false)
                    })
                }
            })
        }
    }

    const onChange = async ({ fieldname, value, index = null }) => {

        switch (fieldname) {
            case 'removeLine':
                await Modal.confirm({
                    title: "คุณต้องการลบรายการใช่หรือไม่ ?",
                    onOk: async () => {
                        // console.log(index, value)
                        let removeInitialList = remove(index, 1, initialList)
                        // await setInitialData({
                        //     ...initialData,
                        //     avgTrainingScore: calculateAvg(removeInitialList, 'score'),  // เฉลี่ยคะแนนระดับผลการเรียน/จำนวนหัวข้อ
                        //     resultTraining: calculateAvg(removeInitialList, 'score') > 2.56 ? 'pass' : 'notPass', // เช็คว่าผ่าานหรือไม่ผ่าน ถ้ามากกว่า 2.56 คือ ผ่าน 
                        // })
                        await setInitialList(removeInitialList)
                        // }
                        //                         // await setInitialList(removeInitialList)
                        //                         let indexValue = initialList.findIndex((el) => el.idRow === (value.idRow - 1))
                        //                         console.log({ indexValue })

                        await setLoading(true)
                        if (value.docNo) {
                            await APIRemoveTraining(value, async (res) => {
                                if (res) {
                                    await Modal.success({ title: 'ดำเนินการลบสำเร็จ' })
                                }
                            })
                        }

                        await setLoading(false)
                    }
                })

                break;
            case 'trainingType':
            case 'trainingGroup':
            case 'trainingMethod':
            case 'trainingDate':
            case 'trainerNo':
            case 'evaluationMethod':
            case 'trainingCourses':
            case 'score':
            case 'timePeriod':
                let updateInitialList = update(index, { ...initialList[index], [fieldname]: value, }, initialList)
                await setInitialData({
                    ...initialData,
                    avgTrainingScore: calculateAvg(updateInitialList, 'score'),  // เฉลี่ยคะแนนระดับผลการเรียน/จำนวนหัวข้อ
                    resultTraining: calculateAvg(updateInitialList, 'score') > 3 ? 'pass' : 'notPass', // เช็คว่าผ่าานหรือไม่ผ่าน ถ้ามากกว่า 2.56 คือ ผ่าน 
                })
                await setInitialList(updateInitialList)
                break;
            case 'mentorCode':
            case 'timeAttendance':
            case 'typeStaff':
                await setInitialData({ ...initialData, [fieldname]: value[0] })
                break;
            default:
                await setInitialData({ ...initialData, [fieldname]: value })
                break;
        }

    }
    return (
        <Spin tip={'กำลังโหลด'} spinning={loading}>
            <div style={{ padding: '20px 0px' }}>
                < LayoutBreadcrumb breadcrumbLists={BREADCRUMB_MANAGE_TRAINING({ pathname: window.location.pathname })} />
            </div>
            <div style={{ padding: 20, backgroundColor: colors.white, marginBottom: 20 }}>
                <Row gutter={[10, 24]}>
                    {MANAGE_TRAINING({
                        update: isUpdate,
                        view: isView,
                        permisstion: userLogin.userPermissioln,
                        options: { ...masterTrainingState },
                        defaultValue: { ...initialData }
                    }).map((row) => (
                        row.map((each, eachIndex) => {
                            switch (each.type) {
                                case TYPE.DIVIDER:
                                    return (<Divider key={eachIndex} />)
                                default:
                                    return (
                                        <Col key={eachIndex} span={each.span} style={{ display: 'flex', padding: 0 }}>
                                            <Col span={10} style={{ padding: 10, fontWeight: 600 }}>
                                                {requiredInput({ required: each.required, label: `${each.header} :` })}
                                            </Col>
                                            <Col span={14} style={{ padding: 10 }}>
                                                <LayoutInput
                                                    type={each.type}
                                                    value={path([each.inputOption.fieldname], initialData)}
                                                    onChange={onChange}
                                                    {...each}
                                                />
                                            </Col>
                                        </Col>
                                    )
                            }
                        })
                    )
                    )}
                </Row>
            </div>
            <Row style={{ alignItems: 'center', paddingLeft: 15 }}>
                <Col span={12}>
                    <div style={{ fontSize: 20, fontWeight: 600 }}>{'บันทึกผลการอบรมการฝึกปฎิบัติงาน (OJT)'}</div>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 0 }}>
                    {

                        (initialData && initialData.filePath) ?
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <ButtonTheme type="primary" ghost
                                    style={{ width: 'unset' }}
                                    buttonText={`${TEXT_BUTTON.DOWNLOAD} ผลการอบรม`}
                                    onClick={async () => { await onDownload() }} />
                                {(isView) ? <ButtonTheme type="primary"
                                    style={{ width: 'unset' }}
                                    buttonText={TEXT_BUTTON.PRINT}
                                    onClick={() => {
                                        window.open(PATHNAME.TRAINING_FORM, '_blank');
                                        // browserHistory.push(PATHNAME.TRAINING_FORM)
                                    }} />
                                    :
                                    <ButtonTheme
                                        style={{ width: 'unset' }}
                                        type="primary" buttonText="เพิ่มหัวข้อ" onClick={() => onAddList()} />
                                }
                            </Col>
                            :
                            <Col span={12}>
                                {(isView) ? <ButtonTheme type="primary"
                                    style={{ width: 'unset' }}
                                    buttonText={TEXT_BUTTON.PRINT}
                                    onClick={() => {
                                        window.open(PATHNAME.TRAINING_FORM, '_blank');
                                        // browserHistory.push(PATHNAME.TRAINING_FORM)
                                    }} />
                                    :
                                    <ButtonTheme
                                        style={{ width: 'unset' }}
                                        type="primary" buttonText="เพิ่มหัวข้อ" onClick={() => onAddList()} />
                                }
                            </Col>
                    }

                    {/* {
                        (initialData && initialData.filePath) ?
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <ButtonTheme type="primary" ghost
                                    style={{ width: 'unset' }}
                                    buttonText={`${TEXT_BUTTON.DOWNLOAD} ผลการอบรม`}
                                    onClick={async () => { await onDownload() }} />
                                {(isView) ? <ButtonTheme type="primary"
                                    style={{ width: 'unset' }}
                                    buttonText={TEXT_BUTTON.PRINT}
                                    onClick={() => {
                                        window.open(PATHNAME.TRAINING_FORM, '_blank');
                                        // browserHistory.push(PATHNAME.TRAINING_FORM)
                                    }} />
                                    :
                                    <ButtonTheme
                                        style={{ width: 'unset' }}
                                        type="primary" buttonText="เพิ่มหัวข้อ" onClick={() => onAddList()} />
                                }
                            </Col>
                            :
                            <Col span={12}>
                                <ButtonTheme type="primary" buttonText="เพิ่มหัวข้อ" onClick={() => onAddList()} />
                            </Col>
                    } */}
                </Col>
            </Row>
            <div style={{ padding: '0px 15px' }}>
                <LayoutTable
                    bordered
                    rowKey={'idRow'}
                    scroll={{ x: (!isView) && 1000 }}
                    dataSource={initialList}
                    columns={TABLE_INSERT_TRAINING({
                        update: isUpdate,
                        view: isView,
                        userLogin: userLogin,
                        onChange: (val) => onChange(val)
                    })}
                    pagination={{
                        // pageSize: pagesize,
                        defaultPageSize: 100,
                        // showSizeChanger: true,
                        // onShowSizeChange: (current, size) => setPageSize(size),
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                    }}
                />
                {
                    (!isView) &&
                    <>
                        {(initialData && initialData.filePath) ? null
                            :
                            <UploadFile
                                message={false}
                                maxlength={1}
                                fieldname="trainingFiles"
                                label="แนบเอกสารเพิ่มเติม (ผลการเรียน)"
                                fileList={files['trainingFiles']}
                                onClick={(action, file, fieldname) => onUploadFile(action, file, fieldname)}
                            />}

                        <ButtonConfirm
                            disabled={validateEmptyLists(initialList, validateFieldList)}
                            onClick={onSubmit} />
                    </>
                }
            </div>
        </Spin >
    )
}

export default ManageTraining

