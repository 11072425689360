import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Upload } from 'antd';
import styled from 'styled-components';
import {
	ExcelExport,
	ExcelExportColumn,
	ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';
import { SR_COLORS, SR_SIZES, Svg } from './index.jsx';
import { checkLength } from './function.jsx';
import { UploadOutlined } from '@ant-design/icons';

export const ContainerButton = styled.div`
    display: flex;
    width:${100}%;
    justify-content: ${props =>
		props.left ? 'flex-start' :
			props.center ? 'center' :
				props.right ? 'flex-end' : ''
	}
`

const ButtonStyle = styled(Button)`
	align-items: center;
	border-radius: ${5}px;
	margin: ${5}px;
	padding: ${0}px ${5}px;
	width: ${props => props.width ? props.width : 'unset'};
	background-color: ${props => props.bgcolor ? props.bgcolor : 'transparent'};
	border-color: ${props => props.bordercolor ? props.bordercolor : (props.bgcolor ? props.bgcolor : SR_COLORS.THEME)};
	color: ${props => props.color ? props.color : SR_COLORS.WHITE};
	font-size: ${props => props.fontSize ? `${props.fontSize}px` : `${SR_SIZES.TEXT}px`};
	span {
		padding: ${0}px ${5}px;
	}
	&:hover &:active {
		background-color: ${SR_COLORS.HOVER}  !important;
		border-color: ${SR_COLORS.HOVER} !important;
	}
`



export function ButtonSearch({ htmlType, type, size, fontSize, disabled, onClick, title }) {
	return (
		<ButtonStyle
			htmlType={htmlType}
			disabled={disabled}
			onClick={onClick}
			type={type || "primary"}
			size={size || 'middle'}
			fontSize={fontSize}
			icon={<Svg.search color={disabled ? SR_COLORS.DISABLED : SR_COLORS.WHITE} />}
			bgcolor={SR_COLORS.THEME}
		>
			<span> {title || 'ค้นหา'} </span>
		</ButtonStyle>
	)
}


export function ButtonClear({ htmlType, type, size, fontSize, disabled, onClick, title }) {
	return (
		<ButtonStyle
			htmlType={htmlType}
			disabled={disabled}
			onClick={onClick}
			type={type || ""}
			size={size || 'middle'}
			fontSize={fontSize}
			icon={<Svg.reset color={disabled ? SR_COLORS.DISABLED : SR_COLORS.THEME} />}
			color={SR_COLORS.THEME}
		>
			<span>{title || 'ล้างข้อมูล'}</span>
		</ButtonStyle >
	)
}


export const ButtonExport = React.forwardRef(({ htmlType, size, fontSize, dataSource, columns, fieldname, title }, ref) => {
	async function exportFile() {
		await ref.current.save();
	}

	return (
		<Fragment>
			<ButtonStyle
				ghost
				type=""
				htmlType={htmlType}
				disabled={!checkLength(dataSource)}
				onClick={() => exportFile()}
				size={size || 'middle'}
				fontSize={fontSize}
				icon={<Svg.download color={!checkLength(dataSource) ? SR_COLORS.DISABLED : SR_COLORS.THEME} />}
				color={SR_COLORS.THEME}
				bgcolor={SR_COLORS.WHITE}
				bordercolor={SR_COLORS.THEME}
				style={{ marginBottom: 15 }}
			>
				<span>{title || 'ส่งออกเอกสาร'}</span>
			</ButtonStyle>
			<ExcelExport
				data={dataSource}
				fileName={fieldname || 'document'}
				ref={(exporter) => { ref.current = exporter }} >
				{
					Array.isArray(columns) && columns.map((element, index) => {
						return (
							(element.children) ?
								<ExcelExportColumnGroup
									key={index}
									title={`${element.title}`}
									headerCellOptions={{ textAlign: 'center' }} >
									{
										element.children.map((each, i) => (
											<ExcelExportColumn key={i} field={`${each && each.field}`} title={`${each && each.title}`} />
										))
									}
								</ExcelExportColumnGroup>
								:
								<ExcelExportColumn key={index}
									field={`${element && element.field}`}
									title={`${element && element.title}`}
									cellOptions={element && element.cellOptions}
								/>
						)
					})
				}
			</ExcelExport>
		</Fragment>
	)
})

export const ButtonImport = React.forwardRef(({ htmlType, size, fontSize, dataSource, columns, fieldname, title, onRemove, onUpload, fileList, onCloseUpload }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {

		if (onCloseUpload) {
			console.log('888888888888888888888888888888888888', onCloseUpload)
			openModalUpload()
		}
	}, [onCloseUpload])

	function openModalUpload() {
		setIsOpen(!isOpen)
	}

	return (
		<Fragment>
			<ButtonStyle
				ghost
				type=""
				htmlType={htmlType}
				onClick={() => openModalUpload()}
				size={size || 'middle'}
				fontSize={fontSize}
				// icon={<Svg.download color={!checkLength(dataSource) ? SR_COLORS.DISABLED : SR_COLORS.THEME} />}
				color={SR_COLORS.THEME}
				bgcolor={SR_COLORS.WHITE}
				bordercolor={SR_COLORS.THEME}
				style={{ marginBottom: 15 }}
			>
				<span>{title || 'อัปโหลดเอกสาร'}</span>
			</ButtonStyle>
			<Modal visible={isOpen} title={title || 'อัปโหลดเอกสาร'} footer={null} onCancel={() => setIsOpen(false)}>
				<Upload
					beforeUpload={() => false}
					accept={'*'}
					onChange={onUpload ? (e) => onUpload(e) : (e) => { console.log('e file', e) }}
					onRemove={onRemove ? onRemove : () => { }}
					fileList={fileList && fileList.length > 0 ? fileList : []}
					maxCount={1}
				>
					<Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
				</Upload>
			</Modal>
			{/* <ExcelExport
				data={dataSource}
				fileName={fieldname || 'document'}
				ref={(exporter) => { ref.current = exporter }} >
				{
					Array.isArray(columns) && columns.map((element, index) => {
						return (
							(element.children) ?
								<ExcelExportColumnGroup
									key={index}
									title={`${element.title}`}
									headerCellOptions={{ textAlign: 'center' }} >
									{
										element.children.map((each, i) => (
											<ExcelExportColumn key={i} field={`${each && each.field}`} title={`${each && each.title}`} />
										))
									}
								</ExcelExportColumnGroup>
								:
								<ExcelExportColumn key={index}
									field={`${element && element.field}`}
									title={`${element && element.title}`}
									cellOptions={element && element.cellOptions}
								/>
						)
					})
				}
			</ExcelExport> */}
		</Fragment>
	)
})