import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()

export async function APIInsertRequestOT(payload, nextAction) {
    try {
        const response = await API.insertRequestOT(payload);
        responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateRequestOT(payload, nextAction) {
    try {
        const response = await API.updateRequestOT(payload);
        //console.log({ response })
        if (response.status === 200) {
            return response.data
        }
        // responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateCFApprovalOT(payload, nextAction) {
    try {
        const response = await API.updateCFApprovalOT(payload);
        // console.log({ response })
        if (response.status === 200) {
            return response.data
        }
        return response
        // responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateRecallROT(payload, nextAction) {
    try {
        const response = await API.updateRecallROT(payload);
        if (response.status === 200) {
            return response.data
        }
        return response
        // responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIDelRequestOTLine(payload, nextAction) {
    try {
        const response = await API.delRequestOTLine(payload);
        responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIDelRequestOTLineAll(payload, nextAction) {
    try {
        const response = await API.delRequestOTLineAll(payload);
        responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APISelectUserRequestOT(payload, nextAction) {
    try {
        const response = await API.selectUserRequestOT(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
