/* eslint-disable react/display-name */
import React from "react";
import { Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const renderSearchColumn = (dataIndex, searchInput, disableSearch) => {
  if (disableSearch) return;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm: cf,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`ค้นหา`}
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            setSelectedKeys(selectedKeys);
            cf();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys(selectedKeys);
              cf();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: "#1890ff" }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSelectedKeys("");
            }}
            size="small"
            style={{ width: 90 }}
          >
            เคลียร์
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  };
};
