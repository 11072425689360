import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import './layoutForm.css'
import { TEXT_BUTTON } from '../../../constants/buttons'
import { ButtonTheme } from '../../../moduleKPIs/common/buttons'
import { printDiv } from '../../../moduleKPIs/common/functions'
import { LayoutTable } from '../../../moduleKPIs/common/layouts'
import { path } from 'ramda'

const LayoutFormPrint = ({
    idForm = null,
    dataSource = [],
    columns = [],
    rowKey = null,
    structureHeader = [],
    structureFooter = [],
    scroll,
}) => {

    return (
        <div className="layout-root-report">
            <div className="layout-report-btn-confirm">
                {/* <ButtonTheme
                    style={{ justifyContent: 'flex-start', width: 'unset', padding: 0 }}
                    buttonText={TEXT_BUTTON.BACK}
                    onClick={() => window.history.back()}
                /> */}
                <ButtonTheme
                    type="primary"
                    style={{ justifyContent: 'flex-start', padding: '0px 0px 0px 15px' }}
                    buttonText={TEXT_BUTTON.PRINT}
                    onClick={() => printDiv(idForm)}
                />
            </div>
            <div id={idForm}>
                <div className="layout-header-report">
                    {structureHeader.map((rowHeader, iRHeader) => {
                        return (
                            <Row key={iRHeader} gutter={[24, 0]}>
                                {rowHeader.map((colHeader, iCHeader) => {
                                    return (
                                        <Col key={iCHeader} span={colHeader.span} style={colHeader.style}>
                                            {
                                                (colHeader.render) ?
                                                    colHeader.render(colHeader.title, colHeader.record, iCHeader) :
                                                    `${colHeader.title} ${path([colHeader.dataIndex], colHeader.record)}`
                                            }
                                        </Col>
                                    )
                                })}
                            </Row>
                        )
                    })}
                </div>
                <div className="layout-table-report">
                    <LayoutTable
                        bordered
                        scroll={scroll}
                        rowKey={rowKey}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                    <div className="layout-footer-report">
                        {structureFooter.map((rowFooter, iRFooter) => {
                            return (
                                <Row key={iRFooter} gutter={[24, 0]} >
                                    {rowFooter.map((colFooter, iCFooter) => {
                                        return (
                                            <Col key={iCFooter} span={colFooter.span} style={{ padding: 0 }} >
                                                {
                                                    (colFooter.render) ? colFooter.render(colFooter.title, colFooter.record, iCFooter) : null
                                                }
                                            </Col>
                                        )
                                    })}
                                </Row>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayoutFormPrint