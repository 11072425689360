import React, { useState, useEffect } from "react";
import { Card, Spin, Table } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportStatusWorker } from "../../constants/HeaderExcel";
import { GET, GET_SELECT_REPORT_STATUS_WORKER } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { WORDS } from "../../constants/ConstantComponent";
import { ColumnReportStatusWorker } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportStatusWorker = () => {
  // [START] States
  const [dataTable, setDataTable] = useState([]);
  const [overAll, setOverAll] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States

  useEffect(() => {
    getReportStatusWorker();
  }, []);

  // [START] REST API
  const getReportStatusWorker = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setOverAll({});
      const response = await GET(GET_SELECT_REPORT_STATUS_WORKER);
      const { result } = response;
      const { report, overall } = result;
      let mapResult = [];
      let mapOverAll = {};
      if (report.length !== 0) {
        mapResult = report.map((row, index) => ({
          key: `${index}`,
          ...row,
        }));
        mapOverAll = overall
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
      setOverAll(mapOverAll);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Status Worker: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  const renderSummaryRow = (obj) => {
    if (typeof obj !== "object") return null;
    let renderCells = [];
    for (const key in obj) {
      renderCells.push(
        <Table.Summary.Cell key={key} align="right">
          {obj[key] || "-"}
        </Table.Summary.Cell>
      );
    }
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center" colSpan={3}>
            จำนวนพนักงานตามสถานะการจ้าง
          </Table.Summary.Cell>
          {renderCells}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Card
        title="รายงานจำนวนพนักงานตามสถานะการจ้างงาน"
        style={{ marginBottom: 30 }}
        extra={useExportExcel({
          header: Header_ReportStatusWorker,
          dataset: dataTable,
          filename: "รายงานจำนวนพนักงานตามสถานะการจ้างงาน",
        })}
      >
        {dataTable.length !== 0 && (
          <CustomTableAtnd
            HeadTable={ColumnReportStatusWorker}
            DataTable={dataTable}
            moreProps={{
              scroll: {
                x: 1080,
                y: 500,
              },
              summary: () => renderSummaryRow(overAll),
            }}
          />
        )}
      </Card>
    </Spin>
  );
};

export default FormReportStatusWorker;
