import React from "react"
import { useText } from "../../conponents/typography/Typography";
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";

export const ColumnChangeInsurancePlan = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personalId",
		key: "personalId",
		align: "right",
		width: 200,
		...filterSearchColumn("personalId", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "birthday",
		key: "birthday",
		align: "right",
		width: 150,
		...filterSearchColumn("birthday", "วันเกิด"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	{
		title: "ประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 150,
		render: (record) => {
			const { datatimeHEA } = record;
			let textProps = {};
			if (datatimeHEA) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${datatimeHEA}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` -`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "อายุงาน (ปี)",
		key: "yearsWork",
		dataIndex: "yearsWork",
		align: "right",
		width: 150,
		...useSorterTable("yearsWork", "number"),
	},
	{
		title: "แผนประกันสุขภาพ",
		dataIndex: "planHeaDes",
		key: "planHeaDes",
		align: "left",
		width: 200,
		...filterSearchColumn("planHeaDes", "แผนประกันสุขภาพ"),
	},
	{
		title: "แผนประกันอุบัติเหตุ",
		dataIndex: "planAcdDes",
		key: "planAcdDes",
		align: "left",
		width: 200,
		...filterSearchColumn("planAcdDes", "แผนประกันอุบัติเหตุ"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "startDateJob",
		key: "startDateJob",
		align: "right",
		width: 150,
		...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
	},
	{
		title: "สถานะพนักงาน",
		dataIndex: "statusWorkerDes",
		key: "statusWorkerDes",
		align: "left",
		width: 200,
		...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
	},
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "สถานที่",
		dataIndex: "location",
		key: "location",
		align: "left",
		width: 100,
		...filterSearchColumn("location", "สถานที่"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
];

export const ColumnManageBenefitTransfer = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personalId",
		key: "personalId",
		align: "right",
		width: 200,
		...filterSearchColumn("personalId", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "birthday",
		key: "birthday",
		align: "right",
		width: 150,
		...filterSearchColumn("birthday", "วันเกิด"),
	},
	{
		title: "วันที่มีผล",
		dataIndex: "actionDate",
		key: "actionDate",
		align: "right",
		width: 150,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	// {
	//     title: "ประกันสุขภาพ/อุบัติเหตุ",
	//     key: "statusHEA",
	//     align: "left",
	//     width: 150,
	//     render: (record) => {
	//         const { datatimeHEA } = record;
	//         let textProps = {};
	//         if (datatimeHEA) {
	//             textProps = {
	//                 type: "success",
	//                 icon: <CheckCircleOutlined />,
	//                 text: ` ${datatimeHEA}`,
	//             };
	//         } else {
	//             textProps = {
	//                 type: "danger",
	//                 icon: <CloseCircleOutlined />,
	//                 text: ` -`,
	//             };
	//         }
	//         return useText(textProps);
	//     },
	// },
	{
		title: "วันที่ยื่นประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 200,
		render: (record) => {
			const { datatimeStampHea } = record;
			let textProps = {};
			if (datatimeStampHea) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${datatimeStampHea}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` -`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "วันที่ยื่นกองทุนสำรองเลี้ยงชีพ",
		key: "statusPvd",
		align: "left",
		width: 200,
		render: (record) => {
			const { datatimeStampPvd } = record;
			let textProps = {};
			if (datatimeStampPvd) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${datatimeStampPvd}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` -`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "แผนประกันสุขภาพ",
		dataIndex: "planInsuranceHea",
		key: "planInsuranceHea",
		align: "left",
		width: 200,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	{
		title: "แผนประกันอุบัติเหตุ",
		dataIndex: "planInsuranceAcd",
		key: "planInsuranceAcd",
		align: "left",
		width: 200,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	// {
	//     title: "สถานะพนักงาน",
	//     dataIndex: "statusWorkerDes",
	//     key: "statusWorkerDes",
	//     align: "left",
	//     width: 200,
	//     ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
	// },
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "บริษัท (เก่า)",
		dataIndex: "oldCompany",
		key: "oldCompany",
		align: "left",
		width: 100,
		...filterSearchColumn("oldCompany", "บริษัท (เก่า)"),
	},
	{
		title: "สถานที่",
		dataIndex: "location",
		key: "location",
		align: "left",
		width: 100,
		...filterSearchColumn("location", "สถานที่"),
	},
	{
		title: "สถานที่ (เก่า)",
		dataIndex: "oldLocation",
		key: "oldLocation",
		align: "left",
		width: 100,
		...filterSearchColumn("oldLocation", "สถานที่ (เก่า)"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "ชื่อแผนก (เก่า)",
		dataIndex: "oldDepNoDes",
		key: "oldDepNoDes",
		align: "left",
		width: 300,
		...filterSearchColumn("oldDepNoDes", "ชื่อแผนก (เก่า)"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
	{
		title: "แผนกย่อย (เก่า)",
		dataIndex: "oldSubDepDes",
		key: "oldSubDepDes",
		align: "left",
		width: 400,
		...filterSearchColumn("oldSubDepDes", "แผนกย่อย (เก่า)"),
	},
];

export const ColumnManageBenefitTransferOut = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personalId",
		key: "personalId",
		align: "right",
		width: 200,
		...filterSearchColumn("personalId", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "birthday",
		key: "birthday",
		align: "right",
		width: 150,
		...filterSearchColumn("birthday", "วันเกิด"),
	},
	{
		title: "วันที่มีผล",
		dataIndex: "actionDate",
		key: "actionDate",
		align: "right",
		width: 150,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	// {
	//     title: "ประกันสุขภาพ/อุบัติเหตุ",
	//     key: "statusHEA",
	//     align: "left",
	//     width: 150,
	//     render: (record) => {
	//         const { datatimeHEA } = record;
	//         let textProps = {};
	//         if (datatimeHEA) {
	//             textProps = {
	//                 type: "success",
	//                 icon: <CheckCircleOutlined />,
	//                 text: ` ${datatimeHEA}`,
	//             };
	//         } else {
	//             textProps = {
	//                 type: "danger",
	//                 icon: <CloseCircleOutlined />,
	//                 text: ` -`,
	//             };
	//         }
	//         return useText(textProps);
	//     },
	// },
	{
		title: "วันที่แจ้งออกประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 200,
		render: (record) => {
			const { datatimeStampHea } = record;
			let textProps = {};
			if (datatimeStampHea) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${datatimeStampHea}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` -`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "วันที่แจ้งออกกองทุนสำรองเลี้ยงชีพ",
		key: "statusPvd",
		align: "left",
		width: 200,
		render: (record) => {
			const { datatimeStampPvd } = record;
			let textProps = {};
			if (datatimeStampPvd) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${datatimeStampPvd}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` -`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "แผนประกันสุขภาพ",
		dataIndex: "planInsuranceHea",
		key: "planInsuranceHea",
		align: "left",
		width: 200,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	{
		title: "แผนประกันอุบัติเหตุ",
		dataIndex: "planInsuranceAcd",
		key: "planInsuranceAcd",
		align: "left",
		width: 200,
		...filterSearchColumn("actionDate", "วันที่มีผล"),
	},
	// {
	//     title: "สถานะพนักงาน",
	//     dataIndex: "statusWorkerDes",
	//     key: "statusWorkerDes",
	//     align: "left",
	//     width: 200,
	//     ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
	// },
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "บริษัท (ใหม่)",
		dataIndex: "newCompany",
		key: "newCompany",
		align: "left",
		width: 100,
		...filterSearchColumn("newCompany", "บริษัท (ใหม่)"),
	},
	{
		title: "สถานที่",
		dataIndex: "location",
		key: "location",
		align: "left",
		width: 100,
		...filterSearchColumn("location", "สถานที่"),
	},
	{
		title: "สถานที่ (ใหม่)",
		dataIndex: "newLocation",
		key: "newLocation",
		align: "left",
		width: 100,
		...filterSearchColumn("newLocation", "สถานที่ (ใหม่)"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "ชื่อแผนก (ใหม่)",
		dataIndex: "newDepNoDes",
		key: "newDepNoDes",
		align: "left",
		width: 300,
		...filterSearchColumn("newDepNoDes", "ชื่อแผนก (ใหม่)"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
	{
		title: "แผนกย่อย (ใหม่)",
		dataIndex: "newSubDepNoDes",
		key: "newSubDepNoDes",
		align: "left",
		width: 400,
		...filterSearchColumn("newSubDepNoDes", "แผนกย่อย (ใหม่)"),
	},
];

export const ColumnBenefitNewEmp = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personal_ID",
		key: "personal_ID",
		align: "left",
		width: 200,
		...filterSearchColumn("personal_ID", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "brithday",
		key: "brithday",
		align: "left",
		width: 150,
		...filterSearchColumn("brithday", "วันเกิด"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	{
		title: "ประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 150,
		render: (record) => {
			const { date_HEA, expACD_HEA } = record;
			let textProps = {};
			if (date_HEA) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${date_HEA}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` ${expACD_HEA}`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "กองทุนสำรองเลี้ยงชีพ",
		key: "statusPVD",
		align: "left",
		width: 150,
		render: (record) => {
			const { date_PVD, expPVD } = record;
			//return conditionDate(date_PVD, expPVD)
			let textProps = {};
			if (date_PVD) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${date_PVD}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` ${expPVD}`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "แผนประกันสุขภาพ",
		dataIndex: "planHea",
		key: "planHea",
		align: "left",
		width: 200,
		...filterSearchColumn("planHea", "แผนประกันสุขภาพ"),
	},
	{
		title: "แผนประกันอุบัติเหตุ",
		dataIndex: "planAcd",
		key: "planAcd",
		align: "left",
		width: 200,
		...filterSearchColumn("planAcd", "แผนประกันอุบัติเหตุ"),
	},
	{
		title: "สถานะพนักงาน",
		dataIndex: "statusWorkerDes",
		key: "statusWorkerDes",
		align: "left",
		width: 200,
		...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
	},
	{
		title: "สถานะ (ประเมินงาน)",
		dataIndex: "statusMainCodeDes",
		key: "statusMainCodeDes",
		align: "left",
		width: 150,
		...filterSearchColumn("statusMainCodeDes", "สถานะ (ประเมินงาน)"),
	},
	{
		title: "ชื่อผู้บังคับบัญชา",
		dataIndex: "apprivalName1",
		key: "apprivalName1",
		align: "left",
		width: 200,
		...filterSearchColumn("apprivalName1", "ชื่อผู้บังคับบัญชา"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "startDateJob",
		key: "startDateJob",
		align: "right",
		width: 150,
		//...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
		sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
	},
	{
		title: "วันผ่านทดลองงาน",
		dataIndex: "probationEndDate",
		key: "probationEndDate",
		align: "right",
		width: 150,
		//...filterSearchColumn("probationEndDate", "วันผ่านทดลองงาน"),
		sorter: (a, b) => moment(a.probationEndDate).unix() - moment(b.probationEndDate).unix(),
	},
	{
		title: "วันที่ HR รับเอกสาร",
		dataIndex: "HR_ReceivedDate",
		key: "HR_ReceivedDate",
		align: "right",
		width: 150,
		...useSorterTable("HR_ReceivedDate", "date"),
	},
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "Location",
		dataIndex: "Location",
		key: "Location",
		align: "left",
		width: 100,
		...filterSearchColumn("Location", "Location"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
];

export const ColumnBenefitNewEmpTemp = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personal_ID",
		key: "personal_ID",
		align: "right",
		width: 200,
		...filterSearchColumn("personal_ID", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "brithday",
		key: "brithday",
		align: "left",
		width: 150,
		...filterSearchColumn("brithday", "วันเกิด"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	{
		title: "ประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 150,
		render: (record) => {
			const { date_HEA, expACD_HEA } = record;
			let textProps = {};
			if (date_HEA) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${date_HEA}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` ${expACD_HEA}`,
				};
			}
			return useText(textProps);
		},
	},
	// {
	//   title: "กองทุนสำรองเลี้ยงชีพ",
	//   key: "statusPVD",
	//   align: "left",
	//   width: 150,
	//   render: (record) => {
	//     const { date_PVD, expPVD } = record;
	//     let textProps = {};
	//     if (date_PVD) {
	//       textProps = {
	//         type: "success",
	//         icon: <CheckCircleOutlined />,
	//         text: ` ${date_PVD}`,
	//       };
	//     } else {
	//       textProps = {
	//         type: "danger",
	//         icon: <CloseCircleOutlined />,
	//         text: ` ${expPVD}`,
	//       };
	//     }
	//     return useText(textProps);
	//   },
	// },
	{
		title: "แผนประกันสุขภาพ",
		dataIndex: "planHeaDes",
		key: "planHeaDes",
		align: "left",
		width: 200,
		...filterSearchColumn("planHeaDes", "แผนประกันสุขภาพ"),
	},
	{
		title: "แผนประกันอุบัติเหตุ",
		dataIndex: "planAcdDes",
		key: "planAcdDes",
		align: "left",
		width: 200,
		...filterSearchColumn("planAcdDes", "แผนประกันอุบัติเหตุ"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "startDateJob",
		key: "startDateJob",
		align: "right",
		width: 150,
		//...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
		sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
	},
	{
		title: "วันที่สิ้นสุดสัญญาจ้าง",
		dataIndex: "endContactDate",
		key: "endContactDate",
		align: "right",
		width: 150,
		sorter: (a, b) => moment(a.endContactDate).unix() - moment(b.endContactDate).unix(),
	},
	{
		title: "วันที่ผ่านทดลองงาน",
		dataIndex: "passProbationDate",
		key: "passProbationDate",
		align: "right",
		width: 150,
		sorter: (a, b) => moment(a.passProbationDate).unix() - moment(b.passProbationDate).unix(),
	},
	{
		title: "วันที่ HR รับเอกสาร",
		dataIndex: "HR_ReceivedDate",
		key: "HR_ReceivedDate",
		align: "right",
		width: 150,
		...useSorterTable("HR_ReceivedDate", "date"),
	},
	{
		title: "สถานะ (ประเมินงาน)",
		dataIndex: "statusMainCodeDes",
		key: "statusMainCodeDes",
		align: "left",
		width: 200,
		...filterSearchColumn("statusMainCodeDes", "สถานะ (ประเมินงาน)"),
	},
	{
		title: "รอบสัญญาจ้าง",
		dataIndex: "roundContract",
		key: "roundContract",
		align: "right",
		width: 150,
		...filterSearchColumn("roundContract", "รอบสัญญาจ้าง"),
	},
	{
		title: "ชื่อผู้บังคับบัญชา",
		dataIndex: "apprivalName1",
		key: "apprivalName1",
		align: "left",
		width: 200,
		...filterSearchColumn("apprivalName1", "ชื่อผู้บังคับบัญชา"),
	},
	{
		title: "แผนก/กลุ่ม",
		dataIndex: "group",
		key: "group",
		align: "left",
		width: 150,
	},
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "สถานที่",
		dataIndex: "Location",
		key: "Location",
		align: "left",
		width: 100,
		...filterSearchColumn("Location", "สถานที่"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
	{
		title: "ระยะเวลาต่อสัญญา",
		dataIndex: "contractPeriodDes",
		key: "contractPeriodDes",
		align: "left",
		width: 200,
		...filterSearchColumn("contractPeriodDes", "ระยะเวลาต่อสัญญา"),
	},
];

export const ColumnBenefitOutEmp = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "คำนำหน้า",
		dataIndex: "title",
		key: "title",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("title", "คำนำหน้า"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "หมายเลขบัตรประชาชน",
		dataIndex: "personal_ID",
		key: "personal_ID",
		align: "right",
		width: 200,
		...filterSearchColumn("personal_ID", "หมายเลขบัตรประชาชน"),
	},
	{
		title: "วันเกิด",
		dataIndex: "brithday",
		key: "brithday",
		align: "left",
		width: 150,
		...filterSearchColumn("brithday", "วันเกิด"),
	},
	{
		title: "สถานะส่งเอกสาร",
		dataIndex: "statusStamp",
		key: "statusStamp",
		align: "left",
		width: 150,
		render: (text) => {
			let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
			let typeText = text === "done" ? "success" : "danger";
			return useText({
				text: showText,
				type: typeText,
				moreProps: { strong: true },
			});
		},
	},
	{
		title: "ประกันสุขภาพ/อุบัติเหตุ",
		key: "statusHEA",
		align: "left",
		width: 150,
		render: (record) => {
			const { dateOut_HEA, exp_HEA } = record;
			let textProps = {};
			if (dateOut_HEA) {
				textProps = {
					type: "success",
					icon: <CheckCircleOutlined />,
					text: ` ${dateOut_HEA}`,
				};
			} else {
				textProps = {
					type: "danger",
					icon: <CloseCircleOutlined />,
					text: ` ${exp_HEA}`,
				};
			}
			return useText(textProps);
		},
	},
	{
		title: "กองทุนสำรองเลี้ยงชีพ",
		key: "statusPVD",
		align: "left",
		width: 150,
		render: (record) => {
			const { dateOut_PVD, exp_PVD } = record;
			let textProps = {};
			if (record.statusWorkerCode !== "Temp") {
				if (dateOut_PVD) {
					textProps = {
						type: "success",
						icon: <CheckCircleOutlined />,
						text: ` ${dateOut_PVD}`,
					};
				} else {
					textProps = {
						type: "danger",
						icon: <CloseCircleOutlined />,
						text: ` ${exp_PVD}`,
					};
				}
				return useText(textProps);
			} else {
				return "-"
			}
		},
	},
	{
		title: "สถานะการคืนสินทรัพย์",
		dataIndex: "statusReturn",
		key: "statusReturn",
		align: "center",
		width: 150,
		...filterSearchColumn("statusReturn", "สถานะการคืนสินทรัพย์"),
		render: (text) => {
			if (text === "รอยืนยัน")
			  return useText({
				text: text,
				type: "danger",
				moreProps: { strong: true },
			  });
			return { text };
		  },
	},
	{
		title: "วันที่รับเช็ค",
		dataIndex: "dateGetChq",
		key: "dateGetChq",
		align: "left",
		width: 150,
	},
	{
		title: "ชื่อผู้บังคับบัญชา",
		dataIndex: "approvalName1",
		key: "approvalName1",
		align: "left",
		width: 200,
		...filterSearchColumn("approvalName1", "ชื่อผู้บังคับบัญชา"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "startDateJob",
		key: "startDateJob",
		align: "right",
		width: 150,
		//...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
		sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
	},
	{
		title: "วันสิ้นสุดการทำงาน",
		dataIndex: "endDateJob",
		key: "endDateJob",
		align: "right",
		width: 150,
		//...filterSearchColumn("endDateJob", "วันสิ้นสุดการทำงาน"),
		sorter: (a, b) => moment(a.endDateJob).unix() - moment(b.endDateJob).unix(),
	},
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "left",
		width: 100,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "สถานที่",
		dataIndex: "Location",
		key: "Location",
		align: "left",
		width: 100,
		...filterSearchColumn("Location", "สถานที่"),
	},
	{
		title: "ชื่อแผนก",
		dataIndex: "depName",
		key: "depName",
		align: "left",
		width: 300,
		...filterSearchColumn("depName", "ชื่อแผนก"),
	},
	{
		title: "แผนกย่อย",
		dataIndex: "subDepName",
		key: "subDepName",
		align: "left",
		width: 400,
		...filterSearchColumn("subDepName", "แผนกย่อย"),
	},
	{
		title: "รอบสัญญา",
		dataIndex: "roundContract",
		key: "roundContract",
		align: "right",
		width: 150,
		//...filterSearchColumn("roundContract", "รอบสัญญา"),
		sorter: (a, b) => a.roundContract - b.roundContract,
	},
];

// render: (record) => {
//     const { date_PVD, expPVD } = record;
//     let textProps = {};
//     if (date_PVD) {
//         textProps = {
//             type: "success",
//             icon: <CheckCircleOutlined />,
//             text: ` ${date_PVD}`,
//         };
//     } else {
//         textProps = {
//             type: "danger",
//             icon: <CloseCircleOutlined />,
//             text: ` ${expPVD}`,
//         };
//     }
//     return useText(textProps);
// },

const conditionDate = (date, exp) => {
	let setColor = "";
	if (date) {
		return <span style={{ color: USE_COLORS.successDate }}> <CheckCircleOutlined /> {date} </span>
	} else {
		const getDay = new Date().getDate();
		if (getDay > 15) {
			setColor = USE_COLORS.overDate;
		} else if (getDay > 9 && getDay < 16) {
			setColor = USE_COLORS.yellow;
		} else if (getDay < 10) {
			setColor = USE_COLORS.black;
		}
		return <span style={{ color: setColor }}> <CloseCircleOutlined /> {exp} </span>
	}
}
