import { useState, useEffect, useRef } from "react";
import { GET, GET_FORM_CONTRACT_DETAIL } from "../../../../service";
import { decode } from "../../../../extendFunction/encodeString";
import moment from "moment";

export const useGetDetail = ({ setValue }) => {
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [allData, setAllData] = useState(null);
  const userId = useRef(0);

  useEffect(() => {
    onFetchDocumentDetail();
  }, []);

  useEffect(() => {
    setDefaultValue(allData);
  }, [allData]);

  const onFetchDocumentDetail = async () => {
    try {
      setLoadingDocument(true);
      const queryParams = new URLSearchParams(window.location.search);
      userId.current = decode(queryParams.get("userId"));
      const respond = await GET(
        `${GET_FORM_CONTRACT_DETAIL}?userId=${userId.current}`
      );
      const data = respond.result;
      setAllData(() => data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocument(false);
    }
  };

  const setDefaultValue = async (data) => {
    if (!data) return;
    const defaultValue = { 
      ...data, 
      jobGrade: decode(data.jobGrade),
      contractDate: moment().format('YYYY-MM-DD')
    };
    for (const [key, value] of Object.entries(defaultValue)) {
      setValue(key, value);
    }
  };

  return {
    loadingDocument,
  };
};
