import React, { useState } from 'react'
import { Checkbox, Col, Row } from 'antd'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { TEXT_BUTTON } from '../buttons'



export const ButtonConfirm = ({ label, disabled, txtTrue, txtFalse, onClick }) => {
    const [statusCF, setStatusCF] = useState(false)
    return (
        <Row >
            <Col span={6}>
                <Checkbox
                    disabled={disabled}
                    style={{ paddingTop: 10 }}
                    checked={statusCF}
                    onChange={() => setStatusCF(!statusCF)} >
                    {TEXT_BUTTON.CONFIRM || label}
                </Checkbox>
            </Col>
            <Col span={18}>
                <div className="layout-content-btn-confirm">
                    <ButtonTheme
                        disabled={(statusCF) ? disabled : false}
                        type={(statusCF) ? "primary" : ''}
                        style={{ paddingRight: 10 }}
                        buttonText={(statusCF) ? (TEXT_BUTTON.CONFIRM || txtTrue) : (TEXT_BUTTON.DRAFT || txtFalse)}
                        onClick={() => onClick(statusCF)}
                    />
                </div>
            </Col>
        </Row>
    )
}
