export const tab_self = [
  {
    tab_ref: "loadData",
    tab_label: "Action",
    key_field: "DocNo",
  },
  {
    tab_ref: "loadDataView",
    tab_label: "View",
    key_field: "DocNo",
  },
];

export const tab_Discussion = [
  {
    tab_ref: "loadData",
    tab_label: "Action",
    key_field: "DocNo",
  },
  {
    tab_ref: "loadDataView",
    tab_label: "View",
    key_field: "DocNo",
  },
];

export const tab_CF_Discussion = [
  {
    tab_ref: "loadData",
    tab_label: "Action",
    key_field: "DocNo",
  },
  {
    tab_ref: "loadDataView",
    tab_label: "View",
    key_field: "DocNo",
  },
];

export const tab_Performance = [
  {
    tab_ref: "loadData",
    tab_label: "Action",
    key_field: "DocNo",
  },
  {
    tab_ref: "loadDataView",
    tab_label: "View",
    key_field: "DocNo",
  },
];
