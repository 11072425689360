import React, { useContext, useState } from 'react'
import { InsertScore } from './modal';
import { EvaluationContext } from '../services/manage/evaluationProvider';
import { ButtonTheme } from '../common/buttons';
import { Modal } from 'antd';
import { IndicatorContext } from '../services/manage/indicatorProvider';
import { colors } from '../../themes';
import { APIReportIndicatorLineScore } from '../services/report/reportConnect';
import { APIMasterIndicatorFactory } from '../services/master/masterConnect';
import { InsertScoreFactory } from './modal/insertScoreFactory';

const ContentUpdateScore = ({ factory, content, payload }) => {
    const { viewDetail, onSubmitScoreFactory, evaluationState, onSubmitScore } = useContext(EvaluationContext)
    const { userLogin } = useContext(IndicatorContext)
    const [open, showModal] = useState(false)
    return (
        <div>
            <ButtonTheme
                buttonText="รอยืนยัน"  //"รอยืนยันคะแนน"
                style={{ padding: 0, justifyContent: 'center' }}
                buttonStyle={{ borderColor: colors.wraning, color: colors.wraning }}
                onClick={async () => {
                    if (factory) {
                        await APIMasterIndicatorFactory({ userId: content.ID_User, year: content.year, month: content.month }, res => {
                            if (res.result.length > 0) {
                                viewDetail({ ...content, indicatorFac: res.result, indicator: res.result })
                                showModal(true)
                            } else {
                                Modal.error({ title: 'ไม่สามารถทำรายการได้', content: 'กรุณาตรวจสอบรายการตัวชี้วัด' })
                            }
                        })
                    } else {
                        await APIReportIndicatorLineScore(payload, res => {
                            if (res.length > 0) {
                                viewDetail({ ...content, indicator: res })
                                showModal(true)
                            } else {
                                Modal.error({ title: 'ไม่สามารถทำรายการได้', content: 'กรุณาตรวจสอบรายการตัวชี้วัด' })
                            }
                        })

                    }
                }
                }

            />
            <Modal
                visible={open}
                title={'ประเมิน KPIs'}
                onOk={() => {
                    (factory) ?
                        onSubmitScoreFactory({ userLogin, content, update: true })
                        :
                        onSubmitScore(userLogin, true, { month: content.month, year: content.year })
                    showModal(false)
                }}
                onCancel={() => {
                    showModal(false)
                }}
                okText={((factory) ? evaluationState.statusApproval : evaluationState[`status${content.month}`] === 3) ? "ยืนยัน (แก้ไขไม่ได้) " : "บันทึกร่าง"}
                cancelText="ยกเลิก"
            >
                {
                    (factory) ?
                        <InsertScoreFactory
                            approval={content.approval}
                            month={content.month}
                            monthYear={content.monthYear} />
                        :
                        <InsertScore
                            approval={content.approval}
                            month={content.month}
                            monthYear={content.monthYear} />
                }
            </Modal>
        </div>

    )
}

export default ContentUpdateScore