import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from 'react-icons/fi';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { browserHistory } from 'react-router';
import { loadingPage } from '../components/loadingPage'
import encodeString from '../extendFunction/encodeString';
import { Modal } from 'antd';
import { GET, POST, SELECT_DATA_CF_DISCUSSION, POST_UPDATE_CF_DIS } from "../service/";

class CF_DiscussionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadData: [],
            groupEdit: this.props.params.Type,
            DocDIS: encodeString.decode(this.props.params.DocCF),
            done: undefined,
            CF_Draft: false,
            CF_HR: false,
            userLogin: this.props.userLogin,
            premission: this.props.premission
        };
        this.updateDIS = this.updateDIS.bind(this)
    }

    componentDidMount() {
        this.selectData();
    }

    selectData = async () => {
        const docNo = this.state.DocDIS;
        const response = await GET(SELECT_DATA_CF_DISCUSSION({ docNo }));
        const { result } = response;
        const CF_GradeHR = result[0]['CF_GradeHR']
        const Years = result[0]['Years']
        this.setState({ loadData: result, CF_GradeHR: CF_GradeHR, done: true, Years: Years }) //
    }


    changeValue = (e) => {
        var _id = e.target.id
        var Arr = this.state.loadData
        Arr[0][_id] = e.target.value
        this.setState({ loadData: Arr });
    }


    updateDIS(event) {
        this.setState({ done: undefined });
        event.preventDefault();
        const data = new FormData(event.target);
        // let errors = {};
        // let formIsValid = true;
        var entries = data.entries();
        var current, item, key, output, value;
        output = {};
        while (item = entries.next().value) {
            // assign to variables to make the code more readable.
            key = item[0];
            value = item[1];
            // Check if key already exist
            if (Object.prototype.hasOwnProperty.call(output, key)) {
                current = output[key];
                if (!Array.isArray(current)) {
                    // If it's not an array, convert it to an array.
                    current = output[key] = [current];
                }
                current.push(value); // Add the new value to the array.
            } else {
                output[key] = value;
            }
        }
        // if (formIsValid === true) {
        let arrData = {
            userLogin: output.User_login,
            docNoDIS: output.DocNoDIS
        }
        this.setState({ done: true });
        Modal.confirm({
            title: 'คุณต้องการยืนยันการทำรายการใช่หรือไม่?',
            content: '',
            onOk: async () => {

                const res = await POST(POST_UPDATE_CF_DIS, arrData);
                const { result } = res
                const { status_api } = result;
                if (status_api === 1) {
                    Modal.success({
                        title: "Update Success!!",
                        onOk: () => browserHistory.push("/DashboardCF_DIS"),
                    });
                } else {
                    Modal.error({ title: "Update Error!!" });
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    onChangeCheckboxHR = (e) => {
        let isChecked = e.target.checked;
        this.setState({ CF_HR: isChecked })
    }

    onChangeCheckbox = (e) => {
        let isChecked = e.target.checked;
        this.setState({ CF_Draft: isChecked })
    }


    render() {
        var data = this.state.loadData;
        let checkbox
        let btnSave;

        btnSave = '(โปรดติ๊กยืนยันเพื่อรับทราบผลการประเมิน)'
        if (this.state.CF_Draft === true) {
            btnSave = <Button color="success" className="margin-right-20" > ยืนยันข้อมูล (แก้ไขไม่ได้) </Button>
        }

        checkbox = <div class="ui checkbox"> <Input type="checkbox" name="CF_Draft" id="CF_Draft"
            onChange={this.onChangeCheckbox} value={this.state.CF_Draft} defaultChecked={this.state.CF_Draft} />  <label>
                <b> ข้าพเจ้าได้รับทราบผลการปฎิบัติงานประจำปี {this.state.Years} และได้ทำการหารือผลงานกับผู้บังคับบัญชาเรียบร้อยแล้ว  </b></label></div>


        return (
            <Container>
                {this.state.done ? (
                    <div>
                        <Card>
                            <CardHeader><FiEdit3 className="margin-icon" />รับทราบผลการประเมินฟอร์มหารือผลงาน (DISCUSSION FORM)</CardHeader>
                            {data.map((val, index) => {
                                return (
                                    <CardBody>
                                        <Form onSubmit={this.updateDIS}>
                                            <Card>
                                                <CardHeader>ข้อมูลส่วนตัว</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="Type">ประเภทการประเมิน</Label>
                                                                <Input type="text" name="Type_des" id="Type_des" value={val.TypeDes} disabled />
                                                                <Input type="hidden" name="Type" id="Type" value={val.Type} />
                                                                <Input type="hidden" name="DocNoDIS" id="DocNoDIS" value={this.state.DocDIS} />
                                                                <Input type="hidden" name="groupEdit" id="groupEdit" value={this.state.groupEdit} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="Years">การประเมินตนเองประจำปี</Label>
                                                                <Input type="text" name="Years_Des" id="Years_Des" value={val.Years} disabled />
                                                                <Input type="hidden" name="Years" id="Years" value={val.Years} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="StaffName">ชื่อผู้ถูกประเมิน</Label>
                                                                <Input type="text" name="StaffName_dis" id="StaffName_dis" value={val.StaffName} disabled />
                                                                <Input type="hidden" name="StaffName" id="StaffName" value={val.StaffName} />
                                                                <Input type="hidden" name="StaffCode" id="StaffCode" value={val.StaffCode} />
                                                                <Input type="hidden" name="DepNo" id="DepNo" value={val.DepNo} />
                                                                <Input type="hidden" name="User_login" id="User_login" value={this.state.userLogin} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="Level_Worker">ตำแหน่ง</Label>
                                                                <Input type="text" name="Level_Worker" id="Level_Worker" disabled value={val.Level_WorkerDes} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="BossName">ชื่อผู้ประเมิน</Label>
                                                                <Input type="text" name="BossName_dis" id="BossName_dis" value={val.BossName} disabled />
                                                                <Input type="hidden" name="BossName" id="BossName" value={val.BossName} />
                                                                <Input type="hidden" name="BossCode" id="BossCode" value={val.BossCode} />
                                                                <Input type="hidden" name="ID_Flow" id="ID_Flow" value={val.ID_Flow} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="Level_Worker">ตำแหน่ง</Label>
                                                                <Input type="text" name="SupLevel_Worker" id="SupLevel_Worker" value={val.Level_WorkerBossDes} disabled />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            {/* <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col md={8}>
                                                                การให้คะแนนประเมินตนเอง (คะแนนเต็ม 10)
                              </Col>
                                                            <Col md={4}> <NumericInput className="form-control" name="selfScore" id="selfScore" value={val.selfScore} readOnly /></Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>

                                                            <Col md={12}>
                                                                <b>คำอธิบายเพิ่มเติม :: </b> &nbsp; &nbsp;
                                                        <label> 1-2 => ต่ำกว่าความคาดหวังมาก &nbsp; &nbsp;
                                                                3-4 => ต่ำกว่าความคาดหวัง &nbsp; &nbsp;
                                                                5-6 => ตามความคาดหวัง &nbsp; &nbsp;
                                                                7-8 => สูงกว่าความคาดหวัง &nbsp; &nbsp;
                                                        9-10 => สูงกว่าความคาดหวังมาก </label>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div> */}
                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader><span className="red"> * </span>0. เป้าหมายการหารือผลงาน / สิ่งที่คาดหวัง - ผู้บังคับบัญชาชี้แจงให้พนักงานทราบวัตถุประสงค์การสนทนา ได้แก่ </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Input type="textarea" className="text-area" name="Target_Discus" id="Target_Discus" rows="10"
                                                                        value={val.Target_Discus} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>1. ผลการดำเนินการ ปีที่ผ่านมา (Performance)</CardHeader>
                                                    <CardBody>
                                                        <Label for="company">1.1 ผลสำเร็จตามเป้าหมาย</Label>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="company"><span className="red"> * </span>1.1.) ผลสำเร็จตามเป้าหมาย</Label>
                                                                    <Input type="textarea" className="text-area" name="Target_Success" id="Target_Success" value={val.Target_Success} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN"><span className="red"> * </span>1.1.2) สิ่งที่ได้เรียนรู้และพัฒนา</Label>
                                                                    <Input type="textarea" className="text-area" name="Learn_Developed" id="Learn_Developed" value={val.Learn_Developed} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col> */}
                                                        </Row>
                                                        <br />
                                                        <Label for="company">1.2 ผลงานที่ยังไม่บรรลุตามความคาดหวัง</Label>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="company"><span className="red"> * </span>1.2.) ผลงานที่ยังไม่บรรลุตามเป้าหมาย</Label>
                                                                    <Input type="textarea" className="text-area" name="Target_NotSuccess" id="Target_NotSuccess" value={val.Target_NotSuccess} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN"><span className="red"> * </span>1.2.2) สาเหตุที่ยังไม่บรรลุตามเป้าหมาย</Label>
                                                                    <Input type="textarea" className="text-area" name="Causes_NotSuccess" id="Causes_NotSuccess" value={val.Causes_NotSuccess} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col> */}
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="company"><span className="red"> * </span>1.3) อุปสรรคหรือข้อจำกัดที่พบในการทำงาน (เช่น กระบวนการ บุคลากร ระบบ เครื่องมือ/อุปกรณ์ สิ่งแวดล้อม ความรู้ทักษะในการทำงาน)</Label>
                                                                    <Input type="textarea" className="text-area" name="Barrier" id="Barrier" value={val.Barrier} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN"><span className="red"> * </span>1.2.4) เราจะมีแนวทางพัฒนาหรือแก้ไขปัญหาร่วมกันอย่างไรได้บ้าง</Label>
                                                                    <Input type="textarea" className="text-area" name="Solution" id="Solution" value={val.Solution} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>2. การพัฒนาสมรรถนะในการทำงาน (Core & Managerial Competency)  ** อ้างอิง Form ประเมินผลงาน/Competency **</CardHeader>
                                                    <CardBody>
                                                        {/* <Row >
                                                            <Col md={12}>
                                                                3.1)คุณค่าที่ดีของพนักงาน - ชื่นชมในสิ่งที่ดี (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่เป็นคุณค่าที่ดี
                                                            </Col>
                                                        </Row> */}

                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label> 2.1) คุณค่าที่ดีของพนักงาน - ชื่นชมในสิ่งที่ดี (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่เป็นคุณค่าที่ดี</Label>
                                                                    <Input type="textarea" className="text-area" name="valueEmp" id="valueEmp" value={val.valueEmp} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col md={4}>
                                                                <FormGroup>
                                                                    <Label><span className="red"> * </span>พฤติกรรม/การกระทำที่เป็นคุณค่าที่ดีของพนักงาน (A)</Label>
                                                                    <Input type="textarea" className="text-area" name="actionEmp" id="actionEmp" value={val.actionEmp} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label><span className="red"> * </span> ผลของการกระทำของคุณค่าที่ดี (I)</Label>
                                                                    <Input type="textarea" className="text-area" name="resultEmp" id="resultEmp" value={val.resultEmp} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col> */}
                                                        </Row>
                                                        <br />
                                                        {/* <Row >
                                                            <Col md={12}>
                                                                3.2) คุณค่าที่ต้องการให้พนักงานพัฒนา (แนะให้เห็นแนวทางพัฒนา ปรับปรุง) (VAI) (ประเด็นหลักๆ 1-3 ข้อ)
                                                            </Col>
                                                        </Row> */}

                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>2.2) คุณค่าที่ต้องการให้พนักงานพัฒนา ปรับปรุง (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่อยากเห็น และแนะให้เห็นแนวทางพัฒนา ปรับปรุง</Label>
                                                                    <Input type="textarea" className="text-area" name="valueCulture" id="valueCulture" value={val.valueCulture} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* <Col md={4}>
                                                                <FormGroup>
                                                                    <Label><span className="red"> * </span>พฤติกรรม/การกระทำที่ดีที่ต้องการให้ทำ (A) </Label>
                                                                    <Input type="textarea" className="text-area" name="actionMust" id="actionMust" value={val.actionMust} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label> <span className="red"> * </span> ผลของการกระทำที่มีผลดีต่อพนักงาน (I)</Label>
                                                                    <Input type="textarea" className="text-area" name="directResult" id="directResult" value={val.directResult} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col> */}
                                                        </Row>
                                                        {/* <br />
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label> <span className="red"> * </span>3.3) แผนในการพัฒนา ปรับปรุงสมรรถนะ เป็นอย่างไรบ้าง กำหนดระยะเวลาร่วมกัน </Label>
                                                                    <Input type="textarea" className="text-area" name="planDev" id="planDev" value={val.planDev} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>3. ความรู้ ความเชี่ยวชาญในงาน  (Functional Competency)</CardHeader>
                                                    <CardBody>
                                                        <Label for="company"></Label>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label>3.1) สิ่งที่อยากพัฒนา ปรับปรุง -  มีเรื่องอะไรบ้างที่อยากเรียนรู้พัฒนาเพิ่มเติม เพื่อช่วยให้ทำงานได้ง่ายขึ้น ดีขึ้น  </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label>3.2) เหตุผลที่อยากเรียนรู้ พัฒนา (สำหรับแต่ละหัวข้อ)</Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="company"><span className="red"> * </span>ลำดับที่ 1 </Label>
                                                                    <Input type="textarea" className="text-area" name="KM_1" id="KM_1" value={val.KM_1} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN"><span className="red"> * </span>เหตุผล </Label>
                                                                    <Input type="textarea" className="text-area" name="Reason_1" id="Reason_1" value={val.Reason_1} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="company">ลำดับที่ 2 </Label>
                                                                    <Input type="textarea" className="text-area" name="KM_2" id="KM_2" value={val.KM_2} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN">เหตุผล </Label>
                                                                    <Input type="textarea" className="text-area" name="Reason_2" id="Reason_2" value={val.Reason_2} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="company">ลำดับที่ 3 </Label>
                                                                    <Input type="textarea" className="text-area" name="KM_3" id="KM_3" value={val.KM_3} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="FNameEN">เหตุผล </Label>
                                                                    <Input type="textarea" className="text-area" name="Reason_3" id="Reason_3" value={val.Reason_3} readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            

                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>4. ความคาดหวังในงานที่ชัดเจน </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>4.1) ชี้แจงหน้าที่รับผิดชอบ และความคาดหวัง</Label>
                                                                    <Input type="textarea" className="text-area" name="expectWork" id="expectWork" value={val.expectWork} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label > <span className="red"> * </span>4.2) แผนงานที่มอบหมายให้รับผิดชอบ (Action Plan ที่มอบหมายให้พนักงานรับผิดชอบ เพื่อสนับสนุนภาพรวมหน่วยงาน)</Label>
                                                                    <Input type="textarea" className="text-area" name="actionPlan" id="actionPlan" value={val.actionPlan} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}
                                                        {/* <Row>
                                                            <Col md={12}>
                                                                <Label >4.3) ผลของงานที่คาดหวัง และตัวชี้วัดผลงาน สำหรับปีนี้ (ปัจจัยหลัก 3-7 ข้อ)</Label>

                                                            </Col>
                                                        </Row> */}
                                                        <Row>
                                                            {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <Label ><span className="red"> * </span>ผลของงานที่ต้องทำให้บรรลุ (ผลงานที่ผ่านมาและที่ควรจะเป็น)</Label>
                                                                    <Input type="textarea" className="text-area" name="outputPass" id="outputPass" value={val.outputPass} rows="10" readOnly />

                                                                </FormGroup>
                                                            </Col> */}
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label >4.2) ตัวชี้วัดผลงานที่ชัดเจน</Label>
                                                                    <Input type="textarea" className="text-area" name="indicator" id="indicator" value={val.indicator} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>5.ข้อเสนอแนะ และการให้คำปรึกษา (รับ Feedback และให้ข้อเสนอแนะเพิ่มเติม)</CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                        5.1 สิ่งที่อยากเสนอแนะผู้บังคับบัญชา -
                                                                        การทำงานร่วมกันเป็นอย่างไรบ้าง มีสิ่งที่อยากให้
                                                                        ผู้บังคับบัญชาปรับหรือสนับสนุน (Feedback)
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea" className="text-area" name="Feedback" id="Feedback" value={val.Feedback} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                        5.2 สิ่งที่อยากเสนอแนะองค์กร - สิ่งที่อยากให้องค์กรปรับเพิ่มเติม
                                                                    </Label>
                                                                    <Input type="textarea" className="text-area" name="Organ_comment" id="Organ_comment" value={val.Organ_comment} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                        5.3 ความเห็นอื่นๆ - ประเด็นอื่นๆ ที่อยากหารือกับผู้บังคับบัญชา / อยากขอบคุณใคร หรือ หน่วยงานใด สำหรับการทำงานในปีนี้
                                                                    </Label>
                                                                    <Input type="textarea" className="text-area" name="Other_comment" id="Other_comment" value={val.Other_comment} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>6.ความร่วมมือ และความคิดริเริ่ม  </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                       6.1  ปีที่ผ่านมา เราช่วยให้  <b>"ใคร"</b> ในองค์กรได้ทำงานให้ประสบความสำเร็จบ้าง /<b> "ใคร"</b> ช่วยผลักดันงานของเราให้สำเร็จบ้าง
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea" className="text-area" name="whoHelp" id="whoHelp" value={val.whoHelp} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                        6.2 ปีที่ผ่านมาเอา <b>"ไอเดีย/ความคิดริเริ่ม"</b> ไหนขององค์กร หรือ ฝ่ายอื่นมาต่อยอดได้บ้าง
                                                                    </Label>
                                                                    <Input type="textarea" className="text-area" name="adaptIdea" id="adaptIdea" value={val.adaptIdea} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label>
                                                                       6.3 ถ้ามีโอกาสสร้างการเปลี่ยนแปลงในองค์กรได้ มีโครงการ มีไอเดียไหนที่น่าทำและอยากทำบ้าง (สามารถเสนอของหน่วยงานตัวเอง หรือหน่วยงานอื่น ๆ ได้)
                                                                    </Label>
                                                                    <Input type="textarea" className="text-area" name="changeOrg" id="changeOrg" value={val.changeOrg} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader> <span className="red"> * </span> 7.ผลการประเมินโดยรวม - (สื่อสาร แจ้งภาพรวมผลประเมินของพนักงานพร้อมทั้งแจ้ง โบนัส และการปรับเงินเดือนประจำปี)</CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Input type="textarea" className="text-area" name="overallResult" id="overallResult" value={val.overallResult} rows="10" readOnly />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            {/* <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>8. สรุปสิ่งที่จะพัฒนาปรับปรุงร่วมกันในปีนี้ ที่ชัดเจน (เรียงตามลำดับความสำคัญ 2-5 เรื่อง) </CardHeader>
                                                    <CardBody>
                                                        <br />
                                                        <Row >
                                                            <Col md={2} > <center><b>ลำดับที่</b> </center>   </Col>
                                                            <Col md={6} > <center><b>  หัวข้อ/ รายละเอียด</b> </center>     </Col>
                                                            <Col md={4} > <center> <b>กำหนดเวลา</b>    </center>     </Col>
                                                        </Row><br />
                                                        <Row>
                                                            <Col md={2} > <center> <span className="red"> * </span> 1 </center>
                                                            </Col>
                                                            <Col md={6} >
                                                                <Input type="textarea" className="text-area" name="overallDepYearDetail_1" id="overallDepYearDetail_1" value={val.overallDepYearDetail_1} readOnly />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Input type="textarea" className="text-area" name="overallDepYearTime_1" id="overallDepYearTime_1" value={val.overallDepYearTime_1} readOnly />
                                                            </Col>
                                                        </Row><br />
                                                        <Row>
                                                            <Col md={2} > <center> <span className="red"> * </span> 2 </center>
                                                            </Col>
                                                            <Col md={6} >
                                                                <Input type="textarea" className="text-area" name="overallDepYearDetail_2" id="overallDepYearDetail_2" value={val.overallDepYearDetail_2} readOnly />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Input type="textarea" className="text-area" name="overallDepYearTime_2" id="overallDepYearTime_2" value={val.overallDepYearTime_2} readOnly />
                                                            </Col>
                                                        </Row><br />
                                                        <Row>
                                                            <Col md={2} > <center> 3 </center>
                                                            </Col>
                                                            <Col md={6} >
                                                                <Input type="textarea" className="text-area" name="overallDepYearDetail_3" id="overallDepYearDetail_3" value={val.overallDepYearDetail_3} readOnly />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Input type="textarea" className="text-area" name="overallDepYearTime_3" id="overallDepYearTime_3" value={val.overallDepYearTime_3} readOnly />
                                                            </Col>
                                                        </Row><br />
                                                        <Row>
                                                            <Col md={2} > <center> 4 </center>
                                                            </Col>
                                                            <Col md={6} >
                                                                <Input type="textarea" className="text-area" name="overallDepYearDetail_4" id="overallDepYearDetail_4" value={val.overallDepYearDetail_4} readOnly />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Input type="textarea" className="text-area" name="overallDepYearTime_4" id="overallDepYearTime_4" value={val.overallDepYearTime_4} readOnly />
                                                            </Col>
                                                        </Row><br />
                                                        <Row>
                                                            <Col md={2} > <center> 5 </center>
                                                            </Col>
                                                            <Col md={6} >
                                                                <Input type="textarea" className="text-area" name="overallDepYearDetail_5" id="overallDepYearDetail_5" value={val.overallDepYearDetail_5} readOnly />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Input type="textarea" className="text-area" name="overallDepYearTime_5" id="overallDepYearTime_5" value={val.overallDepYearTime_5} readOnly />
                                                            </Col>
                                                        </Row>

                                                    </CardBody>
                                                </Card>
                                            </div> */}
                                            <div className="margin-card2">
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col md={12}>   8. สรุปผลการดำเนินงานประจำปี {val.Years}    </Col>
                                                            {/* <Col md={4}> {val.gradeDes}</Col> */}
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={12}> {val.Type === 'Half' ? '-' : val.gradeDes === '' ? '(รอทาง HR ยืนยันผลการประเมิน)' : val.gradeDes}</Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <Row>
                                                <Col md={12} className="text-center">
                                                    <div class="ui checkbox">
                                                        {checkbox}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} className="text-center">
                                                    {btnSave}
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                );

                            })}
                        </Card>
                    </div>) : (<div>{loadingPage} </div>)}
            </Container>
        );

    }
}
export default CF_DiscussionForm;