import { Col, Row } from 'antd';
import React from 'react'

const FormPrintCertificateSalaryVISA = () => {
    return (
        <div style={{ display: "block", fontSize: 20 }}>
            <Row>
                <Col span={8} >
                    picture
                </Col>
                <Col span={16} style={{ display: 'block', textAlign: "right" }}>
                    <div >
                        <b>Dplus Intertrade Co.,Ltd </b><br />
                        123/20-22 Nontree Road, Chongnontree, Yannawa, Bangkok, Thailand <br />
                        Tel. (66)2 294-4848 (Auto 10 Lines) <br />
                    </div>
                </Col>
            </Row>
            <br />
            <br />
            <div style={{ display: "block" }}> September 11, 2020 </div>
            <div style={{ display: "block", textAlign: "center", fontWeight: "bold" }}> To Whom it may concern </div>
        </div>

    )
};

export default FormPrintCertificateSalaryVISA;
