import apisauce from 'apisauce';
import { CONFIG } from '../../components/connectAPI';
import { withFormData, withFormDataObject } from '../../constants/functions/uploadFile';
import { CHECK_IMPORT_INCENTIVE_EXCEL, IMPORT_MAP_FOCUS_SHOP_SALE_CODE_EXCEL } from '../../service';

const create = (baseURL = `${CONFIG.API_URL}`) => {

	const API = apisauce.create({
		baseURL,
		headers: {
			"Content-Type": "application/json",
			"Cache-Control": "no-cache",
		},
		timeout: 100000
	})
	//# REGION MASTER    
	const masterOptions = (payload) => API.get(`${CONFIG.MASTER.SELECT_OPTIONS}`, payload, {});
	const masterEvaluationEmployee = (payload) => API.get(`${CONFIG.MASTER.EVALUATION_EMPLOYEE}`, payload, {});
	const masterIndicatorStatus = (payload) => API.get(`${CONFIG.MASTER.INDICATOR_STATUS}`, payload, {});
	const masterIndicatorHeader = (payload) => API.get(`${CONFIG.MASTER.INDICATOR_HEADER}`, payload, {});
	const masterBossApproval = (payload) => API.get(`${CONFIG.MASTER.BOSS_APPROVAL}`, payload, {});
	const masterBossInspect = (payload) => API.get(`${CONFIG.MASTER.BOSS_INSPECT}`, payload, {});
	const masterDepartment = (payload) => API.get(`${CONFIG.MASTER.PERMISSIONFILTER}`, payload, {});
	const masterIndicatorFactory = (payload) => API.get(`${CONFIG.MASTER.INDICATOR_FACTORY}`, payload, {});
	const masterFilterFactory = (payload) => API.get(`${CONFIG.MASTER.FILTER_FACTORY}`, payload, {});
	const masterFilterSubDepFactory = (payload) => API.get(`${CONFIG.MASTER.FILTER_FACTORY_SUBDEP}`, payload, {});
	//# END REGION MASTER 

	//# REGION MANAGE    
	const insertIndicatorHeader = (payload) => API.post(`${CONFIG.MANAGE.INDICATOR.INSERT}`, payload, {});
	const updateIndicatorHeader = (payload) => API.post(`${CONFIG.MANAGE.INDICATOR.UPDATE}`, payload, {});
	const insertIndicatorLicense = (payload) => API.post(`${CONFIG.MANAGE.INDICATOR.INSERT_LICENSE}`, payload, {});
	const updateIndicatorLicense = (payload) => API.post(`${CONFIG.MANAGE.INDICATOR.UPDATE_LICENSE}`, payload, {});
	const updateIndicatorTime = (payload) => API.post(`${CONFIG.MANAGE.INDICATOR.UPDATE_TIME}`, payload, {});
	const insertEvaluationScore = (payload) => API.post(`${CONFIG.MANAGE.EVALUATION.INSERT}`, payload, {});
	const updateEvaluationScore = (payload) => API.post(`${CONFIG.MANAGE.EVALUATION.UPDATE}`, payload, {});
	//# END REGION MANAGE 

	//# REGION REPORT    
	const reportIndicatorHeader = (payload) => API.get(`${CONFIG.REPORT.INDICATOR_HEADER}`, payload, {});
	const reportIndicatorLine = (payload) => API.get(`${CONFIG.REPORT.INDICATOR_LINE}`, payload, {});
	const reportIndicatorLicense = (payload) => API.get(`${CONFIG.REPORT.INDICATOR_LICENSE}`, payload, {});
	const reportIndicatorLineScore = (payload) => API.post(`${CONFIG.REPORT.INDICATOR_LINE_SCORE}`, payload, {});
	const reportEvaluationKPIs = (payload) => API.get(`${CONFIG.REPORT.EVALUATION_KPIs}`, payload, {});
	const reportEvaluation = (payload) => API.post(`${CONFIG.REPORT.EVALUATION}`, payload, {});
	const reportEvaluationLine = (payload) => API.post(`${CONFIG.REPORT.EVALUATION_LINE}`, payload, {});
	const reportAll = (payload) => API.post(`${CONFIG.REPORT.ALL}`, payload, {});
	const reportIndicatorApproval = (payload) => API.get(`${CONFIG.REPORT.INDICATOR_APPROVAL}`, payload, {});
	const reportDashboardKPIs = (payload) => API.get(`${CONFIG.REPORT.DASHBOARD_KPIS}`, payload, {});
	//# END REGION REPORT 

	//# REGION KPIS_FACTORY
	const insertScoreFactory = (payload) => API.post(`${CONFIG.FACTORY.INSERT_SCORE}`, payload, {});
	const updateScoreFactory = (payload) => API.post(`${CONFIG.FACTORY.UPDATE_SCORE}`, payload, {});
	const reportFactory = (payload) => API.get(`${CONFIG.FACTORY.REPORT_SCORE}`, payload, {});
	const insertConfirmKPIsFac = (payload) => API.post(`${CONFIG.FACTORY.INSERT_CONFIRM_KPIs}`, payload, {});
	const updateStatusKPIsFac = (payload) => API.post(`${CONFIG.FACTORY.UPDATE_STATUS}`, payload, {});
	//# END REGION KPIS_FACTORY

	//# REGION FACTORY_OT
	const insertRequestOT = (payload) => API.post(`${CONFIG.FACTORY.INSERT_REQUEST_OT}`, payload, {});
	const updateRequestOT = (payload) => API.post(`${CONFIG.FACTORY.UPDATE_REQUEST_OT}`, payload, {});
	const selectUserRequestOT = (payload) => API.get(`${CONFIG.FACTORY.SELECT_USER_REQUEST_OT}`, payload, {});
	const selectSubDepartmentOT = (payload) => API.get(`${CONFIG.FACTORY.SELECT_SUB_DEPARTMENT}`, payload, {});
	const selectFilterRequestOT = (payload) => API.get(`${CONFIG.FACTORY.SELECT_FILTER_REQUEST_OT}`, payload, {});
	const selectHRMFlow = (payload) => API.get(`${CONFIG.FACTORY.SELECT_HRM_FLOW}`, payload, {});
	const selectOptionDashboard = (payload) => API.get(`${CONFIG.FACTORY.SELECT_OPTION_DASHBOARD}`, payload, {});
	const dashboardOTFactory = (payload) => API.get(`${CONFIG.FACTORY.DASHBOARD_OT_FACTORY}`, payload, {});
	const dashboardOTFactoryApproval = (payload) => API.get(`${CONFIG.FACTORY.DASHBOARD_OT_FACTORY_APPROVAL}`, payload, {});
	const selectReportOT = (payload) => API.get(`${CONFIG.FACTORY.REPORT_OT_FACTORY}`, payload, {});
	const selectFilterDashboardOTFac = (payload) => API.get(`${CONFIG.FACTORY.SELECT_FILTER_DASHBOARD_OTFAC}`, payload, {});
	const selectWorkShift = (payload) => API.get(`${CONFIG.FACTORY.SELECT_WORK_SHIFT}`, payload, {});
	const exportOTFac = (payload) => API.get(`${CONFIG.FACTORY.EXPORT_OT_FACTORY}`, payload, {});

	const updateCFApprovalOT = (payload) => API.post(`${CONFIG.FACTORY.UPDATE_CF_APPROVAL_OT}`, payload, {});
	const updateRecallROT = (payload) => API.post(`${CONFIG.FACTORY.UPDATE_RECALLROT}`, payload, {});
	const delRequestOTLine = (payload) => API.post(`${CONFIG.FACTORY.DEL_REQUEST_OT_LINE}`, payload, {});
	const delRequestOTLineAll = (payload) => API.post(`${CONFIG.FACTORY.DEL_REQUEST_OT_LINE_ALL}`, payload, {});

	//# END REGION FACTORY_OT


	//# REGION OF EVAC

	const selectDashboardReviewer = (payload) => API.get(`${CONFIG.EVAC.SELECT_DASHBOARD_REVIEWER}`, payload, {});
	const insertReviewer = (payload) => API.post(`${CONFIG.EVAC.INSERT_REVIEWER}`, payload, {});
	const updateReviewer = (payload) => API.post(`${CONFIG.EVAC.UPDATE_REVIEWER}`, payload, {});
	const deleteReviewer = (payload) => API.post(`${CONFIG.EVAC.DELETE_REVIEWER}`, payload, {});

	const selectDashboardEvac = (payload) => API.get(`${CONFIG.EVAC.SELECT_DASHBOARD_EVAC}`, payload, {});
	const selectDashboardEvacAction = (payload) => API.get(`${CONFIG.EVAC.SELECT_DASHBOARD_EVAC_ACTION}`, payload, {});
	const selectDashboardEvacView = (payload) => API.get(`${CONFIG.EVAC.SELECT_DASHBOARD_EVAC_VIEW}`, payload, {});
	const selectDataEvac = (payload) => API.get(`${CONFIG.EVAC.SELECT_DATA_EVAC}`, payload, {});
	const selectReviewerCurrent = (payload) => API.get(`${CONFIG.EVAC.SELETE_REVIEWER_CURRENT}`, payload, {});
	const selectReviewerUpdate = (payload) => API.get(`${CONFIG.EVAC.SELETE_REVIEWER_UPDATE}`, payload, {});
	const insertEvac = (payload) => API.post(`${CONFIG.EVAC.INSERT_EVAC}`, payload, {});

	const selectOptionFrequencyWorkWith = (payload) => API.get(`${CONFIG.EVAC.SELECT_OPTION_FREQUENCY_WORK_WITH}`, payload, {});
	const selectOptionDepEvac = (payload) => API.get(`${CONFIG.EVAC.SELECT_OPTION_DEP_EVAC}`, payload, {});
	const selectQuestion = (payload) => API.get(`${CONFIG.EVAC.SELECT_QUESTION}`, payload, {});
	const selectFilterDepEvac = (payload) => API.get(`${CONFIG.EVAC.SELECT_FILTER_DEP_EVAC}`, payload, {});
	const selectFormEvacAll = (payload) => API.get(`${CONFIG.EVAC.SELECT_FORM_EVAC_ALL}`, payload, {});
	const selectFormEvacByUserID = (payload) => API.get(`${CONFIG.EVAC.SELECT_FORM_EVAC_BY_USER_ID}`, payload, {});

	const selectSummaryReplyEvacByForm = (payload) => API.get(`${CONFIG.EVAC.SELECT_SUMMARY_REPLY_EVAC_BY_FORM}`, payload, {});
	const selectSummaryReplyEvacByDep = (payload) => API.get(`${CONFIG.EVAC.SELECT_SUMMARY_REPLY_EVAC_BY_DEP}`, payload, {});
	const selectSummaryAvgScoreByLine = (payload) => API.get(`${CONFIG.EVAC.SELECT_SUMMARY_AVG_SCORE_BY_LINE}`, payload, {});

	const getNonEvalation = (payload) => API.post(`/HRM_API/evac/getNonEvaluation`, payload)
	const recordEvaluationByQ = (payload) => API.post(`/HRM_API/evac/recordEvaluationByQ`, payload)

	//# END REGION OF EVAC

	const selectOptionAddressByPostCode = (payload) => API.get(`${CONFIG.SELECT_OPTION_ADDRESS_BY_POSTCODE}`, payload, {});
	const selectOptionDep = (payload) => API.get(`${CONFIG.SELECT_OPTION_DEP}`, payload, {});
	const selectOptionSubDep = (payload) => API.get(`${CONFIG.SELECT_OPTION_SUBDEP}`, payload, {});
	const selectOptionSupervisors = (payload) => API.get(`${CONFIG.SELECT_OPTION_SUPERVISOR}`, payload, {});
	const selectOptionMentor = (payload) => API.get(`${CONFIG.SELECT_OPTION_MENTOR}`, payload, {});
	const selectOptionFlow = (payload) => API.get(`${CONFIG.SELECT_OPTION_FLOW}`, payload, {});
	const selectOptionStatus = (payload) => API.get(`${CONFIG.SELECT_OPTION_STATUS}`, payload, {});
	const selectOptionUtility = (payload) => API.get(`${CONFIG.SELECT_OPTION_UTILITY}`, payload, {});
	const selectOptionWorkShiftAll = (payload) => API.get(`${CONFIG.SELECT_OPTION_WORKSHIFT_ALL}`, payload, {});
	const selectOptionDepartmentAll = (payload) => API.get(`${CONFIG.SELECT_OPTION_DEPARTMENT_ALL}`, payload, {});
	const selectOptionSubDepartment = (payload) => API.get(`${CONFIG.SELECT_OPTION_SUB_DEPARTMENT}`, payload, {});
	const selectOptionPosition = (payload) => API.get(`${CONFIG.SELECT_OPTION_POSITION}`, payload, {});


	const selectFilterDep = (payload) => API.get(`${CONFIG.SELECT_FILTER_DEP}`, payload, {});
	const selectFilterSubDep = (payload) => API.get(`${CONFIG.SELECT_FILTER_SUBDEP}`, payload, {});

	//# REGION OF TRAINING
	const selectOptionUserTraining = (payload) => API.get(`${CONFIG.TRAINING.SELECT_OPTION_USER_TRAINING}`, payload, {});
	const selectOptionTraining = (payload) => API.get(`${CONFIG.TRAINING.SELECT_OPTION_TRAINING}`, payload, {});
	const selectOptionTrainers = (payload) => API.get(`${CONFIG.TRAINING.SELECT_OPTION_TRAINERS}`, payload, {});
	const selectOptionTrainingCourse = (payload) => API.get(`${CONFIG.TRAINING.SELECT_OPTION_TRAINING_COURSE}`, payload, {});
	const selectTrainingCourse = (payload) => API.get(`${CONFIG.TRAINING.SELECT_TRAINING_COURSE}`, payload, {});
	const selectTrainingUser = (payload) => API.get(`${CONFIG.TRAINING.SELECT_TRAINING_USER}`, payload, {});
	const insertTrainingHistory = (payload) => API.post(`${CONFIG.TRAINING.INSERT_TRAINING_HISTORY}`, payload, {});
	const insertTraining = (payload) => API.post(`${CONFIG.TRAINING.INSERT_TRAINING}`, payload, {});
	const importTraining = (payload) => API.post(`${CONFIG.TRAINING.IMPORT_TRAINING}`, payload, {});
	const updateTraining = (payload) => API.post(`${CONFIG.TRAINING.UPDATE_TRAINING}`, payload, {});
	const viewTraining = (payload) => API.get(`${CONFIG.TRAINING.VIEW_TRAINING}`, payload, {});
	const removeTraining = (payload) => API.post(`${CONFIG.TRAINING.REMOVE_TRAINING}`, payload, {});
	const insertTrainingFile = (payload) => API.post(`${CONFIG.TRAINING.INSERT_FILE}`, withFormData(payload), {});
	const insertOthersFile = (payload) => API.post(`${CONFIG.TRAINING.INSERT_OTHERS_FILE}`, withFormData(payload), {});
	const downloadTrainingFile = (payload) => API.get(`${CONFIG.TRAINING.DOWNLOAD_FILE}`, payload, {});

	const selectDashboardIndividual = (payload) => API.get(`${CONFIG.TRAINING.DASHBOARD_INDIVIDUAL}`, payload, {});
	const selectDashboardTraining = (payload) => API.get(`${CONFIG.TRAINING.DASHBOARD_TRAINING}`, payload, {});
	const selectDashboardTrainers = (payload) => API.get(`${CONFIG.TRAINING.DASHBOARD_TRAINERS}`, payload, {});
	//# END REGION OF TRAINING


	//# REGION OF PROBATION
	const selectContractByDocNo = (payload) => API.post(`${CONFIG.PROBATION.DASHBOARD_CONTRACT_BY_DOCNO}`, payload, {});
	const selectDashboardContract = (payload) => API.post(`${CONFIG.PROBATION.DASHBOARD_CONTRACT}`, payload, {});
	const selectDashboardContractNew = (payload) => API.post(`${CONFIG.PROBATION.DASHBOARD_CONTRACT_New}`, payload, {});
	const selectDashboardContractView = (payload) => API.post(`${CONFIG.PROBATION.DASHBOARD_CONTRACT_VIEW}`, payload, {});
	const selectDashboardBenenfitsOut = (payload) => API.get(`${CONFIG.PROBATION.DASHBOARD_BENEFIT_FITS_OUT}`, payload, {});
	const selectDashboardTrack = (payload) => API.get(`${CONFIG.PROBATION.DASHBOARD_TRACK}`, payload, {});
	const selectDashboardBenefit = (payload) => API.get(`${CONFIG.PROBATION.DASHBOARD_BENEFIT}`, payload, {});

	const insertTimeStampBenefits = (payload) => API.post(`${CONFIG.PROBATION.INSERT_TIMESTAMP_BENEFITS}`, payload, {});
	const insertProbationContract = (payload) => API.post(`${CONFIG.PROBATION.INSERT_CONTRACT}`, payload, {});
	const confirmProbationContract = (payload) => API.post(`${CONFIG.PROBATION.CONFIRM_CONTRACT}`, payload, {});
	const updateProbationContract = (payload) => API.post(`${CONFIG.PROBATION.UPDATE_CONTRACT}`, payload, {});
	const confirmReceivedContract = (payload) => API.post(`${CONFIG.PROBATION.CONFIRM_RECEIVED_CONTRACT}`, payload, {});

	//# END REGION OF PROBATION

	/* ----------------------------- Module Personal ---------------------------- */
	const exportProsoft = (payload) => API.get(`${CONFIG.PERSONAL.EXPORT_PROSOFT}`, payload, {});
	const getVerifyUser = (payload) => API.post(`${CONFIG.PERSONAL.GET_VERIFY_EMP}`, payload, {});
	const getUserCode = (payload) => API.get(`${CONFIG.PERSONAL.GET_USER_CODE}`, payload, {});
	const insertProbationHistory = (payload) => API.post(`${CONFIG.PERSONAL.INSERT_PROBATION_HISTORY}`, payload, {});
	const insertPersonal = (payload) => API.post(`${CONFIG.PERSONAL.INSERT_PERSONAL}`, withFormDataObject(payload), {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
	const insertPersonalIdCard = (payload) => API.post(`${CONFIG.PERSONAL.INSERT_PERSONAL_ID_CARD}`, payload, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
	const removeNewEmpByIdCardDocNo = (payload) => API.post(`${CONFIG.PERSONAL.REMOVE_PERSONAL_ID_CARD_DOCNO}`, payload, {});
	const importPersonal = (payload) => API.post(`${CONFIG.PERSONAL.IMPOER_PERSONAL}`, withFormDataObject(payload), {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
	const updatePersonal = (payload) => API.post(`${CONFIG.PERSONAL.UPDATE_PERSONAL}`, withFormDataObject(payload), {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
	const selectPersonal = (payload) => API.post(`${CONFIG.PERSONAL.SELECT_EMP_PERSONAL}`, payload, {});
	const dashboardPersonal = (payload) => API.post(`${CONFIG.PERSONAL.DASHBOARD_PERSONAL}`, payload, {});
	const dashboardImportPersonal = (payload) => API.get(`${CONFIG.PERSONAL.SELECT_IMPORT_PERSONAL}`, payload, {});
	const getLineNoti = (payload) => API.post(`${CONFIG.PERSONAL.GET_LINE_NOTI}`, payload, {});
	const getDISC = (payload) => API.post(`${CONFIG.PERSONAL.GET_DISC}`, payload, {});

	/* ------------------------------- End Module ------------------------------- */

	const checkImportIncentiveExcel = (payload) => API.post(`${CHECK_IMPORT_INCENTIVE_EXCEL}`, payload, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
	const importMapFocusShopSaleCodeExcel = (payload) => API.post(`${IMPORT_MAP_FOCUS_SHOP_SALE_CODE_EXCEL}`, payload, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});


	return {

		/* ----------------------------- Module Personal ---------------------------- */
		exportProsoft,
		getVerifyUser,
		getUserCode,
		insertPersonal,
		importPersonal,
		updatePersonal,
		selectPersonal,
		dashboardPersonal,
		dashboardImportPersonal,
		insertProbationHistory,
		getLineNoti,
		getDISC,
		/* ------------------------------- End Module ------------------------------- */
		masterOptions,
		masterEvaluationEmployee,
		masterIndicatorStatus,
		masterIndicatorHeader,
		masterBossApproval,
		masterBossInspect,
		masterDepartment,
		masterIndicatorFactory,
		masterFilterFactory,
		masterFilterSubDepFactory,


		insertIndicatorHeader,
		updateIndicatorHeader,
		insertIndicatorLicense,
		updateIndicatorLicense,
		updateIndicatorTime,
		insertEvaluationScore,
		updateEvaluationScore,

		reportIndicatorLineScore,
		reportIndicatorHeader,
		reportIndicatorLine,
		reportIndicatorLicense,
		reportEvaluation,
		reportEvaluationKPIs,
		reportEvaluationLine,
		reportAll,
		reportIndicatorApproval,
		reportDashboardKPIs,

		insertScoreFactory,
		updateScoreFactory,
		reportFactory,
		insertConfirmKPIsFac,
		updateCFApprovalOT,
		updateRecallROT,
		delRequestOTLine,
		delRequestOTLineAll,
		updateStatusKPIsFac,

		insertRequestOT,
		updateRequestOT,
		selectUserRequestOT,
		selectSubDepartmentOT,
		selectFilterRequestOT,
		selectHRMFlow,
		selectOptionDashboard,
		dashboardOTFactory,
		selectReportOT,
		selectFilterDashboardOTFac,
		selectWorkShift,
		selectOptionWorkShiftAll,
		selectOptionDepartmentAll,
		selectOptionSubDepartment,
		selectOptionPosition,
		exportOTFac,
		selectOptionUtility,

		selectDashboardReviewer,
		insertReviewer,
		updateReviewer,
		deleteReviewer,
		selectDashboardEvac,
		selectDataEvac,
		selectReviewerCurrent,
		selectReviewerUpdate,
		insertEvac,
		selectOptionFrequencyWorkWith,
		selectOptionDepEvac,
		selectQuestion,
		selectFilterDepEvac,
		selectSummaryReplyEvacByForm,
		selectSummaryReplyEvacByDep,
		selectSummaryAvgScoreByLine,
		getNonEvalation,
		recordEvaluationByQ,
		selectFormEvacAll,
		selectFormEvacByUserID,
		selectDashboardEvacAction,
		selectDashboardEvacView,

		selectOptionAddressByPostCode,
		selectOptionDep,
		selectFilterDep,
		selectOptionSubDep,
		selectFilterSubDep,
		selectOptionStatus,
		insertOthersFile,

		selectDashboardTrainers,
		selectDashboardIndividual,
		selectDashboardTraining,
		selectOptionTrainingCourse,
		selectOptionTraining,
		selectOptionUserTraining,
		selectTrainingCourse,
		selectTrainingUser,
		selectOptionTrainers,
		selectOptionMentor,
		selectOptionSupervisors,
		insertTrainingHistory,
		insertTraining,
		insertTrainingFile,
		updateTraining,
		viewTraining,
		importTraining,
		removeTraining,
		selectOptionFlow,
		downloadTrainingFile,
		//# REGION OF PROBATION
		selectContractByDocNo,
		selectDashboardContract,
		selectDashboardContractNew,
		selectDashboardContractView,
		selectDashboardBenenfitsOut,
		selectDashboardTrack,
		selectDashboardBenefit,

		insertProbationContract,
		confirmProbationContract,
		updateProbationContract,
		insertTimeStampBenefits,
		confirmReceivedContract,
		dashboardOTFactoryApproval,
		//# END REGION OF PROBATION

		insertPersonalIdCard,
		removeNewEmpByIdCardDocNo,

		/**Commission Upload */
		checkImportIncentiveExcel,
		importMapFocusShopSaleCodeExcel
	}
}

export default { create }


