import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { API_URL } from "../components/connectAPI";
import { AiOutlineUser } from "react-icons/ai";
import Select from "react-select";
import { browserHistory } from "react-router";
import { loadingPage } from "../components/loadingPage";
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
} from "reactstrap";
import { Modal } from "antd";
import {
  GET,
  POST,
  POST_SELECT_DATA_ASSETS_FORM,
  SELECT_USER_LIST,
  SELECT_DATA_ASSET_TEMPLATE,
  GET_DETAIL_IN,
  GET_BEARER,
  POST_BEARER,
  POST_ADD_USER_IN,
} from "../service";
import { GET_UserLogin } from "../screens/functions/UseFunction";

let editCell = [];

const columns = [
  {
    dataField: "id",
    text: "ลำดับ",
    style: {
      width: "5%",
    },
  },

  {
    dataField: "type",
    text: "ประเภทสินทรัพย์",
    style: {
      width: "15%",
    },
    editable: false,
  },
  {
    dataField: "description",
    title: () => "กดคลิก",
    text: "รายการสินทรัพย์",
    style: { width: "50%", cursor: "pointer" },
  },
  {
    dataField: "note",
    text: "หมายเหตุ",
    style: {
      width: "30%",
      cursor: "pointer",
    },
    title: () => "กดคลิก",
    editor: {
      type: Type.INPUT,
    },
  },
  {
    dataField: "typeID",
    text: "TypeID",
    hidden: true,
  },
  {
    dataField: "subType",
    text: "SubType",
    hidden: true,
  },
];

let Lines = [];
let AllowWeb = [];
let AllowWebName = [];

class Assets_NewEmpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: "",
      loadDataTemplate: [],
      ChangeValue: [],
      WebByUserId: [],
      Web_UserID: "",
      resultUserList: [],
      resultTemplate: [],
      resultTemplateNum: [],
      findIndex: [],
      Description: "",
      selected_arr: [],
      done: undefined,
      isClearable: true,
      permission: [],
      statusSave: "",
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let userID = params.get("userId");
    this.setState({ userID: userID }, () => {
      this.getDataUser();
    });
  }

  option_onChange = (e) => {
    this.setState({ ChangeValue: e });
    this.selectAssetTemplate(e);
  };

  async getDataUser() {
    const userId = JSON.stringify({ userID: this.state.userID });
    try {
      const res = await GET_BEARER(GET_DETAIL_IN(this.state.userID));
      const { result } = res;
      this.setState({
        UserID: result.data.userId,
        FullName: result.data.fullname,
        SupName: result.data.bossName,
        DepartmentName: result.data.depName,
        StartDateJob: result.data.startDateJob,
        Location: result.data.location,
        Level_Worker: result.data.levelWorker,
        LevelID: result.data.levelId,
        DivisionID: result.data.divisionId,
        Company: result.data.Company,
        Status: result.data.Status,
        Sup: result.data.Sup,
        ManType: result.data.ManType,
        DupUserID: "",
      });
      this.setState({
        permission: result.data.permission,
        statusSave: result.data.statusSave,
      });
      this.getDataUserList();
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด" });
    }
  }

  async getDataUserList() {
    const res = await GET(SELECT_USER_LIST({ LevelWorker: "" }));
    const { result } = res;
    let resultUserList = result.map((val, index) => {
      return { value: val.ID_User, label: val.Name };
    });

    this.setState({ resultUserList: resultUserList }, () => {
      this.select_AllowWebByUserId();
      this.selectAssetTemplate();
    });
  }

  async selectAssetTemplate(e) {
    const res = await GET_BEARER(GET_DETAIL_IN(this.state.userID));
    const { result } = res;
    let resultTemplate = [];
    let resultTemplateNum = [];
    let Description = "";
    let selected_arr = [];
    let i = 1;

    result.data.line.map((val, index) => {
      if (val.type === "สิทธิ์เว็บอื่นๆ") {
        Description = (
          <React.Fragment>
            <Select
              options={this.state.WebByUserId}
              value={this.state.ChangeValue}
              placeholder="กรุณาเลือก...."
              onChange={this.option_onChange}
              isMulti
              isDisabled={this.state.statusSave === 1}
              //defaultValue={{ label: "Select Dept", value: 0 }}
            />
          </React.Fragment>
        );
      } else {
        Description = val.description;
      }

      resultTemplate.push({
        id: i++,
        type: val.type,
        description: Description,
        note: "",
        typeID: val.typeId,
        subType: val.subType,
      });

      resultTemplateNum.push(index + 1);

      if (val.defualt === "YES") {
        selected_arr.push(index + 1);
      }
    });

    this.setState({
      selected_arr: selected_arr,
      resultTemplate: resultTemplate,
      resultTemplateNum: resultTemplateNum,
      done: true,
    });
  }

  select_AllowWebByUserId(Web_UserID) {
    let API = `${API_URL}/HRM_API/select_AllowWebByUserId`;
    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ userID: Web_UserID }),
    })
      .then((res) => res.json())
      .then((results) => {
        let WebByUserId = results["result"].map((val, index) => {
          return { value: val.Web_No, label: val.Web_Name };
        });
        // let ChangeValue = results["result"].map((val, index) => {
        //   if (val.Checked === "1") {
        //     return { value: val.Web_No, label: val.Web_Name };
        //   }
        // });
        let ChangeValue = this.state.permission.map((val, index) => ({
          value: val,
        }));
        const filterDataSelect = WebByUserId.filter(({ value: value1 }) =>
          ChangeValue.some(({ value: value2 }) => value2 === value1)
        );
        this.setState({
          ChangeValue: filterDataSelect,
          WebByUserId: WebByUserId,
        });

        this.selectAssetTemplate(this.state.ChangeValue);
      })
      .catch((ex) => {
        console.log("error gate ttt");
        this.setState({ loading: false });
      });
  }

  onChange = (e) => {
    if (e === null) {
      //this.setState((state) => ({ isClearable: !state.isClearable }));
      const Web_UserID = "";
      this.select_AllowWebByUserId(Web_UserID);
      this.setState({ DupUserID: Web_UserID });
    } else {
      const Web_UserID = e.value;
      this.select_AllowWebByUserId(Web_UserID);
      this.setState({ DupUserID: Web_UserID });
    }
  };

  handleSaveData = () => {
    Lines = [];
    let dataAll = this.node.props.data;
    let indexSelect = this.node.selectionContext.selected;
    let dataSelect = dataAll.filter((e) =>
      indexSelect.some((el) => el === e.id)
    );

    let EditTemplate = "NO";
    let line = 1;
    dataSelect.map((val, index) => {
      let dataEdit = dataSelect.filter((g) =>
        editCell.some((gl) => gl === g.id)
      );
      dataEdit.map((value, index) => {
        if (val.type === value.type) {
          EditTemplate = "YES";
        } else {
          EditTemplate = "NO";
        }
      });
      let Description = "",
        Remark = "";
      if (val.type === "สิทธิ์เว็บอื่นๆ") {
        let WebDefualt = this.state.ChangeValue;
        let WebFilter = WebDefualt.filter((e) => e != undefined);

        WebFilter.map((val, index) => {
          AllowWeb.push(val.value);
          AllowWebName.push(val.label);
        });

        Description = AllowWebName.join();
        Remark = AllowWeb.join();
      } else {
        Description = val.description;
      }

      Lines.push({
        typeId: val.typeID,
        subType: val.subType,
        type: val.type,
        description: Description,
        edit: EditTemplate,
        note: val.note,
        remark: Remark,
      });
    });
    const objBody = {
      userId: this.state.userID,
      data: Lines,
    };
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => this.onPostAddUserIn(objBody),
    });
  };
  async onPostAddUserIn(objBody) {
    try {
      const res = await POST_BEARER(POST_ADD_USER_IN(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => browserHistory.push("/DashboardAssetEmp"),
        });
      }
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด", content: error.message });
    }
  }

  render() {
    const viewSelectRows = {
      mode: "checkbox",
      clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected_arr,
      nonSelectable: this.state.resultTemplateNum,
      hideSelectAll: true,
    };
    const editSelectRows = {
      mode: "checkbox",
      clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected_arr,
    };
    return (
      <Container>
        {this.state.done ? (
          <div>
            <Card>
              <CardHeader>
                <AiOutlineUser /> ข้อมูลพนักงาน
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup row>
                    <Label sm={1}>รหัส/ชื่อพนักงาน: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="UserName"
                        id="UserName"
                        value={this.state.FullName}
                        readOnly
                      />
                    </Col>
                    <Label sm={1}>รหัส/ชื่อหัวหน้า: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="SupName"
                        id="SupName"
                        value={this.state.SupName}
                        readOnly
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={1}>วันที่เริ่มทำงาน: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="StartDateJob"
                        id="StartDateJob"
                        value={this.state.StartDateJob}
                        readOnly
                      />
                    </Col>
                    <Label sm={1}>สถานที่: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="Location"
                        id="Location"
                        value={this.state.Location}
                        readOnly
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={1}>แผนก: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="DepartmentName"
                        id="DepartmentName"
                        value={this.state.DepartmentName}
                        readOnly
                      />
                    </Col>
                    <Label sm={1}>ระดับ: </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="Level_Worker"
                        id="Level_Worker"
                        value={this.state.Level_Worker}
                        readOnly
                      />
                    </Col>
                  </FormGroup>

                  <hr />
                  <FormGroup row>
                    <Label sm={1}>สิทธิ์เหมือน: </Label>
                    <Col sm={5}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        //defaultValue={""}
                        isClearable
                        options={this.state.resultUserList}
                        placeholder="กรุณาเลือก...."
                        onChange={this.onChange}
                        isDisabled={this.state.statusSave === 1}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              {this.state.statusSave !== 1 && (
                <Row>
                  <Col style={{ color: "red" }}>
                    <b>**หมายเหตุ:</b> - Double click เพื่อแก้ไขรายละเอียด
                    รายการสินทรัพย์ และ หมายเหตุ เมื่อแก้ไขเสร็จให้กดปุ่ม Enter
                  </Col>
                </Row>
              )}
            </Card>

            <BootstrapTable
              keyField="id"
              data={this.state.resultTemplate}
              columns={columns}
              ref={(n) => (this.node = n)}
              selectRow={
                this.state.statusSave === 1 ? viewSelectRows : editSelectRows
              }
              cellEdit={
                this.state.statusSave !== 1 &&
                cellEditFactory({
                  nonEditableRows: () => [17],
                  mode: "dbclick",
                  //blurToSave: true,
                  autoSelectText: true,

                  beforeSaveCell: (oldValue, newValue, row, column, done) => {
                    setTimeout(() => {
                      if (window.confirm("ต้องการบันทึกใช่หรือไม่?")) {
                        editCell.push(row.id);
                        done(true);
                      } else {
                        done(false);
                      }
                    }, 0);

                    return { async: true };
                  },
                })
              }
            />
            {this.state.statusSave !== 1 && (
              <center>
                <Button
                  type="submit"
                  color="success"
                  className="text-center"
                  onClick={this.handleSaveData}
                >
                  บันทึก
                </Button>
              </center>
            )}
          </div>
        ) : (
          <div>{loadingPage} </div>
        )}
      </Container>
    );
  }
}

export default Assets_NewEmpForm;
