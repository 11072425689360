import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";
import { NAME, LABEL } from "../TransferEmployee/StaticVariable";

export const searchEmployee = ({
  handleChange = () => null,
  options = {},
  isDisable = {},
}) => [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.company,
              label: LABEL.company,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.company) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.company),
              disabled: isDisable[NAME.company] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.company],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.location,
              label: LABEL.location,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.location) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.location),
              disabled: isDisable[NAME.location] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.location],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.depNo,
              label: LABEL.depNo,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.depNo) }],

            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.depNo),
              disabled: isDisable[NAME.depNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.depNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.subDepNo,
              label: LABEL.subDepNo,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.subDepNo) }],

            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.subDepNo),
              disabled: isDisable[NAME.subDepNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.subDepNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.hrmFlow,
              label: LABEL.hrmFlow,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.hrmFlow) }],

            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.hrmFlow),
              disabled: isDisable[NAME.hrmFlow] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.hrmFlow],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.viewFlow,
              label: LABEL.viewFlow,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.viewFlow) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.viewFlow),
              disabled: isDisable[NAME.viewFlow] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.viewFlow],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.positionNo,
              label: LABEL.positionNo,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.positionNo) }],

            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.positionNo),
              disabled: isDisable[NAME.positionNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.positionNo],
          },
        },
      ], // column
    }, // Row
  ];

export const actionChangeCompany = ({
  handleChange = () => null,
  options = {},
  isDisable = {},
}) => [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_company,
              label: LABEL.action_company,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_company) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_company),
              disabled: isDisable[NAME.action_company] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_company],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_location,
              label: LABEL.action_location,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_location) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_location),
              disabled: isDisable[NAME.action_location] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_location],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_depNo,
              label: LABEL.action_depNo,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_depNo) }],

            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_depNo),
              disabled: isDisable[NAME.action_depNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_depNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_subDepNo,
              label: LABEL.action_subDepNo,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(LABEL.action_subDepNo) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_subDepNo),
              disabled: isDisable[NAME.action_subDepNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_subDepNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_approveId,
              label: LABEL.action_approveId,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_approveId) }],

            },
            componentProps: {
              style: {
                width: "100%",
              },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_approveId),
              disabled: isDisable[NAME.action_approveId] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_approveId],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: NAME.action_positionNo,
              label: LABEL.action_positionNo,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_positionNo) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(LABEL.action_positionNo),
              disabled: isDisable[NAME.action_positionNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.action_positionNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: NAME.action_Date,
              label: LABEL.action_Date,
              rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: {
                width: "100%",
              },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[NAME.action_Date] || false,
            },
            fnChange: handleChange,
          },
        },
      ], // column
    }, // Row
  ];
