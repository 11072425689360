import { path } from "ramda";
import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()

/** -----  STR SELECT DASHBOARD ----- **/

export async function APIDashboardTraining(payload, nextAction) {
    try {
        const response = await API.selectDashboardTraining(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIDashboardTrainers(payload, nextAction) {
    try {
        const response = await API.selectDashboardTrainers(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIDashboardIndividual(payload, nextAction) {
    try {
        const response = await API.selectDashboardIndividual(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


/** -----  END SELECT DASHBOARD ----- **/

/** ----- STR SELECT OPTION ----- **/

export async function APISelectOptionUserTraining(payload, nextAction) {
    try {
        const response = await API.selectOptionUserTraining(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionTraining(payload, nextAction) {
    try {
        const response = await API.selectOptionTraining(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionTrainers(payload, nextAction) {
    try {
        const response = await API.selectOptionTrainers(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectTrainingCourse(payload, nextAction) {
    try {
        const response = await API.selectTrainingCourse(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectTrainingUser(payload, nextAction) {
    try {
        const response = await API.selectTrainingUser(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APISelectOptionSupervisors(payload, nextAction) {
    try {
        const response = await API.selectOptionSupervisors(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionDep(payload, nextAction) {
    try {
        const response = await API.selectOptionDep(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionMentor(payload, nextAction) {
    try {
        const response = await API.selectOptionMentor(payload);
        return responseAPI(response, nextAction, true)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionSubDep(payload, nextAction) {
    try {
        const response = await API.selectOptionSubDep(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APISelectFilterDep(payload, nextAction) {
    try {
        const response = await API.selectFilterDep(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APISelectFilterSubDep(payload, nextAction) {
    try {
        const response = await API.selectFilterSubDep(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APISelectOptionFlow(payload, nextAction) {
    try {
        const response = await API.selectOptionFlow(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionTrainingCourse(payload, nextAction) {
    try {
        const response = await API.selectOptionTrainingCourse(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


/** ----- END SELECT OPTION ----- **/
