import React, { useState } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { OJT, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { SelectAntd } from "../select/SelectAntd";
import { OpenNotificationWithIcon } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { useButton } from "../buttons/CustomButtonAntd";
import { useMakeQueryString } from "../../functions/UseMakeQueryString";
import { useOptionSelect } from "../../constants/OptionSelect";

export const FormSearchTraining = () => {
  const [form] = Form.useForm();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({});

  const [dataTable, setDataTable] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);

  let queryTraining = useMakeQueryString(changeSearchValue);
  let optionTrainings = useOptionSelect("training");
  // [END] States

  // [START] Handle Form
  const onFinish = (values) => {
    GET_Search_Trainings();
  };

  const onReset = () => {
    form.resetFields();
    setChangeSearchValue({});
    setDataTable([]);
  };
  // [END] Handle Form

  // [START] REST API
  const GET_Search_Trainings = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Training_ALL}?${queryTraining}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const RESULT = data.result;
        if (RESULT.length !== 0) {
          setDataTable(RESULT.map((val) => ({ ...val, key: val.trainingNo })));
        } else {
          setDataTable([]);
          OpenNotificationWithIcon(
            "error",
            "ไม่พบข้อมูลจากการค้นหา",
            `กรุณาลองใหม่อีกครั้ง`
          );
        }
      }
    } catch (err) {
      setDataTable([]);
      console.log("Error Search Training: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    setDataTable([]);
    setChangeSearchValue((prev) => ({
      ...prev,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { ColumnOJT_Training } = useColumnTable({
    Config: {
      LinkEdit: `ManageTraining/update-OJT-Training/`,
    },
  });
  // [END] Control Column

  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        <Card title="จัดการหลักสูตรหลัก" style={{ marginBottom: 30 }}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <SelectAntd
                  name={`trainingNo`}
                  label={`หลักสูตรหลัก`}
                  smgReq={`กรุณาเลือกหลักสูตร Training`}
                  placeholder={`เลือก หลักสูตร Training`}
                  dropdown={optionTrainings}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                <Form.Item>{useButton({ call: "search" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {dataTable.length !== 0 && (
          <CustomTableAtnd
            HeadTable={ColumnOJT_Training}
            DataTable={dataTable}
            SetScroll={{ x: `80%` }}
          />
        )}
      </Spin>
    </>
  );
};

export default FormSearchTraining;
