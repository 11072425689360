import moment from "moment";

export const compareSortText = (a, b, key) => {
  const keyA = a[key] || "";
  const keyB = b[key] || "";
  return keyA.localeCompare(keyB);
};

export const compareSortNumber = (a, b, key) => {
  const strA = `${a[key]}`;
  const strB = `${b[key]}`;
  const keyA = parseFloat(strA.replaceAll(",", "")) || 0;
  const keyB = parseFloat(strB.replaceAll(",", "")) || 0;
  return keyA - keyB;
};

export const compareSortDate = (a, b, key) => {
  const keyA = a[key] || "";
  const keyB = b[key] || "";
  return moment(keyA, "DD-MM-YYYY").unix() - moment(keyB, "DD-MM-YYYY").unix();
};
