import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import { getCurrent } from "../extendFunction/getCurrentYears";
import { loadingPage } from "../components/loadingPage";
import { ColumnRecheckGrade } from "../modulePMS/constantsPMS/columns";
import {
  RenderDepartmentPMS,
  RenderDepartment_SuperAdmin,
  RenderStatusRecheckGrade,
  RenderYearPMS_5Years,
} from "../modulePMS/componentsPMS/dropdown";
import {
  GET,
  POST,
  POST_UPDATE_GRADE,
  SELECT_DASHBOARD_RECHECK_GRADE,
} from "../service";
import { Modal } from "antd";

const obj = SetStringToObject(Cookies.get("cookie_session"));

class DashboardRecheckGrade extends React.Component {
  constructor(props) {
    super(props);
    const { yearsSelect } = getCurrent();
    const { userLogin, premission } = props;
    this.state = {
      loadData: [],
      SegmentTxt: "",
      yearsTxt: yearsSelect,
      years: yearsSelect,
      statusCode: "",
      dataArrCF: [],
      done: false,
      userLogin,
      premission,
    };
  }

  onLoadFinish = () => {
    this.setState({ done: true });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlGetUpdateData = async () => {
    try {
      this.setState({ done: false });
      const { userLogin } = this.state;
      const _data = this.node.props.data; // data row
      const _indexSelect = this.node.selectionContext.selected; // key row
      if (_indexSelect.length !== 0) {
        const object = {
          data: _data,
          indexSelect: _indexSelect,
          userLogin: userLogin,
        };

        const response = await POST(POST_UPDATE_GRADE, object);
        const { result } = response;
        if (result.status_api === 1) {
          Modal.success({
            title: "INSERT SUCCESSFULLY !!",
            onOk: () => window.location.reload(),
          });
        } else {
          Modal.error({ title: "INSERT ERROR !!" });
        }
      } else {
        Modal.warning({
          title: "กรุณาเลือกข้อมูลที่ต้องการอัพเดทอย่างน้อย 1 รายการ !!",
        });
      }
    } catch (err) {
      console.log("Error POST_UPDATE_GRADE: ", err);
    } finally {
      this.setState({ done: true });
    }
  };

  search = async () => {
    try {
      if (!this.state.done) {
        return;
      }
      const { SegmentTxt, yearsTxt, statusCode, userLogin } = this.state;
      const { premission } = obj;
      this.setState({ loadData: [] });
      if (yearsTxt !== "0") {
        this.setState({ done: false });
        const queryObject = {
          SegmentTxt,
          yearsTxt,
          userLogin,
          statusCode,
          premission,
        };
        // console.log('queryObject', queryObject)
        const response = await GET(SELECT_DASHBOARD_RECHECK_GRADE(queryObject));
        const { data, dataCF } = response.result;
        //console.log("response: ", response);
        let dataArrCF = dataCF || [];
        let dataArr = [];
        if (response.length !== 0) {
          dataArr = data;
        }
        this.setState({
          loadData: dataArr,
          dataArrCF,
        });
      } else {
        Modal.warning({ title: "กรุณาเลือกปีประเมินให้ครบถ้วน !!" });
      }
    } catch (err) {
      console.log("Error Search Dashboard RecheckGrade: ", err);
    } finally {
      this.setState({ done: true });
    }
  };

  render() {
    const { userLogin, premission } = this.state;
    return (
      <Container>
        <div>
          <Card>
            <CardHeader>Dashboard Recheck Grade</CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md={3}>
                    <RenderYearPMS_5Years onChange={this.onChange} />
                  </Col>
                  <Col md={3}>
                    {this._renderSelectDepart({
                      userLogin,
                      premission,
                      onChange: this.onChange,
                      onLoadFinish: this.onLoadFinish,
                    })}
                  </Col>
                  <Col md={3}>
                    <RenderStatusRecheckGrade onChange={this.onChange} />
                  </Col>
                  <Col md={3} className="text-center cs_btn">
                    <FormGroup>
                      <Button color="info" onClick={this.search}>
                        SEARCH
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <hr />
          <br />
          {this.state.done ? (
            <>
              {this._renderButtonExportCSV()}
              {this._renderTable()}
              <Row>
                <Col md={12}>
                  <div className="float-right">
                    <Button color="success" onClick={this.handlGetUpdateData}>
                      Update Data
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <div>{loadingPage} </div>
          )}
        </div>
      </Container>
    );
  } // render

  _renderTable = () => {
    const { loadData } = this.state;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      nonSelectable: this.state.dataArrCF,
      clickToEdit: true,
    };

    const cellEditProps = {
      mode: "click",
      blurToSave: true,
      // nonEditableRows: this.returnArr  //เรียกฟังก์ชั่นแบบยาวหน่อย
      nonEditableRows: () => this.state.dataArrCF, // เรียกเป็น Arrow FN เพื่อเอาค่าจาก State
    };

    return (
      <BootstrapTable
        keyField="docNo" // RowID
        data={loadData}
        columns={ColumnRecheckGrade()}
        ref={(n) => (this.node = n)}
        cellEdit={cellEditFactory(cellEditProps)}
        striped
        hover
        condensed
        selectRow={selectRow}
        //pagination={paginationFactory(opt)}
        wrapperClasses="table-responsive"
      />
    );
  };

  _renderButtonExportCSV = () => {
    const { loadData } = this.state;
    const headerCSV = [
      { label: "ปี", key: "years" },
      { label: "รหัสพนักงาน", key: "idUser" },
      { label: "ชื่อ - สกุล", key: "staffName" },
      { label: "รหัสแผนก", key: "depNo" },
      { label: "แผนก", key: "depName" },
      { label: "คะแนนรวม", key: "totalScore" },
      { label: "ผลการประเมิน", key: "gradeDes" },
      { label: "PG", key: "pg" },
      { label: "JG", key: "jg" },
    ];
    return (
      <div className="btn-right">
        <CSVLink
          headers={headerCSV}
          data={loadData}
          filename={"คะแนนผลการประเมินประจำปี.csv"}
          className="btn btn-export"
        >
          <MdFileDownload /> Export CSV
        </CSVLink>
      </div>
    );
  };

  _renderSelectDepart = ({
    userLogin,
    premission,
    onChange = () => null,
    onLoadFinish = () => null,
  }) => {
    if (premission === "HRS_OFF") {
      return (
        <RenderDepartment_SuperAdmin
          onChange={onChange}
          onLoadFinish={onLoadFinish}
        />
      );
    } else {
      return (
        <RenderDepartmentPMS
          userLogin={userLogin}
          onChange={onChange}
          onLoadFinish={onLoadFinish}
        />
      );
    }
  };
}
export default DashboardRecheckGrade;
