import React, { useRef } from "react";
import { FIELD_BTN, TEXT_BTN } from "./buttonOT";
import renderButtonWithField from "../../componentsOT/buttonWithField";
import moment from "moment";
import { CONNECT_API, CONFIG } from "../../../components/connectAPI";
import { equals, path } from "antd";
import { ButtonTheme } from "../../../moduleKPIs/common/buttons";
import { colors } from "../../../themes";
import "moment/locale/th"; // without this line it didn't work
import { FIELD_BUTTON, TEXT_BUTTON } from "../../../constants/buttons";
import { columnSearchProps } from "../../../components/structuresReport/function";
import { isMobile } from "../../../common/DeviceCheck";
import { MdDelete } from "react-icons/md";
moment.locale("th");
/**
 *
 * @param {*} rest :  openModal,showModal,closeModal,onSubmit,onChangeBtn
 */

export const TABLE_DASHBOARD_OT = ({
  //  onChange,
  permission,
  userLogin,
  actionButton,
  actionStatus,
}) => {
  const searchInput = useRef();
  // console.log({ userLogin })

  return [
    {
      title: "รายละเอียดคำขอโอที",
      children: [
        {
          title: "สถานะ",
          dataIndex: "statusDes",
          align: "left",
          render: (text, record) => {
            return record.waitApprovalName ? (
              <div>
                <div>{text}</div>
                <div>{`(${record.waitApprovalName || ""})`}</div>
              </div>
            ) : (
              <div>{text}</div>
            );
          },
        },
        {
          title: "เลขที่เอกสาร",
          dataIndex: "docNo",
          align: "center",
          // render: (text) => text || '-'
          ...columnSearchProps("docNo", searchInput),
        },
        {
          title: "ผู้อนุมัติอันดับที่ 1 ",
          dataIndex: "approvalName1",
          align: "center",
          // render: (text) => text || '-'
          ...columnSearchProps("approvalName1", searchInput),
        },
        {
          title: "แผนกย่อย",
          dataIndex: "subDepName",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "วันที่มีผล",
          dataIndex: "activeDate",
          align: "center",
          render: (text) => {
            return text
              ? `${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(0, 4)}`
              : "-";
          },
        },
        {
          title: "เริ่มวันที่/เวลา",
          dataIndex: "startDateTime",
          align: "center",
          render: (text) => {
            return text ? (
              <div>
                <div>{`${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(
                  0,
                  4
                )}`}</div>
                <div>{`เวลา ${text.split("T")[1].substr(0, 5)} น.`}</div>
              </div>
            ) : (
              "-"
            );
          },
          // (text) ? `${text.split('T')[0]} เวลา  ${text.split('T')[1].substr(0, 5)} น.` : '-'
        },
        {
          title: "จบวันที่/เวลา",
          dataIndex: "endDateTime",
          align: "center",
          render: (text) => {
            return text ? (
              <div>
                <div>{`${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(
                  0,
                  4
                )}`}</div>
                <div>{`เวลา ${text.split("T")[1].substr(0, 5)} น.`}</div>
              </div>
            ) : (
              "-"
            );
          },
          // render: (text) => (text) ? `${text.split('T')[0]} เวลา ${text.split('T')[1].substr(0, 5)} น.` : '-'
        },
        {
          title: "เวลาต่อคน",
          dataIndex: "totalHours",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "จำนวนคน",
          dataIndex: "totalEmp",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "รวมเวลาทั้งหมด",
          dataIndex: "totalTime",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "",
          dataIndex: "statusCode",
          align: "center",
          render: (text, record) => {
            // console.log({ userLogin, record })
            return renderButtonWithField({
              active: userLogin.userId === record.createBy,
              fieldBtn: actionStatus(text, record).fieldBtn,
              actionButton: (fieldBtn) =>
                actionButton({
                  fieldBtn: fieldBtn,
                  payload: record,
                  pathname: actionStatus(text, record).pathname,
                }),
            });
          },
        },
      ],
    },
  ];
};

export const TABLE_EXPORT_OT = ({
  permission,
  onChange,
  actionButton,
  optionModal,
}) => {
  switch (permission) {
    default:
      return [
        {
          title: "รายละเอียด",
          children: [
            {
              title: "รหัสพนักงาน",
              dataIndex: "userId",
              align: "left",
              render: (text) => text || "-",
            },
            {
              title: "ชื่อพนักงาน",
              dataIndex: "name",
              align: "left",
              render: (text) => text || "-",
            },
            {
              title: "รหัสกะการทำงาน",
              dataIndex: "workShift",
              align: "center",
              render: (text) => text || "-",
            },
            {
              title: "วันที่มีผล",
              dataIndex: "activeDate",
              align: "center",
              render: (text) => text || "-",
              // render: (text) => {
              //     return (text ? `${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(0, 4)}` : '-')
              // }
            },
            {
              title: "วันที่ขาเข้า",
              dataIndex: "startDate",
              align: "center",
              render: (text) => text || "-",
              // render: (text) => {
              //     return (text ? `${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(0, 4)}` : '-')
              // }
            },
            {
              title: "โอทีตั้งแต่",
              dataIndex: "startTime",
              align: "center",
              render: (text) => text || "-",
              // render: (text) => {
              //     return (text ? `${text.split('T')[1].substr(0, 5)}` : '-')
              // }
            },
            {
              title: "วันที่ขาออก",
              dataIndex: "endDate",
              align: "center",
              render: (text) => text || "-",
              // render: (text) => {
              //     return (text ? `${text.substr(8, 2)}/${text.substr(5, 2)}/${text.substr(0, 4)}` : '-')
              // }
            },
            {
              title: "โอทีถึง",
              dataIndex: "endTime",
              align: "center",
              render: (text) => text || "-",
              // render: (text) => {
              //     return (text ? `${text.split('T')[1].substr(0, 5)}` : '-')
              // }
            },
            {
              title: "หมายเหตุ",
              dataIndex: "remark",
              align: "center",
              render: (text) => text || "-",
            },
          ],
        },
      ];
  }
};

export const TABLE_REGISTER_OT = ({
  permission,
  employeeLists,
  userLogin,
  onChange,
  update,
  actionButton,
  optionModal,
  selectedRowKeys,
}) => {
  const searchInput = useRef();
  return [
    {
      title: "ข้อมูลพนักงาน",
      children: [
        {
          title: "รหัสพนักงาน",
          dataIndex: "userId",
          // width: 100,
          align: "left",
          ...columnSearchProps("userId", searchInput),
          // render: (text) => text || '-'
        },
        {
          title: "ชื่อพนักงาน",
          dataIndex: "name",
          // width: 100,
          align: "left",
          ...columnSearchProps("name", searchInput),
          // render: (text) => text || '-'
        },
        {
          title: "ชื่อเล่น",
          dataIndex: "nickname",
          // width: 100,
          align: "center",
          // render: (text) => text || '-'
          ...columnSearchProps("nickname", searchInput),
        },
        {
          title: "ตำแหน่ง",
          dataIndex: "positionName",
          // width: 200,
          align: "left",
          render: (text) => text || "-",
        },
        // {
        //   title: "ลายเซ็นต์",
        //   dataIndex: "signature",
        //   // width: 100,
        //   fixed: "right",
        //   align: "center",
        //   render: (text, record, index) => {
        //     // let activeSigPen = false
        //     let disIndex =
        //       Array.isArray(selectedRowKeys) &&
        //       selectedRowKeys.findIndex((val) => val === record.userId);
        //     // console.log(disIndex, selectedRowKeys, record.userId)
        //     let base64 = `${text}`.includes("base64");
        //     // console.log(record)
        //     return (
        //       <div
        //         style={{
        //           display: "flex",
        //           width: "100%",
        //           alignItems: "center",
        //           justifyContent: "flex-end",
        //         }}
        //       >
        //         {text ? (
        //           <div style={{ display: "block", width: "100%" }}>
        //             {/* {                                           (!base64) ? */}
        //             {!base64 ? (
        //               <img
        //                 src={`${CONFIG.API_IMAGE}${text}`}
        //                 width={100}
        //                 height={40}
        //               />
        //             ) : (
        //               <>
        //                 <div style={{ position: "relative" }}>
        //                   <img src={`${text}`} width={100} height={40} />
        //                   <div
        //                     style={{
        //                       position: "absolute",
        //                       top: 0,
        //                       zIndex: 1,
        //                       right: 0,
        //                     }}
        //                     onClick={() =>
        //                       actionButton({
        //                         fieldBtn: FIELD_BUTTON.RESET_SIGNATURE,
        //                         payload: { text, record, index },
        //                       })
        //                     }
        //                   >
        //                     {!base64 && (
        //                       <MdDelete
        //                         style={{ color: colors.error, fontSize: 20 }}
        //                       />
        //                     )}
        //                   </div>
        //                 </div>
        //               </>
        //             )}
        //           </div>
        //         ) : (
        //           !update && (
        //             <ButtonTheme
        //               disabled={disIndex !== -1 ? false : true}
        //               type="primary"
        //               ghost
        //               style={{ padding: "0px 10px", display: "block" }}
        //               buttonText={TEXT_BUTTON.INSERT_SIGNATURE}
        //               onClick={() =>
        //                 actionButton({
        //                   fieldBtn: FIELD_BUTTON.INSERT_SIGNATURE,
        //                   payload: { text, record, index },
        //                 })
        //               }
        //             />
        //           )
        //         )}
        //         {update && (
        //           <MdDelete
        //             style={{ color: colors.error, fontSize: 20 }}
        //             onClick={() =>
        //               actionButton({
        //                 fieldBtn: FIELD_BTN.REMOVE_EMP_REQUEST_OT,
        //                 payload: record,
        //               })
        //             }
        //           />
        //         )}
        //       </div>
        //     );
        //   },
        // },
      ],
    },
  ];
};

export const TABLE_REPORT_PRINT_OT = ({ permission }) => {
  switch (permission) {
    default:
      return [
        {
          title: "ลำดับที่",
          dataIndex: "linenum",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "รหัสพนักงาน",
          dataIndex: "userId",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "ชื่อพนักงาน",
          dataIndex: "name",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "ตำแหน่ง",
          dataIndex: "positionName",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "หมายเหตุ",
          dataIndex: "remark",
          align: "center",
          render: (text) => text || "-",
        },
        {
          title: "ลายเซ็นต์",
          dataIndex: "signature",
          align: "center",
          render: (text) =>
            text ? (
              <img width={50} height={10} src={`${CONFIG.API_IMAGE}${text}`} />
            ) : (
              "-"
            ),
        },
      ];
  }
};

export const COLUMNS_EXPORT_DASHBOARD_OT = ({ permission }) => {
  switch (permission) {
    default:
      return [
        {
          title: "สถานะ",
          field: "statusName",
        },
        {
          title: "เลขที่เอกสาร",
          field: "documentNo",
        },
        {
          title: "แผนกย่อย",
          field: "subDepartmentName",
        },
        {
          title: "เริ่มวันที่/เวลา",
          field: "startActive",
        },
        {
          title: "จบวันที่/เวลา",
          field: "endActive",
        },
        {
          title: "รวมเวลา",
          field: "totalTime",
        },
      ];
  }
};

export const COLUMNS_EXPORT_OT = ({ permission }) => {
  switch (permission) {
    default:
      return [
        {
          title: "รหัสพนักงาน",
          field: "userId",
        },
        {
          title: "ชื่อพนักงาน",
          field: "name",
        },
        {
          title: "รหัสกะการทำงาน",
          field: "workShift",
        },
        {
          title: "วันที่มีผล",
          field: "startDate",
        },
        {
          title: "วันที่ขาเข้า",
          field: "startDate",
        },
        {
          title: "โอทีตั้งแต่",
          field: "startTime",
        },
        {
          title: "วันที่ขาออก",
          field: "endDate",
        },
        {
          title: "โอทีถึง",
          field: "endTime",
        },
        {
          title: "หมายเหตุ",
          field: "remark",
        },
      ];
  }
};
