//TODO : ตรวจสอบ Array ที่มีค่าว่าง หรือ undefined ต้องตัดออก
export function modifyArrayEmpty(arr, initial) {
    if(!arr) arr = [];
    for (let i = 0; i < arr.length;) {
        if (typeof arr[i] !== 'undefined') {
            if (Object.keys(arr[i]).length !== Object.keys(initial).length) {
                arr[i] = { ...initial, ...arr[i] }
            }
            i++;
            continue;
        }
        arr.splice(i, 1);
    }
    return arr
}


//TODO : กำหนด Default ของ Array
export const modifyArrayDefault = (array, condition, field) => {
    return array.reduce((acc, cur, iA) => {
        let isEmpty = cur[field].includes('')
        if (!isEmpty) {
            return [...acc]
        } else {
            // for (let i = 0; i < condition.length; i++) {
            //     const newCur = condition[iA];
            //     return ([...acc, {
            //         ...cur,
            //         [field]: newCur || cur[field][i] || ''
            //     }])
            // }
            for(const key in condition) {
                const newCur = condition[iA];
                return ([...acc, { ...cur, [field]: newCur || cur[field][key] || ''}])
            }
        }
    }, [])
}
