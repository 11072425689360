import React, { useContext, useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';

import { REPORT_OPTION, MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { LayoutTable } from '../../moduleKPIs/common/layouts'

import { COLUMNS_EXPORT_DASHBOARD_ADMIN, TABLE_SUMMARY_REPLY_BY_FORM } from '../commonsEvac/structuresEvac/tableEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider';
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider';
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider';
import { ButtonTheme, ExportExcel } from '../../moduleKPIs/common/buttons';
import { TEXT_BUTTON } from '../../constants/buttons';
import { TEXT_LOADING } from '../../constants/enums/enumCenter';

const SummaryReplyEvacByForm = ({ reportBy }) => {
    const refExport = useRef()
    const { userLogin } = useContext(ManageEvacContext);
    const { masterEvacState } = useContext(MasterEvacContext);
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext);
    const { summaryReplyByForm } = reportEvacState;

    const [loading, setLoading] = useState(false);


    async function fetchAPI() {
        try {
            setLoading(true)
            await getReportEvac({ fieldname: REPORT_OPTION.SUMMARY_REPLY_EVAC_BY_ADMIN })

        } catch (error) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAPI()
    }, [])

    return (
        <div>
            <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    type="primary"
                    style={{ padding: 10 }}
                    buttonText={TEXT_BUTTON.SEARCH}
                    onClick={() => fetchAPI()}
                />
            </div>
            <Spin tip={TEXT_LOADING} spinning={loading}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div >
                        <ExportExcel
                            ref={refExport}
                            columns={COLUMNS_EXPORT_DASHBOARD_ADMIN({
                                permission: null,
                                searchData: {
                                    year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                                    quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`]
                                }
                            })}
                            dataSource={summaryReplyByForm}
                        />
                    </div>
                </div>
                <LayoutTable
                    scroll={{ x: 600 }}
                    bordered
                    style={{ padding: 10 }}
                    rowKey="formId"
                    dataSource={summaryReplyByForm}
                    columns={TABLE_SUMMARY_REPLY_BY_FORM({
                        userLogin: userLogin,
                        searchData: {
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`]
                        }
                    })}
                    pagination={{
                        pageSize: 100,
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`

                    }}
                />
            </Spin>
        </div>
    )
}

export default SummaryReplyEvacByForm