import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiOutlineUser } from "react-icons/ai";
import {
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Row,
  Button
} from "reactstrap";
import {
  GET_BEARER,
  GET_DETAIL_TASK_USER_IN,
} from "../service";
import { Modal, Space } from "antd";
import { QRCodeSVG } from "qrcode.react";
import { printDiv } from "../moduleKPIs/common/functions";
import { PATHNAME } from "../constants/enums/pathname";
import { CONNECT_API, USE_CONNECTION_MODE } from "../components/connectAPI";
import { browserHistory } from "react-router";


const columns = [
  {
    dataField: "id",
    headerAlign: "center",
    align: "center",
    text: "ลำดับ",
  },

  {
    dataField: "type",
    headerAlign: "center",
    text: "ประเภทสินทรัพย์",
  },
  {
    dataField: "description",
    headerAlign: "center",
    text: "รายการสินทรัพย์",
  },
  {
    dataField: "note",
    headerAlign: "center",
    text: "หมายเหตุ",
  },
];

class Assets_NewEmpPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: "",
      resultView: [],
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let UserID = params.get("UserID"); // is the string "Jonathan"
    this.setState({ UserID: UserID }, () => {
      this.getDataUser();
    });
  }

  async getDataUser() {
    const userId = this.state.UserID;
    try {
      const res = await GET_BEARER(GET_DETAIL_TASK_USER_IN(userId));
      const { result } = res;
      let mapResult = [];
      this.setState({
        FullName: result.data.fullname,
        SupName: result.data.bossName,
        DepartmentName: result.data.depName,
        StartDateJob: result.data.startDateJob,
        Location: result.data.location,
        Level_Worker: result.data.levelWorker,
        LevelID: result.data.levelId,
        DivisionID: result.data.divisionId,
        QrCode: result.data.qrCode,
      });
      mapResult = result.data.line.map((item, key) => ({
        id: key + 1,
        type: item.type,
        description: item.description,
        note: item.note,
      }));
      this.setState({
        resultView: mapResult,
      });
    } catch (error) {
      Modal.error({ title: "เกิดข้อผิดพลาด", content: error.message });
    }
  }
  
  render() {
    const { resultView } = this.state;
    const originUrl = CONNECT_API[USE_CONNECTION_MODE].URL
    const removePortUrl = originUrl.replace(/:\d+/, '');
    const linkQrCode = `${removePortUrl}`+PATHNAME.ASSETS_CONFIRM_LOGIN;
    return (
      <div>
        <div id={'formPrint'}>
        <Card>
          <CardHeader>
            <AiOutlineUser /> ข้อมูลพนักงาน
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={8}>
                  <Label sm={6}>รหัส/ชื่อพนักงาน: <b>{this.state.FullName}</b></Label>
                  <Label sm={6}>รหัส/ชื่อหัวหน้า: <b>{this.state.SupName}</b></Label>
                  <Label sm={6}>วันที่เริ่มทำงาน: <b>{this.state.StartDateJob}</b></Label>
                  <Label sm={6}>ฝ่าย: <b>{this.state.Location}</b></Label>
                  <Label sm={6}>ระดับ: <b>{this.state.Level_Worker}</b></Label>
                  <Label sm={6}>แผนก: <b>{this.state.DepartmentName}</b></Label>
              </Col>
              <Col sm={4} align="center">
                <QRCodeSVG
                            value={linkQrCode}
                            style={{ width: "100px", height: "100px" }}
                />
                <br></br>
                <br></br>
                <Label>สแกนเพื่อยืนยันรับสินทรัพย์</Label>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <BootstrapTable keyField="id" data={resultView} columns={columns} />
        </div>
        <center>
          <Space>
          <Button
            color="info"
            className="text-center"
            onClick={() =>
              printDiv('formPrint')
            }
          >
            พิมพ์เอกสาร
          </Button>
          <Button
            color="secondary"
            className="text-center"
            onClick={() =>
              browserHistory.push(`/Assets_NewEmpMain`)
            }
          >
            กลับหน้าหลัก
          </Button>
          </Space>
        </center>

        
      </div>
    );
  }
}

export default Assets_NewEmpPrint;
