import React from "react"
import { useText } from "../../conponents/typography/Typography";
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";
import { Input } from "antd";

export const ColumnImportIncentiveStrategy = [
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "center",
		width: 100,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "name",
		key: "name",
		align: "center",
		width: 100,
		...filterSearchColumn("name", "ชื่อพนักงาน"),
	},
	{
		title: "รหัสเซลล์",
		dataIndex: "saleCode",
		key: "saleCode",
		align: "center",
		width: 100,
		...filterSearchColumn("saleCode", "รหัสเซลล์"),
	},
	{
		title: "Incentive กลยุทธ์",
		dataIndex: "incentiveStrategy",
		key: "incentiveStrategy",
		align: "right",
		width: 100,
		...filterSearchColumn("incentiveStrategy", "Incentive กลยุทธ์"),
	},
	{
		title: "Commission Focus Shop",
		dataIndex: "commissionFocusShop",
		key: "commissionFocusShop",
		align: "right",
		width: 200,
		...filterSearchColumn("commissionFocusShop", "Commission Focus Shop"),
	},
];

export const EXPORT_TEMPLATE_PAGES = () => {
	return [{
		id: 1,
		// title: 'เลขบัตรประชาชน',
		title: 'userId',
		field: 'userId'
	},
	{
		id: 2,
		// title: 'เลขบัตรประชาชน',
		title: 'saleCode',
		field: 'saleCode'
	},
	{
		id: 3,
		// title: 'คำนำหน้าชื่อ (ไทย)',
		title: 'incentiveStrategy',
		field: 'incentiveStrategy'
	}, {
		id: 4,
		// title: 'คำนำหน้าชื่อ (อังกฤษ)',
		title: 'incentiveFocusShop',
		field: 'incentiveFocusShop'
	}
	]
}