import React from "react";
import { Tabs } from "antd";
import FormCreateDocCrime from "./conponents/forms/FormCreateDocCrime";
import FormSearchReportCrime from "./conponents/forms/FormSearchReportCrime";

const ManageCrime = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="สร้างเอกสารส่งอาชญากรรม" key="1">
          <FormCreateDocCrime />
        </TabPane>
        <TabPane tab="ส่งเอกสารเพิ่มเติม" key="2">
          <FormSearchReportCrime />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManageCrime;
