import React, { useEffect, useState } from "react";
import { Form, Card, Spin, Tabs } from "antd";
import { USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useNotification } from "../notification/NotificationWithIcon";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { subStringDate, sumColumnWidth, useReplace } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import moment from "moment";
import { Header_ReportShowDetailCrime } from "../../constants/HeaderExcel";
import { useExportExcel } from "../../services/ExportExcel";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentShowDetailCrime } from "../../constants/ManageCrime/Components/ComponentSearchReportCrime";
import { POST, POST_SELECT_DATA_CRIME_LINE } from "../../../service";
import { ColumnShowDetailCrime } from "../../constants/ManageCrime/Columns/ColumnSearchReportCrime";

export const FormShowDetailCrime = (props) => {
  const [form] = Form.useForm();
  const { docNo } = props.params;
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(() => {
    selectDataCrimeLine();
  }, []);

  // [START] REST API
  const selectDataCrimeLine = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const response = await POST(POST_SELECT_DATA_CRIME_LINE, { docNo });
      const { result } = response;
      let mapData = [];
      if (result.length !== 0) {
        mapData = result.map((row) => ({
          key: `${row.userId}`,
          ...row,
          prepareDate: subStringDate(row.prepareDate),
          birthday: subStringDate(row.birthday),
          sendDate: subStringDate(row.sendDate),
          receivedDate: subStringDate(row.receivedDate),
          filePath: useReplace(row.receivedDate, "/public", USE_CONNECT_MODE_AUTO),
        }));
        const { sendDate, receivedDate, externalDoc } = mapData[0];
        form.setFieldsValue({
          docNo,
          externalDoc,
          sendDate: moment(sendDate),
          receivedDate: moment(receivedDate),
        });
      } else {
        useNotification({ type: "info", desc: "ไม่พบข้อมูล" });
      }
      setDataTable(mapData);
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Crime Line: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const sendDataExcel = (arr = []) => {
    return arr.map((row, index) => ({ numRow: index + 1, ...row }));
  };
  // [END] Functions

  return (
    <>
      <Form form={form} {...configForm} >
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
          <Card
            title="ดูรายละเอียดผลตรวจอาชญากรรม"
            style={{ marginBottom: 30 }}
          >
            {dataTable.length !== 0 && (
              <>
                {useRenderFormItem({ constantComponent: ComponentShowDetailCrime({}), })}
                <Tabs
                  defaultActiveKey="1"
                  tabBarExtraContent={{
                    right: useExportExcel({
                      header: Header_ReportShowDetailCrime,
                      dataset: sendDataExcel(dataTable),
                      filename: "รายงานผลตรวจอาชญากรรม",
                    }),
                  }}
                >
                  <Tabs.TabPane tab="ผลตรวจอาชญากรรม" key="1">
                    <CustomTableAtnd
                      HeadTable={ColumnShowDetailCrime}
                      DataTable={dataTable}
                      moreProps={{
                        scroll: {
                          x: sumColumnWidth(ColumnShowDetailCrime) || 1080,
                          y: 500,
                        },
                      }}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </>
            )}
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default FormShowDetailCrime;
