import React, { useContext } from "react";
import { TYPE } from "../../../moduleKPIs/common/inputs";
import { MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions";

export const SEARCH_DASHBOARD_REGISTER = ({ permission, options }) => {
    switch (permission) {
        default:
            return [
                // {
                //     span:12,
                //     type: TYPE.RANG_PICKER_SEARCH,
                //     header: 'วันที่อบรม',
                //     required: false,
                //     message: null,
                //     inputOption: {
                //         fieldname: 'trainingDate',
                //         fieldOption: MASTER_OPTION.RANG_DATE,
                //         plainOptions: [],//options[MASTER_OPTION.OPTION_YEAR],
                //         defaultValue: options[`SEARCH_${MASTER_OPTION.RANG_DATE}`],
                //         disabled: false,
                //         placeholder: null
                //     }
                // },
                {
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'รหัสพนักงาน (วิทยากร)',
                    required: true,
                    message: null,
                    inputOption: {
                        fieldname: 'userId',
                        fieldOption: MASTER_OPTION.OPTION_USER_TRAINING,
                        plainOptions: options[MASTER_OPTION.OPTION_USER_TRAINING],
                        defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`],
                        disabled: false,
                        placeholder: null
                    }
                },
                {
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'ชื่อหลักสูตร',
                    required: true,
                    message: null,
                    inputOption: {
                        fieldname: 'trainingNo',
                        fieldOption: MASTER_OPTION.OPTION_TRAINING_COURSE,
                        plainOptions: options[MASTER_OPTION.OPTION_TRAINING_COURSE],
                        defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`],
                        disabled: false,
                        placeholder: null
                    }
                }
            ]

    }
}
export const SEARCH_DASHBOARD_TRAINING = ({ permission, options }) => {
    switch (permission) {
        default:
            return [
                //     {
                //     span: 4,
                //     type: TYPE.INPUT_SEARCH,
                //     header: 'รหัสพนักงาน',
                //     required: false,
                //     message: null,
                //     inputOption: {
                //         fieldname: 'employeeId',
                //         fieldOption: MASTER_OPTION.OPTION_USER_OJT,
                //         plainOptions: options[MASTER_OPTION.OPTION_USER_OJT],
                //         defaultValue: options[`SEARCH_${MASTER_OPTION}`],
                //         disabled: false,
                //         placeholder: null
                //     }
                // }, 
                {
                    span: 8,
                    type: TYPE.SELECT,
                    header: 'แผนกย่อย',
                    required: false,
                    message: null,
                    placeholder: 'ไม่พบข้อมูล',
                    inputOption: {
                        fieldname: 'employeeId',
                        fieldOption: MASTER_OPTION.OPTION_SUBDEP,
                        plainOptions: options[MASTER_OPTION.OPTION_SUBDEP],
                        defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`],
                        disabled: false,

                    }
                },
                {
                    span: 8,
                    type: TYPE.RANG_PICKER_SEARCH,
                    header: 'วันที่เริ่มงาน',
                    required: false,
                    message: null,
                    inputOption: {
                        fieldname: 'date',
                        fieldOption: MASTER_OPTION.RANG_DATE,
                        plainOptions: [],//options[MASTER_OPTION.OPTION_YEAR],
                        defaultValue: options[`SEARCH_${MASTER_OPTION.RANG_DATE}`],
                        disabled: false,
                        placeholder: null
                    }
                }, {
                    span: 8,
                    type: TYPE.SELECT,
                    header: 'สถานะ',
                    required: false,
                    message: null,
                    inputOption: {
                        fieldname: 'status',
                        fieldOption: MASTER_OPTION.OPTION_TRAINING_STATUS,
                        plainOptions: options[MASTER_OPTION.OPTION_TRAINING_STATUS],
                        defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_STATUS}`],
                        disabled: false,
                        placeholder: null
                    }
                }]

    }
}
export const SEARCH_DASHBOARD_INDIVIDUAL = ({ permission, options }) => {
    switch (permission) {
        default:
            return [{
                span: 8,
                type: TYPE.SELECT,
                header: 'รหัสพนักงาน',
                required: false,
                message: null,
                inputOption: {
                    fieldname: 'userId',
                    fieldOption: MASTER_OPTION.OPTION_USER_TRAINING,
                    plainOptions: options[MASTER_OPTION.OPTION_USER_TRAINING],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`],
                    disabled: false,
                    placeholder: null
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'กลุ่มหลักสูตร',
                required: false,
                message: null,
                inputOption: {
                    fieldname: 'trainingGroup',
                    fieldOption: MASTER_OPTION.OPTION_TRAINING_GROUP,
                    plainOptions: options[MASTER_OPTION.OPTION_TRAINING_GROUP],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_GROUP}`],
                    disabled: false,
                    placeholder: null
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'ชื่อหลักสูตร',
                required: false,
                message: null,
                inputOption: {
                    fieldname: 'trainingNo',
                    fieldOption: MASTER_OPTION.OPTION_TRAINING_COURSE,
                    plainOptions: options[MASTER_OPTION.OPTION_TRAINING_COURSE],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`],
                    disabled: false,
                    placeholder: null
                }
            }]

    }
}
