import React, { useContext, useState, useEffect, useRef } from 'react'
import { Row, Col, Card, Statistic, Button, Input, Spin, Empty, Typography } from 'antd'
import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { ButtonTheme, ExportExcel } from '../../moduleKPIs/common/buttons'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { path, equals } from 'ramda'
import { colors } from '../../themes'
import { CloseCircleOutlined, CheckCircleOutlined, SearchOutlined, ClearOutlined, ProfileOutlined } from '@ant-design/icons'
import { COLUMNS_EXPORT_DASHBOARD_EVAC, TABLE_DASHBOARD_FOLLOW_EVAC } from '../commonsEvac/structuresEvac/tableEvac'
import { MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import { TEXT_BTN } from '../commonsEvac/structuresEvac/buttonEvac'

const DashboardFollowEvac = () => {
    const refExport = useRef()
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext)
    const { clearMaster } = useContext(MasterEvacContext)
    const { evacData: { followEvac, view } } = reportEvacState;
    const [loading, setLoading] = useState(false)
    // console.log({ masterEvacState })
    async function fetchAPI() {
        await setLoading(true)
        await clearMaster({ fieldOption: MASTER_OPTION.OPTION_DEP_EVAC })
        await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC_VIEW })
        await setLoading(false)
    }

    useEffect(() => {
        fetchAPI()
    }, [])
    
    return (
        <div>
            <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    type="primary"
                    style={{ padding: 10 }}
                    buttonText={TEXT_BTN.SEARCH_DASHBOARD_EVAC}
                    onClick={async () => {
                        await setLoading(true)
                        await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC_VIEW })
                        await setLoading(false)
                    }}
                />
            </div>
            <Spin spinning={loading} tip="กำลังโหลด">
                {(Array.isArray(view) && view.length > 0) ?
                    <div style={{ padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: 20, fontWeight: 600, paddingLeft: 10, alignItems: 'center', display: 'flex' }} >
                                <ProfileOutlined />
                                <span style={{ padding: '0px 0px 3px 10px' }}>{'รายชื่อผู้ที่ยังไม่ตอบแบบสอบถาม'} </span>
                            </div>
                            <ExportExcel
                                ref={refExport}
                                columns={COLUMNS_EXPORT_DASHBOARD_EVAC({ permission: null })}
                                dataSource={view}
                            />
                        </div>
                        {Array.isArray(followEvac) && followEvac.map((each, index) => {
                            return (
                                <LayoutTable
                                    style={{ padding: 10 }}
                                    scroll={{ x: 600 }}
                                    bordered
                                    rowKey={index}
                                    columns={TABLE_DASHBOARD_FOLLOW_EVAC({
                                        permission: null,
                                        department: each.depName,
                                        percentDone: each.percentDone,
                                    })}
                                    pagination={{
                                        pageSize: 50,
                                        showTotal: total => `ทั้งหมด ${total || 0} รายการ`
                                    }}
                                    dataSource={each.dataLine}
                                />
                            )
                        })}
                    </div>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Spin>
        </div>
    )
}

export default DashboardFollowEvac