import { path } from "ramda";

export function responseAPI(response, nextAction, dataOnly) {
    const { data, status } = response
    if (status === 200) {
        if (typeof nextAction === 'function') {
            return nextAction((dataOnly) ? data : path(['result'], data))
        } else {
            return (dataOnly) ? data : path(['result'], data)
        }
    } else {
        // let secondsToGo = 5;
        // const modal = Modal.error({
        //     title: `${status || ''} ( ${path(['message'], data) || problem} )`,
        //     content: `${CONFIG.API_URL}${config.url || ''} (${secondsToGo} second.)`,
        // });
        // const timer = setInterval(() => {
        //     secondsToGo -= 1;
        //     modal.update({
        //         content: `${CONFIG.API_URL}${config.url || ''} (${secondsToGo} second.)`
        //     });
        // }, 1000);
        // setTimeout(() => {
        //     clearInterval(timer);
        //     modal.destroy();
        // }, secondsToGo * 1000);
        return { error: path(['message'], data) }
    }
}
