import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { loadingPage } from "../components/loadingPage";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { FiXCircle, FiEdit, FiSend, FiChevronRight } from "react-icons/fi";
import paginationFactory from "react-bootstrap-table2-paginator";
import encodeString from "../extendFunction/encodeString";
import { getCurrent } from "../extendFunction/getCurrentYears";
import { GET, SELECT_DASHBOARD_CF_DISCUSSION } from "../service/";
import { Tabs, Modal,Table } from "antd";
import { tab_CF_Discussion } from "../modulePMS/constantsPMS";
import { ColumnConfirmDiscussion } from "../modulePMS/constantsPMS/columns";
import {
  RenderDepartmentPMS,
  RenderStatusEvaluateCF_Discussion,
  RenderTypeEvaluatePMS,
  RenderYearPMS,
} from "../modulePMS/componentsPMS/dropdown";

const { TabPane } = Tabs;
class DashboardCF_DIS extends React.Component {
  constructor(props) {
    super(props);
    const { yearsSelect, typeSelect } = getCurrent();
    const { userLogin, premission } = props;
    this.state = {
      typeTXt: typeSelect,
      SegmentTxt: "all",
      yearsTxt: yearsSelect,
      years: yearsSelect,
      statusCode: "all",
      done: false,
      loadData: [],
      loadDataView: [],
      userLogin,
      premission,
    };
  }

  onLoadFinish = () => {
    this.setState({ done: true });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  printDIS = (e) => {
    let docNo = encodeString.encode(e.target.id);
    window.open("/PrintDiscussionForm/" + docNo);
  };

  openCFDIS = (e) => {
    let docNo = encodeString.encode(e.target.id);
    window.open("/CF_DiscussionForm/" + docNo);
  };

  search = async () => {
    try {
      if (!this.state.done) {
        return;
      }
      const { typeTXt, SegmentTxt, yearsTxt, statusCode, userLogin } =
        this.state;
      this.setState({ loadData: [], loadDataView: [] });
      if (yearsTxt !== "0" && typeTXt !== 0) {
        this.setState({ done: false });
        const queryObject = {
          typeTXt,
          SegmentTxt,
          yearsTxt,
          userLogin,
          statusCode,
        };
        const response = await GET(SELECT_DASHBOARD_CF_DISCUSSION(queryObject));
        const { data, dataView } = response.result;
        this.setState({
          loadData: data !== 0 ? data : [],
          loadDataView: dataView !== 0 ? dataView : [],
          done: true,
        });
      } else {
        Modal.warning({ title: "กรุณาเลือกปีและประเภทการประเมินให้ครบถ้วน!!" });
      }
    } catch (err) {
      console.log("Error Search Confrim Discussion: ", err);
    } finally {
      this.setState({ done: true });
    }
  }; // end func Search

  render() {
    const { userLogin } = this.state;
    return (
      <Container>
        <div>
          <Card>
            <CardHeader>Dashboard Confirm Discussion Form</CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md={2}>
                    <RenderYearPMS onChange={this.onChange} />
                  </Col>
                  <Col md={3}>
                    <RenderTypeEvaluatePMS onChange={this.onChange} />
                  </Col>
                  <Col md={3}>
                    <RenderDepartmentPMS
                      userLogin={userLogin}
                      onChange={this.onChange}
                      onLoadFinish={this.onLoadFinish}
                    />
                  </Col>
                  <Col md={2}>
                    <RenderStatusEvaluateCF_Discussion
                      onChange={this.onChange}
                    />
                  </Col>

                  <Col md={2} className="text-center cs_btn">
                    <FormGroup>
                      <Button color="info" onClick={this.search}>
                        SEARCH
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>กระบวนในการรับทราบผลประเมิน</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      &nbsp; &nbsp;
                      <FiXCircle
                        className="margin-icon"
                        color="#FF5733"
                        size={20}
                      />
                      อยู่ระหว่างดำเนินการ
                      <FiChevronRight className="margin-icon" size={20} />
                      <FiEdit
                        className="margin-icon"
                        color="#95A5A6"
                        size={20}
                      />
                      รอยืนยันรับทราบผลประเมิน
                      <FiChevronRight className="margin-icon" size={20} />
                      <FiSend
                        className="margin-icon"
                        color="#28B463"
                        size={20}
                      />
                      ยืนยันรับทราบผลประเมินแล้ว
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    **หมายเหตุ -
                    สามารถคลิกที่รหัสพนักงานเพื่อเรียกดูรูปพนักงานได้
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {this.state.done ? this._renderTable() : <div>{loadingPage} </div>}
        </div>
      </Container>
    );
  }

  _renderTable = () => {
    const { userLogin, premission } = this.state;
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group">
        {options.map((option, i) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={`${option.text}${i}`}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );

    const opt = {
      sizePerPageRenderer,
    };

    return (
      <Tabs defaultActiveKey="1">
        {tab_CF_Discussion.map((e, i) => {
          return (
            <TabPane tab={`${e.tab_label}`} key={`${i + 1}`}>
              <Table
                  key={`${e.key_field}${i + 1}`}
                  columns={ColumnConfirmDiscussion({
                    userLogin,
                    permission: premission,
                    printDIS: this.printDIS,
                    openCFDIS: this.openCFDIS,
                  })}
                  dataSource={this.state[e.tab_ref]}
              />
{/* 
              <ToolkitProvider
                keyField={`${e.key_field}${i + 1}`}
                data={this.state[e.tab_ref]}
                columns={ColumnConfirmDiscussion({
                  userLogin,
                  permission: premission,
                  printDIS: this.printDIS,
                  openCFDIS: this.openCFDIS,
                })}
                exportCSV={{
                  noAutoBOM: false,
                }}
              >
                {(props) => (
                  <BootstrapTable
                    {...props.baseProps}
                    ref={(n) => (this.node = n)}
                    striped
                    hover
                    condensed
                    tabIndexCell
                    pagination={paginationFactory(opt)}
                    wrapperClasses="table-responsive"
                  />
                )}
              </ToolkitProvider> */}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };
}
export default DashboardCF_DIS;
