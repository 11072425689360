import { toCamelCase } from "../../constants/functions";

//TODO: แปลง Field Former ให้เป็น Present
export function modifyToFormer(_field = "", _nField = "former") {
  let diffField = `${_nField}`;
  let nField = `${_field}`.substring(7);
  return toCamelCase(`${diffField}${nField}`);
}

//TODO: แปลง Fullname
export function modifyToFullname(
  _firstname = "",
  _lastname = "",
  _nickname = "",
  _title = ""
) {
  return (
    `${_title || ""}${_firstname || ""}   ${_lastname || ""} ${
      _nickname ? `(${_nickname})` : ""
    }` || ""
  );
}

//TODO: แปลง optionId to value
export function modifyOptionIdToValue(
  _options = [],
  _value = "",
  _type = "select"
) {
  // console.log(_options, _value)
  if (_options && Array.isArray(_options)) {
    let newValue = _options.filter(
      (e) => `${e.optionValue}`.trim() === `${_value}`.trim()
    );

    switch (_type) {
      case "content":
        return newValue.length > 0 && newValue[0]["optionValue"] != ""
          ? newValue[0]["optionLabel"]
          : "-";
      default:
        // console.log(newValue)
        return newValue.length > 0 ? newValue[0]["optionLabel"] : "";
    }
  }
}
