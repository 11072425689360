import React from "react";
import { Button, notification, Space } from "antd";

export const OpenNotificationWithIcon = (
  type = "info",
  titie = "Notification Title",
  desc = "content of the notification"
) => {
  return notification[type]({
    message: titie,
    description: desc,
  });
};

export const useNotification = ({ type, title, desc, moreProps }) => {
  return notification[type || "info"]({
    message: title,
    description: desc,
    ...(moreProps || {}),
  });
};
