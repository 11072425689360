import React, { useState, useContext, useEffect } from 'react'
import { LayoutWrapper, LayoutBreadcrumb, LayoutSearch, LayoutTabs } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_DASHBOARD_EVAC_BY_ADMIN } from '../commonsEvac/structuresEvac/breadcrumEvac'
import { Row, Col } from 'antd'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import { SEARCH_DASHBOARD_EVAC_BY_ADMIN } from '../commonsEvac/structuresEvac/searchEvac'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider'
import { TABS_DASHBOARD_EVAC_BY_ADMIN } from '../commonsEvac/structuresEvac/tabsEvac'

const PageDashboadEvacByAdmin = () => {
    const { masterEvacState, onChangeMaster } = useContext(MasterEvacContext);
    const { userLogin } = useContext(ManageEvacContext);

    const [activeTab, setActiveTab] = useState('1')
    let SEARCH_LIST = SEARCH_DASHBOARD_EVAC_BY_ADMIN({ permission: null, options: masterEvacState })
    let TABS_LIST = TABS_DASHBOARD_EVAC_BY_ADMIN({ userLogin: userLogin, options: masterEvacState })

    useEffect(() => {

    }, [activeTab])

    return (
        <LayoutWrapper
            breadcrumb={<LayoutBreadcrumb breadcrumbLists={BREADCRUMB_DASHBOARD_EVAC_BY_ADMIN()} />}
            search={<Row gutter={[24, 10]}>
                {SEARCH_LIST.map((each, eachIndex) => (
                    <Col key={eachIndex} span={each.span} >
                        <LayoutSearch
                            type={each.type}
                            header={each.header}
                            onChange={onChangeMaster}
                            {...each.inputOption}
                        />
                    </Col>
                ))}
            </Row>}
            content={
                <div className="layout-content-card" >
                    <LayoutTabs
                        defaultkey={activeTab}
                        tabsLists={TABS_LIST}
                        onChange={(_k) => setActiveTab(_k)}
                    />
                </div>
            }
        />
    )
}

export default PageDashboadEvacByAdmin