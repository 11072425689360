import React from "react";
import { tableColumn } from "../../../components/global/tables/tableColumn";
import { ButtonTheme } from "../../../components/global/buttons";
import { Space, Tag, Typography } from "antd";
import { CONNECT_API, USE_CONNECTION_MODE } from "../../../components/connectAPI";
const { Paragraph } = Typography;

export const FormColumn = ({ showModal, getLinkDownload }) => {
  const arrMainFile = ['HOUSE','IDCARD','GUARANTEE'] 
  return [
    tableColumn({
      type: "CUSTOM_FLAT",
      title: "สถานะแนบไฟล์",
      // key: "fileStatusDesc",
      width: 150,
      option: {
        render: (val) => {
          const { fileStatusDesc, statusFileGuarantee, fileList } = val;
          const color = (fileStatusDesc != "รอยืนยัน") ? "success" : "warning";
          const isFileExists = fileList.some(e => e.fileType == 'GUARANTEE');
          return (
            <Space direction="vertical">
              <Tag color={color}> {fileStatusDesc} </Tag>
              { statusFileGuarantee && !isFileExists &&
                  <p style={{color: "red"}}>ไม่พบเอกสารค้ำประกัน</p>
              }            
            </Space>
          )
        },
      },
    }),
    tableColumn({
      type: "TEXT",
      title: "รหัสพนักงาน",
      key: "employeeNo",
      width: 150,
    }),
    tableColumn({
      type: "TEXT",
      title: "ชื่อ-สกุล",
      key: "employeeFullName",
      width: 200,
    }),
    tableColumn({
      type: "TEXT",
      title: "ชื่อเล่น",
      key: "employeeNickName",
      width: 150,
    }),
    tableColumn({
      type: "TEXT",
      title: " แผนก",
      key: "departmentName",
      width: 200,
    }),
    tableColumn({
      type: "TEXT",
      title: "วันที่เริ่มงาน",
      key: "startDateJob",
      width: 150,
    }),
    tableColumn({
      type: "TEXT",
      title: "ประเภทพนักงาน",
      key: "employeeType",
      width: 100,
    }),
    tableColumn({
      type: "TEXT",
      title: "สรรหา",
      key: "recruiter",
      width: 200,
    }),
    tableColumn({
      type: "TEXT",
      title: "เอกสารค้ำประกัน",
      key: "statusFileGuaranteeText",
      width: 150,
    }),
    tableColumn({
      type: "CUSTOM_FLAT",
      title: "จัดการไฟล์",
      //key: "fileList",
      width: 250,
      isAction: true,
      option: {
        fixed: "right",
        render: (val) => {
          const { fileList, employeeNo } = val
          return (
            <>
              <Paragraph>
                {fileList.filter(img => arrMainFile.includes(img.fileType)).map((e,i) => (
                  <>
                  <a href={`${CONNECT_API[USE_CONNECTION_MODE].URL}${e.filePath}`} target="_blank">{e.fileName} </a> 
                  {arrMainFile.length === (i + 1) ?  '' : ' | '}
                  </>
                ))}
              </Paragraph>

              <Space>
              <ButtonTheme
                useFor="VIEW"
                title = {`ไฟล์แนบ (${fileList.length})`}
                disabled={fileList.length == 0 ? true : false}
                onClick={() => showModal(fileList)}
              />
              <ButtonTheme
                useFor="DOWNLOAD"
                title = "ดาวน์โหลด"
                disabled={fileList.length == 0 ? true : false}
                onClick={() => { getLinkDownload({employeeNo}) }}
              />                
              </Space>
            </>
          );
        },
      },
    }),
  ];
};
