import React, { useContext, useEffect, useState } from 'react'
import { Spin } from 'antd';
import { LayoutBreadcrumb, LayoutTabs, LayoutWrapper, WrapperSearch } from '../../moduleKPIs/common/layouts';
import { SEARCH_CONTRACT } from '../commonsContract/structuresContract/searchContract';
import { BREADCRUMB_CONTRACT } from '../commonsContract/structuresContract/breadcrumContract';
import { TABS_CONTRACT } from '../commonsContract/structuresContract/tabsContract';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { ContractContext } from '../servicesContract/contractProvider';

const PageDashboardTrackContract = () => {
    const { userLogin, userPermission } = useContext(ContractContext);
    const { masterCenterState, onChangeMaster, getMaster } = useContext(MasterCenterContext);

    const [loading, setLoading] = useState(false);

    const _pathname = window.location.pathname;

    useEffect(() => {
        async function fetchAPI() {
            await setLoading(true)
            await Promise.all(
                [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_DEP, payload: { userId: userLogin, permission: userPermission } })],
                [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_USERS })],
                [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_LOCATION, payload: { ...masterCenterState } })],
                [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_STATUS_WORKERS, payload: { ...masterCenterState } })],
                [getMaster({ fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, payload: { ...masterCenterState } })],
                [getMaster({ fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_RESULT, payload: { ...masterCenterState } })]
            )
            await setLoading(false)
        }
        fetchAPI()
    }, [])

    return (
        <Spin spinning={loading} >
            <LayoutWrapper
                breadcrumb={< LayoutBreadcrumb breadcrumbLists={BREADCRUMB_CONTRACT(_pathname)} />}
                search={
                    <WrapperSearch
                        searchLists={SEARCH_CONTRACT({ pathname: _pathname, permission: userPermission, options: masterCenterState })}
                        onChange={onChangeMaster} />
                }
                content={
                    <div className="layout-content-card" style={{ padding: 10 }} >
                        <LayoutTabs tabsLists={TABS_CONTRACT({ pathname: _pathname, permission: userPermission })} />
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardTrackContract