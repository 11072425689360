import { PATHNAME } from "../../../constants/enums/pathname";

export function renderColumnsWithPathname(pathname) {
    switch (pathname) {
        case PATHNAME.DASHBOARD_PROBATION_CONTRACT:
            return [
                {
                    title: "สถานะ",
                    field: "statusMain"
                }, {
                    title: "รหัสพนักงาน",
                    field: "StaffCode"
                }, {
                    title: "ชื่อพนักงาน",
                    field: "StaffName"
                }, {
                    title: "ชื่อผู้บังคับบัญชา",
                    field: "BossName"
                }, {
                    title: "แผนก / กลุ่ม",
                    field: "DepNo"
                }, {
                    title: "วันที่เริ่มงาน",
                    field: "StartDateJob"
                },
                {
                    title: 'วันที่เริ่มรอบสัญญาจ้าง',
                    field: 'startProbationDate',
                }, {
                    title: 'ต่อสัญญาครั้งที่',
                    field: 'roundContract',
                }, {
                    title: "วันที่ผ่านทดลองงาน",
                    field: "expProDate"
                }, {
                    title: "รอบการประเมิน",
                    field: "RoundNow"
                }, {
                    title: "สถานะการประเมินล่าสุด",
                    field: "probationStatus_Des"
                }, {
                    title: "กองทุนสำรองเลี้ยงชีพ",
                    field: "providentFund"
                }, {
                    title: "ประกันสังคม",
                    field: "SSO"
                },
            ]
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT:
            return [
                {
                    title: "สถานะหลัก",
                    field: "statusMain"
                }, {
                    title: "สถานะการผ่านทดลองงาน",
                    field: "probationStatus_Des"
                }, {
                    title: "รหัสพนักงาน",
                    field: "StaffCode"
                }, {
                    title: "ชื่อพนักงาน",
                    field: "StaffName"
                }, {
                    title: "ชื่อผู้บังคับบัญชา",
                    field: "BossName"
                }, {
                    title: "แผนก / กลุ่ม",
                    field: "DepNo"
                },
                {
                    title: 'วันที่เริ่มรอบสัญญาจ้าง',
                    field: 'startProbationDate',
                }, {
                    title: 'ต่อสัญญาครั้งที่',
                    field: 'roundContract',
                },
                {
                    title: "วันที่ครบกำหนดทดลองงาน",
                    field: "expProDate"
                }, {
                    title: "วันที่ผ่านการทดลองงาน",
                    field: "probationEndDate"
                }, {
                    title: "วันที่ HR รับเอกสาร",
                    field: "HR_ReceivedDate"
                }, {
                    title: "วันที่ออกประกันสุขภาพ / ประกันอุบัติเหตุ",
                    field: "date_HEA"
                }, {
                    title: "วันที่ออกกองทุนสำรองเลี้ยงชีพ",
                    field: "date_PVD"
                }, {
                    title: "วันที่ออกประกันสังคม",
                    field: "date_SOS"
                },
            ]
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW:
            return [
                {
                    title: "สถานะหลัก",
                    field: "statusMain"
                }, {
                    title: "สถานะการผ่านทดลองงาน",
                    field: "probationStatus_Des"
                }, {
                    title: "รหัสพนักงาน",
                    field: "StaffCode"
                }, {
                    title: "ชื่อพนักงาน",
                    field: "StaffName"
                }, {
                    title: "ชื่อผู้บังคับบัญชา",
                    field: "BossName"
                }, {
                    title: "แผนก / กลุ่ม",
                    field: "DepNo"
                },
                {
                    title: 'วันที่เริ่มรอบสัญญาจ้าง',
                    field: 'startProbationDate',
                }, {
                    title: 'ต่อสัญญาครั้งที่',
                    field: 'roundContract',
                },
                {
                    title: "วันที่ครบกำหนดทดลองงาน",
                    field: "expProDate"
                }, {
                    title: "วันที่ผ่านการทดลองงาน",
                    field: "probationEndDate"
                }, {
                    title: "วันที่ HR รับเอกสาร",
                    field: "HR_ReceivedDate"
                }, {
                    title: "วันที่ออกประกันสุขภาพ / ประกันอุบัติเหตุ",
                    field: "date_HEA"
                }, {
                    title: "วันที่ออกกองทุนสำรองเลี้ยงชีพ",
                    field: "date_PVD"
                }, {
                    title: "วันที่ออกประกันสังคม",
                    field: "date_SOS"
                },
            ]
        case PATHNAME.DASHBOARD_BENEFIT_CONTRACT:
            return [
                {
                    title: "รหัสพนักงาน",
                    field: "StaffCode"
                }, {
                    title: "ชื่อพนักงาน",
                    field: "StaffName"
                }, {
                    title: "ชื่อผู้บังคับบัญชา",
                    field: "BossName"
                }, {
                    title: "แผนก / กลุ่ม",
                    field: "DepNo"
                }, {
                    title: "วันที่เริ่มงาน",
                    field: "StartDateJob"
                }, {
                    title: "วันที่คาดว่าผ่านทดลองงาน",
                    field: "expProDate"
                }, {
                    title: "วันที่ผ่านทดลองงาน",
                    field: "probationEndDate"
                },
                {
                    title: 'วันที่เริ่มรอบสัญญาจ้าง',
                    field: 'startProbationDate',
                }, {
                    title: 'ต่อสัญญาครั้งที่',
                    field: 'roundContract',
                },
                {
                    title: "ระยะเวลาในการต่อสัญญา",
                    field: "contractPeriod"
                }, {
                    title: "ประกันสุขภาพ / ประกันชีวิต",
                    field: "date_HEA"
                }, {
                    title: "ประกันอุบัติเหตุ",
                    field: "date_ACD"
                }, {
                    title: "กองทุนสำรองเลี้ยงชีพ",
                    field: "date_PVD"
                }, {
                    title: "ประกันสังคม",
                    field: "date_SOS"
                }
            ]
        case PATHNAME.DASHBOARD_TRACK_CONTRACT:
            return [
                {
                    title: "รหัสพนักงาน",
                    field: "StaffCode"
                }, {
                    title: "ชื่อพนักงาน",
                    field: "StaffName"
                }, {
                    title: "ชื่อผู้บังคับบัญชา",
                    field: "BossName"
                }, {
                    title: "แผนก / กลุ่ม",
                    field: "DepNo"
                }, {
                    title: "วันที่เริ่มงาน",
                    field: "StartDateJob"
                },
                {
                    title: 'วันที่เริ่มรอบสัญญาจ้าง',
                    field: 'startProbationDate',
                }, {
                    title: 'ต่อสัญญาครั้งที่',
                    field: 'roundContract',
                },
                {
                    title: "วันที่คาดว่าผ่านทดลองงาน",
                    field: "expProDate"
                }, {
                    title: "วันที่ผ่านทดลองงาน",
                    field: "probationEndDate"
                }, {
                    title: "ประกันสุขภาพ / ประกันชีวิต",
                    field: "date_HEA"
                }, {
                    title: "ประกันอุบัติเหตุ",
                    field: "date_ACD"
                }, {
                    title: "กองทุนสำรองเลี้ยงชีพ",
                    field: "date_PVD"
                }, {
                    title: "ประกันสังคม",
                    field: "date_SOS"
                }
            ]
        default:
            break;
    }
}

export const COLUMNS_EXPORT = ({ pathname, permission }) => {
    switch (permission) {
        default:
            return renderColumnsWithPathname(pathname)
    }
}