import React, { Component } from "react";
import { path } from "ramda";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Col, Row, Form } from "reactstrap";
import { SelectInput, DateInput, TextInput } from "../constants/inputs";
import { ButtonTheme } from "../constants/buttons/ButtonTheme";
import BenefitReportOut from "../components/benefits/BenefitReportOut";
import * as Layouts from "../constants/layouts";
import { INITIAL_FILTER_PROBATION } from "../components/probation/initialProbation";
import { APIProbation, APIBenefitOut, GET_SELECT_OPTION_ALL, GET } from "../service";
import { SetDateAPI } from "../common/setDate";
import { API_URL } from '../components/connectAPI'
import { filterDashboardReportBenefitOut } from "../moduleReport/constantsReport/searchform";
import { optionProbationStatus, optionLocation, optionStatusWorkerCode } from '../common/enum'
import { RenderForm } from './../constants/inputs/RenderForm';

class ReportBenefit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userLogin: this.props.userLogin,
			initialFilter: INITIAL_FILTER_PROBATION,
			dataSource: [],
			SegmentTxt: "",
			txtUserSeaching: '',
			locationID: "",
			statusProbationCode: '',
		};
	}

	componentDidMount() {
		// console.log('componentDidMount ::', this.props)
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				result['department'].map(el => {
					departmentLists.push({ label: el.depNameTH, value: el.depNo })
				})
				let optionSegment = [{ value: "", label: "----- ทั้งหมด -----" }, ...departmentLists]
				this.setState({ optionSegment })
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	//----- Start :: Function -----//

	handleFilter = (fieldname, value) => {
		let initialFilter = { ...this.state.initialFilter }
		let fieldNameSet = ''
		switch (fieldname) {
			case "depNo":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "SegmentTxt"
				break;

			case "location":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "locationID"
				break;

			case "userSeaching":
				// console.log(value)
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "txtUserSeaching"
				break;

			case "statusProbation":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "statusProbationCode"
				break;

			default:
				initialFilter = { ...initialFilter, [fieldname]: value };
				break;
		}
		this.setState({ initialFilter, [fieldNameSet]: value })
	};

	functionButton = async name => {
		const { initialFilter, locationID, statusProbationCode, txtUserSeaching, SegmentTxt } = this.state
		switch (name) {
			case "search":
				let pathURL = `${"user_login="}${this.state.userLogin}${"&locationID="}${locationID}${"&statusProbation="}${statusProbationCode}${"&txtUserSeaching="}${txtUserSeaching}${"&SegmentTxt="}${SegmentTxt}${"&statusMainCode=3"}${"&StartDateJob="}
        ${SetDateAPI(initialFilter.StartDateJob)}${"&toDateJob="}${SetDateAPI(initialFilter.toDateJob)}`;
				//console.log("pathURL", pathURL);
				return await APIBenefitOut(pathURL).then(res => {
					// console.log("APIBenefit ::", res);
					let dataSource = []
					if (path(["data"], res) !== 0) {
						dataSource = path(["data"], res);
					}
					this.setState({ dataSource })
				});
			default:
				return null;
		}
	};

	//----- End :: Function -----//


	handleLocation = async ({ pathname, state, status }) => {
		//console.log('[handleLocation] ::', pathname, state, this.props).
		this.setState({ done: undefined });
		const { router } = this.props;
		let initialData = null;
		// let pathURL = `${"DocNo="}${state.DocNo}`;
		let payload = { docNo: state.DocNo };
		APIProbation(payload).then(res => {
			initialData = res.result
			//  console.log("view", { initialData });
			router.push({ pathname, state: { ...initialData } });
			this.setState({ done: true });
		});

	};

	render() {

		return (
			<React.Fragment>
				<Layouts.MainTheme
					header={"รายการค้างสวัสดิการพนักงานพ้นสภาพ"}
					component={this.mapArray()}
				></Layouts.MainTheme>
				<BenefitReportOut
					userLogin={this.state.userLogin}
					dataSource={this.state.dataSource}
					fncSearch={this.functionButton}
					handleLocation={this.handleLocation}
				/>
			</React.Fragment>
		);
	}

	mapArray = () => {
		const { optionSegment, txtOption, txtUserSeaching, initialFilter } = this.state
		return (
			<Form>
				{filterDashboardReportBenefitOut({
					handlechange: this.handleFilter,
					options: {
						optionLocation,
						optionSegment,
						optionProbationStatus,
						optionStatusWorkerCode
					},
					txtOption: {
						txtUserSeaching,
						txtOption,
						// txtOptionStatusProbation,
					},
					initialFilter,
					onClick: this.functionButton
				}).map((row, i) => (
					<Row key={i}>
						{row.map((each, i) => (
							<Col key={i} md={each.size} className={each.className}>
								{RenderForm(each)}
							</Col>
						))}
					</Row>
				))}
			</Form>
		);
	};
}

export default ReportBenefit;
