import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";
import * as XLSX from "xlsx";

const DEFINE = {
  file: {
    name: "file",
  },
  year: {
    label: "ประจำปี (ย้อนหลัง 3 ปี)",
    name: "year",
  },
  month: {
    label: "เดือน",
    name: "month",
  },
};

export const ComponentInsertSaleTarget = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { year, month } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: year.name,
              label: year.label,
              rules: [
                {
                  required: true,
                  message: ERROR_MESSAGE.INPUT(year.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.INPUT(year.label),
              disabled: isDisable[year.name] || false,
            },
            fnChange: (value) => handleChange(year.name, value),
            dropdown: options[year.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: month.name,
              label: month.label,
              rules: [
                {
                  required: true,
                  message: ERROR_MESSAGE.INPUT(month.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.INPUT(month.label),
              disabled: isDisable[month.name] || false,
            },
            fnChange: (value) => handleChange(month.name, value),
            dropdown: options[month.name],
          },
        },
      ], // column
    }, // Row
  ];
};

export const ComponentImportSaleTarget = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
  ...moreProps
}) => {
  const { file } = DEFINE;
  return [
    {
      rowProps: { justify: "start", align: "bottom" },
      column: [
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 12 },
          component: {
            type: "upload",
            itemProps: {
              name: file.name,
              label: file.label,
            },

            componentProps: {
              style: { width: "100%" },
              disabled: isDisable[file.name] || false,
              fileList: moreProps.fileList,
            },
            fnChange: handleChange,
          },
        },
      ],
    },
  ];
};

export const ExcelToJSON = (
  f,
  callback = (e) => console.log("no call back")
) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const data = workbook.SheetNames.map((e) => {
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const workSheetData = XLSX.utils.sheet_to_json(worksheet);
        return { sheetName: e, data: workSheetData };
      });
      resolve(data);
    };
    reader.readAsBinaryString(f);
  });
};

export const MapDataExcel = (data) => {
  const allColumns = Object.getOwnPropertyNames(data[0]);
  const columns = ['__rowNum__', 'สาขา', 'Temp', 'Acc', 'จำนวนคนในสาขา']
  const dataArray = data.map(e => {
    const {  Acc: accTarget, Temp: tempTarget, จำนวนคนในสาขา: saleQTY, สาขา: roleId } = e;
    return {
      roleId: roleId,
      tempTarget: tempTarget,  
      accTarget: accTarget, 
      saleQTY: saleQTY
    }
  })
  return allColumns.every(e => columns.includes(e)) ? dataArray : false
}