import moment from 'moment';

const contractDays = 30; //สัญญาจ้ารายเดือน นับเป็น 30 วัน สูตร วันเริ่มงาน + 89 = 90 วัน
export const generalDetailAll = (
  setValue,
  getValues,
  options = {
    optionsCompanyTH: [],
    optionsPositionTH: [],
    optionsDepartmentTH: [],
    optionsSigner: [],
    optionsDocType: []
  },  
  ) => {
  return [
    //No.1
    {
      ref: "docType",
      name: "docType",
      key: "docType",
      label: "ประเภทเอกสาร",
      type: "DROPDOWN",
      span: 6,
      properties: {
        options: options.optionsDocType,
      },      
    },
    {
      ref: `contractDate`,
      name: `contractDate`,
      key: `contractDate`,
      label: "วันที่สร้างสัญญา",
      type: "DATE_PICKER",
      span: 6,
      rules: {
        required: "กรุณาระบุ วันที่สร้างสัญญา",
      },
    },
    {
      ref: "companyName",
      name: "companyName",
      key: "companyName",
      label: "บริษัท",
      type: "DROPDOWN",
      span: 12,
      properties: {
        options: options.optionsCompanyTH,
      },
    },
    {
      ref: "companyAddress",
      name: "companyAddress",
      key: "companyAddress",
      label: "ที่อยู่บริษัท",
      type: "INPUT",
      span: 24,
      placeholder: "",
      rules: {
        required: "กรุณาระบุ ที่อยู่บริษัท",
      },
    },
    {
      ref: "empFullName",
      name: "empFullName",
      key: "empFullName",
      label: "ชื่อลูกจ้าง",
      type: "INPUT",
      span: 12,
      placeholder: "",
      rules: {
        required: "กรุณาระบุ ชื่อลูกจ้าง",
      },
    },
    {
      ref: "empPersonalID",
      name: "empPersonalID",
      key: "empPersonalID",
      label: "เลขบัตรประชาชน",
      type: "INPUT_NUMBER",
      span: 12,
      placeholder: "",
      rules: {
          required: "กรุณาระบุ เลขบัตรประชาชน",
      },      
    },
    {
      ref: "departmentName",
      name: "departmentName",
      key: "departmentName",
      label: "แผนก",
      type: "DROPDOWN",
      span: 10,
      properties: {
        options: options.optionsDepartmentTH,
      },
    },
    {
      ref: "positionName",
      name: "positionName",
      key: "positionName",
      label: "ตำแหน่ง",
      type: "DROPDOWN",
      span: 10,
      properties: {
        options: options.optionsPositionTH,
      },
      rules: {
        required: "กรุณาระบุ ตำแหน่งงาน",
      },
    },
    {
      ref: "contractMonth",
      name: "contractMonth",
      key: "contractMonth",
      label: "จำนวนเดือนจ้าง",
      type: "INPUT_NUMBER",
      span: 4,
      itemHandleChange: (e, item) => {
        const contractMonth = e.target.value;
        const { startDateJob } = getValues();
        setValue(item.ref, contractMonth);
        const calDays = (contractDays * contractMonth) - 1
        const value = moment(startDateJob).add(calDays, 'days').format('YYYY-MM-DD');
        setValue('endDateJob', value);
      },
    },
    {
      ref: "empAddress",
      name: "empAddress",
      key: "empAddress",
      label: "ที่อยู่ลูกจ้าง",
      type: "INPUT",
      span: 24,
      placeholder: "",
      rules: {
        required: "กรุณาระบุ ที่อยู่ลูกจ้าง",
      },
    },
    {
      ref: `startDateJob`,
      name: `startDateJob`,
      key: `startDateJob`,
      placeholder: "",
      label: "วันที่เริ่มสัญญา",
      type: "DATE_PICKER",
      span: 8,
      rules: {
        required: "กรุณาระบุ วันที่เริ่มสัญญา",
      },
      itemHandleChange: (e, item) => {
        const startDateJob = e;
        const { contractMonth } = getValues();
        setValue(item.ref, startDateJob);
        const calDays = (contractDays * contractMonth) - 1
        const value = moment(startDateJob).add(calDays, 'days').format('YYYY-MM-DD');
        setValue('endDateJob', value);
      },
    },
    {
      ref: `endDateJob`,
      name: `endDateJob`,
      key: `endDateJob`,
      placeholder: "",
      label: "ถึงวันที่",
      type: "DATE_PICKER",
      span: 8,
      rules: {
        required: "กรุณาระบุ วันที่จบงาน",
      },
    },
    {
      ref: `probationDay`,
      name: `probationDay`,
      key: `probationDay`,
      label: "ระยะเวลาทดลองงาน (วัน)",
      placeholder: "",
      type: "INPUT_NUMBER",
      span: 4,
    },
    {
      ref: `salary`,
      name: `salary`,
      key: `salary`,
      label: "เงินเดือน (บาท)",
      placeholder: "",
      type: "INPUT_NUMBER",
      span: 4,
      rules: {
        required: "กรุณาระบุ เงินเดือน",
      },
      
    },
     //Approval
     {
      ref: `approverID`,
      name: `approverID`,
      key: `approverID`,
      label: "นายจ้าง / ผู้รับมอบอำนาจ",
      placeholder: "",
      type: "DROPDOWN",
      span: 12,
      properties: {
        options: options.optionsSigner,
      },
    },
    {
      ref: `approverPosition`,
      name: `approverPosition`,
      key: `approverPosition`,
      label: "ตำแหน่งผู้รับมอบอำนาจ",
      placeholder: "",
      type: "INPUT",
      span: 8,
    },
    {
      ref: `signAuto`,
      name: `signAuto`,
      key: `signAuto`,
      label: "แสดงลายเซ็น",
      type: "CHECK_BOX",
      span: 4,
    },
    {
      ref: `witness1`,
      name: `witness1`,
      key: `witness1`,
      label: "พยานลำดับที่ 1",
      placeholder: "",
      type: "INPUT",
      span: 12,
    },
    {
      ref: `witness2`,
      name: `witness2`,
      key: `witness2`,
      label: "พยานลำดับที่ 2",
      placeholder: "",
      type: "INPUT",
      span: 12,
    },
    {
      ref: "companyCode",
      name: "companyCode",
      key: "companyCode",
      type: "HIDDEN",
      span: 24,
      display: 'none'
    },
    {
      ref: "userId",
      name: "userId",
      key: "userId",
      type: "HIDDEN",
      span: 24,
      display: 'none'
    },
  ];
};
