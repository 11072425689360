import { responseAPI } from "../../common/functions/funcResponse";
import servicesAPI from "../servicesAPI";
import { path } from "ramda";
import { Modal, message } from "antd";

let API = servicesAPI.create()

export async function APIReportIndicatorHeader(payload, nextAction) {
    try {
        const response = await API.reportIndicatorHeader(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportIndicatorLine(payload, nextAction) {
    try {
        const response = await API.reportIndicatorLine(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportIndicatorLicense(payload, nextAction) {
    try {
        const response = await API.reportIndicatorLicense(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportIndicatorLineScore(payload, nextAction) {
    try {
        const response = await API.reportIndicatorLineScore(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportEvaluation(payload, nextAction) {
    try {
        // const response = await API.reportEvaluation(payload);
        const response = await API.reportEvaluationKPIs(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportEvaluationLine(payload, nextAction) {
    try {
        const response = await API.reportEvaluationLine(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportAll(payload, nextAction) {
    try {
        const response = await API.reportAll(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportIndicatorApproval(payload, nextAction) {
    try {
        const response = await API.reportIndicatorApproval(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIDashboardKPIs(payload, nextAction) {
    try {
        const response = await API.reportDashboardKPIs(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIReportFactory(payload, nextAction) {
    try {
        const response = await API.reportFactory(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertConfirmKPIsFac(payload, nextAction) {
    try {
        const response = await API.insertConfirmKPIsFac(payload);
        const { data, problem, status, message } = response


        if (status === 200) {
            if (typeof nextAction === 'function') {
                return nextAction(path(['result'], data))
            } else {
                return path(['result'], data)
            }
        } else if (status === 204) {
            Modal.error({
                title: `${status} (ไม่พอข้อมูล)`,
                content: 'กรุณาประเมินรายบุคคลให้เรียบร้อยก่อนกดยืนยัน',
                // onOk: () => window.location.reload()
            })
        } else {
            Modal.error({
                title: `${status} ( ${problem || message} )`,
                content: path(['message'], data),
                // onOk: () => window.location.reload()
            })
            return { error: path(['message'], data) }
        }
        // }



        // responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APIUpdateStatusKPIs(payload, nextAction) {
    try {
        const response = await API.updateStatusKPIsFac(payload);
        const { data, problem, status, message } = response
        if (status === 200) {
            if (typeof nextAction === 'function') {
                return nextAction(path(['success'], data))
            } else {
                return path(['success'], data)
            }
        } else {
            Modal.error({
                title: `${status} ( ${problem || message} )`,
                content: path(['message'], data),
                // onOk: () => window.location.reload()
            })
            return { error: path(['message'], data) }
        }
    } catch (error) {
        console.error('error : ', error)
    }
}