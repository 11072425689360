import React from "react"
import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";
import { USE_COLORS } from "../../Colors";

const setCellColor = {
    render: (text) => {
        let setColor = ""
        if (text < 50) {
            setColor = USE_COLORS.percentFail;
        } else if (text < 60) {
            setColor = USE_COLORS.percentWaring;
        }
        else if (text > 59) {
            setColor = USE_COLORS.percentPass;
        }
        return {
            props: {
                style: { background: setColor }
            },
            children: <div>{text} %</div>
        };
    }
}

const gradeRecruiter = [
    {
        title: "A",
        dataIndex: "gradeA",
        key: "gradeA",
        align: "right",
        width: 200,
        ...useSorterTable("gradeA", "number"),
    },
    {
        title: "B",
        dataIndex: "gradeB",
        key: "gradeB",
        align: "right",
        width: 200,
        ...useSorterTable("gradeB", "number"),
    },
    {
        title: "C",
        dataIndex: "gradeC",
        key: "gradeC",
        align: "right",
        width: 200,
        ...useSorterTable("gradeC", "number"),
    },
    {
        title: "D",
        dataIndex: "gradeD",
        key: "gradeD",
        align: "right",
        width: 150,
        ...useSorterTable("gradeD", "number"),
    },
    {
        title: "รวม",
        dataIndex: "grandTotal",
        key: "grandTotal",
        align: "right",
        width: 150,
        ...useSorterTable("grandTotal", "number"),
    },
    {
        title: "A-B (%)",
        dataIndex: "percentAB",
        key: "percentAB",
        align: "right",
        width: 150,
        ...useSorterTable("percentAB", "number"),
        //render: (text) => `${text} %`,
        ...setCellColor,
    },
    {
        title: "C (%)",
        dataIndex: "percentC",
        key: "percentC",
        align: "right",
        width: 150,
        ...useSorterTable("percentC", "number"),
        //render: (text) => `${text} %`
        ...setCellColor,
    },
    {
        title: "D (%)",
        dataIndex: "percentD",
        key: "percentD",
        align: "right",
        width: 150,
        ...useSorterTable("percentD", "number"),
        //render: (text) => `${text} %`
        ...setCellColor,
    },
]

export const ColumnReportRecruiterGradeStatus = [
    {
        title: "สถานะจ้างงาน",
        dataIndex: "currentStatus",
        key: "currentStatus",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("currentStatus", "สถานะจ้างงาน"),
    },
    ...gradeRecruiter,
];

export const ColumnReportRecruiterGradeDept = [
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        fixed: "left",
        width: 300,
        ...filterSearchColumn("depName", "แผนก"),
    },
    ...gradeRecruiter,
];

export const ColumnReportRecruiterGradeUser = [
    {
        title: "รหัสพนักงาน (ผู้สรรหา)",
        dataIndex: "recruiterId",
        key: "recruiterId",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("recruiterId", "รหัสพนักงาน (ผู้สรรหา)"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterNickname",
        key: "recruiterNickname",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("recruiterNickname", "ผู้สรรหา"),
    },
    ...gradeRecruiter,
];
