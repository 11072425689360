import React, { Fragment } from "react"
import { useText } from "../../conponents/typography/Typography";
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";
import { Space, Input, Tag, Button } from "antd";
import { useButton } from './../../conponents/buttons/CustomButtonAntd';
import { convertBaht } from "../../../service/functions/convertOptions";
import { renderStatusColorInstallment } from "../RecheckWorkingSaleEmployee/ColumnRecheckWorkingSaleEmployee";

const renderButton = (permissionUser, editRow, returnStatusRow, record, viewRow) => {
	if (permissionUser.length > 0) {
		let index = permissionUser.findIndex(val => (record.departmentCode).split(',')[0] == val.departmentNo)
		if (index != -1) {
			if (permissionUser[index].sequence == "1") {
				if (record.statusSave == '0') {
					return <Button type="primary" onClick={() => editRow(record)} style={{ background: "#F28C28	", borderColor: "#F28C28" }} >แก้ไข
					</Button>
				} else if (record.statusCutOff == '1') {
					return <>
						{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
					</>
				}
				return <>
					{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
					{useButton({ call: "search", moreProps: { label: 'ย้อนสถานะ', onClick: () => returnStatusRow(record) } })}
				</>
			} else {
				if (record.statusSave != '0') {
					if (record.statusSave == '1') {
						return <Button type="primary" onClick={() => editRow(record)} style={{ background: "#F28C28	", borderColor: "#F28C28" }} >แก้ไข
						</Button>
					} else if (record.statusCutOff == '1') {
						return <>
							{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
						</>
					}
					return <>
						{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
						{useButton({ call: "search", moreProps: { label: 'ย้อนสถานะ', onClick: () => returnStatusRow(record) } })}
					</>
				}
			}
			// statusSave = +(permissionUser[index].sequence)
		}
	}
	return useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } });

}

export const getNewValueSum = (bf, value, total) => {
	// getNewValueSum(dataSource[record.rowNo - 1].totalCommissionBeforeUpdate, dataSource[record.rowNo - 1].commission, dataSource[record.rowNo - 1].totalCommission, dataSourceOrigin[record.rowNo - 1].commission, dataSource[record.rowNo - 1].commissionFocusShop)

	/** comFocusIncStrg ==>  commission focusshop / incentiveStretegy ใช้ช่องเดียวกันเพราะมันเข้าคนละรอบ */
	// let totalBF = bf ? parseFloat(bf) : 0
	// let totalValue = value ? parseFloat(value) : 0
	// let grandTotal = total ? parseFloat(total) : 0
	// let totalComfocusShop = comfocusShop ? parseFloat(comfocusShop) : 0
	// let totalIncStrg = incStrg ? parseFloat(incStrg) : 0
	// if (type == 'com') {
	// 	if (dataSourceOrigin.commissionFocusShop && parseFloat(dataSourceOrigin.commissionFocusShop) && dataSourceOrigin.commissionFocusShop != totalComfocusShop) {
	// 		return (totalBF + totalValue + totalComfocusShop) != grandTotal && totalBF + totalValue + totalComfocusShop > 0 ? <Tag color="green">ค่าใหม่: {convertBaht((totalBF + totalValue + totalComfocusShop).toFixed(2))}</Tag> : ''
	// 	}
	// 	return (totalBF + totalValue) != grandTotal && totalBF + totalValue > 0 ? <Tag color="green">ค่าใหม่: {convertBaht((totalBF + totalValue).toFixed(2))}</Tag> : ''
	// }
	// console.log('parseFloat(dataSourceOrigin.incentiveStrategy) != totalIncStrg', parseFloat(dataSourceOrigin.incentiveStrategy), totalIncStrg)
	// if (dataSourceOrigin.incentiveStrategy && parseFloat(dataSourceOrigin.incentiveStrategy) && parseFloat(dataSourceOrigin.incentiveStrategy) != totalIncStrg) {
	// 	return (totalBF + totalValue + totalIncStrg) != grandTotal && totalBF + totalValue + totalIncStrg > 0 ? <Tag color="green">ค่าใหม่: {convertBaht((totalBF + totalValue + totalIncStrg).toFixed(2))}</Tag> : ''
	// }
	// return (totalBF + totalValue) != grandTotal && totalBF + totalValue > 0 ? <Tag color="green">ค่าใหม่: {convertBaht((totalBF + totalValue).toFixed(2))}</Tag> : ''

	let totalBF = bf ? parseFloat(bf) : 0
	let totalValue = value ? parseFloat(value) : 0
	let grandTotal = total ? parseFloat(total) : 0
	return (totalBF + totalValue) != grandTotal && totalBF + totalValue > 0 ? <Tag color="green">ค่าใหม่: {convertBaht((totalBF + totalValue).toFixed(2))}</Tag> : ''

}

export const getSumValuePay = (total, totalPay , bf) => {
	let totalValue = total ? parseFloat(total) : 0
	let totalPayValue = totalPay ? parseFloat(totalPay) : 0
	let bfValue = bf ? parseFloat(bf) : 0
	return ((totalValue + totalPayValue) && (bfValue !== totalValue + totalPayValue)) ? <Tag color="green">ค่าใหม่: {convertBaht((totalValue + totalPayValue).toFixed(2))}</Tag> : ''
}

// export const getTotalValuePay = (record) => {
// 	let totalValue = total ? parseFloat(total) : 0
// 	let totalPayValue = totalPay ? parseFloat(totalPay) : 0
// 	let bfValue = bf ? parseFloat(bf) : 0
// 	return ((totalValue + totalPayValue) && (bfValue !== totalValue + totalPayValue)) ? <Tag color="green">ค่าใหม่: {convertBaht((totalValue + totalPayValue).toFixed(2))}</Tag> : ''
// }

export const getNewValueSumText = (bf, value, total) => {
	
	let totalBF = bf ? parseFloat(bf) : 0
	let totalValue = value ? parseFloat(value) : 0
	let grandTotal = total ? parseFloat(total) : 0
	return (totalBF + totalValue) != grandTotal && totalBF + totalValue > 0 ? convertBaht((totalBF + totalValue).toFixed(2)) : ''

}

export const getTotalNet = (record) => {
	let totalCom = record.totalCommission ? parseFloat(record.totalCommission) : 0
	let totalInc = record.totalIncentive ? parseFloat(record.totalIncentive) : 0
	let totalComPay = record.totalCommissionPay ? parseFloat(record.totalCommissionPay) : 0
	let totalIncPay = record.totalIncentivePay ? parseFloat(record.totalIncentivePay) : 0

	return convertBaht((totalComPay + totalIncPay).toFixed(2))
}

export const ColumnRecheckCommissionIncentiveDashboard = ({ editRow, viewRow, returnStatusRow, permissionUser }) => [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "left",
		fixed: "left",
		width: 10,
		...filterSearchColumn("rowNo", "ลำดับ"),
	},
	{
		title: "เลขที่เอกสาร",
		dataIndex: "docNo",
		key: "docNo",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("docNo", "เลขที่เอกสาร"),
	},
	{
		title: "ช่วงวันที่งวดเงินเดือน",
		dataIndex: "installmentDatePeriod",
		key: "installmentDatePeriod",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("installmentDatePeriod", "ช่วงวันที่งวดเงินเดือน"),
		render: (text, record) => {
			return `${record.installmentStartDate} ถึง ${record.installmentEndDate}`
		}
	},
	{
		title: "วันที่สร้างเอกสาร",
		dataIndex: "createDate",
		key: "createDate",
		align: "left",
		fixed: "left",
		width: 100,
	},
	// {
	// 	title: "แผนก",
	// 	dataIndex: "departmentName",
	// 	key: "departmentName",
	// 	align: "left",
	// 	fixed: "left",
	// 	width: 100,
	// 	...filterSearchColumn("departmentName", "แผนก"),
	// },
	{
		title: "สถานะเอกสาร",
		dataIndex: "stausSaveDesc",
		key: "stausSaveDesc ",
		align: "center",
		width: 50,
		render: (text) => text === 'แบบร่าง' ? <Tag color="blue">{text}</Tag> : <Tag color="green">{text}</Tag>
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		align: "right",
		width: 50,
		render: (text, record) => {
			return (
				<div style={{ textAlignLast: 'center' }}>
					<Space>
						{renderButton(permissionUser, editRow, returnStatusRow, record, viewRow)}
					</Space>
				</div>
			)
		}
	},
];
export const ColumnRecheckCommissionIncentiveDashboardView = ({ viewRow }) => [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "left",
		fixed: "left",
		width: 10,
		...filterSearchColumn("rowNo", "ลำดับ"),
	},
	{
		title: "เลขที่เอกสาร",
		dataIndex: "docNo",
		key: "docNo",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("docNo", "เลขที่เอกสาร"),
	},
	{
		title: "ช่วงวันที่งวดเงินเดือน",
		dataIndex: "installmentDatePeriod",
		key: "installmentDatePeriod",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("installmentDatePeriod", "ช่วงวันที่งวดเงินเดือน"),
		render: (text, record) => {
			return `${record.installmentStartDate} ถึง ${record.installmentEndDate}`
		}
	},
	{
		title: "วันที่สร้างเอกสาร",
		dataIndex: "createDate",
		key: "createDate",
		align: "left",
		fixed: "left",
		width: 100,
	},
	{
		title: "สถานะเอกสาร",
		dataIndex: "stausSaveDesc",
		key: "stausSaveDesc ",
		align: "center",
		width: 50,
		render: (text) => text === 'แบบร่าง' ? <Tag color="blue">{text}</Tag> : <Tag color="green">{text}</Tag>
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		align: "right",
		width: 50,
		render: (text, record) => {
			return (
				<div style={{ textAlignLast: 'center' }}>
					<Space>
					{useButton({ call: "normal", moreProps: { label: 'ดูรายละเอียด', onClick: () => viewRow(record) } })}
					</Space>
				</div>
			)
		}
	},
];

export const ColumnRecheckCommissionIncentiveAction = ({ isView, dataSource, onChangeRow, dataSourceOrigin }) => [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "left",
		fixed: "left",
		width: 100,
	},
	{
		title: "รหัสเซลล์",
		dataIndex: "saleCode",
		key: "saleCode",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("saleCode", "รหัสเซลล์"),
		render: (text, record) => {
			return (
				<div>
					{text || <Tag color="red" >ไม่มีรหัสเซลล์</Tag>}
				</div>
			)
		}
	},
	{
		title: "รหัสพนักงาน",
		dataIndex: "employeeId",
		key: "employeeId",
		align: "left",
		fixed: "left",
		width: 100,
		...filterSearchColumn("employeeId", "รหัสพนักงาน"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		fixed: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "startDateJob",
		key: "startDateJob",
		align: "left",
		width: 150,
		...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
	},
	{
		title: "วันที่พ้นสภาพ",
		dataIndex: "EndDateJob",
		key: "EndDateJob",
		align: "left",
		width: 150,
		...filterSearchColumn("EndDateJob", "วันที่พ้นสภาพ"),
	},

	{
		title: "แผนก",
		dataIndex: "departmentName",
		key: "departmentName",
		align: "left",
		width: 100,
		...filterSearchColumn("departmentName", "แผนก"),
	},
	{
		title: "กลุ่มรายได้",
		dataIndex: "incomeGroup",
		key: "incomeGroup",
		align: "center",
		width: 150,
		...filterSearchColumn("incomeGroup", "กลุ่มรายได้"),

		render: (text, record) => {
			return (
				<div>
					{record.incomeGroupCode == 'N' ? <Tag color="red" >ไม่มีกลุ่มรายได้</Tag> : text}
				</div>
			)
		}
	},
	{
		title: "สถานะพนักงานประจำงวด",
		dataIndex: "statusInstallmentName",
		key: "statusInstallmentName",
		align: "right",
		width: 150,
		filters: [
			{
				text: 'ปัจจุบัน',
				value: 'ปัจจุบัน',
			},
			{
				text: 'เข้าใหม่',
				value: 'เข้าใหม่',
			},
			{
				text: 'ลาออก',
				value: 'ลาออก',
			},
		],
		onFilter: (value: string, record) => record.statusInstallmentName.includes(value),
		// ...filterSearchColumn("statusInstallmentName", "สถานะพนักงานประจำงวด"),
		render: (text, record) => {
			return <Tag color={renderStatusColorInstallment(record.statusWorkerInstallment)}>{text}</Tag>
		}
	},
	{
		title: "Target ยอดขาย (บาท)",
		dataIndex: "saleTarget",
		key: "saleTarget",
		align: "right",
		width: 150,
		...filterSearchColumn("saleTarget", "Target ยอดขาย (บาท)"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Actual ยอดขาย (บาท)",
		dataIndex: "saleActual",
		key: "saleActual",
		align: "right",
		width: 150,
		...filterSearchColumn("saleActual", "Actual ยอดขาย (บาท)"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "%ยอดขายเดือน",
		dataIndex: "persentSalePerMonth",
		key: "persentSalePerMonth",
		align: "right",
		width: 150,
		...filterSearchColumn("persentSalePerMonth", "%ยอดขายเดือน"),
		render: (text, record) => {
			return (
				<div>
					{text && text.toFixed(2)}
				</div>
			)
		}
	},
	{
		title: "Incentive Month",
		dataIndex: "incentiveMonth",
		key: "incentiveMonth",
		align: "right",
		width: 150,
		...filterSearchColumn("incentiveMonth", "Incentive Month"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "ยอดรับชำระหนี้ (บาท)",
		dataIndex: "rvAmount",
		key: "rvAmount",
		align: "right",
		width: 150,
		...filterSearchColumn("rvAmount", "ยอดรับชำระหนี้ (บาท)"),
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Commission",
		dataIndex: "commissionMonth",
		key: "commissionMonth",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Target ยอดขายไตรมาส (บาท)",
		dataIndex: "saleTargetQuater",
		key: "saleTargetQuater",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Actual ยอดขายไตรมาส (บาท)",
		dataIndex: "saleActualQuater",
		key: "saleActualQuater",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "%ยอดขายไตรมาส",
		dataIndex: "persentSalePerQuater",
		key: "persentSalePerQuater",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && text.toFixed(2)}
				</div>
			)
		}
	},
	{
		title: "Incentive Quarter",
		dataIndex: "incentiveQuater",
		key: "incentiveQuater",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Incentive กลยุทธ์",
		dataIndex: "incentiveStrategy",
		key: "incentiveStrategy",
		align: "right",
		width: 150,
		render: (text, record) => {
			return dataSource.length > 0 && <Input disabled={isView} type="number" name={`incentiveStrategy${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].incentiveStrategy ? Number(dataSource[record.rowNo - 1].incentiveStrategy).toFixed(2) : '' : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
		}
	},
	{
		title: "Incentive Focus Shop",
		dataIndex: "incentiveFocusShop",
		key: "incentiveFocusShop",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Commission Focus Shop",
		dataIndex: "commissionFocusShop",
		key: "commissionFocusShop",
		align: "right",
		width: 150,
		render: (text, record) => {
			return dataSource.length > 0 && <Input disabled={isView} type="number" name={`commissionFocusShop${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].commissionFocusShop ? Number(dataSource[record.rowNo - 1].commissionFocusShop).toFixed(2) : '' : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
		}
	},
	{
		title: "การันตี Incentive",
		dataIndex: "incentiveGuarantee",
		key: "incentiveGuarantee",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "การันตี Commission",
		dataIndex: "commissionGuarantee",
		key: "commissionGuarantee",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "เหตุผลการันตี",
		dataIndex: "remarkGuarantee",
		key: "remarkGuarantee",
		align: "center",
		width: 200,
		render: (text, record) => {
			return (
				<div>
					{text && text != 'undefined' ? text : ''}
				</div>
			)
		}
	},
	{
		title: "Total Commision ก่อนปรับปรุง",
		dataIndex: "totalCommissionBeforeUpdate",
		key: "totalCommissionBeforeUpdate",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Total Incentive ก่อนปรับปรุง",
		dataIndex: "totalIncentiveBeforeUpdate",
		key: "totalIncentiveBeforeUpdate",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "เพิ่ม/ลด Commission",
		key: "commission",
		align: "left",
		width: 150,
		render: (text, record) => {
			return <Input disabled={isView} type="number" name={`commission${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].commission : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
		}
	},
	{
		title: "เพิ่ม/ลด Incentive",
		key: "incentive",
		align: "left",
		width: 150,
		render: (text, record) => {
			return dataSource.length > 0 && <Input disabled={isView} type="number" name={`incentive${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].incentive : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
		}
	},
	{
		title: "หมายเหตุอนุมัติพิเศษ (ต้องระบุ)",
		key: "remarkApprovalSpecial",
		align: "left",
		width: 300,
		filters: [
			{
				text: 'เฉพาะที่มีหมายเหตุ',
				value: 'haveRemark',
			},
		],
		onFilter: (value: string, record) => {
			if (value == 'haveRemark') {
				return record.remarkApprovalSpecial && true
			}
			return record.remarkApprovalSpecial
		},
		render: (text, record) => {
			const { TextArea } = Input;
			return dataSource.length > 0 && <TextArea rows={1} disabled={isView} name={`remarkApprovalSpecial${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].remarkApprovalSpecial : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
		}
	},
	{
		title: "Total Commision",
		dataIndex: "totalCommission",
		key: "totalCommission",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{dataSource.length > 0 && dataSource[record.rowNo - 1] &&

						getNewValueSum(dataSource[record.rowNo - 1].totalCommissionBeforeUpdate, dataSource[record.rowNo - 1].commission, dataSource[record.rowNo - 1].totalCommission)
					}
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "Total Incentive",
		dataIndex: "totalIncentive",
		key: "totalIncentive",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{dataSource.length > 0 && dataSource[record.rowNo - 1] && getNewValueSum(dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate, dataSource[record.rowNo - 1].incentive, dataSource[record.rowNo - 1].totalIncentive)}
					{text && convertBaht(text.toFixed(2))}
				</div>
			)
		}
	},
	{
		title: "ชดเชย Commission",
		key: "recoverCommission",
		align: "left",
		width: 150,
		render: (text, record) => {
			return <Input disabled={isView} min={0} type="number" name={`recoverCommission${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].recoverCommission : ''} onChange={(e) => onChangeRow(e, record.rowNo)} /> 
		}
	},
	{
		title: "ชดเชย Incentive",
		key: "recoverIncentive",
		align: "left",
		width: 150,
		render: (text, record) => {
			return dataSource.length > 0 && <Input disabled={isView} type="number" min={0} name={`recoverIncentive${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].recoverIncentive : ''}  onChange={(e) => onChangeRow(e, record.rowNo)} /> 
		}
	},
	{
		title: "หมายเหตุค่าชดเชย(ต้องระบุ)",
		key: "remarkRecover",
		align: "left",
		width: 300,
		filters: [
			{
				text: 'เฉพาะที่มีหมายเหตุ',
				value: 'haveRemark',
			},
		],
		onFilter: (value, record) => {
			if (value == 'haveRemark') {
				return record.remarkRecover && true
			}
			return record.remarkRecover
		},
		render: (text, record) => {
			const { TextArea } = Input;
			return dataSource.length > 0 && <TextArea rows={1} disabled={isView} name={`remarkRecover${record.rowNo}`}  value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].remarkRecover : ''} onChange={(e) => onChangeRow(e, record.rowNo)} /> 
		}
	},

	{
		title: "Total Commision จ่าย",
		dataIndex: "totalCommissionPay",
		key: "total",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
				  {dataSource.length > 0 &&
				  dataSource[record.rowNo - 1] &&
				  getNewValueSumText(
					dataSource[record.rowNo - 1].totalCommissionBeforeUpdate,
					dataSource[record.rowNo - 1].commission,
					dataSource[record.rowNo - 1].totalCommission
				  )
					? getSumValuePay(
						getNewValueSumText(
						  dataSource[record.rowNo - 1].totalCommissionBeforeUpdate,
						  dataSource[record.rowNo - 1].commission,
						  dataSource[record.rowNo - 1].totalCommission
						),
						dataSource[record.rowNo - 1].recoverCommission,
						text
					  )
					: getSumValuePay(
						dataSource[record.rowNo - 1].totalCommission,
						dataSource[record.rowNo - 1].recoverCommission,
						text
					  )}
					  <div>{text && convertBaht(text.toFixed(2))}</div>
				</div>
			  );
		}
	},
	{
		title: "Total Incentive จ่าย",
		dataIndex: "totalIncentivePay",
		key: "total",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<Fragment>
					<div>
						{dataSource.length > 0 &&
						dataSource[record.rowNo - 1] &&
						getNewValueSumText(
						dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate,
						dataSource[record.rowNo - 1].incentive,
						dataSource[record.rowNo - 1].totalIncentive
						)
						? getSumValuePay(
							getNewValueSumText(
								dataSource[record.rowNo - 1].totalIncentiveBeforeUpdate,
								dataSource[record.rowNo - 1].incentive,
								dataSource[record.rowNo - 1].totalIncentive
							),
							dataSource[record.rowNo - 1].recoverIncentive,
							text
							)
						: getSumValuePay(
							dataSource[record.rowNo - 1].totalIncentive,
							dataSource[record.rowNo - 1].recoverIncentive,
							text
							)}
					</div>
					{text && convertBaht(text.toFixed(2))}
        		</Fragment>
			  );
		}
	},
	{
		title: "Total จ่าย",
		dataIndex: "total",
		key: "total",
		align: "right",
		width: 150,
		render: (text, record) => {
			return (
				<div>
					{
						dataSource.length > 0
						&& dataSource[record.rowNo - 1]
						&& getTotalNet(dataSource[record.rowNo - 1])
					}
					{/* {text && convertBaht(text.toFixed(2))} */}
				</div>
			)
		}
	},
];
