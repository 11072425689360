import React from 'react'

import ExportOT from '../../componentsOT/ExportOT'
import { KEY_ACTIVE_TAB, KEY_PERMISSION } from '../../../constants/enums/enumCenter'

export const TABS_DASHBOARD_OT = ({ permission, userLogin, renderTable }) => {
    switch (permission) {
        case KEY_PERMISSION.HRS_FAC:
        case KEY_PERMISSION.HRD_FAC:
        case KEY_PERMISSION.HRM_FAC:
        case KEY_PERMISSION.HRS_OFF:
        case KEY_PERMISSION.HRD_OFF:
        case KEY_PERMISSION.HRM_OFF:
        case KEY_PERMISSION.HeadDep_FAC:
        case KEY_PERMISSION.Head_FAC:
            return [
                { key: '1', tab: 'รายการคำขอโอที', content: renderTable },
                { key: '2', tab: 'รายการอนุมัติคำขอโอที', content: renderTable },
            ]
        default:
            return [
                { key: '1', tab: 'รายการคำขอโอที', content: renderTable }
            ]
    }
}

export const TABS_EXPORT_OT = ({ permission }) => {
    return [{ tab: 'Export CSV', content: <ExportOT /> }]
}