const CryptoJS = require("crypto-js");

const KEY = 'commission2022HR'

export function encryptString(str) {
	if (!str) return ''
	return CryptoJS.AES.encrypt(str, KEY).toString();
}

export function decryptString(encodeStr) {
	if (!encodeStr) return ''
	// console.log('sffafww', CryptoJS.AES.decrypt(
	// 	encodeStr,
	// 	KEY
	// ).toString(CryptoJS.enc.Utf8))
	return CryptoJS.AES.decrypt(
		encodeStr,
		KEY
	).toString(CryptoJS.enc.Utf8);
}

export function decryptBase64 (data) {
    return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};