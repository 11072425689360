import { useState } from "react";
import { onPrintPDF } from "../function/print";
import { POST, POST_SAVE_LOG_PRINT, GET_FORM_CONTRACT_DETAIL_PRINT } from "../../../../service";
import { setDataFormPrint } from "../function/setDataFormPrint";
import Cookies from "js-cookie";
import { SetStringToObject } from "../../../../constants/functions";
import encodeString from "../../../../extendFunction/encodeString";

export const useGetFormPrint = ({ setActiveKey }) => {
  const [formPrint, setFormPrint] = useState({});
  const [loadingFormPrint, setLoadingFormPrint] = useState([]);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));
  const [rawValue, setRawValue] = useState({});

  //*พรีวิวเอกสาร
  const onGetFormPrint = async (value) => {
    setFormPrint(() => {});
    value.printType = 'MAIN'
    const data = await onFetchFormPrint(value);
    if (data) {
      setFormPrint(() => data);
    }
  };

  //*เลือกพิมพ์เอกสารทันที
  const onPrint = async (value, printType) => {
    value.printType = printType;
    const data = await onFetchFormPrint(value);
    if (data) {
      onPrintPDF(data);
      setFormPrint(() => data);
    }
    await onSaveFormPrint(value);
  };

  //CALL API
  const onFetchFormPrint = async (value) => {
    try {
      setLoadingFormPrint(true);
      setActiveKey(["docDetailGeneral","formPrint"]);
      const data = await setDataFormPrint(value)
      const respond = await POST(GET_FORM_CONTRACT_DETAIL_PRINT, data);
      const sendVal = respond.result.rawValue;
      sendVal.requesterID = encodeString.decode(cookies.ID_User)
      setRawValue(sendVal)
      return respond.result.docFormPrint;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoadingFormPrint(false);
    }
  };

  const onSaveFormPrint = async (value) => {
    try {
      const data = {
        docID: '',
        requesterID: encodeString.decode(cookies.ID_User),
        employeeID: value.userId,
        formType: 'CONTRACT',
        companyCode: value.companyCode
      }
      await POST(POST_SAVE_LOG_PRINT, data);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return {
    formPrint,
    loadingFormPrint,
    onGetFormPrint,
    onPrint,
    rawValue
  };
};
