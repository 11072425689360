import React from 'react'
import { Table } from 'antd';

export const TableNested = ({
    rowKey,
    columns,
    columnsExpanded,
    dataSource,
    expandedField,
    expandedProps,
    ...rest
}) => {
    return (
        <Table
            rowKey={rowKey}
            bordered
            columns={columns}
            expandedRowRender={record =>
                <div style={{ padding: 10 }}>
                    <Table
                        bordered
                        pagination={false}
                        columns={columnsExpanded}
                        dataSource={record[expandedField]}
                        {...expandedProps} />
                </div>
            }
            dataSource={dataSource}
            {...rest}
        />
    )
}