import React from "react";
import { Col } from "antd";
import { Checkbox } from "../../../../components/global/forms/checkBox";

export const DocInputCheckbox = ({
  form = {},
  control = { control },
  handleChange = () => console.warn("No handleChange"),
}) => {
  return (
    <Col
      key={`renderInput${form.ref}`}
      xs={{ span: 24 }}
      md={{ span: 24 }}
      xl={{ span: form.span }}
      lg={{ span: form.span }}
    >
      <Checkbox item={form} control={control} handleChange={handleChange} />
    </Col>
  );
};
