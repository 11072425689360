import React, { useState } from "react";
import { Form, Card, Row, Col, Spin, Tabs, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportSSO_OutEmp } from "../../constants/HeaderExcel";
import {
  optionSelectLocation,
  optionStatusDocSSO,
  useOptionSelect,
} from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import {
  GET_UserLogin,
  subStringDate,
  sumColumnWidth,
  toUndefined,
} from "../../functions/UseFunction";
import moment from "moment";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import {
  POST,
  POST_INSERT_SEND_SSO,
  POST_SELECT_DASHBOARD_ACTION_OUT_EMP,
} from "../../../service";
import { ColumnSearchSSOOutEmp } from "../../constants/ManageSSO/Columns";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import {
  ComponentSearchSSOOutEmp,
  ComponentSendSSO,
} from "../../constants/ManageSSO/Components";
import { TextFileDownload } from "../views/TextFileDownload";

const initValue = {
  company: undefined,
  location: undefined,
  depNo: undefined,
  subDepNo: undefined,
  startDate: undefined,
  toDate: undefined,
  statusDoc: undefined,
};

export const FormSearchSSOoutEmp = () => {
  const [formSearch] = Form.useForm();
  const [formSendSSO] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState(initValue);
  const [dataTable, setDataTable] = useState([]);
  const [rowSelection, setRowSelection] = useState({
    selectedRowKeys: [],
    selectedRows: [],
  });
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [fileText, setFileText] = useState("");
  // [END] States
  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect(
      "department",
      useFixedQueryString(changeSearchValue, ["companyCode", "location"])
    ),
    subDepNo: useOptionSelect(
      "subDepartment",
      useFixedQueryString(changeSearchValue, ["company", "location", "depNo"])
    ),
    statusDoc: optionStatusDocSSO,
  };

  // [START] Handle Form
  const onFinish = (values) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusDoc } =
      values;
    let objectBody = {
      company: company || "",
      location: location || "",
      depNo: depNo || "",
      subDepNo: subDepNo || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
      statusDoc: statusDoc || "",
    };
    selectDashboardActionOutEmp(objectBody);
  };

  const onFinishSendSSO = (values) => {
    const { docRef, datatimeStamp } = values;
    const { selectedRows } = rowSelection;
    let dataArray = [];
    if (selectedRows.length === 0) {
      useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    } else if (!datatimeStamp) {
      useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `คุณยังไม่ได้เลือก "วันที่ยื่น" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    } else {
      dataArray = selectedRows.map((row) => ({
        userId: row.userId,
        typeInsurance: "sos",
        planInsuranceHea: row.planHea || "",
        planInsuranceAcd: row.planAcd || "",
        company: row.companyCode,
        typeBenefit: "out",
        datatimeStamp: datatimeStamp.format("YYYY-MM-DD"),
        docRef,
        userlogin,
      }));
      //console.log("dataArray", dataArray)
      useConfirm({ FnOK: () => sendSSO(dataArray) });
    }
  };

  const onReset = () => {
    formSearch.resetFields();
    setDataTable([]);
    setChangeSearchValue(initValue);
    setRowSelection({ selectedRowKeys: [], selectedRows: [] });
  };
  // [END] Handle Form

  // [START] REST API
  const selectDashboardActionOutEmp = async (objectValue) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setRowSelection({ selectedRowKeys: [], selectedRows: [] });
      formSendSSO.resetFields();
      const response = await POST(
        POST_SELECT_DASHBOARD_ACTION_OUT_EMP,
        objectValue
      );
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        setFileText(result.fileText);
        mapResult = result.data.map((row, index) => ({
          key: `${row.userId}_${index}`,
          ...row,
          dataStamp: subStringDate(row.dataStamp),
          endDateJob: subStringDate(row.endDateJob),
        }));
      } else {
        setFileText("");
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Dashboard Action Out Emp: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const sendSSO = async (dataBody) => {
    try {
      const response = await POST(POST_INSERT_SEND_SSO, dataBody);
      const { success, result } = response;
      if (success) {
        useAlert({
          type: "success",
          content: `${result}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "info",
          content: `บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Send SSO (HEA): ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    let setName = {};
    switch (name) {
      case "company":
        setName = { companyCode: value };
        setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
        break;
      case "location":
        setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"]);
        break;
    }
    if (name !== "datatimeStamp" && name !== "docRef") setDataTable([]);
    formSearch.setFieldsValue(setUndefined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...setUndefined,
      ...setName,
      [name]: value,
    }));
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={formSearch} onFinish={onFinish} {...configForm}>
        <Card
          title="ค้นหาข้อมูลประกันสังคมพนักงานพ้นสภาพ"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentSearchSSOOutEmp({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="ยื่นประกันสังคมพนักงานพ้นสภาพ"
          style={{ marginBottom: 30 }}
        >
          <Form
            form={formSendSSO}
            {...configForm}
            onFinish={onFinishSendSSO}
            initialValues={{ datatimeStamp: moment() }}
          >
            <Row gutter={[16, 16]} align="bottom">
              <Col span={10}>
                {useRenderFormItem({
                  constantComponent: ComponentSendSSO({
                    handleChange: onChangeValue,
                  }),
                })}
              </Col>
              <TextFileDownload fileText={fileText} />
            </Row>
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={{
                right: useExportExcel({
                  header: Header_ReportSSO_OutEmp,
                  dataset: dataTable,
                  filename: "รายงานการยื่นประกันสังคมพนักงานพ้นสภาพ",
                }),
              }}
            >
              <Tabs.TabPane tab="ข้อมูลพนักงานพ้นสภาพ" key="1">
                <CustomTableAtnd
                  HeadTable={ColumnSearchSSOOutEmp}
                  DataTable={dataTable}
                  moreProps={{
                    scroll: {
                      x: sumColumnWidth(ColumnSearchSSOOutEmp) || 2000,
                      y: 500,
                    },
                    rowSelection: {
                      onChange: (selectedRowKeys, selectedRows) => {
                        setRowSelection({ selectedRowKeys, selectedRows });
                      },
                      getCheckboxProps: (record) => ({
                        disabled: record.statusCode === "Y",
                      }),
                    },
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Spin>
  );
};

export default FormSearchSSOoutEmp;
