import React, { Component } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { FiArrowDownCircle, FiCheckCircle, FiXCircle, FiAlertCircle, FiClock } from "react-icons/fi";
import { API_URL } from "../connectAPI";
import { Col, Button } from "reactstrap";
import checkDateColorCode from "../../extendFunction/checkDateColorCode";
import { Table, Input, Popconfirm, Tooltip, Modal } from "antd";
import { getColumnSearchProps } from "../../constants/functions/getColumnsSearch";
import { SetDate } from "../../common/setDate";
import { RenderStatusMainCode } from "../../common/setStatus";


const { confirm } = Modal;

function onChange(pagination, filters, sorter, extra) {
	// console.log("params", pagination, filters, sorter, extra);
}

// const api = connectAPI.API_URL;
class BenefitReportNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
		};
	}

	openViewPro = (_id) => {
		this.props.handleLocation({ pathname: `${'/ViewProbation/'}`, state: { DocNo: _id }, status: 'view' })
	};

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	insertDate = (_getType, _data) => {
		const userLogin = this.props.userLogin;
		const link = API_URL + "/HRM_API/insert_timeStampBenefits";
		let docSOS = ''
		if (_getType === 'sos') {
			docSOS = this.state.fields[_data.StaffCode]
		}
		var data = {
			StaffCode: _data.StaffCode,
			typeInsurance: _getType,
			typeBenefit: 'in',
			user_login: userLogin,
			docSOS: docSOS,
			refDocProbation: _data.DocNo
		};

		//console.log(data)

		fetch(link, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(Response => Response.json())
			.then(results => {
				if (results.status_api === 1) {
					Modal.success({ title: "บันทึกเรียบร้อยแล้ว" })
					this.props.fncSearch({ ...this.props });
				} else {
					Modal.error({ title: "Insert Error!!" })
				}
			});
	};



	render() {
		const columnsBenefit = () => {
			const searchInput = React.createRef();

			return [
				{
					title: "รหัสพนักงาน",
					dataIndex: "StaffCode",
					key: "StaffCode",
					fixed: "left",

					...getColumnSearchProps('StaffCode', searchInput),
				},
				{
					title: "ชื่อพนักงาน",
					dataIndex: "StaffName",
					key: "StaffName",
					fixed: "left",
					...getColumnSearchProps('StaffName', searchInput),
				},
				{
					dataIndex: "NickName",
					title: "ชื่อเล่น",
					key: "NickName",
					width: 100,
					fixed: "left",
					...getColumnSearchProps('NickName', searchInput),
				},
				{
					dataIndex: "probationStatus_Des",
					title: "ผลการทดลองงาน",
					key: "probationStatus_Des",
					width: 100,
					fixed: "left",
					render: (text, record) => {
						let icon = <FiClock color={"#424949"} size={"18"} />;
						if (record.statusProbationLast === '1') { //ผ่านทดลองงาน
							icon = <FiCheckCircle color={"#028E22"} size={"18"} />

						} else if (record.statusProbationLast === '2') {  //ไม่ผ่าน
							icon = <FiXCircle color={"#E74C3C"} size={"18"} />

						} else if (record.statusProbationLast === '3') {  //ต่อโปร
							icon = <FiAlertCircle color={"#F4D03F"} size={"18"} />

						}
						return <div> <React.Fragment>
							<Tooltip placement="topLeft" title={record.probationStatus_Des}>
								{icon}
							</Tooltip>
						</React.Fragment></div>;
					}
				},
				{
					label: "กลุ่ม",
					title: "กลุ่ม",
					dataIndex: "statusWorker",
					key: "statusWorker",
					...getColumnSearchProps('statusWorker', searchInput),

				},
				{
					title: "ชื่อผู้บังคับบัญชา",
					dataIndex: "BossName",
					key: "BossName",
					...getColumnSearchProps('statusWorker', searchInput),

				},
				{
					title: "แผนก / กลุ่ม",
					dataIndex: "Department_Name",
					key: "Department_Name",
					...getColumnSearchProps('Department_Name', searchInput),

				},
				{
					title: "วันที่เริ่มทำงาน",
					dataIndex: "StartDateJob",
					key: "StartDateJob",
					render: (text, record) => SetDate(text),

				},
				{
					dataIndex: "statusMainCode",
					title: "สถานะ",
					key: "statusMain",
					width: 200,
					render: (text, record) => RenderStatusMainCode(text, record.statusMain)

					// render: (text, row) => {
					//   let iconA = ''
					//   if (row.statusMainCode === "0") {
					//     iconA = <div> รอประเมิน </div>   //"#B03A2E"
					//   } else if (row.statusMainCode === "1") {
					//     iconA = <div>ช่วงทดลองงาน  </div>    //"#E67E22"
					//   } else if (row.statusMainCode === "2") {
					//     iconA = <div>รอ HR รับ </div>  //"#1E8449"
					//   } else if (row.statusMainCode === "3") {
					//     iconA = <div>จบประเมิน</div>  //"#1E8449"
					//   }
					//   return (
					//     <div>
					//       {iconA}
					//     </div>
					//   )
					// }
				},
				{
					title: "วันที่คาดว่าผ่านทดลองงาน",
					dataIndex: "expProDate",
					key: "expProDate",
					render: (text, record) => SetDate(text)

				},
				{
					title: "วันที่ผ่าน / ไม่ผ่านทดลองงาน ",
					dataIndex: 'probationEndDate',

					render: (text, record) => (text) ? SetDate(text) : '-'

					// render: (text, record) => {
					//   let dateEndPro = "";
					//   if (record.probationEndDate != null) {
					//     dateEndPro = (
					//       <Moment format="YYYY-MM-DD">{record.probationEndDate}</Moment>
					//     );
					//   }
					//   return <div>{dateEndPro}</div>;
					// }
				},
				{
					title: "วันที่รับเอกสารทดลองงาน",
					dataIndex: 'HR_ReceivedDate',

					key: "HR_ReceivedDate",
					render: (text, record) => (text) ? SetDate(text) : '-'

					// render: (text, record) => {
					//   let HR_ReceivedDate = "";
					//   if (record.HR_ReceivedDate != null) {
					//     HR_ReceivedDate = (
					//       <React.Fragment>
					//         <Moment format="YYYY-MM-DD">
					//           {record.HR_ReceivedDate}
					//         </Moment>
					//       </React.Fragment>
					//     );
					//   }

					//   return <div>{HR_ReceivedDate}</div>;
					// }
				},
				{
					dataIndex: "date_HEA",
					title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
					key: "date_HEA",
					render: (text, record) => {
						if (text === null && record.statusProbationLast !== '2') {
							return (
								<Popconfirm
									title="ยืนยันการอัพเดท?"
									onConfirm={() => this.insertDate("hea", record)}
									okText="Yes"
									cancelText="No"
								>
									<div style={{ display: 'flex' }}>
										<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expACD_HEA)} size={"18"} />
										<div className={checkDateColorCode.colorClassName(SetDate(record.expACD_HEA))} >
											{SetDate(record.expACD_HEA)}
										</div>
									</div >
								</Popconfirm>
							);
						} else if (text !== null && record.statusProbationLast !== '2') {
							return (
								<div style={{ display: 'flex' }}>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									<div className={checkDateColorCode.colorClassName(SetDate(record.date_HEA))} >
										{SetDate(record.date_HEA)}
									</div>
								</div>
							);
						}

					}
					// render: (text, record) => {
					//   console.log({ record })
					//   let dateEndHEA = "";
					//   if (record.date_HEA === null && record.statusProbationLast !== '2') {
					//     dateEndHEA = (
					//       <React.Fragment>
					//         <Popconfirm
					//           title="ยืนยันการอัพเดท?"
					//           onConfirm={() => this.insertDate("hea", record)}
					//           onCancel={this.cancel}
					//           okText="Yes"
					//           cancelText="No"
					//         >
					//           <FiArrowDownCircle color={checkDateColorCode.codeColor(record.expACD_HEA)} size={"18"} />
					//           {/* <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expACD_HEA)}>
					//             {record.expACD_HEA}
					//           </Moment> */}
					//           {SetDate(record.expACD_HEA)}
					//         </Popconfirm>
					//       </React.Fragment>
					//     );
					//   } else if (record.date_HEA != null && record.statusProbationLast !== '2') {
					//     dateEndHEA = (
					//       <React.Fragment>
					//         <FiCheckCircle color={"#028E22"} size={"18"} />
					//         {/* <Moment format="YYYY-MM-DD" className="date-green">
					//           {record.date_HEA}
					//         </Moment> */}
					//         {SetDate(record.date_HEA)}

					//       </React.Fragment>
					//     );
					//   }
					//   return <div>{dateEndHEA}</div>;
					// }
				},
				{
					title: "กองทุนสำรองเลี้ยงชีพ",
					dataIndex: "date_PVD",
					key: "date_PVD",
					// filters: true,
					render: (text, record) => {
						let dateEndPVD = "";
						if (record.date_PVD === null && record.statusProbationLast !== '2') {
							dateEndPVD = (
								<React.Fragment>
									<Popconfirm
										title="ยืนยันการอัพเดท?"
										onConfirm={() => this.insertDate("pvd", record)}
										onCancel={this.cancel}
										okText="Yes"
										cancelText="No"
									>
										<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expPVD)} size={"18"} />
										{/* <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expPVD)}>
                      {record.expPVD}
                    </Moment> */}
										{SetDate(record.expPVD)}

									</Popconfirm>
								</React.Fragment>
							);
						} else if (record.date_PVD != null && record.statusProbationLast !== '2') {
							dateEndPVD = (
								<React.Fragment>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									{/* <Moment format="YYYY-MM-DD" className="date-green">
                    {record.date_PVD}
                  </Moment> */}
									{SetDate(record.date_PVD)}

								</React.Fragment>
							);
						}
						return <div>{dateEndPVD}</div>;
					}
				},
				{
					title: "ประกันสังคม",
					dataIndex: "date_SOS",
					key: "date_SOS",
					render: (text, record) => {
						let dateEndSOS = "";
						if (record.date_SOS === null) {
							dateEndSOS = (
								<React.Fragment>
									<Input type='text' name={record.StaffCode} id={record.StaffCode} onChange={this.handleChange.bind(this, record.StaffCode)} value={this.state.fields[record.StaffCode]} />
									<Popconfirm
										title="ยืนยันการอัพเดท?"
										onConfirm={() => this.insertDate("sos", record)}
										onCancel={this.cancel}
										okText="Yes"
										cancelText="No"
									>
										<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expSOS)} size={"18"} />
										{/* <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expSOS)}>
                      {record.expSOS}
                    </Moment> */}
										{SetDate(record.expSOS)}

									</Popconfirm>

								</React.Fragment>

							);
						} else if (record.date_SOS != null) {
							dateEndSOS = (
								<React.Fragment>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									{/* <Moment format="YYYY-MM-DD" className="date-green">
                    {record.date_SOS}
                  </Moment> <br />
                  {record.docRef} */}
									<div className={checkDateColorCode.colorClassName(SetDate(record.date_SOS))} >
										{SetDate(record.date_SOS)}
									</div>
									<br />
									{record.docRef || ''}
								</React.Fragment>
							);
						}
						return <div>{dateEndSOS}</div>;
					}
				},
				{
					dataIndex: "DocNo",
					title: "เอกสารทดลองงาน",
					key: "",
					render: (text, record) => {
						let showBtn = '-'
						// console.log('record', record)
						if (record.DocNo) {
							showBtn = <Button color="info" onClick={() => this.openViewPro(record.DocNo)} id={record.DocNo}
							>   View  </Button>
						}
						return <div>
							<Col>
								{showBtn}
							</Col>
						</div>
					}
				},
			]
		};

		const headersBenefit = [
			{ label: "รหัสพนักงาน", key: "StaffCode" },
			{ label: "ชื่อพนักงาน", key: "StaffName" },
			{ label: "ชื่อผู้บังคับบัญชา", key: "BossName" },
			{ label: "แผนก / กลุ่ม", key: "DepNo" },
			{ label: "วันที่เริ่มงาน", key: "StartDateJob" },
			{ label: "วันที่คาดว่าผ่านทดลองงาน", key: "expProDate" },
			{ label: "วันที่ผ่านทดลองงาน", key: "probationEndDate" },
			{ label: "ประกันสุขภาพ / ประกันชีวิต", key: "date_HEA" },
			{ label: "ประกันอุบัติเหตุ", key: "date_ACD" },
			{ label: "กองทุนสำรองเลี้ยงชีพ", key: "date_PVD" },
			{ label: "ประกันสังคม", key: "date_SOS" }
		];
		return (
			<div>
				<div className="btn-right">
					<CSVLink
						headers={headersBenefit}
						data={this.props.dataSource || []}
						filename={"BenefitsReport.csv"}
						className="btn btn-export"
						target="_blank"
					>
						<MdFileDownload /> Export CSV
					</CSVLink>
				</div>
				<Table
					columns={columnsBenefit()}
					dataSource={this.props.dataSource || []}
					scroll={{ x: 2000 }}
				/>
			</div>
		);
	}
}

export default BenefitReportNew;
