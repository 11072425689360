//import Cookies from "js-cookie";
import { Modal } from "antd";
import { authenLink, qrLink } from "../service";
//import { SetStringToObject } from "../constants/functions";

export const checkCookies = () => {

  console.log("{object}",window.location.href);
  try {
    const redirectUrl = authenLink
    const assetUrl = qrLink

    if ((window.location.href).includes("Assets_NewEmpForm?userId")) {
      //*กดจากอีเมลยืนยันอุปกรณ์ให้ Redirect ไปเลย
      window.location = redirectUrl;
    } else if ((window.location.href).includes("Assets_ConfirmationLogin")) {
      window.location = assetUrl;
    } else if ((window.location.href).includes("forgotpassword")) {
      window.location = window.location.href;
    } else {
      Modal.warning({
        title: `Session หมดอายุ กรุณา Login ใหม่อีกครั้งค่ะ`,
        onOk() {
          window.location = redirectUrl;
        },
        afterClose() {
          window.location = redirectUrl;
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
};
