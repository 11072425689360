import { Row, Col } from "antd";
import React, { memo } from "react";
import { DocumentSelectCard } from "../style";
import { TextSmall } from "../../../../components/global/text";
import {
  generalDetailEN,
  generalDetailTH,
  generalDetailAll,
} from "../forms/generalDetail";
import { RenderForm } from "../../../../components/global/forms";

const GeneralDocumentDetail = ({
  control,
  setValue,
  documentList,
  options = {
    optionsDepartmentEN: [],
    optionsDepartmentTH: [],
    optionsSigner: [],
  },
}) => {
  const haveDocTH = documentList.reduce(
    (acc, cur) => acc || cur.lang == "TH",
    false
  );
  const haveDocEN = documentList.reduce(
    (acc, cur) => acc || cur.lang == "EN",
    false
  );
  return (
    <Row gutter={[4, 4]}>
      <Col
        key={`GeneralDocumentDetail_ALL`}
        md={{ span: 24 }}
        xl={{ span: 24 }}
      >
        <DocumentSelectCard
          title={<TextSmall bold={false} text={"ข้อมูลทั่วไป"} />}
        >
          <RenderForm
            control={control}
            forms={generalDetailAll(options)}
            setValue={setValue}
          />
        </DocumentSelectCard>
      </Col>
      {haveDocTH && (
        <Col
          key={`GeneralDocumentDetail_TH`}
          md={{ span: 24 }}
          xl={{ span: haveDocEN ? 12 : 24 }}
        >
          <DocumentSelectCard
            title={<TextSmall bold={false} text={"ข้อมูลภาษาไทย"} />}
          >
            <RenderForm
              control={control}
              forms={generalDetailTH(options)}
              setValue={setValue}
            />
          </DocumentSelectCard>
        </Col>
      )}
      {haveDocEN && (
        <Col
          key={`GeneralDocumentDetail_EN`}
          md={{ span: 24 }}
          xl={{ span: haveDocTH ? 12 : 24 }}
        >
          <DocumentSelectCard
            title={<TextSmall bold={false} text={"ข้อมูลภาษาอังกฤษ"} />}
          >
            <RenderForm
              control={control}
              forms={generalDetailEN(options)}
              setValue={setValue}
            />
          </DocumentSelectCard>
        </Col>
      )}
    </Row>
  );
};

export const MemoGeneralDocumentDetail = memo(
  GeneralDocumentDetail,
  (prev, next) =>
    next.documentList.length == prev.documentList.length &&
    next.options.optionsDepartmentEN.length ==
      prev.options.optionsDepartmentEN.length &&
    next.options.optionsDepartmentTH.length ==
      prev.options.optionsDepartmentTH.length &&
    next.options.optionsSigner.length == prev.options.optionsSigner.length
);
