import { useState, useEffect } from "react";
import { GET, GET_MONITOR_FILE_DASHBOARD_VIEW } from "../../../../src/service";
import Cookies from "js-cookie";
import encodeString from "../../../extendFunction/encodeString";
import { SetStringToObject } from "../../../constants/functions";
const cookies = SetStringToObject(Cookies.get("cookie_session"));

export const useGetDataSource = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loadingDataSource, setLoadingDataSource] = useState(false);

  useEffect(
    () => onFetchDataSource({ fileStatus: "WAIT", fileGuaranteeStatus: "NONE" }),
    []
  );

  const onFetchDataSource = async (value) => {
    try {
      const fileStatus = value.fileStatus;
      const fileGuaranteeStatus = value.fileGuaranteeStatus == "REQUIRED" ? 1 : "";
      const loginUser = encodeString.decode(cookies.ID_User)

      setLoadingDataSource(true);
      const respond = await GET(
        `${GET_MONITOR_FILE_DASHBOARD_VIEW}?ref=${loginUser}&fileStatus=${fileStatus || ''}&fileGuaranteeStatus=${fileGuaranteeStatus || ''}`
      );
      setDataSource(() => [
        ...respond.result.map((element) => ({
          ...element,
          key: element.employeeNo
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataSource(false);
    }
  };

  return {
    dataSource,
    onFetchDataSource,
    loadingDataSource,
  };
};
