import React, { useState, useEffect } from "react";
import { Form, Card, Spin } from "antd";
import { ApprovalFlow, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { browserHistory } from "react-router";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsUpdateBranchApprove } from "../../constants/ManageBranchApprove/Components";

const FormEditBranchApprove = (props) => {
  const [form] = Form.useForm();
  const { flowId } = props.params;
  const { userlogin } = GET_UserLogin();

  // [START] States
  const [loadingScreen, setLoadingScreen] = useState(false);

  let optionEmployee = useOptionSelect("empAll");

  let options = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    flowId: useOptionSelect("approval"),
    approvers_lv_1: optionEmployee,
    approvers_lv_2: optionEmployee,
  }
  let isDisable = { company: true, location: true, flowId: true }
  // [END] States

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { approvers_lv_1, approvers_lv_2 } = values;
    const objectBody = {
      flowId: values.flowId,
      approvers: [approvers_lv_1, approvers_lv_2],
      userlogin,
    };
    useConfirm({ FnOK: () => updateApproveFlow(objectBody) });
  };
  // [END] Handle Form Antd

  // [START] useEffect
  useEffect(() => {
    getUpdateAproveFlow();
  }, []);
  // [END] useEffect

  // [START] REST API
  const getUpdateAproveFlow = async () => {
    try {
      setLoadingScreen(true);
      let url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_UPDATE_Approve}${flowId}`;
      const response = await fetch(url);
      const data = await response.json();
      let userIds = [];
      if (response.ok) {
        const RESULT = data.result;
        const { company, location, flowId: getFlowId, approvers } = RESULT;
        userIds = approvers.map(val => val.approverNo);
        form.setFieldsValue({
          company: company,
          location: location,
          flowId: getFlowId,
          approvers_lv_1: userIds[0] || "",
          approvers_lv_2: userIds[1] || "",
        });
        setLoadingScreen(false);
      } else {
        useAlert({
          type: "error",
          title: `ไม่พบข้อมูล ${flowId} ในระบบ`,
          content: "กรุณาลองใหม่อีกครั้ง",
          Fn: () => browserHistory.push("/ManageBranchApprove"),
        });
      }
    } catch (err) {
      console.log("Error Get Data Update AproveFlow: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };

  const updateApproveFlow = async (value) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.PUT_UPDATE_Approve}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      if (response.ok) {
        setLoadingScreen(true);
        useAlert({
          type: "success",
          content: `แก้ไขสายอนุมัติสำเร็จ`,
          Fn: () => browserHistory.push("/ManageBranchApprove"),
        });
      } else {
        useAlert({
          type: "error",
          title: `เกิดข้อผิดพลาดจากการแก้ไขข้อมูล`,
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Update ApproveFlow: ", err);
      setLoadingScreen(false);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API
  return (
    <Card title="แก้ไขข้อมูลสายอนุมัติ" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsUpdateBranchApprove({
              options,
              isDisable,
              onClick: () => window.location.reload(),
            })
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FormEditBranchApprove;
