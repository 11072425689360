import { Tag } from 'antd'
import React from 'react'
import { colors } from '../../themes'


export const RENDER_STATUS_TRAINING = (_text, _status) => {
    let color = ''
    switch (_status) {
        case 'draft':
            color = 'blue'
            break
        case 'edit':
            color = 'warning'
            break
        case 'done':
            color = 'success'
            break
        default:
            color = 'default'
            break
    }
    return <Tag color={color}> {_text || ''}  </Tag>
}

export const RENDER_TEXT_COLORS = (text, color) => {
    return (
        <Tag color={color}>
            {text || ''}
        </Tag>
    )
}
