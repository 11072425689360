import React from "react";
import { path } from "ramda";
import { Select } from 'antd';
import "bootstrap/dist/css/bootstrap.css";
import { FormGroup, Label, Input } from "reactstrap";

const SelectSearch = ({
	label = "",
	name = "",
	options,
	onChange,
	styleLabel,
	styleSelect,
	style,
	valueField,
	value,
	placeholder,
	width,
	...rest
}) => {
	return (
		<FormGroup style={style}>
			{label ? <Label style={styleLabel}>{label}</Label> : null}
			<Select
				showSearch
				style={{ width: width ? width : rest.isRenderForm ? '100%' : 200 }}
				placeholder={placeholder}
				optionFilterProp="children"
				onChange={onChange}
				// showArrow={false}
				// onFocus={onFocus}
				// onBlur={onBlur}
				// onSearch={onSearch}
				// defaultValue={""}
				value={value}
				filterOption={(input, option) =>
					option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				{...rest}
			>
				{rest.isRenderForm ? options.length > 0 &&
					options.map((val, i) =>
						<Select.Option key={val.key || i} value={val.value}>{val.label}</Select.Option>
					)
					: options
				}
			</Select>
		</FormGroup>
	)
}

export default SelectSearch