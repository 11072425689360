import _ from "lodash"

export const setDataFormPrint = (value) => {
    return  _.pick(value,[
        'docType',
        'contractDate',
        'companyName',
        'companyAddress',
        'empFullName',
        'empPersonalID',
        'departmentName',
        'positionName',
        'contractMonth',
        'empAddress',
        'startDateJob',
        'endDateJob',
        'probationDay',
        'salary',
        'approverID',
        'approverPosition',
        'signAuto',
        'witness1',
        'witness2',
        'companyCode',
        'userId',
        'printType'
    ]);
}