import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import { Button, FormGroup } from "reactstrap";
import BaseText from "../text/BaseText";
import { colors } from "../../themes";

export const ButtonIcon = ({ name = "", onClick, label = "", color = colors.buttonTheme, icon, left, right, ...rest }) => {
    return (
        <FormGroup style={{ paddingRight: left ? left : 0, paddingLeft: right ? right : 0 }}>
            <Button {...rest} name={name} onClick={onClick} style={{ display: 'flex', border: 0, backgroundColor: color, paddingTop: 3 }}>
                <div style={{ paddingRight: 10, color: colors.white }}> {icon} </div>
                <BaseText color={colors.white} margin={0} label={label} />
            </Button>
        </FormGroup>
    );
};
