import React, { useState, Fragment, useContext, useRef } from 'react'
import { Button, Modal } from 'antd'
import { browserHistory } from "react-router";
import { ExportExcel, ButtonTheme } from '../common/buttons'
import { TYPE_ASSESSMENT } from './formPrint/assessmentFactory'
import { STRUCTURE_KPIS_FACTORY } from '../constants/structures/structuresKPIsFactory'
import { LayoutTable } from '../common/layouts'
import { PATHNAME } from '../../constants/enums/pathname';
import { ReportContext } from '../services/report/reportProvider';
import { path } from 'ramda';
import { REPORT_OPTION } from '../services/servicesOptions';
import { searchMonth } from '../../constants/functions';
import { renderWithLabel } from '../common/functions';
import { MasterContext } from '../services/master/masterProvider';
import { IndicatorContext } from '../services/manage/indicatorProvider';
import { colors } from '../../themes';
import { TEXT_BTN } from '../../moduleOT/commonsOT/structuresOT/buttonOT';


const ReportKPIsFactory = ({ byAssessment }) => {
    const refExport = useRef()
    const { userLogin, userPermission } = useContext(IndicatorContext)
    const { reportState, manageReport } = useContext(ReportContext)
    const { masterState } = useContext(MasterContext)
    const { SEARCH_OPTION_EMPLOYMENT_TYPE, SEARCH_OPTION_YEAR } = masterState
    let weight = path(['reportFactory', 'datasource', 0], reportState)
    const [selectKeys, setSelectKeys] = useState([])
    let searchResult = path(['reportFactory', 'search'], reportState)
    let dataSource = path(['reportFactory', 'datasource'], reportState)
  
    const rowSelection = {
        selectedRowKeys: selectKeys,
        onChange: async (selectedRowKeys, selectedRows) => {
            await setSelectKeys(selectedRowKeys)
        },
        getCheckboxProps: record => {
            switch (userPermission) {
                // case 'HR':
                // case 'HR-HeadDep':
                // case 'HR-Admin':
                // case 'HR-AdminOff':
                // case 'HR-Ass':
                // case 'Intern':
                // case 'HR-Super':
                case 'HRS-OFF':
                case 'HRD-OFF':
                case 'HRM-OFF':
                case 'HRS-FAC':
                case 'HRD-FAC':
                case 'HRM-FAC':
                    return ({ disabled: (record.grade === "") ? true : false });
                default:
                    return ({ disabled: (record.approvalNo2 === userLogin && record.grade !== "") ? false : true }) // Column configuration not to be checked
            }
        }

    };

    const renderRecall = () => {
        switch (userPermission) {
            // case 'HR':
            // case 'HR-HeadDep':
            // case 'HR-Admin':
            // case 'HR-AdminOff':
            // case 'HR-Ass':
            // case 'Intern':
            // case 'HR-Super':
            case 'HRS-OFF':
            case 'HRD-OFF':
            case 'HRM-OFF':
            case 'HRS-FAC':
            case 'HRD-FAC':
            case 'HRM-FAC':
                return (
                    <ButtonTheme
                    disabled={(Array.isArray(dataSource) && dataSource.length > 0) ? false : true}
                    buttonStyle={{ color: colors.white, background: colors.wraning, bordered: 0 }}
                        buttonText={TEXT_BTN.RECALL_REPORT_OT}
                        onClick={() => {
                            Modal.confirm({
                                title: 'คุณ้องการยืนยันเอกสารใช่หรือไม่ ?',
                                onOk: async () => {
                                    await manageReport({ fieldname: REPORT_OPTION.UPDATE_STATUS_RECALL, value: { ...searchResult, selectKeys } })
                                    // await window.location.reload()
                                }
                            })
                        }} />

                );
            default:
                break;
        }
    }
    switch (byAssessment) {
        case TYPE_ASSESSMENT.MONTH:
        case TYPE_ASSESSMENT.DAY:
            //console.log('reportFactory', reportState.reportFactory)
            return (
                <Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button type="primary"
                                disabled={(Array.isArray(dataSource) && dataSource.length > 0) ? false : true}
                                onClick={() => { browserHistory.push(PATHNAME.ASSESSMENT_FACTORY_BY_DAY) }}>
                                {'Print'}
                            </Button>
                            <ExportExcel
                              ref={refExport}
                                columns={[
                                    {
                                        title: 'ข้อมูลพนักงาน', children: [
                                            { title: 'รหัสพนักงาน', field: 'employeeId' },
                                            { title: 'ชื่อพนักงาน', field: 'employeeName' },
                                            { title: 'ชื่อเล่น', field: 'NickName' },
                                            { title: 'หัวหน้า', field: 'approvalName1' }
                                        ]
                                    }, {
                                        title: 'น้ำหนักงาน (%)', children: [
                                            { title: `ปริมาณงาน (${weight ? weight.weightVolume : ''})`, field: 'rawScoreVolume' },
                                            { title: `คุณภาพงาน (${weight ? weight.weightQuality : ''})`, field: 'rawScoreQuality' },
                                            { title: `พฤติกรรม (${weight ? weight.weightBehavior : ''})`, field: 'rawScoreBehavior' }
                                        ]
                                    }, {
                                        title: 'Total Score', children: [
                                            { title: 'Score', field: 'totalScore' },
                                            { title: '%', field: 'percentage' },
                                            { title: 'เกรด', field: 'grade' }
                                        ]
                                    },
                                ]}
                                // dataSource={[]}
                                dataSource={dataSource}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <ButtonTheme
                                disabled={(Array.isArray(dataSource) && dataSource.length > 0) ? false : true}
                                buttonStyle={{ color: colors.white, background: colors.success, bordered: 0 }}
                                buttonText={TEXT_BTN.APPROVAL_REPORT_OT}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'คุณ้องการยืนยันเอกสารใช่หรือไม่ ?',
                                        onOk: async () => {
                                            await manageReport({ fieldname: REPORT_OPTION.UPDATE_STATUS_CONFIRM, value: { ...searchResult, selectKeys } })
                                            // await window.location.reload()
                                        }
                                    })
                                }} />
                            {/* <ButtonTheme
                                // buttonStyle={{ background: colors.success, color: colors.white }}
                                disabled={(selectRows.length > 0) ? false : true}
                                buttonText="ยืนยันเอกสาร"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'คุณ้องการยืนยันเอกสารใช่หรือไม่ ?',
                                        onOk: async () => {
                                            await manageReport({ fieldname: REPORT_OPTION.UPDATE_STATUS_CONFIRM, value: { ...searchResult, selectKeys } })
                                            // await window.location.reload()
                                        }
                                    })
                                }} /> */}
                            {renderRecall()}

                        </div>
                    </div>
                    <div style={{ padding: 10 }}>
                        <div style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}> {'รายงานผลการประเมินประสิทธิภาพงานพนักงาน'}</div>
                        <div style={{ display: 'flex', fontSize: 14, fontWeight: 300, justifyContent: 'center', padding: 10 }}>
                            <span style={{ fontWeight: 600, padding: '0 8px' }}> {`ประจำปี : `} </span>
                            <span> {`${SEARCH_OPTION_YEAR[0] || ''} `} </span>
                            {/* <span> {`${renderMonth(SEARCH_OPTION_MONTH[0]) ? renderMonth(SEARCH_OPTION_MONTH[0]).th : '-'} ${SEARCH_OPTION_YEAR[0] || ''} `} </span> */}
                            <span style={{ fontWeight: 600, padding: '0 8px' }}> {`ประเภท : `} </span>
                            <span > {`${renderWithLabel({ label: SEARCH_OPTION_EMPLOYMENT_TYPE[0] || '' }) || '-'} `} </span>
                        </div>
                    </div>
                    {/* <div style={{ fontSize: 18, paddingBottom: 30, fontWeight: 600, textAlign: 'center' }}> {'รายงานผลการประเมินประสิทธิภาพงานพนักงาน (รายวัน)'}</div> */}
                    <ReportByAssessment byAssessment={byAssessment} rowSelection={rowSelection} />
                </Fragment>
            );

        default:
            break;
    }
}

export default ReportKPIsFactory



export const ReportByAssessment = ({
    byAssessment,
    rowSelection
}) => {
    const STRUCTURE = STRUCTURE_KPIS_FACTORY;
    const [pageSize, setPageSize] = useState(100);
    const { reportState } = useContext(ReportContext)
    const dataSearch = searchMonth()

    switch (byAssessment) {
        case TYPE_ASSESSMENT.DAY:
        case TYPE_ASSESSMENT.MONTH:
            return (
                <LayoutTable
                    bordered
                    rowKey={'employeeId'}
                    columns={STRUCTURE.COLUMN_EVALUATION_BY_DAY({
                        dataSource: path(['reportFactory', 'datasource'], reportState),
                        searchDate: dataSearch,
                        weight: {
                            volume: path(['reportFactory', 'datasource', 0, 'weightVolume'], reportState) || '',
                            quality: path(['reportFactory', 'datasource', 0, 'weightQuality'], reportState) || '',
                            behavior: path(['reportFactory', 'datasource', 0, 'weightBehavior'], reportState) || ''
                        }
                        // action: undefined
                    })}
                    dataSource={path(['reportFactory', 'datasource'], reportState)}
                    pagination={{
                        position: 'bottom',
                        pageSize: pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ['100', '500', '1000'],
                        onShowSizeChange: (current, size) => {
                            setPageSize(size)
                        },
                        showTotal: (total, range) => `ทั้งหมด ${total} รายการ`
                    }}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                />
            )
        // return (
        //     <LayoutTable
        //         bordered
        //         rowKey={'employeeId'}
        //         columns={STRUCTURE.COLUMN_EVALUATION_BY_MONTH({
        //             dataSource: path(['reportFactory', 'datasource'], reportState),
        //             searchDate: dataSearch,
        //             // action: undefined
        //         })}
        //         dataSource={path(['reportFactory', 'datasource'], reportState)}
        //         pagination={{
        //             position: 'bottom',
        //             pageSize: pageSize,
        //             showSizeChanger: true,
        //             pageSizeOptions: ['100', '500', '1000'],
        //             onShowSizeChange: (current, size) => {
        //                 setPageSize(size)
        //             },
        //             showTotal: (total, range) => `ทั้งหมด ${total} รายการ`
        //         }}
        //     />
        // )

        default: return null
    }
}