const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');

let encode = function (_str) {
  let strEncode = ''
  if (_str) {
    try {
      strEncode = cryptr.encrypt(_str);
    }
    catch (e) {
      strEncode = _str;
    }
  }



  return strEncode;
}

let decode = function (_str) {
  let strDecode = ''
  if (_str) {
    try {
      strDecode = cryptr.decrypt(_str);
    }
    catch (e) {
      strDecode = _str;
    }
  }

  return strDecode;
}


module.exports = {
  encode: encode,
  decode: decode
};