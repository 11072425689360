import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Col, Row, Form, Container } from "reactstrap";
import { optionSumProbation } from "../common/enum/index";
import ProbationReport from "../components/probation/ProbationReport";
import * as Layouts from "../constants/layouts";
import { INITIAL_FILTER_PROBATION } from "../components/probation/initialProbation";
import {
  APIProbation,
  APIDashboardProbation,
  GET,
  GET_SELECT_OPTION_ALL,
  GET_SELECT_PERMISSION,
  POST,
  SELECT_DASHBOARD_PROBATION,
} from "../service";
import { SetDateAPI } from "../common/setDate";
import { API_URL } from "../components/connectAPI";
import { Spin, Tabs, Modal } from "antd";
import { APIDashboardBenefitsOut } from "../moduleContract/servicesContract/contractConnect";
import { TEXT_LOADING } from "../constants/enums/enumCenter";
import { filterDashboardReportNewEmployee } from "../moduleReport/constantsReport/searchform.js";
import {
  optionProbationStatus,
  optionLocation,
  optionStatusWorkerCode,
} from "../common/enum";
import { RenderForm } from "./../constants/inputs/RenderForm";
const { TabPane } = Tabs;
class ReportNewEmployee extends Component {
  constructor(props) {
    super(props);
    const { locationM, userLogin, premission } = props;
    this.state = {
      loading: false,
      statusMainCode: "",
      userLogin,
      premission,
      initialFilter: INITIAL_FILTER_PROBATION,
      dataSource: [],
      dataSourceOut: [],
      optionSegment: [],
      SegmentTxt: "",
      txtUserSeaching: "",
      txtOption: "",
      locationID: "",
      statusWorkerCode: "",
      statusProbationCode: "",
      locationLogin: locationM,
    };
  }

  componentDidMount() {
    this.fatchAPI();
    //console.log('this.props *************************', this.props)
  }

  fatchAPI = async () => {
    try {
      this.setState({ loading: true });
      const { premission } = this.state;
      //console.log("premission", premission)
      // console.log('componentDidMount ::', this.props)
      // console.log('this.state.premission ', this.state.premission)
      switch (premission) {
        // case 'HR-Super':
        // case 'HR-Admin':
        // case 'HR':
        // case 'HR-HeadDep':
        // case 'HR-AdminOff':
        // case 'Intern':
        // case 'STD':
        case "HRS-OFF":
        case "HRD-OFF":
        case "HRM-OFF":
        case "HRS-FAC":
        case "HRD-FAC":
        case "HRM-FAC":
          this.getSelectOptionAll();
          break;
        default:
          await this.getProbationRole();
          break;
      }
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getSelectOptionAll = async () => {
    try {
      let res = await GET(GET_SELECT_OPTION_ALL());
      const { result, success } = res;
      if (success) {
        let departmentLists = [];
        result["department"].map((el) => {
          departmentLists.push({ label: el.depNameTH, value: el.depNo });
        });
        let optionSegment = [
          { value: "", label: "----- ทั้งหมด -----" },
          ...departmentLists,
        ];
        let SegmentTxt = "all";
        this.setState({ optionSegment, SegmentTxt });
      }
    } catch (err) {
      console.log("err", err.message);
      this.setState({ loading: false });
    }
  };

  //----- Start :: API -----//

  getProbationRole = async () => {
    try {
      this.setState({ loading: true });
      // let pathURL = `${"userId="}${this.state.userLogin}` //${"&role="}${userRole}
      // await APIPermission(pathURL).then((res) => {
      // 	let departmentLists = [];
      // 	res.result.department.forEach(el => {
      // 		departmentLists.push({ label: el.depName, value: el.depNo })
      // 	})
      // 	let optionSegment = departmentLists.concat({ value: "", label: "----- ทั้งหมด -----" }, ...departmentLists)
      // 	console.log('departmentLists', departmentLists)
      // 	let SegmentTxt = "all";
      // 	this.setState({ optionSegment, SegmentTxt })
      // });

      // let pathURL = `${"userId="}${this.state.userLogin}` //${"&role="}${userRole}
      // await APIPermission(pathURL).then((res) => {
      // 	let departmentLists = [];
      // 	res.result.depArr.forEach(el => {
      // 		departmentLists.push({ label: el.depName, value: el.depNo })
      // 	})
      // 	let optionSegment = [{ value: "", label: "----- ทั้งหมด -----" }, ...departmentLists]
      // 	let SegmentTxt = "";
      // 	this.setState({ optionSegment, SegmentTxt })
      // });

      const { userLogin } = this.state;
      const res = await GET(GET_SELECT_PERMISSION(userLogin));
      const { result } = res;
      let departmentLists = [];
      if (result["depArr"].length !== 0) {
        result["depArr"].forEach((el) => {
          departmentLists.push({ label: el.depName, value: el.depNo });
        });
      }
      let optionSegment = [
        ...[{ value: "", label: "----- ทั้งหมด -----" }],
        ...departmentLists,
      ];
      this.setState({ optionSegment });
    } catch (error) {
      console.log("getProbationRole: ", error.message);
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  //----- End :: API -----//

  //----- Start :: Function -----//

  handleFilter = (fieldname, value) => {
    let initialFilter = { ...this.state.initialFilter };
    let fieldNameSet = "";
    switch (fieldname) {
      case "DepNo":
      case "depNo":
        initialFilter = { ...initialFilter, [fieldname]: value };
        fieldNameSet = "SegmentTxt";
        break;
      case "userSeaching":
        initialFilter = { ...initialFilter, [fieldname]: value };
        fieldNameSet = "txtUserSeaching";
        break;

      case "location":
        initialFilter = { ...initialFilter, [fieldname]: value };
        fieldNameSet = "locationID";
        break;

      case "statusWorker":
        initialFilter = { ...initialFilter, [fieldname]: value };
        fieldNameSet = "statusWorkerCode";
        break;

      case "statusProbation":
        initialFilter = { ...initialFilter, [fieldname]: value };
        fieldNameSet = "statusProbationCode";
        break;
      default:
        initialFilter = { ...initialFilter, [fieldname]: value };
        break;
    }
    this.setState({ initialFilter, [fieldNameSet]: value });
  };

  handleLocation = async ({ pathname, state, status }) => {
    try {
      this.setState({ loading: true });
      const { router } = this.props;
      let initialData = null;
      // let pathURL = `${"DocNo="}${state.DocNo}`;
      let payload = { docNo: state.DocNo };
      switch (status) {
        case "update":
          await APIProbation(payload).then((res) => {
            initialData = res.result;
            router.push({ pathname, state: initialData });
          });
          break;
        case "view":
          await APIProbation(payload).then((res) => {
            initialData = res.result;
            router.push({ pathname, state: { ...initialData } });
          });
          break;
        default:
          router.push({ pathname, state });
          break;
      }
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getDataOut = async () => {
    try {
      const {
        initialFilter,
        userLogin,
        premission,
        statusWorkerCode,
        locationID,
        SegmentTxt,
        txtUserSeaching,
      } = this.state;
      let payload = {
        user_login: userLogin,
        locationID: locationID,
        statusWorker: statusWorkerCode,
        SegmentTxt: SegmentTxt,
        statusProbation: 2,
        txtUserSeaching: txtUserSeaching,
        StartDateJob: SetDateAPI(initialFilter.StartDateJob),
        toDateJob: SetDateAPI(initialFilter.toDateJob),
        premission: premission,
      };
      this.setState({ loading: true });
      await APIDashboardBenefitsOut(payload, (res) => {
        if (res) {
          this.setState({ dataSourceOut: res });
        }
        this.forceUpdate();
      });
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getDataPass = async () => {
    try {
      this.setState({ loading: true });
      const {
        initialFilter,
        userLogin,
        premission,
        locationID,
        SegmentTxt,
        statusWorkerCode,
        statusProbationCode,
        txtUserSeaching,
      } = this.state;
      let payload = {
        userlogin: userLogin,
        report: "report",
        locationLogin: locationID,
        // SegmentTxt: SegmentTxt,
        statusWorker: statusWorkerCode,
        statusProbation: statusProbationCode,
        searchUser: txtUserSeaching,
        statusMainCode: initialFilter.statusMainCode || "all",
        startDate: SetDateAPI(initialFilter.StartDateJob),
        // premission: premission
      };

      await APIDashboardProbation(payload, (res) => {
        if (res) {
          this.setState({ dataSource: res });
        }
        this.forceUpdate();
      });
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  getSelectDashboardProbation = async () => {
    try {
      this.setState({ dataSource: [], loading: true });
      const {
        initialFilter,
        userLogin,
        locationLogin,
        locationID,
        SegmentTxt,
        statusWorkerCode,
        statusProbationCode,
        txtUserSeaching,
      } = this.state;
      const objectBody = {
        userlogin: userLogin,
        locationLogin,
        depNo: SegmentTxt || "",
        subDepNo: "",
        statusMainCode: "",
        startDate: SetDateAPI(initialFilter.StartDateJob),
        toDate: SetDateAPI(initialFilter.ToDateJob),
        report: "report",
        statusWorker: statusWorkerCode || "",
        currentStatus: "",
        statusProbation: statusProbationCode || "",
        type: "",
        location: locationID || "",
        company: "",
      };
      const res = await POST(SELECT_DASHBOARD_PROBATION, objectBody);
      const { result } = res;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, i) => ({
          key: `${row.StaffCode}_${i}`,
          ...row,
        }));
      }
      this.setState({ dataSource: mapResult });
    } catch (err) {
      console.log("Error SelectDashboardProbation: ", err);
      Modal.error({ title: "เกิดข้อผิดพลาด", content: `${err.message}` });
      this.setState({ dataSource: [] });
    } finally {
      this.setState({ loading: false });
    }
  };

  onSearch = async () => {
    try {
      await this.setState({
        // done: true,
        loading: true,
      });
      await this.getDataOut();
      //await this.getDataPass()
      await this.getSelectDashboardProbation();
      await this.setState({
        // done: true,
        loading: false,
      });
    } catch (error) {
      console.log({ error });
      await this.setState({
        // done: true,
        loading: false,
      });
    }
  };
  //----- End :: Function -----//
  render() {
    const {
      loading,
      optionSegment,
      txtUserSeaching,
      txtOption,
      initialFilter,
    } = this.state;
    return (
      <Spin spinning={loading} tip={TEXT_LOADING}>
        <Container>
          <Layouts.MainTheme
            header={"Report Probation"}
            component={
              <Form>
                {filterDashboardReportNewEmployee({
                  handlechange: this.handleFilter,
                  options: {
                    optionLocation,
                    optionSegment,
                    optionSumProbation,
                    optionProbationStatus,
                    optionStatusWorkerCode,
                  },
                  txtOption: {
                    txtUserSeaching,
                    txtOption,
                    // txtOptionStatusProbation,
                  },
                  initialFilter,
                  onClick: this.onSearch,
                }).map((row, i) => (
                  <Row key={i}>
                    {row.map((each, ie) => (
                      <Col key={ie} md={each.size} className={each.className}>
                        {/* {this.renderContentInput(each)} */}
                        {RenderForm(each)}
                      </Col>
                    ))}
                  </Row>
                ))}
              </Form>
            }
          ></Layouts.MainTheme>
          <Tabs defaultActiveKey="1">
            <TabPane tab="อยู่ระหว่างดำเนินการ - ผ่านทดลอง" key="1">
              <ProbationReport
                userLogin={this.state.userLogin}
                dataSource={this.state.dataSource}
                handleLocation={this.handleLocation}
              />
            </TabPane>
            <TabPane tab="ไม่ผ่านทดลองงาน" key="2">
              <ProbationReport
                userLogin={this.state.userLogin}
                dataSource={this.state.dataSourceOut}
                handleLocation={this.handleLocation}
              />
            </TabPane>
          </Tabs>
        </Container>
      </Spin>
    );
  }
}

export default ReportNewEmployee;
