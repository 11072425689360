import React from "react"
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { Tag } from 'antd';

export const renderStatusColorInstallment = (code) => {
	switch (code) {
		case 'new1':
		case 'new2':
		case 'new3':
		case 'new4':
			return 'green'
		case 'current5':
			return 'gold'
		case 'out6':
		case 'out7':
			return 'red'
	}
}

export const ColumnRecheckWorkingSaleEmployee = [ // Check จำนวนพนักงาน
	{
		title: "รหัสเซลล์-สาขา",
		dataIndex: "Salecode",
		key: "Salecode",
		align: "center",
		width: 200,
		...filterSearchColumn("Salecode", "รหัสเซลล์-สาขา"),
	},
	{
		title: "รหัสพนักงาน",
		dataIndex: "userId",
		key: "userId",
		align: "left",
		width: 150,
		...filterSearchColumn("userId", "รหัสพนักงาน"),
	},
	{
		title: "Username",
		dataIndex: "Username",
		key: "Username",
		align: "left",
		width: 150,
		...filterSearchColumn("Username", "Username"),
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "fullname",
		key: "fullname",
		align: "left",
		width: 200,
		...filterSearchColumn("fullname", "ชื่อพนักงาน"),
	},
	{
		title: "ชื่อเล่น",
		dataIndex: "nickname",
		key: "nickname",
		align: "center",
		width: 200,
		...filterSearchColumn("nickname", "ชื่อเล่น"),
	},
	{
		title: "บริษัท",
		dataIndex: "company",
		key: "company",
		align: "center",
		width: 150,
		...filterSearchColumn("company", "บริษัท"),
	},
	{
		title: "สถานที่",
		dataIndex: "location",
		key: "location",
		align: "center",
		width: 150,
		...filterSearchColumn("location", "สถานที่"),
	},
	{
		title: "แผนก",
		dataIndex: "Department_Name",
		key: "Department_Name",
		align: "center",
		width: 300,
		...filterSearchColumn("Department_Name", "แผนก"),
	},
	{
		title: "ตำแหน่ง",
		dataIndex: "positionName",
		key: "positionName",
		align: "left",
		width: 300,
		...filterSearchColumn("positionName", "ตำแหน่ง"),
	},
	{
		title: "กลุ่มตำแหน่ง",
		dataIndex: "positionGroup",
		key: "positionGroup",
		align: "left",
		width: 300,
		...filterSearchColumn("positionGroup", "กลุ่มตำแหน่ง"),
	},
	{
		title: "สายอนุมัติ",
		dataIndex: "flowApproveName",
		key: "flowApproveName",
		align: "left",
		width: 400,
		...filterSearchColumn("flowApproveName", "สายอนุมัติ"),
	},
	{
		title: "วันที่เริ่มงาน",
		dataIndex: "statrtDateJob",
		key: "statrtDateJob",
		align: "center",
		width: 150,
		...filterSearchColumn("statrtDateJob", "วันที่เริ่มงาน"),
	},
	{
		title: "วันที่พ้นสภาพ",
		dataIndex: "endDateJob",
		key: "endDateJob",
		align: "center",
		width: 150,
		...filterSearchColumn("endDateJob", "วันที่พ้นสภาพ"),
	},
	{
		title: "สถานภาพพนักงาน",
		dataIndex: "currentStatusName",
		key: "currentStatusName",
		align: "center",
		width: 200,
		...filterSearchColumn("currentStatusName", "สถานภาพพนักงาน"),
	},
	{
		title: "สถานะพนักงาน",
		dataIndex: "stautsWorkerDes",
		key: "stautsWorkerDes",
		align: "center",
		width: 200,
		...filterSearchColumn("stautsWorkerDes", "สถานะพนักงาน"),
	},

	{
		title: "สถานะพนักงานประจำงวด",
		dataIndex: "statusInstallmentName",
		key: "statusInstallmentName",
		align: "center",
		width: 200,
		...filterSearchColumn("statusInstallmentName", "สถานะพนักงานประจำงวด"),
		render: (text, record) => {
			return <Tag color={renderStatusColorInstallment(record.statusWorkerInstallment)}>{text}</Tag>
		}
	},
];
