import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col } from "antd";
import {
  optionSelectLocation,
  optionStatusWorkBenefit,
  useOptionSelect,
} from "../../constants/OptionSelect";
import {
  GET_UserLogin,
  toUndefined,
  sumColumnWidth,
  subStringDate,
} from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import {
  POST,
  POST_SELECT_DASHBOARD_PROBATION,
  POST_UPDATE_RECEIVED,
} from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportTrackProbation } from "../../constants/HeaderExcel";
import { ComponentSearchDashboardTrackContract } from "../../constants/ProbationEmployee/Components";
import { ColumnSearchReportTrackContract } from "../../constants/ProbationEmployee/Columns";
import { useConfirm } from "../modal/CustomConfirm";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useDatePicker } from "../datepicker/DatePickerAntd";
import moment from "moment";

const FormTrackContract = () => {
  const [form] = Form.useForm();
  const { userlogin, location } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({
    location: location === "FAC" ? "FAC" : undefined,
    statusMainCode: "2",
  });
  const [dataTable, setDataTable] = useState([]);
  const [probationDate, setProbationDate] = useState("");
  const [probationValue, setProbationValue] = useState({});
  const [receivedDocNo, setReceivedDocNo] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect(
      "department",
      useFixedQueryString(ChangeValue, ["companyCode"])
    ),
    statusMainCode: useOptionSelect("statusMainCode").filter(
      (row) => +row.value !== 3
    ),
    statusWorker: optionStatusWorkBenefit,
    searchUser: useOptionSelect(
      "empAll",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
  };
  // [START] Handle Form Antd
  const onFinish = (values) => {
    const {
      company,
      depNo,
      statusMainCode,
      statusWorker,
      startDate,
      toDate,
      searchUser,
    } = values;
    const objectBody = {
      userlogin,
      locationLogin: location,
      depNo: depNo || "",
      subDepNo: "",
      statusMainCode: statusMainCode || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
      report: "",
      statusWorker: statusWorker || "",
      currentStatus: "",
      statusProbation: "",
      type: "contract",
      location: values.location || "",
      company: company || "",
      searchUser: searchUser || "",
      pageType: "trackProbation",
      contractExtension: "",
    };

    searchReportTrackProbation(objectBody);
  };

  const onReset = () => {
    form.resetFields();
    setChangeValue({});
    setDataTable([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const searchReportTrackProbation = async (objectRow) => {
    try {
      const {
        company,
        depNo,
        statusMainCode,
        statusWorker,
        startDate,
        toDate,
        searchUser,
      } = objectRow;
      const objectBody = {
        userlogin,
        locationLogin: location,
        depNo: depNo || "",
        subDepNo: "",
        statusMainCode: statusMainCode || "",
        startDate: startDate || "",
        toDate: toDate || "",
        report: "",
        statusWorker: statusWorker || "",
        currentStatus: "",
        statusProbation: "",
        type: "contract",
        location: objectRow.location || "",
        company: company || "",
        searchUser: searchUser || "",
        pageType: "trackProbation",
      };
      setLoadingScreen(true);
      const response = await POST(POST_SELECT_DASHBOARD_PROBATION, objectBody);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: index,
          ...row,
          StartDateJob: subStringDate(row.StartDateJob),
          expProDate: subStringDate(row.expProDate),
          probationEndDate: subStringDate(row.probationEndDate),
          HR_ReceivedDate: subStringDate(row.HR_ReceivedDate),
          date_HEA: subStringDate(row.date_HEA),
          date_PVD: subStringDate(row.date_PVD),
          date_SOS: subStringDate(row.date_SOS),
        }));
      } else {
        useNotification({ type: "error", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => setLoadingScreen(false),
      });
      console.log("Error searchReportTrackProbation: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const updateReceivedDoc = async () => {
    try {
      console.log("probationValue", probationValue);
      const { probationStatus, probationGrade, contractExtension } =
        probationValue;
      if (!probationDate) {
        useAlert({ type: "warning", title: "กรุณาเลือก วันผ่านทดลองงาน" });
      }
      setLoadingScreen(true);
      const objectBody = {
        docNo: receivedDocNo,
        probationEndDate: probationDate
          ? probationDate.format("YYYY-MM-DD")
          : "",
        userlogin,
        recruiterGrade: "",
        recruiterScore: "",
        endDate: "",
        probationStatus: probationStatus || "",
        probationGrade: probationGrade || "",
        remarkByHr: "",
        contractExtension: contractExtension || "",
      };
      const response = await POST(POST_UPDATE_RECEIVED, objectBody);
      const { success, message } = response;
      if (success) {
        setModalShow(false);
        useAlert({
          type: "success",
          title: message,
          Fn: () => searchReportTrackProbation(ChangeValue),
        });
      }
    } catch (err) {
      console.log("Error updateReceivedDoc: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => setLoadingScreen(false),
      });
    } finally {
      setLoadingScreen(false);
    }
  };

  const contentModal = () => {
    return (
      <>
        <Row
          gutter={[16, 16]}
          justify="start"
          align="middle"
          style={{ marginBottom: 30 }}
        >
          <Col span={8}>วันผ่านทดลองงาน:</Col>
          <Col span={16}>
            {useDatePicker({
              fnChange: (date, string) => setProbationDate(date),
              componentProps: {
                style: { width: "100%" },
                value: probationDate,
              },
            })}
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="end" align="middle">
          <Col span={8}>
            {useButton({
              call: "normal",
              moreProps: { label: "ยืนยัน", type: "primary" },
              fn: () => useConfirm({ FnOK: () => updateReceivedDoc() }),
              isDisable: probationDate ? false : true,
            })}
          </Col>
        </Row>
      </>
    );
  };

  const showModal = (record) => {
    const {
      statusProbationLast,
      probationGrade,
      probationEndDateDF,
      contractExtension,
    } = record;
    setModalShow(true);
    setProbationDate(
      probationEndDateDF ? moment(probationEndDateDF, "YYYY-MM-DD") : moment()
    );
    setProbationValue({
      contractExtension,
      probationStatus: statusProbationLast,
      probationGrade: probationGrade,
    });
    setReceivedDocNo(record.DocNo);
  };

  const hideModal = () => {
    setModalShow(false);
    setProbationDate("");
    setProbationValue({});
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    //console.log(name, " : ", value)
    let setUndifined = {};
    let setName = {};
    switch (name) {
      case "company":
        setName = { companyCode: value };
        if (location === "FAC") {
          setUndifined = toUndefined(["depNo", "searchUser"]);
        } else {
          setUndifined = toUndefined(["location", "depNo", "searchUser"]);
        }
        break;
      case "location":
        setUndifined = toUndefined(["depNo", "searchUser"]);
        break;
      case "depNo":
        setUndifined = toUndefined(["searchUser"]);
        break;
    }
    form.setFieldsValue(setUndifined);
    setDataTable([]);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndifined,
      ...setName,
      [name]: value,
    }));
  };
  // [END] Functions
  return (
    <>
      <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
        <RenderMainModal
          show={modalShow}
          onCancel={hideModal}
          txtTitle="ยืนยันรับเอกสารทดลองงาน"
          ModalContent={contentModal()}
          width={450}
        />
        <Form
          form={form}
          onFinish={onFinish}
          {...configForm}
          initialValues={{
            statusMainCode: "2",
            statusWorker: "Temp",
            location: location === "FAC" ? "FAC" : undefined,
          }}
        >
          <Card
            title="รายการค้างรับเอกสารทดลองงาน (สัญญาจ้าง)"
            style={{ marginBottom: 30 }}
            extra={
              <Space>
                {useButton({ call: "search" })}
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Space>
            }
          >
            {useRenderFormItem({
              constantComponent: ComponentSearchDashboardTrackContract({
                options: optionSearch,
                handleChange: onChangeValue,
                isDisable: {
                  statusWorker: true,
                  location: location === "FAC" ? true : false,
                },
              }),
            })}
          </Card>
          {dataTable.length !== 0 && (
            <Card
              extra={useExportExcel({
                header: Header_ReportTrackProbation,
                dataset: dataTable,
                filename: "รายการค้างรับเอกสารทดลองงาน (สัญญาจ้าง)",
              })}
            >
              <CustomTableAtnd
                HeadTable={ColumnSearchReportTrackContract({
                  fnReceive: showModal,
                })}
                DataTable={dataTable}
                moreProps={{
                  // rowSelection: {
                  //     onChange: (selectedRowKeys) =>
                  //         setSelectRowKey(selectedRowKeys),
                  //     getCheckboxProps: (record) => ({
                  //         disabled: record.statusTransfer === 0,
                  //     }),
                  //     selectedRowKeys: selectRowKey,
                  // },
                  scroll: {
                    x:
                      sumColumnWidth(ColumnSearchReportTrackContract({})) ||
                      2050,
                    y: 500,
                  },
                }}
              />
            </Card>
          )}
        </Form>
      </Spin>
    </>
  );
};

export default FormTrackContract;
