import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin, toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DASHBOARD_CF_BENEFIT_CONTRACT, POST_UPDATE_END_DATE_PROBATION } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ManageDocProbationContract } from "../../constants/HeaderExcel";
import { ComponentManageBenefitContract } from "../../constants/ProbationEmployee/Components";
import { ColumnManageBenefitContract } from "../../constants/ProbationEmployee/Columns";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useConfirm } from "../modal/CustomConfirm";
import { useDatePicker } from "../datepicker/DatePickerAntd";

const FormManageBenefitContract = () => {
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const { userlogin } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [dataRow, setDataRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    // [END] States

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        searchUser: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    }
    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { company, location, depNo, subDepNo, startDate, toDate, searchUser } = values
        const objectBody = {
            userId: searchUser || "",
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
        }
        searchDashboardCFBenefitsContract(objectBody)
    };

    const onFinishModalPass = (values) => {
        const { probationEndDate } = values
        const { userId } = dataRow
        const objectBody = {
            userId: userId || "",
            probationEndDate: probationEndDate ? probationEndDate.format("YYYY-MM-DD") : "",
            userlogin,
        }
        // console.log("onFinishModalPass", objectBody)
        useConfirm({ FnOK: () => updateEndDateProbation(objectBody) })
    }

    const onReset = () => {
        form.resetFields();
        setChangeValue({});
        setDataTable([])
    };
    // [END] Handle Form Antd

    // [START] REST API
    const searchDashboardCFBenefitsContract = async (objectRow) => {
        try {
            setLoadingScreen(true);
            const response = await POST(POST_SELECT_DASHBOARD_CF_BENEFIT_CONTRACT, objectRow);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    startDateJob: subStringDate(row.startDateJob),
                }))
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" })
            }
            setDataTable(mapResult)
        } catch (err) {
            useAlert({ type: "catch", content: `${err.message}` });
            console.log("Error Search Dashboard CF Benefits Contract: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const updateEndDateProbation = async (objectBody) => {
        try {
            setLoadingScreen(true);
            const response = await POST(POST_UPDATE_END_DATE_PROBATION, objectBody);
            const { success, message } = response
            if (success) {
                useAlert({ type: "success", title: message, Fn: () => window.location.reload() })
            } else {
                useAlert({ type: "info", title: "ดำเนินการสำเร็จกรุณาลองใหม่อีกครั้ง" })
            }
        } catch (err) {
            console.log("Error updateEndDateProbation: ", err);
            useAlert({ type: "catch", content: `${err.message}` });
        } finally {
            setLoadingScreen(false);
        }
    };

    const contentModalTakeBenefit = () => {
        return (
            <Form form={formUpdate} onFinish={onFinishModalPass}>
                <Row gutter={[16, 16]} justify="start" align="middle">
                    <Col span={24}>
                        <Form.Item
                            name={"probationEndDate"}
                            label={"วันผ่านทดลองงาน"}
                            rules={[{ required: true, message: "กรุณเลือก วันที่ผ่านทดลองงาน" }]}
                        >
                            {useDatePicker({
                                //fnChange: (date, string) => setProbationDate(date),
                                componentProps: {
                                    placeholder: "เลือก วันที่ผ่านทดลองงาน",
                                    style: { width: "100%" },
                                }
                            })}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="end" align="middle">
                    <Col span={8}>
                        {useButton({
                            call: "normal",
                            moreProps: { label: "ยืนยัน", type: "primary" },
                            fn: () => formUpdate.submit(),
                        })}
                    </Col>
                </Row>
            </Form>
        )
    }

    const onShowModal = (record) => {
        setDataRow(record);
        // formUpdate.setFieldsValue({ probationEndDate: moment(record.startDateJob) })
        setShowModal(true);
    };

    const hideModal = () => {
        setDataRow({});
        formUpdate.resetFields();
        setShowModal(false);
    };
    // [END] REST API

    // [START] Functions
    const onChangeValue = (name, value) => {
        let setUndifined = {}
        let setName = {}
        switch (name) {
            case "company":
                setName = { companyCode: value }
                setUndifined = toUndefined(["location", "depNo", "searchUser"]);
                break;
            case "location":
                setUndifined = toUndefined(["depNo", "searchUser"]);
                break;
        }
        form.setFieldsValue(setUndifined);
        setDataTable([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndifined,
            ...setName,
            [name]: value,
        }));
    };
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <RenderMainModal
                show={showModal}
                onCancel={() => hideModal()}
                txtTitle={"รับสวัสดิการ"}
                ModalContent={contentModalTakeBenefit()}
                width={500}
            />
            <Form form={form} onFinish={onFinish}  {...configForm} >
                <Card
                    title="ยืนยันผ่านทดลองพนักงานสัญญาจ้าง"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentManageBenefitContract({
                            options: optionSearch,
                            handleChange: onChangeValue
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        extra={
                            useExportExcel({
                                header: Header_ManageDocProbationContract,
                                dataset: dataTable,
                                filename: "ยืนยันผ่านทดลองพนักงานสัญญาจ้าง",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnManageBenefitContract({ showModal: onShowModal })}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnManageBenefitContract({})) || 2050,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    );
};

export default FormManageBenefitContract;
