import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_TURN_OVER_RATE } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportResignRate } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { ColumnReportResignRate } from "../../constants/Reports/Columns/ColumnReportResignRate";
import { ComponentReportResignRate, DEFAULT_REPORT_RESIGN_RATE } from "../../constants/Reports/Components/ComponentReportResignRate";

const FormReportResignRate = () => {
    const [formReportResign] = Form.useForm();
    const [ChangeValue, setChangeValue] = useState(DEFAULT_REPORT_RESIGN_RATE);
    const [dataTable, setDataTable] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(false);

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        userId: useOptionSelect("empAll", useFixedQueryString({ ...ChangeValue, block: 1 }, ["companyCode", "location", "block"])),
    }

    const onFinish = (values) => {
        // console.log(values)
        const { company, location, depNo, subDepNo, startDate, toDate, userId } = values
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            userId: userId || "",
        }
        selectDataTurnoverRate(objectBody)
    };

    const onReset = () => {
        formReportResign.resetFields();
        setDataTable([]);
        setChangeValue(DEFAULT_REPORT_RESIGN_RATE);
    };

    const selectDataTurnoverRate = async (objectValue) => {
        try {
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_TURN_OVER_RATE, objectValue);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                    StartDateJob: subStringDate(row.StartDateJob),
                    EndDateJob: subStringDate(row.EndDateJob),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Turn over Rate: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const onChangeValue = (name, value) => {
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        formReportResign.setFieldsValue(setUndefined);
        setDataTable([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };

    return (
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
            <Form form={formReportResign} onFinish={onFinish} {...configForm}>
                <Card
                    title="ค้นหารายงานอัตราการลาออก"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportResignRate({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานอัตราการลาออก"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportResignRate,
                                dataset: dataTable,
                                filename: "รายงานอัตราการลาออก",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnReportResignRate}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnReportResignRate) || 2050,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    )
}

export default FormReportResignRate
