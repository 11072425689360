import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { REPORT_OPTION, MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { TABLE_SUMMARY_AVG_SCORE_BY_LINE } from '../commonsEvac/structuresEvac/tableEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider';
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider';
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider';
import { TEXT_BUTTON } from '../../constants/buttons';
import { ButtonTheme } from '../../moduleKPIs/common/buttons';
import { API_URL } from '../../components/connectAPI';
import { downloadFile } from '../../constants/functions';

const SummaryAvgScoreByLine = ({ reportBy }) => {
    const { userLogin } = useContext(ManageEvacContext);
    const { masterEvacState } = useContext(MasterEvacContext);
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext);
    const { summaryAvgByLine, headSummary } = reportEvacState;

    const [loading, setLoading] = useState(false);
    const [premissionMAN, setPremissionMAN] = useState(false);
    useEffect(() => {
        async function fetchAPI() {
            try {
                const API = API_URL + "/HRM_API/evac/selectDataPermissionManager"
                await setLoading(true)
                await fetch(API).then((res) => res.json()).then((results) => {
                    const arrData = results.result

                    const findPermission = arrData.findIndex(function (item, i) {
                        return item.ID_User === userLogin.userId
                    });
                    if (findPermission > 0) {
                        setPremissionMAN(true)
                    }
                }).catch(ex => {
                    console.log("error gate ttt");
                    setLoading(false)
                })
                await setLoading(false)
            } catch (error) {
                console.log({ error })
                await setLoading(false)
            }
        }
        fetchAPI()
    }, [])
    return (
        <div>

            <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    type="primary"
                    style={{ padding: 10 }}
                    buttonText={TEXT_BUTTON.SEARCH}
                    onClick={async () => {
                        await setLoading(true)
                        await getReportEvac({
                            fieldname: REPORT_OPTION.SUMMARY_AVG_SCORE_BY_LINE,
                            payload: {
                                "year": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                                "quarter": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                                "formId": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_FORM_EVAC}`],
                            }
                        })
                        await setLoading(false)
                    }}
                />
            </div>
            <Spin tip={'กำลังโหลด'} spinning={loading}>
                {
                    <Row style={{ paddingLeft: 15 }}>
                        <Col span={8}> จำนวนส่ง : {`${headSummary ? headSummary.totalSend : 0} รายการ`}  </Col>
                        <Col span={8} style={{ textAlign: 'center' }}> จำนวนตอบกลับ : {`${headSummary ? headSummary.totalReply : 0} รายการ`}   </Col>
                        <Col span={8} style={{ textAlign: 'right', paddingRight: 20 }}> จำนวนผู้ไม่ประสานงาน : {`${headSummary ? headSummary.frequencyWork : 0} รายการ`}  </Col>
                    </Row>
                }
                {
                    // (premissionMAN) &&
                    <div style={{ position: 'absolute', top: '-68px', right: '7px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <ButtonTheme
                            buttonText={"Export ข้อมูลดิบ"}
                            onClick={async () => {
                                try {
                                    await downloadFile('/images/evaluation/ExcelRefreshReportRawDataEvaluation.xlsx', 'all')
                                } catch (error) {
                                    console.log({ error })
                                }
                            }}
                        />
                    </div>}
                <LayoutTable
                    scroll={{ x: 600 }}
                    bordered
                    style={{ padding: 10 }}
                    rowKey="formId"
                    dataSource={summaryAvgByLine}
                    columns={TABLE_SUMMARY_AVG_SCORE_BY_LINE({
                        userLogin: userLogin,
                        lastSource: summaryAvgByLine.length,
                        searchData: {
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                            formId: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_FORM_EVAC}`]
                        }
                    })}
                    pagination={{
                        pageSize: 100,
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`

                    }}
                />
            </Spin>
        </div>
    )
}

export default SummaryAvgScoreByLine