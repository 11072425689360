import React from "react";
import {
  //TODO
  SearchOutlined,
  CheckOutlined,
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  DownloadOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  ArrowRightOutlined,
  PrinterOutlined,
  CloseOutlined,
  CheckSquareOutlined,
  SwapOutlined,
} from "@ant-design/icons";

export const buttonMap = {
  DEFAULT: {
    title: "DEFAULT",
    bgColor: "grey",
  },
  COLUMN_MANAGE: {
    title: "การแสดงผล",
    bgColor: "grey",
    icon: <CheckSquareOutlined />,
  },
  SEARCH: {
    title: "ค้นหา",
    bgColor: "#03A9F4",
    //icon: <SearchOutlined />,
  },
  EDIT: { title: "แก้ไข", bgColor: "#FFAB00", icon: <EditFilled /> },
  REMOVE: { title: "ลบ", bgColor: "#E53935", icon: <DeleteFilled /> },
  VIEW: {
    title: "ดู",
    bgColor: "#03A9F4",
    //icon: <ArrowRightOutlined />,
  },
  CLEAR: { title: "เคลียร์", bgColor: "grey", icon: <ClearOutlined /> },
  DOWNLOAD: {
    title: "ดาวน์โหลด",
    bgColor: "green",
    icon: <DownloadOutlined />,
  },
  EXPORT_EXCEL: {
    title: "Export Excel",
    bgColor: "green",
    //icon: <DownloadOutlined />,
  },
  PRINT: {
    title: "พิมพ์เอกสาร",
    bgColor: "#03A9F4",
    icon: <PrinterOutlined />,
  },
  SAVE: {
    title: "บันทึก",
    bgColor: "#03A9F4",
    //bgColor: '#4CAF50',
    icon: <SaveOutlined />,
  },
  CANCEL: {
    title: "ยกเลิก",
    bgColor: "grey",
    icon: <RollbackOutlined />,
  },
  BACK: { title: "กลับ", bgColor: "grey", icon: <RollbackOutlined /> },
  CREATE: {
    title: "สร้าง",
    bgColor: "#4CAF50",
    icon: <PlusCircleOutlined />,
  },
  ADD: {
    title: "เพิ่ม",
    bgColor: "#03A9F4",
    icon: <PlusCircleOutlined />,
  },
  UPLOAD_FILE: {
    title: "อัพโหลด",
    bgColor: "#47A5FC",
    icon: <UploadOutlined />,
  },
  APPROVE: {
    title: "อนุมัติ",
    bgColor: "#03A9F4",
    //bgColor: '#4CAF50',
    icon: <CheckOutlined />,
  },
  NOT_APPROVE: {
    title: "ไม่อนุมัติ",
    bgColor: "#E53935",
    icon: <CloseOutlined />,
  },
  //Custom
  TRANSFER: {
    title: "โอนย้าย",
    bgColor: "#b366ff",
    icon: <SwapOutlined />,
  },
};
