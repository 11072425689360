import React, { useEffect, useState } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin, subStringDate, sumColumnWidth } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { browserHistory } from "react-router";
import { useConfirm } from "../modal/CustomConfirm";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentEditCrime } from "../../constants/ManageCrime/Components/ComponentSearchReportCrime";
import { ColumnEditCrime } from "../../constants/ManageCrime/Columns/ColumnSearchReportCrime";
import { POST, POST_SELECT_DATA_CRIME_LINE, POST_SELECT_DATA_CRIME_MULTIPLE } from "../../../service";

export const FormEditCrime = (props) => {
  const [form] = Form.useForm();
  const { docNo } = props.params;
  const { userlogin } = GET_UserLogin();

  const [changeAddValue, setChangeAddValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(() => {
    selectDataCrimeLine();
  }, []);

  // [START] Handle Form Antd
  const onFinish = (values) => {
    if (!changeAddValue) return null
    let objectData = {};
    let lineData = [];
    const { receivedDate, sendDate, docCrime, externalDoc } = values;
    if (docCrime.fileList.length === 0) {
      useAlert({ type: "warning", content: `กรุณาเลือกไฟล์กดก่อน "บันทึก"` });
    } else {
      lineData = dataTable.map((row) => ({
        refDocNo: values.docNo,
        reflineNum: row.reflineNum,
        resultCrime: values[`result_${row.userId}`],
        remark: values[`remark_${row.userId}`],
      }));
      objectData = {
        headerData: {
          docNo: values.docNo,
          externalDoc,
          sendDate: sendDate.format("YYYY-MM-DD"),
          receivedDate: receivedDate.format("YYYY-MM-DD"),
          userlogin,
        },
        fileName: values.docNo,
        docCrime: docCrime.fileList[0].originFileObj,
        lineData,
      };
      useConfirm({
        FnOK: () => insertCrimeMultiple(objectData),
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    selectDataCrimeLine();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const selectDataCrimeLine = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const response = await POST(POST_SELECT_DATA_CRIME_LINE, { docNo });
      const { result } = response;
      let mapData = [];
      if (result.length !== 0) {
        mapData = result.map((row) => ({
          key: `${row.userId}`,
          ...row,
          prepareDate: subStringDate(row.prepareDate),
          birthday: subStringDate(row.birthday),
        }));
      } else {
        useNotification({ type: "info", desc: "ไม่พบข้อมูล" });
      }
      form.setFieldsValue({ docNo });
      setDataTable(mapData);
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Crime Line: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const insertCrimeMultiple = async (value) => {
    try {
      setLoadingScreen(true);
      const formData = new FormData();
      const { headerData, fileName, docCrime, lineData } = value;
      formData.append("headerData", JSON.stringify(headerData));
      formData.append("fileName", fileName);
      formData.append("docCrime", docCrime);
      formData.append("lineData", JSON.stringify(lineData));
      const response = await POST(POST_SELECT_DATA_CRIME_MULTIPLE, formData);
      const { success } = response
      if (success) {
        useAlert({
          type: "success",
          content: "การบันทึกข้อมูลสำเร็จ",
          Fn: () => browserHistory.push("/ManageCrime"),
        });
      } else {
        useAlert({
          type: "info",
          title: "การบันทึกข้อมูลสำเร็จ",
          content: `กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Update Crime: ", err);
      useAlert({ type: "catch", content: err.message, Fn: () => setLoadingScreen(false) });
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    setChangeAddValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const optionCrime = useOptionSelect("crimeResult");
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} >
        <Card title="แก้ไขผลการส่งอาชญากรรม" style={{ marginBottom: 30 }}>
          {dataTable.length !== 0 && (
            <>
              {useRenderFormItem({
                constantComponent: ComponentEditCrime({
                  handleChange: onChangeValue,
                  isDisable: { docNo: true }
                }),
              })}
              <CustomTableAtnd
                HeadTable={ColumnEditCrime(optionCrime)}
                DataTable={dataTable}
                moreProps={{
                  scroll: {
                    x: sumColumnWidth(ColumnEditCrime(optionCrime)),
                    y: 500,
                  },
                }}
              />
              <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify="center" align="bottom">
                <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                  <Form.Item>{useButton({ call: "save" })}</Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                  <Form.Item>
                    {useButton({
                      call: "normal",
                      fn: onReset,
                      moreProps: { label: "เรียกข้อมูลเดิม" },
                    })}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Form>
    </Spin>
  );
};

export default FormEditCrime;
