import React from "react";
import { DocumentDetail } from "../style";
import { TextSmall } from "../../../../components/global/text";
import {
  generalDetailAll
} from "../forms/generalDetail";
import { RenderForm } from "../../../../components/global/forms";

const GeneralDocumentDetail = ({
  control,
  setValue,
  getValues,
  options = {
    optionsCompanyTH: [],
    optionsPositionTH: [],
    optionsDepartmentTH: [],
    optionsSigner: [],
    optionsDocType: []
  }
}) => {
  const { docType, contractRound } = getValues()
  let info = ''
  if (docType == 'TEMP') {
    info = (<p style={{color: "red"}}>**ต่อสัญญาครั้งที่ {contractRound || 1} </p>)
  }
  return (
        <DocumentDetail
          title={<TextSmall bold={false} text={"สัญญาจ้างชั่วคราว / สัญญาจ้างประจำ"} />}
        >
          {info}

          <RenderForm
            control={control}
            forms={generalDetailAll(setValue, getValues, options)}
            setValue={setValue}
          />
        </DocumentDetail>
  
  );
};

export default  GeneralDocumentDetail

