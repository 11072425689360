import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API_URL, URL_IMAGE } from "../components/connectAPI";
import { loadingPage } from "../components/loadingPage";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from "react-datepicker";
import { Link, browserHistory } from "react-router";
import { SetDateAPI } from "../common/setDate";
import { APIInsertProbationHistory, GET, GET_SELECT_OPTION_ALL } from "../service";
import ModalAntd from 'antd/lib/modal';
class ReportEmployee extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown_Department: "",
			showDropdown_subDepartment: "",
			CompanyTxt: "",
			DepTxt: "",
			UserIDTxt: "",
			showDropdown_StatusWorker: "",
			statusWorkerID: "",
			loadData: [],
			done: undefined,
			subDepTxt: '',
			locationTxt: '',
			modal: false,
			startProbationDate: '',
			userIdInClickProbation: '',
			userLogin: this.props.userLogin,
		};
	}

	componentDidMount() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				let subDepartmentLists = [];
				let statusWorkerLists = [];
				result['department'].map(el => {
					departmentLists.push({ label: el.depNameTH, value: el.depNo })
				})
				result["subDep"].forEach(el => {
					subDepartmentLists.push({
						label: el.subDepNo + "::" + el.subDepName,
						value: el.subDepNo
					})
				});
				result["statusWorker"].forEach(el => {
					statusWorkerLists.push({
						label: el.description,
						value: el.code
					});
				});
				this.setState({
					showDropdown_Department: departmentLists,
					showDropdown_StatusWorker: statusWorkerLists,
					showDropdown_subDepartment: subDepartmentLists,
					done: true
				});
				// this.setState({ optionSegment })
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	search = () => {
		var _CompanyTxt = this.state.CompanyTxt;
		var _DepTxt = this.state.DepTxt;
		var _UserIDTxt = this.state.UserIDTxt;
		var _statusWorkerID = this.state.statusWorkerID;
		let _subDep = this.state.subDepTxt;
		let _locationTxt = this.state.locationTxt;
		this.setState({ done: undefined });
		var API =
			API_URL +
			"/HRM_API/select_worker?company=" + _CompanyTxt + "&&dep=" + _DepTxt + "&&subDep=" + _subDep + "&&location=" + _locationTxt + "&&userID=" + _UserIDTxt + "&&statusWorker=" + _statusWorkerID;
		// console.log(API);
		fetch(API)
			.then(res => res.json())
			.then(results => {
				// console.log('results');
				// console.log(results);
				if (results["data"] !== 0) {
					results["data"].forEach((element, index, array) =>
						this.setState({ loadData: array })
					);
				} else {
					this.setState({ loadData: [] });
				}
				//this.setState({ done: true, CompanyTxt: "DP", DepTxt: "", statusWorkerID: "Full", UserIDTxt: "" });
				this.setState({ done: true, CompanyTxt: "", DepTxt: "", statusWorkerID: "", UserIDTxt: "" });
			})
			.catch(ex => {
				console.log("error gate ttt");
				// this.setState({ visible: true });
			});
	};

	onChangeCompany = e => {
		this.setState({ CompanyTxt: e.target.value });
		// console.log(e.target.value)
	};

	onChangeDep = e => {
		this.setState({ DepTxt: e.target.value });
		// console.log(e.target.value)
	};

	onChangeSubDep = e => {
		this.setState({ subDepTxt: e.target.value });
		// console.log(e.target.value)
	};
	onChangelLocation = e => {
		this.setState({ locationTxt: e.target.value });
	}
	onChangeUserID = e => {
		this.setState({ UserIDTxt: e.target.value });
		// console.log(e.target.value)
	};

	onChangeStatusWorker = e => {
		this.setState({ statusWorkerID: e.target.value });
		// console.log(e.target.value)
	};

	openEditWorker = e => {
		var _userID = e.target.id;
		window.open("/UpdateEmployee/" + _userID);
	};

	openModel = (e) => {
		let _userID = e.target.id;
		this.setState({
			modal: !this.state.modal,
			userIdInClickProbation: _userID
		});
	}

	toggleModal = (e) => {
		this.setState({
			modal: !this.state.modal,
			startProbationDate: ''
		});
	}

	startProbationChange = (date) => {
		this.setState({
			startProbationDate: date
		});
	}

	saveCreateProbationHistory = async (e) => {
		let startProbationDate = this.state.startProbationDate

		let userProbation = this.state.userIdInClickProbation
		let data = {
			startProbationDate: SetDateAPI(startProbationDate),
			userId: userProbation,
			probationStatus: 0,
			userCreated: this.state.userLogin
		}

		return await APIInsertProbationHistory(data).then((res) => {
			if (res.result === true) {
				// alert(`${'เพิ่มช่วงทดลองงานเรียบร้อยแล้ว'}`)

				// this.setState({
				// 	modal: !this.state.modal,
				// 	startProbationDate: ''
				// });
				// browserHistory.push('/ReportEmployee')

				ModalAntd.success({
					title: "เพิ่มช่วงทดลองงานเรียบร้อยแล้ว",
					onOk: () => {
						this.setState({
							modal: !this.state.modal,
							startProbationDate: ''
						});
						browserHistory.push('/ReportEmployee');
					}
				})
			} else {
				// alert(`${'Insert Error!!'}`)
				ModalAntd.error({ title: "Insert Error!!" })
			}
		})
	}

	render() {
		const products = this.state.loadData;
		const columns = [
			{
				// dataField: "ID_User",
				text: "รหัสพนักงาน",
				sort: true,
				formatter: (cellContent, row) => {
					return (
						<div>
							<Link target='_blank' to={URL_IMAGE + row.ID_User + '.jpg'}><b>{row.ID_User}</b>        </Link>
						</div>
					)
				}
			},
			{
				dataField: "Username",
				text: "Username",
				sort: true,
				style: {
					//   whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "Name",
				text: "ชื่อ-สกุล",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "NickName",
				text: "ชื่อเล่น",
				sort: true,
				style: {
					//   whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "Department_Name",
				text: "แผนก",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "subDepName",
				text: "แผนกย่อย",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "statusWorker",
				text: "สถานะ",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},

			{
				dataField: "PositionNameEN",
				text: "ตำแหน่ง",
				sort: true,
				style: {
					// whiteSpace: 'nowrap',
					// backgroundColor: '#F0FFF0'
				}
			},
			{
				dataField: "Level_WorkerDes",
				text: "กลุ่มตำแหน่ง",
				sort: true,
				style: {
					// whiteSpace: 'nowrap',
					// backgroundColor: '#F0FFF0'
				}
			},
			{
				dataField: "Flow_Name",
				text: "สายการอนุมัติ",
				sort: true,
				style: {
					// whiteSpace: 'nowrap',
					// backgroundColor: '#F0FFF0'
				}
			},
			{
				// dataField: 'ID_User',
				text: "สร้างแบบทดลองงานเพิ่ม",
				sort: true,
				formatter: (cellContent, row) => {
					let addProbation = ''
					if (row.probationStatus === '1') {
						addProbation = <Button
							color="success"
							onClick={this.openModel}
							id={row.ID_User}
						>
							Create
						</Button>
					}
					return (
						<div>
							{""}
							<Col>
								<center>
									{addProbation}
								</center>
							</Col>
						</div>
					);
				},
				style: {
					// whiteSpace: 'nowrap',
					// textAlign: 'right',
					color: "blue"
				}
			},
			{
				// dataField: 'ID_User',
				text: "Action",
				sort: true,
				formatter: (cellContent, row) => {

					return (
						<div>
							{" "}
							<Col>
								<center>
									<Button
										color="danger"
										onClick={this.openEditWorker}
										id={row.ID_User}
									>
										Edit
									</Button>
								</center>
							</Col>
						</div>
					);
				},
				style: {
					// whiteSpace: 'nowrap',
					// textAlign: 'right',
					color: "blue"
				}
			}

		];

		const headerCSV = [
			// { label: "สถานะ", key: "StaffCode" },
			{ label: "รหัสพนักงาน", key: "ID_User" },
			{ label: "username", key: "Username" },
			{ label: "ชื่อ - สกุล", key: "Name" },
			{ label: "ชื่อเล่น", key: "NickName" },
			{ label: "แผนก", key: "Department_Name" },
			{ label: "แผนกย่อย", key: "subDepName" },
			{ label: "สถานะ", key: "statusWorker" },
			{ label: "ตำแหน่ง", key: "PositionNameEN" },
			{ label: "กลุ่มตำแหน่ง", key: "Level_WorkerDes" },
			{ label: "สายอนุมัติ", key: "Flow_Name" },
		];

		const sizePerPageRenderer = ({
			options,
			currSizePerPage,
			onSizePerPageChange
		}) => (
			<div className="btn-group" role="group">
				{
					options.map((option) => {
						const isSelect = currSizePerPage === `${option.page}`;
						return (
							<button
								key={option.text}
								type="button"
								onClick={() => onSizePerPageChange(option.page)}
								className={`btn ${isSelect ? 'btn-secondary' : 'btn-primary'}`}
							>
								{option.text}
							</button>
						);
					})
				}
			</div>
		);

		const options = {
			sizePerPageRenderer
		};

		return (
			<Container>
				{this.state.done ? (
					<div>
						<Modal isOpen={this.state.modal} toggle={this.toggle}>
							<ModalHeader>โปรดระบุวันที่เริ่มทดลองงานรอบใหม่</ModalHeader>
							<ModalBody>
								<DatePicker className="form-control ignore-react-onclickoutside" placeholderText="เลือกวันที่เริ่มงาน" name="startProbationDate"
									dateFormat="yyyy/MM/dd"
									selected={this.state.startProbationDate}
									onChange={this.startProbationChange}
									ref="startProbationDate"
								/></ModalBody>
							<ModalFooter>
								<Button color="secondary" onClick={this.toggleModal}>Cancel</Button>

								<Button color="success" onClick={this.saveCreateProbationHistory}>Save</Button>
							</ModalFooter>
						</Modal>

						<Card>
							<CardHeader>Report Employee</CardHeader>
							<CardBody>
								<Form>
									<Row>
										<Col md={4}>
											<FormGroup>
												<Label for="company">บริษัท</Label>
												<Input
													type="select"
													name="company"
													id="company"
													onChange={this.onChangeCompany} >
													<option value="">ทั้งหมด</option>
													<option value="DP">Dplus Intertrade</option>
													<option value="DT">Dplus Together</option>
													<option value="ZT">Zetta Soft</option>
													<option value="FI">FIN</option>
													<option value="DC">Digital Commerce</option>
													<option value="BD">Better Dose</option>

												</Input>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label for="FNameEN">แผนก</Label>
												<Input
													type="select"
													name="DepNo"
													id="DepNo"
													onChange={this.onChangeDep}
												>
													<option value="">กรุณาเลือกแผนก</option>
													{this.state.showDropdown_Department.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label for="FNameEN">แผนกย่อย</Label>
												<Input
													type="select"
													name="subDepNo"
													id="subDepNo"
													onChange={this.onChangeSubDep}
												>
													<option value="">กรุณาเลือกแผนกย่อย</option>
													{this.state.showDropdown_subDepartment.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={2}>
											<FormGroup>
												<Label >สถานที่ทำงาน</Label>
												<Input
													type="select"
													name="location"
													id="location"
													onChange={this.onChangelLocation} >
													<option value="">ทั้งหมด</option>
													<option value="OFF">Office</option>
													<option value="FAC">Factory</option>
													{/* <option value="FI">FIN</option>
                          <option value="DC">Digital Commerce</option> */}

												</Input>
											</FormGroup>
										</Col>
										<Col md={2}>
											<FormGroup>
												<Label>รหัสพนักงาน / ชื่อพนักงาน</Label>
												<Input
													type="text"
													name="ID_User"
													id="ID_User"
													onChange={this.onChangeUserID}
												/>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label>สถานะพนักงาน</Label>
												<Input
													type="select"
													name="Status_Worker"
													id="Status_Worker"
													onChange={this.onChangeStatusWorker}
												>
													{/* <option value="">กรุณาเลือกสถานะ</option> */}
													<option value="">ทั้งหมด</option>
													{this.state.showDropdown_StatusWorker.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}

												</Input>
											</FormGroup>
										</Col>
										<Col md={4}>
											<br />
											<FormGroup>
												<Button color="info" onClick={this.search}>
													Search
												</Button>
											</FormGroup>
										</Col>
									</Row>


								</Form>
							</CardBody>
						</Card>

						<div>
							<Row>
								<Col>
									<div className="btn-right">
										<CSVLink
											headers={headerCSV}
											data={products}
											filename={"รายงานพนักงาน.csv"}
											className="btn btn-export"
										>
											<MdFileDownload /> Export CSV
										</CSVLink>
									</div>
								</Col>
							</Row>

							<hr />
							<BootstrapTable
								keyField="ID_User" //"custNo"RowID
								data={products}
								columns={columns}
								ref={n => (this.node = n)}
								striped
								hover
								condensed
								pagination={paginationFactory(options)}
								wrapperClasses="table-responsive"

							/>
						</div>
					</div>
				) : (
					<div>{loadingPage} </div>
				)}
			</Container>
		);
	}
}
export default ReportEmployee;



