import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

export const DEFAULT_RECHECK_WORKING_SALE_EMPLOYEE = {
	company: undefined,
	location: undefined,
	depNo: undefined,
	statusWorker: undefined,
	startDateJob: undefined,
	endDateJob: undefined,
	statusWorkerInstallment: "new",
}

const DEFINE = {
	company: {
		label: "บริษัท",
		name: "company",
	},
	location: {
		label: "สถานที่",
		name: "location",
	},
	depNo: {
		label: "แผนก",
		name: "depNo",
	},
	subDepNo: {
		label: "แผนกย่อย",
		name: "subDepNo",
	},
	hrmFlow: {
		label: "สายอนุมัติ",
		name: "hrmFlow",
	},
	statusWorker: {
		label: "สถานะพนักงาน",
		label_2: "ประเภทพนักงาน",
		name: "statusWorker",
	},
	startDateJob: {
		label: "วันที่เริ่มงาน",
		label_2: "งวดเงินเดือน",
		label_3: "จากวันที่",
		name: "startDateJob",
	},
	endDateJob: {
		label: "ถึงวันที่",
		label_2: "ถึงเดือน",
		name: "endDateJob",
	},
	startDateSalary: {
		label: "งวดเงินเดือน",
		name: "startDateSalary",
	},
	toDateSalary: {
		label: "ถึงวันที่",
		name: "toDateSalary",
	},
	recruiterId: {
		label: "รหัสพนักงาน (ผู้สรรหา)",
		name: "recruiterId",
	},
	statusWorkerInstallment: {
		label: "สถานะพนักงานประจำงวด",
		name: "statusWorkerInstallment",
	},
}

export const ComponentRecheckWorkingSaleEmployee = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
}) => {
	const { company, location, depNo, startDateJob, endDateJob, statusWorker, statusWorkerInstallment } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: company.name,
							label: company.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(company.label),
							disabled: isDisable[company.name] || false,
						},
						fnChange: (value) => handleChange(company.name, value),
						dropdown: options[company.name],
					},
				},
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: location.name,
							label: location.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(location.label),
							disabled: isDisable[location.name] || false,
						},
						fnChange: (value) => handleChange(location.name, value),
						dropdown: options[location.name],
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: depNo.name,
							label: depNo.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(depNo.label),
							disabled: isDisable[depNo.name] || false,
						},
						fnChange: (value) => handleChange(depNo.name, value),
						dropdown: options[depNo.name],
					},
				},
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: statusWorker.name,
							label: statusWorker.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
							disabled: isDisable[statusWorker.name] || false,
						},
						fnChange: (value) => handleChange(statusWorker.name, value),
						dropdown: options.optionStatusWorkerCode.length > 0 && [{ txt: '-----ทั้งหมด-----', value: '' }, ...options.optionStatusWorkerCode]
					},
				},
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDateJob.name,
							label: startDateJob.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(startDateJob.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(startDateJob.label),
							disabled: isDisable[startDateJob.name] || false,
						},
						fnChange: (date, dateString) => handleChange(startDateJob.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDateJob.name,
							label: endDateJob.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[endDateJob.name] || false,
						},
						fnChange: (date, dateString) => handleChange(endDateJob.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: statusWorkerInstallment.name,
							label: statusWorkerInstallment.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorkerInstallment.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							mode: "multiple",
							placeholder: ERROR_MESSAGE.SELECT(statusWorkerInstallment.label),
							disabled: isDisable[statusWorkerInstallment.name] || false,
						},
						fnChange: (value) => handleChange(statusWorkerInstallment.name, value),
						dropdown: options.optionInstallmentSaleStatus,
					},
				},
			], // column
		}, // Row
	]
}
