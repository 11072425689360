import React, { useContext, useState } from 'react'
import { ButtonTheme } from '../../common/buttons'
import { Modal, Spin } from 'antd'
import { InsertIndicator } from './insertIndicator'
import { IndicatorContext } from '../../services/manage/indicatorProvider'
import { MasterContext } from '../../services/master/masterProvider'
import { MASTER_OPTION } from '../../services/servicesOptions'
import { colors } from '../../../themes'
import { LoadingOutlined } from '@ant-design/icons'


const ConfirmIndicator = ({ content, approvalNo, approval }) => {
    // console.log({ approval })
    const { onSubmit, viewDetail, clearIndicator, userLogin, indicatorState } = useContext(IndicatorContext)
    const { getMaster } = useContext(MasterContext)

    const [open, showModal] = useState(false)
    const [loading, setLoading] = useState(false)
    return (
        <div>
            <Spin indicator={<LoadingOutlined />} spinning={loading} tip="กำลังโหลด..." >
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                <ButtonTheme
                    buttonStyle={{ backgroundColor: colors.success, color: colors.white, border: 0 }}
                    buttonText={(approval) ? "ดูรายละเอียด / อนุมัติ" : `ดูรายละเอียด`}
                    onClick={async () => {
                        // console.log('ดูรายละเอียด', { content })
                        let payloadEmployee = {
                            indicatorHeaderId: content.indicatorHeaderId,
                            strMonth: content.strMonth,
                            endMonth: content.endMonth,
                            year: content.strYear,
                            userLogin: userLogin,
                            searchText: ''
                        }
                        await setLoading(true)
                        await getMaster({ fieldname: MASTER_OPTION.EVALUATION_EMPLOYEE, payload: payloadEmployee })
                        // // console.log('ดูรายละเอียด', content)
                        await viewDetail(content)
                        await showModal(true)
                        await setLoading(false)
                    }}
                />
                {(!approval) && <div style={{ textAlign: 'center', color: colors.helper, fontSize: 12 }}> {`รอ ${approvalNo} อนุมัติ`}</div>}
                {/* </div> */}
                <Modal
                    visible={open}
                    title={'รายละเอียดตัวชี้วัด'}
                    onOk={() => {
                        onSubmit()
                        showModal(false)
                    }}
                    onCancel={() => {
                        clearIndicator()
                        showModal(false)
                    }}
                    okText={(indicatorState.approval) ? "ยืนยัน (แก้ไขไม่ได้)" : "บันทึกร่าง"}
                    cancelText="ยกเลิก"
                >
                    <InsertIndicator approval={approval} />
                </Modal>
            </Spin>
        </div>
    )
}

export default ConfirmIndicator