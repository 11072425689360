import React, { createContext, useReducer } from "react";
import { REPORT_OPTION } from "../../../moduleKPIs/services/servicesOptions";
import { APISelectReportOT, APIExportOTFactory } from "./reportOTConnect";
import { path } from "ramda";
import { POST } from "../../../service";
import { CONFIG } from "../../../components/connectAPI";
import { KEY_ACTIVE_TAB } from "../../../constants/enums/enumCenter";

export const ReportOTContext = createContext();

let initialState = {
    hrmFlow: [],
    [KEY_ACTIVE_TAB.DASHBOARD_OT]: [],
    [KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT]: [],
    reportUser: {
        userData: null,
        userLists: []
    },
    exportOTFac: []
}

const REPORT_OT_ACTION = {
    SET_REPORT_OT_SUCCESS: "SET_REPORT_OT_SUCCESS",
    SET_REPORT_OT_FAILURE: "SET_REPORT_OT_FAILURE"
}

const reportOTReducer = (state, action) => {
    switch (action.type) {
        case REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS:
            return { ...state, ...action.state }
        case REPORT_OT_ACTION.SET_REPORT_OT_FAILURE:
        default:
            return state
    }
}

const ProviderReportOT = ({ children }) => {
    const [reportOTState, reportOTDispatch] = useReducer(reportOTReducer, initialState)

    let payloadDefault = {}
    let response = null
    async function getReports({ fieldname, payload }) {
        switch (fieldname) {
            case REPORT_OPTION.DASHBOARD_OT_FACTORY:
                //console.log({ payload })
                payloadDefault = {
                    userLogin: payload.userLogin,
                    statusCode: payload.statusCode,
                    startDateTime: payload.startDateTime,
                    endDateTime: payload.endDateTime,
                    subDepNo: payload.subDepNo,
                    activeTab: payload.activeTab,
                    createPermission: payload.createPermission
                }
                response = await POST(`${CONFIG.API_URL}${CONFIG.FACTORY.DASHBOARD_OT_FACTORY}`, {
                    ...payloadDefault
                })
                //console.log('DASHBOARD_OT_FACTORY', response)
                if (response) {
                    const { result } = response

                    initialState = {
                        ...initialState,
                        [KEY_ACTIVE_TAB.DASHBOARD_OT]: [],
                        [KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT]: []
                    }
                    reportOTDispatch({
                        type: REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS,
                        state: {
                            [KEY_ACTIVE_TAB.DASHBOARD_OT]: result[KEY_ACTIVE_TAB.DASHBOARD_OT],
                            [KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT]: result[KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT],
                        }
                    })
                }

                // await APIDashboardOTFactory({ ...payload }, res => {
                //     // console.log('DASHBOARD_OT_FACTORY', res)
                //     if (res) {
                //         reportOTDispatch({
                //             type: REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS,
                //             state: {
                //                 [KEY_ACTIVE_TAB.DASHBOARD_OT]: res[KEY_ACTIVE_TAB.DASHBOARD_OT],
                //                 [KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT]: res[KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT],
                //             }
                //         })
                //     }
                // })
                break;
            // case REPORT_OPTION.DASHBOARD_OT_FACTORY_APPROVAL:
            //     payloadDefault = {
            //         userLogin: payload.userLogin,
            //         statusCode: payload.statusCode,
            //         startDate: payload.startDate,
            //         endDate: payload.endDate,
            //         subDepNo: payload.subDepNo
            //     }
            //     response = await POST(`${CONFIG.API_URL}${CONFIG.FACTORY.DASHBOARD_OT_FACTORY_APPROVAL}`, {
            //         ...payloadDefault
            //     })
            //     console.log('DASHBOARD_OT_FACTORY_APPROVAL', response)
            //     if (response) {
            //         reportOTDispatch({
            //             type: REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS,
            //             state: {
            //                 dashboardOTApproval: response.result
            //             }
            //         })
            //     }

            //     break;
            case REPORT_OPTION.EXPORT_CSV__OT_FACTORY:
                await APIExportOTFactory({ ...payload }, res => {
                    // console.log('EXPORT_CSV__OT_FACTORY', res)
                    if (res) {
                        reportOTDispatch({
                            type: REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS, state: {
                                exportOTFac: res
                            }
                        })
                    }
                })
                break;
            case REPORT_OPTION.REPORT_OT_FACTORY:
                await APISelectReportOT({ ...payload }, res => {
                    //console.log('REPORT_OT_FACTORY', res)
                    if (res) {
                        //window.localStorage.setItem('userData', JSON.stringify(path(['dataHeader', 0], res)))
                        window.localStorage.setItem('userData', JSON.stringify(payload["record"]))
                        window.localStorage.setItem('userLists', JSON.stringify(path(['dataLine'], res)))
                        // reportOTDispatch({
                        //     type: REPORT_OT_ACTION.SET_REPORT_OT_SUCCESS, state: {
                        //         // reportOT: res
                        //         reportUser: {
                        //             userData: path(['dataHeader', 0], res),
                        //             userLists: path(['dataLine'], res),
                        //         }
                        //     }
                        // })
                    }
                })
                break;
            default:
                break;
        }
    }

    return (
        <ReportOTContext.Provider
            value={{
                getReports,
                reportOTState,
                reportOTDispatch
            }}>
            {children}
        </ReportOTContext.Provider>
    )
}


export default ProviderReportOT