import { responseAPI } from "../../common/functions/funcResponse";
import servicesAPI from "../servicesAPI";

let API = servicesAPI.create()

export async function APIInsertEvaluationScore(payload, nextAction) {
    try {
        const response = await API.insertEvaluationScore(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateEvaluationScore(payload, nextAction) {
    try {
        const response = await API.updateEvaluationScore(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertScoreFactory(payload, nextAction) {
    try {
        const response = await API.insertScoreFactory(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateScoreFactory(payload, nextAction) {
    try {
        const response = await API.updateScoreFactory(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}