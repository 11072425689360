import React, { useContext } from 'react';
import '../CSSPersonal.css'

import { KEY_PERSONAL, PersonalContext } from '../servicesPersonal/personalProvider'
import ManagePersonal from '../componentsPersonal/managePersonal';
import { PATHNAME } from '../../constants/enums/pathname';
import { STEPS_PROGRESS_IMPORT } from '../constantsPersonal/importPersonal';
import { FIELD_EMP } from '../constantsPersonal/contents/employeeInfo';

const ImportPersonal = (props) => {
    const { personalState } = useContext(PersonalContext);
    return (
        <ManagePersonal
            refData={{ [FIELD_EMP.CANDIDATE_NO]: props.params[FIELD_EMP.CANDIDATE_NO] }}
            initial={{ ...personalState[KEY_PERSONAL.IMPORT_PERSONAL] }}
            pathname={PATHNAME.IMPORT_PERSONAL}
            process={STEPS_PROGRESS_IMPORT} />
    )
}

export default ImportPersonal

