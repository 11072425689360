import React from "react"
import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";

export const ColumnReportWarning = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickname",
        key: "nickname",
        align: "left",
        width: 100,
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
    },
    {
        title: "บริษัท",
        dataIndex: "Company",
        key: "Company",
        align: "left",
        width: 150,
        ...filterSearchColumn("Company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionNo",
        key: "positionNo",
        align: "left",
        width: 300,
        ...filterSearchColumn("positionNo", "ตำแหน่งงาน"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorkerDes",
        key: "statusWorkerDes",
        align: "left",
        width: 250,
        ...filterSearchColumn("statusWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: "กลุ่มเงินเดือน",
        dataIndex: "payrollGroupDes",
        key: "payrollGroupDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("payrollGroupDes", "กลุ่มเงินเดือน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "startDateJob",
        key: "startDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "Sick เกิน 10D (วันละ-1%)",
        children: [
            {
                title: "จำนวนวันลา",
                dataIndex: "totalDaySick",
                key: "totalDaySick",
                align: "right",
                width: 150,
                ...useSorterTable("totalDaySick", "number"),
            },
            {
                title: "จำนวนวันที่เกิน",
                dataIndex: "overDaySick",
                key: "overDaySick",
                align: "right",
                width: 150,
                ...useSorterTable("overDaySick", "number"),
            },
            {
                title: "% กระทบโบนัส",
                dataIndex: "percentBonusSick",
                key: "percentBonusSick",
                align: "right",
                width: 150,
                ...useSorterTable("percentBonusSick", "number"),
            },
        ],
    },
    {
        title: "ขาดงาน (วันละ 2%)",
        children: [
            {
                title: "จำนวนวันลา",
                dataIndex: "absentDay",
                key: "absentDay",
                align: "right",
                width: 150,
                ...useSorterTable("absentDay", "number"),
            },
            {
                title: "จำนวนวันที่เกิน",
                dataIndex: "overDate",
                key: "overDate",
                align: "right",
                width: 150,
                ...useSorterTable("overDate", "number"),
            },
            {
                title: "% กระทบโบนัส",
                dataIndex: "percentBonusAbs",
                key: "percentBonusAbs",
                align: "right",
                width: 150,
                ...useSorterTable("percentBonusAbs", "number"),
            },
        ],
    },
    {
        title: "ลาอื่น ๆ (เกิน 10D -10%)",
        children: [
            {
                title: "จำนวนวันลา",
                dataIndex: "totalDayOtherLeave",
                key: "totalDayOtherLeave",
                align: "right",
                width: 150,
                ...useSorterTable("totalDayOtherLeave", "number"),
            },
            {
                title: "จำนวนวันที่เกิน",
                dataIndex: "overDayOtherLeave",
                key: "overDayOtherLeave",
                align: "right",
                width: 150,
                ...useSorterTable("overDayOtherLeave", "number"),
            },
            {
                title: "% กระทบโบนัส",
                dataIndex: "percentBonusOtherLeave",
                key: "percentBonusOtherLeave",
                align: "right",
                width: 150,
                ...useSorterTable("percentBonusOtherLeave", "number"),
            },
        ],
    },
    {
        title: "ลาไม่รับค่าจ้าง (วันละ -1%)",
        children: [
            {
                title: "จำนวนวันลา",
                dataIndex: "totalDayNotPay",
                key: "totalDayNotPay",
                align: "right",
                width: 150,
                ...useSorterTable("totalDayNotPay", "number"),
            },
            {
                title: "จำนวนวันที่เกิน",
                dataIndex: "overDayNotPay",
                key: "overDayNotPay",
                align: "right",
                width: 150,
                ...useSorterTable("overDayNotPay", "number"),
            },
            {
                title: "% กระทบโบนัส",
                dataIndex: "percentBonusNotPay",
                key: "percentBonusNotPay",
                align: "right",
                width: 150,
                ...useSorterTable("percentBonusNotPay", "number"),
            },
        ],
    },
    // {
    //     title: "วันที่ขาด",
    //     dataIndex: "absentDay",
    //     key: "absentDay",
    //     align: "right",
    //     width: 150,
    //     ...filterSearchColumn("absentDay", "วันที่ขาด"),
    // },
    // {
    //     title: () => {
    //         return (
    //             <div>
    //                 <span>%เพิ่ม/ลด</span> <br />
    //                 <span>(สายขาดลา ใบเตือน)</span>
    //             </div>
    //         )
    //     },
    //     dataIndex: "percentBonusAbs",
    //     key: "percentBonusAbs",
    //     align: "right",
    //     width: 200,
    //     ...useSorterTable("percentBonusAbs", "number")
    // },
];
