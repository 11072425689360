import React from 'react'
import { ButtonTheme } from './buttonTheme.jsx';
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

/**
 * @param {*} dataSource
 * @param {*} columns - { title:"",field: ""} or { title:"", children : [{ title:"",field: "" }] }]
 * @param {string} filename 
 * @param {object} style
 */

export const ExportExcel = React.forwardRef(({
    columns,
    dataSource,
    style,
    fieldname,

}, ref) => {

    // let refExporter = React.createRef()

    async function exportFile() {
        await ref.current.save();
    }

    return (
        <div >
            <ButtonTheme
                style={style}
                disabled={(Array.isArray(dataSource) && dataSource.length > 0) ? false : true}
                buttonText={'Export Excel'}
                onClick={() => exportFile()} />
            <ExcelExport
                data={dataSource}
                fileName={fieldname || 'export_excel'}
                ref={(exporter) => { ref.current = exporter }}
            >
                {
                    Array.isArray(columns) && columns.map((element, index) => (
                        (element.children) ?
                            <ExcelExportColumnGroup key={index} title={`${element.title}`}
                                headerCellOptions={{ textAlign: 'center' }}
                            >
                                {
                                    element.children.map((each, i) => (
                                        <ExcelExportColumn key={i} field={`${each.field}`} title={`${each.title}`} />
                                    ))
                                }
                            </ExcelExportColumnGroup>
                            :
                            <ExcelExportColumn key={index}
                                field={`${element.field}`}
                                title={`${element.title}`}
                                cellOptions={element.cellOptions}

                            />
                    ))
                }
            </ExcelExport>
        </div >
    )
});