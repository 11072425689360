import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col } from "antd";
import {
  optionSelectLocation,
  useOptionSelect,
} from "../../constants/OptionSelect";
import {
  GET_UserLogin,
  toUndefined,
  sumColumnWidth,
  subStringDate,
} from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useText } from "../typography/Typography";
import { useNotification } from "../notification/NotificationWithIcon";
import {
  POST,
  POST_INSERT_DATA_MOVEMENT,
  POST_ROLL_BACK_MOVEMENT,
  POST_SELECT_DASHBOARD_MOVEMENT,
  POST_SELECT_DATA_LOGS_NOT_TRANSFER,
} from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import {
  ComponentActionChangeStatusWorker,
  ComponentSearchChangeStatusWorker,
} from "../../constants/TransferEmployee/ComponentChangeStatusWorker";
import {
  ColumnChangeStatusWorker,
  ColumnTransferLogStatusWorker,
} from "../../constants/TransferEmployee/Columns";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ChangeStatusWorker } from "../../constants/HeaderExcel";

const FormSearchChangeStatusWorker = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [selectRowData, setSelectRowData] = useState([]);

  const [modalshow, setModalshow] = useState(false);
  const [dataTableModal, setDataTableModal] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const typeMove = "statusWorker";
  // [END] States

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect(
      "department",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
    subDepNo: useOptionSelect(
      "subDepartment",
      useFixedQueryString(ChangeValue, ["company", "location", "depNo"])
    ),
    userId: useOptionSelect(
      "empAll",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
  };

  let optionAction = {
    statusWorker: useOptionSelect("statusWorker"),
  };
  optionAction.statusWorker = optionAction.statusWorker.filter(
    (el) => el.value !== "Block" && el.value !== "notCome"
  );

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { actionDate, statusWorker } = values;
    let objectBody = {};
    let dataLine = [];
    if (selectRowData.length !== 0) {
      dataLine = selectRowData.map((row) => ({ userId: row.userId }));
      objectBody = {
        dataHeader: {
          typeMove,
          actionDate: actionDate.format("YYYY-MM-DD"),
          newCompany: "",
          newLocation: "",
          newDepNo: "",
          newSubDepNo: "",
          newHrmFlow: "",
          newViewFlow: "",
          newPositionNo: "",
          newStatusWorker: statusWorker,
          userLogin: userlogin,
        },
        dataLine,
      };
      useConfirm({ FnOK: () => insertDataMovement(objectBody) });
    } else {
      useAlert({
        type: "warning",
        title: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setSelectRowKey([]);
    setSelectRowData([]);
    setChangeValue({});
  };

  const searchDataDashboardMovement = async (objectValue) => {
    try {
      let bodyRaw = {
        company: objectValue.company || "",
        location: objectValue.location || "",
        depNo: objectValue.depNo || "",
        subDepNo: objectValue.subDepNo || "",
        hrmFlow: objectValue.hrmFlow || "",
        viewFlow: objectValue.viewFlow || "",
        position: objectValue.positionNo || "",
        typeMove,
        userId: objectValue.userId || "",
      };
      setLoadingScreen(true);
      setDataTable([]);
      setSelectRowKey([]);
      setSelectRowData([]);
      const response = await POST(POST_SELECT_DASHBOARD_MOVEMENT, bodyRaw);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: `${row.userId}_${index}`,
          ...row,
        }));
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      console.log("Error Search Movement: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => setLoadingScreen(false),
      });
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] Handle Form Antd

  // [START] REST API
  const insertDataMovement = async (objectValue) => {
    try {
      setLoadingScreen(true);
      const response = await POST(POST_INSERT_DATA_MOVEMENT, objectValue);
      const { success, result, message } = response;
      if (success) {
        useAlert({
          type: "success",
          title: `${result}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "info",
          title: `${message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error insertDataMovement: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => setLoadingScreen(false),
      });
    }
  };

  const selectDataLogsNotTransfer = async (record) => {
    try {
      setLoadingModal(true);
      const { userId } = record;
      const response = await POST(POST_SELECT_DATA_LOGS_NOT_TRANSFER, {
        userId,
        typeMove,
      });
      const { result, success, message } = response;
      let mapResult = [];
      if (success) {
        if (result.length !== 0) {
          mapResult = result.map((row) => ({
            key: row.userId,
            ...row,
            actionDate: subStringDate(row.actionDate),
          }));
        }
      } else {
        useAlert({ type: "info", title: `${message}` });
      }
      setDataTableModal(mapResult);
    } catch (err) {
      console.log("Error selectDataLogsNotTransfer: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => setLoadingScreen(false),
      });
    } finally {
      setLoadingModal(false);
    }
  };

  const rollBackMovement = async (record) => {
    try {
      const { userId } = record;
      const response = await POST(POST_ROLL_BACK_MOVEMENT, {
        userId,
        userlogin,
        typeMove,
      });
      const { result, success, message } = response;
      if (success) {
        useAlert({
          type: "success",
          title: `${result}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${message}`,
        });
      }
    } catch (err) {
      console.log("Error rollbackMovement: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
      });
    }
  };

  const showModal = (record) => {
    setModalshow(true);
    selectDataLogsNotTransfer(record);
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndifined = {};
    let setName = {};
    let arrSearch = ["company", "location", "depNo", "subDepNo", "userId"];
    let arrAction = ["actionDate", "statusWorker", "remark"];
    switch (name) {
      case "company":
        setName = { companyCode: value };
        setUndifined = toUndefined([...arrSearch.slice(1), ...arrAction]);
        break;
      case "location":
        setUndifined = toUndefined([...arrSearch.slice(2), ...arrAction]);
        break;
      case "depNo":
        setUndifined = toUndefined([...arrSearch.slice(3), ...arrAction]);
        break;
      case "subDepNo":
        setUndifined = toUndefined([...arrSearch.slice(4), ...arrAction]);
        break;
      case "userId":
        setUndifined = toUndefined(arrAction);
        break;
    }
    if (name === "actionDate" || name === "statusWorker" || name === "remark") {
      setSelectRowData([]);
      setSelectRowKey([]);
    } else {
      setDataTable([]);
    }
    form.setFieldsValue(setUndifined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndifined,
      ...setName,
      [name]: value,
    }));
  };

  const contentLogTransfer = ({ data = data || [] }) => {
    return dataTableModal.length !== 0 ? (
      <Spin tip="Loading..." spinning={loadingModal}>
        <CustomTableAtnd
          HeadTable={ColumnTransferLogStatusWorker}
          DataTable={dataTableModal}
          SetScroll={{
            x: sumColumnWidth(ColumnTransferLogStatusWorker) || 2000,
            y: 500,
          }}
        />
      </Spin>
    ) : (
      useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
    );
  };
  // [END] Functions
  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      {RenderMainModal({
        show: modalshow,
        onCancel: () => setModalshow(false),
        txtTitle: "โอนย้าย",
        ModalContent: contentLogTransfer({}),
      })}
      <Form form={form} onFinish={onFinish} {...configForm}>
        <Card
          title="ค้นหาพนักงานเพื่อเปลี่ยนสถานะพนักงาน"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({
                call: "normal",
                fn: () => searchDataDashboardMovement(ChangeValue),
                isDisable:
                  ChangeValue["company"] && ChangeValue["location"]
                    ? false
                    : true,
                moreProps: {
                  label: "ค้นหา",
                  type: "primary",
                },
              })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentSearchChangeStatusWorker({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
        {dataTable.length !== 0 && (
          <>
            <Card
              title="กรอกข้อมูลที่ต้องการเปลี่ยนแปลงใหม่"
              style={{ marginBottom: 30 }}
            >
              {useRenderFormItem({
                constantComponent: ComponentActionChangeStatusWorker({
                  options: optionAction,
                  handleChange: onChangeValue,
                }),
              })}
            </Card>
            <Card
              title="เลือกพนักงานที่ต้องการเปลี่ยนแปลงสถานะพนักงาน"
              style={{ marginBottom: 30 }}
              extra={useExportExcel({
                header: Header_ChangeStatusWorker,
                dataset: dataTable,
                filename: "รายงานพนักงาน(เปลี่ยนสถานะพนักงาน)",
              })}
            >
              <CustomTableAtnd
                HeadTable={ColumnChangeStatusWorker({
                  FnShowLog: showModal,
                  FnRollback: rollBackMovement,
                })}
                DataTable={dataTable}
                moreProps={{
                  rowSelection: {
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectRowKey(selectedRowKeys);
                      setSelectRowData(selectedRows);
                    },
                    getCheckboxProps: (record) => ({
                      disabled: record.statusTransfer === 0,
                    }),
                    selectedRowKeys: selectRowKey,
                  },
                  scroll: {
                    x: sumColumnWidth(ColumnChangeStatusWorker({})) || 2050,
                    y: 500,
                  },
                }}
              />
            </Card>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Spin>
  );
};

export default FormSearchChangeStatusWorker;
