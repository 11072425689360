import { Button } from 'antd'
import React from 'react'
import { TEXT_BUTTON } from '.'
import { MdSearch } from "react-icons/md";

import './buttons.css'
import { colors } from '../../themes';

const ButtonSearch = ({ style, disabled, onClick }) => {
    return (
        <div style={style}>
            <Button
                type="primary"
                disabled={disabled}
                icon={<MdSearch style={{ marginRight: 5 }} />}
                style={{
                    color: colors.white,
                    backgroundColor: disabled ? colors.buttonDisabled : colors.buttonSearch
                }}
                onClick={onClick}
            > {TEXT_BUTTON.SEARCH} </Button>
        </div>
    )
}

export default ButtonSearch