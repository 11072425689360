import React, { useState, useContext } from 'react'
import PopoverWrapper from '../common/popover/popoverWrapper'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BaseText } from '../common/text';
import { APIReportIndicatorLicense } from '../services/report/reportConnect';
import { IndicatorContext } from '../services/manage/indicatorProvider';

const ContentInfoEmployee = ({ content }) => {
    const [contentList, setContentList] = useState([])
    const { userLogin } = useContext(IndicatorContext)

    return (
        <div
            onClick={async () =>
                await APIReportIndicatorLicense({ indicatorHeaderId: content.result.indicatorHeaderId, userLogin },
                    res => {
                        if (res.LV1.length > 0) {
                            setContentList(res.LV1)
                        } else {
                            setContentList(res.LV2)
                        }
                    }
                )
            }
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PopoverWrapper
                icon={<InfoOutlinedIcon />}
                trigger="click"
                title={`รายชื่อพนักงานทั้งหมด`}
                content={
                    <>
                        {contentList.map((el, elIndex) => {
                            return (
                                <BaseText key={elIndex} size={14} label={`${elIndex + 1}. ${el.employeeId} : ${el.employeeName} - ${el.departmentNo} `} />
                            )
                        })}
                    </>
                }
            />
        </div >
    )
}

export default ContentInfoEmployee
