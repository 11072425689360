import React, { useState } from "react";
import { Form, Card, Spin, Table, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportEmployeeSummary } from "../../constants/HeaderExcel";
import { optionFilterReportBy, optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useMakeQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportEmployeeSummary } from "../../constants/Reports/Components/ComponentReports";
import { POST, POST_SELECT_REPORT_EMPLOYEE_SUMMARY } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReporttEmployeeSummary } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportEmployeeSummary = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [monthHeader, setMonthHeader] = useState({ month: "เดือน-ปี", month_2: "เดือน-ปี" });
  const [dataTable, setDataTable] = useState([]);
  const [overAll, setOverAll] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    filterBy: optionFilterReportBy,
  }

  // [START] Handle Form
  const onFinish = (values) => {
    const { company, location, from, to, filterBy } = values;
    const arrayMonth = [from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")];
    const objectBody = {
      company: company || "",
      location: location || "",
      filterBy: filterBy || "",
    }
    getReportEmployeeSummary(useMakeQueryString(objectBody), arrayMonth, filterBy);
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
    setMonthHeader({ month: "เดือน-ปี", month_2: "เดือน-ปี" });
    setOverAll({});
  };
  // [END] Handle Form

  // [START] REST API
  const getReportEmployeeSummary = async (query, arrMonth, filter) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setOverAll({});
      const response = await POST(POST_SELECT_REPORT_EMPLOYEE_SUMMARY(query), { month: arrMonth });
      const { result } = response;
      const { month, summary, overall } = result[0]
      const { month: month_2, summary: summary_2, overall: overall_2 } = result[1]
      let mapResult = [];
      let mapOverAll = {};
      let mapMonthHeader = { month: "เดือน-ปี", month_2: "เดือน-ปี" };
      let setFilter = filter === "subDep" ? "subDepNo" : "depNo"
      if (summary.length !== 0 && summary_2.length !== 0) {
        mapResult = summary.map((row, index) => {
          const numIndex = summary_2.findIndex((el) => el[setFilter] === row[setFilter])
          if (numIndex !== -1) {
            return {
              key: `${index}`,
              ...row,
              newEmployee_2: summary_2[numIndex].newEmployee,
              outEmployee_2: summary_2[numIndex].outEmployee,
              moveInEmployee_2: summary_2[numIndex].moveInEmployee,
              moveOutEmployee_2: summary_2[numIndex].moveOutEmployee,
              totalEmployee_2: summary_2[numIndex].totalEmployee,
            }
          }
        });
        mapOverAll = {
          ...overall,
          newEmployee_2: overall_2["newEmployee"],
          outEmployee_2: overall_2["outEmployee"],
          moveInEmployee_2: overall_2["moveInEmployee"],
          moveOutEmployee_2: overall_2["moveOutEmployee"],
          totalEmployee_2: overall_2["totalEmployee"],
        };
        mapMonthHeader = { month, month_2 };
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
      setOverAll(mapOverAll);
      setMonthHeader(mapMonthHeader);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Employee Summary: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    if (name === "company" && locationLogin !== "FAC") {
      setUndefined = toUndefined(["location"]);
    } else if (name === "from") {
      setUndefined = toUndefined(["to"]);
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  const renderSummaryRow = (obj) => {
    if (Object.keys(obj).length === 0) return null;
    let renderCells = [];
    for (const key in obj) {
      renderCells.push(
        <Table.Summary.Cell align="right">
          {obj[key] || 0}
        </Table.Summary.Cell>
      )
    }
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center" colSpan={ChangeValue["filterBy"] !== "subDep" ? 4 : 6}>
            รวมทั้งสิ้น
          </Table.Summary.Cell>
          {renderCells}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form
        form={form}
        onFinish={onFinish}
        {...configForm}
        initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }}
      >
        <Card
          title="ค้นหารายงานเปรียบเทียบจำนวนพ้นสภาพ-จ้างใหม่-โอนย้าย"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportEmployeeSummary({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                location: locationLogin === "FAC" || false,
              }
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานเปรียบเทียบจำนวนพ้นสภาพ-จ้างใหม่-โอนย้าย"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportEmployeeSummary(monthHeader),
              dataset: dataTable,
              filename: "รายงานเปรียบเทียบจำนวน",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReporttEmployeeSummary(monthHeader, ChangeValue["filterBy"])}
            DataTable={dataTable}
            moreProps={{
              scroll: { x: 1800, y: 500 },
              summary: () => renderSummaryRow(overAll),
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportEmployeeSummary;
