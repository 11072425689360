import React from "react"
import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";

export const ColumnReportLevelWorker = [
    {
        title: "รหัสแผนก",
        dataIndex: "depNo",
        key: "depNo",
        align: "left",
        width: 150,
        ...filterSearchColumn("depNo", "รหัสแผนก"),
    },
    {
        title: "ชื่อแผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 400,
        ...filterSearchColumn("depName", "ชื่อแผนก"),
    },
    {
        title: "เกรด (Level Worker)",
        children: [
            {
                title: "MAN",
                dataIndex: "MAN",
                key: "MAN",
                align: "right",
                ...useSorterTable("MAN", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "PRO",
                dataIndex: "PRO",
                key: "PRO",
                align: "right",
                ...useSorterTable("PRO", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "STF",
                dataIndex: "STF",
                key: "STF",
                align: "right",
                ...useSorterTable("STF", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "SUP",
                dataIndex: "SUP",
                key: "SUP",
                align: "right",
                ...useSorterTable("SUP", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "รวมทั้งสิ้น",
                dataIndex: "total",
                key: "total",
                align: "right",
                ...useSorterTable("total", "number"),
                render: (num) => num || `-`,
            },
        ],
    },
];

export const ColumnReportStatusWorker = [
    {
        title: "รหัสบริษัท",
        dataIndex: "companyCode",
        key: "companyCode",
        align: "left",
        width: 150,
        ...filterSearchColumn("companyCode", "รหัสบริษัท"),
    },
    {
        title: "ชื่อบริษัท",
        dataIndex: "companyName",
        key: "companyName",
        align: "left",
        width: 300,
        ...filterSearchColumn("companyName", "ชื่อบริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "สถานะการจ้าง",
        children: [
            {
                title: "พนักงานประจำรายเดือน",
                dataIndex: "Full",
                key: "Full",
                align: "right",
                ...useSorterTable("Full", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "พนักงานประจำรายวัน",
                dataIndex: "Daily",
                key: "Daily",
                align: "right",
                ...useSorterTable("Daily", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "พนักงานชั่วคราว",
                dataIndex: "Temp",
                key: "Temp",
                align: "right",
                ...useSorterTable("Temp", "number"),
                render: (num) => num || `-`,
            },
            {
                title: "จำนวนพนักงานในสถานที่ทำงาน",
                dataIndex: "total",
                key: "total",
                align: "right",
                ...useSorterTable("total", "number"),
                render: (num) => num || `-`,
            },
        ],
    },
];

export const ColumnReportEmployeeAge = () => {
    let columnAge = [];
    for (let i = 18; i <= 60; i++) {
        columnAge.push({
            title: i,
            dataIndex: i,
            key: i,
            align: "right",
            ...useSorterTable(`${i}`, "number"),
        });
    }
    return [
        {
            title: "บริษัท",
            dataIndex: "company",
            key: "company",
            align: "left",
            ...filterSearchColumn("company", "บริษัท"),
        },
        {
            title: "รหัสแผนก",
            dataIndex: "depNo",
            key: "depNo",
            align: "left",
            ...filterSearchColumn("depNo", "รหัสแผนก"),
        },
        {
            title: "ชื่อแผนก",
            dataIndex: "depName",
            key: "depName",
            align: "left",
            ...filterSearchColumn("depName", "ชื่อแผนก"),
        },
        {
            title: "สถานที่",
            dataIndex: "location",
            key: "location",
            align: "left",
            ...filterSearchColumn("location", "สถานที่"),
        },
        {
            title: "อายุพนักงาน",
            children: [
                ...columnAge,
                {
                    title: "รวมทั้งสิ้น",
                    dataIndex: "total",
                    key: "total",
                    align: "right",
                    ...useSorterTable("total", "number"),
                },
            ],
        },
    ];
};

export const ColumnReportWorkAge = (filterBy) => {
    let columnAge = [];
    let columnSubDep = [];
    for (let i = 0; i <= 20; i++) {
        columnAge.push({
            title: `${i}`,
            dataIndex: `${i}`,
            key: `${i}`,
            align: "right",
            ...useSorterTable(`${i}`, "number"),
        });
    }

    if (filterBy === "subDepartment") {
        columnSubDep = [
            {
                title: "รหัสแผนกย่อย",
                dataIndex: "subDepNo",
                key: "subDepNo",
                align: "left",
                ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
            },
            {
                title: "ชื่อแผนกย่อย",
                dataIndex: "subDepName",
                key: "subDepName",
                align: "left",
                ...filterSearchColumn("subDepName", "ชื่อแผนกย่อย"),
            },
        ]
    }

    return [
        {
            title: "บริษัท",
            dataIndex: "company",
            key: "company",
            align: "left",
            ...filterSearchColumn("company", "บริษัท"),
        },
        {
            title: "สถานที่",
            dataIndex: "location",
            key: "location",
            align: "left",
            ...filterSearchColumn("location", "สถานที่"),
        },
        {
            title: "รหัสแผนก",
            dataIndex: "depNo",
            key: "depNo",
            align: "left",
            ...filterSearchColumn("depNo", "รหัสแผนก"),
        },
        {
            title: "ชื่อแผนก",
            dataIndex: "depName",
            key: "depName",
            align: "left",
            ...filterSearchColumn("depName", "ชื่อแผนก"),
        },
        ...columnSubDep,
        {
            title: "อายุงานพนักงาน",
            children: [
                ...columnAge,
                {
                    title: "รวมทั้งสิ้น",
                    dataIndex: "total",
                    key: "total",
                    align: "right",
                    ...useSorterTable("total", "number"),
                },
            ],
        },
    ];
};

export const ColumnReportALL = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: 'left',
        width: 100,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "คำนำหน้าชื่อ (ไทย)",
        dataIndex: "title",
        key: "title",
        align: "left",
        fixed: 'left',
        width: 150,
        ...filterSearchColumn("title", "คำนำหน้าชื่อ (ไทย)"),
    },
    {
        title: "ชื่อภาษาไทย",
        dataIndex: "firstname",
        key: "firstname",
        align: "left",
        fixed: 'left',
        width: 150,
        ...filterSearchColumn("firstname", "ชื่อภาษาไทย"),
    },
    {
        title: "นามสกุลภาษาไทย",
        dataIndex: "lastname",
        key: "lastname",
        align: "left",
        fixed: 'left',
        width: 200,
        ...filterSearchColumn("lastname", "นามสกุลภาษาไทย"),
    },
    {
        title: "คำนำหน้าชื่อ (อังกฤษ)",
        dataIndex: "titleEN",
        key: "titleEN",
        align: "left",
        width: 150,
        ...filterSearchColumn("titleEN", "คำนำหน้าชื่อ (อังกฤษ)"),
    },
    {
        title: "ชื่อภาษาอังกฤษ",
        dataIndex: "firstnameEN",
        key: "firstnameEN",
        align: "left",
        width: 150,
        ...filterSearchColumn("firstnameEN", "ชื่อภาษาอังกฤษ"),
    },
    {
        title: "นามสกุลภาษาอังกฤษ",
        dataIndex: "lastnameEN",
        key: "lastnameEN",
        align: "left",
        width: 200,
        ...filterSearchColumn("lastnameEN", "ชื่อภาษาอังกฤษ"),
    },
    {
        title: "เพศ",
        dataIndex: "gender",
        key: "gender",
        align: "left",
        width: 100,
        ...filterSearchColumn("gender", "เพศ"),
    },
    {
        title: "วันเกิด",
        dataIndex: "birthday",
        key: "birthday",
        align: "left",
        width: 150,
    },
    {
        title: "อายุ",
        dataIndex: "age",
        key: "age",
        align: "right",
        width: 100,
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickName",
        key: "nickName",
        align: "left",
        width: 100,
        ...filterSearchColumn("nickName", "ชื่อเล่น"),
    },
    {
        title: "สัญชาติ",
        dataIndex: "nationality",
        key: "nationality",
        align: "left",
        width: 100,
        ...filterSearchColumn("nationality", "สัญชาติ"),
    },
    {
        title: "เชื้อชาติ",
        dataIndex: "race",
        key: "race",
        align: "left",
        width: 100,
        ...filterSearchColumn("race", "เชื้อชาติ"),
    },
    {
        title: "ศาสนา",
        dataIndex: "religion",
        key: "religion",
        align: "left",
        width: 100,
        ...filterSearchColumn("religion", "ศาสนา"),
    },
    {
        title: "เลขบัตรประชาชน",
        dataIndex: "personalId",
        key: "personalId",
        align: "left",
        width: 200,
        ...filterSearchColumn("personalId", "เลขบัตรประจำตัวประชาชน"),
    },
    {
        title: "อีเมล",
        dataIndex: "email",
        key: "email",
        align: "left",
        width: 300,
        ...filterSearchColumn("email", "อีเมล"),
    },
    {
        title: "ที่อยู่ปัจจุบัน",
        dataIndex: "presentAddress",
        key: "presentAddress",
        align: "left",
        width: 400,
        ...filterSearchColumn("presentAddress", "ที่อยู่ปัจจุบัน"),
    },
    {
        title: "อำเภอ/เขต",
        dataIndex: "presentDistrict",
        key: "presentDistrict",
        align: "left",
        width: 150,
        ...filterSearchColumn("presentDistrict", "อำเภอ/เขต"),
    },
    {
        title: "จังหวัด",
        dataIndex: "presentProvince",
        key: "presentProvince",
        align: "left",
        width: 150,
        ...filterSearchColumn("presentProvince", "จังหวัด"),
    },
    {
        title: "รหัสไปรษณีย์",
        dataIndex: "presentPostcode",
        key: "presentPostcode",
        align: "right",
        width: 100,
        ...filterSearchColumn("presentPostcode", "รหัสไปรษณีย์"),
    },
    {
        title: "ที่อยู่ตามทะเบียนบ้าน",
        dataIndex: "formerAddress",
        key: "formerAddress",
        align: "left",
        width: 400,
        ...filterSearchColumn("formerAddress", "ที่อยู่ตามทะเบียนบ้าน"),
    },
    {
        title: "อำเภอ/เขต",
        dataIndex: "formerDistrict",
        key: "formerDistrict",
        align: "left",
        width: 150,
        ...filterSearchColumn("formerDistrict", "อำเภอ/เขต"),
    },
    {
        title: "จังหวัด",
        dataIndex: "formerProvince",
        key: "formerProvince",
        align: "left",
        width: 150,
        ...filterSearchColumn("formerProvince", "จังหวัด"),
    },
    {
        title: "รหัสไปรษณีย์",
        dataIndex: "formerPostcode",
        key: "formerPostcode",
        align: "right",
        width: 100,
        ...filterSearchColumn("formerPostcode", "รหัสไปรษณีย์"),
    },
    {
        title: "โทรศัพท์มือถือ(1)",
        dataIndex: "telNo",
        key: "telNo",
        align: "right",
        width: 150,
        ...filterSearchColumn("telNo", "โทรศัพท์มือถือ(1)"),
    },
    {
        title: "วุฒิการศึกษา",
        dataIndex: "educationLevel",
        key: "educationLevel",
        align: "left",
        width: 150,
        ...filterSearchColumn("educationLevel", "วุฒิการศึกษา"),
    },
    {
        title: "สถาบันการศึกษา",
        dataIndex: "educationInstitution",
        key: "educationInstitution",
        align: "left",
        width: 250,
        ...filterSearchColumn("educationInstitution", "สถาบันการศึกษา"),
    },
    {
        title: "คณะการศึกษา",
        dataIndex: "educationFaculty",
        key: "educationFaculty",
        align: "left",
        width: 200,
        ...filterSearchColumn("educationFaculty", "คณะการศึกษา"),
    },
    {
        title: "เกรดเฉลี่ย",
        dataIndex: "gpa",
        key: "gpa",
        align: "right",
        width: 100,
        ...filterSearchColumn("gpa", "เกรดเฉลี่ย"),
    },
    {
        title: "รหัสบริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 100,
        ...filterSearchColumn("company", "รหัสบริษัท"),
    },
    {
        title: "ชื่อบริษัท",
        dataIndex: "companyName",
        key: "companyName",
        align: "left",
        width: 250,
        ...filterSearchColumn("companyName", "ชื่อบริษัท"),
    },
    {
        title: "สถานภาพพนักงาน",
        dataIndex: "currentStatusDes",
        key: "currentStatusDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("currentStatusDes", "สถานภาพพนักงาน"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorker",
        key: "statusWorker",
        align: "left",
        width: 200,
        ...filterSearchColumn("statusWorker", "สถานะพนักงาน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "StartDateJob",
        key: "StartDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "อายุงาน (ปี.เดือน)",
        dataIndex: "ageOfWork",
        key: "ageOfWork",
        align: "right",
        width: 100,
        ...filterSearchColumn("ageOfWork", "อายุงาน (ปี.เดือน)"),
    },
    {
        title: "วันผ่านทดลองงาน",
        dataIndex: "probationDateEnd",
        key: "probationDateEnd",
        align: "right",
        width: 150,
        ...filterSearchColumn("probationDateEnd", "วันผ่านทดลองงาน"),
    },
    {
        title: "วันที่มีผลพ้นสภาพพนักงาน",
        dataIndex: "endDateJob",
        key: "endDateJob",
        align: "right",
        width: 200,
        ...filterSearchColumn("endDateJob", "วันที่มีผลพ้นสภาพพนักงาน"),
    },
    {
        title: "ประเภทการลาออก",
        dataIndex: "reasonLeaveDesCode",
        key: "reasonLeaveDesCode",
        align: "left",
        width: 150,
        ...filterSearchColumn("reasonLeaveDesCode", "ประเภทการลาออก"),
    },
    {
        title: "เหตุผลการพ้นสภาพ",
        dataIndex: "reasonLeaveDes",
        key: "reasonLeaveDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("reasonLeaveDes", "เหตุผลการพ้นสภาพ"),
    },
    {
        title: "รหัสตำแหน่งงาน",
        dataIndex: "positionNo",
        key: "positionNo",
        align: "left",
        width: 200,
        ...filterSearchColumn("positionNo", "รหัสตำแหน่งงาน"),
    },
    {
        title: "ชื่อตำแหน่งงาน",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 300,
        ...filterSearchColumn("positionName", "ชื่อตำแหน่งงาน"),
    },
    {
        title: "รหัสแผนก",
        dataIndex: "depNo",
        key: "depNo",
        align: "left",
        width: 200,
        ...filterSearchColumn("depNo", "รหัสแผนก"),
    },
    {
        title: "ชื่อแผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 300,
        ...filterSearchColumn("depName", "ชื่อแผนก"),
    },
    {
        title: "รหัสแผนกย่อย",
        dataIndex: "subDepNo",
        key: "subDepNo",
        align: "left",
        width: 200,
        ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
    },
    {
        title: "ชื่อแผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "ชื่อแผนกย่อย"),
    },
    {
        title: "ระดับพนักงาน",
        dataIndex: "levelWorker",
        key: "levelWorker",
        align: "left",
        width: 150,
        ...filterSearchColumn("levelWorker", "ระดับพนักงาน"),
    },
    {
        title: "ชื่อระดับพนักงาน",
        dataIndex: "levelWorkerCode",
        key: "levelWorkerCode",
        align: "left",
        width: 250,
        ...filterSearchColumn("levelWorkerCode", "ชื่อระดับพนักงาน"),
    },
    {
        title: "รหัสสถานที่ทำงาน",
        dataIndex: "locationName",
        key: "locationName",
        align: "left",
        width: 150,
        ...filterSearchColumn("locationName", "รหัสสถานที่ทำงาน"),
    },
    {
        title: "รหัสผู้บังคับบัญชา",
        dataIndex: "bossId",
        key: "bossId",
        align: "left",
        width: 150,
        ...filterSearchColumn("bossId", "รหัสผู้บังคับบัญชา"),
    },
    {
        title: "ชื่อผู้บังคับบัญชา",
        dataIndex: "bossName",
        key: "bossName",
        align: "left",
        width: 200,
        ...filterSearchColumn("bossName", "ชื่อผู้บังคับบัญชา"),
    },
    {
        title: "สายอนุมัติ",
        dataIndex: "hrmFlow",
        key: "hrmFlow",
        align: "left",
        width: 200,
        ...filterSearchColumn("hrmFlow", "สายอนุมัติ"),
    },
    {
        title: "ประเภทพนักงานในระบบเงินเดือน",
        dataIndex: "typeEmpSalary",
        key: "typeEmpSalary",
        align: "left",
        width: 200,
        ...filterSearchColumn("typeEmpSalary", "ประเภทพนักงานในระบบเงินเดือน"),
    },
    {
        title: "เลขที่ล๊อคเกอร์",
        dataIndex: "lockerId",
        key: "lockerId",
        align: "left",
        width: 200,
        ...filterSearchColumn("lockerId", "เลขที่ล๊อคเกอร์"),
    },
    {
        title: "ผลการตรวจสารเสพติด",
        dataIndex: "addictiveResult",
        key: "addictiveResult",
        align: "left",
        width: 150,
        ...filterSearchColumn("addictiveResult", "ผลการตรวจสารเสพติด"),
    },
    {
        title: "ผลตรวจอาชญากรรม",
        dataIndex: "crimeResult",
        key: "crimeResult",
        align: "left",
        width: 150,
        ...filterSearchColumn("crimeResult", "ผลตรวจอาชญากรรม"),
    },
    {
        title: "สูบบุหรี่",
        dataIndex: "smoking",
        key: "smoking",
        align: "left",
        width: 100,
        ...filterSearchColumn("smoking", "สูบบุหรี่"),
    },
];

export const ColumnReporttEmployeeSummary = (monthHeader, filterBy) => {
    let columnSup = [];
    const { month, month_2 } = monthHeader
    if (filterBy === "subDep") {
        columnSup = [
            {
                title: "รหัสแผนกย่อย",
                dataIndex: "subDepNo",
                key: "subDepNo",
                align: "left",
                ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
            },
            {
                title: "ชื่อแผนกย่อย",
                dataIndex: "subDepName",
                key: "subDepName",
                align: "left",
                ...filterSearchColumn("subDepName", "ชื่อแผนกย่อย"),
            },
        ]
    }
    return [
        {
            title: "รหัสบริษัท",
            dataIndex: "company",
            key: "company",
            align: "left",
            ...filterSearchColumn("company", "รหัสบริษัท"),
        },
        {
            title: "สถานที่",
            dataIndex: "location",
            key: "location",
            align: "left",
            ...filterSearchColumn("location", "Location"),
        },
        {
            title: "รหัสแผนก",
            dataIndex: "depNo",
            key: "depNo",
            align: "left",
            ...filterSearchColumn("depNo", "รหัสแผนก"),
        },
        {
            title: "ชื่อแผนก",
            dataIndex: "depName",
            key: "depName",
            align: "left",
            ...filterSearchColumn("depName", "ชื่อแผนก"),
        },
        ...columnSup,
        {
            title: `${month}`,
            children: [
                {
                    title: "เข้าใหม่",
                    dataIndex: "newEmployee",
                    key: "newEmployee",
                    align: "right",
                    ...useSorterTable("newEmployee", "number"),
                },
                {
                    title: "พ้นสภาพ",
                    dataIndex: "outEmployee",
                    key: "outEmployee",
                    align: "right",
                    ...useSorterTable("outEmployee", "number"),
                },
                {
                    title: "ย้ายเข้าหน่วยงาน",
                    dataIndex: "moveInEmployee",
                    key: "moveInEmployee",
                    align: "right",
                    ...useSorterTable("moveInEmployee", "number"),
                },
                {
                    title: "ย้ายออกหน่วยงาน",
                    dataIndex: "moveOutEmployee",
                    key: "moveOutEmployee",
                    align: "right",
                    ...useSorterTable("moveOutEmployee", "number"),
                },
                {
                    title: "จำนวนพนักงาน",
                    dataIndex: "totalEmployee",
                    key: "totalEmployee",
                    align: "right",
                    ...useSorterTable("totalEmployee", "number"),
                },
            ],
        },
        {
            title: `${month_2}`,
            children: [
                {
                    title: "เข้าใหม่",
                    dataIndex: "newEmployee_2",
                    key: "newEmployee_2",
                    align: "right",
                    ...useSorterTable("newEmployee_2", "number"),
                },
                {
                    title: "พ้นสภาพ",
                    dataIndex: "outEmployee_2",
                    key: "outEmployee_2",
                    align: "right",
                    ...useSorterTable("outEmployee_2", "number"),
                },
                {
                    title: "ย้ายเข้าหน่วยงาน",
                    dataIndex: "moveInEmployee_2",
                    key: "moveInEmployee_2",
                    align: "right",
                    ...useSorterTable("moveInEmployee_2", "number"),
                },
                {
                    title: "ย้ายออกหน่วยงาน",
                    dataIndex: "moveOutEmployee_2",
                    key: "moveOutEmployee_2",
                    align: "right",
                    ...useSorterTable("moveOutEmployee_2", "number"),
                },
                {
                    title: "จำนวนพนักงาน",
                    dataIndex: "totalEmployee_2",
                    key: "totalEmployee_2",
                    align: "right",
                    ...useSorterTable("totalEmployee_2", "number"),
                },
            ],
        },
    ];
};

export const ColumnReportResultCrime = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "เลขบัตรประชาชน",
        dataIndex: "personalId",
        key: "personalId",
        align: "left",
        width: 150,
        ...filterSearchColumn("personalId", "เลขบัตรประชาชน"),
    },
    {
        title: "วันเดือนปีเกิด",
        dataIndex: "birthday",
        key: "birthday",
        align: "left",
        width: 150,
    },
    {
        title: "ชื่อบิดา-มารดา",
        dataIndex: "parentName",
        key: "parentName",
        align: "left",
        width: 200,
        ...filterSearchColumn("parentName", "ชื่อบิดา-มารดา"),
    },
    {
        title: "ที่อยู่ปัจจุบันและภูมิลำเนา",
        dataIndex: "address",
        key: "address",
        align: "left",
        width: 400,
        ...filterSearchColumn("address", "ที่อยู่ปัจจุบันและภูมิลำเนา"),
    },
    {
        title: "ผลตรวจอาชญากรรม",
        dataIndex: "crimeResult",
        key: "crimeResult",
        align: "left",
        width: 200,
        ...filterSearchColumn("crimeResult", "ผลตรวจอาชญากรรม"),
    },
    {
        title: "หมายเลขหนังสือเดินทาง",
        dataIndex: "passportId",
        key: "passportId",
        align: "left",
        width: 200,
        ...filterSearchColumn("passportId", "หมายเลขหนังสือเดินทาง"),
    },
    {
        title: "บริษัท",
        dataIndex: "Company",
        key: "Company",
        align: "left",
        width: 100,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "Location",
        dataIndex: "Location",
        key: "Location",
        align: "left",
        width: 100,
        ...filterSearchColumn("Location", "Location"),
    },
    {
        title: "รหัสแผนก",
        dataIndex: "depNo",
        key: "depNo",
        align: "left",
        width: 150,
        ...filterSearchColumn("depNo", "รหัสแผนก"),
    },
    {
        title: "ชื่อแผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "ชื่อแผนก"),
    },
    {
        title: "รหัสแผนกย่อย",
        dataIndex: "subDepNo",
        key: "subDepNo",
        align: "left",
        width: 200,
        ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
    },
    {
        title: "ชื่อแผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "ชื่อแผนกย่อย"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "startDateJob",
        key: "startDateJob",
        align: "left",
        width: 150,
    },
    {
        title: "ชุดเอกสาร(ระบบ)",
        dataIndex: "refDocNo",
        key: "refDocNo",
        align: "left",
        width: 150,
        ...filterSearchColumn("refDocNo", "ชุดเอกสาร(ระบบ)"),
    },
    {
        title: "ชุดเอกสาร(เพิ่มเติม)",
        dataIndex: "externalDoc",
        key: "externalDoc",
        align: "left",
        width: 150,
        ...filterSearchColumn("externalDoc", "ชุดเอกสาร(เพิ่มเติม)"),
    },
    {
        title: "วันที่ยื่น",
        dataIndex: "prepareDate",
        key: "prepareDate",
        align: "right",
        width: 150,
    },
    {
        title: "วันที่ส่งตรวจ",
        dataIndex: "sendDate",
        key: "sendDate",
        align: "right",
        width: 150,
    },
    {
        title: "วันที่รับผลตรวจ",
        dataIndex: "receivedDate",
        key: "receivedDate",
        align: "right",
        width: 150,
    },
];

export const ColumnReportPersonal = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "ID_User",
        key: "ID_User",
        align: "left",
        width: 100,
        ...filterSearchColumn("ID_User", "รหัสพนักงาน"),
    },
    {
        title: "Username",
        dataIndex: "Username",
        key: "Username",
        align: "left",
        width: 100,
        ...filterSearchColumn("Username", "Username"),
    },
    {
        title: "คำนำหน้า",
        dataIndex: "title",
        key: "title",
        align: "left",
        width: 100,
        ...filterSearchColumn("title", "คำนำหน้า"),
    },
    {
        title: "ชื่อ - นามสกุล",
        dataIndex: "Name",
        key: "Name",
        align: "left",
        width: 250,
        ...filterSearchColumn("Name", "ชื่อ - นามสกุล"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "NickName",
        key: "NickName",
        align: "left",
        width: 120,
        ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 120,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "locationName",
        key: "locationName",
        align: "left",
        width: 120,
        ...filterSearchColumn("locationName", "สถานที่"),
    },
    {
        title: "แผนก",
        dataIndex: "Department_Name",
        key: "Department_Name",
        align: "left",
        width: 250,
        ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 200,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "ตำแหน่ง",
        dataIndex: "PositionNameEN",
        key: "PositionNameEN",
        align: "left",
        width: 200,
        ...filterSearchColumn("PositionNameEN", "ตำแหน่ง"),
    },
    {
        title: "สายการอนุมัติ",
        dataIndex: "Flow_Name",
        key: "Flow_Name",
        align: "left",
        width: 350,
        ...filterSearchColumn("Flow_Name", "สายการอนุมัติ"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "StartDateJob",
        key: "StartDateJob",
        align: "left",
        width: 120,
        ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "วันที่ผ่านทดลองงาน",
        dataIndex: "probationDateEnd",
        key: "probationDateEnd",
        align: "left",
        width: 120,
        render: (text) => {
            return text || '-'
        },
        ...filterSearchColumn("probationDateEnd", "วันที่ผ่านทดลองงาน"),
    },
    {
        title: "วันที่สิ้นสุดสัญญาจ้าง",
        dataIndex: "endContractDate",
        key: "endContractDate",
        align: "left",
        width: 120,
        render: (text) => {
            return text || '-'
        },
        ...filterSearchColumn("endContractDate", "วันที่สิ้นสุดสัญญาจ้าง"),
    },
    {
        title: "วันที่ผ่านโปรพนักงานประจำ",
        dataIndex: "probationDateEnd_Fulltime",
        key: "probationDateEnd_Fulltime",
        align: "left",
        width: 120,
        render: (text) => {
            return text || '-'
        },
        ...filterSearchColumn("probationDateEnd_Fulltime", "วันที่ผ่านทดลองงาน"),
    },
    {
        title: "วันที่พ้นสภาพการทำงาน",
        dataIndex: "EndDateJob",
        key: "EndDateJob",
        align: "left",
        width: 120,
        render: (text) => {
            return text || '-'
        },
        ...filterSearchColumn("EndDateJob", "วันที่พ้นสภาพการทำงาน"),
    },
    {
        title: "สถานภาพพนักงาน",
        dataIndex: "currentStatusDes",
        key: "currentStatusDes",
        align: "left",
        width: 120,
        ...filterSearchColumn("currentStatusDes", "สถานภาพพนักงาน"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "statusWorker",
        key: "statusWorker",
        align: "left",
        width: 120,
        ...filterSearchColumn("statusWorker", "สถานะพนักงาน"),
    },
    {
        title: "ไซส์เสื้อ",
        dataIndex: "shirtSize",
        key: "shirtSize",
        align: "left",
        width: 100,
        ...filterSearchColumn("shirtSize", "ไซส์เสื้อ"),
    },
    {
        title: "กลุ่มเงินเดือน",
        dataIndex: "payrollGroupDes",
        key: "payrollGroupDes",
        align: "left",
        width: 150,
        ...filterSearchColumn("payrollGroupDes", "กลุ่มเงินเดือน"),
    },
    {
        title: "สแกนลายนิ้วมือ",
        dataIndex: "codeMapFingerScan",
        key: "codeMapFingerScan",
        align: "left",
        width: 120,
        ...filterSearchColumn("codeMapFingerScan", "สถานะผ่านทดลองงาน"),
    },

    {
        title: "รหัสบัตรประชาชน",
        dataIndex: "Personal_id",
        key: "Personal_id",
        align: "left",
        width: 150,
        ...filterSearchColumn("Personal_id", "รหัสบัตรประชาชน"),
    },


];