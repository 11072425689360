import { Table } from 'antd';
import React from 'react';

const TableForm = ({
  rowSelection,
  columns,
  dataSource,
  expandable,
  components,
  loading,
  style,
  disablePagination,
  rowClassName,
  summary,
}) => {
  return (
    <div style={style}>
      <Table
        locale={{
          emptyText: <span>ไม่พบข้อมูล</span>,
        }}
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        expandable={expandable}
        columns={columns}
        dataSource={dataSource || []}
        bordered
        components={components}
        size="small"
        loading={loading}
        scroll={{ x: 'max-content' }}
        summary={summary}
        pagination={
          disablePagination
            ? {}
            : {
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} จาก ${total} รายการ`,
                responsive: true,
                defaultPageSize: 10,
                showSizeChanger: true,
              }
        }
        sticky
      />
    </div>
  );
};

export default TableForm;
