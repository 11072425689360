import React, { useContext, useEffect, useState, useRef } from "react";
import { Modal, Spin, Button, Tooltip } from "antd";

import { MasterCenterContext } from "../../service/master/masterProvider";
import { PersonalContext } from "../servicesPersonal/personalProvider";
import { PATHNAME } from "../../constants/enums/pathname";
import { SetDateAPI } from "../../common/setDate";

import LayoutSearch from "../../components/structuresReport/layoutSearch";
import LayoutTable from "../../components/structuresReport/layoutTable";
import { LayoutTabs } from "../../components/structuresReport/layoutTabs";
import { RenderInput } from "../componentsPersonal";
import { TEXT_BUTTON } from "../../constants/buttons";
import { APILineNoti, KEY_PERSONAL } from "../servicesPersonal";
import {
  ButtonExport,
  ContainerButton,
} from "../../components/structuresReport/buttons";
import {
  LayoutBreadcrumb,
  LayoutWrapper,
} from "../../moduleKPIs/common/layouts";
import {
  KEY_TYPE,
  KEY_OPTION,
  TEXT_LOADING,
  KEY_ACTIVE_TAB,
} from "../../constants/enums/enumCenter";
import {
  FORM_DASHBOARD_PERSONLA,
  ICON_DASHBOARD_PERSONLA,
  TEXT_DASHBOARD_PERSONLA,
} from "../constantsPersonal/dashboards/dashboardPersonal";
import { EditOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { colors } from "../../themes";
import { encryptString } from "../../service/functions/encodeFunction";
import { set } from "lodash";

/**
 * TODO : ถ้าต้องการเปลี่ยนข้อมูล ตาม Report
 * ! ใน API_PAGE อย่าลืมเพิ่ม fetchAPIPersonal ตาม KEY_API
 * @param API_PAGE - เปลี่ยน KEY_API
 * @param TEXT - เปลี่ยน TEXT_PAGE
 * @param ICON - เปลี่ยน TEXT_PAGE
 * @param FORM - เปลี่ยน FORM_PAGE
 * @param DEFAULT_SEARCH - เปลี่ยน DEFAULT_SEARCH
 *
 */

const DashboardPersonal = (props) => {
  const refExport = useRef(); // TODO: The reference of export button Excel
  const layoutRef = useRef();
  /* --------------------------------- CONTEXT -------------------------------- */
  const { personalState, fetchAPIPersonal, loadingAPI } =
    useContext(PersonalContext);
  const { getMaster, loadingMaster, masterCenterState, clearMaster } =
    useContext(MasterCenterContext);

  /* -------------------------------------------------------------------------- */

  /* --------------------------------- CONTENT -------------------------------- */
  let API_PAGE = `${KEY_PERSONAL.DASHBOARD_PERSONAL}`; //! อย่าลืมเปลี่ยน KEY_API
  let TEXT_PAGE = TEXT_DASHBOARD_PERSONLA; //! อย่าลืมเปลี่ยน TEXT_PAGE
  // let ICON_PAGE = ICON_DASHBOARD_PERSONLA;                    //! อย่าลืมเปลี่ยน ICON_PAGE
  let FORM_PAGE = FORM_DASHBOARD_PERSONLA; //! อย่าลืมเปลี่ยน FORM_PAGE
  let DEFAULT_TAB = KEY_ACTIVE_TAB.UPDATE_PERSONAL; //! อย่าลืมเปลี่ยน DEFAULT_TAB
  let DATASOURCE = personalState[API_PAGE]["dataSource"];
  let ROWKEY = personalState[API_PAGE]["rowKey"];

  let location = props.locationM;

  //TODO : SET Default Search
  let DEFAULT_SEARCH = {
    company: "",
    location: "",
    depNo: "",
    subDepNo: "",
    statusWorker: "",
    userID: "",
  };
  // console.info({ DATASOURCE, ROWKEY })
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- STATE --------------------------------- */
  const [searchProsoft, setSearchProsoft] = useState({ ...DEFAULT_SEARCH });
  const [errorField, setErrorField] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- USE_EFFECT ------------------------------- */

  async function fetchAPI() {
    try {
      await Promise.all([
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_LOCATION,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_COMPANY,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_STATUS_WORKERS,
          typeOption: "none",
        }),
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY,
          typeOption: "none",
          payload: { block: "all" },
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAPI();
  }, []);
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- STATE --------------------------------- */
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                  Function                                 */
  /* -------------------------------------------------------------------------- */
  async function handleSearch(values) {
    try {
      setSearchProsoft({ ...values, activeTab });
      await fetchAPIPersonal({
        key: KEY_PERSONAL.DASHBOARD_PERSONAL,
        data: { ...values, activeTab },
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleTabs(_activeKey) {
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */
    switch (+_activeKey) {
      case 1:
        /* ----------------------------- อัพเดต Key Tabs ---------------------------- */
        setActiveTab(KEY_ACTIVE_TAB.UPDATE_PERSONAL);
        /* ------------------------ เปลี่ยน Tabs แล้วให้ค้นหา ----------------------- */
        fetchAPIPersonal({
          key: KEY_PERSONAL.DASHBOARD_PERSONAL,
          data: { ...DEFAULT_SEARCH, activeTab },
        });
        break;
      default:
        break;
    }
  }

  function handleExportFile() {
    fetchAPIPersonal({
      key: KEY_PERSONAL.EXPORT_PROSOFT,
      data: { ...searchProsoft },
    });
  }

  function handleExportFileTiger() {
    fetchAPIPersonal({
      key: KEY_PERSONAL.EXPORT_TIGER_SOFT,
      data: { ...searchProsoft },
    });
  }

  function handleChange(item) {
    const { field, value } = item;
    let objState = { ...searchProsoft, [field]: value };
    const { company: c, location: lc } = objState;
    let fieldArr = [];
    console.log(item);
    if (errorField.includes(field))
      setErrorField(errorField.filter((e) => e !== field));
    switch (field) {
      case "company":
        objState = {
          ...objState,
          location: "",
          depNo: "",
          subDepNo: "",
        };
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
          payload: { company: c || "", location: lc || "" },
          typeOption: "none",
        });
        clearMaster({ key: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT });
        fieldArr = [...fieldArr, "location", "depNo", "subDepNo"];
        break;
      case "location":
        objState = {
          ...objState,
          depNo: "",
          subDepNo: "",
        };
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL,
          payload: { company: c || "", location: lc || "" },
          typeOption: "none",
        });
        clearMaster({ key: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT });
        fieldArr = [...fieldArr, "depNo", "subDepNo"];
        break;
      case "depNo":
        const { depNo: d } = objState;
        objState = {
          ...objState,
          subDepNo: "",
        };
        getMaster({
          fieldname: KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT,
          payload: { depNo: d },
          typeOption: "none",
        });
        fieldArr = [...fieldArr, "subDepNo"];
        break;
      case "startDate":
        if (value === "") {
          setErrorField(errorField.filter((field) => field !== "endDate"));
        } else {
          setErrorField([...errorField, "endDate"]);
        }
        break;
      case "endDateJobFrom":
        if (value === "") {
          setErrorField(errorField.filter((field) => field !== "endDateJobTo"));
        } else {
          setErrorField([...errorField, "endDateJobTo"]);
        }
        break;
    }

    layoutRef.current && layoutRef.current.onClearValue(fieldArr);
    setSearchProsoft(objState);
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  const RENDER_TABLE = () => {
    return (
      <LayoutTable
        title={null} // TODO : <TitleTable contentList={[{ title: '', label: '' }]} />
        loading={loadingAPI}
        rowKey={ROWKEY[activeTab]}
        dataSource={DATASOURCE[activeTab]}
        columns={FORM_PAGE.TABLE({
          location,
          activeTab,
          actionButton: RENDER_BUTTON_TABLE,
        })}
      />
    );
  };
  // TODO : FORM เพิ่มแบบทดลองงาน
  const RENDER_INSERT_PROBATION_HISTORY = (_userId, _userLogin) => {
    const [visible, setVisible] = useState(false);
    const [probationDate, setProbationDate] = useState("");

    function openModal() {
      setVisible(true);
    }

    function handleCancel() {
      setVisible(false);
      setProbationDate("");
    }

    async function handleOk() {
      try {
        let data = {
          startProbationDate: SetDateAPI(probationDate),
          userId: _userId,
          probationStatus: 0,
          userCreated: _userLogin,
        };
        let response = await fetchAPIPersonal({
          key: KEY_PERSONAL.INSERT_PROBATION_HISTORY,
          data,
        });
        if (!!response && response.success) {
          Modal.success({
            title: "เพิ่มช่วงทดลองงานเรียบร้อยแล้ว",
            onOk: () =>
              fetchAPIPersonal({
                key: KEY_PERSONAL.DASHBOARD_PERSONAL,
                data: { ...DEFAULT_SEARCH, activeTab },
              }),
          });
        }
      } catch (error) {
        console.log("[CATCH] RENDER_INSERT_PROBATION_HISTORY : ", error);
        setVisible(false);
      } finally {
        setVisible(false);
        setProbationDate("");
      }
    }

    return (
      <>
        <Button
          type="primary"
          style={{ display: "flex", alignItems: "center" }}
          icon={<PlusOutlined />}
          onClick={openModal}
        >
          <Tooltip title="เพิ่มแบบประเมินทดลองงาน">{"เพิ่ม"}</Tooltip>
        </Button>
        <Modal
          title="โปรดระบุวันที่เริ่มทดลองงานรอบใหม่"
          width={500}
          centered
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <RenderInput
            style={{ margin: "10px 0px" }}
            type={KEY_TYPE.DATE_PICKER}
            value={probationDate}
            onChange={(val) => setProbationDate(val)}
          />
        </Modal>
      </>
    );
  };

  const RENDER_BUTTON_TABLE = (text, record) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {
          // (+record.probationStatus === 1) &&
          //(+record.probationStatus === 1 && record.Status_Worker !== 'Block') &&
          // (record.Status_Worker !== 'Block') &&
          /** กรณีมีการประเมินทดลองงานแล้วมากกว่า 1 ครั้ง */
          //TODO: [Render] Form Insert Probation History => "startProbationDate" Round than more 2
          //  RENDER_INSERT_PROBATION_HISTORY(record.ID_User, props.userLogin)
        }
        {
          // TODO : BUTTON แจ้งพ้นสภาพ
          //(record.Status_Worker !== 'Block' || +record.Blocked !== 1) &&
          +record.Blocked !== 1 && (
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "5px",
              }}
              icon={<StopOutlined />}
              type="primary"
              danger
              onClick={() => {
                const param = encodeURIComponent(encryptString(record.ID_User)); //encrypt improve security
                window.open(`${PATHNAME.BLOCK_PERSONAL}/${param}`);
              }}
            >
              {TEXT_BUTTON.BLOCK_USER}
            </Button>
          )
        }
        <Button
          // TODO : BUTTON แก้ไข
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: colors.wraning,
            border: 0,
            margin: "0px 5px",
          }}
          icon={<EditOutlined />}
          type="primary"
          onClick={() => {
            const param = encodeURIComponent(encryptString(record.ID_User)); //encrypt improve security
            window.open(`${PATHNAME.UPDATE_PERSONAL}/${param}`);
          }}
        >
          {TEXT_BUTTON.UPDATE}
        </Button>
      </div>
    );
  };

  const RENDER_BUTTON_EXPORT = () => {
    return (
      <ContainerButton right>
        <Button
          type="primary"
          ghost
          disabled={loadingAPI || loadingMaster} // รอโหลดทุกอย่างเสร็จถึงจะเปิดปุ่มให้ทำการ Download ได้
          style={{ marginTop: 5 }}
          onClick={() => handleExportFile()}
        >
          {TEXT_BUTTON.EXPORT_INFO_EMP}
        </Button>
        <Button
          type="primary"
          ghost
          disabled={loadingAPI || loadingMaster} // รอโหลดทุกอย่างเสร็จถึงจะเปิดปุ่มให้ทำการ Download ได้
          style={{ marginTop: 5, marginLeft: 5 }}
          onClick={() => handleExportFileTiger()}
        >
          {TEXT_BUTTON.EXPORT_INFO_EMP_TIGER}
        </Button>
        {/* ------------------------------ EXPORT EXCEL ------------------------------ */}
        <ButtonExport
          ref={refExport}
          columns={FORM_PAGE.EXPORT_EXCEL({ location, activeTab })}
          fieldname={TEXT_PAGE.FILE_EXPORT[activeTab]}
          dataSource={DATASOURCE[activeTab]}
        />
        {/* -------------------------------------------------------------------------- */}
      </ContainerButton>
    );
  };

  return (
    <Spin spinning={loadingMaster && loadingAPI} tip={TEXT_LOADING}>
      <LayoutWrapper
        breadcrumb={
          <LayoutBreadcrumb
            breadcrumbLists={FORM_PAGE.BREADCRUMB({
              pathname: PATHNAME.DASHBOARD_PERSONAL,
            })}
          />
        }
        search={
          <LayoutSearch
            ref={layoutRef}
            initialValues={{ ...DEFAULT_SEARCH }}
            onSearch={handleSearch}
            disabledSearch={false}
            disabledClear={false}
            onHandleChange={handleChange}
            searchLists={FORM_PAGE.SEARCH({
              search: { ...DEFAULT_SEARCH },
              location,
              activeTab,
              options: { ...masterCenterState },
              errorField,
            })}
            errorField={errorField}
          />
        }
        content={
          <div className="layout-content-card" style={{ padding: 10 }}>
            <LayoutTabs
              type="card"
              defaultKey="1"
              onChange={handleTabs}
              tabsLists={FORM_PAGE.TABS({
                location,
                activeTab,
                renderTable: RENDER_TABLE(),
              })}
              extraContent={RENDER_BUTTON_EXPORT()} //component for render button export
            />
          </div>
        }
      />
    </Spin>
  );
};

export default DashboardPersonal;
