import React, { useContext, useEffect, useState } from 'react'
import { ReportContext, REPORT_TYPE } from '../services/report/reportProvider';
import { STRUCTURE_INDICATOR } from '../constants/structures/structureIndicator';
import { TableNested } from '../../constants/tables/TableNested';
import ContentInfoEmployee from './contentInfoEmployee';
import { MasterContext } from '../services/master/masterProvider';
import { Spin } from 'antd';

export const ReportIndicator = () => {
    const STRUCTURE = STRUCTURE_INDICATOR
    const { reportState, expandColumsIndicator, searchReport } = useContext(ReportContext)
    const { masterState, defaultMaster } = useContext(MasterContext)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        async function fetchReport() {
            await setLoading(true)
            await searchReport({ reportName: REPORT_TYPE.INDICATOR_HEADER, searchOption: masterState })
            // await getAPIReport({ nameAPI: 'APIReportIndicatorHeader' })
            await defaultMaster()
            await setLoading(false)
        }
        fetchReport()
    }, [])
    return (
        <div style={{ padding: 10 }}>
            <Spin spinning={loading} tip="กำลังโหลด...">

                <TableNested
                    rowKey={"indicatorHeaderId"}
                    columns={STRUCTURE.COLUMN_INDICATOR_ALL({
                        action: (record) => (<ContentInfoEmployee content={{ result: record }} />)
                    })}
                    onExpand={expandColumsIndicator}
                    columnsExpanded={STRUCTURE.COLUMN_INDICATOR_EXPANDED()}
                    dataSource={reportState.indicatorHeaderLists}
                    expandedField="indicator"
                />
            </Spin>
        </div>
    )
};

