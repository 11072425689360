import React from "react";
import { Tabs } from "antd";
import FormSearchBranchView from "./conponents/forms/FormSearchBranchView";
import FormAddBranchView from "./conponents/forms/FormAddBranchView";

const ManageBranchView = () => {
  const { TabPane } = Tabs;

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="ดูข้อมูลสายการดูข้อมูล" key="1">
        <FormSearchBranchView />
      </TabPane>
      <TabPane tab="เพิ่มสายการดูข้อมูล" key="2">
        <FormAddBranchView />
      </TabPane>
    </Tabs>
  );
};

export default ManageBranchView;
