import { useState } from "react";
import { mapSelectedOptionData } from "../function/map";
import { onPrintPDF } from "../function/print";
import { POST_DOC_PRINT_BENEFITS, POST } from "../../../../service";

export const useGetFormPrint = ({ documentList, setActiveKey, docID }) => {
  const [formPrint, setFormPrint] = useState([]);
  const [loadingFormPrint, setLoadingFormPrint] = useState([]);

  const onGetFormPrint = async (value) => {
    const data = await onFetchFormPrint(value);
    if (data) {
      setFormPrint(() => data);
    }
  };

  const onPrintAll = async (value) => {
    const data = await onFetchFormPrint(value);
    if (data) {
      onPrintPDF(data.reduce((prev, cur) => prev + cur.formPrint, ""));
      setFormPrint(() => data);
    }
  };

  const onFetchFormPrint = async (value) => {
    try {
      setLoadingFormPrint(true);
      setActiveKey(["attachment", "formPrint"]);
      const docSelected = mapSelectedOptionData(value, documentList);
      const respond = await POST(POST_DOC_PRINT_BENEFITS, {
        docSelected,
        docID: docID.current,
        signer: value.signer,
      });
      return respond.result.docFormPrint;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoadingFormPrint(false);
    }
  };

  return {
    formPrint,
    loadingFormPrint,
    onGetFormPrint,
    onPrintAll,
  };
};
