import React, { useState, useEffect } from "react";
import { browserHistory } from "react-router";
import { Layout, Col, Form, Input, Row, Spin } from "antd";
import { PATHNAME } from "../constants/enums/pathname";
import { useConfirm } from "../screens/conponents/modal/CustomConfirm";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import { FORGOT_PASSWORD, POST } from "../service";
import { VERSION_TEXT } from "../constants/enums/enumCenter";
import { useButton } from "../screens/conponents/buttons/CustomButtonAntd";
import dayjs from "dayjs";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const queryParams = new URLSearchParams(window.location.search);
  const USER_ID = decodeURIComponent(queryParams.get("userID"));
  const EXPIRE_DATE = decodeURIComponent(queryParams.get("expireDate"));

  useEffect(() => {
    const now = dayjs();
    const expireDate = dayjs(EXPIRE_DATE, "YYYY-MM-DD HH:mm");

    if (now.isAfter(expireDate)) {
      useAlert({
        type: "warn",
        title: "คุณไม่ได้ทำรายการในเวลาที่กำหนด กรุณากดลืมรหัสผ่านใหม่",
        moreProps: {
          onOk: () =>
            browserHistory.push(`${PATHNAME.FORGOT_PASSWORD_REQUEST}`),
        },
      });
    }
  }, [EXPIRE_DATE]);

  const onFinish = (value) => {
    const { newPassword, newPasswordRecheck } = value;

    const payload = {
      userID: USER_ID,
      newPassword: newPassword,
      newPasswordRecheck,
    };
    useConfirm({
      content: `ยืนยันรหัสผ่านใหม่`,
      FnOK: () => onForgotPassword(payload),
    });
  };

  const onForgotPassword = async (value) => {
    setLoading(true);
    try {
      const res = await POST(FORGOT_PASSWORD, value);

      const { success } = res;
      if (success) {
        useAlert({
          type: "success",
          title: "เปลี่ยนรหัสผ่านใหม่สำเร็จ",
          moreProps: {
            onOk: () => browserHistory.push(`account${PATHNAME.LOGIN}`),
          },
        });
      }
    } catch (error) {
      useAlert({
        type: "error",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Layout style={{ backgroundColor: "#FFF" }}>
        <Layout.Header
          style={{
            color: "#fff",
            fontSize: "x-large",
            textAlign: "center",
            backgroundColor: "#3368df",
          }}
        >
          HR - PMS System
        </Layout.Header>
        <Layout.Content
          style={{ padding: 50, minHeight: `calc(100vh - 135px)` }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="รหัสผ่านใหม่ (New Password)"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก รหัสผ่านใหม่ (New Password)",
                    },
                  ]}
                >
                  <Input.Password
                    allowClear
                    placeholder={`กรุณากรอก รหัสผ่านใหม่ (New Password)`}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="ยืนยันรหัสผ่านใหม่ (Confirm New Password)"
                  name="newPasswordRecheck"
                  rules={[
                    {
                      required: true,
                      message: "กรุณายืนยันรหัสผ่านใหม่ (Confirm New Password)",
                    },
                  ]}
                >
                  <Input.Password
                    allowClear
                    placeholder={`กรุณายืนยันรหัสผ่านใหม่ (Confirm New Password)`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" align="bottom">
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <Form.Item>
                  {useButton({
                    call: "confirm",
                    fn: form.submit,
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center", backgroundColor: "#FFF" }}>
          {`Copyright © Zetta Soft Co.Ltd., | ${VERSION_TEXT}`}
        </Layout.Footer>
      </Layout>
    </Spin>
  );
};

export default ForgotPassword;
