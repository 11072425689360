import { useState, useEffect } from "react";
import { GET, GET_DASHBOARD_FORM_CONTRACT } from "../../../../service";
import moment from "moment";

export const useGetDataSource = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loadingDataSource, setLoadingDataSource] = useState(false);

  useEffect(
    () =>
      onFetchDataSource({
        company: "all",
        empID: "all",
        startDateJob: [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD"),
        ],
      }),
    []
  );

  const onFetchDataSource = async (value) => {
    try {
      const company = value.company == "all" ? "" : value.company;
      const empID = value.empID == "all" ? "" : value.empID;
      const {
        startDateJob = [
          moment(startDateJob[0]).format("YYYY-MM-DD"),
          moment(startDateJob[1]).format("YYYY-MM-DD"),
        ],
      } = value;
      setLoadingDataSource(true);
      const respond = await GET(
        `${GET_DASHBOARD_FORM_CONTRACT}?company=${company || ''}&empID=${empID || ''}&startDate=${startDateJob[0] || ''}&endDate=${startDateJob[1] || ''}`
      );
      setDataSource(() => [
        ...respond.result.map((element) => ({
          ...element,
          key: element.userId,
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataSource(false);
    }
  };

  return {
    dataSource,
    onFetchDataSource,
    loadingDataSource,
  };
};
