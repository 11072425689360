import React, { useState, useEffect } from "react";
import { Spin, Space, Card } from "antd";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnSaleCodeAreaAssignment } from "./constants/SaleCodeAreaAssignment/Columns";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import { RenderMainModal } from "./conponents/modal/CustomModalAntd";
import { POST, GET_MAP_SALE_MO, INSERT_UPDATE_SALE_AREA_FSP } from "../service";
import { useAlert } from "./conponents/modal/CustomAlert";
import { getPeriodDate } from "../functions";
import encodeString from "../extendFunction/encodeString";
import Cookies from "js-cookie";
import { SetStringToObject } from "./../constants/functions/index";
import { ColumnAreaCodeFSP } from "./constants/SaleCodeAreaAssignmentFSP/Columns";

const SaleCodeAreaAssignmentFSP = () => {
  const [showModal, setShowModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [saleListOrigin, setSaleListOrigin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));

  useEffect(() => {
    getMapFspAreaList();
  }, []);

  const getMapFspAreaList = async () => {
    try {
      setLoading(true);
      const response = await POST(GET_MAP_SALE_MO, {
        periodDate: getPeriodDate("SQL"),
      });
      const { result, success } = response;
      if (success) {
        setDataSource(result.arrfinal);
        const mapSaleListOrigin = result.arrfspChild.map((item, key) => ({
          key: item,
          childCode: item,
        }));
        setSaleListOrigin(mapSaleListOrigin);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
    } finally {
      setLoading(false);
    }
  };

  const onOpenModal = (row) => {
    setShowModal(true);
    setRoleSelected(row.roleId);
    setSelectedRows(row.childCode);
    setSelectedRowKeys(row.childCode);
  };

  const onSelectChangeModal = (selectedRowDataKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowDataKeys);
  };

  const rowsSelectionModal = {
    selectedRowKeys,
    selectedRows,
    onChange: onSelectChangeModal,
  };

  const insertUpdateSaleArea = async (obj) => {
    try {
      setLoading(true);
      const response = await POST(INSERT_UPDATE_SALE_AREA_FSP, obj);
      const { message, success } = response;
      if (success) {
        setShowModal(false);
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          content: message,
          Fn: () => getMapFspAreaList(),
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    const selectChildCode = selectedRows.map((item) =>
      item.childCode ? item.childCode : item
    );
    let obj = {
      roleMain: [roleSelected],
      roleChildSelected: selectChildCode,
      periodDate: getPeriodDate("SQL"),
      userLogin: encodeString.decode(cookies.ID_User),
    };
    useAlert({
      type: "confirm",
      title: "ยืนยันการบันทึก",
      Fn: () => insertUpdateSaleArea(obj),
    });
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
      <RenderMainModal
        show={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        txtTitle={`บันทึกพื้นที่ ${roleSelected}`}
        ModalContent={
          <div>
            <CustomTableAtnd
              HeadTable={ColumnAreaCodeFSP}
              DataTable={saleListOrigin}
              moreProps={{
                rowSelection: rowsSelectionModal,
                scroll: {
                  x: sumColumnWidth(ColumnAreaCodeFSP) || 2050,
                  y: 500,
                },
                size: "small",
              }}
            />
            <div style={{ textAlignLast: "right", marginTop: "15px" }}>
              <Space>
                {useButton({
                  call: "save",
                  moreProps: { onClick: () => onSave(true) },
                })}
                {useButton({
                  call: "normal",
                  fn: () => {},
                  moreProps: {
                    label: "ยกเลิก",
                    onClick: () => setShowModal(false),
                  },
                })}
              </Space>
            </div>
          </div>
        }
        width={800}
      />

      <Card
        title={`กำหนดพื้นที่ยอดขายโฟกัสช็อป (รอบวันที่ ${getPeriodDate(
          "display"
        )})`}
      >
        <CustomTableAtnd
          HeadTable={ColumnSaleCodeAreaAssignment({ action: onOpenModal })}
          DataTable={dataSource}
          moreProps={{
            scroll: {
              x:
                sumColumnWidth(
                  ColumnSaleCodeAreaAssignment({
                    action: () => setShowModal(true),
                  })
                ) || 2050,
              y: 500,
            },
            size: "small",
          }}
        />
      </Card>
    </Spin>
  );
};

export default SaleCodeAreaAssignmentFSP;
