import React from "react";
import moment from "moment";
import {
  Radio,
  Input,
  Button,
  Divider,
  Checkbox,
  Select,
  DatePicker,
  Descriptions,
  Space,
  Row,
} from "antd";
import "../CSSPersonal.css";

import { ButtonTheme } from "../../constants/buttons";
import {
  LayoutPosition,
  TextTiltle,
  TextLabel,
  LayoutButton,
} from "../StyledPersonal.jsx";
import { KEY_TYPE } from "../../constants/enums/enumCenter";
import LayoutLists from "./layoutLists";
import LayoutListCards from "./layoutListCards";
import { UploadAvatar } from "./uploadAnts";
import { UploadFiles } from "./uploadAnts/uploadFiles";
import { validateTextEN } from "../../constants/functions";
import { modifyOptionIdToValue } from "../functions/modifyField";
import Text from "antd/lib/typography/Text";
import { colors } from "../../themes";
import { FIELD_EMP } from "../constantsPersonal/contents/employeeInfo";
import { useText } from "../../screens/conponents/typography/Typography.js";

const RENDER_TITLE = (_key) => {
  switch (_key) {
    case FIELD_EMP.EMPLOYEE_ID:
      return "รหัสพนักงาน";
    case FIELD_EMP.FULLNAME_TH:
      return "ชื่อ-นามสกุล";
    case FIELD_EMP.START_DATE_JOB:
      return "วันที่เริ่มงานเดิม";
    case FIELD_EMP.END_DATE_JOB:
      return "วันที่สิ้นสุดการทำงานเดิม";
    case FIELD_EMP:
      return "หมายเหตุ";
    default:
      break;
  }
};
//[{    key: FIELD_EMP.EMPLOYEE_ID, label: `${values[FIELD_EMP.EMPLOYEE_ID]}`}]
//TODO : Render Content ข้อมูลพนักงาน
export const MAP_CONTENT_EMPINFO = (_content) => (
  <div style={{ paddingTop: 5 }}>
    {Array.isArray(_content) &&
      _content.map((con, i) => (
        <Descriptions key={`line_${i}`} size={"small"}>
          <Descriptions.Item
            label={`${RENDER_TITLE(con.key)} `}
            labelStyle={{ fontWeight: "bold" }}
          >
            {`${con.label || "-"}`}
          </Descriptions.Item>
        </Descriptions>
      ))}
  </div>
);
// return รหัสพนักงาน : CT64002

export const RENDER_BUTTON = ({
  current = 0,
  onPrevious = () => {},
  onNext = () => {},
  onSubmit = () => {},
  label = "",
  htmltype = "",
  color,
  disabled = false,
}) => {
  return (
    <LayoutPosition x="center" y="center">
      <ButtonTheme
        color={color}
        onClick={onSubmit}
        htmltype={htmltype}
        name="submit"
        label={label}
        disabled={disabled}
      />
    </LayoutPosition>
  );
};

export const RENDER_BUTTON_PERSONAL = ({
  current = 0,
  onPrevious = () => {},
  onNext = () => {},
  onSubmit = () => {},
  label = "",
  htmltype = "",
  color,
  disabled = false,
}) => {
  return (
    <LayoutButton>
      <ButtonTheme
        color={color}
        onClick={onSubmit}
        htmltype={htmltype}
        name="submit"
        label={label}
        disabled={disabled}
      />
    </LayoutButton>
  );
};

export const RenderInput = React.forwardRef((element, ref) => {
  switch (element.type) {
    case KEY_TYPE.DATE_PICKER:
      return (
        <>
          <DatePicker
            {...element}
            style={{
              width: "100%",
              ...(element.errorField && element.errorField.includes(element.id)
                ? { border: "1px solid red" }
                : {}),
            }}
            onChange={(date, dateString) => element.onChange(dateString)}
            defaultValue={""}
            value={element.value ? moment(element.value) : ""}
          />
          {element.errorField &&
            element.errorField.includes(element.id) &&
            useText({
              type: "danger",
              text: "กรุณาระบุวันที่",
            })}
        </>
      );
    case KEY_TYPE.DECIMAL:
      return (
        <Input
          {...element}
          type="text"
          autoComplete="off"
          value={element.value}
          onChange={(e) => {
            if (
              !isNaN(parseFloat(e.target.value)) &&
              isFinite(e.target.value)
            ) {
              if (!!element.max && +parseFloat(e.target.value) > +element.max) {
                //TODO: ถ้าค่า value มากกว่า Max จะต้องพิมพ์ไม่ได้
                element.onChange("");
              } else {
                //TODO: ถ้าค่า value น้อยกว่า Max ให้ส่งค่า
                element.onChange(e.target.value);
              }
            } else {
              //TODO : ถ้าค่า value ไม่ใช่ตัวเลขจะต้องพิมพ์ไม่ได้
              element.onChange("");
            }
          }}
        />
      );
    case KEY_TYPE.TEL:
    case KEY_TYPE.NUMBER:
      return (
        <Input
          {...element}
          type="text"
          autoComplete="off"
          value={element.value}
          onChange={(e) => {
            let lengthValue = e.target.value.length;
            if (lengthValue <= element.length) {
              if (
                !isNaN(parseFloat(e.target.value)) &&
                isFinite(e.target.value)
              ) {
                if (!!element.max && +e.target.value > +element.max) {
                  //TODO: ถ้าค่า value มากกว่า Max จะต้องพิมพ์ไม่ได้
                  element.onChange("");
                } else {
                  //TODO: ถ้าค่า value น้อยกว่า length และ น้อยกว่า Max ให้ส่งค่า
                  element.onChange(e.target.value);
                }
              } else {
                //TODO : ถ้าค่า value ไม่ใช่ตัวเลขจะต้องพิมพ์ไม่ได้
                element.onChange("");
              }
            }
          }}
        />
      );
    case KEY_TYPE.SELECT:
      return (
        <Select
          defaultValue={element.defaultValue}
          disabled={element.disabled}
          placeholder={element.placeholder}
          showSearch={`${element.showSearch}` || `true`}
          showArrow={element.showArrow}
          value={modifyOptionIdToValue(element.options, `${element.value}`)}
          onChange={(e, option) => {
            element.onChange(option.props.optionvalue, option.props.optiondata);
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {Array.isArray(element.options) &&
            element.options.map((option, optionIndex) => {
              return (
                <Select.Option
                  key={Number(optionIndex)}
                  value={`${option.optionId}`}
                  optionvalue={
                    option.optionValue ? `${option.optionValue}` : null
                  }
                  optiondata={option.optionData ? option.optionData : {}}
                >
                  {`${option.optionLabel}`}
                </Select.Option>
              );
            })}
        </Select>
      );
    case KEY_TYPE.UPLOAD_FLIES:
      return <UploadFiles {...element} />;
    case KEY_TYPE.UPLOAD_IMAGE:
      return <UploadAvatar {...element} />;
    case KEY_TYPE.CHECKBOX:
      return (
        <Checkbox
          {...element}
          checked={element.value || ""}
          onChange={(e) => element.onChange(e.target.checked)}
        >
          {element["label"]}
        </Checkbox>
      );
    case KEY_TYPE.RADIO:
      return (
        <Radio.Group
          value={element.value}
          disabled={element.disabled}
          onChange={(e) => element.onChange(e.target.value)}
        >
          {element.options.map((option, optionIndex) => (
            <Radio key={optionIndex} value={option.optionValue}>
              {option.optionLabel}
            </Radio>
          ))}
        </Radio.Group>
      );

    case KEY_TYPE.LIST_FORM:
      return <LayoutLists ref={ref} {...element} />;
    case KEY_TYPE.CARD_FORM:
      return <LayoutListCards ref={ref} {...element} />;
    case KEY_TYPE.INPUT_AREA:
      return (
        <Input.TextArea
          {...element}
          autoComplete="off" //เครียร์ค่าใน input
          showcount="true"
          autoSize={{ minRows: 2, maxRows: 6 }}
          maxLength={100}
          value={element.value}
          onChange={(e) => element.onChange(e.target.value)}
        />
      );
    case KEY_TYPE.BUTTON:
      return (
        <Button {...element} type="primary">
          {element.label}
        </Button>
      );
    case KEY_TYPE.ID_CARD:
      return (
        <Input
          {...element}
          autoComplete="off" //เครียร์ค่าใน input
          value={element.value}
          onChange={(e) => {
            let lengthValue = e.target.value.length;
            if (lengthValue <= 13) {
              if (
                !isNaN(parseInt(e.target.value)) &&
                isFinite(e.target.value)
              ) {
                element.onChange(e.target.value.trim());
              } else {
                element.onChange("");
              }
            }
          }}
        />
      );
    case KEY_TYPE.INPUT_EN:
      return (
        <Input
          {...element}
          autoComplete="off" //เครียร์ค่าใน input
          value={element.value}
          onChange={(e) => {
            if (!validateTextEN(e.target.value)) {
              element.onChange("");
            } else {
              element.onChange(e.target.value);
            }
          }}
        />
      );
    case KEY_TYPE.DIVIDER:
      return (
        <Divider
          ref={ref}
          orientation="left"
          style={{ fontWeight: "bold", fontSize: 15, color: colors.theme }}
        >
          <Text type={element.typeText}>{element.title}</Text>
        </Divider>
      );
    case KEY_TYPE.CONTENT:
      return (
        <Descriptions {...element}>
          <Descriptions.Item label={element.label}>
            {element.options ? (
              modifyOptionIdToValue(element.options, element.value, "content")
            ) : (
              <Text type={element.typeText} style={element.styleText}>
                {element.value || "-"}{" "}
              </Text>
            )}
          </Descriptions.Item>
        </Descriptions>
      );
    default:
      return (
        <>
          <Input
            ref={ref}
            {...element}
            autoComplete="off" //เครียร์ค่าใน input
            value={element.value}
            onChange={(e) => {
              if (!e.target.value.trim()) {
                element.onChange("");
              } else {
                element.onChange(e.target.value);
              }
            }}
            style={element.isDuplicate ? { border: "1px solid red" } : {}}
          />
          {element.isDuplicate &&
            useText({
              type: "danger",
              text: "กรุณากรอกใหม่อีกครั้ง",
            })}
        </>
      );
  }
});
