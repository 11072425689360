import React, { useState, useContext, useEffect } from 'react'
import { STRUCTURE_INDICATOR } from '../constants/structures/structureIndicator';
import { TableNested } from '../../constants/tables/TableNested';
import { ReportContext, REPORT_TYPE } from '../services/report/reportProvider';
import { IndicatorContext } from '../services/manage/indicatorProvider';

import { Spin } from 'antd';
import ConfirmIndicator from './modal/confirmIndicator';
import { MasterContext } from '../services/master/masterProvider';

export const AwaitingApproval = () => {
    const STRUCTURE = STRUCTURE_INDICATOR
    const { reportState, expandColumsApproval, searchReport } = useContext(ReportContext)
    const { userLogin } = useContext(IndicatorContext)
    const { defaultMaster } = useContext(MasterContext)

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        async function fetchReport() {
            await setLoading(true)
            // await getAPIReport({ nameAPI: 'APIReportIndicatorApproval' })
            await searchReport({ reportName: REPORT_TYPE.INDICATOR_HEADER })
            await defaultMaster()
            await setLoading(false)
        }
        fetchReport()
    }, [])


    // console.log({ masterState })
    return (
        <div style={{ padding: 10 }}>
            <Spin spinning={loading} tip="กำลังโหลด...">

                <TableNested
                    rowKey={"indicatorHeaderId"}
                    columns={STRUCTURE.COLUMN_INDICATOR_ALL({
                        action: (record) => {
                            // console.log({ record })
                            return (
                                <ConfirmIndicator
                                    approvalNo={record.approvalNo2}
                                    approval={record.approvalNo2 === userLogin}
                                    content={record} />
                            )
                        }
                    })}
                    onExpand={expandColumsApproval}
                    columnsExpanded={STRUCTURE.COLUMN_INDICATOR_EXPANDED()}
                    dataSource={reportState.indicatorApprovalLists}
                    expandedField="indicator"
                />
            </Spin>

        </div>
    )
};


