import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_REPORT_RESULT_CRIME = {
  company: undefined,
  location: undefined,
  depNo: undefined,
  subDepNo: undefined,
  startDate: undefined,
  endDate: undefined,
}

const DEFINE = {
  company: {
    label: "บริษัท",
    name: "company",
  },
  location: {
    label: "สถานที่",
    name: "location",
  },
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  subDepNo: {
    label: "แผนกย่อย",
    name: "subDepNo",
  },
  startDate: {
    label: "วันที่เริ่มงาน",
    name: "startDate",
  },
  endDate: {
    label: "ถึงวันที่",
    name: "endDate",
  },
}

export const ComponentReportResultCrime = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { company, location, depNo, subDepNo, startDate, endDate } = DEFINE
  return [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: company.name,
              label: company.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(company.label),
              disabled: isDisable[company.name] || false,
            },
            fnChange: (value) => handleChange(company.name, value),
            dropdown: options[company.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: location.name,
              label: location.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(location.label),
              disabled: isDisable[location.name] || false,
            },
            fnChange: (value) => handleChange(location.name, value),
            dropdown: options[location.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: subDepNo.name,
              label: subDepNo.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
              disabled: isDisable[subDepNo.name] || false,
            },
            fnChange: (value) => handleChange(subDepNo.name, value),
            dropdown: options[subDepNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDate.name,
              label: startDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDate.name] || false,
            },
            fnChange: (date, dateString) => handleChange(startDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: endDate.name,
              label: endDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[endDate.name] || false,
            },
            fnChange: (date, dateString) => handleChange(endDate.name, dateString),
          },
        },
      ], // column
    }, // Row
  ]
}
