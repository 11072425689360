import React, { useState, useEffect } from "react";
import { Form, Card, Spin } from "antd";
import { Position, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { browserHistory } from "react-router";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsUpdatePosition } from "../../constants/ManagePosition/Components";

export const FormEditPosition = (props) => {
  const [form] = Form.useForm();
  const { positionCode } = props.params;
  const { userlogin } = GET_UserLogin();
  const [loadingScreen, setLoadingScreen] = useState(false);

  // [START] useEffect
  useEffect(() => {
    getDataUpdatePosition();
  }, []);
  // [END] useEffect

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { positionNameEN, positionNameTH } = values;
    useConfirm({
      FnOK: () =>
        updatePosition({ positionNameEN, positionNameTH, userlogin }),
    });
  };
  const onReset = () => {
    getDataUpdatePosition();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const getDataUpdatePosition = async () => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_SEARCH_Position_BY_ID}${positionCode}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const RESULT = data.result;
        const Object_SET_Fields = {
          company: RESULT.company,
          positionCode: RESULT.positionCode,
          positionNameEN: RESULT.positionNameEN,
          positionNameTH: RESULT.positionNameTH,
        };
        form.setFieldsValue(Object_SET_Fields);
      } else {
        useAlert({
          type: "info",
          title: `ไม่พบข้อมูล ${positionCode} ในระบบ`,
          content: `กรุณาลองใหม่อีกครั้ง`,
          Fn: () => browserHistory.push("/ManagePosition"),
        });
      }
    } catch (err) {
      console.log("Error Get Update Position: ", err);
      useAlert({
        type: "error",
        title: "[CATCH] : เกิดข้อผิดพลาด",
        content: `${err.message}`,
      });
    } finally {
      setLoadingScreen(false);
    }
  };

  const updatePosition = async (value) => {
    try {
      setLoadingScreen(true);
      const url = ` ${USE_CONNECT_MODE_AUTO}${Position.PUT_UPDATE_Position}${positionCode}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การแก้ไขตำแหน่งงานสำเร็จ`,
          Fn: () => browserHistory.push("/ManagePosition"),
        });
      } else {
        useAlert({
          type: "info",
          title: `แก้ไขข้อมูลไม่สำเร็จ`,
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error UPDATE Position: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API
  return (
    <Card title="แก้ไขข้อมูลตำแหน่งงาน" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsUpdatePosition({
              options: { company: useOptionSelect("company") },
              onClick: onReset,
            })
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FormEditPosition;
