import React, { Component } from "react";
import { VERSION_TEXT } from "../constants/enums/enumCenter";
import "../index.css";
//import 'bulma/css/bulma.css'
// import HomePic from "../assets/images/homepage.png";

class home extends Component {
  render() {
    return (
      <div className="App">
        <p style={{ textAlign: "center" }}>
          {/* <img src={HomePic} className="img-fluid" alt="Responsive" /> */}
          {/* width={600} */}
        </p>
        {/* <p style={{ textAlign: "center" }}>
          {`Copyright © 2023 Zettasoft Co., Ltd All rights reserved. ${VERSION_TEXT}`}
        </p> */}
      </div>
    );
  }
}

export default home;
