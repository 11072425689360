import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Col, Modal, Row, Spin } from 'antd';
import { browserHistory } from "react-router";

import { LayoutTable } from '../../moduleKPIs/common/layouts';
import ButtonSearch from '../../constants/buttons/buttonSearch';
import { TABLE_CONTRACT } from '../commonsContract/structuresContract/tableContract';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { PATHNAME } from '../../constants/enums/pathname';
import { ButtonExport, FIELD_BUTTON } from '../../constants/buttons';
import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { COLUMNS_EXPORT } from '../commonsContract/structuresContract/columnsContract';


const DashboardEmpContract = ({ pathname, idTab }) => {
	const refExport = useRef();

	const [loading, setLoading] = useState(false);

	const { contractState, getAPI, userLogin, userPermission } = useContext(ContractContext);
	const { masterCenterState } = useContext(MasterCenterContext);

	const { dataTable: { rowKey, dataSource } } = contractState;


	async function onSearch() {
		try {
			await setLoading(true)
			switch (idTab) {
				case '2':
					await getAPI({
						fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_NOTPASS,
						payload: { ...masterCenterState }
					})
					break;

				default:
					await getAPI({
						fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_PASS,
						payload: { ...masterCenterState }
					})
					break;
			}
			await setLoading(false)
		} catch (error) {
			console.log({ error })
			await setLoading(false)
		}
	}


	return (


		<div>
			<div className="layout-content-btn-search">
				<ButtonSearch
					disabled={false}
					onClick={() => onSearch()}
					style={{ padding: 10 }} />
			</div>
			<div className="layout-content-btn-export-track">
				<ButtonExport
					ref={refExport}
					labelButton=""
					fieldname="รายงานพนักงานช่วงทดลองงาน (สัญญาจ้าง)"
					columns={COLUMNS_EXPORT({
						pathname: pathname,
						permission: userPermission
					})}
					dataSource={dataSource}
					style={{ paddingRight: 10 }}
					styleButton={{}}
				/>
			</div>
			<Spin spinning={loading} tip="กำลังโหลด">
				<LayoutTable
					scroll={{ x: 4000 }}
					bordered={false}
					rowKey={rowKey}
					dataSource={dataSource}
					columns={TABLE_CONTRACT({
						pathname,
						userLogin: userLogin,
						actionButton: async ({ fieldBtn, payload }) => {
							try {
								await window.localStorage.setItem(KEY_OPTION.DATA.PROBATION_CONTRACT, JSON.stringify(payload))
								switch (fieldBtn) {
									case FIELD_BUTTON.VIEW_CONTRACT:
										await window.open(PATHNAME.VIEW_PROBATION_CONTRACT, '_blank')
										break;
									default:
										break;
								}
							} catch (error) {
								console.log({ error })

							}
						}
					})}
				/>
			</Spin>
		</div>
	)
}

export default DashboardEmpContract