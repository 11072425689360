import React from 'react'
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';
import { Button } from 'antd';
import { MdFileDownload } from "react-icons/md";


/**
 * @param {*} dataSource
 * @param {*} columns - { title:"",field: ""} or { title:"", children : [{ title:"",field: "" }] }]
 * @param {string} filename 
 * @param {object} style
 */

export const ButtonExport = React.forwardRef(({
    columns,
    dataSource,
    fieldname,
    style,
    styleButton,
    labelButton
}, ref) => {

    async function exportFile() {
        await ref.current.save();
    }

    return (
        <div style={style} >
            <Button type="primary"
                style={styleButton}
                icon={<MdFileDownload style={{ marginRight: 5 }} />}
                disabled={(Array.isArray(dataSource) && dataSource.length > 0) ? false : true}
                onClick={() => exportFile()} >
                {labelButton || ' Export'}
            </Button>
            <ExcelExport
                data={dataSource}
                fileName={fieldname || 'document'}
                ref={(exporter) => { ref.current = exporter }}
            >
                {
                    Array.isArray(columns) && columns.map((element, index) => (
                        (element.children) ?
                            <ExcelExportColumnGroup
                                key={index}
                                title={`${element.title}`}
                                headerCellOptions={{ textAlign: 'center' }}
                            >
                                {
                                    element.children.map((each, i) => (
                                        <ExcelExportColumn key={i} field={`${each.field}`} title={`${each.title}`} />
                                    ))
                                }
                            </ExcelExportColumnGroup>
                            :
                            <ExcelExportColumn key={index}
                                field={`${element.field}`}
                                title={`${element.title}`}
                                cellOptions={element.cellOptions}
                            />
                    ))
                }
            </ExcelExport>
        </div >
    )
});