import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

const DEFINE = {
	file: {
		label: "ไฟล์",
		name: "file",
	},
}

export const ComponentImportMapFocusShopSaleCode = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
	...moreProps
}) => {
	const { file } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.UPLOAD,
						itemProps: {
							name: file.name,
							label: file.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(file.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(file.label),
							disabled: isDisable[file.name] || false,
							// fileList: moreProps.fileList || []
						},
						fnChange: (value) => handleChange(file.name, value),
					},
				},
			], // column
		}, // Row
	]

}
