export const CANVAS_DISC = {
    xField: 'Type',
    yField: 'Score',
    padding: 'auto',
    forceFit: true,
    seriesField: 'Type',
    color: ['#58d68d', '#ec7063', '#5dade2', '#f5b041'],
    legend: {
        position: 'right-top',
    },
    label: {
        visible: true,
        type: 'point',
    },
    lineStyle: {
        opacity: 0,
    },
    point: {
        visible: true,
        size: 5,
        shape: 'cross',
        style: {
            fill: 'white',
            stroke: '#000',
            lineWidth: 1.5,
        },
    },
}