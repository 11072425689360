import { renderMonth } from '../../constants/functions';
import { getCurrent } from '../../extendFunction/getCurrentYears';
import moment from 'moment';

var _year = getCurrent().yearsSelect
export const optionYears = [
	{ value: "0", label: "กรุณาเลือกปี" },
	{ value: _year, label: _year },
	{ value: _year + 1, label: _year + 1 },
	{ value: _year + 2, label: _year + 2 }
];

export const optionSegment = [{ value: "0", label: "-----All-----" }];

export const optionSumProbation = [
	{ value: "", label: "----- ทั้งหมด -----" },
	{ value: "0", label: "รอสร้างประเมิน" },
	{ value: "1", label: "อยู่ระหว่างประเมิน" },
	{ value: "2", label: "รอส่งเอกสารให้  HR รับ" },
	{ value: "3", label: "จบการประเมิน" }
];

export const optionProbationStatus = [
	{ value: "0", label: "อยู่ในช่วงทดลองงาน" },
	{ value: "1", label: "ผ่านการทดลองงานแล้ว" },
	{ value: "2", label: "ไม่ผ่านการทดลองงาน" },
	{ value: "3", label: "อื่นๆ (ต่อช่วงทดลองงาน)", other: true },
];

export const optionStatusWorkerCode = [
	{ value: "Full", label: "พนักงานประจำรายเดือน" },
	{ value: "Temp", label: "พนักงานสัญญาจ้างชั่วคราว" },
	{ value: "Daily", label: "พนักงานประจำรายวัน" },
	// { value: "Block", label: "พ้นสภาพการเป็นพนักงาน" },
	// { value: "notCome", label: "พนักงานไม่มาเริ่มงานตามตกลง" }
]

// //สำหรับ UAT ข้อมูลเดือน 1
// export const optionInstallmentSaleStatus = [
// 	{ value: `new1 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).format('YYYY')}` },
// 	{ value: `new2 | ${moment(new Date()).subtract(3, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(2, 'months').format('M')).sortTH} ${moment(new Date()).subtract(1, 'months').format('YYYY')}` },
// 	{ value: `new3 | ${moment(new Date()).subtract(4, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(3, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(3, 'months').format('M')).sortTH} ${moment(new Date()).subtract(2, 'months').format('YYYY')}` },
// 	{ value: `new4 | ${moment(new Date()).subtract(5, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(4, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(4, 'months').format('M')).sortTH} ${moment(new Date()).subtract(3, 'months').format('YYYY')}` },
// 	{ value: `current5 | ${moment(new Date()).subtract(5, 'months').format('YYYY-MM')}-21`, label: "พนักงานปัจจุบัน" },
// 	{ value: `out6 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).subtract(2, 'months').format('YYYY')}`, label: `ลาออก ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).subtract(1, 'months').format('YYYY')}` },
// 	{ value: `out7 | ${moment(new Date()).subtract(3, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).subtract(2, 'months').format('YYYY')}`, label: `ลาออก ${renderMonth(+moment(new Date()).subtract(2, 'months').format('M')).sortTH} ${moment(new Date()).subtract(2, 'months').format('YYYY')}` }
// ]

//ตัวจริงใช้อันนี้
export const optionInstallmentSaleStatus = [
	{ value: `new1 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).format('M')).sortTH} ${moment(new Date()).format('YYYY')}` },
	{ value: `new2 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).subtract(1, 'months').format('YYYY')}` },
	{ value: `new3 | ${moment(new Date()).subtract(3, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(2, 'months').format('M')).sortTH} ${moment(new Date()).subtract(2, 'months').format('YYYY')}` },
	{ value: `new4 | ${moment(new Date()).subtract(4, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(3, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).subtract(3, 'months').format('M')).sortTH} ${moment(new Date()).subtract(3, 'months').format('YYYY')}` },
	{ value: `current5 | ${moment(new Date()).subtract(4, 'months').format('YYYY-MM')}-21`, label: "พนักงานปัจจุบัน" },
	{ value: `out6 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).format('M')).sortTH} ${moment(new Date()).format('YYYY')}`, label: `ลาออก ${renderMonth(+moment(new Date()).format('M')).sortTH} ${moment(new Date()).format('YYYY')}` },
	{ value: `out7 | ${moment(new Date()).subtract(2, 'months').format('YYYY-MM')}-21 | ${moment(new Date()).subtract(1, 'months').format('YYYY-MM')}-20`, label: `เข้าใหม่ ${renderMonth(+moment(new Date()).format('M')).sortTH} ${moment(new Date()).format('YYYY')}`, label: `ลาออก ${renderMonth(+moment(new Date()).subtract(1, 'months').format('M')).sortTH} ${moment(new Date()).subtract(1, 'months').format('YYYY')}` }
]

export const optionLocation = [
	{ value: "OFF", label: "Office" },
	{ value: "FAC", label: "Factory" }
]

export const optionProbationGrade = [
	{ value: "", label: "-----โปรดระบุ-------" },
	{ value: "A", label: "สูงกว่าความคาดหวังมาก" },
	{ value: "B", label: "สูงกว่าความคาดหวัง" },
	{ value: "C", label: "ตามความคาดหวัง" },
	// { value: "C-", label: "เกือบตามความคาดหวัง" },
	{ value: "D", label: "ต่ำกว่าความคาดหวัง" },
	{ value: "F", label: "ต่ำกว่าความคาดหวังมาก" },
];

export const optionStatusRound = (_round) => {

	switch (+_round) {
		case 1:
		case 2:
			return [
				{ value: "0", label: "อยู่ในช่วงทดลองงาน" },
				{ value: "1", label: "ผ่านการทดลองงานแล้ว" },
				{ value: "2", label: "ไม่ผ่านการทดลองงาน" }

			];
		case 3:
			return [
				{ value: "0", label: "อยู่ในช่วงทดลองงาน" },
				{ value: "1", label: "ผ่านการทดลองงานแล้ว" },
				{ value: "2", label: "ไม่ผ่านการทดลองงาน" },
				{ value: "3", label: "อื่นๆ (ต่อช่วงทดลองงาน)", other: true },

			];
		case 4:
		case 5:
			return [
				{ value: "1", label: "ผ่านการทดลองงานแล้ว" },
				{ value: "2", label: "ไม่ผ่านการทดลองงาน" },
				{ value: "3", label: "อื่นๆ (ต่อช่วงทดลองงาน)", other: true },
			];
		case 6:
			return [
				{ value: "1", label: "ผ่านการทดลองงานแล้ว" },
				{ value: "2", label: "ไม่ผ่านการทดลองงาน" },
			];
		default:
			return [
				{ value: "0", label: "อยู่ในช่วงทดลองงาน" },
			]
	}
}