import React, { useState } from 'react'
import { Button, Input, Popconfirm, Tooltip } from 'antd';
import { MdCreate, MdEdit } from 'react-icons/md';
import { ButtonIcon, ButtonInsert, ButtonTheme, FIELD_BUTTON, TEXT_BUTTON } from '../../../constants/buttons';
import { ButtonView, ButtonUpdate } from '../../../constants/buttons';
import { PATHNAME } from "../../../constants/enums/pathname";
import { getColumnSearchProps } from '../../../constants/functions/getColumnsSearch';
import { colors } from '../../../themes';
import { FiXCircle, FiEdit, FiSend, FiFileText, FiArrowDownCircle, FiCheckCircle, FiClock, FiAlertCircle } from 'react-icons/fi';
import { URL_IMAGE } from '../../../components/connectAPI';
import { Link } from "react-router";
import checkDateColorCode, { colorClassName } from '../../../extendFunction/checkDateColorCode';
import Moment from 'react-moment';
import { SetDate } from '../../../common/setDate';
import { RenderStatusMainCode } from '../../../common/setStatus';
import { path } from 'ramda';

export const TABLE_CONTRACT_VIEW = ({ pathname, userLogin, actionButton }) => {
	const searchInput = React.createRef();
	const [staffCode, setStaffCode] = useState()
	switch (pathname) {
		case PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE:
			return [
				{
					title: 'สถานะ',
					dataIndex: 'statusMain',
					align: 'left',
					...getColumnSearchProps('statusMain', searchInput),
				}, {
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อผู้บังคับบัญชา',
					dataIndex: 'BossName',
					align: 'left',
					...getColumnSearchProps('BossName', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'subDepName',
					align: 'left',
					...getColumnSearchProps('DepNo', searchInput),
				}, {
					title: 'วันที่เริ่มงาน ',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text)
				},
				{
					title: 'ระยะเวลาในการต่อสัญญา',
					dataIndex: 'contractPeriod',
					align: 'center',
				},
				{
					title: 'รอบการประเมิน',
					dataIndex: 'RoundNow',
					align: 'left',
					...getColumnSearchProps('RoundNow', searchInput),
				}, {
					title: 'สถานะการประเมินล่าสุด',
					dataIndex: 'probationStatus_Des',
					align: 'left',
					...getColumnSearchProps('probationStatus_Des', searchInput),
				}, {
					title: 'กองทุนสำรองเลี้ยงชีพ',
					dataIndex: 'providentFund',
					align: 'left',
					...getColumnSearchProps('providentFund', searchInput),
				}, {
					title: 'ประกันสังคม',
					dataIndex: 'SSO',
					align: 'left',
					...getColumnSearchProps('SSO', searchInput),
				}, {
					title: '',
					dataIndex: 'statusMainCode',
					width: 150,
					fixed: 'right',
					align: 'center',
					render: (text, record) => {
						console.log(record, userLogin)
						let permission = (path(['BossCode'], record) === userLogin) || (path(['ApprovalNo'], record) === userLogin)
						if (permission) {
							switch (text) {
								case '0':
									return (

										<ButtonInsert
											onClick={() => actionButton({
												fieldBtn: FIELD_BUTTON.INSERT_CONTRACT, payload: { ...record }
											})}
										/>
									)
								case '1':
									return (
										<ButtonUpdate
											onClick={() => actionButton({
												fieldBtn: FIELD_BUTTON.UPDATE_CONTRACT, payload: { ...record }
											})}
										/>
									)
								case '2':
									return (
										(record.DocNo) ?
											<ButtonView
												onClick={() => actionButton({
													fieldBtn: FIELD_BUTTON.VIEW_CONTRACT, payload: { ...record }
												})}
											/> : '-'
									)

								default:
									return (<div> - </div>)
							}
						} else {
							return (
								(record.DocNo) ?
									<ButtonView
										onClick={() => actionButton({
											fieldBtn: FIELD_BUTTON.VIEW_CONTRACT, payload: { ...record }
										})}
									/> : '-'
							)
						}
					}
				}
			]
		case PATHNAME.DASHBOARD_PROBATION_CONTRACT:
			return [
				{
					title: 'สถานะ',
					dataIndex: 'statusMainCode',
					align: 'left',
					fixed: "left",
					render: (text, record) => {
						return RenderStatusMainCode(text, record.statusMain)
					}
				}, {
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					fixed: "left",
					render: (text) => <Link target='_blank' to={URL_IMAGE + text + '.jpg'}><b>{text}</b>        </Link>,
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					fixed: "left",
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					align: 'left',
					fixed: "left",
					...getColumnSearchProps('NickName', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'Department_Name',
					align: 'left',
					fixed: "left",
					...getColumnSearchProps('Department_Name', searchInput),
				}, {
					dataIndex: "Flow_Name",
					title: "สายอนุมัติ",
					key: "Flow_Name",
					width: 200,
					...getColumnSearchProps('Flow_Name', searchInput),

				},
				{
					dataIndex: "statusWorkerDes",
					title: "สถานะพนักงาน",
					key: "statusWorkerDes",
					width: 150,
					...getColumnSearchProps('statusWorkerDes', searchInput),
				}, {
					title: 'วันที่เริ่มงาน',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				},
				{
					title: 'วันที่เริ่มรอบสัญญาจ้าง',
					dataIndex: 'startProbationDate',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				}, {
					title: 'ต่อสัญญาครั้งที่',
					dataIndex: 'roundContract',
					align: 'right',
					...getColumnSearchProps('roundContract', searchInput),
				},
				{
					title: 'วันที่ครบกำหนดทดลองงาน',
					dataIndex: 'expProDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ผ่านทดลองงาน',
					dataIndex: 'probationEndDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: "รอบปัจจุบัน",
					dataIndex: "RoundNow",
					key: "RoundNow",
					align: "right",
					width: 150,
				},
				{
					title: "รอบที่ยืนยันแล้ว",
					dataIndex: "status_confirm",
					key: "status_confirm",
					align: "right",
					width: 150,
				},
				{
					title: "รอบที่ 1",
					dataIndex: "RoundDate_1",
					key: "RoundDate_1",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_1 = "";
						if (record.RoundDate_1) {
							if (record.RoundNow > 1) {
								//จบการประเมินรอบที่ 1
								RoundDate_1 = record.RoundDate_1 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_1}
									</Moment>
								) : '-';
							} else {
								RoundDate_1 = record.RoundDate_1 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_1)}
									>
										{record.RoundDate_1}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_1 || "-"}</div>;
					},
				},
				{
					title: "รอบที่ 2",
					dataIndex: "RoundDate_2",
					key: "RoundDate_2",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_2 = "";
						if (record.RoundNow) {
							if (record.RoundNow > 2 || record.statusProbationLast === "1") {
								//จบการประเมินตอนรอบ 2
								RoundDate_2 = record.RoundDate_2 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_2}
									</Moment>
								) : '-';
							} else {
								RoundDate_2 = record.RoundDate_2 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_2)}
									>
										{record.RoundDate_2}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_2 || "-"}</div>;
					},
				},
				{
					title: "รอบที่ 3",
					dataIndex: "RoundDate_3",
					key: "RoundDate_3",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_3 = "";
						if (record.RoundNow) {
							if (record.RoundNow < 3 && record.statusProbationLast === "1") {
								//ประเมินจบตั้งแต่รอบที่ 2
								RoundDate_3 = "-";
							} else if (record.RoundNow > 3 || record.statusProbationLast === "1") {
								//จบการประเมินตอนรอบ 3
								RoundDate_3 = record.RoundDate_3 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_3}
									</Moment>
								) : '-';
							} else {
								RoundDate_3 = record.RoundDate_3 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_3)}
									>
										{record.RoundDate_3}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_3 || "-"}</div>;
					},
				},
				{
					title: "รอบที่ 4",
					dataIndex: "RoundDate_4",
					key: "RoundDate_4",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_4 = "";
						if (record.RoundNow) {
							if (record.RoundNow < 4 && record.statusProbationLast === "1") {
								//ประเมินจบตั้งแต่รอบที่ 3
								RoundDate_4 = "-";
							} else if (record.RoundNow > 4 || record.statusProbationLast === "1") {
								//จบการประเมินตอนรอบ 4
								RoundDate_4 = record.RoundDate_4 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_4}
									</Moment>
								) : '-';
							} else {
								RoundDate_4 = record.RoundDate_4 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_4)}
									>
										{record.RoundDate_4}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_4 || "-"}</div>;
					},
				},
				{
					title: "รอบที่ 5",
					dataIndex: "RoundDate_5",
					key: "RoundDate_5",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_5 = "";
						if (record.RoundNow) {
							if (record.RoundNow < 5 && record.statusProbationLast === "1") {
								//ประเมินจบตั้งแต่รอบที่ 4
								RoundDate_5 = "-";
							} else if (record.RoundNow > 5 || record.statusProbationLast === "1") {
								//จบการประเมินตอนรอบ 5
								RoundDate_5 = record.RoundDate_5 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_5}
									</Moment>
								) : '-';
							} else {
								RoundDate_5 = record.RoundDate_5 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_5)}
									>
										{record.RoundDate_5}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_5 || "-"}</div>;
					},
				},
				{
					title: "รอบที่ 6",
					dataIndex: "RoundDate_6",
					key: "RoundDate_6",
					align: "right",
					width: 150,
					render: (text, record) => {
						let RoundDate_6 = "";
						if (record.RoundNow) {
							if (record.RoundNow < 6 && record.statusProbationLast === "1") {
								//ประเมินจบตั้งแต่รอบที่ 5
								RoundDate_6 = "-";
							} else if (record.RoundNow > 6 || record.statusProbationLast === "1") {
								//จบการประเมินตอนรอบ 6
								RoundDate_6 = record.RoundDate_6 ? (
									<Moment format="YYYY-MM-DD" className="date-green">
										{record.RoundDate_6}
									</Moment>
								) : '-';
							} else {
								RoundDate_6 = record.RoundDate_6 ? (
									<Moment
										format="YYYY-MM-DD"
										className={colorClassName(record.RoundDate_6)}
									>
										{record.RoundDate_6}
									</Moment>
								) : '-';
							}
						}
						return <div>{RoundDate_6 || "-"}</div>;
					},
				}, {
					title: 'สถานะการประเมินล่าสุด',
					dataIndex: 'probationStatus_Des',
					align: 'right',
					...getColumnSearchProps('probationStatus_Des', searchInput),
				},
			]
		case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT:
			return [
				{
					title: 'สถานะหลัก',
					dataIndex: 'statusMainCode',
					align: 'left',
					fixed: 'left',
					render: (text, record) => {
						return RenderStatusMainCode(text, record.statusMain)
					}
				}, {
					title: 'สถานะการผ่านทดลองงาน',
					dataIndex: 'probationStatus_Des',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('probationStatus_Des', searchInput),
				}, {
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					align: 'left',
					...getColumnSearchProps('NickName', searchInput),
				}, {
					title: 'กลุ่ม',
					dataIndex: 'statusWorker',
					align: 'left',
					...getColumnSearchProps('statusWorker', searchInput),
				}, {
					title: 'ชื่อผู้บังคับบัญชา',
					dataIndex: 'BossName',
					align: 'left',
					...getColumnSearchProps('BossName', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'Department_Name',
					align: 'left',
					...getColumnSearchProps('Department_Name', searchInput),
				}, {
					title: 'การประเมินครั้งล่าสุด',
					dataIndex: 'RoundNow',
					align: 'left',
					...getColumnSearchProps('RoundNow', searchInput),
				}, {
					title: 'เอกสารทดลองงาน',
					dataIndex: 'DocNo',
					align: 'left',
					render: (text, record) => {
						if (text) {
							return <ButtonView
								onClick={() => actionButton({
									fieldBtn: FIELD_BUTTON.VIEW_CONTRACT,
									payload: { ...record }
								})}
							/>
						} else { return '-' }
					}
				}, {
					title: 'วันที่เริ่มงาน',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text),
				},
				{
					title: 'วันที่เริ่มรอบสัญญาจ้าง',
					dataIndex: 'startProbationDate',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				}, {
					title: 'ต่อสัญญาครั้งที่',
					dataIndex: 'roundContract',
					align: 'right',
					...getColumnSearchProps('roundContract', searchInput),
				},
				{
					title: 'ระยะเวลาในการต่อสัญญา',
					dataIndex: 'contractPeriod',
					align: 'center',
				}, {
					title: 'วันที่ครบกำหนดทดลองงาน',
					dataIndex: 'expProDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ผ่านทดลองงาน',
					dataIndex: 'probationEndDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ HR รับเอกสาร',
					dataIndex: 'HR_ReceivedDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'ประกันสุขภาพ / ประกันอุบัติเหตุ',
					dataIndex: 'date_HEA',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'กองทุนสำรองเลี้ยงชีพ',
					dataIndex: 'date_PVD',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'ประกันสังคม',
					dataIndex: 'date_SOS',
					align: 'left',
					render: (text, record) => SetDate(text)
				}
			]
		case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW:
			return [
				{
					title: 'สถานะหลัก',
					dataIndex: 'statusMainCode',
					align: 'left',
					fixed: 'left',
					render: (text, record) => {
						return RenderStatusMainCode(text, record.statusMain)
					}
				}, {
					title: 'สถานะการผ่านทดลองงาน',
					dataIndex: 'probationStatus_Des',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('probationStatus_Des', searchInput),
				}, {
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					align: 'left',
					...getColumnSearchProps('NickName', searchInput),
				}, {
					title: 'กลุ่ม',
					dataIndex: 'statusWorker',
					align: 'left',
					...getColumnSearchProps('statusWorker', searchInput),
				}, {
					title: 'ชื่อผู้บังคับบัญชา',
					dataIndex: 'BossName',
					align: 'left',
					...getColumnSearchProps('BossName', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'Department_Name',
					align: 'left',
					...getColumnSearchProps('Department_Name', searchInput),
				}, {
					title: 'การประเมินครั้งล่าสุด',
					dataIndex: 'RoundNow',
					align: 'left',
					...getColumnSearchProps('RoundNow', searchInput),
				}, {
					title: 'เอกสารทดลองงาน',
					dataIndex: 'DocNo',
					align: 'left',
					render: (text, record) => {
						if (text) {
							return <ButtonView
								onClick={() => actionButton({
									fieldBtn: FIELD_BUTTON.VIEW_CONTRACT,
									payload: { ...record }
								})}
							/>
						} else { return '-' }
					}
				}, {
					title: 'วันที่เริ่มงาน',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text),
				},
				{
					title: 'วันที่เริ่มรอบสัญญาจ้าง',
					dataIndex: 'startProbationDate',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				}, {
					title: 'ต่อสัญญาครั้งที่',
					dataIndex: 'roundContract',
					align: 'right',
					...getColumnSearchProps('roundContract', searchInput),
				},
				{
					title: 'ระยะเวลาในการต่อสัญญา',
					dataIndex: 'contractPeriod',
					align: 'center',
				}, {
					title: 'วันที่ครบกำหนดทดลองงาน',
					dataIndex: 'expProDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ผ่านทดลองงาน',
					dataIndex: 'probationEndDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ HR รับเอกสาร',
					dataIndex: 'HR_ReceivedDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'ประกันสุขภาพ / ประกันอุบัติเหตุ',
					dataIndex: 'date_HEA',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'กองทุนสำรองเลี้ยงชีพ',
					dataIndex: 'date_PVD',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'ประกันสังคม',
					dataIndex: 'date_SOS',
					align: 'left',
					render: (text, record) => SetDate(text)
				}
			]
		case PATHNAME.DASHBOARD_TRACK_CONTRACT:
			return [
				{
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					fixed: 'left',
					width: 100,
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('NickName', searchInput),
				}, {
					title: 'ชื่อผู้บังคับบัญชา',
					dataIndex: 'BossName',
					align: 'left',
					...getColumnSearchProps('BossName', searchInput),
				}, {
					title: 'กลุ่ม',
					dataIndex: 'statusWorker',
					align: 'left',
					...getColumnSearchProps('statusWorker', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'Department_Name',
					...getColumnSearchProps('Department_Name', searchInput),
				}, {
					title: 'สถานะ',
					dataIndex: 'statusMainCode',
					render: (text) => RenderStatusMainCode(text)
				}, {
					title: 'วันที่เริ่มงาน',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text),
				},
				{
					title: 'วันที่เริ่มรอบสัญญาจ้าง',
					dataIndex: 'startProbationDate',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				}, {
					title: 'ต่อสัญญาครั้งที่',
					dataIndex: 'roundContract',
					align: 'right',
					...getColumnSearchProps('roundContract', searchInput),
				}, {
					title: 'วันที่คาดว่าผ่านทดลองงาน',
					dataIndex: 'expProDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'สัญญาจ้างรอบที่',
					dataIndex: 'roundContract',
					align: 'left'
				}, {
					title: 'เอกสารทดลองงาน',
					dataIndex: 'DocNo',
					align: 'left',
					render: (text, record) => {
						if (text) {
							return <ButtonView
								onClick={() => actionButton({
									fieldBtn: FIELD_BUTTON.VIEW_CONTRACT, payload: { ...record }
								})}
							/>
						} else return '-'
					}
				}, {
					title: 'รอบที่ 1',
					dataIndex: 'RoundDate_1',
					align: 'left',
					render: (text, record) => {
						return <div className={(record.RoundNow > 1) ? 'date-green' : checkDateColorCode.colorClassName(SetDate(text))} >
							{SetDate(text)}
						</div>
					}
				}, {
					title: 'รอบที่ 2',
					dataIndex: 'RoundDate_2',
					align: 'left',
					render: (text, record) => {
						return <div className={(record.RoundNow > 2 || record.statusProbationLast === '1') ? 'date-green' : checkDateColorCode.colorClassName(SetDate(text))} >
							{SetDate(text)}
						</div>
					}
				}, {
					title: 'รอบที่ 3',
					dataIndex: 'RoundDate_3',
					align: 'left',
					render: (text, record) => {
						if (record.RoundNow < 3 && record.statusProbationLast === '1') {  //ประเมินจบตั้งแต่รอบที่ 2
							return "-"
						} else if (record.RoundNow > 3 || record.statusProbationLast === '1') { //จบการประเมินตอนรอบ 3 
							return <div className="date-green" >{SetDate(text)}</div>
						} else {
							return <div className={checkDateColorCode.colorClassName(SetDate(text))} >
								{SetDate(text)}
							</div>
						}
					}
				}, {
					title: 'รอบที่ 4',
					dataIndex: 'RoundDate_4',
					align: 'left',
					render: (text, record) => {
						if (record.RoundNow < 4 && record.statusProbationLast === '1') {  //ประเมินจบตั้งแต่รอบที่ 3
							return "-"
						} else if (record.RoundNow === '4' || record.statusProbationLast === '1') { //จบการประเมินตอนรอบ 4 
							return <div className="date-green" >{SetDate(text)}</div>
						} else {
							return <div className={checkDateColorCode.colorClassName(SetDate(text))} >
								{SetDate(text)}
							</div>
						}
					}
				}, {
					title: 'วันที่ผ่านทดลองงาน',
					dataIndex: 'probationEndDate',
					align: 'left',
					render: (text, record) => (text ? SetDate(text) : '-')
				}, {
					title: 'วันที่รับเอกสารทดลองงาน',
					dataIndex: 'HR_ReceivedDate',
					align: 'left',
					render: (text, record) => {
						if (+record.statusMainCode === 2) {
							return (
								<Popconfirm
									title="ยืนยันการอัพเดท?"
									onConfirm={() => actionButton({
										fieldBtn: FIELD_BUTTON.CONFIRM_RECEIVE_CONTRACT, payload: { ...record }
									})}
									okText="Yes"
									cancelText="No">
									<FiArrowDownCircle color={"#C32604"} size={"18"} />
									{'ยืนยันรับเอกสาร'}
								</Popconfirm>
							)
						} else if (record.HR_ReceivedDate !== null) {
							return (<>
								<FiCheckCircle color={"#028E22"} size={"18"} />
								<div className="date-green">
									{SetDate(text)}
								</div>
							</>)
						}

					}
				}
			]
		case PATHNAME.DASHBOARD_BENEFIT_CONTRACT:
			return [
				{
					title: 'รหัสพนักงาน',
					dataIndex: 'StaffCode',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffCode', searchInput),
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'StaffName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('StaffName', searchInput),
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					align: 'left',
					fixed: 'left',
					...getColumnSearchProps('NickName', searchInput),
				}, {
					title: 'ผลการทดลองงาน',
					dataIndex: 'probationStatus_Des',
					align: 'left',
					render: (text, record) => {
						let icon = <FiClock color={"#424949"} size={"18"} />;

						if (record.statusProbationLast === '1') { //ผ่านทดลองงาน
							icon = <FiCheckCircle color={"#028E22"} size={"18"} />

						} else if (record.statusProbationLast === '2') {  //ไม่ผ่าน
							icon = <FiXCircle color={"#E74C3C"} size={"18"} />

						} else if (record.statusProbationLast === '3') {  //ต่อโปร
							icon = <FiAlertCircle color={"#F4D03F"} size={"18"} />

						}
						return (
							<Tooltip placement="topLeft" title={record.probationStatus_Des || ''}>
								{icon} <span style={{ paddingLeft: 5 }}>{`  ${record.probationStatus_Des || ''}`} </span>
							</Tooltip>
						)
							;
					}
				}, {
					title: 'กลุ่ม',
					dataIndex: 'statusWorker',
					align: 'left',
					...getColumnSearchProps('statusWorker', searchInput),
				}, {
					title: 'ชื่อผู้บังคับบัญชา',
					dataIndex: 'BossName',
					align: 'left',
					...getColumnSearchProps('statusWorker', searchInput),
				}, {
					title: 'แผนก / กลุ่ม',
					dataIndex: 'Department_Name',
					align: 'left',
					...getColumnSearchProps('Department_Name', searchInput),
				},
				{
					title: 'วันที่เริ่มงาน',
					dataIndex: 'StartDateJob',
					align: 'left',
					render: (text, record) => SetDate(text),
				},
				{
					title: 'วันที่เริ่มรอบสัญญาจ้าง',
					dataIndex: 'startProbationDate',
					align: 'left',
					render: (text, record) => SetDate(text)
					// ...getColumnSearchProps('StartDateJob', searchInput),
				}, {
					title: 'ต่อสัญญาครั้งที่',
					dataIndex: 'roundContract',
					align: 'right',
					...getColumnSearchProps('roundContract', searchInput),
				}, {
					title: 'ระยะเวลาในการต่อสัญญา',
					dataIndex: 'contractPeriod',
					align: 'center',
					// ...getColumnSearchProps('probationTimes', searchInput),
				}, {
					title: 'สถานะ',
					dataIndex: 'statusMainCode',
					align: 'left',
					render: (text, record) => RenderStatusMainCode(text, record.statusMain)
				}, {
					title: 'วันที่คาดว่าผ่านทดลองงาน',
					dataIndex: 'expProDate',
					align: 'left',
					render: (text, record) => SetDate(text)
				}, {
					title: 'วันที่ผ่าน / ไม่ผ่านทดลองงาน',
					dataIndex: 'probationEndDate',
					align: 'left',
					render: (text, record) => (text) ? SetDate(text) : '-'
				}, {
					title: 'วันที่รับเอกสารทดลองงาน',
					dataIndex: 'HR_ReceivedDate',
					align: 'left',
					render: (text, record) => (text) ? SetDate(text) : '-'

				}, {
					title: 'ประกันสุขภาพ / ประกันอุบัติเหตุ',
					dataIndex: 'date_HEA',
					align: 'left',
					render: (text, record) => {
						if (text === null && record.statusProbationLast !== '2') {
							return (
								<Popconfirm
									title="ยืนยันการอัพเดท?"
									onConfirm={() => actionButton({
										fieldBtn: FIELD_BUTTON.CONFIRM_CONTRACT, payload: { type: 'hea', ...record }
									})}
									okText="Yes"
									cancelText="No"
								>
									<div style={{ display: 'flex' }}>
										<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expACD_HEA)} size={"18"} />
										{/* <div className={checkDateColorCode.colorClassName(SetDate(record.expACD_HEA))} > */}
										{SetDate(record.expACD_HEA)}
										{/* </div> */}
									</div >
								</Popconfirm>
							);
						} else if (text !== null && record.statusProbationLast !== '2') {
							return (
								<div style={{ display: 'flex' }}>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									{/* <div className={checkDateColorCode.colorClassName(SetDate(record.date_HEA))} > */}
									{SetDate(record.date_HEA)}
									{/* </div> */}
								</div>
							);
						}

					}
				}, {
					title: 'กองทุนสำรองเลี้ยงชีพ',
					dataIndex: 'date_PVD',
					align: 'left',
					render: (text, record) => {
						if (record.date_PVD === null && record.statusProbationLast !== '2') {
							return (
								<Popconfirm
									title="ยืนยันการอัพเดท?"
									onConfirm={() => actionButton({
										fieldBtn: FIELD_BUTTON.CONFIRM_CONTRACT, payload: { type: 'pvd', ...record }
									})}
									okText="Yes"
									cancelText="No"
								>
									<div style={{ display: 'flex' }}>
										<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expPVD)} size={"18"} />
										<div className={checkDateColorCode.colorClassName(SetDate(record.expPVD))} >
											{SetDate(record.expPVD)}
										</div>
									</div>
								</Popconfirm>
							);
						} else if (record.date_PVD !== null && record.statusProbationLast !== '2') {
							return (
								<div style={{ display: 'flex' }}>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									{/* <div className={checkDateColorCode.colorClassName(SetDate(record.date_PVD))} > */}
									{SetDate(record.date_PVD)}
									{/* </div> */}

								</div>
							);
						}
					}
				}, {
					title: 'ประกันสังคม',
					dataIndex: 'date_SOS',
					align: 'left',
					render: (text, record) => {
						if (record.date_SOS === null) {
							return (
								<div style={{ paddingTop: 5 }}>
									<Input type='text'
										name={record.StaffCode}
										id={record.StaffCode}
										onChange={setStaffCode(record.StaffCode)}
										defaultValue={record.StaffCode}
									/>

									<Popconfirm
										title="ยืนยันการอัพเดท?"
										onConfirm={() => actionButton({
											fieldBtn: FIELD_BUTTON.CONFIRM_CONTRACT, payload: { type: 'sos', ...record, StaffCode: staffCode }
										})}
										okText="Yes"
										cancelText="No"
									>
										<div style={{ display: 'flex' }}>
											<FiArrowDownCircle color={checkDateColorCode.codeColor(record.expSOS)} size={"18"} />
											{/* <div className={checkDateColorCode.colorClassName(SetDate(record.expSOS))} > */}
											{SetDate(record.expSOS)}
											{/* </div> */}
										</div>
									</Popconfirm>

								</div>

							);
						} else if (record.date_SOS != null) {
							return (
								<div style={{ display: 'flex' }}>
									<FiCheckCircle color={"#028E22"} size={"18"} />
									{/* <div className={checkDateColorCode.colorClassName(SetDate(record.date_SOS))} > */}
									{SetDate(record.date_SOS)}
									{/* </div> */}
									<br />
									{(record.docRef === "undefined") ? '' : (record.docRef || '')}
								</div>
							);
						}
					}
				}, {
					title: 'เอกสารทดลองงาน',
					dataIndex: 'DocNo',
					align: 'left',
					render: (text, record) => {
						if (text) {
							return <ButtonView
								onClick={() => actionButton({
									fieldBtn: FIELD_BUTTON.VIEW_CONTRACT, payload: { ...record }
								})}
							/>
						} else return '-'
					}
				}
			]
		default:
			break;
	}
}
