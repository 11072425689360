import React, { createContext, useReducer, useContext } from "react";
import { APIReportIndicatorHeader, APIReportEvaluation, APIReportIndicatorApproval, APIReportIndicatorLine, APIReportEvaluationLine, APIDashboardKPIs, APIReportFactory, APIUpdateStatusKPIs } from "./reportConnect";
import { equals, path } from "ramda"
import { MASTER_OPTION, REPORT_OPTION } from "../servicesOptions";
import { Modal } from "antd";
import { MasterContext } from "../master/masterProvider";

export const ReportContext = createContext();

const initialState = {
    indicatorLineLists: [],
    dashboardKPIsLists: [],
    reportKPIsLists: [],
    indicatorHeaderLists: [],
    evaluationLists: [],
    reportBoss: [],
    reportAll: [],
    reportFactory: null,
    indicatorApprovalLists: [],
    dataSearch: null
}

export const REPORT_TYPE = {
    EVALUATION: 'EVALUATION',
    EVALUATION_LINE: 'EVALUATION_LINE',
    EMPLOYEE: 'EMPLOYEE',
    INDICATOR_LINE: 'INDICATOR_LINE',
    // INDICATOR_LICENSE: 'INDICATOR_LICENSE',
    INDICATOR_HEADER: 'INDICATOR_HEADER',
    DASHBOARD_KPIS: 'DASHBOARD_KPIS',
    REPORT_FACTORY: 'REPORT_FACTORY',
    REFRESH_DASHBOARD_KPIS: 'REFRESH_DASHBOARD_KPIS'
}

const REPORT_ACTION = {
    GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
    GET_REPORT_FAILURE: "GET_REPORT_FAILURE",
}

const reportReducer = (state, action) => {
    switch (action.type) {
        case REPORT_ACTION.GET_REPORT_SUCCESS:
            return { ...state, ...action.state }
        case REPORT_ACTION.GET_REPORT_FAILURE:
        default:
            return state
    }
}

const ProviderReport = ({ children }) => {
    const [reportState, reportDispatch] = useReducer(reportReducer, initialState)
    const { masterState } = useContext(MasterContext)

    const { userLogin } = children.props; //'ZT58025'


    // async function getAPIReport({ nameAPI }) {
    //     switch (nameAPI) {
    //         case 'APIReportIndicatorHeader':
    //             await APIReportIndicatorHeader({ userLogin: userLogin }, res => {
    //                 if (res) {
    //                     reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { indicatorHeaderLists: res } })
    //                 } else {
    //                     reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
    //                 }
    //             })
    //             break;
    //         // case 'APIReportEvaluation':
    //         //     let payloadEvaluation = {
    //         //         userLogin: userLogin,
    //         //         searchHRM: masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`].length > 0 ? masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`] : null,
    //         //         searchView: masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_INSPECT}`].length > 0 ? masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_INSPECT}`] : null,
    //         //         searchYear: masterState[`SEARCH_OPTION_${MASTER_OPTION.YEAR}`][0]
    //         //     }
    //         //     await APIReportEvaluation({ ...payloadEvaluation }, res => {
    //         //         if (res) {
    //         //             reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { evaluationLists: res, reportAll: res } })
    //         //         } else {
    //         //             reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
    //         //         }
    //         //     })
    //         //     break;
    //         // case 'APIReportAll':
    //         //     await APIReportAll({ userLogin: userLogin }, res => {
    //         //         if (res) {
    //         //             reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { reportBoss: res } })
    //         //         } else {
    //         //             reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
    //         //         }
    //         //     })
    //         //     break;
    //         case 'APIReportIndicatorApproval':
    //             await APIReportIndicatorApproval({ userLogin: userLogin }, res => {
    //                 if (res) {
    //                     reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { indicatorApprovalLists: res } })
    //                 } else {
    //                     reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
    //                 }
    //             })
    //             break;
    //         default:
    //             break;
    //     }
    // }

    async function searchReport({ reportName, searchOption, valueId, dataSearch, factory, searchYear }) {
        const {
            SEARCH_OPTION_YEAR, SEARCH_OPTION_MONTH, SEARCH_OPTION_EMPLOYMENT_TYPE, SEARCH_OPTION_FLOWHRM_FACTORY, SEARCH_OPTION_VIEW_FACTORY,
            SEARCH_OPTION_SUBDEP_FACTORY, OPTION_EMPLOYMENT_TYPE, OPTION_FLOWHRM_FACTORY, OPTION_VIEW_FACTORY, OPTION_DEPARTMENT_FACTORY, OPTION_SUBDEP_FACTORY
        } = masterState;
        let DEFAULT_FLOWHRM_FACTORY = []
        let DEFAULT_VIEW_FACTORY = []
        let DEFAULT_DEPARTMENT_FACTORY = []
        let DEFAULT_SUBDEP_FACTORY = []
        let DEFAULT_EMPLOYMENT_TYPE = []
        OPTION_FLOWHRM_FACTORY.length > 0 ? OPTION_FLOWHRM_FACTORY.forEach(
            ({ optionValue }) => DEFAULT_FLOWHRM_FACTORY.push(`${optionValue}`)) : DEFAULT_FLOWHRM_FACTORY = '';
        OPTION_VIEW_FACTORY.length > 0 ? OPTION_VIEW_FACTORY.forEach(
            ({ optionValue }) => DEFAULT_VIEW_FACTORY.push(`${optionValue}`)) : DEFAULT_VIEW_FACTORY = '';
        OPTION_DEPARTMENT_FACTORY.length > 0 ? OPTION_DEPARTMENT_FACTORY.forEach(
            ({ optionValue }) => DEFAULT_DEPARTMENT_FACTORY.push(`${optionValue}`)) : DEFAULT_DEPARTMENT_FACTORY = '';
        OPTION_SUBDEP_FACTORY.length > 0 ? OPTION_SUBDEP_FACTORY.forEach(
            ({ optionValue }) => DEFAULT_SUBDEP_FACTORY.push(`${optionValue}`)) : DEFAULT_SUBDEP_FACTORY = '';
        OPTION_EMPLOYMENT_TYPE.length > 0 ? OPTION_EMPLOYMENT_TYPE.forEach(
            ({ optionValue }) => DEFAULT_EMPLOYMENT_TYPE.push(`${optionValue}`)) : DEFAULT_EMPLOYMENT_TYPE = '';
        Array.isArray(DEFAULT_FLOWHRM_FACTORY) && DEFAULT_FLOWHRM_FACTORY.shift()
        Array.isArray(DEFAULT_VIEW_FACTORY) && DEFAULT_VIEW_FACTORY.shift()
        Array.isArray(DEFAULT_DEPARTMENT_FACTORY) && DEFAULT_DEPARTMENT_FACTORY.shift()
        Array.isArray(DEFAULT_SUBDEP_FACTORY) && DEFAULT_SUBDEP_FACTORY.shift()
        let NEW_SEARCH_OPTION_SUBDEP_FACTORY = (SEARCH_OPTION_SUBDEP_FACTORY[0] !== " ") ? SEARCH_OPTION_SUBDEP_FACTORY[0] : ''
        let NEW_SEARCH_OPTION_FLOWHRM_FACTORY = (SEARCH_OPTION_FLOWHRM_FACTORY[0] === " " || SEARCH_OPTION_FLOWHRM_FACTORY.length === 0) ? DEFAULT_FLOWHRM_FACTORY : SEARCH_OPTION_FLOWHRM_FACTORY;
        let NEW_SEARCH_OPTION_VIEW_FACTORY = (SEARCH_OPTION_VIEW_FACTORY[0] === " " || SEARCH_OPTION_VIEW_FACTORY.length === 0) ? DEFAULT_VIEW_FACTORY : SEARCH_OPTION_VIEW_FACTORY;

        switch (reportName) {
            case REPORT_TYPE.INDICATOR_HEADER:
                let newMonth = masterState[`SEARCH_OPTION_${MASTER_OPTION.MONTH}`][0] ? masterState[`SEARCH_OPTION_${MASTER_OPTION.MONTH}`][0] : ''
                let payloadHeader = {
                    userLogin: userLogin,
                    searchApproval: masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`][0] || '',
                    searchData: newMonth ? `${(parseInt(newMonth) > 9 ? `${newMonth}` : `0${newMonth}`)}${masterState[`SEARCH_OPTION_${MASTER_OPTION.YEAR}`]}` : '',
                    searchStatus: masterState[`SEARCH_OPTION_${MASTER_OPTION.INDICATOR_STATUS}`] || ''
                }
                // console.log({ payloadHeader })
                await APIReportIndicatorHeader({ ...payloadHeader }, res => {
                    // console.log(res)
                    if (res) {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { indicatorHeaderLists: res } })
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })
                await APIReportIndicatorApproval({ ...payloadHeader }, res => {
                    if (res) {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { indicatorApprovalLists: res } })
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })
                break;
            case REPORT_TYPE.EVALUATION:

                let payloadEvaluation = {
                    userLogin: userLogin,
                    searchHRM: masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`].length > 0 ? masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_APPROVAL}`] : null,
                    searchView: masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_INSPECT}`].length > 0 ? masterState[`SEARCH_OPTION_${MASTER_OPTION.BOSS_INSPECT}`] : null,
                    searchYear: masterState[`SEARCH_OPTION_${MASTER_OPTION.YEAR}`][0],
                    location: 'OFF'
                }

                await APIReportEvaluation({ ...payloadEvaluation }, res => {
                    //console.log(res)
                    if (res) {
                        reportDispatch({
                            type: REPORT_ACTION.GET_REPORT_SUCCESS,
                            state: {
                                dashboardKPIsLists: res.reportAction,
                                reportAreportKPIsLists: res.reportView,
                                reportBoss: res.reportAll,
                                dataSearch: res.searchData
                            }
                        })
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })


                // await APIReportEvaluation({ ...payloadEvaluation }, res => {
                //     if (res) {
                //         reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { evaluationLists: res, reportAll: res } })
                //     } else {
                //         reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                //     }
                // })
                // await APIReportAll({ ...payloadEvaluation }, res => {
                //     if (res) {
                //         reportDispatch({ type: REPORT_ACTION.GET_REPORT_SUCCESS, state: { reportBoss: res } })
                //     } else {
                //         reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                //     }
                // })

                break

            case REPORT_TYPE.INDICATOR_LINE:
                let result = []
                await APIReportIndicatorLine({ indicatorHeaderId: valueId }, (res) => result = res)

                return result
            case REPORT_TYPE.DASHBOARD_KPIS:
                localStorage.removeItem('dataSearch');
                let payloadDashboard = {
                    userId: userLogin,//'ZT58025',
                    year: SEARCH_OPTION_YEAR[0],
                    statusWorker: SEARCH_OPTION_EMPLOYMENT_TYPE.length > 0 ? SEARCH_OPTION_EMPLOYMENT_TYPE : DEFAULT_EMPLOYMENT_TYPE,
                    subDepNo: NEW_SEARCH_OPTION_SUBDEP_FACTORY === undefined ? '' : NEW_SEARCH_OPTION_SUBDEP_FACTORY,
                    refFlowHRM: NEW_SEARCH_OPTION_FLOWHRM_FACTORY === [] ? DEFAULT_FLOWHRM_FACTORY : NEW_SEARCH_OPTION_FLOWHRM_FACTORY,
                    refFlowView: NEW_SEARCH_OPTION_VIEW_FACTORY === [] ? DEFAULT_VIEW_FACTORY : NEW_SEARCH_OPTION_VIEW_FACTORY,
                }

                let success = false
                await APIDashboardKPIs(payloadDashboard, (res) => {
                    if (res) {
                        window.localStorage.setItem('dataSearch', JSON.stringify(payloadDashboard))
                        reportDispatch({
                            type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                                dashboardKPIsLists: path(['resultAction'], res),
                                reportKPIsLists: path(['resultView'], res),
                            }
                        })

                        success = true
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })
                return success
            case REPORT_TYPE.REFRESH_DASHBOARD_KPIS:
                // console.log({ dataSearch })
                await APIDashboardKPIs(dataSearch, (res) => {
                    if (res) {
                        // localStorage.removeItem('dataSearch');
                        reportDispatch({
                            type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                                dashboardKPIsLists: path(['resultAction'], res),
                                reportKPIsLists: path(['resultView'], res),
                            }
                        })
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })
                break;
            case REPORT_TYPE.REPORT_FACTORY:

                let payloadReport = {
                    userId: userLogin, //'ZT58025',
                    year: SEARCH_OPTION_YEAR[0],
                    month: SEARCH_OPTION_MONTH[0],
                    statusWorker: SEARCH_OPTION_EMPLOYMENT_TYPE[0],
                    subDepNo: NEW_SEARCH_OPTION_SUBDEP_FACTORY === undefined ? '' : NEW_SEARCH_OPTION_SUBDEP_FACTORY,
                    refFlowHRM: NEW_SEARCH_OPTION_FLOWHRM_FACTORY === [] ? DEFAULT_FLOWHRM_FACTORY : NEW_SEARCH_OPTION_FLOWHRM_FACTORY,
                    refFlowView: NEW_SEARCH_OPTION_VIEW_FACTORY === [] ? DEFAULT_VIEW_FACTORY : NEW_SEARCH_OPTION_VIEW_FACTORY,
                }

                let Reportsuccess = false
                await APIReportFactory(payloadReport, (res) => {
                    //console.log({ res })
                    if (res) {
                        reportDispatch({
                            type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                                reportFactory: res,
                                dataSearch: payloadReport
                            }
                        })
                        Reportsuccess = true
                    } else {
                        reportDispatch({ type: REPORT_ACTION.GET_REPORT_FAILURE })
                    }
                })
                return Reportsuccess

            default:
                break;
        }
    }

    async function expandColumsIndicator(expanded, record) {
        if (expanded) {
            // console.log({ record })
            await APIReportIndicatorLine({ indicatorHeaderId: record.indicatorHeaderId }, res => {
                let result = reportState.indicatorHeaderLists.reduce((acc, cur) => {
                    const dataIndex = acc.findIndex(el => equals(el.indicatorHeaderId, cur.indicatorHeaderId))
                    if (dataIndex !== -1) {
                        acc[dataIndex] = { ...acc[dataIndex] }
                        return acc
                    } else {
                        return [
                            ...acc,
                            { ...cur, indicator: res }]
                    }
                }, [])
                reportDispatch({
                    type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                        indicatorHeaderLists: result,
                        // indicatorApprovalLists: result
                    }
                })
            })
        }
    }

    async function expandColumsApproval(expanded, record) {
        if (expanded) {
            // console.log({ record })
            await APIReportIndicatorLine({ indicatorHeaderId: record.indicatorHeaderId }, res => {
                let result = reportState.indicatorApprovalLists.reduce((acc, cur) => {
                    const dataIndex = acc.findIndex(el => equals(el.indicatorHeaderId, cur.indicatorHeaderId))
                    if (dataIndex !== -1) {
                        acc[dataIndex] = { ...acc[dataIndex] }
                        return acc
                    } else {
                        return [
                            ...acc,
                            { ...cur, indicator: res }]
                    }
                }, [])
                reportDispatch({
                    type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                        indicatorApprovalLists: result,
                        // indicatorApprovalLists: result
                    }
                })
            })
        }
    }

    async function expandColumsScore(expanded, record) {
        if (expanded) {
            // console.log('expandColumsScore', record, reportState)
            await APIReportEvaluationLine({ employeeId: record.employeeId }, res => {
                let result = reportState.reportAll.resultLists.reduce((acc, cur) => {
                    const dataIndex = acc.findIndex(el => equals(el.employeeId, cur.employeeId))
                    if (dataIndex !== -1) {
                        acc[dataIndex] = { ...acc[dataIndex] }
                        return acc
                    } else {
                        return [
                            ...acc,
                            { ...cur, evaluationLine: res }]
                    }
                }, [])

                if (result.length > 0 && result.filter(e => e.employeeId === record.employeeId)) {
                    reportDispatch({
                        type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
                            reportAll: {
                                ...reportState.reportAll,
                                resultLists: result
                            },
                        }
                    })
                }
            })
            // await APIReportIndicatorLine({ indicatorHeaderId: record.indicatorHeaderId }, res => {
            //     let result = reportState.indicatorHeaderLists.reduce((acc, cur) => {
            //         const dataIndex = acc.findIndex(el => equals(el.indicatorHeaderId, cur.indicatorHeaderId))
            //         if (dataIndex !== -1) {
            //             acc[dataIndex] = { ...acc[dataIndex] }
            //             return acc
            //         } else {
            //             return [
            //                 ...acc,
            //                 { ...cur, indicator: res }]
            //         }
            //     }, [])
            //     reportDispatch({
            //         type: REPORT_ACTION.GET_REPORT_SUCCESS, state: {
            //             indicatorHeaderLists: result,
            //             indicatorApprovalLists: result
            //         }
            //     })
            // })
        }
    }

    async function manageReport({ fieldname, value }) {
        switch (fieldname) {
            case REPORT_OPTION.UPDATE_STATUS_CONFIRM:
                let payloadConfirm = value.selectKeys.reduce((acc, cur) => {
                    return [...acc,
                    {
                        year: value.year,
                        month: value.month,
                        employeeId: cur,
                        statusNo: "1",
                        updateBy: userLogin
                    }]
                }, [])
                // console.log({ payloadConfirm })
                await APIUpdateStatusKPIs(payloadConfirm, async response => {
                    // console.log(response)
                    if (response) {
                        Modal.success({
                            title: 'บันทึกสำเร็จ',
                            onOk: () => window.location.reload()
                        })
                    }
                })

                break;
            case REPORT_OPTION.UPDATE_STATUS_RECALL:
                let payloadRecall = value.selectKeys.reduce((acc, cur) => {
                    return [...acc,
                    {
                        year: value.year,
                        month: value.month,
                        employeeId: cur,
                        statusNo: "0",
                        updateBy: userLogin
                    }]
                }, [])
                // console.log({ payloadRecall })
                await APIUpdateStatusKPIs(payloadRecall, async response => {
                    // console.log(response)
                    if (response) {
                        Modal.success({
                            title: 'บันทึกสำเร็จ',
                            onOk: () => window.location.reload()
                        })
                    }
                })

                break;
            default:
                break;
        }
    }

    return (
        <ReportContext.Provider
            value={{
                searchReport,
                reportState,
                manageReport,
                // getAPIReport,
                expandColumsIndicator,
                expandColumsScore,
                expandColumsApproval,
                reportDispatch
            }}>
            {children}
        </ReportContext.Provider>
    )
}


export default ProviderReport