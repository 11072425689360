import React, { forwardRef, useImperativeHandle } from 'react'
import { Card, Col, Form, Modal, Row } from 'antd'
import { ButtonClear, ButtonSearch } from './buttons';
import { RenderInput } from '../../modulePersonal/componentsPersonal';
import { useText } from '../../screens/conponents/typography/Typography';

const LayoutSearch = forwardRef(({ onSearch, initialValues, searchLists, disabledSearch, disabledClear, onHandleChange, noTextRemark, errorField }, ref) => {
	const [form] = Form.useForm();

	/* -------------------------------------------------------------------------- */
	/*                                  Function                                  */
	/* -------------------------------------------------------------------------- */

	function onChange(item, value) {
		onHandleChange(item)
	}


	function onReset() {
		form.resetFields();
	}

	useImperativeHandle(ref, () => ({
		onClearValue(arr) {
			for (const val of arr) {
				form.setFieldsValue({ [val]: '' })
			}
		}
	}))

	async function onFinishFailed(values) {
		try {
			//console.log('onFinishFailed :', values)
			Modal.warning({
				title: 'กรุณากรอกข้อมูลให้ครบถ้วน'
			})
		} catch (error) {

		}
	}
	/* -------------------------------------------------------------------------- */
	let spanAll = 0;
	searchLists.forEach((e) => spanAll += e.span);
	return (
		<Card>
			<Form
				layout="vertical"
				form={form}
				initialValues={{ ...initialValues }}
				onFinish={onSearch}
				onFinishFailed={onFinishFailed} >
				<Row gutter={[24, 10]}>
					{Array.isArray(searchLists) && searchLists.map((e, i) => {
						return (
							<Col key={i} {...e}>
								<Form.Item {...e.itemprops}>
									<RenderInput  {...e.inputprops} errorField={errorField || []}
										onChange={(val) => onChange({ field: e.itemprops.name, value: val })} />

								</Form.Item>
							</Col>
						)
					})}
					<Col span={24 - (spanAll - 24)} style={{ paddingTop: 30, textAlign: 'end' }}>
						<ButtonSearch
							disabled={disabledSearch}
							htmlType="submit" />
						<ButtonClear
							htmlType="reset"
							onClick={onReset}
							disabled={disabledClear}
						/>
					</Col>
				</Row>
			</Form>
			{!noTextRemark && useText({ type: "danger", text: "* หากต้องการเลือกค้นหาผ่าน แผนก หรือ แผนกย่อย กรุณาระบุบริษัท หรือสถานที่ก่อนทำการค้นหา" })}
		</Card>
	)
})

export default LayoutSearch

