import React, { useState } from "react";
import { Form, Card, Row, Col, Spin, Button } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { InputAnt } from "../inputs/InputAnt";
import { Probation, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";

export const FormAddProbation = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeAddValue, setChangeAddValue] = useState({});

  const [loadingScreen, setLoadingScreen] = useState(false);

  // [START] Handle Form Antd
  const onFinish = (values) => {
    let BodyData = [];
    const { positionNo, Descriptions } = values;
    if (Descriptions === undefined)
      return useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `กรุณาเพิ่ม "เกณฑ์ชี้วัด" ก่อนกดบันทึก`,
      });
    if (Descriptions.length !== 0) {
      BodyData = Descriptions.map((val, index) => ({
        positionNo,
        lineNum: index + 1,
        description: val,
        userlogin,
      }));
    } else {
      BodyData = [];
    }
    useConfirm({
      FnOK: () => POST_INSERT_Probation(BodyData),
    });
  };
  const onReset = () => {
    form.resetFields();
    setChangeAddValue({});
  };
  // [END] Handle Form Antd

  // [START] REST API
  const POST_INSERT_Probation = async (value) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Probation.POST_Insert_Probation}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Probation", err);
      setLoadingScreen(false);
    }
  };

  const POST_verifyDataInPosition = async (positionNo) => {
    if (!positionNo) return null;
    try {
      const url = `${USE_CONNECT_MODE_AUTO}${Probation.POST_verifyDataInPosition}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ positionNo }),
      });
      const data = await response.json();
      if (!response.ok) {
        useAlert({
          type: "warning",
          title: "แจ้งเตือน",
          content: `${data.message}`,
          Fn: () =>
            form.setFieldsValue({
              positionNo: undefined,
              Descriptions: undefined,
            }),
        });
      }
    } catch (err) {
      console.log("Error verifyDataInPosition", err);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndefined = {};
    if (name === "company") {
      setUndefined = {
        positionNo: undefined,
        Descriptions: undefined,
      };
    } else if (name === "positionNo") {
      setUndefined = { Descriptions: undefined };
      POST_verifyDataInPosition(val);
    }
    form.setFieldsValue(setUndefined);
    setChangeAddValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: val,
    }));
  };
  // [END] Functions

  const CopyChangeValue = {
    companyCode: changeAddValue.company,
    ...changeAddValue,
  };

  return (
    <>
      <Card title="เพิ่มข้อมูล Probation" style={{ marginBottom: 30 }}>
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                <SelectAntd
                  name={`company`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={useOptionSelect("company")}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <SelectAntd
                  name={`positionNo`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={useOptionSelect(
                    "position",
                    useFixedQueryString(CopyChangeValue, ["companyCode"])
                  )}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>

              <Form.List name="Descriptions">
                {(fields, { add, remove }) => (
                  <>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                          style={{
                            width: "100%",
                          }}
                          icon={<PlusOutlined />}
                        >
                          เพิ่มเกณฑ์ชี้วัด
                        </Button>
                      </Form.Item>
                    </Col>

                    {fields.map((field, index) => (
                      <React.Fragment key={field.key}>
                        <Col span={20}>
                          <InputAnt
                            name={field.name}
                            label={`เกณฑ์ชี้วัดที่ ${index + 1}`}
                            smgReq={`กรุณากรอก เกณฑ์ชี้วัด`}
                            placeholder={`กรอก เกณฑ์ชี้วัด`}
                            FnOnChange={onChangeValue}
                            required={true}
                          />
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              type="danger"
                              icon={<CloseCircleOutlined />}
                              onClick={() => {
                                remove(field.name);
                              }}
                              style={{ width: "100%" }}
                            >
                              ลบ
                            </Button>
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Form.List>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default FormAddProbation;
