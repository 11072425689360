import React, { useState } from "react";
import { Form, Card, Row, Col, Spin, Space, Tabs } from "antd";
import { Crime, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import moment from "moment";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportCreateCrime } from "../../constants/HeaderExcel";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentPrepareDateDocCrime, ComponentSearchCreateDocCrime } from "../../constants/ManageCrime/Components/ComponentCreateDocCrime";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { POST, POST_SELECT_DATA_DASHBOARD_CRIME_ACTION } from "../../../service";
import { ColumnCreateCrime } from "../../constants/ManageCrime/Columns/ComlumnCreateDocCrime";

export const FormCreateDocCrime = () => {
  const [form] = Form.useForm();
  const [formCreateDocCrime] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
  const [loadingScreen, setLoadingScreen] = useState(false);

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(changeValue, ["companyCode", "location"])),
    subDepNo: useOptionSelect("subDepartment", useFixedQueryString(changeValue, ["company", "location", "depNo"])),
  };

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { company, location, depNo, subDepNo, startDate, endDate } = values
    let objectBody = {
      company: company || "",
      location: location || "",
      depNo: depNo || "",
      subDepNo: subDepNo || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    }
    selectDataDashboardCrimeAction(objectBody);
  };

  const onFinishCreateDoc = (values) => {
    let objectValue = {};
    let lineData = [];
    const { prepareDate } = values;
    const { selectedRowKeys } = rowSelection
    if (selectedRowKeys.length === 0) {
      useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `กรุณาเลือกพนักงานก่อนกด "บันทึก"`,
      });
    } else {
      lineData = selectedRowKeys.map((userId, lineNumber) => ({
        lineNum: lineNumber + 1,
        userId,
      }));
      objectValue = {
        headerData: {
          company: form.getFieldValue("company"),
          location: form.getFieldValue("location"),
          prepareDate: prepareDate.format("YYYY-MM-DD"),
          userlogin,
        },
        lineData,
      };
      //console.log("onFinishCreateDoc", objectValue)
      useConfirm({ FnOK: () => insertDataCrimeMultiple(objectValue) });
    }
  };

  const onReset = () => {
    form.resetFields();
    setChangeValue({});
    setDataTable([]);
    setRowSelection({ selectedRowKeys: [], selectedRows: [] })
  };
  // [END] Handle Form Antd

  // [START] REST API
  const selectDataDashboardCrimeAction = async (objectValue) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setRowSelection({ selectedRowKeys: [], selectedRows: [] })
      const response = await POST(POST_SELECT_DATA_DASHBOARD_CRIME_ACTION, objectValue);
      const { result } = response;
      let mapData = [];
      if (result.length !== 0) {
        mapData = result.map((row) => ({
          key: row.ID_User,
          ...row,
          startDateJob: subStringDate(row.startDateJob),
          birthday: subStringDate(row.birthday),
        }));
      } else {
        useNotification({ type: "info", desc: "ไม่พบข้อมูล" })
      }
      setDataTable(mapData);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Dashboard Crime Action: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const insertDataCrimeMultiple = async (objectValue) => {
    try {
      if (Object.keys(objectValue).length === 0) return null;
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Crime.POST_Insert_Crime}`;
      const formData = new FormData();
      const { headerData, lineData } = objectValue;
      formData.append("headerData", JSON.stringify(headerData));
      formData.append("lineData", JSON.stringify(lineData));
      const response = await fetch(url, { method: "POST", body: formData });
      if (response.ok) {
        useAlert({
          type: "success",
          content: "การบันทึกข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          content: "เกิดข้อผิดพลาด: บันทึกข้อมูลไม่สำเร็จ",
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      setLoadingScreen(false);
      console.log("Error insertDataCrimeMultiple: ", err);
      useAlert({ title: "catch", content: err.message })
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setName = {};
    let setUndefined = {};
    switch (name) {
      case "company":
        setName = { companyCode: value }
        setUndefined = toUndefined(["depNo", "subDepNo", "location"]);
        break;
      case "location":
        setUndefined = toUndefined(["depNo", "subDepNo"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"])
        break;
      case "startDate":
        setUndefined = toUndefined(["endDate"])
        break;
    }
    if (name !== "prepareDate") setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setName,
      ...setUndefined,
      [name]: value,
    }));
  };

  const sendDataExcel = (arr = []) => {
    return arr.map((row, index) => ({ numRow: index + 1, ...row }));
  };
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} >
        <Card
          title="ค้นหาพนักงานเพื่อสร้างเอกสารส่งอาชญากรรม"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                moreProps: { label: "ล้างข้อมูล" },
                fn: onReset,
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentSearchCreateDocCrime({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card title="สร้างเอกสารส่งอาชญากรรม" >
          <Form
            form={formCreateDocCrime}
            {...configForm}
            onFinish={onFinishCreateDoc}
            initialValues={{ prepareDate: moment() }}
            scrollToFirstError
          >
            {useRenderFormItem({
              constantComponent: ComponentPrepareDateDocCrime({}),
            })}
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={{
                right: useExportExcel({
                  header: Header_ReportCreateCrime,
                  dataset: sendDataExcel(rowSelection.selectedRows.length !== 0 ? rowSelection.selectedRows : dataTable),
                  filename: "รายงานผลตรวจอาชญากรรม",
                }),
              }}
            >
              <Tabs.TabPane tab="ข้อมูลพนักงาน" key="1">
                <CustomTableAtnd
                  HeadTable={ColumnCreateCrime}
                  DataTable={dataTable}
                  moreProps={{
                    scroll: {
                      x: sumColumnWidth(ColumnCreateCrime) || 2000,
                      y: 500,
                    },
                    rowSelection: {
                      onChange: (selectedRowKeys, selectedRows) => {
                        setRowSelection({ selectedRowKeys, selectedRows });
                      },
                    },
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Form.Item>
                  {useButton({
                    call: "save",
                    isDisable: rowSelection.selectedRowKeys.length === 0 ? true : false,
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Spin>

  );
};

export default FormCreateDocCrime;
