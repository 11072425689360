import * as XLSX from "xlsx";

const DEFINE = {
  file: {
    label: "แนบไฟล์อบรม",
    name: "file",
  },
};

export const ComponentImportTraining = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
  ...moreProps
}) => {
  const { file } = DEFINE;
  return [
    {
      rowProps: { justify: "start", align: "bottom" },
      column: [
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: "upload",
            itemProps: {
              name: file.name,
              label: file.label,
            },

            componentProps: {
              style: { width: "100%" },
              disabled: isDisable[file.name] || false,
              fileList: moreProps.fileList,
            },
            fnChange: handleChange,
          },
        },
      ],
    },
  ];
};

export const ExcelToJSON = (
  f,
  callback = (e) => console.log("no call back")
) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const data = workbook.SheetNames.map((e) => {
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const workSheetData = XLSX.utils.sheet_to_json(worksheet);
        return { sheetName: e, data: workSheetData };
      });
      resolve(data);
    };
    reader.readAsBinaryString(f);
  });
};

export const MapDataExcel = (data) => {
  const dataArray = data.map(e => {
    return {
      userId: e.userId,
      trainingNo: e.trainingNo,  
      trainerNo: e.trainerNo || '', 
      trainerName: e.trainerName, 
      evaluationMethod: e.evaluationMethod, 
      trainingDate: e.trainingDate, 
      trainingResult: e.trainingResult ,
      score: e.score,
      timeAttendance: e.timeAttendance,
      remark: e.remark  || ''
    }
  })
  return dataArray
}