import React, { useState, useContext, useEffect, useRef } from 'react'
import { Spin, Row, Col } from 'antd'
import { LayoutWrapper } from '../../moduleKPIs/common/layouts/layoutWrapper'
import { LayoutBreadcrumb } from '../../moduleKPIs/common/layouts/layoutBreadcrumb'
import { LayoutSearch } from '../../moduleKPIs/common/layouts/layoutSearch'
import { MasterTrainingContext } from '../servicesTraining/masterTraining/masterTrainingProvider'
import { BREADCRUMB_TRAINING } from '../commonsTraining/structuresTraining/breadcrumTraining'
import { TABS_TRAINING } from '../commonsTraining/structuresTraining/tabsTraining'
import { SEARCH_DASHBOARD_INDIVIDUAL } from '../commonsTraining/structuresTraining/searchTraining'
import { LayoutTabs } from '../../moduleKPIs/common/layouts'
import { ExportExcel } from '../../moduleKPIs/common/buttons'
import { FILED_OPTION, MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider'
import { PATHNAME } from '../../constants/enums/pathname'
import { COLUMNS_EXPORT } from '../commonsTraining/structuresTraining/columnsTraining'
import { TEXT_LOADING } from '../../constants/enums/enumCenter'


const PageDashboardHistoryIndividual = () => {
    const refExport = useRef()
    const { manageTrainingState, userLogin } = useContext(ManageTrainingContext);
    const { masterTrainingState, onChangeMaster, getMaster } = useContext(MasterTrainingContext);

    const { dataTableAction: { dataSource } } = manageTrainingState
    const [loading, setLoading] = useState(false)

    let SEARCH_LIST = SEARCH_DASHBOARD_INDIVIDUAL({ permission: userLogin.userPermission, options: masterTrainingState })
    let TABS_LIST = TABS_TRAINING({ pathname: PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL, permission: userLogin.userPermission })

    /* -------------------------------------------------------------------------- */
    /*                                  useEffect                                 */
    /* -------------------------------------------------------------------------- */

    async function fetchAPI() {
        try {
            setLoading(true)
            await Promise.all(
                [getMaster({ fieldname: MASTER_OPTION.OPTION_SUBDEP })],
                [getMaster({ fieldname: MASTER_OPTION.OPTION_USER_TRAINING, payload: { ...masterTrainingState } })],
                [getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINING_COURSE, payload: { ...masterTrainingState } })],
                [getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINING_GROUP, payload: { type: FILED_OPTION.OPTION_TRAINING_GROUP } })],
            )
        } catch (error) {
            console.log({ error })
        } finally {

            setLoading(false)
        }

    }
    useEffect(() => {
        fetchAPI()
    }, [])
    /* -------------------------------------------------------------------------- */

    return (
        <Spin spinning={loading} tip={TEXT_LOADING} >
            <LayoutWrapper
                breadcrumb={<LayoutBreadcrumb breadcrumbLists={BREADCRUMB_TRAINING(window.location.pathname)} />}
                search={
                    <Row gutter={[24, 10]}>
                        {
                            SEARCH_LIST.map((each, eachIndex) => (
                                <Col key={eachIndex} span={each.span} >
                                    <LayoutSearch
                                        header={each.header}
                                        type={each.type}
                                        required={each.required}
                                        onChange={onChangeMaster}
                                        {...each.inputOption}
                                    />
                                </Col>
                            ))}

                    </Row>}
                content={
                    <div>
                        <ExportExcel
                            ref={refExport}
                            fieldname={`บันทึกประวัติผลการฝึกอบรมรายบุคคล`}
                            columns={COLUMNS_EXPORT({ pathname: PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL, permission: userLogin.userPermission })}
                            dataSource={dataSource}
                        />
                        <div className="layout-content-card" style={{ padding: 10 }} >
                            <LayoutTabs tabsLists={TABS_LIST} />
                        </div>
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardHistoryIndividual