import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";

const CustomTableAtnd = ({
	
	DataTable = [],
	HeadTable = [],
	ExpandTable = {},
	SetScroll = { x: "100vw" },
	TableLayout = `auto`,
	TableLoading = false,
	EnableRowSelect = false,
	GetDataRow = (getRecord) => { },
	Sumary = Sumary || null,
	moreProps = moreProps || {},
	title,
}) => {
	const [dataRow, setDataRow] = useState({}); // when click at any record

	const [columnTbl, setColumnTbl] = useState([]); //
	const [dataTable, setDataTable] = useState([]); // set data from props.dataTbl
	const [pageSize, setPageSize] = useState(moreProps.defaultPageSize || 10); // set data from props.dataTbl

	useEffect(() => {
		setColumnTbl(HeadTable);
	}, []);

	useEffect(() => {
		setDataTable(DataTable);
	}, [DataTable]);

	// [Start] For Handle Modal
	const [Modalshow, setModalshow] = useState(false);

	const showModal = () => {
		setModalshow(true);
	};

	const hideModal = () => {
		setModalshow(false);
	};
	// [End] For Handle Modal

	const onShowSizeChange = (current, sizeOfPage) => {
		setPageSize(sizeOfPage)
	}

	return (
		<>
			<Table
				tableLayout={TableLayout}
				loading={TableLoading}
				columns={HeadTable}
				dataSource={dataTable}
				// scroll={SetScroll || { x: 'max-content' }}
				pagination={{
					showTotal: (total, range) =>
						`${range[0]}-${range[1]} จาก ${total} รายการ`,
					responsive: true,
					defaultPageSize: moreProps.defaultPageSize || 10,
					pageSize: pageSize,
					showSizeChanger: true,
					onShowSizeChange,
				}}
				scroll={
					{ x: 'max-content' }
				}
				rowSelection={EnableRowSelect}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							try {
								GetDataRow(record);
							} catch {
								console.log("Error Select dataRow");
							}
						}, // click row
						onMouseEnter: (event) => {
							try {
								GetDataRow(record);
							} catch {
								console.log("Data Hover dataRow");
							}
						},
					};
				}}
				expandable={ExpandTable}
				summary={Sumary}
				title={title}
				{...moreProps}
			/>
		</>
	);
};

export default CustomTableAtnd;
