import React, { useContext } from 'react'
import { ButtonTheme } from '../../../moduleKPIs/common/buttons'
import { colors } from '../../../themes'
import { Input, Select, DatePicker, Modal, Space } from 'antd';
import { FIELD_BUTTON, TEXT_BUTTON } from '../../../constants/buttons';
import { MasterTrainingContext } from '../../servicesTraining/masterTraining/masterTrainingProvider';
import { MASTER_OPTION } from '../../../moduleKPIs/services/servicesOptions';
import { requiredInput, TYPE } from '../../../moduleKPIs/common/inputs';
import moment from 'moment'
import { getColumnSearchProps } from '../../../constants/functions/getColumnsSearch';
import { isNil } from 'ramda';
import { downloadFile, validateHour } from '../../../constants/functions';
import { baseLabel } from '../../../moduleKPIs/common/functions';
import { RENDER_STATUS_TRAINING, RENDER_TEXT_COLORS } from '../../../constants/functions/renderWithStyle';


export const TABLE_DASHBOARD_TRAINING = ({ permission, userLogin, actionButton, isView }) => {
    const searchInput = React.createRef();

    return [
        {
            title: "ผลการอบรมฝึกปฏิบิติงาน",
            children: [{
                title: "รหัสพนักงาน",
                dataIndex: "userId",
                align: 'left',
                width: 150,
                ...getColumnSearchProps('userId', searchInput),

            }, {
                title: "สถานะ",
                dataIndex: "statusDes",
                align: 'center',
                width: 120,
                /* ----------------------------- Filter mulitple ---------------------------- */
                filters: [
                    { text: RENDER_TEXT_COLORS('รอสร้าง', 'blue'), value: 'draft' },
                    { text: RENDER_TEXT_COLORS('แบบร่าง', 'warning'), value: 'edit' },
                    { text: RENDER_TEXT_COLORS('เรียบร้อยแล้ว', 'success'), value: 'done' },
                ],
                onFilter: (value, record) => (record['statusNo'].includes(value)),
                render: (text, record) => (RENDER_STATUS_TRAINING(text, record['statusNo']))
                /* -------------------------------------------------------------------------- */

                // render: (text, record) => {
                //     switch (record.statusNo) {
                //         case 'draft':
                //             return <span style={{ color: colors.buttonCreate }}> {text} </span>
                //         case 'edit':
                //             return <span style={{ color: colors.wraning }}> {text} </span>
                //         case 'done':
                //             return <span style={{ color: colors.success }}> {text} </span>
                //         default:
                //             break;
                //     }
                // }

            }, {
                title: "ชื่อ",
                dataIndex: "fullname",
                align: 'left',
                width: 300,
                ...getColumnSearchProps('fullname', searchInput),

            }, {
                title: "ชื่อเล่น",
                dataIndex: "nickname",
                align: 'left',
                width: 100,
                ...getColumnSearchProps('nickname', searchInput),

            }, , {
                title: "ตำแหน่ง",
                dataIndex: "positionName",
                align: 'left',
                width: 200,
                ...getColumnSearchProps('positionName', searchInput),

            },
            {
                title: "แผนก",
                dataIndex: "depName",
                align: 'left',
                width: 200,
                ...getColumnSearchProps('depName', searchInput),

            }, {
                title: "แผนกย่อย",
                dataIndex: "subDepName",
                align: 'left',
                width: 300,
                ...getColumnSearchProps('subDepName', searchInput),

            }, {
                title: "บังคับบัญชาที่ 1",
                dataIndex: "approverFullName1",
                align: 'left',
                width: 300,
                ...getColumnSearchProps('approverFullName1', searchInput),

            }, {
                title: "วันที่เริ่ม",
                dataIndex: "startDate",
                align: 'left',
                width: 100,
                render: text => text || '-'

            }, {
                title: "Expect Date",
                dataIndex: "expectDate",
                align: 'left',
                width: 100,
                render: text => text || '-'

            },
            {
                title: "วันที่อบรมล่าสุด",
                dataIndex: "trainingDateMax",
                align: 'left',
                width: 100,
                render: text => text || '-'

            },
            {
                title: "Over Due",
                dataIndex: "overDue",
                align: 'left',
                width: 200,
                render: (text, record) => {
                    switch (record.statusNo) {
                        case 'done':
                            return (text) && <span style={{ color: colors.error }}> {`เกินกำหนด ${Math.floor(text)} วัน`} </span>
                        default:
                            break;
                    }
                }
            },
            {
                title: "",
                dataIndex: "statusNo",
                width: 130,
                fixed: 'right',
                align: 'left',
                render: (text, record) => {
                    let permissionView = (record.approverNo1.trim() === userLogin.userId.trim()) || (record.approverNo2.trim() === userLogin.userId.trim());
                    switch (text) {
                        case 'draft':
                            //console.log({ text, isView, permissionView }, record.approverNo1, userLogin.userId, record.approverNo2)
                            return (
                                (!isView && permissionView) ?
                                    <>
                                        <ButtonTheme
                                            style={{ padding: 5 }}
                                            buttonStyle={{ backgroundColor: colors.success, color: colors.white, width: 100 }}
                                            buttonText={TEXT_BUTTON.INSERT}
                                            onClick={() => actionButton({ fieldBtn: FIELD_BUTTON.INSERT_TRAINING, payload: record })}

                                        />
                                        <ButtonTheme
                                            style={{ padding: 5 }}
                                            buttonStyle={{ backgroundColor: colors.white, color: colors.buttonCreate, width: 100 }}
                                            buttonText={TEXT_BUTTON.EXPORT}
                                            onClick={() => actionButton({ fieldBtn: FIELD_BUTTON.EXPORT_OJT, payload: record })}

                                        />
                                    </>
                                    :
                                    <div style={{ display: 'flex', height: 45 }} />
                            )
                        case 'edit':
                            return (
                                (!isView && permissionView) ?
                                    <ButtonTheme
                                        style={{ padding: 5 }}
                                        buttonStyle={{ backgroundColor: colors.wraning, color: colors.white, width: 100 }}
                                        buttonText={TEXT_BUTTON.UPDATE}
                                        onClick={() => actionButton({ fieldBtn: FIELD_BUTTON.UPDATE_TRAINING, payload: record })} />
                                    :
                                    <div style={{ display: 'flex', height: 45 }} />
                            )
                        case 'done':
                            return (
                                <div style={{ display: 'flex' }}>
                                    <ButtonTheme
                                        style={{ padding: 5 }}
                                        buttonStyle={{ backgroundColor: colors.info, color: colors.white, width: 100 }}
                                        buttonText={TEXT_BUTTON.VIEW}
                                        onClick={() => actionButton({ fieldBtn: FIELD_BUTTON.VIEW_TRAINING, payload: record })} />
                                </div>
                            )
                        default:
                            return <div style={{ display: 'flex', height: 45 }} />
                    }

                }
            }]
        }
    ]

}

export const TABLE_INSERT_TRAINING = ({ update, view, onChange }) => {
    const { masterTrainingState } = useContext(MasterTrainingContext)
    let arr = [{
        title: "ลำดับ",
        dataIndex: "idRow",
        align: 'center',
        fixed: 'left',
        width: 50,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${index + 1}` }, false, 'center')
    }, {
        title: "หัวข้อการฝึกอบรม",
        dataIndex: "trainingCourses",
        align: 'left',
        fixed: 'left',
        width: 300,
        render: (text, record, index) => {
            if (!view) {
                if (record.trainingNo !== "" && !isNil(record.trainingNo)) {
                    return text || '-'
                } else {
                    return (RenderInTable({
                        type: TYPE.INPUT,
                        value: text || '',
                        onChange: (_value) => onChange({ fieldname: 'trainingCourses', value: _value, index: index })
                    }))

                }
            } else {
                return text || '-'
            }

        }
    }, {
        title: "วันที่ฝึกอบรม",
        dataIndex: "trainingDate",
        align: 'center',
        width: 100,
        render: (text, record, index) => {
            // console.log({ record, text })
            if (view) {
                return text
            } else {
                return (RenderInTable({
                    type: TYPE.DATE_PICKER,
                    options: masterTrainingState[MASTER_OPTION.OPTION_TRAINERS],
                    value: text,
                    onChange: (_value) => onChange({ fieldname: 'trainingDate', value: _value.dateStrings, index: index })
                }))
            }
        }
    },
    {
        title: "ผู้ฝึกอบรม",
        dataIndex: "trainerNo",
        width: 200,
        render: (text, record, index) => {
            if (view) {
                return record.trainerName || '-'
            } else {
                return (RenderInTable({
                    type: TYPE.SELECT,
                    options: masterTrainingState[MASTER_OPTION.OPTION_TRAINERS],
                    value: text,
                    onChange: (_value) => onChange({ fieldname: 'trainerNo', value: _value, index: index })
                })
                )
            }
        }
    }, {
        title: "วิธีการอบรม",
        dataIndex: "trainingMethod",
        width: 100,
        render: (text, record, index) => {
            if (view) {
                return record.trainingMethod || '-'
            } else {
                return (RenderInTable({
                    type: TYPE.SELECT,
                    options: masterTrainingState[MASTER_OPTION.OPTION_TRAINING_METHOD],
                    value: text,
                    onChange: (_value) => onChange({ fieldname: 'trainingMethod', value: _value, index: index })
                })
                )
            }
        }
    }, {
        title: "วิธีการประเมินผล",
        dataIndex: "evaluationMethod",
        width: 150,
        render: (text, record, index) => {
            if (view) {
                return record.evaluationMethodsDes || '-'
            } else {
                return (RenderInTable({
                    type: TYPE.SELECT,
                    options: masterTrainingState[MASTER_OPTION.OPTION_EVALUATION_METHOD],
                    value: text,
                    onChange: (_value) => onChange({ fieldname: 'evaluationMethod', value: _value, index: index })
                })
                )
            }
        }
    }, {
        title: "เวลาที่ใช้อบรม (ชม.)",
        dataIndex: "timePeriod",
        width: 80,
        align: 'center',
        render: (text, record, index) => {
            if (view) {
                return text || '-'
            } else {
                return (RenderInTable({
                    type: TYPE.NUMBER,
                    value: (text === 0) ? "" : text,
                    validate: validateHour,
                    onChange: (_value) => onChange({
                        fieldname: 'timePeriod',
                        value: _value,
                        index: index,
                    })
                }))
            }
        }
    }, {
        title:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>{'ระดับผลการเรียน'}</div>
                {requiredInput({ required: true, label: 'คะแนนมากกว่า 3 ขึ้นไปผ่าน', size: 10, labelColor: colors.error })}
            </div>,
        dataIndex: "score",
        align: 'center',
        width: 110,
        render: (text, record, index) => {
            if (view) {
                return text || '-'
            } else {
                return (RenderInTable({
                    type: TYPE.SELECT,
                    options: masterTrainingState[MASTER_OPTION.OPTION_LEARNING_RESULT],
                    value: `${text}`,
                    onChange: (_value) => onChange({ fieldname: 'score', value: _value, index: index })
                }))
            }
        }
    }]
    if (!view) {
        return [...arr, {
            title: "",
            dataIndex: "trainingNo",
            align: 'center',
            fixed: 'right',
            width: 50,
            render: (text, record, index) => {
                return <ButtonTheme
                    style={{ justifyContent: 'center', padding: 10 }}
                    danger type="dashed" buttonText={TEXT_BUTTON.REMOVE}
                    onClick={() => onChange({ fieldname: 'removeLine', value: { docNo: record.docNo, linenum: record.linenum, idRow: record.idRow }, index: index })} />
            }

        }]
    } else {
        return [...arr]
    }
}

export const RenderInTable = (props) => {
    const { type, options, value, onChange, validate, disabled } = props
    switch (type) {
        case TYPE.SELECT:
            return (
                <Select
                    showSearch
                    showArrow={false}
                    style={{ width: '100%' }}
                    defaultValue={value}
                    onChange={(val) => onChange(val)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        Array.isArray(options) && options.map((option, optionIndex) => {
                            return (
                                <Select.Option key={optionIndex} value={option.optionValue}>
                                    {option.optionLabel}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            )
        case TYPE.DATE_PICKER:
            return (
                <DatePicker
                    style={{ width: '100%' }}
                    disabled={disabled}
                    defaultValue={value ? moment(value) : ''}
                    onChange={(dates, dateStrings) => onChange({ dates, dateStrings })} />
            )
        case TYPE.NUMBER:
            return (
                <Input
                    style={{ textAlign: 'right' }}
                    value={value}
                    onChange={(e) => {
                        let val = parseFloat(e.target.value)
                        if (!isNaN(val) && isFinite(e.target.value)) {
                            if (typeof validate === 'function') {
                                onChange(validate(e.target.value))
                            } else {
                                onChange(val)
                            }
                        } else {
                            onChange('')
                        }
                    }}

                />
            )
        case TYPE.INPUT:
            return (
                <Input
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)} />
            )

        default:
            break
    }
}

export const TABLE_HISTORY_INDIVIDUAL_FORM = ({ permission, userLogin, actionButton }) => {
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        title: "ชื่อหลักสูตร",
        dataIndex: "trainingCourses",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "กลุ่มหลักสูตร",
        dataIndex: "trainingGroup",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "รหัสหลักสูตร",
        dataIndex: "trainingNo",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "วิธีการอบรม",
        dataIndex: "trainingMethodDes",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "ระยะเวลา",
        dataIndex: "timePeriod",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    },
    //  {
    //     title: "รหัสวิทยากร",
    //     dataIndex: "trainerNo",
    //     align: 'left',
    //     // fixed: 'left',
    //     render: (text, record, index) => text || '-'
    // }, 
    {
        title: "วิทยากร",
        dataIndex: "trainerName",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => `${text} ${record.trainerNo ? `(${record.trainerNo})` : ''}` || '-'
    }, {
        title: "ประเภทการฝึกอบรม",
        dataIndex: "trainingTypeDes",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "ผลการฝึกอบรม",
        dataIndex: "trainingResultDes",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "ผู้บันทึกผลฝึกอบรม",
        dataIndex: "recorderName",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "หมายเหตุ",
        dataIndex: "remark",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }]
}

export const TABLE_HISTORY_INDIVIDUAL = ({ permission, userLogin, actionButton }) => {
    const searchInput = React.createRef();
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')
    }, {
        title: "ชื่อ-นามสกุล",
        dataIndex: "fullname",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('fullname', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('subDepName', searchInput),

        // render: (text, record, index) => text || '-'
    }, {
        title: "กลุ่มหลักสูตร",
        dataIndex: "trainingGroup",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainingGroup', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "ชื่อหลักสูตร",
        dataIndex: "trainingCourses",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainingCourses', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "ผู้อบรม",
        dataIndex: "trainerName",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainerName', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "",
        dataIndex: "",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ButtonTheme
                        type="primary"
                        ghost
                        style={{ justifyContent: 'center', padding: 10 }}
                        buttonText={TEXT_BUTTON.PRINT}
                        onClick={() => actionButton({ fieldBtn: FIELD_BUTTON.PRINT_INDIVIDUAL_REPORT, payload: record })} />
                </div>
            )
        }

    }]
}

export const TABLE_HISTORY_TRAINERS_FORM = ({ permission, userLogin, actionButton }) => {
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        title: "ชื่อวิทยากร",
        dataIndex: "fullname",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "ชื่อหลักสูตร",
        dataIndex: "trainingCourses",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => text || '-'
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'center',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนผู้เข้าอบรม",
        dataIndex: "numParticipants",
        align: 'right',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนชั่วโมงอบรม",
        dataIndex: "timePeriod",
        align: 'right',
        render: (text, record, index) => text || '-'
    }]
}

export const TABLE_HISTORY_TRAINERS = ({ permission, userLogin, actionButton }) => {
    const searchInput = React.createRef();
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        title: "เลขที่เอกสาร",
        dataIndex: "refDocNo",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        // title: "รหัสพนักงาน (วิทยากร)",
        title: () => {
            return (
                <div>
                    {"รหัสพนักงาน"} <br />
                    {"(วิทยากร)"}
                </div>
            )
        },
        dataIndex: "trainerNo",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainerNo', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "ชื่อวิทยากร",
        dataIndex: "trainerName",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainerName', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "ชื่อหลักสูตร",
        dataIndex: "trainingCourses",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainingCourses', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'center',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนผู้เข้าอบรม",
        dataIndex: "numParticipants",
        align: 'right',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนชั่วโมงอบรม",
        dataIndex: "timePeriod",
        align: 'right',
        render: (text, record, index) => text || '-'
    }, {
        title: "",
        dataIndex: "filePath",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => {
            return (
                (text) && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ButtonTheme
                        type="primary"
                        ghost
                        style={{ justifyContent: 'center', padding: 10 }}
                        buttonText={TEXT_BUTTON.DOWNLOAD}
                        onClick={() => downloadFile(text, 'all')}
                    />
                </div>
            )
        }
    }]
}

export const TABLE_TRAINING_FORM = ({ permission, userLogin, actionButton }) => {
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        fixed: 'left',
        width: 50,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${index + 1}` }, false, 'center')
    }, {
        title: "หัวข้อการฝึกอบรม",
        dataIndex: "trainingCourses",
        align: 'left',
        fixed: 'left',
        // width: 300,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false)
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'center',
        width: 100,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false, 'center')
    }, {
        title: "ผู้ฝึกอบรม",
        dataIndex: "trainerName",
        align: 'left',
        width: 200,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false)
    }, {
        title: "วิธีการประเมินผล",
        dataIndex: "evaluationMethodsDes",
        align: 'left',
        width: 100,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false)
    },
    //  {
    //     title: "ผลฝึกอบรม",
    //     dataIndex: "trainingResultDes",
    //     align: 'center',
    //     width: 100,
    //     render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false, 'center')
    // },
    //  {
    //     title: "เวลาที่ใช้อบรม (ชม.)",
    //     dataIndex: "timePeriod",
    //     align: 'center',
    //     width: 80,
    //     render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false, 'center')
    // }, 
    {
        title: "ระดับผลการเรียน",
        dataIndex: "score",
        align: 'center',
        width: 80,
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text} (${record.trainingResultDes})` }, false, 'center')
    },
        //  {
        //     title: "การปฏิบัติงาน",
        //     dataIndex: "timeAttendance",
        //     align: 'center',
        //     width: 80,
        //     render: (text, record, index) => baseLabel(TYPE.TEXT, { label: `${text}` }, false, 'center')
        // }
    ]
}


export const TABLE_REGISTER_TRAINING = ({ permission, userLogin, actionButton }) => {
    const searchInput = React.createRef();
    return [{
        title: "ลำดับ",
        dataIndex: "linenum",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        title: "เลขที่เอกสาร",
        dataIndex: "refDocNo",
        align: 'center',
        // fixed: 'left',
        render: (text, record, index) => baseLabel(TYPE.TEXT, { label: text }, false, 'center')

    }, {
        title: "ชื่อวิทยากร",
        dataIndex: "trainerName",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainerName', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "ชื่อหลักสูตร",
        dataIndex: "trainingCourses",
        align: 'left',
        // fixed: 'left',
        ...getColumnSearchProps('trainingCourses', searchInput),
        // render: (text, record, index) => text || '-'
    }, {
        title: "วันที่อบรม",
        dataIndex: "trainingDate",
        align: 'center',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนผู้เข้าอบรม",
        dataIndex: "numParticipants",
        align: 'right',
        render: (text, record, index) => text || '-'
    }, {
        title: "จำนวนชั่วโมงอบรม",
        dataIndex: "timePeriod",
        align: 'right',
        render: (text, record, index) => text || '-'
    }, {
        title: "",
        dataIndex: "filePath",
        align: 'left',
        // fixed: 'left',
        render: (text, record, index) => {
            return (
                (text) && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ButtonTheme
                        type="primary"
                        ghost
                        style={{ justifyContent: 'center', padding: 10 }}
                        buttonText={TEXT_BUTTON.DOWNLOAD}
                        onClick={() => downloadFile(text, 'all')}
                    />
                </div>
            )
        }

    }]
}