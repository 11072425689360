import React, { createContext, useReducer } from "react";
import { APIInsertEvaluationScore, APIUpdateEvaluationScore, APIInsertScoreFactory, APIUpdateScoreFactory } from "./evaluationConnect";
import { path, update } from "ramda";
import { Modal } from "antd";
import { splitString } from "../../../constants/functions";

export const EvaluationContext = createContext();

const initialState = {
    statusApproval: false,
    // searchDate: [],
    indicatorFac: [{
        indicatorLine: '',
        indicatorNo: '',
        rawScore: '',
        weight: '',
        score: '',
    }],
}

const EVALUATION_ACTION = {
    SET_EVALUATION_SCORE_SUCCESS: "SET_EVALUATION_SCORE_SUCCESS",
    SET_EVALUATION_SCORE_FAILURE: "SET_EVALUATION_SCORE_FAILURE",
    GET_REPORT_EVALUATION_SUCCESS: "GET_REPORT_EVALUATION_SUCCESS",
    GET_REPORT_EVALUATION_FAILURE: "GET_REPORT_EVALUATION_FAILURE",
    CLEAR_EVALUATION_SUCCESS: "CLEAR_EVALUATION_SUCCESS",
    CLEAR_EVALUATION_FAILURE: "CLEAR_EVALUATION_FAILURE"

}

const evaluationReducer = (state, action) => {
    // console.log('evaluationReducer', { state, action })
    switch (action.type) {
        case EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS:
        case EVALUATION_ACTION.GET_REPORT_EVALUATION_SUCCESS:
            return {
                ...state,
                ...action.state
            }
        case EVALUATION_ACTION.CLEAR_EVALUATION_SUCCESS:
            return initialState
        case EVALUATION_ACTION.CLEAR_EVALUATION_FAILURE:
        case EVALUATION_ACTION.SET_EVALUATION_SCORE_FAILURE:
        case EVALUATION_ACTION.GET_REPORT_EVALUATION_FAILURE:
        default:
            return state
    }
}
const ProviderEvaluation = ({ children }) => {
    const [evaluationState, evaluationDispatch] = useReducer(evaluationReducer, initialState)
    let searchEmpty = (Array.isArray(evaluationState.searchDate) && evaluationState.searchDate.length === 0) ? true : false

    async function onSubmitScore(userLogin, comfirm, selectMonthYear) {
        let totalScore = 0
        let newIndicator = []
        // console.log('default', { evaluationState })
        evaluationState.indicator.forEach(({ indicatorHeaderId, evaluationId, indicatorLineId, rawScore, scoreWeight }) => {
            newIndicator.push({
                indicatorLineId: indicatorLineId,
                indicatorHeaderId: indicatorHeaderId,
                evaluationId: evaluationId,
                score: (parseFloat(rawScore) * scoreWeight) / 100,
                scoreWeight: scoreWeight,
                rawScore: parseFloat(rawScore)
            })
            totalScore += (parseFloat(rawScore) * scoreWeight) / 100
        })

        let payloadInsert = {
            params: {
                // indicatorHeaderId: evaluationState.indicatorHeaderId,
                employeeId: evaluationState.employeeId,
                totalScore: totalScore,
                month: evaluationState.month,
                year: evaluationState.year,
                indicator: newIndicator,
                userLogin: userLogin,
                statusNo: evaluationState[`status${selectMonthYear.month}`] ? evaluationState[`status${selectMonthYear.month}`] : 1,
            }
        }

        let payloadUpdate = {
            whereId: newIndicator[0].evaluationId,
            params: {
                // indicatorHeaderId: evaluationState.indicatorHeaderId,
                employeeId: evaluationState.employeeId,
                totalScore: totalScore,
                month: evaluationState.month,
                year: evaluationState.year,
                indicator: newIndicator
            },
            statusUpdate: {
                [`status${selectMonthYear.month}`]: evaluationState[`status${selectMonthYear.month}`] === 1 ? 2 : evaluationState[`status${selectMonthYear.month}`],
            },
            dataUpdate: {
                updateDate: new Date(),
                updateBy: userLogin
            }
        }

        //console.log({ payloadInsert, payloadUpdate })
        if (comfirm) {
            //console.log('onSubmitScore', { payloadUpdate })
            await APIUpdateEvaluationScore(payloadUpdate, res => {
                //console.log({ res })
                if (res) {
                    Modal.success({
                        title: "บันทึกสำเร็จ",
                        onOk: () => {
                            evaluationDispatch({ type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: res })
                            clearIndicator()
                            window.location.reload()
                        }
                    })
                } else {
                    evaluationDispatch({ type: EVALUATION_ACTION.SET_EVALUATION_SCORE_FAILURE })
                }
            })
        } else {
            //console.log('onSubmitScore', { payloadInsert })
            await APIInsertEvaluationScore(payloadInsert, res => {
                if (res) {
                    Modal.success({
                        title: "บันทึกสำเร็จ",
                        content: path([0, 'evaluationId'], res),
                        onOk: () => {
                            evaluationDispatch({ type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: res })
                            window.location.reload()
                        }
                    })
                } else {
                    evaluationDispatch({ type: EVALUATION_ACTION.SET_EVALUATION_SCORE_FAILURE })
                }
            })
        }

    }



    async function submitEvaluation(params) {
        const { content, userLogin, update, dataSearch } = params
        // console.log('params', params, content, evaluationState)

        let totalScore = 0
        let insertIndicaor = evaluationState.indicatorFac.reduce((acc, cur) => {
            return [...acc, {
                indicatorLine: cur.indicatorLine,
                indicatorNo: cur.indicatorNo,
                // indicatorGroup: cur.indicatorGroup,
                weight: `${cur.weight}`,
                rawScore: `${cur.rawScore}`,
                // score: `${cur.score}`,
                score: parseFloat(cur.score).toFixed(2),
                createBy: userLogin
            }]
        }, [])
        let updateIndicaor = evaluationState.indicatorFac.reduce((acc, cur) => {
            return [...acc, {
                evaluationNo: cur.evaluationNo,
                evaluationLine: cur.evaluationLine,
                // indicatorGroup: cur.indicatorGroup,
                weight: `${cur.weight}`,
                rawScore: `${cur.rawScore}`,
                // score: `${cur.score}`,
                score: parseFloat(cur.score).toFixed(2),
                updateBy: userLogin
            }]
        }, [])

        let weightByDay = 0

        evaluationState.indicatorFac.forEach(({ score, weight }) => {
            if (score) {
                totalScore += parseFloat(score)
                weightByDay += parseFloat(weight)
            }
        });
        let scoreStatus = content[`score${content.sortEN}`] ? splitString(content[`score${content.sortEN}`], '=)', 0) : 0
        // let scoreMonth = content[`score${content.sortEN}`] ? Number(splitString(content[`score${content.sortEN}`], '=)', 1)) : 0

        let weightBehavior = 0
        let weightQuality = 0
        let weightVolume = 0
        let totalBehavior = 0
        let totalQuality = 0
        let totalVolume = 0

        evaluationState.indicatorFac.forEach(({ indicatorGroup, weight, score, rawScore }) => {
            //console.log({ weight, score, rawScore })
            switch (indicatorGroup) {
                case 'behavior':
                    weightBehavior += parseInt(weight)
                    totalBehavior += (parseInt(weight) * parseInt(rawScore)) / 100
                    break
                case 'quality':
                    weightQuality += parseInt(weight)
                    totalQuality += (parseInt(weight) * parseInt(rawScore)) / 100
                    break
                case 'volume':
                    weightVolume += parseInt(weight)
                    totalVolume += (parseInt(weight) * parseInt(rawScore)) / 100
                    break
                default: break;
            }
        })
        //console.log({ insertIndicaor, updateIndicaor, scoreStatus, totalScore })
        //console.log({ totalBehavior, totalQuality, totalVolume })

        let totalGroup = (totalBehavior + totalQuality + totalVolume) / 3
        let percentageGroup = (totalGroup / 5) * 100
        let percentage = (totalScore / 5) * 100
        //console.log({ totalGroup, percentage, percentageGroup })
        // console.log('fac', totalGroup, weightBehavior, totalBehavior, weightQuality, totalQuality, weightVolume, totalVolume)
        let payloadInsert = {
            "dataHeader":
            {
                "year": content.year,
                "month": content.month,
                "employeeId": content.ID_User,
                "statusNo": (evaluationState.statusApproval) ? 1 : scoreStatus,
                "totalScore": (content.Status_Worker === 'Full') ? `${totalGroup}` : `${totalScore}`,
                "percentage": (content.Status_Worker === 'Full') ? `${percentageGroup}` : `${percentage}`,
                "grade": (content.Status_Worker === 'Full') ? calculateGrade(percentageGroup) : calculateGrade(percentage),
                "createBy": userLogin
            },
            "dataLine": insertIndicaor,
            "dataSearch": dataSearch
        }
        let payloadUpdate = {
            "dataHeader":
            {
                "evaluationNo": updateIndicaor[0].evaluationNo,
                "year": content.year,
                "month": content.month,
                "employeeId": content.ID_User,
                "statusNo": (evaluationState.statusApproval) ? 1 : scoreStatus,
                "totalScore": (content.Status_Worker === 'Full') ? `${totalGroup}` : `${totalScore}`,
                "percentage": (content.Status_Worker === 'Full') ? `${percentageGroup}` : `${percentage}`,
                "grade": (content.Status_Worker === 'Full') ? calculateGrade(percentageGroup) : calculateGrade(percentage),
                "updateBy": userLogin
            },
            "dataLine": updateIndicaor,
            "dataSearch": dataSearch
        }

        // console.log({ searchContent })
        // console.log(content.Status_Worker, update, { payloadInsert, payloadUpdate, weightByDay })

        switch (content.Status_Worker) {
            case 'Full':
                if (weightBehavior !== 100 || weightQuality !== 100 || weightVolume !== 100) {
                    Modal.error({
                        title: 'เกณฑ์การประเมินแต่ละหัวข้อให้ครบ 100%'
                    })
                } else {
                    if (update) {
                        await APIUpdateScoreFactory(payloadUpdate, res => {
                            // console.log({ res })
                            if (res.evaluationNo) {
                                Modal.success({
                                    title: `บันทึกสำเร็จ เลขที่เอกสาร : ${res.evaluationNo}`,
                                    onOk: () => {
                                        // console.log('FULL', JSON.parse(window.localStorage.getItem("dataSearch")) )
                                        window.localStorage.setItem('statusWorker', JSON.stringify(['Full']))
                                        window.location.reload()
                                        // onChangeSdataSearchearch({ fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`, payload: ['Full'] })
                                    }
                                })
                            }
                        })


                    } else {
                        await APIInsertScoreFactory(payloadInsert, res => {
                            // console.log({ res })
                            if (res.docNo) {
                                Modal.success({
                                    title: `บันทึกสำเร็จ เลขที่เอกสาร : ${res.docNo}`,
                                    onOk: () => {
                                        window.localStorage.setItem('statusWorker', JSON.stringify(['Full']))
                                        window.location.reload()
                                        // onChangeSearch({ fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`, payload: ['Full'] })
                                    }

                                })
                            }
                        })
                    }
                }
                break;

            case 'Daily':
                if (weightByDay !== 100) {
                    Modal.error({
                        title: 'เกณฑ์การประเมินแต่ละหัวข้อให้ครบ 100%'
                    })
                } else {
                    if (update) {
                        await APIUpdateScoreFactory(payloadUpdate, res => {
                            // console.log({ res })
                            if (res.evaluationNo) {
                                Modal.success({
                                    title: `บันทึกสำเร็จ เลขที่เอกสาร : ${res.evaluationNo}`,
                                    onOk: () => {
                                        window.localStorage.setItem('statusWorker', JSON.stringify(['Daily']))
                                        window.location.reload()
                                        // window.location.reload()
                                        // onChangeSearch({ fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`, payload: ['Daily'] })
                                    }

                                })
                            }
                        })

                    } else {
                        await APIInsertScoreFactory(payloadInsert, res => {
                            // console.log({ res })
                            if (res.docNo) {
                                Modal.success({
                                    title: `บันทึกสำเร็จ เลขที่เอกสาร : ${res.docNo}`,
                                    onOk: () => {
                                        window.localStorage.setItem('statusWorker', JSON.stringify(['Daily']))
                                        window.location.reload()
                                        // onChangeSearch({ fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`, payload: ['Daily'] })
                                    }

                                })
                            }
                        })
                    }
                }
                break;
            default:
                break;
        }


    }

    function calculateGrade(score) {
        //console.log({ score })
        if (parseFloat(score) < 59.00) {
            return 'D'
        } else if (parseFloat(score) <= 64.00) {
            return 'C-'
        } else if (parseFloat(score) <= 79.00) {
            return 'C'
        } else if (parseFloat(score) <= 89.00) {
            return 'B'
        } else if (parseFloat(score) <= 100.00) {
            return 'A'
        } else {
            return ''
        }
    }

    async function clearIndicator() {
        await evaluationDispatch({ type: EVALUATION_ACTION.CLEAR_EVALUATION_SUCCESS, state: { ...initialState } })
    }

    async function onChangeValue({ fieldname, value }, index) {
        switch (fieldname) {
            case 'indicator':
                await evaluationDispatch({
                    type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: {
                        [fieldname]: value
                    }
                })
                break;
            case 'indicatorFac':
                const { indicatorFac } = evaluationState;
                let newValue = value > 5 ? 5 : value
                // console.log({ newValue })
                evaluationDispatch({
                    type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: {
                        [fieldname]: update(index, { ...indicatorFac[index], ...newValue }, evaluationState.indicatorFac)
                    }
                })
                break;

            default:
                evaluationDispatch({ type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: { [fieldname]: value } })
                break;
        }
    }

    async function viewDetail(values) {
        //console.log('viewDetail', values)
        await evaluationDispatch({
            type: EVALUATION_ACTION.SET_EVALUATION_SCORE_SUCCESS, state: { ...values }
        })
    }

    return (
        <EvaluationContext.Provider
            value={{
                searchEmpty,
                viewDetail,
                onSubmitScore,
                onSubmitScoreFactory: submitEvaluation,
                onChangeValue,
                evaluationState,
                evaluationDispatch
            }}>
            {children}
        </EvaluationContext.Provider>
    )
}



export default ProviderEvaluation