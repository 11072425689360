import React from "react";
import { Tabs } from "antd";
import FormAddSSO from "./conponents/forms/FormAddSSO";
import FormSearchSSONewEmp from "./conponents/forms/FormSearchSSONewEmp";
import FormSearchSSOoutEmp from "./conponents/forms/FormSearchSSOoutEmp";

export const ManageSSO = () => {
  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="ยื่นประกันสังคมพนักงานเข้าใหม่" key="1">
        <FormSearchSSONewEmp />
      </TabPane>
      <TabPane tab="ยื่นประกันสังคมพนักงานพ้นสภาพ" key="2">
        <FormSearchSSOoutEmp />
      </TabPane>
      <TabPane tab="เพิ่มข้อมูลโรงพยาบาลประกันสังคม" key="3">
        <FormAddSSO />
      </TabPane>
    </Tabs>
  );
};

export default ManageSSO;
