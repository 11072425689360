import React, { useContext, useEffect, useRef, useState } from "react";
import { Spin, Modal } from "antd";
import WorkingProcess from "../../components/workingProcess";
import { ContractContext } from "../servicesContract/contractProvider";
import { MasterCenterContext } from "../../service/master/masterProvider";
import { KEY_OPTION } from "../../constants/enums/enumCenter";
import { Container } from "reactstrap";
import * as Layouts from "../../constants/layouts";
import { filterDashboardProbation } from "../../components/probation/ProbationContantsRender";
import { RenderForm } from "../../constants/inputs/RenderForm";
import { PATHNAME } from "../../constants/enums/pathname";
import { Col, Row, Tabs } from "antd";
import { INITIAL_FILTER_PROBATION } from "./../../components/probation/initialProbation";
import { SetDateAPI } from "../../common/setDate";
import { LayoutTable } from "./../../moduleKPIs/common/layouts/layoutTable";
import { TABLE_CONTRACT } from "./../commonsContract/structuresContract/tableContract";
import {
  ButtonExport,
  ButtonIcon,
  FIELD_BUTTON,
} from "../../constants/buttons";
import { APIConfirmProbationContract } from "../servicesContract/contractConnect";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { COLUMNS_EXPORT } from "../commonsContract/structuresContract/columnsContract";
import { TABLE_CONTRACT_VIEW } from "../commonsContract/structuresContract/tableContractView";

const { TabPane } = Tabs;

const PageDashboardContract = (props) => {
  const refExport = useRef();
  const { userLogin, userPermission, getAPI, contractState } =
    useContext(ContractContext);
    // const { userLogin, userPermission, getAPI, contractState } =
    // useContext(ContractViewContext);
  const { masterCenterState, onChangeMaster, getMaster } =
    useContext(MasterCenterContext);
  // const { dataTable: { dataSource } } = contractState;

  const [loading, setLoading] = useState(false);
  const [initialFilter, setInitialFilter] = useState(INITIAL_FILTER_PROBATION);
  const [SegmentTxt, setSegmentTxt] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceView, setDataSourceView] = useState([]);

  const txtOption = { value: "value", label: "label" };

  const options = {
    optionSegment: masterCenterState[KEY_OPTION.CENTER.OPTION_DEP].map(
      (el) => ({ label: el.optionLabel, value: el.optionValue })
    ),
    optionSumProbation: masterCenterState[
      KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT
    ].map((el) => ({ label: el.optionLabel, value: el.optionValue })),
    optionEmployee: masterCenterState[
      KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY
    ].map((el) => ({ label: el.optionLabel, value: el.optionValue })),
  };

  const _pathname = window.location.pathname;

  useEffect(() => {
    async function fetchAPI() {
      try {
        await setLoading(true);
        await Promise.all(
          [window.localStorage.clear()],
          [
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_DEP,
              payload: { userId: userLogin, permission: userPermission },
            }),
          ],
          [
            getMaster({
              fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT,
              payload: { ...masterCenterState },
            }),
          ],
          [
            getMaster({
              fieldname: KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY,
              payload: { ...masterCenterState },
            }),
          ]
        );
        await setLoading(false);
      } catch (error) {
        console.log({ error });
        await setLoading(false);
      }
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    setDataSource(contractState.dataTable.dataSource);
  }, [contractState.dataTable.dataSource]);

  useEffect(() => {
    setDataSourceView(contractState.dataTableView.dataSourceView);
  }, [contractState.dataTableView.dataSourceView]);

  const functionButton = async (name) => {
    setLoading(true);
    switch (name) {
      case "search":
        let _payload = {
          userlogin: userLogin,
          // userID: this.state.userLogin,
          locationLogin: props.locationM,
          depNo: SegmentTxt == "all" ? "" : SegmentTxt,
          statusMainCode: initialFilter.statusMainCode || "",
          startDate: SetDateAPI(initialFilter.StartDateJob),
          toDate: SetDateAPI(initialFilter.ToDateJob),
          searchUser: initialFilter.searchUser,
        };
        console.log({ _payload });
        await getAPI({
          fieldname: KEY_OPTION.PROBATION.DASHBOARD_CONTRACT,
          payload: _payload,
        });
        await getAPI({
          fieldname: KEY_OPTION.PROBATION.DASHBOARD_CONTRACT_VIEW,
          payload: _payload,
        });
        setLoading(false);
        break;
      default:
        return null;
    }
  };

  const handleFilter = (fieldname, value) => {
    console.log("handleFilter", fieldname, value);
    let initialFilterTemp = initialFilter;
    let SegmentTxt = "";
    switch (fieldname) {
      case "SegmentTxt":
        initialFilterTemp = { ...initialFilter, [fieldname]: value.value };
        SegmentTxt = value.value;
        setSegmentTxt(SegmentTxt);
        break;
      case "statusMainCode":
      case "searchUser":
        initialFilterTemp = { ...initialFilter, [fieldname]: value.value };
        break;
      default:
        initialFilterTemp = { ...initialFilter, [fieldname]: value };
        break;
    }
    //('initialFilter wwwwwwwwww', initialFilter)
    setInitialFilter(initialFilterTemp);
  };

  return (
    <Spin spinning={loading}>
      <Container>
        <Layouts.MainTheme
          header={"Dashboard Contract Probation"}
          component={
            <>
              {filterDashboardProbation({
                handlechange: handleFilter,
                initialFilter,
                optionSegment: options.optionSegment,
                optionSumProbation: options.optionSumProbation,
                optionEmployee: options.optionEmployee,
                txtOption,
                functionButton: functionButton,
              }).map((row, i) => (
                <Row key={i} gutter={[16, 16]}>
                  {row.map((each, ie) => (
                    <Col
                      key={ie}
                      md={each.size}
                      className={each.className}
                      style={each.styleCol}
                    >
                      {RenderForm(each)}
                    </Col>
                  ))}
                </Row>
              ))}
              <WorkingProcess pathname={PATHNAME.DASHBOARD_PROBATION} />
            </>
          }
        ></Layouts.MainTheme>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Action" key="1">
            <div style={{ textAlign: "end" }}>
              <ButtonExport
                ref={refExport}
                labelButton=""
                fieldname="แบบประเมินทดลองงาน (สัญญาจ้าง)"
                columns={COLUMNS_EXPORT({
                  pathname: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
                  permission: userPermission,
                })}
                dataSource={dataSource}
                style={{ paddingRight: 10 }}
                styleButton={{}}
              />
            </div>
            <LayoutTable
              style={{ marginTop: 15 }}
              scroll={{ x: 3000 }}
              bordered={false}
              // rowKey={rowKey}
              dataSource={dataSource}
              columns={TABLE_CONTRACT({
                pathname: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
                userLogin: userLogin,
                actionButton: async ({ fieldBtn, payload }) => {
                  await setLoading(true);
                  await window.localStorage.setItem(
                    KEY_OPTION.DATA.PROBATION_CONTRACT,
                    JSON.stringify(payload)
                  );
                  switch (fieldBtn) {
                    case FIELD_BUTTON.INSERT_CONTRACT:
                      await window.open(
                        PATHNAME.INSERT_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    case FIELD_BUTTON.UPDATE_CONTRACT:
                      await window.open(
                        PATHNAME.UPDATE_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    case FIELD_BUTTON.CONFIRM_CONTRACT:
                      //console.log({ payload })
                      await APIConfirmProbationContract(
                        {
                          DocNo: payload.DocNo,
                          user_login: userLogin,
                        },
                        (res) => {
                          //console.log({ res })
                          if (res) {
                            Modal.success({
                              title: `ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร ${res.data.DocNo}`,
                              onOk: () => window.location.reload(),
                            });
                          }
                        }
                      );
                      break;
                    case FIELD_BUTTON.INSERT_BENEFIT_CONTRACT:
                      //console.log({ payload })
                      // const userLogin = this.props.userLogin;
                      // const link = API_URL + "/HRM_API/insert_timeStampBenefits";
                      // let docSOS = ''
                      // if (_getType === 'sos') {
                      //   docSOS = this.state.fields[_data.StaffCode]
                      // }
                      // #  Benefits New
                      // var data = {
                      //   StaffCode: _data.StaffCode,
                      //   typeInsurance: _getType,
                      //   typeBenefit: 'in',
                      //   user_login: userLogin,
                      //   docSOS: docSOS,
                      //   refDocProbation: _data.DocNo
                      // };
                      // #  Benefits Out
                      // var data = {
                      //     StaffCode: _data.StaffCode,
                      //     typeInsurance: _getType,
                      //     typeBenefit: 'out',
                      //     user_login: userLogin
                      //   };

                      // #  Track Probation
                      // var data = {
                      //     StaffCode: _data.StaffCode,
                      //     typeInsurance: _getType,
                      //     typeBenefit: 'in',
                      //     user_login: userLogin
                      //   };

                      // await APIInsertTimeStampBenefits(null, (res) => {
                      //     console.log({ res })
                      //     if (res.status_api === 1) {
                      //         Modal.success({
                      //             title: `บันทึกเรียบร้อยแล้ว`,
                      //             onOk: () => window.location.reload(true) // search dat in APIBenefitOut or APIBenefit   let pathURL = `${"user_login="}${this.state.userLogin}${"&locationID="}${locationID}${"&statusProbation="}${statusProbationCode}${"&txtUserSeaching="}${txtUserSeaching}${"&SegmentTxt="}${SegmentTxt}${"&statusMainCode=3"}${"&StartDateJob="}
                      //             // ${SetDateAPI(initialFilter.StartDateJob)}${"&toDateJob="}${SetDateAPI(initialFilter.toDateJob)}`;
                      //         })
                      //     }
                      // })
                      break;
                    case FIELD_BUTTON.VIEW_CONTRACT:
                      //console.log({ payload })
                      await window.open(
                        PATHNAME.VIEW_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    default:
                      break;
                  }
                  await setLoading(false);
                },
              })}
            />
          </TabPane>

          <TabPane tab="View" key="2">
          <div style={{ textAlign: "end" }}>
              <ButtonExport
                ref={refExport}
                labelButton=""
                fieldname="แบบประเมินทดลองงาน (สัญญาจ้าง)"
                columns={COLUMNS_EXPORT({
                  pathname: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
                  permission: userPermission,
                })}
                dataSource={dataSourceView}
                style={{ paddingRight: 10 }}
                styleButton={{}}
              />
            </div>
            <LayoutTable
              style={{ marginTop: 15 }}
              scroll={{ x: 3000 }}
              bordered={false}
              // rowKey={rowKey}
              dataSource={dataSourceView}
              columns={TABLE_CONTRACT_VIEW({
                pathname: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
                userLogin: userLogin,
                actionButton: async ({ fieldBtn, payload }) => {
                  await setLoading(true);
                  await window.localStorage.setItem(
                    KEY_OPTION.DATA.PROBATION_CONTRACT,
                    JSON.stringify(payload)
                  );
                  switch (fieldBtn) {
                    case FIELD_BUTTON.INSERT_CONTRACT:
                      await window.open(
                        PATHNAME.INSERT_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    case FIELD_BUTTON.UPDATE_CONTRACT:
                      await window.open(
                        PATHNAME.UPDATE_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    case FIELD_BUTTON.CONFIRM_CONTRACT:
                      //console.log({ payload })
                      await APIConfirmProbationContract(
                        {
                          DocNo: payload.DocNo,
                          user_login: userLogin,
                        },
                        (res) => {
                          //console.log({ res })
                          if (res) {
                            Modal.success({
                              title: `ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร ${res.data.DocNo}`,
                              onOk: () => window.location.reload(),
                            });
                          }
                        }
                      );
                      break;
                    case FIELD_BUTTON.INSERT_BENEFIT_CONTRACT:
                      break;
                    case FIELD_BUTTON.VIEW_CONTRACT:
                      await window.open(
                        PATHNAME.VIEW_PROBATION_CONTRACT,
                        "_blank"
                      );
                      break;
                    default:
                      break;
                  }
                  await setLoading(false);
                },
              })}
            />
          </TabPane>
        </Tabs>
      </Container>
    </Spin>
  );
};

export default PageDashboardContract;
