import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportAll } from "../../constants/HeaderExcel";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { sendDataExcel, toUndefined, sumColumnWidth, GET_UserLogin, subStringDate } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useAlert } from "../modal/CustomAlert";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportAll } from "../../constants/Reports/Components/ComponentReports";
import { GET, GET_SELECT_REPORT_ALL_PROFILE } from "../../../service";
import { ColumnReportALL } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportAll = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();

  // [START] States
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    companyCode: useOptionSelect("company"),
    location: optionSelectLocation,
    userId: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
  }
  // [START] Handle Form
  const onFinish = (values) => {
    const { companyCode, location, userId } = values
    let objectBody = {
      company: companyCode || "",
      location: location || "",
      userId: userId || "",
    }
    getReportAll(useFixedQueryString(objectBody));
  };
  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
  };
  // [END] Handle Form

  // [START] REST API
  const getReportAll = async (query) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const response = await GET(GET_SELECT_REPORT_ALL_PROFILE(query));
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: `${index}`,
          ...row,
          StartDateJob: subStringDate(row.StartDateJob),
          probationDateEnd: subStringDate(row.probationDateEnd),
          birthday: subStringDate(row.birthday),
        }));
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report All Profile: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    if (name === "companyCode") {
      if (locationLogin === "FAC") {
        setUndefined = toUndefined(["userId"]);
      } else {
        setUndefined = toUndefined(["location", "userId"]);
      }
    } else if (name === "location") {
      setUndefined = toUndefined(["userId"]);
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form
        form={form}
        onFinish={onFinish}
        {...configForm}
        initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }}
      >
        <Card
          title="รายงานสร้างไฟล์ข้อมูลพนักงาน"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportAll({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                location: locationLogin === "FAC" || false,
              }
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานสร้างไฟล์ข้อมูลพนักงาน"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportAll(ColumnReportALL),
              dataset: sendDataExcel(dataTable),
              filename: "รายงานสร้างไฟล์ข้อมูลพนักงาน",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReportALL}
            DataTable={dataTable}
            moreProps={{
              scroll: {
                x: sumColumnWidth(ColumnReportALL) || 15250,
                y: 500,
              },
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportAll;
