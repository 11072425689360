import React from 'react';
import { DocumentDetail } from '../style';
import { TextSmall } from '../../../../components/global/text';
import { generalDetailAll } from '../forms/generalDetail';
import { RenderForm } from '../../../../components/global/forms';

const GeneralDocumentDetail = ({
  control,
  setValue,
  getValues,
  options = {
    optionsCompanyTH: [],
    optionsPositionEN: [],
    optionsPositionTH: [],
    optionsDepartmentTH: [],
    optionsSubDepartmentTH: [],
    optionsWorkShift: [],
  },
}) => {
  return (
    <DocumentDetail title={<TextSmall bold={false} text={'ข้อมูลทั่วไป'} />}>
      <RenderForm
        control={control}
        forms={generalDetailAll(setValue, getValues, options)}
        setValue={setValue}
      />
    </DocumentDetail>
  );
};

export default GeneralDocumentDetail;
