
export function SetDate(_date = "") {
    // console.log(_date)
    if (_date) {
        const regex = /\//gi;
        const shotDate = _date.replace(regex, '-');
        const result = shotDate.substr(0, 10).split("-").reverse().join("-");
        // console.log(result)
        return result
    }
    return '-'
}

export function SetDateStr (date) {
    if (date) {
        const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        const [year, month, day] = date.split("-");
        const formattedDate = `${parseInt(day)} ${months[parseInt(month) - 1]} ${year}`;
        return formattedDate;
    }
    return '-'
}  

export function getFormatted(_info = "") {

    return _info < 10 ? `0${_info}` : _info;
}


export function SetDateAPI(_date = "") {

    const _selectDate = new Date(_date);
    return _date ? `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}` : "";

}