import React from "react";
import { useForm } from "react-hook-form";
import ReportPage from "../../../components/global/pages/ReportPage";
import { formFilter } from "./forms/reportFilter";
import { useFormState } from "./hook/useFormState";
import { useGetOptions } from "./hook/useGetOptions";
import { useGetDataSource } from "./hook/useGetDataSource";
import { employFormColumn } from "./constants/tableColumn";

const FormContractDashboardScene = () => {
  const { defaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues, });
  const { optionsEmp, optionsCompany, loadingOption } = useGetOptions();
  const { dataSource, onFetchDataSource, loadingDataSource } = useGetDataSource();
  
  return (
    <ReportPage
      title={"สร้างฟอร์มสัญญา"}
      filterForm={formFilter({
        optionsEmp,
        optionsCompany,
      })}
      filterLoading={
        loadingOption.emp || loadingOption.company || loadingOption.startDateJob
      }
      tableLoading={loadingDataSource}
      tableColumn={employFormColumn()}
      dataSource={dataSource}
      onSearchClick={handleSubmit(onFetchDataSource)}
      control={control}
      setValue={setValue}
      getValues={getValues}
    />
  );
};

export default FormContractDashboardScene;
