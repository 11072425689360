import React, { useState, useContext, useEffect, useRef } from 'react'
import { Spin } from 'antd'
import { FIELD_BUTTON, TEXT_BUTTON } from '../../constants/buttons'
import { ButtonTheme, ExportExcel } from '../../moduleKPIs/common/buttons'
import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { TABLE_DASHBOARD_TRAINING } from '../commonsTraining/structuresTraining/tableTraining'
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider'
import { PATHNAME } from '../../constants/enums/pathname'
import { MasterTrainingContext } from '../servicesTraining/masterTraining/masterTrainingProvider'
import { COLUMNS_EXPORT } from '../commonsTraining/structuresTraining/columnsTraining'
import { colors } from '../../themes'
import { GET, GET_EXPORT_TEMPLATE_OJT, POST, POST_IMPORT_OJT } from '../../service'
import { CONNECT_API, USE_CONNECTION_MODE } from '../../components/connectAPI'
import { RenderMainModal } from '../../screens/conponents/modal/CustomModalAntd'
import { UseUpload } from '../../screens/conponents/uploads/UploadAntd'
import { useAlert } from '../../screens/conponents/modal/CustomAlert'

const DashboardTraining = ({ isView }) => {
    const refExport = useRef()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [fileUpload, setFileUpload] = useState([]);
    const selectDate = ''
    const { userLogin, manageTrainingState, getAPI } = useContext(ManageTrainingContext);
    const { masterTrainingState } = useContext(MasterTrainingContext);
    const { dataTableAction, dataTableView } = manageTrainingState


    async function fetchAPI() {
        await setLoading(true)
        await window.localStorage.clear()
        if (isView) {
            await getAPI({ fieldname: REPORT_OPTION.DASHBOARD_TRAINING_VIEW, payload: { ...masterTrainingState } })
        } else {
            await getAPI({ fieldname: REPORT_OPTION.DASHBOARD_TRAINING_ACTION, payload: { ...masterTrainingState, type: 'trainingFirst' } })
        }
        await setLoading(false)
    }

    useEffect(() => {
        fetchAPI()
    }, [])

    const onExportTemplateOJT = async (payload = {}) => {
        try {
            const { positionNo, userId } = payload
            const res = await GET(GET_EXPORT_TEMPLATE_OJT(`?positionNo=${positionNo}&userId=${userId}`));
            //const res = await GET(GET_EXPORT_TEMPLATE_OJT(`?positionNo=DP-COF-HR-RC-OF&userId=DT65499`));
            const { success, result } = res;
            if (success) {
                const pathExportOJT = `${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`;
                window.open(pathExportOJT, '_blank');
            }
        } catch (err) {
            console.log('err: export-template-ojt', err.message);
        }
    }

    const onImportTemplateOJT = async () => {
        try {
            const { userId } = userLogin
            const formData = new FormData();
            formData.append("userLogin", userId);
            formData.append("file", fileUpload[0].originFileObj);
            const res = await POST(POST_IMPORT_OJT, formData);
            const { success } = res;
            if (success) {
                useAlert({
                    type: 'success',
                    title: 'Import ข้อมูลสำเร็จ',
                    Fn: () => {
                        setShow(false);
                        window.location.reload();
                    },
                });
            } else {
                useAlert({ type: 'error', title: res.message || 'Import ข้อมูลไม่สำเร็จ' });
            }
        } catch (err) {
            console.log('err: import-ojt', err.message);
        }
    }

    const onChangeFileUpload = (e) => {
        const { fileList = [] } = e
        if (fileList.length === 0) {
            setFileUpload([]);
            return;
        }

        const { originFileObj } = fileList[0]
        if (!originFileObj.name.includes('.xlsx')) {
            setFileUpload([]);
            return;
        }
        setFileUpload(e.fileList);
    }

    return (
        <div >
            <RenderMainModal
                show={show}
                onCancel={() => {
                    setShow(!show);
                    setFileUpload([]);
                }}
                txtTitle={'Import OJT'}
                ModalContent={
                    <div>
                        <UseUpload
                            fnChange={e => onChangeFileUpload(e)}
                            componentProps={{
                                fileList: fileUpload,
                                accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            }}

                        />
                    </div>
                }
                footer={
                    <ButtonTheme
                        style={{ padding: 5, zIndex: 999 }}
                        buttonStyle={{ backgroundColor: colors.buttonTheme, color: colors.white, width: 100 }}
                        buttonText={"UPLOAD"}
                        onClick={() => onImportTemplateOJT()}
                        disabled={fileUpload.length === 0}
                    />
                }
            />
            <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    buttonStyle={{ background: colors.buttonSearch, color: colors.white }}
                    style={{ padding: 10 }}
                    buttonText={TEXT_BUTTON.SEARCH}
                    onClick={async () => {
                        await setLoading(true)
                        await getAPI({ fieldname: isView ? REPORT_OPTION.DASHBOARD_TRAINING_VIEW : REPORT_OPTION.DASHBOARD_TRAINING_ACTION, payload: { ...masterTrainingState, type: 'trainingAction' } })
                        await setLoading(false)
                    }}
                />

            </div>
            <div className="layout-content-btn-search" style={{ top: '13.8em', right: '20px' }}>
                <ExportExcel
                    ref={refExport}
                    fieldname={`บันทึกผลการอบรมการฝึกปฏิบัติงาน (OJT)`}
                    columns={COLUMNS_EXPORT({ pathname: PATHNAME.DASHBOARD_TRAINING, permission: userLogin.userPermission })}
                    dataSource={(isView) ? dataTableView.dataSource : dataTableAction.dataSource}
                />
                <ButtonTheme
                    style={{ padding: 5 }}
                    buttonStyle={{ backgroundColor: colors.white, color: colors.buttonCreate, width: 100 }}
                    buttonText={TEXT_BUTTON.IMPORT_OJT}
                    onClick={() => setShow(true)}
                />
            </div>

            <Spin spinning={loading} tip="กำลังโหลด" >
                <LayoutTable
                    scroll={{ x: true }}
                    bordered
                    rowKey={isView ? dataTableView.rowKey : dataTableAction.rowKey}
                    dataSource={isView ? dataTableView.dataSource : dataTableAction.dataSource}
                    columns={TABLE_DASHBOARD_TRAINING({
                        userLogin: userLogin,
                        permission: userLogin.userPermission,
                        isView: isView,
                        selectDate: selectDate,
                        actionButton: async ({ fieldBtn, payload }) => {
                            await setLoading(true)
                            await window.localStorage.setItem(REPORT_OPTION.VIEW_TRAINING, JSON.stringify(payload))
                            switch (fieldBtn) {
                                case FIELD_BUTTON.INSERT_TRAINING:

                                    await getAPI({
                                        fieldname: REPORT_OPTION.SELECT_TRAINING_COURSE,
                                        payload: { positionNo: payload.positionNo }
                                    })
                                    await getAPI({
                                        fieldname: REPORT_OPTION.SELECT_TRAINING_USER,
                                        payload: payload,
                                        // pathname: `${PATHNAME.INSERT_TRAINING}`
                                    })
                                    await window.open(PATHNAME.INSERT_TRAINING, '_blank')

                                    break;
                                case FIELD_BUTTON.UPDATE_TRAINING:
                                    await getAPI({
                                        fieldname: REPORT_OPTION.SELECT_TRAINING_COURSE,
                                        payload: { positionNo: payload.positionNo, docNo: payload.docNo }
                                    })
                                    await getAPI({
                                        fieldname: REPORT_OPTION.SELECT_TRAINING_USER_BY_DOC_NO,
                                        payload: {
                                            userId: payload.userId,
                                            docNo: payload.docNo,
                                            filePath: payload.filePath,
                                            filename: payload.filename
                                        },
                                        // pathname: `${PATHNAME.UPDATE_TRAINING}`
                                    })
                                    await window.open(PATHNAME.UPDATE_TRAINING, '_blank')
                                    break;
                                case FIELD_BUTTON.VIEW_TRAINING:
                                    await getAPI({
                                        fieldname: REPORT_OPTION.SELECT_TRAINING_COURSE,
                                        payload: { positionNo: payload.positionNo }
                                    })

                                    await getAPI({
                                        fieldname: REPORT_OPTION.VIEW_TRAINING,
                                        payload: {
                                            userId: payload.userId,
                                            docNo: payload.docNo,
                                            filePath: payload.filePath,
                                            filename: payload.filename
                                        },
                                        // pathname: `${PATHNAME.VIEW_TRAINING}`
                                    })
                                    await window.open(PATHNAME.VIEW_TRAINING, '_blank')
                                    break;
                                case FIELD_BUTTON.EXPORT_OJT:
                                    await onExportTemplateOJT({ ...payload });
                                    break;
                                default:
                                    break;
                            }
                            await setLoading(false)
                        }
                    })}
                    pagination={{
                        pageSize: 100,
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                    }}
                />

            </Spin>
        </div>
    )
}

export default DashboardTraining