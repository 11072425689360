import React, { useState } from "react";
import { Form, Card, Row, Spin } from "antd";
import { ApprovalFlow, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_PageApproval } from "../../constants/HeaderExcel";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin, sumColumnWidth, toUndefined } from "../../functions/UseFunction";
import { useFixedQueryString, useMakeQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useConfirm } from "../modal/CustomConfirm";
import { useText } from "../typography/Typography";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsSearchBranchApprove } from "../../constants/ManageBranchApprove/Components";

const FormSearchBranchApprove = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUserCount, setModalUserCount] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  // [END] States

  // [START] Variables
  let querySearchApprove = useMakeQueryString(changeSearchValue);
  let queryApproveFlow = useFixedQueryString(changeSearchValue, ["company", "location"]);

  let options = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    flowId: useOptionSelect("approval", queryApproveFlow),
  }
  // [END] Variables

  // [START] Handle Form Antd
  const onFinish = (values) => {
    searchApproveFlow();
  };

  const onReset = () => {
    form.resetFields();
    setChangeSearchValue({});
    setDataTable([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const searchApproveFlow = async () => {
    try {
      setDataTable([]);
      setLoadingScreen(true);
      let url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_ApproveFlow}?${querySearchApprove}`;
      const response = await fetch(url);
      const data = await response.json();
      let mapResult = []
      if (response.ok) {
        const RESULT = data.result;
        if (RESULT.length !== 0) {
          mapResult = RESULT.map((val, index) => ({
            key: index,
            flowId: val.flowId,
            flowName: val.flowName,
            userCount: val.userCount,
            statusActive: val.statusActive,
          }));
        } else {
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        }
      }
      setDataTable(mapResult);
    } catch (err) {
      console.log("Error Search ApproveFlow: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const putDEACTIVE = async (record) => {
    if (!record) return null;
    try {
      setLoadingScreen(true);
      let statusActive = record.statusActive ? `inactive` : `active`;
      let statusChange = record.statusActive ? false : true;
      let url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.DELETE_INACTIVE_ApproveFlow}${record.flowId}/${statusActive}`;
      const response = await fetch(url, {
        method: `PUT`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การจัดการสถานะสำเร็จ`,
          Fn: () =>
            setDataTable(
              dataTable.map((val) => {
                return val.flowId === record.flowId
                  ? { ...val, statusActive: statusChange }
                  : val
              })
            ),
        });
      } else {
        useAlert({
          type: "info",
          title: "การจัดการสถานะไม่สำเร็จ",
          content: `${data.message}`,
        });
      }
    } catch (err) {
      console.log("Error deActive Approve Flow: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const getEmployeeList = async (record) => {
    try {
      setShowModal(true);
      setDataRow(record);
      setLoadingContentModal(true);
      setModalUserCount([]);
      const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_EmployeeList_BY_ID}${record["flowId"]}`;
      const response = await fetch(url);
      const data = await response.json();
      let mapResult = []
      if (response.ok) {
        const RESULT = data.result;
        mapResult = RESULT.map((val, index) => ({
          key: index,
          ID_User: val.ID_User,
          Name: val.Name,
          NickName: val.NickName,
        }));
      }
      setModalUserCount(mapResult);
    } catch (err) {
      console.log("Error Data Modal Approve Flow: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingContentModal(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndifined = {};
    let arrayInput = ['company', 'location', 'flowId'];
    switch (name) {
      case "company":
        setUndifined = toUndefined(arrayInput.slice(1));
        break;
      case "location":
        setUndifined = toUndefined(arrayInput.slice(2));
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...setUndifined,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { Column_Approval, ColumnCountEmployees } = useColumnTable({
    Config: {
      openModal: getEmployeeList,
      fnDelete: (record) => useConfirm({ FnOK: () => putDEACTIVE(record) }),
    },
  });
  // [END] Control Column
  const contentModalUsersBranchApprove = () => {
    return (
      <div style={{ width: "100%" }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingContentModal}>
          {modalUserCount.length !== 0 ? (
            <CustomTableAtnd
              HeadTable={ColumnCountEmployees}
              DataTable={modalUserCount}
              SetScroll={{ x: sumColumnWidth(ColumnCountEmployees) || "20%" }}
              TableLayout={`fixed`}
            />
          ) : (
            useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
          )}
        </Spin>
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setDataRow([])
  }
  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <RenderMainModal
        show={showModal}
        onCancel={closeModal}
        txtTitle={`สมาชิก : ${dataRow.flowName}`}
        ModalContent={contentModalUsersBranchApprove()}
      />
      <Card title="ข้อมูลสายอนุมัติ" style={{ marginBottom: 30 }}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsSearchBranchApprove({
              options,
              isDisable: {
                location: changeSearchValue["company"] ? false : true,
                flowId: changeSearchValue["company"] ? false : true,
              },
              onClick: onReset,
              handleChange: onChangeValue,
            })
          })}
        </Form>
      </Card>
      {dataTable.length !== 0 && (
        <>
          <Row justify="end" align="middle" style={{ marginBottom: 30 }}>
            {useExportExcel({
              header: Header_PageApproval,
              dataset: dataTable,
              filename: "รายงานสายอนุมัติ",
            })}
          </Row>
          <CustomTableAtnd
            HeadTable={Column_Approval}
            SetScroll={{ x: sumColumnWidth(Column_Approval) || 720, y: 500 }}
            DataTable={dataTable}
          />
        </>
      )}
    </Spin>
  );
};

export default FormSearchBranchApprove;
