// export const size = {
//   smaller: "10px",
//   small: "12px",
//   medium: "14px",
//   large: "16px",
//   larger: "18px",
//   header: "24px"
// };


export const size = {
  smaller: "8",
  small: "10px",
  medium: "12px",
  large: "14px",
  larger: "16px",
  header: "18px"
};
