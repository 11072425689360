import { useEffect, useState } from "react";
import {
  GET,
  GET_OPTION_BENEFITS,
  GET_SELECT_OPTION_DEPARTMENT_ALL,
  GET_USER_LIST
} from "../../../service";

export const useGetOptions = () => {
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsEmp, setOptionsEmp] = useState([]);
  const [loadingOption, setLoadingOption] = useState({
    company: false,
    departmentNo: false,
    employeeNo: false,
  });

  useEffect(() => {
    onFetchOptionsCompany();
    onFetchOptionsDepartment();
    onFetchOptionsEmp();
  }, []);

  const onFetchOptionsCompany = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, company: true }));
      const respond = await GET(GET_OPTION_BENEFITS("company"));
      setOptionsCompany(() => [
        { label: "ทุกบริษัท", value: "all", key: "allCompany" },
        ...respond.result.data.map((e) => ({
          label: e.companyNameTH,
          value: e.companyCode,
          key: e.companyCode,
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, company: false }));
    }
  };

  const onFetchOptionsDepartment = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, departmentNo: true }));
      const respond = await GET(GET_SELECT_OPTION_DEPARTMENT_ALL("", ""));
      setOptionsDepartment(() => [
        { label: "ทุกแผนก", value: "all", key: "allDepartment" },
        ...respond.result.map((e) => ({ label: e.depNameTH, value: e.depNo, key: e.depNo, }))
        ]
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, departmentNo: false }));
    }
  };

  const onFetchOptionsEmp = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, employeeNo: true }));
      const respond = await GET(GET_USER_LIST);
      setOptionsEmp([
        { label: "พนักงานทุกคน", value: "all", key: "allEmployee" },
        ...respond.result,
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, employeeNo: false }));
    }
  };

  const optionStatus = [{ label: "รอยืนยัน", value: "WAIT", key: "WAIT" },{ label: "ยืนยันแล้ว", value: "CONFIRM", key: "CONFIRM" }]
  const optionStatusGuarantee = [{ label: "จำเป็นต้องระบุ", value: "REQUIRED", key: "REQUIRED" },{ label: "ไม่จำเป็นต้อง", value: "NONE", key: "NONE" }]

  return {
    optionsCompany,
    optionsDepartment,
    optionsEmp,
    optionStatus,
    optionStatusGuarantee,
    loadingOption,
  };
};
