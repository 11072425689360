import React, { createContext, useReducer } from "react";
import { MASTER_OPTION, servicesOptions } from "../../../moduleKPIs/services/servicesOptions";
// import { APIMasterFilterSubDepFactory } from "../../../moduleKPIs/services/master/masterConnect";
import { APISelectHRMFlow, APISelectSubDepartmentOT, APISelectFilterRequestOT, APISelectOptionDashboard, APISelectFilterDashboardOTFac, APISelectOptionWorkShift } from "./masterOTConnect";
import { path } from "ramda";
import { Modal } from "antd";
import { APISelectUserRequestOT } from "../manageOT/manageOTConnect";
import { GET_SELECT_OPTION_ALL, GET } from '../../../service/index'


export const MasterOTContext = createContext();

const initialState = {
	masterUser: {
		userData: null,
		userLists: []
	},

	[`SEARCH_${MASTER_OPTION.SUB_DEPARTMENT_FACTORY}`]: [],
	[`OPTION_${MASTER_OPTION.SUB_DEPARTMENT_FACTORY}`]: [],

	[`SEARCH_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: [],
	[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: [],

	[`SEARCH_${MASTER_OPTION.FILTER_DASHBOARD_OT}`]: [],
	[`OPTION_${MASTER_OPTION.FILTER_DASHBOARD_OT}`]: [],

	[`SEARCH_${MASTER_OPTION.FILTER_STATUS_OT}`]: [],
	[`OPTION_${MASTER_OPTION.FILTER_STATUS_OT}`]: [],

	[`SEARCH_${MASTER_OPTION.FILTER_WORK_SHIFT}`]: [],
	[`OPTION_${MASTER_OPTION.FILTER_WORK_SHIFT}`]: [],
}

const MASTER_OT_ACTION = {
	SET_MASTER_OT_SUCCESS: "SET_MASTER_OT_SUCCESS",
	SET_MASTER_OT_FAILURE: "SET_MASTER_OT_FAILURE"
}

const masterOTReducer = (state, action) => {
	switch (action.type) {
		case MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS:
			return { ...state, ...action.state }
		case MASTER_OT_ACTION.SET_MASTER_OT_FAILURE:
		default:
			return state
	}
}

const ProviderMasterOT = ({ children }) => {
	const { userLogin, userPermission } = children.props;
	const [masterOTState, masterOTDispatch] = useReducer(masterOTReducer, initialState)
	// console.log({ masterOTState })

	async function getMaster({ fieldname, payload, selectAll = false }) {
		// console.log('getMaster', fieldname, payload)
		switch (fieldname) {
			// case MASTER_OPTION.FILTER_SUB_DEP_OT:
			//     switch (payload.userPermission) {
			//         case 'HR':
			//         case 'HR-HeadDep':
			//         case 'HR-Admin':
			//         case 'HR-AdminOff':
			//         case 'HR-Ass':
			//         case 'Intern':
			//         case 'HR-Super':
			//             await APISelectSubDepartmentOT({ subDepNo: '' }, res => {
			//                 // console.log('PERMISSION - HR', res)
			//                 if (res) {
			//                     masterOTDispatch({
			//                         type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
			//                             [`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res, true),
			//                         }
			//                     })
			//                 }
			//             })
			//             break;
			//         default:
			//             await APISelectFilterDashboardOTFac({
			//                 userId: payload.userId// 'DP54024'
			//             }, res => {
			//                 console.log('APISelectFilterDashboardOTFac', payload, res)
			//                 if (res) {
			//                     masterOTDispatch({
			//                         type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
			//                             [`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res.resultsubDep, true),
			//                             [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, true)
			//                         }
			//                     })
			//                 }
			//             })
			//             break;
			//     }
			//     break;

			case MASTER_OPTION.USER_LOGIN_DATA:
				// console.log('USER_LOGIN_DATA', payload)
				let _loginData = {
					userId: userLogin,
					userPermission: userPermission,
					subDepNo: '',
					depNo: '',
				}
				if (payload.subDep) {
					// console.log('SELECT')
					await APISelectUserRequestOT({ ...payload }, async res => {
						// console.log(res)
						if (res) {
							masterOTDispatch({
								type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
									masterUser: {
										...masterOTState,
										userLists: res,
									},

								}
							})
							await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDep }, res => {
								// console.log('APISelectHRMFlow FLOW', payload, res)
								if (res) {
									masterOTDispatch({
										type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
											[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.HRM_FLOW_FACTORY, res)
										}
									})
								}
							})
						}
					})
				} else {
					await APISelectFilterRequestOT({ ...payload }, async (res) => {
						// console.log('APISelectFilterRequestOT', res)
						if (res) {
							// let _subDepNo = path(['userloginData', 0, 'subDepNo'], res) || ''
							// let _depNo = path(['userloginData', 0, 'DepartmentNo'], res) || ''
							// let _loginData = {
							//     userId: userLogin,
							//     userPermission: userPermission,
							//     subDepNo: _subDepNo,
							//     depNo: _depNo,
							// }
							_loginData.subDepNo = path(['userloginData', 0, 'subDepNo'], res) || ''
							_loginData.depNo = path(['userloginData', 0, 'DepartmentNo'], res) || ''
							masterOTDispatch({
								type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
									masterUser: {
										userData: path(['userloginData', 0], res),
										userLists: path(['workerInsubDep'], res),
									},
									[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.hrmFlow)
								}
							})

							await APISelectOptionWorkShift({ payload: null },
								resWorkShift => {
									// console.log('FILTER_WORK_SHIFT', resWorkShift)
									if (resWorkShift) {
										masterOTDispatch({
											type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
												[`OPTION_${MASTER_OPTION.FILTER_WORK_SHIFT}`]: servicesOptions(MASTER_OPTION.FILTER_WORK_SHIFT, resWorkShift.optionData)
											}
										})
									}
								})
						}
					})
				}
				window.localStorage.setItem("loginData", JSON.stringify({ ..._loginData }))

				break;

			case MASTER_OPTION.FILTER_STATUS_OT:
				await APISelectOptionDashboard(null, res => {
					// console.log('FILTER_STATUS_OT', res)
					if (res) {
						masterOTDispatch({
							type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
								[`OPTION_${fieldname}`]: servicesOptions(fieldname, res.optionData, true)
							}
						})
					}
				})
				break;
			case MASTER_OPTION.FILTER_WORK_SHIFT:
				// console.log('FILTER_WORK_SHIFT', payload)
				await APISelectOptionWorkShift({ ...payload }, res => {
					// console.log('FILTER_WORK_SHIFT', res)
					if (res) {
						masterOTDispatch({
							type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
								[`OPTION_${fieldname}`]: servicesOptions(fieldname, res.optionData)
							}
						})
					}
				})
				break;
			case MASTER_OPTION.FILTER_HRM_FLOW_OT:
				await APISelectHRMFlow({ ...payload }, res => {
					if (res) {
						masterOTDispatch({
							type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
								[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.HRM_FLOW_FACTORY, res)
							}
						})
					}
				})
				break;
			case MASTER_OPTION.FILTER_DASHBOARD_OT:
				// console.log('FILTER_DASHBOARD_OT', payload)
				switch (payload.userPermission) {
					// case 'HR':
					// case 'HR-HeadDep':
					// case 'HR-Admin':
					// case 'HR-AdminOff':
					// case 'HR-Ass':
					// case 'Intern':
					// case 'HR-Super':
					case 'HRS-OFF':
					case 'HRD-OFF':
					case 'HRM-OFF':
					case 'HRS-FAC':
					case 'HRD-FAC':
					case 'HRM-FAC':
						await APISelectSubDepartmentOT({ subDepNo: '' }, res => {
							//console.log('PERMISSION - HR')
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res, selectAll),
									}
								})
							}
						})
						await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res, selectAll)
									}
								})
							}
						})
						break;

					default:
						//console.log('OTHER USER', payload)
						await APISelectFilterDashboardOTFac({
							userId: payload.userId,
							subDepNo: payload.subDepNo,
							startDate: payload.startDate,
							endDate: payload.endDate,
							startTime: payload.startTime,
							endTime: payload.endTime
						}, res => {
							//console.log('PERMISSION - OTHER')
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res.resultsubDep, selectAll),
										// [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
									}
								})
							}
						})
						await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
									}
								})
							}
						})
					// await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
					//     if (res) {
					//         masterOTDispatch({
					//             type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
					//                 [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res)
					//             }
					//         })
					//     }
					// })
					// break;
				}
				break;
			case MASTER_OPTION.FILTER_INSERT_OT:
				// console.log('FILTER_DASHBOARD_OT', payload)
				switch (payload.userPermission) {
					// case 'HR':
					// case 'HR-HeadDep':
					// case 'HR-Admin':
					// case 'HR-AdminOff':
					// case 'HR-Ass':
					// case 'Intern':
					// case 'HR-Super':
					case 'HRS-OFF':
					case 'HRD-OFF':
					case 'HRM-OFF':
					case 'HRS-FAC':
					case 'HRD-FAC':
					case 'HRM-FAC':
						const resSelectPermission = await GET(GET_SELECT_OPTION_ALL())
						if (resSelectPermission) {
							masterOTDispatch({
								type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
									[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, resSelectPermission.result.subDep, selectAll),
									// [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
								}
							})
						}
						// await APISelectSubDepartmentOT({ subDepNo: '' }, res => {
						// 	console.log('PERMISSION - HR')
						// 	if (res) {
						// 		masterOTDispatch({
						// 			type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
						// 				[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res, selectAll),
						// 			}
						// 		})
						// 	}
						// })
						await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res, selectAll)
									}
								})
							}
						})
						break;

					default:
						//console.log('OTHER USER', payload)
						const resSelectPermission2 = await GET(GET_SELECT_OPTION_ALL())
						if (resSelectPermission2) {
							masterOTDispatch({
								type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
									[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, resSelectPermission2.result.subDep, selectAll),
									// [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
								}
							})
						}
						// await APISelectFilterDashboardOTFac({
						// 	userId: payload.userId,
						// 	subDepNo: payload.subDepNo,
						// 	startDate: payload.startDate,
						// 	endDate: payload.endDate,
						// 	startTime: payload.startTime,
						// 	endTime: payload.endTime
						// }, res => {
						// 	console.log('PERMISSION - OTHER')
						// 	if (res) {
						// 		masterOTDispatch({
						// 			type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
						// 				[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`]: servicesOptions(MASTER_OPTION.FILTER_SUB_DEP_OT, res.resultsubDep, selectAll),
						// 				// [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
						// 			}
						// 		})
						// 	}
						// })
						await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
							if (res) {
								masterOTDispatch({
									type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
										[`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res.resultFlowHRM, selectAll)
									}
								})
							}
						})
					// await APISelectHRMFlow({ depNo: '', subDepNo: payload.subDepNo }, res => {
					//     if (res) {
					//         masterOTDispatch({
					//             type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
					//                 [`OPTION_${MASTER_OPTION.FILTER_HRM_FLOW_OT}`]: servicesOptions(MASTER_OPTION.FILTER_HRM_FLOW_OT, res)
					//             }
					//         })
					//     }
					// })
					// break;
				}
				break;
			default:
				break;
		}
	}


	async function onSearchMaster({ fieldname }) {
		switch (fieldname) {
			default:
				break;
		}

	}



	async function onChangeMaster({ fieldOption, checkedList, checkAll, plainOptions }) {
		switch (fieldOption) {
			default:
				await masterOTDispatch({
					type: MASTER_OT_ACTION.SET_MASTER_SUCCESS, state: {
						[`SEARCH_${fieldOption}`]: (checkAll) ? plainOptions : checkedList
					}
				})
				break;
		}

	}

	// async function defaultMaster({ fieldname, payload }) {
	//     switch (fieldname) {
	//         case MASTER_OPTION.SUB_DEPARTMENT_FACTORY:
	//             console.log('SUB_DEPARTMENT_FACTORY')
	//             // await APISelectSubDepartmentOT({subDepNo:''},res=>{
	//             //     if(res) {
	//             //         masterOTDispatch({
	//             //             type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state:{
	//             //                 [`OPTION_${fieldname}`] : servicesOptions(fieldname,res.result)
	//             //             }
	//             //         })
	//             //     }
	//             // })
	//             break;
	//         case MASTER_OPTION.HRM_FLOW_FACTORY:
	//             console.log('HRM_FLOW_FACTORY')
	//             // await APISelectHRMFlow({ depNo: '', subDepNo: '' }, res => {
	//             //     if (res) {
	//             //         masterOTDispatch({
	//             //             type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
	//             //                 [`OPTION_${fieldname}`]: servicesOptions(fieldname, res.result)
	//             //             }
	//             //         })
	//             //     }
	//             // })
	//             break;
	//         default:
	//             break;
	//     }
	// }

	async function changeSignature(list) {
		await masterOTDispatch({
			type: MASTER_OT_ACTION.SET_MASTER_OT_SUCCESS, state: {
				masterUser: {
					userLists: list,
				}
			}
		})
	}


	return (
		<MasterOTContext.Provider
			value={{
				onSearchMaster,
				onChangeMaster,
				masterOTState,
				getMaster,
				changeSignature,
				masterOTDispatch
			}}>
			{children}
		</MasterOTContext.Provider>
	)
}


export default ProviderMasterOT