import { CONFIG } from "../../../components/connectAPI";
import React from "react";
import { Col } from "antd";
import { useButton } from "../buttons/CustomButtonAntd";
import moment from "moment";

export const TextFileDownload = ({ fileText }) => (
  <Col span={14}>
    {useButton({
      call: "normal",
      fn: async () => {
        const response = await fetch(`${CONFIG.API_URL}${fileText}`);
        const { url } = response;
        const text = await response.text();
        const blob = new Blob([text], { type: "text/plain" });
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        let filename = '';
        if (url) {
          const parts = url.split("/");
          filename = parts[parts.length - 1];
        }
        a.download = filename ? `${moment().format('DD-MM-YYYY')}-${filename}` : "text_file.txt";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      width: "150px",
      isDisable: fileText == "",
      moreProps: { label: "ดาวน์โหลด Text File" },
    })}

    <div
      style={{
        color: "red",
        visibility: fileText == "" ? "visible" : "hidden",
      }}
    >
      กรุณาระบุ บริษัท, จากวันที่, ถึงวันที่ และ เลือก กรอกข้อมูลเลือกโรงพยาบาล
      เป็น "กรอกข้อมูลแล้ว" เพื่อดาวน์โหลด Text File
    </div>
  </Col>
);
