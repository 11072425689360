import React, { useState, useEffect } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { InputAnt } from "../inputs/InputAnt";
import { CustomizeRole, USE_CONNECT_MODE_AUTO, CenterAPI } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useColumnTable } from "../../constants/UseColumnTable";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";

export const FormSearchRoleUser = () => {
  const [changeSearchValue, setChangeSearchValue] = useState({});
  const [optionRole, setOptionRole] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [SelectRow, setSelectRow] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [form] = Form.useForm();
  const { userlogin, location, permission } = GET_UserLogin();
  // Variable

  // [START] useEffect
  useEffect(() => {
    Promise.all([GET_Roles_Pages()]);
  }, []);

  useEffect(() => {
    GET_Navigator_User();
  }, [changeSearchValue["userId"]]);

  useEffect(() => {
    GET_PermissionByRole();
  }, [changeSearchValue["roles"]]);
  // [END] useEffect

  // [START] Handle Form Antd
  const onFinish = (values) => {
    let SET_DATA = {
      userId: values.userId,
      roles: values.roles,
      permissions: SelectRow,
      userlogin,
    };
    useConfirm({ FnOK: () => POST_Add_Permission(SET_DATA) });
  };

  const onReset = () => {
    form.resetFields();
    setChangeSearchValue({});
    setSelectRow([]);
  };
  // [END] Handle Form Antd

  // [START] REST API
  const GET_Navigator_User = async () => {
    try {
      const userId = form.getFieldValue("userId");
      form.setFieldsValue({ roles: undefined });
      if (!userId) return setSelectRow([]);
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.POST_GetNavigator}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          location,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        const navigators = data.result;
        if (navigators.length === 0) {
          useAlert({
            type: "error",
            title: "ไม่พบข้อมูลจัดการสิทธิ์",
            content: `กรุณา เลือกสิทธิ์ ให้พนักงาน`,
            Fn: () => setSelectRow([]),
          });
        } else {
          const Pages = navigators.map((val) => val.pageNo);
          const prevRole = navigators[0].role;
          setSelectRow(Pages);
          form.setFieldsValue({ prevRole });
        }
      } else {
        setSelectRow([]);
      }
    } catch (err) {
      console.log("Error GET_Navigator_User: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const GET_PermissionByRole = async () => {
    try {
      const roles = form.getFieldValue("roles");
      if (!roles) return null;
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${CustomizeRole.POST_getPermissionByRole}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roles,
        }),
      });
      const data = await response.json();
      let Pages = [];
      if (response.ok) {
        const { navigators } = data.result;
        Pages = navigators.map((val) => val.pageNo);
      }
      setSelectRow(Pages);
    } catch (err) {
      console.log("Error GET_PermissionByRole: ", err);
      setSelectRow([]);
    } finally {
      setLoadingScreen(false);
    }
  };

  const GET_Roles_Pages = async () => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${CustomizeRole.GET_Default_Info}`;
      const response = await fetch(url);
      const data = await response.json();
      let Map_Navigators = [];
      let Map_roles = [];
      if (response.ok) {
        const { roles, navigators } = data.result;
        if (permission === "HRS-OFF") {
          Map_Navigators = navigators.map((val) => ({
            key: val.pageNo,
            ...val,
          }))
        } else {
          Map_Navigators = navigators.map((val) => ({
            key: val.pageNo,
            ...val,
          })).filter(element => {
            return element.pageNo !== "P1707" && // อัพเดทข้อมูลแก้ไข JG PG
              element.pageNo !== "P3200" && // การจัดการผลคะแนน
              element.pageNo !== "P3201" && // Import ผลการประเมิน (Grade)
              element.pageNo !== "P3202" && // 	ตรวจสอบยืนยันผลการประเมิน (Grade)
              element.pageNo !== "P3203" // Import ผลคะแนนการประเมินย้อนหลัง
          });
        }
        Map_roles = roles.map((val) => ({
          value: val,
          txt: val,
        }));
      }
      setDataTable(Map_Navigators);
      setOptionRole(Map_roles);
    } catch (err) {
      console.log("Error GET Default Role/Page: ", err);
      setOptionRole([]);
      setDataTable([]);
    } finally {
      setLoadingScreen(false);
    }
  };

  const POST_Add_Permission = async (values) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${CustomizeRole.POST_Add_Permission}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: "การเพิ่มสิทธิ์สำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          content: `การเพิ่มสิทธิ์ไม่สำเร็จ : ${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Erro Add Permission", err);
      setLoadingScreen(true);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let SET_undifined = {};
    let ArrayInput = Object.keys(form.getFieldsValue());
    switch (name) {
      case "companyCode":
      case "location":
        SET_undifined = toUndefined(ArrayInput.slice(2));
        break;
      case "userId":
        SET_undifined = toUndefined(ArrayInput.slice(3));
        break;
      case "roles":
        if (val === undefined) GET_Navigator_User();
        break;
      default:
        SET_undifined = {};
        break;
    }
    form.setFieldsValue(SET_undifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...SET_undifined,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { Column_CustomizeRole } = useColumnTable({});

  const onSelectChange = (selectedRowKeys) => {
    setSelectRow(selectedRowKeys);
  };
  // [END] Control Column

  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Card title="กำหนดสิทธิ์เข้าถึง" style={{ marginBottom: 30 }}>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={8} lg={5} xl={4}>
                <SelectAntd
                  name={`companyCode`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={useOptionSelect("company")}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={5} xl={4}>
                <SelectAntd
                  name={`location`}
                  label={`Location`}
                  smgReq={`กรุณาเลือก Location`}
                  placeholder={`เลือก Location`}
                  dropdown={optionSelectLocation}
                  FnOnChange={onChangeValue}
                  DefaultValue={location}
                  required={true}
                  isDisable={true}
                />
              </Col>
              <Col xs={24} sm={24} md={16} lg={11} xl={5}>
                <SelectAntd
                  name={`userId`}
                  label={`พนักงาน`}
                  smgReq={`กรุณาเลือก พนักงาน`}
                  placeholder={`เลือก พนักงาน`}
                  dropdown={useOptionSelect(
                    "empAll",
                    useFixedQueryString(changeSearchValue, [
                      "companyCode",
                      "location",
                    ])
                  )}
                  FnOnChange={onChangeValue}
                  required={true}
                  isDisable={changeSearchValue["companyCode"] ? false : true}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                <InputAnt
                  name={`prevRole`}
                  label={`สิทธิ์เดิม`}
                  smgReq={`สิทธิ์เดิม`}
                  placeholder={`รอประมวลผล ...`}
                  FnOnChange={onChangeValue}
                  required={false}
                  isDisable={true}
                />
              </Col>
              <Col xs={24} sm={17} md={12} lg={4} xl={4}>
                <SelectAntd
                  name={`roles`}
                  label={`เลือกสิทธิ์`}
                  smgReq={`กรุณาเลือก สิทธิ์`}
                  placeholder={`เลือก สิทธิ์`}
                  dropdown={optionRole}
                  FnOnChange={onChangeValue}
                  required={false}
                  isDisable={changeSearchValue["userId"] ? false : true}
                />
              </Col>
              <Col xs={24} sm={7} md={12} lg={4} xl={3}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: () => GET_Navigator_User(),
                    moreProps: {
                      label: "เรียกข้อมูลเดิม",
                      type: "primary",
                    },
                    isDisable: changeSearchValue["userId"] ? false : true,
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {dataTable.length !== 0 && (
            <>
              <CustomTableAtnd
                HeadTable={Column_CustomizeRole}
                SetScroll={{ x: "80%", y: 500 }}
                DataTable={dataTable}
                EnableRowSelect={{
                  onChange: (val) => onSelectChange(val),
                  selectedRowKeys: SelectRow,
                }}
              />
              <Row gutter={[16, 16]} justify="center" align="bottom">
                <Col xs={24} sm={12} md={12} lg={6} xl={4}>
                  <Form.Item>{useButton({ call: "save" })}</Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6} xl={4}>
                  <Form.Item>
                    {useButton({
                      call: "normal",
                      fn: onReset,
                      moreProps: { label: "ล้างข้อมูล" },
                    })}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </>
  );
};

export default FormSearchRoleUser;
