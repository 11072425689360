import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from 'react-icons/fi';
import { Col, Row, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import * as NumericInput from "react-numeric-input";
import { Tabs } from "antd";
import encodeString from '../extendFunction/encodeString';
import {
    GET,
    SELECT_DATA_PE_HISTORY,
    SELECT_DATA_PE
} from "../service";
const { TabPane } = Tabs;

var _year = new Date().getFullYear()

class PrintPE_SUP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
            value: "",
            arrScore1: [],
            arrNum1: [],
            scoreP1: '',
            arrScore2: [],
            arrNum2: [],
            scoreP2: '',
            totalScore: '',
            loadData: [],
            dataHistory: [],
            _DocPEF: encodeString.decode(this.props.params.PDocPEF_SUP),
            groupEdit: this.props.params.Type,
            status_confirm: false,
        };
    }

    componentDidMount() {
        this.selectData();
    }

    async selectData() {
        try {
            const docNo = this.state._DocPEF;
            const response = await GET(SELECT_DATA_PE(docNo));
            const { result } = response;
            let { data } = result;
            const status_con = data[0].status_confirm;
            const staffCode = data[0].staffCode;
            const years = docNo.substring(3, 7);
            let chk;
            if (status_con === 1) {
                chk = true;
            } else {
                chk = false;
            }
            //  console.log(data)
            const arrScore1 = (result.arrScore1).map(Number);
            const arrScore2 = result.arrScore2.map(Number)
            this.setState({
                loadData: data,
                status_confirm: chk,
                arrScore1,
                arrScore2,
            },
                () => {
                    this.selectDataHistory(years, staffCode);
                }
            );
        } catch (err) {
            console.log("Error Search select Data: ", err);
            this.setState({ loading: false });
        }
    }

    async selectDataHistory(years, staffCode) {
        try {
            const queryObject = {
                years,
                staffCode,
            };
            const response = await GET(SELECT_DATA_PE_HISTORY(queryObject));
            const { result } = response;
            this.setState({
                dataHistory: result !== 0 ? result : [],
            });
        } catch (err) {
            console.log("Error Search select DataHistory: ", err);
            this.setState({ loading: false });
        }
    }

    render() {
        let data = this.state.loadData;
        let dataHistory = this.state.dataHistory || [];
        let notFound = ''
        if (dataHistory.length === 0) {
            notFound = <p > <br /> ***ไม่พบข้อมูลผลการประเมินย้อนหลัง*** </p>
        }

        return (

            <div>

                <Card>
                    <CardHeader><FiEdit3 className="margin-icon" /> แบบฟอร์มบริหารผลงาน ระดับหัวหน้างาน (Supervisor)</CardHeader>  </Card>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="แบบกรอกฟอร์มประเมิน" key="1">
                        <h3>กรอกแบบฟอร์มการประเมินประจำปี</h3>
                        {data.map((val, index) => {
                            return (
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                                                <Input type="text" name="StaffName_dis" id="StaffName_dis" value={val.staffName} disabled />
                                                <Input type="hidden" name="StaffName" id="StaffName" value={val.staffName} />
                                                <Input type="hidden" name="StaffCode" id="StaffCode" value={val.staffCode} />
                                                <Input type="hidden" name="DepNo" id="DepNo" value={val.DepNo} />
                                                <Input type="hidden" name="ID_Flow" id="ID_Flow" value={val.ID_Flow} />
                                                <Input type="hidden" name="DocNoSAF" id="DocNoSAF" value={val.Ref_DocSAF} />
                                                <Input type="hidden" name="DocNoPEF" id="DocNoPEF" value={this.state._DocPEF} />
                                                <Input type="hidden" name="groupEdit" id="groupEdit" value={this.state.groupEdit} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="company">ตำแหน่งงาน</Label>
                                                <Input type="text" name="Level_WorkerDes" id="Level_WorkerDes" value={val.Level_WorkerDes} disabled />
                                                <Input type="hidden" name="Level_Worker" id="Level_Worker" value={val.Level_Worker} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <h4>ส่วนที่ 1 การประเมินปัจจัยด้านผลงาน</h4>
                                    <Row className="table-custom table-head3">
                                        <Col md={2} className="col-style"><b>1-2</b></Col>
                                        <Col md={2} className="col-style"><b>3-4</b></Col>
                                        <Col md={2} className="col-style"><b>5-6</b></Col>
                                        <Col md={2} className="col-style"><b>7-8</b></Col>
                                        <Col md={2} className="col-style"><b>9-10</b></Col>
                                        <Col md={2} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>
                                    <Row className="table-custom3"><b>&nbsp; &nbsp;1.1 ผลงาน (น้ำหนักคะแนน 45 :: ปริมาณงาน 30 / ระยะเวลา  15)<span className="red"> * </span></b></Row>

                                    <Row className="table-custom2">
                                        <Col md={2} className="col-style2"><b>ปริมาณงาน :</b> ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่ หรือตามที่ได้รับมอบหมายในปริมาณน้อยกว่าที่กำหนดไว้อย่างมาก</Col>
                                        <Col md={2} className="col-style2">ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่ หรือตามที่ได้รับมอบหมายในปริมาณน้อยกว่าที่กำหนดไว้</Col>
                                        <Col md={2} className="col-style2">ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่ หรือตามที่ได้รับมอบหมายได้ตามปริมาณที่กำหนดไว้</Col>
                                        <Col md={2} className="col-style2">ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่ หรือตามที่ได้รับมอบหมายในปริมาณค่อนข้างสูงกว่าที่กำหนดไว้</Col>
                                        <Col md={2} className="col-style2">ปฏิบัติงานให้เกิดผลสำเร็จตามหน้าที่ หรือตามที่ได้รับมอบหมาย ในปริมาณสูงกว่าที่กำหนดไว้มาก</Col>
                                        <Col md={2} className="col-style2">
                                            <NumericInput className="form-control" name="score1_1_1" id="score1_1_1" value={val.score1_1_1} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={2} className="col-style2"><b>ระยะเวลา :</b> ใช้ระยะเวลาในการปฏิบัติงานล่าช้ากว่าที่กำหนดอย่างมาก</Col>
                                        <Col md={2} className="col-style2">ใช้ระยะเวลาในการปฏิบัติงานล่าช้ากว่าที่กำหนดค่อนข้างมาก</Col>
                                        <Col md={2} className="col-style2">ใช้ระยะเวลาในการปฏิบัติงานเป็นไปตามที่กำหนด</Col>
                                        <Col md={2} className="col-style2">ใช้ระยะเวลาในการปฏิบัติงานรวดเร็วกว่าที่กำหนดค่อนข้างมาก</Col>
                                        <Col md={2} className="col-style2">ใช้ระยะเวลาในการปฏิบัติงานรวดเร็วกว่าที่กำหนดมาก</Col>
                                        <Col md={2} className="col-style2">
                                            <NumericInput className="form-control" name="score1_1_2" id="score1_1_2" value={val.score1_1_2} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="table-custom3"><b>&nbsp; &nbsp;1.2 มาตรฐาน (น้ำหนักคะแนน 15)<span className="red"> * </span></b></Row>
                                    <Row className="table-custom2">
                                        <Col md={2} className="col-style2">
                                            - ผลงานไม่มีคุณภาพ มีข้อผิดพลาด และขาดความครบถ้วนอยู่เสมอ มักเป็นงานที่ไม่มีความเรียบร้อย <br />
                                            - ต้องแก้ไขงาน หรือทำใหม่อยู่เสมอ
                                        </Col>
                                        <Col md={2} className="col-style2">
                                            - ผลงานไม่มีคุณภาพมากนัก มักมีข้อผิดพลาดและ/หรือขาดความครบถ้วน เรียบร้อย<br />
                                            - ต้องแก้ไขปรับปรุงผลงานบ่อยครั้ง
                                        </Col>
                                        <Col md={2} className="col-style2">
                                            - ผลงานมีคุณภาพ มีความถูกต้อง ครบถ้วน และเรียบร้อยพอสมควร หรืออยู่ในระดับที่ยอมรับได้<br />
                                            - อาจมีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงอยู่บ้าง แต่ไม่ก่อให้เกิดความเสียหาย
                                        </Col>
                                        <Col md={2} className="col-style2">
                                            - ผลงานมีคุณภาพดี มีความถูกต้อง ครบถ้วน และเรียบร้อยค่อนข้างสูง<br />
                                            - มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงน้อยมา และเกิดขึ้นน้อยครั้ง
                                        </Col>
                                        <Col md={2} className="col-style2">
                                            - ผลงานมีคุณภาพดีมาก มีความถูกต้อง ครบถ้วน เรียบร้อย และสมบูรณ์<br />
                                            - ไม่มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุง สามารถนำไปใช้เป็นผลงานตัวอย่างได้
                                        </Col>
                                        <Col md={2} className="col-style2">
                                            <NumericInput className="form-control" name="score1_2_1" id="score1_2_1" value={val.score1_2_1} readOnly />
                                        </Col>
                                    </Row>
                                    {/* <Row className="table-custom2 margin-bottom20">
                                        <Col md={2} className="col-style2">ต้องแก้ไขงาน หรือทำใหม่อยู่เสมอ</Col>
                                        <Col md={2} className="col-style2">ต้องแก้ไขปรับปรุงผลงานบ่อยครั้ง</Col>
                                        <Col md={2} className="col-style2">อาจมีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงอยู่บ้าง แต่ไม่ก่อให้เกิดความเสียหาย</Col>
                                        <Col md={2} className="col-style2">มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุงน้อยมา และเกิดขึ้นน้อยครั้ง</Col>
                                        <Col md={2} className="col-style2">ไม่มีข้อผิดพลาดหรือจุดที่ต้องปรับปรุง สามารถนำไปใช้เป็นผลงานตัวอย่างได้</Col>
                                        <Col md={2} className="col-style2">
                                            <NumericInput className="form-control" name="score1_2_2" id="score1_2_2" value={val.score1_2_2} readOnly />
                                        </Col>
                                    </Row> */}


                                    <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Core Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.1 Teamwork : การทำงานเป็นทีม (น้ำหนักคะแนน 5) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>   ความร่วมแรงร่วมใจในการผลักดันพันธกิจของหน่วยงาน/องค์กร ให้ไปในทิศทางเดียวกัน มีทัศนคติที่เปิดกว้างรับฟังความคิดเห็นและเคารพในความแตกต่างของผู้อื่น ตลอดจนมีน้ำใจช่วยเหลือผู้อื่น โดยเห็นแก่ความสำเร็จขององค์กรในภาพรวม
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            - มีทัศนคติเชิงบวก ยืดหยุ่นต่อแนวทางและความคิดใหม่ๆ<br></br>
                                            - รับฟังคำแนะนำ การวิพากษ์ วิจารณ์ที่สร้างสรรค์และปรับพฤติกรรมของตนได้โดยคำนึงถึงเป้าหมายของทีม<br></br>
                                            - มีส่วนร่วมในการกำหนดภารกิจและเป้าหมายของทีม<br></br>
                                            - นำเสนอแนวทางเลือกในการแก้ไขปัญหาที่เกิดขึ้นของสมาชิกในทีม<br></br>
                                            - เต็มใจที่จะแบ่งเบาภาระงานกับผู้อื่น
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - พยายามแสวงหาฉันทามติของทีมเมื่อเกิดความเห็นต่างของสมาชิก<br></br>
                                            - กล้าที่จะวิพากษ์วิจารณ์ความคิดเห็น ทัศนะของผู้อื่นอย่างสร้างสรรค์บนพื้นฐานของการให้เกียรติซึ่งกันและกัน<br></br>
                                            - กระตุ้นให้สมาชิกในทีมแลกเปลี่ยนความคิดเห็นซึ่งกันและกัน<br></br>
                                            - แสดงออกถึงความมุ่งมั่นที่จะรักษาพันธะสัญญา กติกาของทีม<br></br>
                                            - แสวงหาหนทางบรรลุข้อตกลงทั้งอย่างเป็นทางการและไม่เป็นทางการเพื่อสนับสนุนผลประโยชน์ของทีม
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - กำหนดบทบาทและหน้าที่ของสมาชิกในทีม<br></br>
                                            - ติดตาม ประเมินผลการทำงานของสมาชิกในทีมอย่างต่อเนื่อง<br></br>
                                            - ตระหนักถึงความขัดแย้งภายในทีมและเปิดโอกาสให้มีการพูดคุย เจรจาเพื่อแก้ไขข้อขัดแย้ง<br></br>
                                            - เป็นตัวกลางประสานเชื่อม ไกล่เกลี่ยความขัดแย้งระหว่างสมาชิก<br></br>
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_1" id="score2_1" value={val.score2_1} readOnly />

                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.2 Customer Focus : ลูกค้าสำคัญ (น้ำหนักคะแนน 5)<span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>  ความตระหนักถึงความต้องการคาดหวังตลอดจนปัญหาของลูกค้าภายใน/ภายนอก พยายามแสวงหาแนวทางวิธีการในการส่งมอบผลิตภัณฑ์ บริการที่มีคุณภาพเพื่อสนองตอบความต้องการและสร้างความพึงพอใจของลูกค้าอย่างต่อเนื่องกระตือรือร้น
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            - สร้าง/พัฒนา การสื่อสาร คู่มือ กระบวนการ วิธีการทำงาน ระบบข้อมูลที่จำเป็นสำหรับลูกค้า<br></br>
                                            - กำหนดมาตรฐาน หลักประกันว่าสินค้า/บริการจะสามารถตอบสนองความต้องการของลูกค้าได้<br></br>
                                            - ปรับปรุงกระบวนการให้มีประสิทธิภาพสามารถตอบสนองความต้องการของลูกค้าได้อย่างรวดเร็ว ทันกาล<br></br>
                                            - เสนอแนะวิธีการที่จะปรับปรุงคุณภาพผลิตภัณฑ์การบริการเพื่อสร้างความพึงพอใจของลูกค้า
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ใช้แนวทางเชิงรุกเพื่อสร้างความพึงพอใจให้แก่ลูกค้าอย่างมีประสิทธิผล<br></br>
                                            - สร้าง/พัฒนาแบบหรือวิธีการสำรวจความพึงพอใจของลูกค้า วิเคราะห์ผลและมีการปรับปรุงตามความจำเป็นเร่งด่วน<br></br>
                                            - พัฒนาเทคนิคใหม่ๆ ระดมความเห็นจากสมาชิกในทีมเพื่อระบุจุดอ่อนเพื่อการปรับปรุงผลิตภัณฑ์ บริการ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - มีทัศนคติต่อลูกค้าเสมือนเป็นหุ้นส่วน พันธมิตร<br></br>
                                            - คาดการณ์ได้ถึงความต้องการ ความคาดหวังของลูกค้าในอนาคตได้<br></br>
                                            - ร่วมมือกับลูกค้าในการสอบถามความต้องการ ให้การช่วยเหลือ แก้ปัญหา สนองความคาดหวัง  <br></br>
                                            - กระตุ้นจูงใจสมาชิกในทีมให้มีจิตสำนึกในการให้บริการลูกค้า  <br></br>
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_2" id="score2_2" value={val.score2_2} readOnly />

                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.3 Excellence / Go Beyond : ความเป็นเลิศ / ความก้าวหน้า (น้ำหนักคะแนน 5) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>  การปฏิบัติงานอย่างมีวิสัยทัศน์ ประสิทธิภาพใช้ทรัพยากรที่มีอยู่อย่างชาญฉลาด คุ้มค่า ส่งมอบผลงานที่มีคุณภาพอย่างเหนือความคาดหมาย
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">

                                        <Col md={3} className="col-style2">
                                            - กำหนดขั้นตอนและวินัยในการทำงานของตนเองอย่างเป็นระบบและถือปฏิบัติอย่างสม่ำเสมอ<br></br>
                                            - สำนึกถึงความจำเป็นเร่งด่วน และจัดลำดับงานที่ต้องปฏิบัติก่อนหลังได้อย่างเหมาะสม<br></br>
                                            - เข้าใจและอธิบายต่อผู้อื่นได้ถึงปัจจัยแห่งความสำเร็จของงานแต่ละอย่างที่ตนรับผิดชอบอยู่
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ได้รับความไว้วางใจสูงจากหัวหน้าว่าจะส่งมอบผลงานที่มีคุณภาพอย่างสม่ำเสมอ ทันตามกำหนด<br></br>
                                            - มุ่งมั่น ติดตามไม่ลดละเพื่อการบรรลุเป้าหมายทั้งทีพบความยุ่งยาก อุปสรรคและปัญหา<br></br>
                                            - มุ่งเน้นที่ผลลัพธ์ที่พึงประสงค์ด้วยการปฏิบัติตามกระบวนการ วิธีการที่มีประสิทธิภาพ ถูกต้อง มีเหตุผล
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - สามารถรักษาความมุ่งมั่นที่จะบรรลุเป้าหมายแม้เผชิญหน้ากับอุปสรรค ท้าทาย<br></br>
                                            - ตั้งเป้าหมายที่ท้าทาย  เมื่อบรรลุแล้วเพิ่มระดับความท้าทายในเป้าหมายครั้งต่อไปให้สูงขึ้น<br></br>
                                            - ประมวลผลความผิดพลาดในอดีตแล้วนำเอาข้อสรุปที่ได้มาแบ่งปันกับสมาชิกในทีมเพื่อการเรียนรู้ ปรับปรุง
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_3" id="score2_3" value={val.score2_3} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.4 Learning and Development : การเรียนรู้และพัฒนา (น้ำหนักคะแนน 5) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>  กระตือรือร้นที่จะเรียนรู้สิ่งใหม่ๆเพื่อเพิ่มพูนควาสามารถและทักษะในการปฏิบัติงานของตนเองอยู่เสมอมีการถ่ายทอดแบ่งปันความรู้วิธีปฏิบัติงานใหม่ๆเพื่อให้สามารถตอบสนองต่อการเปลี่ยนแปลงของสภาพแวดล้อมแก่สมาชิกในทีม
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            - แสดงออกถึงการมีทัศนคติที่เปิดกว้างและรับฟังอย่างเต็มใจต่อความคิดเห็นที่แตกต่าง<br></br>
                                            - เรียนรู้จากความผิดพลาดของตนหรือผู้อื่นแล้วนำมาเป็นแนวทางการปรับปรุงแก้ไขให้ดีขึ้น<br></br>
                                            - ประยุกต์ขั้นตอนและวิธีการทำงานใหม่ๆได้ด้วยตนเอง
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - สนใจและติดตามกิจกรรมเรียนรู้เพื่อที่จะพัฒนาเสริมสร้างตนเอง<br></br>
                                            - จัดสรรเวลาในการเรียนรู้งานอื่นๆนอกเหนือจากงานที่ตนรับผิดชอบอยู่<br></br>
                                            - ประยุกต์วิธีการ  การใช้สารสนเทศ เทคนิคใหม่ๆมาใช้ในการปรับปรุงการปฏิบัติงาน
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - แสดงออกเชิงรุกในการแสวงหามุมมอง วิธีการ แนวทางใหม่ๆเพื่อปรับปรุงการปฏิบัติงานทั้งระบบ<br></br>
                                            - กำหนด หัวข้อ เนื้อหาองค์ความรู้หลักในทุกกระบวนการของหน่วยงานของตนได้<br></br>
                                            - ถ่ายทอดประสบการณ์หรือสิ่งที่เรียนรู้มาให้ผู้อื่นรับรู้และสามารถนำไปประยุกต์ใช้ในงานได้
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_4" id="score2_4" value={val.score2_4} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.5 Integrity : ความมีคุณธรรม</b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> มีความเห็นใจและเข้าใจผู้อื่น ประพฤติตนและเป็นแบบอย่างในด้านความซื่อสัตย์ ยุติธรรม และมีจริยธรรม แสดงออกถึงความสม่ำเสมอทั้งคำพูดและการกระทำ
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={6} className="col-style">
                                            <FormGroup>
                                                <label> ตัวอย่างหรือตัวชี้บ่งพฤติกรรม </label>
                                                <Input type="textarea" name="ans2_5_example" id="ans2_5_example" value={val.ans2_5_example} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="col-style">
                                            <FormGroup>
                                                <label>ข้อเสนอแนะเพื่อการพัฒนา </label>
                                                <Input type="textarea" name="ans2_5_suggestion" id="ans2_5_suggestion" value={val.ans2_5_suggestion} readOnly />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Functional Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.6 Job Knowledge : ความรู้ในงาน (น้ำหนักคะแนน 4)  <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>   เป็นผู้มีวิสัยทัศน์ มองการณ์ไกล เข้าใจภาพรวม ประพฤติตนเป็นแบบอย่างที่ดีตามค่านิยมขององค์กรมอบหมายงานและสร้างแรงจูงใจเพื่อผลักดันให้ไปสู่เป้าหมาย ตลอดจนประเมินผลลัพธ์อย่างเที่ยงตรง
                                            สือสารอย่างมีประสิทธิภาพ ได้รับการยอมรับจากสมาชิกในหน่วยงาน
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">

                                        <Col md={3} className="col-style2">
                                            - ผลงานทีส่งมอบมีความเที่ยงตรง ถูกต้อง สม่ำเสมอ เสร็จตามกำหนด<br></br>
                                            - ข้อผิดพลาดมีน้อยหรือถูกค้นพบแก้ไขก่อนด้วยตนเองก่อนส่งมอบผลงาน<br></br>
                                            - ต้องการคำแนะนำจากหัวหน้างาน ในกรณีทีต้องปฏิบัติงานใหม่ๆที่ไม่เคยทำมาก่อน<br></br>
                                            - ประยุกต์ใช้ความรู้ในการแก้ปัญหาที่ไม่ยุ่งยากซับซ้อนมากนักอย่างมีประสิทธิผล
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ปฏิบัติงานในสถานการณ์ไม่ปกติ ยุ่งยากซับซ้อนโดยไม่ต้องพึ่งคำแนะนำและยังส่งมอบผลงานที่ดีตามมาตรฐานที่กำหนดได้<br></br>
                                            - เข้าใจและสามารถอธิบายที่มา เหตุผล ความจำเป็น ความสัมพันธ์เชื่อมโยงของกระบวนการทั้งหมดในหน่วยงานได้<br></br>
                                            - กระตือรือร้นที่จะถ่ายทอดแบ่งปันความรู้ เทคนิค วิธีการที่มีประสิทธิภาพของตนกับผู้อื่นอยู่เสมอ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - สามารถแนะนำ ให้คำปรึกษาด้านความรู้ เทคนิคที่ซับซ้อนแก่ผู้อื่นได้อย่างกระจ่างชัด  <br></br>
                                            - ออกแบบกระบวนการ  แนวคิด มุมมอง วิธีปฏิบัติงานใหม่ๆเพื่อยกระดับผลงานอย่างมีนัยสำคัญ<br></br>
                                            - สามารถปฏิบัติงาน โครงการใหม่ๆ ที่ต้องแสวงหาความรู้ พัฒนาทักษะเพิ่มเติมจากที่มีอยู่แล้ว<br></br>
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_6" id="score2_6" value={val.score2_6} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.7 Problem Solving : การแก้ไขปัญหา (น้ำหนักคะแนน 4) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>   ความเข้าใจในบุคลิก คุณลักษณะของสมาชิกในทีม ส่งเสริมความสัมพันธ์ในทีม/หน่วยงานสามารถปรับพฤติกรรมของสมาชิกในทีมให้ไปในทิศทางที่พึงประสงค์ได้ มีทักษะในการ ถ่ายทอด สอนงาน ชี้แนะ โน้มน้าวจูงใจ พัฒนาผู้ใต้บังคับบัญชา
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">

                                        <Col md={3} className="col-style2">
                                            - ใช้การวิเคราะห์เชิงเหตุผล  ตรรกะ บนฐานข้อมูล ข้อเท็จจริงไม่ด่วนสรุป คาดเดาบนฐานของอคติ ความเชื่อส่วนตัว<br></br>
                                            - สามารถสืบค้น แสวงหา รวบรวม ข้อมูล ข้อเท็จจริงที่ต้องใช้ประกอบการตัดสินใจได้อย่างครบถ้วน ตรงประเด็น<br></br>
                                            - ไม่เฉพาะแต่สามารถระบุถึงปัญหาเท่านั้น หากยังสามารถวิเคราะห์สาเหตุปัญหาที่ไม่ซับซ้อนมากและเสนอแนวทางแก้ไขได้
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - รู้จัก นำเครื่องมือ วิธีการ ข้อมูล สถิติ รายงานมาใช้ในการระบุ วิเคราะห์ ชี้บ่งประเด็นปัญหาอย่างน่าเชื่อถือ<br></br>
                                            - ชี้บ่งทางเลือกหรือวิธีการในการแก้ปัญหามากกว่าหนึ่งแนวทางและสามารถระบุจุดอ่อน จุดแข็งของทางเลือกเหล่านั้นได้<br></br>
                                            - ติดตามผลของมาตรการแก้ปัญหาเพื่อให้แน่ใจว่าการแก้ปัญหาบรรลุผลแล้ว<br></br>
                                            - กำหนดมาตรการ ขั้นตอนป้องกันล่วงหน้าเพื่อหลีกเลี่ยงการเกิดซ้ำของปัญหาเดิม
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - แยกแยะปัญหาที่ซับซ้อนออกเป็นประเด็นย่อยที่ต้องจัดการ กำหนดลำดับ วิธีการ ขั้นตอนแก้ไขในแต่ละประเด็นอย่างเป็นระบบ<br></br>
                                            - กำหนดแนวทางแก้ปัญหาหลายทางเลือกสำหรับสถานการณ์ต่างๆ คาดการณ์อุปสรรคล่วงหน้าและเตรียมแผนรับมือ<br></br>
                                            - ประเมินผลกระทบแนวทางการแก้ปัญหาที่ได้ดำเนินการไปแล้วเพื่อทบทวนความคุ้มค่า ประสิทธิผล ตลอดจนปรับปรุงให้ดีขึ้น<br></br>
                                            - มีการระดมสมองในทีม เพื่อแบ่งปัน เรียนรู้ ปัญหาอุปสรรคที่เกิดขึ้น เปิดโอกาสให้สมาชิกทุกคนมีส่วนร่วมในการคิดหาวิธีมาตรการป้องกัน แก้ไขในอนาคต
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_7" id="score2_7" value={val.score2_7} readOnly />

                                        </Col>
                                    </Row>

                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Managerial Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.8 Leadership : ภาวะผู้นำ (น้ำหนักคะแนน 4) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>เป็นผู้มีวิสัยทัศน์ มองการณ์ไกล เข้าใจภาพรวม ประพฤติตนเป็นแบบอย่างที่ดีตามค่านิยมขององค์กร มอบหมายงานและสร้างแรงจูงใจเพื่อผลักดันให้ไปสู่เป้าหมาย
                                            ตลอดจนประเมินผลลัพธ์อย่างเที่ยงตรง สือสารอย่างมีประสิทธิภาพ ได้รับการยอมรับจากสมาชิกในหน่วยงาน
                                        </Col>
                                    </Row>

                                    <Row className="table-custom2">

                                        <Col md={3} className="col-style2">
                                            - สื่อสารให้สมาชิกในทีมเข้าใจถึงเป้าหมายและวัตถุประสงค์การทำงานในหน้าที่บทบาทของตน<br></br>
                                            - กระตุ้นให้ทีมงานนำเสนอความคิดเห็นใหม่ๆ<br></br>
                                            - ยอมรับความคิดเห็นต่างๆของสมาชิกในทีม

                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - กำหนดกิจกรรมเพื่อเสริมสร้างลูกทีมที่มีประสบการณ์น้อยเพื่อยกระดับความรู้ ทักษะในงาน<br></br>
                                            - แสดงบทบาทที่ปรึกษา ผู้สนับสนุน ในการเสริมสร้างบุคลากร  ทีม และหน่วยงาน<br></br>
                                            - แสดงความรับผิดชอบต่อผลงานของทีมไม่ว่าจะสำเร็จหรือล้มเหลว
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - มีความสามารถในการปรับตัวและความยืดหยุ่นต่อการเปลี่ยนแปลงภายนอก/ภายใน<br></br>
                                            - เข้าใจสามารถบริหารจัดการด้านทรัพยากรบุคคล งบประมาณ การเงิน อย่างมีประสิทธิผล<br></br>
                                            - สร้างสรรค์สภาพแวดล้อมที่เอื้อต่อการทำงาน ตลอดจนแนวปฏิบัติในการมอบหมายงาน ประเมินผล ให้รางวัลและลงโทษอย่างชัดเจนมีประสิทธิผล
                                        </Col>

                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_8" id="score2_8" value={val.score2_8} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.9 People Management : การบริหารจัดการคน (น้ำหนักคะแนน 4) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> ความเข้าใจในบุคลิก คุณลักษณะของสมาชิกในทีม ส่งเสริมความสัมพันธ์ในทีม/หน่วยงาน สามารถปรับพฤติกรรมของสมาชิกในทีมให้ไปในทิศทางที่พึงประสงค์ได้มีทักษะในการ ถ่ายทอด สอนงาน ชี้แนะ โน้มน้าวจูงใจ พัฒนาผู้ใต้บังคับบัญชา
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            -  สร้างบรรยากาศแห่งความไว้วางใจ ช่วยเหลือ ไม่จ้องจับผิดหรือเอาแต่หาข้อตำหนิ<br></br>
                                            - สื่อสารให้ลูกทีมเข้าใจและถือปฏิบัติตามนโยบาย กระบวนการ การทำงานของหน่วยงาน/องค์กร<br></br>
                                            - ถ่ายทอด สอนงาน ชี้แนะปัจจัยความสำเร็จ ผลลัพธ์ที่คาดหวังแก่สมาชิกในทีมอย่างมีประสิทธิภาพ<br></br>
                                            - ใส่ใจกับการบริหารผลงานของลูกน้อง ประเมินและให้คำชี้แนะทั้งเป็นทางการและไม่เป็นทางการเป็นระยะ<br></br>
                                            - ให้กำลังใจและแสดงความชื่นชมต่อพนักงานที่ประพฤติตนดีและสร้างคุณประโยชน์แก่หน่วยงาน/องค์กร
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - เข้าใจถึงลำดับความสำคัญของวัตถุประสงค์ขององค์กร อธิบายถึงผลกระทบที่มีต่อภารกิจในขอบเขตของสมาชิกแต่ละคนได้เป็นที่กระจ่าง<br></br>
                                            - ทำให้สมาชิกในทีมเข้าใจถึงเป้าหมาย แนวปฏิบัติ การวัดผล โดยใช้การจัดการข้อมูลเพื่อสะท้อนผลงานและเพื่อการติดตามเพื่อการปรับปรุงอย่างทันท่วงที<br></br>
                                            - มีทักษะในการสะท้อนผลการปฏิบัติงาน (feedback) อย่างสร้างสรรค์ ชัดเจน เจาะจง เป็นที่ยอมรับ<br></br>
                                            - มีส่วนร่วมและสนับสนุนการระดมสมองและความคิดของสมาชิกทีม
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - มอบอำนาจและความมีอิสระในการตัดสินใจ ปฏิบัติงานอย่างเหมาะสมแก่ลูกทีมแต่ละคน<br></br>
                                            - สนับสนุนลูกทีมให้มีการอภิปราย พูดคุยกันได้ถึงประเด็นปัญหาที่มีความยุ่งยาก ซับซ้อน อ่อนไหว ได้อย่างเปิดเผย เพื่อหามาตรการปฏิบัติที่เหมาะสม เป็นไปได้<br></br>
                                            - สนับสนุนการพัฒนาศักยภาพของลูกทีมเพื่อให้ปฏิบัติภารกิจปัจจุบันได้อย่างมีประสิทธิภาพตลอดจนเตรียมความพร้อมเพื่อการเติบโตในสายอาชีพสำหรับอนาคตด้วย
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_9" id="score2_9" value={val.score2_9} readOnly />

                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.10 Planning and Organizing : การวางแผนและการจัดองค์กร (น้ำหนักคะแนน 4) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> กำหนดแผนงานโดยตระหนักถึงปัจจัยที่เกี่ยวข้องต่างๆเช่น สภาพแวดล้อม ทรัพยากรที่จำเป็น กำลังคน กระบวนการ ขั้นตอน ปัญหา อุปสรรค
                                            ความเสี่ยง ลำดับความสำคัญเร่งด่วน ตั้งเป้าหมายที่ท้าทายและเป็นไปได้  ดำเนินการตามแผนโดยกำกับ ติดตามและตอบสนองตามความจำเป็นเพื่อให้บรรลุตามที่กำหนดไว้
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            - ช่วยกำหนดแผนการ ขั้นตอนการปฏิบัติการได้จากข้อมูลที่มีอยู่<br></br>
                                            - เจรจาต่อรอง ปรึกษากับหัวหน้า เพื่อปรับกำหนดการ ขอบเขตงาน/กิจกรรมให้เหมาะสม รวมทั้งจัดลำดับความสำคัญให้สอดคล้องกับสภาวะการณ์ที่เกิดขึ้น<br></br>
                                            - ประเมิน ทบทวนผลงานของตนเองเป็นระยะและปรับปรุงวิธีการทำงานอยู่เสมอ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - จัดทำกระบวนการ ขั้นตอนการปฏิบัติงานในหน่วยงานได้อย่างละเอียด สมบูรณ์ ครบถ้วน<br></br>
                                            - ประสานงานกับผู้เกี่ยวข้องเมื่อพบปัญหา/ข้อขัดแย้งซึ่งอาจส่งผลกระทบต่องานอย่างทันท่วงที<br></br>
                                            - สามารถปรับเปลี่ยนรายละเอียด ขั้นตอนตามแผนให้เหมาะสมแก่สถานการณ์ทีไม่คาดฝันได้<br></br>
                                            - มีการรายงานสถานะความก้าวหน้าของการดำเนินงานตลอดจนแจ้งให้ผู้ที่เกี่ยวข้องทราบ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ผลักดันให้สมาชิกในทีมงานมีส่วนร่วมในการกำหนดแผนการทำงานของทีมงาน<br></br>
                                            - มีการกำหนด ติดตามการดำเนินงานของสมาชิกในทีมในช่วงเวลาต่างๆ<br></br>
                                            - รับมือกับสภาวะความกดดัน เร่งรีบ ข้อเรียกร้องต่างๆได้ในระหว่างดำเนินการตามแผนและยังสามารถรักษาความก้าวหน้าของงานตามที่กำหนดได้<br></br>
                                            - สามารถสานประโยชน์กับผู้อื่นเพื่อตอบสนองกับข้อเรียกร้องที่หลากหลายเพื่อให้สามารถแน่ใจได้ว่างานจะเสร็จสมบูรณ์ภายในกำหนด
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_10" id="score2_10" value={val.score2_10} readOnly />
                                        </Col>
                                    </Row>
                                    <br />
                                    <h4>
                                        ส่วนที่ 3 การเปลี่ยนแปลง
                                        Promote/โยกย้าย/ปรับตำแหน่ง/Execute D (ถ้าเสนอ โปรดระบุ
                                        เพื่อ Discuss Management){" "}
                                    </h4>
                                    <Row className="table-custom2">
                                        <Col md={6} className="col-style2">
                                            <FormGroup>
                                                <Label>รายละเอียด</Label>
                                                <Input
                                                    type="textarea"
                                                    name="remarkDetail"
                                                    id="remarkDetail"
                                                    onChange={this.changeValue}
                                                    value={val.remarkDetail}
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="col-style2">
                                            <FormGroup>
                                                <Label>ระยะเวลา</Label>
                                                <Input
                                                    type="textarea"
                                                    name="remarkTime"
                                                    id="remarkTime"
                                                    onChange={this.changeValue}
                                                    value={val.remarkTime}
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="table-custom table-footer">
                                        <Col md={4} className="margin-top-20"><b>ส่วนที่ 1 ด้านผลสำเร็จของงาน (60 คะแนน) </b> <Input type="text" name="scorePart1" id="scorePart1" value={val.scorePart1} readOnly /></Col>
                                        <Col md={4} className="margin-top-20"><b>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม (40 คะแนน) </b><Input type="text" name="scorePart2" id="scorePart2" value={val.scorePart2} readOnly /></Col>
                                        <Col md={4} className="margin-top-20 padding-right-32"><b>รวมคะแนนทั้งหมด (100 คะแนน) </b><Input type="text" name="totalScore" id="totalScore" value={val.totalScore} readOnly /></Col>
                                    </Row>

                                </CardBody>

                            );

                        })}

                    </TabPane>
                    <TabPane tab="รายงานผลประเมินย้อนหลัง 3 ปี" key="2">
                        <h3>รายงานผลประเมินย้อนหลัง 3 ปี</h3>
                        {notFound}
                        {dataHistory.map((val, i) => {
                            if (i === 0) {
                                return (
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                                                    <Input type="text" name="StaffName_dis" id="StaffName_dis" value={dataHistory[0]['staffName']} disabled />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="company">ตำแหน่งงาน</Label>
                                                    <Input type="text" name="Level_WorkerDes" id="Level_WorkerDes" value={dataHistory[0]['Level_WorkerDes']} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h4>ส่วนที่ 1 การประเมินปัจจัยด้านผลงาน</h4>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>หัวข้อการประเมิน</b></Col>
                                            <Col md={2} className="col-style"><b> {dataHistory['0'] === undefined ? '' : "ปี" + dataHistory['0']['Years']}</b> <br />
                                                {dataHistory['0'] === undefined ? '' : "(" + dataHistory['0']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"><b>{dataHistory['1'] === undefined ? '' : "ปี" + dataHistory['1']['Years']}</b><br />
                                                {dataHistory['1'] === undefined ? '' : "(" + dataHistory['1']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"><b> {dataHistory['2'] === undefined ? '' : "ปี" + dataHistory['2']['Years']}</b><br />
                                                {dataHistory['2'] === undefined ? '' : "(" + dataHistory['2']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"> <b> ปีปัจจุบัน </b> <br />({_year})

                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head2">&nbsp;&nbsp; <b>ผลงานตามเป้าหมาย</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">ปริมาณงาน</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_1_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_1_1']}</center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_1_1']}</center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_1_1" id="score1_1_1"
                                                    onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 30, 0, 'score1_1_1')} value={data[0]['score1_1_1']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">เวลา</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_1_2" id="score1_1_2"
                                                    onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 15, 1, 'score1_1_2')} value={data[0]['score1_1_2']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head2">&nbsp;&nbsp; <b>มาตรฐานงาน</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">คุณภาพ</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_2_1" id="score1_2_1"
                                                    onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 15, 2, 'score1_2_1')} value={data[0]['score1_2_1']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">การแก้ไข</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_2_2']} </center></Col>
                                            {/* <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_2_2" id="score1_2_2"
                                                    onChange={valueAsNumber => this.handleInputChangeP1(valueAsNumber, 15, 3, 'score1_2_2')} value={data[0]['score1_2_2']} />
                                            </Col> */}
                                        </Row>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>รวมคะแนนส่วนที่ 1</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="scorePart1" id="scorePart1" value={data[0]['scorePart1']} readOnly />
                                            </Col>
                                        </Row>
                                        <br />
                                        <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Core Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Teamwork</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_1" id="score2_1"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 0, 'score2_1')} value={data[0]['score2_1']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Customer Focus</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_2" id="score2_2"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 1, 'score2_2')} value={data[0]['score2_2']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Excellence / Go Beyond</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_3" id="score2_3"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 2, 'score2_3')} value={data[0]['score2_3']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Learning and Development</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_4" id="score2_4"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 3, 'score2_4')} value={data[0]['score2_4']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Functional Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Job Knowledge</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_6" id="score2_6"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 4, 4, 'score2_6')} value={data[0]['score2_6']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Problem Solving</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_7" id="score2_7"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 4, 5, 'score2_7')} value={data[0]['score2_7']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Managerial Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Leadership</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_8" id="score2_8"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 4, 6, 'score2_8')} value={data[0]['score2_8']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">People Management</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_9" id="score2_9" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 4, 7, 'score2_9')}
                                                    value={data[0]['score2_9']} />

                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Planing and Organizing</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_10" id="score2_10"
                                                    onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 4, 8, 'score2_10')} value={data[0]['score2_10']} />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>รวมคะแนนส่วนที่ 2</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="scorePart2" id="scorePart2" value={data[0]['scorePart2']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">
                                            <Col md={4} className="col-style"><b>รวมคะแนนทั้งหมด</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="totalScore" id="totalScore" value={data[0]['totalScore']} readOnly />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                );
                            }
                        })}
                    </TabPane>
                </Tabs >
            </div>
        );

    }
}
export default PrintPE_SUP;