import React, { Component } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import axios from "axios";
import { API_URL } from "../components/connectAPI";
import BootstrapTable from "react-bootstrap-table-next";
import { browserHistory } from "react-router";
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
} from "reactstrap";
import { Modal } from "antd";
import {
  GET,
  SELECT_PRINT_CF_EMP_OUT
} from "../service";

const columnsTask = [
  {
    dataField: "ITAsset",
    headerAlign: "center",
    align: "center",
    text: "IT Fixed Asset",
  },
  {
    dataField: "ITDomain",
    headerAlign: "center",
    align: "center",
    text: "IT Domain",
  },
  {
    dataField: "ITEmail",
    headerAlign: "center",
    align: "center",
    text: "IT Email",
  },
  {
    dataField: "ITSoftware",
    headerAlign: "center",
    align: "center",
    text: "IT Software",
  },
  {
    dataField: "PurchaseFixed",
    headerAlign: "center",
    align: "center",
    text: "Purchase Fixed Asset",
  },
  {
    dataField: "Account",
    headerAlign: "center",
    align: "center",
    text: "Account",
  },
];

const columnsDescription = [
  {
    dataField: "id",
    text: "No",
    headerAlign: "center",
    align: "center",
    style: { width: "5%" },
  },

  {
    dataField: "type",
    headerAlign: "center",
    text: "Type",
    style: { width: "20%" },
  },
  {
    dataField: "description",
    headerAlign: "center",
    text: "Description",
    style: { width: "40%" },
  },
  {
    dataField: "note",
    headerAlign: "center",
    text: "Note",
    style: { width: "30%" },
  },
];

class Assets_QuitFormConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: "",
      resultView: [],
      note: "",
      userLogin: this.props.userLogin,
      confirmInfos: [],
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let UserID = params.get("UserID"); // is the string "Jonathan"
    this.setState({ UserID: UserID }, () => {
      this.ViewPrintConfirmEmployeeQuit();
    });
  }

  //---------------------------Call API-----------------------

  ViewPrintConfirmEmployeeQuit = async () => {

    const userID = this.state.UserID;
    const res = await GET(SELECT_PRINT_CF_EMP_OUT(userID));
    const { result } = res

    let profiles = result.profile;
    let confirmInfos = result.confirmInfo;
    let assetLists = result.assetList;

    let resultView = [];
    let resultComfirm = [confirmInfos];

    this.setState({
      FullName: `${profiles.ID_User}  ${profiles.FullName}`,
      SupName: `${profiles.SupID} ${profiles.SupName}`,
      DepartmentName: profiles.DepartmentName,
      EndDateJob: profiles.EndDateJob,
      Location: profiles.Location,
      Level_Worker: profiles.Level_Worker,
    });

    assetLists.map((val, index) => {
      resultView.push({
        id: index + 1,
        type: val.AssetID,
        description: val.AssetName,
        note: val.AssetDesc,
      });
    });

    confirmInfos = resultComfirm.map((val, index) => {
      return {
        ITAsset: (
          <React.Fragment>
            {val.ITFixedAssetUser_End}
            <br />
            {val.ITFixedAssetCompletedDate_End}
            <br />
            {val.ITFixedAssetNote_End}
          </React.Fragment>
        ),
        ITDomain: (
          <React.Fragment>
            {val.ITDomainUser_End}
            <br />
            {val.ITDomainCompletedDate_End}
            <br />
            {val.ITDomainNote_End}
          </React.Fragment>
        ),
        ITEmail: (
          <React.Fragment>
            {val.ITEmailUser_End}
            <br />
            {val.ITEmailCompletedDate_End}
            <br />
            {val.ITEmailNote_End}
          </React.Fragment>
        ),
        ITSoftware: (
          <React.Fragment>
            {val.ITSoftwareUser_End}
            <br />
            {val.ITSoftwareCompletedDate_End}
            <br />
            {val.ITSoftwareNote_End}
          </React.Fragment>
        ),
        PurchaseFixed: (
          <React.Fragment>
            {val.PurchaseFixedUser_End}
            <br />
            {val.PurchaseFixedCompletedDate_End}
            <br />
            {val.PurchaseFixedNote_End}
          </React.Fragment>
        ),
        Account: (
          <React.Fragment>
            {val.AccountUser_End}
            <br />
            {val.AccountCompletedDate_End}
            <br />
            {val.AccountNote_End}
          </React.Fragment>
        ),
      };
    });
    this.setState({
      resultView: resultView,
      confirmInfos: confirmInfos,
    });
  };

  handleConfirm = async () => {
    const { note, userLogin, UserID } = this.state;
    if (window.confirm("ยืนยันปิดงานใช่หรือไม่?")) {
      let API = `${API_URL}/HRM_API/update_TaskConfirm`;
      let options = {
        headers: { "content-type": "application/json; charset=utf-8" },
      };
      let data = {
        TransStatus: "1",
        UserID: UserID,
        Note: note,
        updateUserID: userLogin,
        isWork: "QUIT",
      };
      let res = await axios.post(API, data, options);
      const { success, message } = res.data;
      if (success) {
        browserHistory.push(`/Assets_QuitFormPrint?UserID=${UserID}`);
      } else {
        // alert(message);
        Modal.info({ title: message })
      }
    }
  };
  //---------------------------Call API-----------------------

  //---------------------------onchange-----------------------
  onChangeNote = (e) => {
    this.setState({ note: e.target.value });
  };
  //------------------------- onChange-----------------------

  render() {
    const {
      FullName,
      SupName,
      EndDateJob,
      Location,
      Level_Worker,
      DepartmentName,
      resultView,
      note,
      confirmInfos,
    } = this.state;
    return (
      <div>
        <Container>
          <Card>
            <CardHeader>สรุปข้อมูลสินทรัพย์พนักงานลาออก</CardHeader>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label sm={1}>รหัส/ชื่อพนักงาน: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="UserName"
                      id="UserName"
                      value={FullName}
                      readOnly
                    />
                  </Col>
                  <Label sm={1}>รหัส/ชื่อหัวหน้า: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="SupName"
                      id="SupName"
                      value={SupName}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={1}>วันที่ลาออก: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="EndDateJob"
                      id="EndDateJob"
                      value={EndDateJob}
                      readOnly
                    />
                  </Col>
                  <Label sm={1}>ฝ่าย: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="Location"
                      id="Location"
                      value={Location}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={1}>ระดับ: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="Level_Worker"
                      id="Level_Worker"
                      value={Level_Worker}
                      readOnly
                    />
                  </Col>
                  <Label sm={1}>แผนก: </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="DepartmentName"
                      id="DepartmentName"
                      value={DepartmentName}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <hr />
          <h5>งานที่ทำ</h5>
          <BootstrapTable
            keyField="id"
            data={confirmInfos}
            columns={columnsTask}
            noDataIndication={"no results found"}
          />
          <br />
          <h5>รายละเอียดสินทรัพย์ที่ต้องคืน</h5>
          <BootstrapTable
            keyField="id"
            data={resultView}
            columns={columnsDescription}
            noDataIndication={"no results found"}
          />
          <FormGroup>
            <Label for="note">
              <b>เพิ่มเติม</b>
            </Label>
            <Input
              type="textarea"
              name="note"
              id="note"
              value={note}
              onChange={this.onChangeNote}
            />
          </FormGroup>

          <center>
            <Button
              type="submit"
              color="success"
              className="text-center"
              onClick={this.handleConfirm}
            >
              <AiOutlineCheck /> Confirm
            </Button>
          </center>
        </Container>
      </div>
    );
  }
}

export default Assets_QuitFormConfirm;
