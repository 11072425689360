import React from "react";
import { Col, Row } from "antd";
import { Dropdown } from "./dropdown";
import { InputLabel, InputNumber, InputArea, AutoComplete } from "./input";
import { DatePicker, RangePicker } from "./datePicker";
import { MemoizedCheckbox } from "./checkBox";
import { TextLabel } from "./text";
const { Fragment } = React;

export const RenderForm = ({
  forms = [],
  setValue,
  //getValues, //unused
  control,
  styleRow,
  onChange,
  onClear,
  searchSpan,
  renderButton,
  title,
}) => {
  function handleChange(e, item, option) {
    // if (!e) {
    //   return;
    // }

    switch (item.type) {
      case "DROPDOWN":
      case "SELECT":
      case "SELECT_MULTI":
      case "DATE_PICKER":
      case "RANGE_PICKER":
        setValue(item.ref, e);
        break;
      case "FILE_UPLOAD":
      case "IMAGE_UPLOAD":
        setValue(item.ref, e.fileList);
        break;
      case "SELECT_MODAL":
        setValue(item.ref, e);
        break;
      case "CHECK_BOX":
        setValue(item.ref, e.target.checked);
        break;
      case "AUTO_COMPLETE":
        setValue(item.ref, e);
        break;
      default:
        setValue(item.ref, e.target.value);
        break;
    }

    onChange && onChange(e, item, option);
  }

  function handleClear(e, item) {
    setValue(item.ref, "");
    onClear && onClear(e, item);
  }

  const rest = {
    control,
    setValue,
    //getValues,  //unused
    handleChange,
    handleClear,
  };

  return (
    <Fragment>
      <Row gutter={[8, 8]} style={styleRow}>
        {forms.map((f, i) => {
          return (
            <Col
              key={`renderInput${i}_${title}`}
              xs={{ span: 24 }}
              md={{ span: 24 }}
              xl={{ span: f.span }}
              lg={{ span: f.span }}
              style={{ ...f.style }}
            >
              {renderInputType(f, rest)}
            </Col>
          );
        })}
        <Col
          key={`renderButton_${title}`}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          xl={{ span: searchSpan }}
          lg={{ span: searchSpan }}
          style={{
            padding: 0,
            textAlign: "center",
            marginTop: searchSpan == 24 ? 0 : "auto",
          }}
        >
          {renderButton && (
            <div
              style={{
                marginTop: "14px",
              }}
            >
              {renderButton}
            </div>
          )}
        </Col>
      </Row>
      {/* <div style={{ marginBottom: '16px' }} /> */}
    </Fragment>
  );
};

function renderInputType(item, globalProps) {
  const { type } = item;
  switch (type) {
    case "INPUT":
      return <InputLabel item={item} {...globalProps} />;
    case "INPUT_NUMBER":
      return <InputNumber item={item} {...globalProps} />;
    case "DROPDOWN":
      return <Dropdown item={item} {...globalProps} />;
    case "DATE_PICKER":
      return <DatePicker item={item} {...globalProps} />;
    case "RANGE_PICKER":
      return <RangePicker item={item} {...globalProps} />;
    case "INPUT_AREA":
      return <InputArea item={item} {...globalProps} />;
    case "CHECK_BOX":
      return <MemoizedCheckbox item={item} {...globalProps} />;
    case "AUTO_COMPLETE":
      return <AutoComplete item={item} {...globalProps} />;
    case "TEXT":
      return <TextLabel item={item} {...globalProps} />;

    default:
      return <div />;
  }
}
