import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from "antd";
import { configForm } from "./constants/ConstantComponent";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useRenderFormItem } from "./conponents/RenderComponents";
import { ComponentCutOffCommissionIncentive } from "./constants/CutOffCommissionIncentive/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import {
  POST,
  GET_COMMISSION_INCENTIVE_BY_DOCNO_FSP,
  UPDATE_STATUS_SAVE_COMMISSION_FSP,
} from "../service";
import { useAlert } from "./conponents/modal/CustomAlert";
import { useOptionSelect } from "./constants/OptionSelect";
import moment from "moment";
import { decryptString } from "../service/functions/encodeFunction";
import { optionInstallmentSaleStatus } from "../common/enum";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import encodeString from "../extendFunction/encodeString";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import { useConfirm } from "./conponents/modal/CustomConfirm";
import { browserHistory } from "react-router";
import { useExportExcel } from "./services/ExportExcel";
import {
  Header_CutOffSaleEmployeeReport,
  Header_CutOffSaleEmployeeReportFSP,
} from "./constants/HeaderExcel";
import { getTotalNet } from "./constants/RecheckCommissionIncentive/Columns";
import { ColumnCutOffCommissionIncentiveFSP } from "./constants/CutOffCommissionIncentiveFSP/Columns";

const CutOffCommissionIncentiveFSP = (props) => {
  const [form] = Form.useForm();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const cookies = SetStringToObject(Cookies.get("cookie_session"));
  const [dateStart, setDateStart] = useState("");
  const [dataExcel, setDataExcel] = useState([]);
  const [dateEnd, setDateEnd] = useState("");

  let optionSearch = {
    depNo: useOptionSelect("department"),
  };

  useEffect(() => {
    if (props.location.state && props.location.state.docNo) {
      getCommissionIncentiveByDocNo(props.location.state.docNo);
      const start = moment(new Date(props.location.state.installmentStartDate));
      const end = moment(new Date(props.location.state.installmentEndDate));
      setDateEnd(end);
      setDateStart(start);
    } else {
      browserHistory.push({
        pathname: "/CutOffCommissionIncentiveDashboardFSP",
      });
    }
  }, []);
  const formDataExcel = (result) => {
    return result.map((row, index) => {
      let preObj = {
        ...row,
        key: index + 1,
        statusInstallmentName:
          row.statusWorkerInstallment &&
          renderLabelStatusInstallment(row.statusWorkerInstallment),
        commission: row.commission
          ? parseFloat(decryptString(row.commission)).toLocaleString()
          : "",
        incentive: row.incentive
          ? parseFloat(decryptString(row.incentive)).toLocaleString()
          : "",
        incentiveGuarantee:
          parseFloat(decryptString(row.incentiveGuarantee)) || "",
        incentiveMonth: decryptString(row.incentiveMonth) || "",
        commissionMonth: decryptString(row.commissionMonth) || "",
        persentSalePerMonth: decryptString(row.persentSalePerMonth) || "",
        rvAmount: parseFloat(decryptString(row.rvAmount)) || "",
        saleActual: row.saleActual
          ? parseFloat(decryptString(row.saleActual)).toLocaleString()
          : "",
        saleTarget: row.saleTarget
          ? parseFloat(decryptString(row.saleTarget)).toLocaleString()
          : "",
        totalCommission: row.totalCommission
          ? parseFloat(decryptString(row.totalCommission))
          : "",
        totalCommissionBeforeUpdate:
          parseFloat(decryptString(row.totalCommissionBeforeUpdate)) || "",
        totalIncentive: row.totalIncentive
          ? parseFloat(decryptString(row.totalIncentive))
          : "",
        totalIncentiveBeforeUpdate:
          parseFloat(decryptString(row.totalIncentiveBeforeUpdate)) || "",
        percentAccDecrypt: parseFloat(decryptString(row.percentAcc)) || "",
        percentTempDecrypt: parseFloat(decryptString(row.percentTemp)) || "",

        totalCommissionExcel: row.totalCommission
          ? parseFloat(decryptString(row.totalCommission)).toLocaleString()
          : "",
        totalIncentiveExcel: row.totalIncentive
          ? parseFloat(decryptString(row.totalIncentive)).toLocaleString()
          : "",
        totalCommissionBeforeUpdateExcel: row.totalCommissionBeforeUpdate
          ? parseFloat(
              decryptString(row.totalCommissionBeforeUpdate)
            ).toLocaleString()
          : "",
        totalIncentiveBeforeUpdateExcel: row.totalIncentiveBeforeUpdate
          ? parseFloat(
              decryptString(row.totalIncentiveBeforeUpdate)
            ).toLocaleString()
          : "",
      };
      return {
        ...preObj,
        total: getTotalNet(preObj),
      };
    });
  };
  const getCommissionIncentiveByDocNo = async (docNo) => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_COMMISSION_INCENTIVE_BY_DOCNO_FSP, {
        docNo,
      });
      const { result } = response;
      let mapResult = [];
      let mapExcelResult = [];
      if (result.length !== 0) {
        form.setFieldsValue({ depNo: props.location.state.depNo.split(",") });
        form.setFieldsValue({
          startDate: moment(props.location.state.installmentStartDate),
        });
        form.setFieldsValue({
          endDate: moment(props.location.state.installmentEndDate),
        });
        mapExcelResult = formDataExcel(result);
        mapResult = result.map((row, index) => {
          let preObj = {
            ...row,
            key: index + 1,
            statusInstallmentName:
              row.statusWorkerInstallment &&
              renderLabelStatusInstallment(row.statusWorkerInstallment),
            commission: row.commission
              ? parseFloat(decryptString(row.commission)).toFixed(2)
              : "",
            incentive: row.incentive
              ? parseFloat(decryptString(row.incentive)).toFixed(2)
              : "",
            incentiveGuarantee: row.incentiveGuarantee
              ? parseFloat(decryptString(row.incentiveGuarantee)).toFixed(2)
              : "",
            incentiveMonth: row.incentiveMonth
              ? decryptString(row.incentiveMonth)
              : "",
            commissionMonth: row.commissionMonth
              ? decryptString(row.commissionMonth)
              : "",
            incentiveStrategy: row.incentiveStrategy
              ? parseFloat(decryptString(row.incentiveStrategy)).toFixed(2)
              : "",
            persentSalePerMonth: row.persentSalePerMonth
              ? parseFloat(decryptString(row.persentSalePerMonth)).toFixed(2)
              : "",
            rvAmount: row.rvAmount
              ? parseFloat(decryptString(row.rvAmount)).toFixed(2)
              : "",
            saleActual: row.saleActual
              ? parseFloat(decryptString(row.saleActual)).toFixed(2)
              : "",
            saleTarget: row.saleTarget
              ? parseFloat(decryptString(row.saleTarget)).toFixed(2)
              : "",
            totalCommission: row.totalCommission
              ? parseFloat(decryptString(row.totalCommission))
              : "",
            totalCommissionBeforeUpdate: row.totalCommissionBeforeUpdate
              ? parseFloat(
                  decryptString(row.totalCommissionBeforeUpdate)
                ).toFixed(2)
              : "",
            totalIncentive: row.totalIncentive
              ? parseFloat(decryptString(row.totalIncentive))
              : "",
            totalIncentiveBeforeUpdate: row.totalIncentiveBeforeUpdate
              ? parseFloat(
                  decryptString(row.totalIncentiveBeforeUpdate)
                ).toFixed(2)
              : "",
          };
          return {
            ...preObj,
            total: getTotalNet(preObj),
          };
        });
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataSource(mapResult);
      setDataExcel(mapExcelResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Recheck Employee: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const renderLabelStatusInstallment = (statusWorkerInstallment) => {
    return optionInstallmentSaleStatus[
      optionInstallmentSaleStatus.findIndex(
        (el) => el.value.split(" | ")[0] == statusWorkerInstallment
      )
    ].label;
  };

  const onSelectChange = (selectedRowDataKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowDataKeys);
  };

  const rowsSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.statusSave !== "1" || props.location.state.view, // Column configuration not to be checked
    }),
  };

  const cutOffData = () => {
    let obj = {
      statusCutOff: "1",
      docNo: props.location.state.docNo,
      userLogin: encodeString.decode(cookies.ID_User),
    };
    useConfirm({
      content: `ต้องการยืนยันการตัดรอบข้อมูลใช่หรือไม่`,
      FnOK: () => updateStatusSave(obj),
    });
  };

  const updateStatusSave = async (obj) => {
    try {
      setLoadingScreen(true);
      const response = await POST(UPDATE_STATUS_SAVE_COMMISSION_FSP, obj);
      const { message, success } = response;
      if (success) {
        useAlert({
          type: "success",
          title: "ดำเนินการสำเร็จ",
          content: message,
          Fn: () =>
            browserHistory.push({
              pathname: "/CutOffCommissionIncentiveDashboardFSP",
            }),
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser: ", err);
      browserHistory.push("/");
    } finally {
      setLoadingScreen(false);
    }
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      <Form form={form} onFinish={() => {}} {...configForm}>
        <Card
          title={`ตัดรอบข้อมูล Commission/Incentive (รอบวันที่ ${moment(
            dateStart
          ).format("YYYY-MM-DD")} - ${moment(dateEnd).format("YYYY-MM-DD")})`}
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search", isDisable: true })}
              {useButton({
                call: "normal",
                fn: () => {},
                isDisable: true,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentCutOffCommissionIncentive({
              options: optionSearch,
              handleChange: () => {},
              isDisableInput: props.location.state.docNo,
            }),
          })}
        </Card>
      </Form>
      <Card
        extra={useExportExcel({
          header: Header_CutOffSaleEmployeeReportFSP,
          dataset: dataExcel,
          filename: "รายงานค่า commission/incentive พนักงาน",
        })}
      >
        <CustomTableAtnd
          HeadTable={ColumnCutOffCommissionIncentiveFSP}
          DataTable={dataSource}
          moreProps={{
            rowSelection: rowsSelection,
            pageSize: 50,
            scroll: {
              x: sumColumnWidth(ColumnCutOffCommissionIncentiveFSP) || 2050,
              y: 500,
            },
            size: "small",
          }}
        />
        <div style={{ textAlignLast: "center", marginTop: "15px" }}>
          <Space>
            {useButton({
              call: "save",
              isDisable: !(
                dataSource.length > 0 &&
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length === dataSource.length
              ),
              moreProps: { label: "ยืนยันข้อมูล", onClick: cutOffData },
            })}
            {useButton({
              call: "normal",
              fn: () =>
                browserHistory.push({
                  pathname: "/CutOffCommissionIncentiveDashboard",
                }),
              moreProps: { label: "กลับหน้าหลัก" },
            })}
          </Space>
        </div>
      </Card>
    </Spin>
  );
};

export default CutOffCommissionIncentiveFSP;
