export const BREADCRUMB_REPORT_EVAC = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "รายงานสรุปผลประเมินภาพรวม" }
            ]
    }
}
export const BREADCRUMB_DASHBOARD_REVIEWER = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "เลือกฟอร์มแบบสอบถาม" }
            ]
    }
}
export const BREADCRUMB_INSERT_REVIEWER = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "เลือกฟอร์มแบบสอบถาม" }
            ]
    }
}
export const BREADCRUMB_DASHBOARD_EVAC = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "ทำแบบสอบถาม" }
            ]
    }
}
export const BREADCRUMB_DASHBOARD_EVAC_BY_ADMIN = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "รายงานสรุปผลประเมินภาพรวม Admin" }
            ]
    }
}
export const BREADCRUMB_DASHBOARD_EVAC_BY_DEP = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "แบบประเมินความพึงพอใจระหว่างแผนก" },
                { label: "รายงานสรุปผลประเมินภาพรวม (รายแผนก)" }
            ]
    }
}