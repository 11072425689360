import React, { useEffect, useState } from "react";
import { Descriptions, Card } from "antd";
import { SSO, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import { browserHistory } from "react-router";
import { GET_UserLogin } from "../../functions/UseFunction";
import { GET } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { PATH_ROUTE } from "../../constants/ConstantPath";

const FormShowSSOInfo = () => {
  const { userlogin } = GET_UserLogin();
  const [dataInfo, setDataInfo] = useState({});

  useEffect(() => {
    GET_SHOW_INFO_SSO();
  }, []);

  const LABEL_INFO = {
    companyCode: "ชื่อสถานประกอบการ",
    userId: "รหัสพนักงาน",
    fullName: "ชื่อ-นามสกุล",
    personalId: "เลขบัตรประจำตัวประชาชน",
    dateOfBirth: "วันเดือนปีเกิด",
    startDateJob: "วันที่เริ่มงาน",
    hospital1: "โรงพยาบาลลำดับที่ 1",
    hospital2: "โรงพยาบาลลำดับที่ 2",
    hospital3: "โรงพยาบาลลำดับที่ 3",
  };

  const renderDescriptionItem = () => {
    let result = [];
    for (const [key, value] of Object.entries(LABEL_INFO)) {
      result.push(
        <Descriptions.Item key={key} label={value}>
          {dataInfo[key] || "-"}
        </Descriptions.Item>
      );
    }
    return result;
  };

  const GET_SHOW_INFO_SSO = async () => {
    try {
      const url = `${USE_CONNECT_MODE_AUTO}${SSO.GET_Report}?userId=${userlogin}`;
      const response = await GET(url);
      const { result } = response;
      let mapData = [{}];
      if (result.length !== 0) {
        mapData = result.map((row) => ({
          ...row,
          dateOfBirth: row.dateOfBirth ? row.dateOfBirth.substring(0, 10) : "-",
          startDateJob: row.startDateJob
            ? row.startDateJob.substring(0, 10)
            : "-",
        }));
      } else {
        useAlert({
          type: "info",
          title: `ไม่พบข้อมูลของ "${userlogin}" กรุณาลองใหม่อีกครั้ง`,
          Fn: () => browserHistory.push(PATH_ROUTE.HOME),
        });
      }
      setDataInfo(...mapData);
    } catch (err) {
      useAlert({
        type: "error",
        title: "[CATCH] :: เกิดข้อผิดพลาด",
        content: `${err.message}`,
        Fn: () => browserHistory.push(PATH_ROUTE.HOME),
      });
    }
  };

  return (
    <Card
      title="ข้อมูลโรงพยาบาลประกันสังคม"
      style={{ marginBottom: 30 }}
      extra={useButton({
        call: "link",
        linkBtn: PATH_ROUTE.HOME,
        moreProps: { label: "กลับหน้าแรก", type: "primary" },
      })}
    >
      <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
        layout="vertical"
      >
        {renderDescriptionItem()}
      </Descriptions>
    </Card>
  );
};

export default FormShowSSOInfo;
