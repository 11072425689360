import parse from "html-react-parser";
import React from "react";
import { Card } from "antd";
import {
  ButtonTheme,
  ContainerButton,
} from "../../../../components/global/buttons";
import { TextLarge } from "../../../../components/global/text";
import { onPrintPDF } from "../function/print";
import { FormPrintContainer } from "../style";

export const FormPrint = ({ formData }) => {
  return (
    <Card>
      <TextLarge text={formData.docDescription} bold={true} align="center" />
      <ContainerButton center>
        <ButtonTheme
          useFor="PRINT"
          title="พิมพ์เอกสาร"
          onClick={() => onPrintPDF(formData.formPrint)}
        />
      </ContainerButton>
      <FormPrintContainer>{parse(formData.formPrint)}</FormPrintContainer>
    </Card>
  );
};
