import React from "react";
import { Checkbox } from "antd";

export const useCheckbox = ({
    name = "useCheckbox",
    text = "",
    componentProps = {},
    fnChange = () => null,
}) => {
    return <Checkbox name={name} {...componentProps} onChange={fnChange} > {text} </Checkbox>;
};