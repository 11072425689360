import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";
import { Modal, message } from "antd";
import { path } from "ramda";
import { browserHistory } from "react-router";

let API = servicesAPI.create()

export async function APISelectDashboardReviewer(payload, nextAction) {
    try {
        const response = await API.selectDashboardReviewer(payload);
        const { status, data, problem } = response;
        switch (status) {
            case 200:
                if (typeof nextAction === 'function') {
                    return nextAction(path(['result'], data))
                }
                break;
            case 403:
                Modal.warn({
                    title: `${path(['result', 'message'], data)}`,
                    onOk: () => browserHistory.push("/")
                })
                break;
            default:
                Modal.error({
                    title: `${status} ( ${problem || ''} )`,
                    onOk: () => browserHistory.push("/")
                })
                break;
        }
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUserPermissionsReviewer(payload, nextAction) {
    try {
        const response = await API.userPermissionReviewer(payload);
        const { status, data, problem } = response;
        switch (status) {
            case 200:
                if (typeof nextAction === 'function') {
                    return nextAction(path(['result'], data))
                }
                break;
            case 403:
                Modal.warn({
                    title: `${path(['result', 'message'], data)}`,
                    onOk: () => browserHistory.push("/")
                })
                break;
            default:
                Modal.error({
                    title: `${status} ( ${problem || ''} )`,
                    onOk: () => browserHistory.push("/")
                })
                break;
        }
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectEmpEvac(payload, nextAction) {
    try {
        const response = await API.selectEmpEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectReviewerCurrent(payload, nextAction) {
    try {
        const response = await API.selectReviewerCurrent(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectReviewerUpdate(payload, nextAction) {
    try {
        const response = await API.selectReviewerUpdate(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectDashboardEvac(payload, nextAction) {
    try {
        const response = await API.selectDashboardEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectDashboardEvacAction(payload, nextAction) {
    try {
        const response = await API.selectDashboardEvacAction(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectDashboardEvacView(payload, nextAction) {
    try {
        const response = await API.selectDashboardEvacView(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectDataEvac(payload, nextAction) {
    try {
        const response = await API.selectDataEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectSummaryReplyEvacByDep(payload, nextAction) {
    try {
        // console.log({ payload })
        const response = await API.selectSummaryReplyEvacByDep(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectSummaryReplyEvacByForm(payload, nextAction) {
    try {
        // console.log({ payload })
        const response = await API.selectSummaryReplyEvacByForm(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISummaryAvgScoreByLine(payload, nextAction) {
    try {
        // console.log({ payload })
        const response = await API.selectSummaryAvgScoreByLine(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
