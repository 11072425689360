import React from "react";
import { Button } from "antd";
import {
  SearchOutlined,
  FormOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { FiPrinter } from "react-icons/fi";

export const useButton = ({
  // [Start] Default Value
  call = "",
  isDisable = false,
  moreProps = {},
  moreStyle = {},
  fn,
  linkBtn = `/`,
  width = "100%",
  // [End] Default Value
}) => {
  let propsBtn = {};
  switch (call) {
    // Standard //
    case "normal":
      propsBtn = {
        label: "Normal",
        type: "button",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "link":
      propsBtn = {
        label: "Link",
        type: "button",
        href: linkBtn,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "submit":
      propsBtn = {
        label: "Submit",
        type: "primary",
        htmlType: "submit",
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "reset":
      propsBtn = {
        label: "Reset",
        htmlType: "rest",
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    // Standard //
    case "save":
      propsBtn = {
        label: moreProps.label || "บันทึก",
        htmlType: "submit",
        style: {
          backgroundColor: isDisable || "#5CB85C",
          borderColor: isDisable || "#5CB85C",
          color: isDisable || "#FFFFFF",
          width,
          ...moreStyle,
        },
        disabled: isDisable,
        ...moreProps,
      };
      break;
    case "search":
      propsBtn = {
        label: "ค้นหา",
        type: "primary",
        htmlType: "submit",
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "clear":
      propsBtn = {
        label: "ล้างข้อมูล",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "view":
      propsBtn = {
        label: "สมาชิก",
        type: "primary",
        onClick: fn,
        icon: <SearchOutlined />,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "active":
      propsBtn = {
        label: "เปิดการใช้งาน",
        onClick: fn,
        icon: <CheckCircleOutlined />,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: "#5CB85C",
          color: "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "inActive":
      propsBtn = {
        label: "ปิดการใช้งาน",
        type: "danger",
        onClick: fn,
        icon: <CloseCircleOutlined />,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "edit_link":
      propsBtn = {
        label: "แก้ไข",
        type: "warning",
        href: linkBtn,
        target: "_blank",
        icon: <FormOutlined />,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: "#F0AD4E",
          borderColor: "#F0AD4E",
          color: "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "download":
      propsBtn = {
        label: "Download",
        href: linkBtn,
        target: "_blank",
        icon: <DownloadOutlined />,
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "quit":
      propsBtn = {
        label: "แจ้งพ้นสภาพ",
        htmlType: "submit",
        style: {
          backgroundColor: isDisable || "#B22222",
          borderColor: isDisable || "#B22222",
          color: isDisable || "#FFFFFF",
          width,
          ...moreStyle,
        },
        disabled: isDisable,
        ...moreProps,
      };
      break;
    case "onWait":
      propsBtn = {
        label: "รอดำเนินการ",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: "#FFC000",
          borderColor: "#FFC000",
          color: "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "onFollow":
      propsBtn = {
        label: "ติดตามงาน",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: "#01A9DB",
          borderColor: "#01A9DB",
          color: "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "confirm":
      propsBtn = {
        label: "ยืนยัน",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: isDisable || "#5CB85C",
          borderColor: isDisable || "#5CB85C",
          color: isDisable || "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "confirmOpen":
      propsBtn = {
        label: "บันทึก",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: isDisable || "#5CB85C",
          borderColor: isDisable || "#5CB85C",
          color: isDisable || "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "print":
      propsBtn = {
        label: "print",
        onClick: fn,
        disabled: isDisable,
        ...moreProps,
        style: {
          backgroundColor: "#01A9DB",
          borderColor: "#01A9DB",
          color: "#FFFFFF",
          width,
          ...moreStyle,
        },
      };
      break;
    case "clickSearch":
      propsBtn = {
        label: "ค้นหา",
        onClick: fn,
        type: "primary",
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    case "custom":
      propsBtn = {
        disabled: isDisable,
        ...moreProps,
        style: {
          width,
          ...moreStyle,
        },
      };
      break;
    default:
      return <Button>Default</Button>;
  }

  return <Button {...propsBtn}>{propsBtn.label}</Button>;
};
