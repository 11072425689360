export const onPrintPDF = async (htmlData) => {
  const winPrint = window.open("", "_blank");
  winPrint.document.write(htmlData);
  winPrint.document.close();
  winPrint.focus();
  setTimeout(() => {
    winPrint.print();
    winPrint.close();
  }, 100);
};
