import React, { Fragment, useContext, useState } from 'react'
import { LayoutSearch } from '../../common/layouts'
import { MainInput, TYPE } from '../../common/inputs'
import { DynamicField } from '../../common/inputs/dynamicField';
import { Col, Row } from 'antd';
import { BaseText } from '../../common/text';
import { MasterContext } from '../../services/master/masterProvider';
import { dynamicLabel } from '../../common/functions';
import { IndicatorContext } from '../../services/manage/indicatorProvider';
import { MASTER_OPTION } from '../../services/servicesOptions';
import { STRUCTURE_KPIS } from '../../constants/structures/structuresKPIs';
import { ReportContext } from '../../services/report/reportProvider';
import moment from 'moment';

// const dateFormat = 'MM-YYYY';
export const InsertIndicator = ({ approval, disabled }) => {
    const { onSearch, masterState } = useContext(MasterContext)
    const { indicatorState, onChangeValue } = useContext(IndicatorContext)
    // console.log({ indicatorState }, masterState.optionEvaluationEmployee_LV1)
    //console.log({ indicatorState })
    return (
        <Fragment>
            <Row gutter={[24, 10]}>
                {
                    STRUCTURE_KPIS.LAYOUT_INSERT_INDICATOR({ indicatorState, option: masterState }).map((element, elementIndex) => {

                        return (
                            <Col key={elementIndex} span={element.span}>
                                <MainInput
                                    // disabled={disabled}
                                    inputProps={{
                                        ...element.inputProps,
                                        onSearch: (value) => onSearch({ fieldname: MASTER_OPTION.INDICATOR_HEADER, searchText: value }),
                                    }}
                                    rangValue={indicatorState.startActive ? [moment(indicatorState.startActive, "MM-YYYY"), moment(indicatorState.endActive, "MM-YYYY")] : ['', '']}
                                    value={indicatorState[element.fieldname]}
                                    inputItems={{ ...element.inputItems }}
                                    onChange={(value) => {
                                        onChangeValue({ fieldname: element.fieldname, value: value })
                                    }}
                                    type={element.type} />
                            </Col>
                        )
                    })
                }
                <Col span={12}>
                    <LayoutSearch
                        selectAll
                        disabled={indicatorState.disabledEmployee_LV1}
                        fieldOption={'employeeList_LV1'}
                        checkedDetail={indicatorState['employeeList_LV1']}
                        onChange={({ fieldOption, checkedList, checkAll, plainOptions }) => {
                            onChangeValue({ fieldname: fieldOption, value: (checkAll) ? plainOptions : checkedList })
                        }}
                        header={<BaseText label={"สิทธิสร้างตัวชี้วัด (บันทึกร่าง)"} />}
                        onSearch={(value) =>
                            onSearch({ fieldname: MASTER_OPTION.EVALUATION_EMPLOYEE, searchText: value })
                        }
                        plainOptions={masterState.optionEvaluationEmployee_LV1}
                    />
                </Col>
                <Col span={12}>
                    <LayoutSearch
                        selectAll
                        disabled={indicatorState.disabledEmployee_LV2}
                        fieldOption={'employeeList_LV2'}
                        checkedDetail={indicatorState['employeeList_LV2']}
                        onChange={({ fieldOption, checkedList, checkAll, plainOptions }) => {
                            onChangeValue({ fieldname: fieldOption, value: (checkAll) ? plainOptions : checkedList })
                        }}
                        header={<BaseText label={"สิทธิอนุมัติตัวชี้วัด"} />}
                        onSearch={(value) =>
                            onSearch({ fieldname: MASTER_OPTION.EVALUATION_EMPLOYEE, searchText: value })
                        }
                        plainOptions={masterState.optionEvaluationEmployee_LV2}
                    />
                </Col>

            </Row>

            <DynamicField
                added
                disabled={disabled}
                header={<BaseText size={12} label={"กำหนดหัวข้อตัวชี้วัด"} />}
                buttonText={<BaseText icon={'+'} label={"เพิ่มหัวข้อตัวชี้วัด"} />}
                validateField={['title', 'criterion', 'proportion']}
                onChange={(value) => {
                    onChangeValue({ fieldname: 'indicator', value })
                }}
                contentLayouts={[
                    { label: 'ตัววัดผลงาน', span: 14, type: TYPE.INPUT, fieldname: 'title' },
                    { label: dynamicLabel({ label: 'เกณฑ์', result: 100 }), span: 4, type: TYPE.NUMBER, fieldname: 'criterion' },
                    { label: dynamicLabel({ label: 'สัดส่วน', result: indicatorState.proportion, backward: true }), span: 4, type: TYPE.NUMBER, fieldname: 'proportion' }
                ]}
                structureContent={{ indicatorHeaderId: '', refHeader: '', title: "", criterion: null, proportion: null }}
                initialField={indicatorState.indicator}
            />
            {
                (indicatorState.disabledEmployee_LV1 || approval) &&
                <MainInput
                    value={indicatorState.approval}
                    type={TYPE.CHECKBOX}
                    inputProps={{

                    }}
                    inputItems={{
                        label: 'ยืนยันการอนุมัติ (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)'
                    }}
                    onChange={(e) => {
                        // setChecked(e.target.checked)
                        onChangeValue({ fieldname: 'approval', value: e.target.checked })

                    }}
                />
            }
        </Fragment>
    )
};

