import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

const DEFINE = {
  // Card 1
  company: {
    label: "บริษัท",
    name: "company",
  },
  location: {
    label: "สถานที่",
    name: "location",
  },
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  subDepNo: {
    label: "แผนกย่อย",
    name: "subDepNo",
  },
  startDate: {
    label: "จากวันที่",
    name: "startDate",
  },
  toDate: {
    label: "ถึงวันที่",
    name: "toDate",
  },
  statusDoc: {
    label: "สถานะยื่นประกันสังคม",
    name: "statusDoc",
  },
  fillData: {
    label: "กรอกข้อมูลเลือกโรงพยาบาล",
    name: "fillData",
  },
  userId: {
    label: "รหัสพนักงาน",
    name: "userId",
  },
  hasSSO: {
    label: "มีบัตรรับรองสิทธิประกันสังคม",
    name: "hasSSO",
  },
  province: {
    label: "จังหวัด",
    name: "province",
  },
  hospital_1: {
    label: "โรงพยาบาลลำดับที่ 1",
    name: "hospital_1",
  },
  hospital_2: {
    label: "โรงพยาบาลลำดับที่ 2",
    name: "hospital_2",
  },
  hospital_3: {
    label: "โรงพยาบาลลำดับที่ 3",
    name: "hospital_3",
  },
  // Card 2
  docRef: {
    label: "ชุดเอกสาร",
    name: "docRef",
  },
  datatimeStamp: {
    label: "วันที่ยื่น",
    name: "datatimeStamp",
  },
};

export const ComponentSearchSSONewEmp = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const {
    company,
    location,
    depNo,
    subDepNo,
    startDate,
    toDate,
    statusDoc,
    fillData,
  } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: company.name,
              label: company.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(company.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(company.label),
              disabled: isDisable[company.name] || false,
            },
            fnChange: (value) => handleChange(company.name, value),
            dropdown: options[company.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: location.name,
              label: location.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(location.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(location.label),
              disabled: isDisable[location.name] || false,
            },
            fnChange: (value) => handleChange(location.name, value),
            dropdown: options[location.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [
                { required: false, message: ERROR_MESSAGE.SELECT(depNo.label) },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: subDepNo.name,
              label: subDepNo.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(subDepNo.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
              disabled: isDisable[subDepNo.name] || false,
            },
            fnChange: (value) => handleChange(subDepNo.name, value),
            dropdown: options[subDepNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDate.name,
              label: startDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(startDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: toDate.name,
              label: toDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[toDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(toDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: statusDoc.name,
              label: statusDoc.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(statusDoc.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(statusDoc.label),
              disabled: isDisable[statusDoc.name] || false,
            },
            fnChange: (value) => handleChange(statusDoc.name, value),
            dropdown: options[statusDoc.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: fillData.name,
              label: fillData.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(fillData.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(fillData.label),
              disabled: isDisable[fillData.name] || false,
            },
            fnChange: (value) => handleChange(fillData.name, value),
            dropdown: options[fillData.name],
          },
        },
      ], // column
    }, // Row
  ];
};

export const ComponentSearchSSOOutEmp = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { company, location, depNo, subDepNo, startDate, toDate, statusDoc } =
    DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: company.name,
              label: company.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(company.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(company.label),
              disabled: isDisable[company.name] || false,
            },
            fnChange: (value) => handleChange(company.name, value),
            dropdown: options[company.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: location.name,
              label: location.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(location.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(location.label),
              disabled: isDisable[location.name] || false,
            },
            fnChange: (value) => handleChange(location.name, value),
            dropdown: options[location.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [
                { required: false, message: ERROR_MESSAGE.SELECT(depNo.label) },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: subDepNo.name,
              label: subDepNo.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(subDepNo.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
              disabled: isDisable[subDepNo.name] || false,
            },
            fnChange: (value) => handleChange(subDepNo.name, value),
            dropdown: options[subDepNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDate.name,
              label: startDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(startDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: toDate.name,
              label: toDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[toDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(toDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: statusDoc.name,
              label: statusDoc.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(statusDoc.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(statusDoc.label),
              disabled: isDisable[statusDoc.name] || false,
            },
            fnChange: (value) => handleChange(statusDoc.name, value),
            dropdown: options[statusDoc.name],
          },
        },
      ], // column
    }, // Row
  ];
};

export const ComponentAddSSO = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
  checkSSO,
}) => {
  const {
    company,
    userId,
    hasSSO,
    province,
    hospital_1,
    hospital_2,
    hospital_3,
  } = DEFINE;
  let compHospital = checkSSO
    ? [
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: province.name,
              label: province.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(province.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(province.label),
              disabled: isDisable[province.name] || false,
            },
            fnChange: (value) => handleChange(province.name, value),
            dropdown: options[province.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: hospital_1.name,
              label: hospital_1.label,
              rules: [
                {
                  required: true,
                  message: ERROR_MESSAGE.SELECT(hospital_1.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(hospital_1.label),
              disabled: isDisable[hospital_1.name] || false,
            },
            fnChange: (value) => handleChange(hospital_1.name, value),
            dropdown: options[hospital_1.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: hospital_2.name,
              label: hospital_2.label,
              rules: [
                {
                  required: true,
                  message: ERROR_MESSAGE.SELECT(hospital_2.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(hospital_2.label),
              disabled: isDisable[hospital_2.name] || false,
            },
            fnChange: (value) => handleChange(hospital_2.name, value),
            dropdown: options[hospital_2.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: hospital_3.name,
              label: hospital_3.label,
              rules: [
                {
                  required: true,
                  message: ERROR_MESSAGE.SELECT(hospital_3.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(hospital_3.label),
              disabled: isDisable[hospital_3.name] || false,
            },
            fnChange: (value) => handleChange(hospital_3.name, value),
            dropdown: options[hospital_3.name],
          },
        },
      ]
    : [];
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: company.name,
              label: company.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(company.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(company.label),
              disabled: isDisable[company.name] || false,
            },
            fnChange: (value) => handleChange(company.name, value),
            dropdown: options[company.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: userId.name,
              label: userId.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(userId.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(userId.label),
              disabled: isDisable[userId.name] || false,
            },
            fnChange: (value) => handleChange(userId.name, value),
            dropdown: options[userId.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: hasSSO.name,
              label: hasSSO.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(hasSSO.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(hasSSO.label),
              disabled: isDisable[hasSSO.name] || false,
            },
            fnChange: (value) => handleChange(hasSSO.name, value),
            dropdown: options[hasSSO.name],
          },
        },
        ...compHospital,
      ], // column
    }, // Row
  ];
};

export const ComponentSendSSO = ({
  isDisable = {},
  handleChange = () => null,
}) => {
  const { docRef, datatimeStamp } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 10, xl: 10 },
          component: {
            type: COMPONENT_TYPE.INPUT,
            itemProps: {
              name: docRef.name,
              label: docRef.label,
              rules: [
                { required: true, message: ERROR_MESSAGE.INPUT(docRef.label) },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.INPUT(docRef.label),
              disabled: isDisable[docRef.name] || false,
            },
            fnChange: (event) => handleChange(docRef.name, event.target.value),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 10, xl: 10 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: datatimeStamp.name,
              label: datatimeStamp.label,
              rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[datatimeStamp.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(datatimeStamp.name, dateString),
          },
        },
      ], // column
    }, // Row
  ];
};
