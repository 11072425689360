import React, { useState } from 'react'
import { Checkbox, Col, Row } from 'antd'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { TEXT_BUTTON } from '../buttons'
import UploadDocuments from '../../common/upload/uploadDocuments'
import { colors } from '../../themes'

export const withFormData = (payload) => {
    const formData = new FormData();
    for (let key in payload) {
        if (typeof payload[key] === 'object' && (payload[key] && payload[key].length > 0)) {
            payload[key].forEach((el) => {
                formData.append(key, el);
            });
        } else {
            formData.append(key, payload[key]);
        }
    }
    return formData;
};

export const withFormDataObject = (payload) => {
    const formData = new FormData();
    for (let key in payload) {
        if (typeof payload[key] === 'object' && (payload[key] && payload[key].length > 0)) {
            payload[key].forEach((el) => {
                /*** ข้อมูลที่เป็น Images */
                formData.append(key, el);
            });
        } else {
            /*** ข้อมูลที่เป็น Object */
            formData.append(key, JSON.stringify(payload[key]));
        }
    }
    return formData;
};



export function GET_DOCUMENT_PREVIEW(_fieldList = []) {
    let result = [];
    for (let i = 0; i < _fieldList.length; i++) {
        result = result.push({
            ..._fieldList[i],
            name: _fieldList[i].filename || "",
            status: 'done',
            url: _fieldList[i].filePath || ""
        })
    }
    // _fieldList.map((each, index) => (
    //     result = result.concat({
    //         ...each,
    //         name: each.filename || "",
    //         status: 'done',
    //         url: each.filePath || ""
    //     })
    // ));
    // console.log('GET_DOCUMENT_PREVIEW', result)
    return result;
}



export const UploadFile = ({ label, maxlength, fieldname, message, fileList, onClick }) => {
    return (
        <div style={{ paddingBottom: 10 }}>
            <div style={{ paddingBottom: 10 }}>{label || ' '}</div>
            <div style={{
                display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start'
            }}>
                <UploadDocuments
                    fieldname={fieldname}
                    multiple={true}
                    maxlength={maxlength || 3}
                    fileList={fileList}
                    handleupload={onClick}
                    defaultfileList={GET_DOCUMENT_PREVIEW(fileList)} />
                {(message) && <div style={{ paddingTop: 10, fontSize: 12, color: colors.error, textAlign: 'start' }}>
                    <span style={{ color: colors.error }}> {'*'}</span> {'รองรับเฉพาะไฟล์ .png, .jpg, .jpeg, .webp, .pdf, .doc, .xlx, .docx, .xles, .csv'}
                </div>}
            </div>
        </div>
    )
}
