import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from "reactstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { getCurrent } from '../extendFunction/getCurrentYears';
import { loadingPage } from '../components/loadingPage'
import { GET, GET_SELECT_DASHBOARD_PLAN_KNOWLEAGE, GET_SELECT_OPTION_ALL } from '../service/index';
import { Modal, notification } from "antd";
class DashboardPlanKnowleage extends React.Component {
	constructor(props) {
		super(props);
		const { yearsSelect, typeSelect, yearsNow } = getCurrent();
		this.state = {
			showDropdown_option: [],
			loadData: [],
			SegmentTxt: "all",
			yearsTxt: yearsSelect,
			typeTXt: typeSelect,
			yearsNow,
			done: false,
			userLogin: this.props.userLogin,
		};
	}

	componentDidMount() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				result['department'].map(el => {
					departmentLists.push({ label: el.depNo + " :: " + el.depNameTH, value: el.depNo })
				})
				this.setState({
					showDropdown_option: departmentLists,
					done: true,
				});
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	onChangeValue = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value });
	}

	onSearchDashboardPlanKnowledge = async () => {
		try {
			const { typeTXt, SegmentTxt, yearsTxt } = this.state
			this.setState({ loadData: [] });
			if (yearsTxt !== '0' && typeTXt !== '0') {
				this.setState({ done: false });
				let res = await GET(GET_SELECT_DASHBOARD_PLAN_KNOWLEAGE({ typeTXt, SegmentTxt, yearsTxt }));
				res = res.result
				//console.log('res', res.result)
				let loadData = [];
				if (res) {
					if (res.length > 0) {
						loadData = res.map((row, index) => ({ ...row, DocNo: index }));
					}
				} else {
					notification.info({ description: "ไม่พบข้อมูล" })
				}
				this.setState({ loadData })
			} else {
				Modal.warning({ title: "กรูณาเลือกปีและประเภทการประเมินให้ครบถ้วน!!" })
			}
		} catch (err) {
			console.log("Error Search Dashboard PlanKnowledge: ", err);
		} finally {
			this.setState({ done: true });
		}
	}

	render() {
		const { done, yearsNow, yearsTxt, typeTXt, SegmentTxt, showDropdown_option } = this.state
		return (
			<Container>
				{done ? (
					<div>
						<Card>
							<CardHeader>รายงานสรุปสิ่งที่อยากพัฒนา</CardHeader>
							<CardBody>
								<Form>
									<Row>
										<Col md={2}>
											<FormGroup>
												<Label><span className="red"> * </span>การประเมินประจำปี</Label>
												<Input defaultValue={yearsTxt} type="select" name="yearsTxt" id="yearsTxt" onChange={this.onChangeValue}
												>
													{/* <option value="0">กรุณาเลือกปี</option> */}
													<option value={yearsNow} > {yearsNow}</option>
													<option value={yearsNow - 1} > {yearsNow - 1}</option>
													<option value={yearsNow - 2} > {yearsNow - 2}</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label><span className="red"> * </span>ประเภทการประเมิน</Label>
												<Input defaultValue={typeTXt} type="select" name="typeTXt" id="typeTXt" onChange={this.onChangeValue}>
													{/* <option value="0">กรุณาเลือกประเภทการประเมิน</option> */}
													<option value="Half" >  ครั้งที่ 1 (ม.ค. - มิ.ย.) </option>
													<option value="Full" >  ครั้งที่ 2 (ม.ค. - ธ.ค.) </option>
												</Input>
											</FormGroup>
										</Col>

										<Col md={3}>
											<FormGroup>
												<Label for="Type">แผนก / กลุ่ม </Label>
												<Input
													type="select"
													name="SegmentTxt"
													id="SegmentTxt"
													onChange={this.onChangeValue}
													defaultValue={SegmentTxt}
												>
													<option value="all" >-----All----- </option>
													{showDropdown_option.map((el, index) =>
														<option key={index} value={el.value} >{el.label}</option>
													)}
												</Input>
											</FormGroup>
										</Col>
										<Col md={2} className="text-center cs_btn">
											<FormGroup>
												{/* <Button color="info" onClick={this.search}> */}
												<Button color="info" onClick={this.onSearchDashboardPlanKnowledge}>
													SEARCH
												</Button>
											</FormGroup>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
						<hr />
						{this._renderExportCSV()}
						{this._renderTable()}
						<br />
					</div >
				) : (<div>{loadingPage} </div>)}
			</Container>
		);
	} // Render

	_renderTable() {
		const { loadData } = this.state

		const columns = [
			{
				dataField: "Years",
				text: "ปี",
				sort: true,
				editable: false,
			},
			{
				dataField: "TypeDes",
				text: "รอบการประเมิน",
				sort: true,
				editable: false,
			},
			{
				dataField: "NameFull",
				text: "ชื่อ - สกุล",
				sort: true,
				editable: false,
				formatter: (cellContent, row) => {
					return (
						<div>
							{row.StaffCode + " :: " + row.StaffName}
						</div>
					)
				}
			},
			{
				dataField: "NickName",
				text: "ชื่อเล่น",
				sort: true,
				editable: false,
			},
			{
				dataField: "Department_Name",
				text: "แผนก",
				sort: true,
				editable: false,
			},
			{
				dataField: "KM_1",
				text: "สิ่งที่อยากเรียนรู้อันดับ 1",
				sort: true,
				editable: false,

			},
			{
				dataField: "Reason_1",
				text: "เหตุผลอันดับ 1",
				sort: true,
			},
			{
				dataField: "KM_2",
				text: "สิ่งที่อยากเรียนรู้อันดับ 2",
				sort: true,
			},

			{
				dataField: "Reason_2",
				text: "เหตุผลอันดับ 2",
				sort: true,
				editable: false,
			},
			{
				dataField: "KM_3",
				text: "สิ่งที่อยากเรียนรู้อันดับ 3",
				sort: true,
				editable: false,
				style: {
					textAlign: 'center'
				}
			},

			{
				dataField: "Reason_3",
				text: "เหตุผลอันดับ 3",
				sort: true,
			},

		];

		const sizePerPageRenderer = ({
			options,
			currSizePerPage,
			onSizePerPageChange
		}) => (
			<div className="btn-group" role="group">
				{
					options.map((option) => {
						const isSelect = currSizePerPage === `${option.page}`;
						return (
							<button
								key={option.text}
								type="button"
								onClick={() => onSizePerPageChange(option.page)}
								className={`btn ${isSelect ? 'btn-secondary' : 'btn-primary'}`}
							>
								{option.text}
							</button>
						);
					})
				}
			</div>
		);

		const opt = {
			sizePerPageRenderer
		};

		return (
			<BootstrapTable
				keyField="DocNo" //"custNo"RowID
				data={loadData}
				columns={columns}
				ref={n => (this.node = n)}
				striped
				hover
				condensed
				pagination={paginationFactory(opt)}
				wrapperClasses="table-responsive"
			/>
		)
	}

	_renderExportCSV() {
		const { loadData } = this.state

		const headerCSV = [
			{ label: "ปี", key: "Years" },
			{ label: "รอบการประเมิน", key: "TypeDes" },
			{ label: "รหัสพนักงาน", key: "StaffCode" },
			{ label: "ชื่อ - สกุล", key: "StaffName" },
			{ label: "ชื่อเล่น", key: "NickName" },
			{ label: "แผนก", key: "Department_Name" },
			{ label: "สิ่งที่อยากเรียนรู้อันดับ 1", key: "KM_1" },
			{ label: "เหตุผลอันดับ 1", key: "Reason_1" },
			{ label: "สิ่งที่อยากเรียนรู้อันดับ 2", key: "KM_2" },
			{ label: "เหตุผลอันดับ 2", key: "Reason_2" },
			{ label: "สิ่งที่อยากเรียนรู้อันดับ 3", key: "KM_3" },
			{ label: "เหตุผลอันดับ 3", key: "Reason_3" },
		];

		return (
			<div className="btn-right">
				<CSVLink
					headers={headerCSV}
					data={loadData}
					filename={"รายงานสรุปสิ่งที่อยากพัฒนา.csv"}
					className="btn btn-export"
				>
					<MdFileDownload /> Export CSV
				</CSVLink>
			</div>
		)
	}
}
export default DashboardPlanKnowleage