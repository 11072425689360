import React from 'react'
import { LayoutWrapper, LayoutBreadcrumb } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_INSERT_OT } from '../commonsOT/structuresOT/breadcrumOT'
import InsertOT from '../componentsOT/insertOT'

const PageInsertOT = () => {
    return (
        <LayoutWrapper
            breadcrumb={
                <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_INSERT_OT()} />
            }
            search={null}
            content={<InsertOT />}
        />
    )
}
export default PageInsertOT
