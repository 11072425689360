import React, { useState, useContext, useEffect } from 'react'
import { Spin } from 'antd'

import { LayoutWrapper } from '../../moduleKPIs/common/layouts/layoutWrapper'
import { LayoutBreadcrumb } from '../../moduleKPIs/common/layouts/layoutBreadcrumb'
import { WrapperSearch } from '../../moduleKPIs/common/layouts/layoutSearch'
import { MasterTrainingContext } from '../servicesTraining/masterTraining/masterTrainingProvider'
import { BREADCRUMB_TRAINING } from '../commonsTraining/structuresTraining/breadcrumTraining'
import { TABS_TRAINING } from '../commonsTraining/structuresTraining/tabsTraining'
import { SEARCH_DASHBOARD_TRAINING } from '../commonsTraining/structuresTraining/searchTraining'
import { LayoutTabs } from '../../moduleKPIs/common/layouts'
import { FILED_OPTION, MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider'
import { PATHNAME } from '../../constants/enums/pathname'
import { TEXT_LOADING } from '../../constants/enums/enumCenter'


const PageDashboardTraining = () => {
    const { userLogin } = useContext(ManageTrainingContext);
    const { masterTrainingState, onChangeMaster, getMaster } = useContext(MasterTrainingContext);

    const [loading, setLoading] = useState(false)

    let SEARCH_LIST = SEARCH_DASHBOARD_TRAINING({ permission: userLogin.userPermission, options: masterTrainingState })
    let TABS_LIST = TABS_TRAINING({ pathname: PATHNAME.DASHBOARD_TRAINING, permission: userLogin.userPermission })

    /* -------------------------------------------------------------------------- */
    /*                                  useEffect                                 */
    /* -------------------------------------------------------------------------- */

    async function fetchAPI() {
        try {
            setLoading(true)
            await Promise.all(
                [getMaster({ fieldname: MASTER_OPTION.OPTION_SUBDEP })],
                [getMaster({ fieldname: MASTER_OPTION.OPTION_TRAINING_STATUS, payload: { type: FILED_OPTION.OPTION_TRAINING_STATUS } })],
            )

        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAPI()
    }, [])
    /* -------------------------------------------------------------------------- */


    return (
        <Spin spinning={loading} tip={TEXT_LOADING}>
            <LayoutWrapper
                breadcrumb={< LayoutBreadcrumb breadcrumbLists={BREADCRUMB_TRAINING(window.location.pathname)} />}
                search={
                    <WrapperSearch
                        searchLists={SEARCH_LIST}
                        onChange={onChangeMaster} />
                }
                content={
                    <div className="layout-content-card" style={{ padding: 10 }} >
                        <LayoutTabs tabsLists={TABS_LIST} />
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardTraining